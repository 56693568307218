import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

type ResponsiveContainerProps = {
  isSidebarCollapsed: boolean
}

export const WideContainer = styled.div<ResponsiveContainerProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: ${(props) => (props.isSidebarCollapsed ? 948 : 1134)}px) {
    display: none;
  }
`

export const NarrowContainer = styled.div<ResponsiveContainerProps>`
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: ${(props) => (props.isSidebarCollapsed ? 948 : 1134)}px) {
    display: flex;
  }
`
