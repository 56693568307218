import styled from 'styled-components'
import { spacing, colors, HeadlineH2DarkGreyRegular } from 'static/theme'
import { Color } from '../Radio'
import { ReactComponent as TickOnSVG } from 'assets/filters/selection-tick-on.svg'
import { ReactComponent as TickOffSVG } from 'assets/filters/selection-tick-off.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

export const Label = styled(HeadlineH2DarkGreyRegular)`
  margin-left: ${spacing.xxs};
`

export const TickOnIcon = styled(TickOnSVG)<{ color: Color }>`
  fill: ${({ color }) =>
    color === 'secondary' ? colors.secondaryMain : colors.primaryMain};
`

export const TickOffIcon = styled(TickOffSVG)<{ color: Color }>`
  stroke: ${colors.primaryLight};
  ${Container}:hover & {
    stroke: ${({ color }) =>
      color === 'secondary' ? colors.secondaryMain : colors.primaryMain};
  }
`
