import React, { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '@logicea/react-auth'
import { useReports } from 'providers/Reports'
import { I18n } from 'lib/i18n'
import { countMessagesAction } from 'requests/messageRQ'
import { MagicNumber, MagicNumberPlaceholder } from '../MagicNumber'
import { buildReportDashboardFilters } from "../../../lib/util";

const EmailsReported = () => {
  const { timeframe, comparisonTimeframe, isComparisonEnabled, teamFilter } = useReports()
  const { from, to } = timeframe
  const { from: comparisonFrom, to: comparisonTo } = comparisonTimeframe

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth as any
  const queryArgs = { realm, token: tokens?.accessToken }

  const filterArgs = {
    dashboardFilters: buildReportDashboardFilters(from, to, teamFilter),
    setFilters: [
      {
        name: 'reports',
        not: false,
        term: true,
        type: 'nested',
      },
    ],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const comparedFilterArgs = {
    dashboardFilters: buildReportDashboardFilters(comparisonFrom, comparisonTo, teamFilter),
    setFilters: [
      {
        name: 'reports',
        not: false,
        term: true,
        type: 'nested',
      },
    ],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const { data, isLoading: dataIsLoading } = useQuery(
    ['countEmailsReceived', filterArgs, queryArgs],
    countMessagesAction,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const { data: comparedData, isLoading: comparedDataIsLoading } = useQuery(
    ['comparedCountEmailsReceived', comparedFilterArgs, queryArgs],
    countMessagesAction,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const percent = useMemo(() => {
    if (comparedData === 0) return undefined
    if (data && comparedData) {
      return data / comparedData - 1
    } else {
      return 0
    }
  }, [data, comparedData])

  const isLoading = dataIsLoading || comparedDataIsLoading

  return (
    <I18n>
      {({ t }) =>
        !isLoading ? (
          <MagicNumber
            title={t({ key: 'EMAILS_REPORTED' })}
            number={data}
            percent={isComparisonEnabled ? percent : undefined}
          />
        ) : (
          <MagicNumberPlaceholder title={t({ key: 'EMAILS_REPORTED' })} />
        )
      }
    </I18n>
  )
}

export { EmailsReported }
