import React, { useCallback, useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '@logicea/react-auth'
import { colors } from 'static/theme'
import { useReports } from 'providers/Reports'
import { aggregateByThreatLevel, countTopHitAction } from 'requests/messageRQ'
import {
  WidgetTitle as Title,
  WidgetSubtitle as Subtitle,
  WidgetPadding as Padding,
} from 'components/ReportWidgets/styles'
import {
  Container,
  Content,
  ThreatLevel,
  ProgressBarContainer,
  ProgressBar,
  Counter,
} from 'components/ReportWidgets/TopHit/styles'
import { ThreatLevel as ThreatLevelType } from 'requests/messageRQ/types'
import { buildReportDashboardFilters } from "../../../lib/util";

type TopHitProps = {
  title: string
  fieldName: string
  isAnimationActive: boolean
}

const TopHit = ({ title, fieldName, isAnimationActive }: TopHitProps) => {
  const { timeframe, comparisonTimeframe, isComparisonEnabled, teamFilter } = useReports()
  const { from, to } = timeframe
  const { from: comparedFrom, to: comparedTo } = comparisonTimeframe

  const auth = useContext(AuthContext)
  const { realm, tokens, user } = auth as any
  const queryArgs = { realm, token: tokens?.accessToken }

  const filterArgs = useCallback(
    ({
      from,
      to,
      fieldName,
      fieldNameKey,
      teamFilter
    }: {
      from: number
      to: number
      fieldName: string
      fieldNameKey?: string
      teamFilter: string[]
    }) => {

      return {
        dashboardFilters: buildReportDashboardFilters(from, to, teamFilter),
        setFilters:
          fieldNameKey !== undefined
            ? [{ name: fieldName, term: [fieldNameKey], type: 'keyword' }]
            : [],
        dashboardFilterMode: 'AND',
        setFilterMode: 'AND',
      }
    },
    []
  )

  const { data: data } = useQuery(
    [
      `count-top-hit-${fieldName}`,
      filterArgs({ from, to, fieldName, teamFilter }),
      queryArgs,
      fieldName,
    ],
    countTopHitAction,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const { data: threatLevelData } = useQuery(
    [
      `top-hit-threat-level-${fieldName}`,
      filterArgs({ from, to, fieldName, fieldNameKey: data?.key, teamFilter }),
      queryArgs,
    ],
    aggregateByThreatLevel,
    { enabled: realm !== undefined && tokens !== undefined && !!data?.key }
  )

  const { data: comparedThreatLevelData } = useQuery(
    [
      `compared-top-hit-threat-level-${fieldName}`,
      filterArgs({
        from: comparedFrom,
        to: comparedTo,
        fieldName,
        fieldNameKey: data?.key,
        teamFilter
      }),
      queryArgs,
    ],
    aggregateByThreatLevel,
    { enabled: realm !== undefined && tokens !== undefined && !!data?.key }
  )

  const progressPercent = useCallback(
    (count: number) => {
      if (threatLevelData) {
        let totalCount = 0
        threatLevelData?.forEach((t) => (totalCount = totalCount + t.value))
        return (100 * count) / totalCount
      } else {
        return 0
      }
    },
    [threatLevelData]
  )

  const counterPercent = useCallback(
    (threatLevel: ThreatLevelType) => {
      if (comparedThreatLevelData) {
        const comparedThreatLevel = comparedThreatLevelData.find(
          (t) => t.threatLevel === threatLevel.threatLevel
        )
        if (comparedThreatLevel === undefined) return undefined
        if (comparedThreatLevel) {
          return threatLevel.value / comparedThreatLevel.value - 1
        } else {
          return 0
        }
      } else {
        return undefined
      }
    },
    [threatLevelData, comparedThreatLevelData]
  )

  const exactlyThreeDataItems = useMemo(
    () =>
      (threatLevelData ?? [])
        .concat([
          { threatLevel: 0, value: 0 },
          { threatLevel: 1, value: 0 },
          { threatLevel: 2, value: 0 },
        ])
        .slice(0, 3),
    [threatLevelData]
  )

  return (
    <Container>
      <Padding>
        <Title>{title}</Title>
        <Subtitle>{data?.key}</Subtitle>
        <Content>
          {exactlyThreeDataItems?.map((t) => (
            <ThreatLevel>
              <ProgressBarContainer>
                {t.value > 0 && (
                  <ProgressBar
                    fullWidth={false}
                    percentage={progressPercent(t.value)}
                    color={
                      t.threatLevel === 0
                        ? colors.neutral
                        : t.threatLevel === 1
                        ? colors.caution
                        : colors.danger
                    }
                    isAnimationActive={isAnimationActive}
                  />
                )}
              </ProgressBarContainer>
              {t.value > 0 && (
                <Counter
                  type='medium'
                  number={t.value}
                  percent={isComparisonEnabled ? counterPercent(t) : undefined}
                />
              )}
            </ThreatLevel>
          ))}
        </Content>
      </Padding>
    </Container>
  )
}

export { TopHit }
