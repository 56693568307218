export const LINK_CLICK_ALERT_LVL_NAMES = name => {
  switch (name) {
    case '0':
      return 'Neutral'
    case '1':
      return 'Caution'
    case '2':
      return 'Danger'
    default:
      return name
  }
}
