import styled from 'styled-components'
import {
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
  VISUALIZATION_BACKGROUND,
} from '../../../../static/colors'
import {
  VISUALIZATION_MARGIN,
} from '../../../../static/appConfig'

export const Container = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  border-radius: 4px;
  margin: ${VISUALIZATION_MARGIN}px;
  background-color: ${VISUALIZATION_BACKGROUND};
`
export const TreeMapWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const getColor = name => {
  switch (name) {
    case '0.0':
      return CHART_SAFE
    case '1.0':
      return CHART_CAUTION
    case '2.0':
      return CHART_DANGER
    default:
      return 'transparent'
  }
}

export const NodeWrapper = styled.div`
  position: relative;
  display: flex;
  flex: ${props => props.size};
  flex-direction: ${props => (props.level / 2 === 1 ? 'column' : 'row')};
  justify-content: center;
  margin: 1px;
  margin: 1px;
  min-height: 2px;
  background-color: ${props => getColor(props.name)};
  border-radius: 2px;
`

export const NodeOverlay = styled.div`
  position: absolute;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;

  &:hover {
    color: white;
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.5);
  }
`
