import React, {
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useQuery } from 'react-query'
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import ReactResizeDetector from 'react-resize-detector'
import { I18n } from 'lib/i18n'
import { useReports } from 'providers/Reports'
import { AuthContext } from '@logicea/react-auth'
import { aggregateByDate } from 'requests/messageRQ'
import { colors } from 'static/theme'
import { DateTick } from 'components/ReportComponents/DateTick'
import { CountTick } from 'components/ReportComponents/CountTick'
import { ActiveDot } from 'components/ReportComponents/ActiveDot'
import { CustomTooltip } from 'components/ReportComponents/CustomTooltip'
import {
  Container,
  Title,
  Content,
  LineChartContainer,
  ComparisonContainer,
  ThreatsInfo,
  ThreatType,
  Threat,
  ThreatTitle,
} from './styles'
import { buildReportDashboardFilters } from "../../../lib/util";

type DateData = {
  date: string
  max: number
  countCaution: number
  countDanger: number
}

const ThreatsOverTime = ({
  isAnimationActive,
}: {
  isAnimationActive: boolean
}) => {
  const [localData, setLocalData] = useState<Array<DateData>>([])
  const [localComparedData, setLocalComparedData] = useState<Array<DateData>>(
    []
  )
  const [maxCount, setMaxCount] = useState(0)
  const [width, setWidth] = useState(0)
  const height = 176

  const { timeframe, comparisonTimeframe, isComparisonEnabled, teamFilter } = useReports()
  const { from, to } = timeframe
  const { from: comparisonFrom, to: comparisonTo } = comparisonTimeframe

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth as any
  const queryArgs = { realm, token: tokens?.accessToken }

  const filterArgs = {
    dashboardFilters: buildReportDashboardFilters(from, to, teamFilter),
    setFilters: [],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const comparedFilterArgs = {
    dashboardFilters: buildReportDashboardFilters(comparisonFrom, comparisonTo, teamFilter),
    setFilters: [],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const { data } = useQuery(
    ['aggregateByDate', filterArgs, queryArgs],
    aggregateByDate,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const { data: comparedData } = useQuery(
    ['comparedAggregateByDate', comparedFilterArgs, queryArgs],
    aggregateByDate,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  useEffect(() => {
    let maxValue = 0
    const formattedData = data?.map((e) => {
      const maxThreatCount = Math.max(e.countCaution, e.countDanger)
      if (maxThreatCount > maxValue) maxValue = maxThreatCount
      return {
        date: e.date,
        max: maxThreatCount,
        countCaution: e.countCaution,
        countDanger: e.countDanger,
      }
    })
    if (formattedData !== undefined) {
      setLocalData(formattedData)
      setMaxCount(maxValue)
    }
  }, [data])

  useEffect(() => {
    const formattedData = comparedData?.map((e) => ({
      date: e.date,
      max: maxCount,
      countCaution: e.countCaution,
      countDanger: e.countDanger,
    }))
    if (formattedData !== undefined) setLocalComparedData(formattedData)
  }, [comparedData, maxCount])

  const onResize = useCallback((width: number) => {
    setWidth(width)
  }, [])

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Title>{t({ key: 'THREATS_OVER_TIME' })}</Title>
          <Content>
            {isComparisonEnabled ? (
              <ComparisonContainer>
                <LineChart
                  width={width}
                  height={height}
                  data={localComparedData}
                  margin={{ top: 10, right: 10, left: 5, bottom: 5 }}
                >
                  <XAxis
                    dataKey='date'
                    interval='preserveStartEnd'
                    tickLine={false}
                    axisLine={false}
                    minTickGap={0}
                    tick={false}
                  />
                  <YAxis
                    width={28}
                    dataKey='max'
                    tick={false}
                    tickLine={false}
                    axisLine={false}
                    allowDecimals={false}
                    domain={[0, 'dataMax']}
                  />
                  <Line
                    dot={false}
                    type='monotone'
                    strokeWidth={1}
                    stroke={`${colors.caution}44`}
                    dataKey='countCaution'
                    isAnimationActive={isAnimationActive}
                  />
                  <Line
                    dot={false}
                    type='monotone'
                    strokeWidth={1}
                    stroke={`${colors.danger}44`}
                    dataKey='countDanger'
                    isAnimationActive={isAnimationActive}
                  />
                </LineChart>
                <ReactResizeDetector handleWidth onResize={onResize} />
              </ComparisonContainer>
            ) : null}
            <LineChartContainer>
              <LineChart
                width={width}
                height={height}
                data={localData}
                margin={{ top: 10, right: 10, left: 5, bottom: 5 }}
              >
                <XAxis
                  dataKey='date'
                  interval='preserveStartEnd'
                  tickLine={false}
                  minTickGap={0}
                  tick={
                    localData && <DateTick lastIndex={localData?.length - 1} />
                  }
                />
                <YAxis
                  width={28}
                  dataKey='max'
                  tickLine={false}
                  axisLine={false}
                  allowDecimals={false}
                  domain={[0, 'dataMax']}
                  tick={localData && <CountTick />}
                />
                <Tooltip content={<CustomTooltip />} />
                <CartesianGrid
                  stroke={colors.primaryExtraLight}
                  vertical={false}
                />
                <Line
                  dot={false}
                  type='monotone'
                  strokeWidth={2}
                  stroke={colors.caution}
                  dataKey='countCaution'
                  activeDot={(props) => (
                    <ActiveDot threatColor={colors.caution} {...props} />
                  )}
                  isAnimationActive={isAnimationActive}
                />
                <Line
                  dot={false}
                  type='monotone'
                  strokeWidth={2}
                  stroke={colors.danger}
                  dataKey='countDanger'
                  activeDot={(props) => (
                    <ActiveDot threatColor={colors.danger} {...props} />
                  )}
                  isAnimationActive={isAnimationActive}
                />
              </LineChart>
              <ReactResizeDetector handleWidth onResize={onResize} />
            </LineChartContainer>
            <ThreatsInfo>
              <Threat>
                <ThreatType threatColor={colors.danger} />
                <ThreatTitle>
                  {t({ key: 'THREATS_OVER_TIME_CHART_DANGER' })}
                </ThreatTitle>
              </Threat>
              <Threat>
                <ThreatType threatColor={colors.caution} />
                <ThreatTitle>
                  {t({ key: 'THREATS_OVER_TIME_CHART_CAUTION' })}
                </ThreatTitle>
              </Threat>
            </ThreatsInfo>
          </Content>
        </Container>
      )}
    </I18n>
  )
}

export { ThreatsOverTime }
