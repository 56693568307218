import React from 'react'
import { ButtonProps } from '@material-ui/core'
import { Button } from './styles'

export const IconButton = ({ ...rest }: ButtonProps) => {
  const { children } = rest
  return (
    <Button variant='contained' disableRipple={true} {...rest}>
      {children}
    </Button>
  )
}
