import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import tail from 'ramda/src/tail'
import propOr from 'ramda/src/propOr'
import ReactTooltip from 'react-tooltip'
import MessageSet from '../../MessageSet'
import {
  Container,
  TabBar,
  Tab,
  TabText,
  TitleAndFilters,
  FilterText,
  Tools,
  MoreButton,
  MoreIcon,
  CloneButton,
  CloneIcon,
  CloseButton,
  Content,
  FilterThumbnail,
  FilterLine,
  IconWrapper,
  ThreatBullet,
} from './styles'
import { I18n } from '../../../lib/i18n'
import { noop } from '../../../lib/ramda'
import { Tooltip, filterToString, getVisualizationIcon } from '../../../lib/util'

import {
  CATEGORY_MAP,
  FILTER_CATEGORY_COLORS,
} from '../../../static/filterNames'

const { array, bool, func } = PropTypes

const Tabs = ({
  sets,
  isFirstLevel,
  belongsToUser,
  isReport,
  configureMode,
  deleteMessageSet,
  toggleMessageSetActionModal,
  ...rest
}) => {
  const [activeIndex, setActiveIndex] = useState(sets.length - 1)

  useEffect(() => {
    setActiveIndex(sets.length - 1)
  }, [sets.length])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [activeIndex])

  const activeSet = sets[activeIndex]

  return activeSet ? (
    <I18n>
      {({ locale, t }) => (
        <Container>
          <TabBar>
            {sets.map((set, index) => {
              const isActive = index === activeIndex
              const { id, filters, content, visualization } = set
              const message = propOr(null, 'messageId', set)
              const parsedContent = message ? tail(content) : content

              return (
                <Tab
                  key={id}
                  active={isActive}
                  onClick={() => setActiveIndex(index)}
                >
                  <TitleAndFilters>
                    <IconWrapper>
                      {message
                        ? <ThreatBullet threat={content[0]} />
                        : getVisualizationIcon(visualization)}
                    </IconWrapper>
                    <TabText onClick={isActive && !message && belongsToUser && !isReport
                      ? () => toggleMessageSetActionModal('rename', 'message set', id)
                      : noop}
                    >
                      {parsedContent || t({ key: 'VISUALISATION_OPTION',
                        variables: { name: visualization },
                      })}
                    </TabText>
                    {false && (
                      <FilterText active={isActive}>
                        {filters.map((filter, i) => `${
                          filterToString(filter.term, filter.type, filter.name, t, locale)
                        }${i === filters.length - 1 ? '' : ', '}`)}
                      </FilterText>
                    )}
                    <FilterThumbnail>
                      {filters.map(filter => (
                        <FilterLine
                          key={filter.name}
                          color={FILTER_CATEGORY_COLORS[CATEGORY_MAP[filter.name]]}
                        />
                      ))}
                    </FilterThumbnail>
                  </TitleAndFilters>
                  {configureMode && belongsToUser && !isReport && (
                    <Tools>
                      {!message && (
                        <MoreButton>
                          <MoreIcon active={isActive ? 'true' : 'false'} />
                          <CloneButton
                            data-tip
                            data-for="cloneButton"
                            onClick={() => toggleMessageSetActionModal('clone', 'message set', id)}
                          >
                            <CloneIcon />
                          </CloneButton>
                          <Tooltip bottom id="cloneButton">
                            <span>{t({ key: 'TOOLTIP_COPY_MESSAGE_SET' })}</span>
                          </Tooltip>
                        </MoreButton>
                      )}
                      <CloseButton
                        active={isActive}
                        data-tip
                        data-for="deleteButton"
                        onClick={() => deleteMessageSet(id)}
                      >
                        ×
                      </CloseButton>
                      <Tooltip bottom id="deleteButton">
                        <span>{t({ key: 'TOOLTIP_DELETE_MESSAGE_SET' })}</span>
                      </Tooltip>
                    </Tools>
                  )}
                </Tab>
              )
            })}
          </TabBar>
          <Content>
            <MessageSet
              isTab
              set={activeSet}
              belongsToUser={belongsToUser}
              isReport={isReport}
              configureMode={configureMode}
              toggleMessageSetActionModal={toggleMessageSetActionModal}
              {...rest}
            />
          </Content>
        </Container>
      )}
    </I18n>
  ) : null
}

Tabs.propTypes = {
  sets: array,
  isFirstLevel: bool,
  belongsToUser: bool,
  configureMode: bool,
  deleteMessageSet: func,
  toggleMessageSetActionModal: func,
}

export default Tabs
