import styled from 'styled-components'
import { MAX_WIDGET_WIDTH } from 'components/ReportWidgets/styles'
import { colors, spacing, BodyXSPrimaryMainRegular } from 'static/theme'
import { Counter as AbstractCounter } from 'components/ReportComponents/Counter'
import { ProgressBar as AbstractProgressBar } from 'components/ReportComponents/ProgressBar'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 18rem;
  max-width: ${MAX_WIDGET_WIDTH};
  border-radius: 3px;
  background-color: ${colors.white};
  margin-right: ${spacing.m};
  margin-bottom: ${spacing.m};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: 2.25rem;
`

export const Category = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const CatTitle = styled(BodyXSPrimaryMainRegular)`
  flex: 1;
  min-width: 5rem;
`

export const ProgressBar = styled(AbstractProgressBar).attrs({
  color: colors.primaryMain,
})``

export const Counter = styled(AbstractCounter)`
  flex: 1;
  min-width: 6rem;
  justify-content: flex-end;
`

export const CounterPlaceholder = styled.div`
  flex: 1;
  min-width: 6rem;
  height: 1.25rem;
`
