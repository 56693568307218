import styled from 'styled-components'
import {
  colors,
  Body1PrimaryMainRegular,
} from 'static/theme'
import {ReactComponent as HelpSVG} from 'assets/filters/help.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StatisticsText = styled(Body1PrimaryMainRegular)``

export const HelpIcon =styled(HelpSVG)`
  fill:${colors.primaryMain};
`
