import styled from 'styled-components'

export const TagList = styled.div`
  padding-right: 8px;
  display: flex;
  padding-left: 0px;
  margin-left: 0px;
  flex-direction: row;
  font-size: 14px;
  display: inline-block;
`


