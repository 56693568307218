import React, { useMemo } from 'react'
import { useReports } from 'providers/Reports'
import { number as formatNumber } from 'format-message'
import {
  Container,
  NumbersLDarkGreyBold,
  NumbersMDarkGreyBold,
  Placeholder,
  ComparedData,
  ArrowIcon,
  Percent,
} from './styles'

type CounterProps = {
  type: 'large' | 'medium'
  number: number
  percent?: number // -1..1
}

export type PercentType = 'positive' | 'negative' | 'zero'

const Counter = (props: CounterProps) => {
  const { type, number, percent, ...rest } = props
  const { isComparisonEnabled } = useReports()
  const percentType = useMemo((): PercentType => {
    if (percent && percent > 0) {
      return 'positive'
    } else if (percent && percent < 0) {
      return 'negative'
    } else {
      return 'zero'
    }
  }, [percent])

  const percentage = useMemo(
    () => percent !== undefined && `${Math.round(Math.abs(percent) * 100)}%`,
    [percent]
  )

  return (
    <Container {...rest}>
      {type === 'large' ? (
        <NumbersLDarkGreyBold>{formatNumber(number)}</NumbersLDarkGreyBold>
      ) : (
        <NumbersMDarkGreyBold>{formatNumber(number)}</NumbersMDarkGreyBold>
      )}
      {isComparisonEnabled ? (
        percent !== undefined ? (
          <ComparedData percentType={percentType}>
            <ArrowIcon percentType={percentType}>
              {percentType === 'zero' ? '-' : '▶'}
            </ArrowIcon>
            <Percent>{percentage}</Percent>
          </ComparedData>
        ) : (
          <ComparedData percentType="zero">
            <ArrowIcon percentType="zero">
              {'-'}
            </ArrowIcon>
            <Percent>0%</Percent>
          </ComparedData>
        )
      ) : undefined}
    </Container>
  )
}

export { Counter }
