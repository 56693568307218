import React, { Component } from 'react'
import PropTypes from 'prop-types'
import prop from 'ramda/src/prop'
import isNil from 'ramda/src/isNil'
import equals from 'ramda/src/equals'
import propEq from 'ramda/src/propEq'
import concat from 'ramda/src/concat'
import uniqBy from 'ramda/src/uniqBy'
import sortBy from 'ramda/src/sortBy'
import eqProps from 'ramda/src/eqProps'
import findIndex from 'ramda/src/findIndex'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import messageActions from '../../../requests/message'
import { I18n } from '../../../lib/i18n'
import { INTIAL_MICROSOFT_SCL } from '../../../static/threatLevels'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'

const { object, func, array, string } = PropTypes

class MicrosoftSCLInput extends Component {
  state = {
    suggestions: INTIAL_MICROSOFT_SCL,
    selectedSCLs: [],
  }

  componentDidMount() {
    const { filter } = this.props
    if (filter && !isNil(filter.term)) this.handleTermChange(filter.term)
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props
    if (eqProps('name', prevProps.filter, filter)) return
    this.handleTermChange(filter.term)
  }

  handleTermChange = async term => {
    const { suggestions } = this.state
    const { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token } = this.props
    const isKey = keys => e => findIndex(equals(e.scl), keys) > -1
    const data = await messageActions.aggregateByMicrosoftSCLAction({
      dashboardFilters: rFilter(f => !propEq('name', 'microsoft_scl')(f), dashboardFilters),
      setFilters: rFilter(f => !propEq('name', 'microsoft_scl')(f), setFilters),
      dashboardFilterMode,
      setFilterMode,
    }, { realm, token })
    const newSCLs = sortBy(prop('scl'), uniqBy(prop('scl'), concat(data.data, suggestions)))
    this.setState({
      suggestions: newSCLs,
      selectedSCLs: rFilter(isKey(term), newSCLs),
    })
  }

  handleRowClick = scl => {
    const { onChange } = this.props
    const { selectedSCLs } = this.state
    const newSCLs = symmetricDifference(selectedSCLs, [scl])
    this.setState({ selectedSCLs: newSCLs })
    onChange(newSCLs.map(r => r.scl))
  }

  render() {
    const { suggestions, selectedSCLs } = this.state

    return (
      <I18n>{({ t }) => (
        <Container>
          <SelectionHeader>
            <SelectionHeaderItem />
            <SelectionHeaderItem right>
              {selectedSCLs.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {selectedSCLs.length}
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
          </SelectionHeader>
          <SelectionWrapper>
            <SelectionColumnWrapper>
              <Scrollbar>
                {suggestions.map(agg => {
                  const isSelected = findIndex(equals(agg), selectedSCLs) > -1
                  return (
                    <SuggestionWrapper>
                      <Suggestion
                        key={agg.scl}
                        isSelected={isSelected}
                        onClick={isSelected ? null
                          : () => this.handleRowClick(agg)
                        }
                      >
                        <SuggestionText>
                          <strong>{agg.scl}</strong>
                        </SuggestionText>
                        {t({
                          key: 'AGGREGATION_HITS_PARENTHESIS',
                          variables: { aggregationHits: agg.value },
                        })}
                      </Suggestion>
                    </SuggestionWrapper>
                  )
                })}
              </Scrollbar>
            </SelectionColumnWrapper>
            <Seperator>
              <SeperatorIcon />
            </Seperator>
            <SelectionColumnWrapper right>
              <Scrollbar>
                {selectedSCLs.map(selectedSCL => (
                  <SuggestionWrapper>
                    <Suggestion
                      closeable
                      key={selectedSCL.scl}
                      isSelected={false}
                      onClick={() => this.handleRowClick(selectedSCL)}
                    >
                      <SuggestionText>
                        <strong>{selectedSCL.scl}</strong>
                      </SuggestionText>
                      {
                        t({
                          key: 'AGGREGATION_HITS_PARENTHESIS',
                          variables: { aggregationHits: selectedSCL.value },
                        })
                    }
                      <CloseIcon>×</CloseIcon>
                    </Suggestion>
                  </SuggestionWrapper>
                ))}
              </Scrollbar>
            </SelectionColumnWrapper>
          </SelectionWrapper>
        </Container>
      )}
      </I18n>
    )
  }
}

MicrosoftSCLInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default MicrosoftSCLInput
