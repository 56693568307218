import React, { useCallback } from 'react'
import { useReports } from 'providers/Reports'
import { I18n } from 'lib/i18n'
import { renderComparedTimeframe } from 'providers/utils'
import { QuickTourStep } from 'components/Help/QuickTourStep'
import { Container, Title, CompareSwitch, DatePeriod } from './styles'

type CompareDataProps = {
  isExport: boolean
}

const CompareData = ({ isExport }: CompareDataProps) => {
  const {
    timeframeOption,
    comparisonTimeframe,
    isComparisonEnabled,
    updateIsComparisonEnabled,
  } = useReports()

  const handleCompareSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (updateIsComparisonEnabled) updateIsComparisonEnabled(checked)
    },
    []
  )

  return (
    <I18n>
      {({ t }) => (
        <Container>
          {!isExport ? (
            <React.Fragment>
              <Title>{t({ key: 'COMPARE_DATA' })}</Title>
              <QuickTourStep
                stepId='compareData'
                title={t({ key: 'QUICK_TOUR_COMPARE_DATA_TITLE' })}
                content={t({ key: 'QUICK_TOUR_COMPARE_DATA_CONTENT' })}
              >
                <CompareSwitch
                  size='small'
                  checked={isComparisonEnabled}
                  onChange={handleCompareSwitchChange}
                />
              </QuickTourStep>
            </React.Fragment>
          ) : null}
          {isComparisonEnabled ? (
            <DatePeriod>
              {t({ key: 'DATA_COMPARED_TO' })}
              {renderComparedTimeframe(timeframeOption, comparisonTimeframe)}
            </DatePeriod>
          ) : null}
        </Container>
      )}
    </I18n>
  )
}

export { CompareData }
