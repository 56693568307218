import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { I18n } from 'lib/i18n'
import { colors } from 'static/theme/index'
import { PaginationLabel } from '../styles'

type TablePaginationProps = {
  count: number
  page: number
  rowsPerPage: number
}

const getPaginationLabel = ({
  page,
  rowsPerPage,
  count,
}: TablePaginationProps) => {
  const totalPage = Math.ceil(count / rowsPerPage)
  const currentPage = page + 1

  return (
    <span>
      Page{' '}
      <span style={{ color: colors.primaryMain, margin: '0 4px›' }}>
        {currentPage}
      </span>{' '}
      of {totalPage}
    </span>
  )
}

type TablePaginationActionsProps = TablePaginationProps & {
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
}

export const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page + 1)
  }

  return (
    <I18n>
      {({ t }) => (
        <div style={{ display: 'flex', marginLeft: -24 }}>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label='previous page'
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <PaginationLabel>
            {getPaginationLabel({ page, rowsPerPage, count })}
          </PaginationLabel>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='next page'
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        </div>
      )}
    </I18n>
  )
}
