import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import modalStyles, {
  SmallButton,
  Label,
  CloseIcon, CloseButtonTop, Content, Button, ResultTypeLabel,
} from '../modalStyles'
import {
  OuterContainer,
  ContentContainer,
  Title,
  Divider,
  ButtonsWrapper, ResultTypeContainer, CriteriaContainer, Row, CriteriaTextArea,
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/actions'
import {AuthContext} from "../../../@logicea/react-auth";
import {Dropdown} from "../QuarantinePolicy/styles";
import {Dimmer, Icon, Message} from "semantic-ui-react";
import TagManager from "react-gtm-module";
import {GTM_KEY} from "../../../static/appConfig";
import {isGTMEnabled} from "../../../lib/util";

const CreateAllowBlockListEntry = () => {

  const [listType, setListType] = useState('allow')
  const [teamId, setTeamId] = useState('')
  const [criteria, setCriteria] = useState('')
  const [resultType, setResultType] = useState('spam_content')
  const [working, setWorking] = useState(false)
  const [dryRunOnly, setDryRunOnly] = useState(true)
  const [showActionStatus, setShowActionStatus] = useState(false)
  const [responseError, setResponseError] = useState()
  const [responseSuccess, setResponseSuccess] = useState()
  const [timeoutId, setTimeoutId] = useState(-1)

  const auth = useContext(AuthContext)
  const context = useContext(DashboardsContext)
  const {
    isCreateAllowBlockListEntryModalOpen,
    setCreateAllowBlockListEntryModalVisibility,
    createAllowBlockListData,
    userTeams,
  } = context

  useEffect(() => {
    if (isCreateAllowBlockListEntryModalOpen) {
      setCriteria('')
      setResultType('spam_content')
    }
  }, [isCreateAllowBlockListEntryModalOpen])

  useEffect(() => {
    setTeamId(createAllowBlockListData.teamId)
    setListType(createAllowBlockListData.listType)
  }, [createAllowBlockListData])

  const handleChangeResultType = (evt, data) => {
    setResultType(data.value)
  }

  const handleChangeCriteria = (evt, data) => {
    setCriteria(data.value)
  }

  const sendRequestToServer = async (dryRunOnly) => {
    setDryRunOnly(dryRunOnly)
    setWorking(true)
    setShowActionStatus(false)
    setResponseError(null)
    const requestData = {
      teamid: teamId,
      csv_data: criteria,
      dry_run: dryRunOnly,
      result_type: resultType,
    }
    let res
    if (listType === 'allow')
      res = await actions.postAllowListEntryAction(requestData, 'add-csv', auth.tokens.accessToken)
    else
      res = await actions.postBlockListEntryAction(requestData, 'add-csv', auth.tokens.accessToken)

    if (isGTMEnabled()) {
      TagManager.initialize({
        gtmId: GTM_KEY,
        dataLayer: {
          event: listType + '_list_entry_added',
          email: userTeams?.data?.authEmail,
          teamid: userTeams?.data?.memberOf,
          license_key: userTeams?.data?.licenseKey,
          team_type: userTeams?.data?.teamType,
          manual: true,
        }
      })
    }

    setWorking(false)
    setShowActionStatus(true)

    if (!res?.error)
      setResponseSuccess(res?.result ?? "Success")
    else
      setResponseError(res?.error)

    if (!res?.error && !dryRunOnly) {
      setTimeoutId(setTimeout(() => {
        handleDismiss()
      }, 5000))
    }
  }

  const handleDismiss = () => {
    clearTimeout(timeoutId)
    setShowActionStatus(false)
    if (!responseError && !dryRunOnly) {
      setCreateAllowBlockListEntryModalVisibility(false)
      if (createAllowBlockListData.successCallback)
        createAllowBlockListData.successCallback(createAllowBlockListData)
    }
  }

  const resultTypeOptions =
    listType === 'allow' ?
      [
        {
          key: 'spam_content',
          text: 'Spam Content',
          value: 'spam_content',
        },
        {
          key: 'phishing_content',
          text: 'Phishing Content',
          value: 'phishing_content',
        },
        {
          key: 'sensitive_content',
          text: 'Sensitive Content',
          value: 'sensitive_content',
        },
        {
          key: 'first_time_sender',
          text: 'First-Time Sender',
          value: 'first_time_sender',
        },
      ]
    :
      [
        {
          key: 'phishing_content',
          text: 'Phishing Content (Danger banner)',
          value: 'phishing_content',
        },
        {
          key: 'spam_content',
          text: 'Spam Content (Caution banner)',
          value: 'spam_content',
        },
      ]

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isCreateAllowBlockListEntryModalOpen}
        onRequestClose={() => setCreateAllowBlockListEntryModalVisibility(false)}
        contentLabel={t({ key: 'MODAL_CREATE_ALLOW_BLOCK_CONTENT_LABEL' })}
        style={{
          overlay: modalStyles.overlay,
          content: modalStyles.content,
        }}
      >
        <Dimmer active={working}>
          <Message icon>
            <Icon name='circle notched' loading />
            <Message.Content>
              <Message.Header>{t({key: 'MODAL_PLEASE_WAIT'})}</Message.Header>
              {t({key: 'MODAL_PROCESSING_REQUEST'})}
            </Message.Content>
          </Message>
        </Dimmer>
        <Dimmer active={showActionStatus}>
          <Message positive={!responseError} onDismiss={handleDismiss} negative={!!responseError}>
            <Message.Header>{t({key: responseError ? 'ERROR' : 'SUCCESS'})}</Message.Header>
            <p>
              {responseError ? responseError : responseSuccess}
            </p>
          </Message>
        </Dimmer>
        <OuterContainer>
          <Title>{t({key: 'MODAL_CREATE_ALLOW_BLOCK_TITLE', variables: {listType: listType === 'allow' ? 'Allow' : 'Block', team: teamId}})}</Title>
          <Divider fitted/>
          <ContentContainer>
            <Row>
              <ResultTypeContainer>
                <ResultTypeLabel>Result Type</ResultTypeLabel>
                <Dropdown selection options={resultTypeOptions} value={resultType} onChange={handleChangeResultType} />
              </ResultTypeContainer>
            </Row>
            <Row>
              <CriteriaContainer>
                <Label>Criteria (CSV list of emails, domains)</Label>
                <CriteriaTextArea placeholder="user@domain.com, company.com" value={criteria} onChange={handleChangeCriteria}/>
              </CriteriaContainer>
            </Row>
          </ContentContainer>
          <CloseButtonTop>
            <CloseIcon onClick={() => setCreateAllowBlockListEntryModalVisibility(false)} />
          </CloseButtonTop>
          <ButtonsWrapper>
            <Button type="button" onClick={() => setCreateAllowBlockListEntryModalVisibility(false)}>{t({ key: 'CANCEL' })}</Button>
            <Button type="button" onClick={() => {sendRequestToServer(true)}}>{t({ key: 'DRY_RUN_ONLY'})}</Button>
            <Button type="button" onClick={() => {sendRequestToServer(false)}} dark>{t({ key: 'MODAL_CREATE_ALLOW_BLOCK_ADD' })}</Button>
          </ButtonsWrapper>
        </OuterContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default CreateAllowBlockListEntry
