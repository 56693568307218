import styled from 'styled-components'
import {
  BACKGROUND_MEDIUM,
  BACKGROUND_LIGHT,
  WHITE,
  DARK_TEXT,
  LINK_TEXT,
} from '../../static/colors'
import nameLogo from '../../assets/logo-on-dark.svg'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${BACKGROUND_MEDIUM};
`

export const Content = styled.div`
  width: 1137px;
  height: 643px;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: row;
  border-radius: 7px;
  box-shadow: 0 9px 24px 1px rgba(0,0,0,0.08);
`

export const Left = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px 0 0 7px;
  background: linear-gradient(135.34deg, #013D85 0%, #00205C 100%);
`

export const NameIcon = styled.img.attrs({
  src: nameLogo,
  alt: 'INKY',
})`
  width: 180px;
  height: 64px;
`

export const Title = styled.div`
  color: ${WHITE};
  font-family: Lato;
  font-size: 30px;
  line-height: 25px;
  margin-top: 18px;
`

export const WebsiteLink = styled.a`
  position: absolute;
  bottom: 43px;
  color: ${WHITE};
  font-family: Lato;
  font-size: 25px;
  line-height: 25px;

  &:hover {
    color: ${WHITE};
  }
`

export const Right = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 7px 7px 0;
  background-color: ${BACKGROUND_LIGHT};
  position: relative;
`

export const Form = styled.form`
  width: 80%;
  max-width: 420px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Label = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 16px;
  align-self: flex-start;
`

export const LabelCenter = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 16px;
  align-self: flex-start;
  text-align: center;
`

export const BottomLinkText = styled.div`
  color: ${LINK_TEXT};
  font-family: Lato;
  font-size: 19px;
  line-height: 23px;
  margin-left: 16px;
  padding-top: 8px;
  position: absolute;
  bottom: 43px;

  &:hover {
    cursor: pointer;
  }
`
