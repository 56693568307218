import React from 'react'
import ReactDOM from 'react-dom'
import { PieChart, Pie, Cell } from 'recharts'
import {
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
} from '../../../static/colors'
import { convertChart } from '../exportHelpers'

const colors = [CHART_SAFE, CHART_CAUTION, CHART_DANGER]

const RADIAN = Math.PI / 180
const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6
  const x = cx + radius * Math.cos(-midAngle * RADIAN) - 10 * Math.sin(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontFamily="Lato"
      fontWeight="bold"
      fontSize={12}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export const exportChart = aggregationData => {

  // Output image size
  const WIDTH = 1024;
  const HEIGHT = 720;

  const chart = (
    <PieChart
      width={WIDTH}
      height={HEIGHT}
      ref={ref => convertChart(ref, WIDTH, HEIGHT)}
    >
      <Pie
        data={aggregationData}
        dataKey="value"
        innerRadius="0%"
        outerRadius="90%"
        // activeIndex={this.state.activeIndex}
        // activeShape={renderActiveShape}
        // onMouseEnter={this.onPieEnter}
        labelLine={false}
        label={CustomLabel}
        isAnimationActive={false}
        paddingAngle={1}
      >
        {
          aggregationData.map((entry, index) => (
            <Cell
              key={`slice-${index}`} // eslint-disable-line react/no-array-index-key
              fill={colors[entry.threatLevel]}
            />
          ))
        }
      </Pie>
    </PieChart>
  )

  // Render chart component into helper div
  const helperDiv = document.createElement('tmp');
  ReactDOM.render(chart, helperDiv);
}
