import styled from 'styled-components'
import { colors, spacing } from 'static/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: ${colors.white};
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`
