import styled from 'styled-components'
import { colors, spacing } from 'static/theme'
import { ReactComponent as ChevronDownSVG } from 'assets/dropdownIcon/chevron-down.svg'
import { ReactComponent as BoxSVG } from 'assets/layout/box.svg'
import { ReactComponent as HorizontalSVG } from 'assets/layout/horizontal.svg'
import { ReactComponent as VerticalSVG } from 'assets/layout/vertical.svg'
import { ReactComponent as PiSVG } from 'assets/layout/pi.svg'
import { ReactComponent as EpsilonSVG } from 'assets/layout/epsilon.svg'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LayoutButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primaryMain};
  padding: ${spacing.xs} 0rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${spacing.xxs} ${spacing.xs2};
  &:hover {
    cursor: pointer;
  }
`

export const ChevronDownIcon = styled(ChevronDownSVG)`
  fill: ${colors.primaryMain};
`
export const BoxIcon = styled(BoxSVG)<{
  selected: boolean
  activeOnList?: boolean
}>`
  fill: ${({ selected, activeOnList }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const HorizontalIcon = styled(HorizontalSVG)<{
  selected: boolean
  activeOnList?: boolean
}>`
  fill: ${({ selected, activeOnList }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const VerticalIcon = styled(VerticalSVG)<{
  selected: boolean
  activeOnList?: boolean
}>`
  fill: ${({ selected, activeOnList }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const PiIcon = styled(PiSVG)<{
  selected: boolean
  activeOnList?: boolean
}>`
  fill: ${({ selected, activeOnList }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const EpsilonIcon = styled(EpsilonSVG)<{
  selected: boolean
  activeOnList?: boolean
}>`
  fill: ${({ selected, activeOnList }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`
