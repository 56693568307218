import React from "react";
import {Button, Icon} from "semantic-ui-react";
import styled from 'styled-components'
import {ACTION_BUTTON_BG, LIGHT_TEXT} from "../../static/colors";

const style = {
  color: `${LIGHT_TEXT}`,
  backgroundColor: `${ACTION_BUTTON_BG}`,
  textShadow: "1px 1px 2px black",
  boxShadow: "1px 1px 2px grey"
}

const CreateButton = ({className, onClick, icon}) => (
  <Button className={className + " circular ui icon button"} onClick={onClick} style={style}>
    <Icon className={"icon " + icon} />
  </Button>
)

const CenteredCreateButton = styled(CreateButton)`
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
`

export {CenteredCreateButton}

