import React, { useState, useCallback } from 'react'
import { I18n } from 'lib/i18n'
import { Tooltip } from 'lib/util'
import { RichTooltip } from 'components/RichToolTip'
import {
  Container,
  LayoutButton,
  DropdownContainer,
  Row,
  ChevronDownIcon,
  BoxIcon,
  HorizontalIcon,
  VerticalIcon,
  PiIcon,
  EpsilonIcon,
} from './styles'

export type LayoutMenuType = {
  layoutMode: 'box' | 'horizontal' | 'vertical' | 'pi' | 'epsilon'
  onLayoutSelect: (name: string) => void
}
export const LayoutMenu = ({
  layoutMode = 'horizontal',
  onLayoutSelect,
}: LayoutMenuType) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleLayoutSelect = (name: LayoutMenuType['layoutMode']) => {
    setIsExpanded(false)
    onLayoutSelect(name)
  }

  const layoutIcon = useCallback(
    ({
      layoutMode,
      selected = false,
      activeOnList = false,
    }: {
      layoutMode: LayoutMenuType['layoutMode']
      selected?: boolean
      activeOnList?: boolean
    }) => {
      switch (layoutMode) {
        case 'box':
          return <BoxIcon selected={selected} activeOnList={activeOnList} />
        case 'horizontal':
          return (
            <HorizontalIcon selected={selected} activeOnList={activeOnList} />
          )
        case 'vertical':
          return (
            <VerticalIcon selected={selected} activeOnList={activeOnList} />
          )
        case 'pi':
          return <PiIcon selected={selected} activeOnList={activeOnList} />
        case 'epsilon':
          return <EpsilonIcon selected={selected} activeOnList={activeOnList} />
        default:
          return null
      }
    },
    []
  )

  return (
    <I18n>
      {({ t }) => (
        <Container data-tip data-for='layoutButton'>
          <RichTooltip
            content={
              <DropdownContainer>
                <Row onClick={() => handleLayoutSelect('box')}>
                  {layoutIcon({
                    layoutMode: 'box',
                    activeOnList: layoutMode === 'box',
                  })}
                </Row>
                <Row onClick={() => handleLayoutSelect('horizontal')}>
                  {layoutIcon({
                    layoutMode: 'horizontal',
                    activeOnList: layoutMode === 'horizontal',
                  })}
                </Row>
                <Row onClick={() => handleLayoutSelect('vertical')}>
                  {layoutIcon({
                    layoutMode: 'vertical',
                    activeOnList: layoutMode === 'vertical',
                  })}
                </Row>
                <Row onClick={() => handleLayoutSelect('pi')}>
                  {layoutIcon({
                    layoutMode: 'pi',
                    activeOnList: layoutMode === 'pi',
                  })}
                </Row>
                <Row onClick={() => handleLayoutSelect('epsilon')}>
                  {layoutIcon({
                    layoutMode: 'epsilon',
                    activeOnList: layoutMode === 'epsilon',
                  })}
                </Row>
              </DropdownContainer>
            }
            open={isExpanded}
            placement='bottom'
            onClose={() => setIsExpanded(false)}
          >
            <LayoutButton onClick={() => setIsExpanded(!isExpanded)}>
              {layoutIcon({ layoutMode: layoutMode, selected: true })}
              <ChevronDownIcon />
            </LayoutButton>
          </RichTooltip>
          <Tooltip left id='layoutButton' delayShow={undefined}>
            <span>{t({ key: 'TOOLTIP_CHANGE_LAYOUT' })}</span>
          </Tooltip>
        </Container>
      )}
    </I18n>
  )
}
