import React, { ReactNode } from 'react'
import { ButtonProps } from '@material-ui/core'
import { MaterialUIButton, CancelText, SubmitText } from './style'

type CustomButtonProps = ButtonProps & {
  cancel?: boolean
  children: ReactNode
}

export const Button = ({
  cancel = false,
  color = cancel ? 'default' : 'secondary',
  variant = cancel ? 'outlined' : 'contained',
  children,
  ...rest
}: CustomButtonProps) => {
  return (
    <MaterialUIButton color={color} variant={variant} {...rest}>
      {cancel ? (
        <CancelText>{children}</CancelText>
      ) : (
        <SubmitText>{children}</SubmitText>
      )}
    </MaterialUIButton>
  )
}
