import styled from 'styled-components'
import {
  Body1DarkGreyRegular,
  HeadlineH1PrimaryMainBold,
  HeadlineH3DarkGreyRegular,
  HeadlineH3PrimaryMainRegular,
  spacing,
} from 'static/theme'

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Title = styled(HeadlineH1PrimaryMainBold)``

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DeleteText = styled(HeadlineH3DarkGreyRegular)`
  margin-top: ${spacing.l};
`

export const ImportWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DashboardInput = styled.input.attrs({
  type: 'file',
  name: 'import',
  accept: 'application/json',
  multiple: false,
})`
  display: none;
`

export const ImportButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacing.m1};
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

export const ImportTextInfo = styled(Body1DarkGreyRegular)`
  margin-top: ${spacing.l};
`

export const ImportText = styled(HeadlineH3PrimaryMainRegular)`
  margin-left: ${spacing.xs};
`

export const DeleteImportedImg = styled.img`
  margin-left: ${spacing.xs};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${spacing.xl};
`
