import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { number as formatNumber } from 'format-message'
import { PieChart, Pie, Cell } from 'recharts'
import {
  colors,
  NumbersLDarkGreyBold,
  NumbersSDarkGreyBold,
  Body1PrimaryDarkRegular,
} from 'static/theme'

type RadialPieCounts = {
  first: number | undefined
  second: number
  third: number
  total: number
}

type RadialPieColors = {
  first: string
  second: string
  third: string
}

type RadialPieProps = {
  type: 'large' | 'small'
  counts: RadialPieCounts
  totalLabel?: string
  colors: RadialPieColors
  isAnimationActive: boolean
}

export const Circle = styled.div<{ type: RadialPieProps['type'] }>`
  width: ${({ type }) => (type === 'large' ? '11rem' : '4rem')};
  height: ${({ type }) => (type === 'large' ? '11rem' : '4rem')};
  border-radius: ${({ type }) => (type === 'large' ? '5.5rem' : '2rem')};
  border: ${({ type }) => (type === 'large' ? '0.5rem' : '0rem')} solid #4c84c8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 ${({ type }) => (type === 'large' ? '3rem' : '0rem')} 0
    ${colors.darkGrey}88;
`

export const Neutral = styled.div<{ type: RadialPieProps['type'] }>`
  width: 100%;
  height: 100%;
  border-radius: ${({ type }) => (type === 'large' ? '5.5rem' : '2rem')};
  border: 0.5rem solid
    ${({ type }) =>
      type === 'large' ? colors.primaryLight : colors.primaryExtraLight};
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`

export const PercentageL = styled(NumbersLDarkGreyBold)``
export const PercentageS = styled(NumbersSDarkGreyBold)``

export const Level = styled(Body1PrimaryDarkRegular)``

const Progress = ({
  progress,
  color,
  type,
}: {
  progress: number
  color: string
  type?: RadialPieProps['type']
}) => {
  const circumference = 2 * 3.14 * 66.5

  return progress > 0 && progress <= 1 ? (
    <svg
      width={type === 'large' ? 180 : 68}
      height={type === 'large' ? 180 : 68}
      viewBox='0 0 180 180'
      xmlns='http://www.w3.org/2000/svg'
      style={{ position: 'absolute', transform: 'rotate(-90deg)' }}
    >
      <motion.circle
        cx='90'
        cy='90'
        r='66.5'
        fill='none'
        stroke={color}
        strokeWidth={type === 'large' ? '8' : '13'}
        strokeLinecap='round'
        strokeDasharray={`0, ${circumference}`}
        animate={{
          strokeDasharray: `${progress * circumference}, ${circumference}`,
        }}
        transition={{ duration: 2 }}
      />
    </svg>
  ) : null
}

const RadialPie = ({
  type,
  counts,
  colors,
  totalLabel,
  isAnimationActive,
}: RadialPieProps) => {
  const { first, second, third, total } = counts
  const radialPieSecondProgress = (second + third) / total
  const radialPieThirdProgress = third / total
  const data: Array<{ key: 'first' | 'second' | 'third'; value: number }> = [
    { key: 'first', value: first ?? 0 },
    { key: 'second', value: second },
    { key: 'third', value: third },
  ]

  return isAnimationActive ? (
    <Circle type={type}>
      <Neutral type={type}>
        {type === 'large' ? (
          <PercentageL>{formatNumber(total)}</PercentageL>
        ) : (
          <PercentageS>{formatNumber(total)}</PercentageS>
        )}
        {type === 'large' && <Level>{totalLabel}</Level>}
        {type === 'small' && counts.first !== undefined ? (
          <Progress type={type} progress={1} color={colors.first} />
        ) : null}
        <Progress
          type={type}
          progress={radialPieSecondProgress ?? 0}
          color={colors.second}
        />
        <Progress
          type={type}
          progress={radialPieThirdProgress ?? 0}
          color={colors.third}
        />
      </Neutral>
    </Circle>
  ) : (
    <Circle type={type}>
      <Neutral type={type}>
        {type === 'large' ? (
          <PercentageL>{formatNumber(total)}</PercentageL>
        ) : (
          <PercentageS>{formatNumber(total)}</PercentageS>
        )}
        {type === 'large' && <Level>{totalLabel}</Level>}
        <PieChart width={type === 'large' ? 160 : 80} height={type === 'large' ? 160 : 80} style={{ position: 'absolute' }}>
          <Pie
            data={data}
            dataKey='value'
            cx={type === 'large' ? 75 : 35}
            cy={type === 'large' ? 75 : 35}
            innerRadius={type === 'large' ? 62 : 22}
            outerRadius={type === 'large' ? 70 : 27}
            fill='transparent'
            stroke='transparent'
            paddingAngle={0}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell fill={colors[entry.key]} />
            ))}
          </Pie>
        </PieChart>
      </Neutral>
    </Circle>
  )
}

export { RadialPie }
