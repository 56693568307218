import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Radio } from 'components/Radio'
import { I18n } from '../../../lib/i18n'
import { spacing } from 'static/theme'

const RadioWrapper = styled.div`
  margin-top: ${spacing.m};
`

const { object, func } = PropTypes

const UnaryInput = ({
  filter,
  onChange,
}) => {
  useEffect(() => {
    onChange(true)
  }, [])

  return (
    <I18n>{({ t }) => (
      <RadioWrapper>
        <Radio
          label={t({ key: filter.name === 'reports' ? 'INPUT_REPORTS' : (filter.name === 'has_attachments' ? 'INPUT_HAS_ATTACHMENTS' : 'INPUT_LINK_CLICKS' )})}
          checked={true}
        />
      </RadioWrapper>
    )}
    </I18n>
  )
}


UnaryInput.propTypes = {
  filter: object,
  onChange: func,
}

export default UnaryInput
