import React from 'react'
import ReactDOM from 'react-dom'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts'
import {
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
  DARK_GREY,
  VISUALIZATION_TICK_DARK,
} from '../../../static/colors'
import I18nProvider from 'lib/i18n/I18nWrapper'
import { convertChart } from '../exportHelpers'
import { parseDate } from '../../../lib/util'
import { I18n } from '../../../lib/i18n'
import { TickText } from './styles'

const DateTick = ({ x, y, payload }) => (
  <I18n>{({ locale, t }) => (
    <g transform={`translate(${x},${y})`}>
      <TickText
        x={0}
        y={0}
        dx={30}
        dy={16}
        textAnchor="end"
        fill={DARK_GREY}
      >
        {t({ key: 'CHART_DATE', variables: { locale, date: new Date(parseDate(payload.value)) } })}
      </TickText>
    </g>
  )}
  </I18n>
)

const CountTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <TickText
      x={0}
      y={4}
      textAnchor="end"
      fill={DARK_GREY}
    >
      {payload.value}
    </TickText>
  </g>
)

export const exportChart = (data) => {
  // Output image size
  const WIDTH = 1920;
  const HEIGHT = 1080;

  const chart = (
    <AreaChart
      width={WIDTH}
      height={HEIGHT}
      data={data}
      ref={ref => convertChart(ref, WIDTH, HEIGHT)}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        allowDataOverflow
        dataKey="date"
        domain={['dataMin', 'dataMax']}
        type="number"
        tick={<DateTick />}
        tickCount={12}
        tickLine={{ stroke: VISUALIZATION_TICK_DARK, strokeWidth: 2.64 }}
        axisLine={false}
      />
      <YAxis
        domain={['dataMin', 'dataMax']}
        axisLine={false}
        tickLine={false}
        tickMargin={16}
        tick={<CountTick />}
        padding={{ top: 20, bottom: 4 }}
      />
      <Area
        type="monotone"
        dataKey="countSafe"
        strokeWidth={0}
        fill={CHART_SAFE}
        fillOpacity={1}
        dot={false}
        isAnimationActive={false}
      />
      <Area
        type="monotone"
        dataKey="countCaution"
        strokeWidth={0}
        fill={CHART_CAUTION}
        fillOpacity={0.9}
        dot={false}
        isAnimationActive={false}
      />
      <Area
        type="monotone"
        dataKey="countDanger"
        strokeWidth={0}
        fill={CHART_DANGER}
        fillOpacity={0.9}
        dot={false}
        isAnimationActive={false}
      />
    </AreaChart>
  )

  // Render chart component into helper div
  const helperDiv = document.createElement('tmp');
  ReactDOM.render(<I18nProvider>{chart}</I18nProvider>, helperDiv);
}
