import styled from 'styled-components'
import {
  HeadlineH1PrimaryMainBold,
  HeadlineH3DarkGreyRegular,
  Body1SecondaryMainRegular,
  spacing,
  colors,
} from 'static/theme'
import { ReactComponent as CloseSVG } from 'assets/widgets/close.svg'

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Title = styled(HeadlineH1PrimaryMainBold)``

export const SelectType = styled(HeadlineH3DarkGreyRegular)`
  margin-top: ${spacing.xs};
`

export const WidgetWrapper = styled.div`
  padding: ${spacing.xs2};
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${spacing.l};
`

export const SelectDeselectButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  &:hover {
    cursor: pointer;
  }
`

export const SelectDeselectAll = styled(Body1SecondaryMainRegular)`
  text-decoration-line: underline;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const CloseIcon = styled(CloseSVG)`
  margin-right: ${spacing.xs};
  fill: ${colors.secondaryMain};
`
