import React, { useState, useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import isNil from 'ramda/src/isNil'
import isEmpty from 'ramda/src/isEmpty'
import { Loader, Icon } from 'semantic-ui-react'
import { AuthContext } from '@logicea/react-auth'
import ReactResizeDetector from 'react-resize-detector'
import { createResource, createCacheWithAsyncInvalidation } from '../../../lib/simple-cache-utils'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/message'
import { noop } from '../../../lib/ramda'
import { I18n } from '../../../lib/i18n'
import ZoomTreeMap from './ZoomTreeMap'
import {
  VISUALIZATION_MARGIN,
} from '../../../static/appConfig'
import {
  LegendWrapper,
  ThreatLevelLegend,
  LegendText,
  LegendDot,
} from '../legendStyles'
import { EmptyResults } from '../EmptyResults'

const { array, func, string, number, bool } = PropTypes

let teamAggCache

const teamAggCacheInvalidator = () => {
  teamAggCache = createCacheWithAsyncInvalidation(teamAggCacheInvalidator)
}
teamAggCache = createCacheWithAsyncInvalidation(teamAggCacheInvalidator)

const TeamAggResource = createResource(
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token }) =>
    actions.aggregateByTeamAction(
      { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode },
      { realm, token }
    ),
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm }) =>
    JSON.stringify({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm })
)

const AsyncTeamTreeMapZoom = ({
  dashboardId,
  dashboardFilters,
  dashboardFilterMode,
  setFilterMode,
  setFilters,
  onCellClick,
  timestamp,
  belongsToUser,
}) => {
  const [dashboard, setDashboard] = useState(null)

  const context = useContext(DashboardsContext)
  const {
    setViewModeTooltip,
  } = context

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  useEffect(() => {
    if (isNil(dashboard)) setDashboard(dashboardId)
    else setDashboard(null)
  }, [dashboardId])

  if (isNil(dashboard)) return null

  const data = TeamAggResource
    .read(teamAggCache, {
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
      timestamp,
      realm,
      token: tokens.accessToken,
    })
  const aggregationData = data.data
  const { networkTook, elasticsearchTook } = data

  return (
    <TeamTreeMapZoomLayout
      elasticsearchTook={elasticsearchTook}
      networkTook={networkTook}
      aggregationData={aggregationData}
      onCellClick={belongsToUser ? onCellClick : () => setViewModeTooltip(true)}
      setFilters={setFilters}
    />
  )
}

const TeamTreeMapZoomLayout = ({
  aggregationData = [],
  onCellClick = noop,
  setFilters = [],
}) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [zoom, setZoom] = useState(false)

  const onResize = (w, h) => {
    setWidth(w)
    setHeight(h)
  }

  return (
    <I18n>{({ t }) => (
      isEmpty(aggregationData) ? (
          <EmptyResults />
      ) : (
        <div style={{
          flex: 1,
          width: '100%',
          height: '100%',
          textAlign: 'center',
          position: 'relative',
        }}
        >
          <LegendWrapper>
            <ThreatLevelLegend>
              <LegendDot threat={2} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 2 } })}
              </LegendText>
              <LegendDot threat={1} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 1 } })}
              </LegendText>
              <LegendDot threat={0} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 0 } })}
              </LegendText>
            </ThreatLevelLegend>
          </LegendWrapper>
          <div style={{
            position: 'absolute',
            top: VISUALIZATION_MARGIN,
            right: VISUALIZATION_MARGIN,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 3,
            padding: 5,
            borderRadius: 4,
          }}
          >
            <Icon name="zoom out" onClick={() => setZoom(!zoom)} />
          </div>
          <div style={{ position: 'absolute' }}>
            <ZoomTreeMap
              width={width - 2 * VISUALIZATION_MARGIN}
              height={height - 2 * VISUALIZATION_MARGIN}
              aggregationData={aggregationData}
              onCellClick={onCellClick}
              setFilters={setFilters}
              zoom={zoom}
            />
          </div>
          <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        </div>
      ))}
    </I18n>
  )
}

const TeamTreeMapZoom = ({ dashboardFilters, ...rest }) => dashboardFilters && (
  <Suspense
    fallback={<Loader active />}
  >
    <AsyncTeamTreeMapZoom dashboardFilters={dashboardFilters} {...rest} />
  </Suspense>
)

AsyncTeamTreeMapZoom.propTypes = {
  dashboardId: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  setFilterMode: string,
  setFilters: array,
  onCellClick: func,
  timestamp: number,
  belongsToUser: bool,
}

TeamTreeMapZoomLayout.propTypes = {
  aggregationData: array,
  onCellClick: func,
  setFilters: array,
}

export default TeamTreeMapZoom
