import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import propEq from 'ramda/src/propEq'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import findIndex from 'ramda/src/findIndex'
import assocPath from 'ramda/src/assocPath'
import { I18n } from '../../../lib/i18n'
import modalStyles, {
  ContentWrapper,
  ButtonsWrapper,
  SmallButton,
  ModalTitle,
  Label,
  Content,
  Table,
  LabelRow,
  HeaderRow,
  PermissionRow,
  Title,
  Cell,
  Checkbox,
  RightArea,
  CloseButton,
  CloseIcon,
} from '../modalStyles'
import {
  DashboardPermissionsContainer,
  DashboardSelectContainer,
  DashboardSelect,
  PermissionsArea,
} from './styles'
import dashboardActions from '../../../requests/dashboard'
import { DashboardsContext } from '../../../providers/Dashboards'
import { permissionsTable, initialDashboardValues } from '../../../static/initialPermissions'

const DashboardPermissions = () => {
  const [localDashboards, setLocalDashboards] = useState([])
  const [localSelectedDashboardIndex, setLocalSelectedDashboardIndex] = useState(null)

  const context = useContext(DashboardsContext)
  const {
    isDashboardPermissionsModalOpen,
    toggleDashboardPermissionsModal,
    handlePermissionsChange,
    selectedDashboardIndex,
    dashboards,
  } = context

  useEffect(() => {
    if (isDashboardPermissionsModalOpen) onModalOpen()
  }, [isDashboardPermissionsModalOpen])

  const onModalOpen = () => {
    if (isNil(dashboards) || isEmpty(dashboards)) return

    const selectedDashboard = dashboards[selectedDashboardIndex]
    const dashboardPermissions = isNil(selectedDashboard) ? initialDashboardValues : selectedDashboard.permissions
    const permissions = isEmpty(dashboardPermissions) ? initialDashboardValues : dashboardPermissions
    setLocalDashboards(assocPath([selectedDashboardIndex, 'permissions'], permissions, dashboards))
    setLocalSelectedDashboardIndex(selectedDashboardIndex)
  }

  const handleDashboardSelect = dashboardId => {
    const newIndex = findIndex(propEq('id', dashboardId), localDashboards)
    const selectedDashboard = localDashboards[newIndex]
    const dashboardPermissions = isNil(selectedDashboard) ? initialDashboardValues : selectedDashboard.permissions
    const permissions = isEmpty(dashboardPermissions) ? initialDashboardValues : dashboardPermissions
    setLocalDashboards(assocPath([newIndex, 'permissions'], permissions, localDashboards))
    setLocalSelectedDashboardIndex(newIndex)
  }

  const handleCheckboxChange = (permission, level) => {
    if (isNil(localDashboards) || isEmpty(localDashboards)) return
    const previousValue = localDashboards[localSelectedDashboardIndex].permissions[permission][level]
    setLocalDashboards(
      assocPath([localSelectedDashboardIndex, 'permissions', permission, level], !previousValue, localDashboards)
    )
  }

  const handleSubmit = async () => {
    const { id, permissions } = localDashboards[localSelectedDashboardIndex]
    await dashboardActions.updateDashboardAction({ id, permissions })
    handlePermissionsChange(localSelectedDashboardIndex, permissions)
    toggleDashboardPermissionsModal()
  }

  const permissions = pathOr(initialDashboardValues, [localSelectedDashboardIndex, 'permissions'], localDashboards)

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isDashboardPermissionsModalOpen}
        onRequestClose={toggleDashboardPermissionsModal}
        contentLabel={t({ key: 'MODAL_PERMISSIONS_CONTENT_LABEL' })}
        style={{
          overlay: modalStyles.overlay,
          content: modalStyles.content,
        }}
      >
        <DashboardPermissionsContainer>
          <ContentWrapper>
            <DashboardSelectContainer>
              <ModalTitle>
                {t({ key: 'MODAL_PERMISSIONS_TITLE' })}
              </ModalTitle>
              <Label>{t({ key: 'MODAL_PERMISSIONS_LABEL' })}</Label>
              <Content>
                {localDashboards.map(d => (
                  <DashboardSelect
                    key={d.id}
                    isSelected={equals(d.id, localDashboards[localSelectedDashboardIndex].id)}
                    onClick={() => handleDashboardSelect(d.id)}
                  >
                    {d.name}
                  </DashboardSelect>
                ))}
              </Content>
            </DashboardSelectContainer>
            <PermissionsArea>
              <Label>{t({ key: 'MODAL_PERMISSIONS_AREA_LABEL' })}</Label>
              <Table>
                <HeaderRow><Title /><Cell>{t({ key: 'MODAL_PERMISSIONS_OWNER_LABEL' })}</Cell><Cell>{t({ key: 'MODAL_PERMISSIONS_GROUP_LABEL' })}</Cell><Cell>{t({ key: 'MODAL_PERMISSIONS_EVERYONE_LABEL' })}</Cell></HeaderRow>
                <PermissionRow>
                  <Title>{t({ key: 'MODAL_PERMISSIONS_AREA_TITLE' })}</Title>
                  <Cell><Checkbox /></Cell>
                  <Cell><Checkbox /></Cell>
                  <Cell><Checkbox /></Cell>
                </PermissionRow>
                {permissionsTable.sections.map(section => (
                  <React.Fragment key={section.name}>
                    <LabelRow>{section.label}</LabelRow>
                    {section.rows.map(row => (
                      <PermissionRow key={row.name}>
                        <Title>{row.label}</Title>
                        <Cell>
                          <Checkbox
                            checked={permissions[row.name].owner}
                            onChange={() => handleCheckboxChange(row.name, 'owner')}
                          />
                        </Cell>
                        <Cell>
                          <Checkbox
                            checked={permissions[row.name].group}
                            onChange={() => handleCheckboxChange(row.name, 'group')}
                          />
                        </Cell>
                        <Cell>
                          <Checkbox
                            checked={permissions[row.name].everyone}
                            onChange={() => handleCheckboxChange(row.name, 'everyone')}
                          />
                        </Cell>
                      </PermissionRow>
                    ))}
                  </React.Fragment>
                ))}
              </Table>
            </PermissionsArea>
            <RightArea>
              <CloseButton onClick={toggleDashboardPermissionsModal}>
                <CloseIcon />
              </CloseButton>
              <ButtonsWrapper>
                <SmallButton type="button" onClick={handleSubmit} dark>{t({ key: 'OK' })}</SmallButton>
                <SmallButton type="button" onClick={toggleDashboardPermissionsModal}>{t({ key: 'CANCEL' })}</SmallButton>
              </ButtonsWrapper>
            </RightArea>
          </ContentWrapper>
        </DashboardPermissionsContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default DashboardPermissions
