import * as R from 'ramda'
import AuthProvider, { AuthContext, authPropType } from './AuthProvider'
import AuthConsumer from './AuthConsumer'
import {
  WithPermission,
  withPermission,
  useWithPermission,
} from './WithPermission'

const hasKey = key => R.contains(key)
const hasAnyKey = keys =>
  R.compose(
    R.flip(R.gt)(0),
    R.length,
    R.intersection(keys)
  )
const hasAllKeys = keys =>
  R.compose(
    R.equals(0),
    R.length,
    R.difference(keys)
  )

const hasRole = role =>
  R.compose(
    hasKey(role),
    R.defaultTo([]),
    R.prop('roles')
  )
const hasAnyRole = roles =>
  R.compose(
    hasAnyKey(roles),
    R.defaultTo([]),
    R.prop('roles')
  )
const hasAllRoles = roles =>
  R.compose(
    hasAllKeys(roles),
    R.defaultTo([]),
    R.prop('roles')
  )

const hasPermission = perm =>
  R.compose(
    hasKey(perm),
    R.defaultTo([]),
    R.prop('permissions')
  )
const hasAnyPermission = perms =>
  R.compose(
    hasAnyKey(perms),
    R.defaultTo([]),
    R.prop('permissions')
  )
const hasAllPermissions = perms =>
  R.compose(
    hasAllKeys(perms),
    R.defaultTo([]),
    R.prop('permissions')
  )

export {
  AuthProvider,
  AuthConsumer,
  AuthContext,
  useWithPermission,
  WithPermission,
  withPermission,
  authPropType,
  hasRole,
  hasAnyRole,
  hasAllRoles,
  hasPermission,
  hasAnyPermission,
  hasAllPermissions,
}
