import styled from 'styled-components'
import {
  MenuItem as MenuItemMaterial,
  Menu as MenuMaterial,
} from '@material-ui/core'
import { spacing, BodyDarkGreyRegular } from 'static/theme'
import { IconButton as Button } from 'components/Buttons/IconButton'

export const IconButton = styled(Button)`
  && {
    margin-left: ${spacing.s};
    height: 2rem;
    width: 2rem;
    padding: ${spacing.xxs};
  }
`

export const Icon = styled.img``

export const Menu = styled(MenuMaterial)`
  && {
  }
`
export const MenuItem = styled(MenuItemMaterial)`
  && {
  }
`
export const MenuTitle = styled(BodyDarkGreyRegular)`
  align-self: center;
`
