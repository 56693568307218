import React from 'react'
import { I18n } from 'lib/i18n'
import { TopHit } from 'components/ReportWidgets/TopHit'

const DomainWithMostThreats = ({
  isAnimationActive,
}: {
  isAnimationActive: boolean
}) => {
  return (
    <I18n>
      {({ t }) => (
        <TopHit
          isAnimationActive={isAnimationActive}
          title={t({ key: 'DOMAIN_WITH_MOST_THREATS' })}
          fieldName='from_domain'
        />
      )}
    </I18n>
  )
}

export { DomainWithMostThreats }
