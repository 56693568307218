import styled from 'styled-components'
import { Form, Radio as SemanticRadio } from 'semantic-ui-react'
import NumericInput from 'react-numeric-input'
import {
  DARK_TEXT,
} from '../../../static/colors'

export const MessageSetSettingContainer = styled.div`
  width: 500px;
  height: 260px;
  max-width: 90vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: 18px;
`
export const DashboardSelectContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`
export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`
export const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
`
export const FormTitle = styled.div`
  color: ${DARK_TEXT};
  font-family: 'Lato';
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
`
export const FormText = styled.div`
  color: ${DARK_TEXT};
  font-family: 'Lato';
  font-size: 12px;
  margin-right: 5px;
`
export const RadioForm = styled(Form)`
  margin: 0px;
  padding: 0px 15px;
`
export const RowWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  line-height: 25px;
`
export const SubFormWrapper = styled.div`
  padding: 0px 20px;
`
export const Radio = styled(SemanticRadio)`
  margin-right: 5px;
`
export const ColumnInput = styled(NumericInput)`
  max-width: 35px;
  height: 25px;
`
