import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import equals from 'ramda/src/equals'
import findIndex from 'ramda/src/findIndex'
import mergeRight from 'ramda/src/mergeRight'
import { I18n } from '../../../lib/i18n'
import modalStyles, {
  SmallContainer,
  ButtonsWrapper,
  Button,
  CloseButton,
  CloseIcon,
} from '../modalStyles'
import {
  ContentWrapper,
  Title,
  Input,
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'

const TagModal = () => {
  const [name, setName] = useState('')

  const context = useContext(DashboardsContext)
  const {
    isTagModalOpen,
    toggleTagModal,
    handleTagModalSubmit,
  } = context

  useEffect(() => {
    if (isTagModalOpen) {
      setName('')
    }
  }, [isTagModalOpen])

  const handleSubmit = () => {
    if (findIndex(equals(name), ['IMPORTANT', 'READ', 'FLAG']) > -1) {
      // TODO: provide a better notfication for this case
      alert('IMPORTANT, REPORT and READ cannot be used for naming a custom tag.') // eslint-disable-line
    } else {
      handleTagModalSubmit([name])
      toggleTagModal()
    }
  }

  const showCloseButton = false

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isTagModalOpen}
        contentLabel={t({ key: 'MODAL_TAG_CONTENT_LABEL' })}
        style={{
          overlay: modalStyles.tagOverlay,
          content: mergeRight(modalStyles.content, { padding: 20 }),
        }}
      >
        <SmallContainer>
          {showCloseButton && (
            <CloseButton onClick={toggleTagModal}>
              <CloseIcon />
            </CloseButton>
          )}
          <ContentWrapper>
            <Title>{t({ key: 'MODAL_TAG_TITLE' })}</Title>
            <Input
              value={name}
              onChange={event => setName(event.target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter') handleSubmit()
              }}
            />
            <ButtonsWrapper>
              <Button type="button" onClick={handleSubmit} dark>{t({ key: 'OK' })}</Button>
              <Button type="button" onClick={toggleTagModal}>{t({ key: 'CANCEL' })}</Button>
            </ButtonsWrapper>
          </ContentWrapper>
        </SmallContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default TagModal
