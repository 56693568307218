import React from 'react'
import { Router } from '@reach/router'
import Login from './Login'
import Unauthorized from './Unauthorized'

const Auth = () => (
  <Router style={{ width: '100%', height: '100%' }}>
    <Login path="/login" />
    <Unauthorized path="/unauthorized" />
  </Router>
)

export default Auth
