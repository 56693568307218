import React, { Component } from 'react'
import PropTypes from 'prop-types'
import equals from 'ramda/src/equals'
import update from 'ramda/src/update'
import find from 'ramda/src/find'
import isNil from 'ramda/src/isNil'
import union from 'ramda/src/union'
import propEq from 'ramda/src/propEq'
import eqProps from 'ramda/src/eqProps'
import unionWith from 'ramda/src/unionWith'
import findIndex from 'ramda/src/findIndex'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import { I18n } from '../../../lib/i18n'
import { TextField } from 'components/TextField'
import messageActions from '../../../requests/message'
import {
  InputGroup,
  InputItem,
  Dot,
} from './styles'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import debounce from "lodash.debounce";
import {INPUT_TEXT_DEBOUNCE_TIME} from "../../../static/appConfig";

const MAX_IP_VALUE = 255

const { object, func, array, string } = PropTypes

class IpInput extends Component {
  state = {
    value: ['', '', '', ''],
    ipsArray: [],
    selectedIps: [],
  }

  constructor(props) {
    super(props);
    this.getIpSuggestionsDebounced = debounce(async (dashboardFilters, setFilters, dashboardFilterMode,
                                                     setFilterMode, realm, token, name, value, selectedIps,
                                                     isKey, term) => {
      const suggestions = await messageActions.getIpSuggestionsAction(
        {
          dashboardFilters: rFilter(f => !propEq('name', name)(f), dashboardFilters),
          setFilters: rFilter(f => !propEq('name', name)(f), setFilters),
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: name,
          term: value,
        }
      )
      const newIps = suggestions.data
      this.setState({
        ipsArray: newIps,
        selectedIps: unionWith(
          equals,
          union(selectedIps, rFilter(isKey(term), newIps)),
          term
        ),
      })
    }, INPUT_TEXT_DEBOUNCE_TIME)
  }

  componentDidMount() {
    const { filter } = this.props
    if (filter && !isNil(filter.term)) this.handleTermChange(filter.term)
  }

  componentDidUpdate(prevProps, prevState) {
    const { filter } = this.props
    const { value } = this.state
    if (eqProps('name', prevProps.filter, filter) && equals(prevState.value, value)) return
    this.handleTermChange(filter.term)
  }

  handleTermChange = async term => {
    const { realm, token, filter, dashboardFilters, setFilters, setFilterMode, dashboardFilterMode } = this.props
    const { name } = filter
    const { value, selectedIps } = this.state
    const isKey = keys => e => findIndex(equals(e), keys) > -1
    this.getIpSuggestionsDebounced(dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token, name,
      value, selectedIps, isKey, term)
  }

  handleRowClick = ip => {
    const { onChange } = this.props
    const { selectedIps } = this.state
    const newIps = symmetricDifference(selectedIps, [ip])
    this.setState({ selectedIps: newIps })
    onChange(newIps.map(r => r))
  }

  handleInputChange = event => {
    const oldValue = this.state.value // eslint-disable-line
    const { name, value } = event.target
    const index = parseInt(name.slice(-1), 10)
    this.setState({
      value: update(index, value > MAX_IP_VALUE ? MAX_IP_VALUE : value, oldValue),
    })
  }

  render() {
    const { filter } = this.props
    const { selectedIps, ipsArray, value } = this.state
    return (
      <I18n>{({ t }) => (
        <Container>
          <div>
            <InputGroup>
              {value.map((cell, index) => (
                <InputItem
                  key={`${filter.name}-item-${index}`} // eslint-disable-line
                >
                  <TextField
                    color='primary'
                    value={cell}
                    key={`${filter.name}-${index}`} // eslint-disable-line
                    name={`${filter.name}-${index}`}
                    onChange={this.handleInputChange}
                    style={{ width: 72 }}
                    type="number"
                    max={MAX_IP_VALUE}
                  />
                  {index < 3 && <Dot>.</Dot>}
                </InputItem>
              ))}
            </InputGroup>
          </div>
          <SelectionHeader>
            <SelectionHeaderItem>
              {ipsArray.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {ipsArray.length}
                  <StatisticsText>({ipsArray.length === 1
                    ? t({ key: 'SELECTION_MATCH' })
                    : t({ key: 'SELECTION_MATCHES' })
                  })</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
            <SelectionHeaderItem right>
              {selectedIps.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {selectedIps.length}
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
          </SelectionHeader>
          <SelectionWrapper>
            <SelectionColumnWrapper>
              <Scrollbar>
                {ipsArray.map(ip => {
                  const isSelected = find(equals(ip), selectedIps)
                  return (
                    <SuggestionWrapper>
                      <Suggestion
                        key={`suggested-${ip}`}
                        isSelected={isSelected}
                        onClick={isSelected ? null
                          : () => this.handleRowClick(ip)
                        }
                      >
                        <SuggestionText>
                          {ip || t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                      </Suggestion>
                    </SuggestionWrapper>
                  )
                })}
              </Scrollbar>
            </SelectionColumnWrapper>
            <Seperator>
              <SeperatorIcon />
            </Seperator>
            <SelectionColumnWrapper right>
              <Scrollbar>
                {selectedIps.map(selectedIp => (
                  <SuggestionWrapper>
                    <Suggestion
                      closeable
                      key={`selected-${selectedIp}`}
                      isSelected={false}
                      onClick={() => this.handleRowClick(selectedIp)}
                    >
                      <SuggestionText>
                        {selectedIp || t({ key: 'SELECTION_NO_VALUE' })}
                      </SuggestionText>
                      <CloseIcon>×</CloseIcon>
                    </Suggestion>
                  </SuggestionWrapper>
                ))}
              </Scrollbar>
            </SelectionColumnWrapper>
          </SelectionWrapper>
        </Container>
      )}
      </I18n>
    )
  }
}

IpInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default IpInput
