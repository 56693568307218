/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import __ from 'ramda/src/__'
import map from 'ramda/src/map'
import keys from 'ramda/src/keys'
import omit from 'ramda/src/omit'
import head from 'ramda/src/head'
import prop from 'ramda/src/prop'
import isNil from 'ramda/src/isNil'
import update from 'ramda/src/update'
import concat from 'ramda/src/concat'
import remove from 'ramda/src/remove'
import pathOr from 'ramda/src/pathOr'
import propEq from 'ramda/src/propEq'
import evolve from 'ramda/src/evolve'
import insert from 'ramda/src/insert'
import append from 'ramda/src/append'
import isEmpty from 'ramda/src/isEmpty'
import indexOf from 'ramda/src/indexOf'
import without from 'ramda/src/without'
import findIndex from 'ramda/src/findIndex'
import assocPath from 'ramda/src/assocPath'
import mergeRight from 'ramda/src/mergeRight'
import { filter as rFilter } from 'ramda/src'
import dashboardActions from '../requests/dashboard'
import initialDashboard from '../static/initialDashboard'
import userActions from '../requests/user'
import { noop } from '../lib/ramda'
import { REFRESH_INTERVAL, MAX_NUM_OF_COLUMNS } from '../static/appConfig'
import quarantineActions from '../requests/quarantine'
import teamActions from '../requests/team'
import eventActions from "../requests/event";

const DEFAULT_SIDEBAR_COLLAPSED = window.innerWidth > 768 ? false : true

let dataRefreshTimerId

const initialOptions = {
  filters: true,
  names: true,
  statistics: true,
  selectors: true,
}

const visualizationOrder = [
  'pie',
  'bar',
  'area',
  // 'tree',
  'stack',
  'line',
  // 'zoom',
  // 'count',
  // 'list',
]

const { node, object } = PropTypes

export const DashboardsContext = React.createContext()

class DashboardsProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      demo: false,
      loading: true,
      autoRefresh: false,
      autoSave: true,
      showMessage: false,
      selectedMessageId: null,
      selectedMessageIndex: null,
      showResults: false,
      options: initialOptions,
      filterMode: 'AND',
      configureMode: true,
      actionTargetType: '',
      action: '',
      layout: 'horizontal',
      targetURL: '',
      dashboards: [],
      timestamp: null,
      messageSetName: '',
      taggedMessage: null,
      selectedSetId: null,
      selectedFilter: null,
      selectedLayoutLevel: 0,
      defaultDashboards: null,
      reportDashboards: null,
      selectedMessageSet: null,
      expandedMessageSetId: null,
      isTagModalOpen: false,
      isTourModalOpen: false,
      isActionModalOpen: false,
      isMessageModalOpen: false,
      isAddWidgetsModalOpen: false,
      isMessageSetExpanded: false,
      selectedDashboardIndex: -1,
      isReportSelected: false,
      isViewModeTooltipOpen: false,
      isConfirmationModalOpen: false,
      isRemediationModalOpen: false,
      isListActionModalOpen: false,
      isReportActionModalOpen: false,
      isFilterEditorModalOpen: false,
      isUserPermissionsModalOpen: false,
      isMessageSetSettingsModalOpen: false,
      isDashboardPermissionsModalOpen: false,
      isQuarantineSettingsModalOpen: false,
      isQuarantineApproversModalOpen: false,
      isQuarantinePolicyModalOpen: false,
      isCreateAllowBlockListEntryModalOpen: false,
      quarantinePolicyData: null,
      quarantineApproverList: {},
      messageSetNumberOfColumns: MAX_NUM_OF_COLUMNS,
      isSidebarCollapsed: DEFAULT_SIDEBAR_COLLAPSED,
      startingRoute: null,
      userPermissions: {},
      userTeams: [],
      listActionModalType: '',
      selectedSettingsPage: '',
      showSettings: false,
      settingsLoading: false, // TODO: Set this when loading data
      selectedSettingsTeam: '',
      selectedDashboard: null,

      // Tag handlers
      handleTagModalSubmit: noop,
      onMessageTag: this.onMessageTag,

      // Action handlers
      addToNextLevel: this.addToNextLevel,
      removeColumn: this.removeColumn,
      addDashboard: this.addDashboard,
      cloneDashboard: this.cloneDashboard,
      uploadDashboard: this.uploadDashboard,
      renameDashboard: this.renameDashboard,
      deleteDashboard: this.deleteDashboard,
      addMessageSet: this.addMessageSet,
      addWidgets: this.addWidgets,
      cloneMessageSet: this.cloneMessageSet,
      renameMessageSet: this.renameMessageSet,
      deleteMessageSet: this.deleteMessageSet,
      addDefaultDashboards: this.addDefaultDashboards,
      deleteQuarantinePolicy: this.deleteQuarantinePolicy,

      // Header handlers
      handleModeChange: this.handleModeChange,
      handleLayoutSelect: this.handleLayoutSelect,
      handleOptionChange: this.handleOptionChange,
      handleDashboardChange: this.handleDashboardChange,
      handleConfigureModeChange: this.handleConfigureModeChange,
      handleDashboardFilterModeChange: this.handleDashboardFilterModeChange,

      // Filter handlers
      handleDashboardFilterEdit: this.handleDashboardFilterEdit,
      toggleDashboardFilterNegation: this.toggleDashboardFilterNegation,
      handleDashboardFilterRemoval: this.handleDashboardFilterRemoval,
      handleMessageSetFilterEdit: this.handleMessageSetFilterEdit,
      toggleMessageSetFilterNegation: this.toggleMessageSetFilterNegation,
      handleMessageSetFilterRemoval: this.handleMessageSetFilterRemoval,
      handleMessageSetFilterModeChange: this.handleMessageSetFilterModeChange,

      // Modal handlers
      toggleTagModal: this.toggleTagModal,
      toggleTourModal: this.toggleTourModal,
      toggleActionModal: this.toggleActionModal,
      toggleMessageModal: this.toggleMessageModal,
      toggleAddWidgetsModal: this.toggleAddWidgetsModal,
      toggleConfirmationModal: this.toggleConfirmationModal,
      setRemediationModalVisibility: this.setRemediationModalVisibility,
      setListActionModalVisibility: this.setListActionModalVisibility,
      setReportActionModalVisibility: this.setReportActionModalVisibility,
      toggleFilterEditorModal: this.toggleFilterEditorModal,
      handlePermissionsChange: this.handlePermissionsChange,
      toggleDashboardPermissionsModal: this.toggleDashboardPermissionsModal,
      toggleMessageSetSettingsModal: this.toggleMessageSetSettingsModal,
      toggleUserPermissionsModal: this.toggleUserPermissionsModal,
      setQuarantineSettingsData: this.setQuarantineSettingsData,
      setQuarantineSettingsModalVisibility: this
        .setQuarantineSettingsModalVisibility,
      setQuarantineApproversModalVisibility: this
        .setQuarantineApproversModalVisibility,
      setQuarantinePolicyData: this.setQuarantinePolicyData,
      setQuarantinePolicyModalVisibility: this
        .setQuarantinePolicyModalVisibility,
      setQuarantineApproverList: this.setQuarantineApproverList,
      setCreateAllowBlockListEntryModalVisibility: this
        .setCreateAllowBlockListEntryModalVisibility,
      setSelectedSettingsTeam: this.setSelectedSettingsTeam,
      setSelectedDashboard: this.setSelectedDashboard,
      handleAddWidgetsModalSubmit: noop,
      handleActionModalSubmit: noop,
      handleFilterEditorModalSubmit: noop,

      // MessageSet handlers
      handleVisualizationChange: this.handleVisualizationChange,
      handleSetLayoutChange: this.handleSetLayoutChange,
      handleMessageSetResize: this.handleMessageSetResize,
      handleMessageSetClick: this.handleMessageSetClick,

      // MessageSet settings
      handleMessageSetNumberOfColumns: this.handleMessageSetNumberOfColumns,

      // General settings
      handleShowVipList: this.handleShowVipList,
      handleShowAllowList: this.handleShowAllowList,
      handleShowBlockList: this.handleShowBlockList,

      // Extras
      handleMenuSectionClick: this.handleMenuSectionClick,
      toggleSidebar: this.toggleSidebar,
      toggleDemoMode: this.toggleDemoMode,
      setAutoRefresh: this.setAutoRefresh,
      toggleAutoSave: this.toggleAutoSave,
      handleFilterEdit: this.handleFilterEdit,
      setViewModeTooltip: this.setViewModeTooltip,
      handleFilterModeChange: this.handleFilterModeChange,
      toggleFilterNegation: this.toggleFilterNegation,
      handleFilterRemoval: this.handleFilterRemoval,
      handleRefreshClick: this.handleRefreshClick,
      reloadMessageList: this.handleReloadMessageList,
      handleMessageClick: this.handleMessageClick,
      handleMessageClose: this.handleMessageClose,
      handleResultsClose: this.handleResultsClose,
      handleLayoutLevelChange: this.handleLayoutLevelChange,
      toggleColumnViewModeChange: this.toggleColumnViewModeChange,
      setListActionData: this.setListActionData,
      setReportActionData: this.setReportActionData,
      setRemediationMessageData: this.setRemediationMessageData,
      addMsgActionedData: this.addMsgActionedData,
      clearMsgActionedData: this.clearMsgActionedData,
      clearMsgListActionedData: this.clearMsgListActionedData,
      setCreateAllowBlockListData: this.setCreateAllowBlockListData,
      remediationMessageData: {},
      listActionData: {},
      reportActionData: {},
      createAllowBlockListData: {},
      msgActionedData: [],
      msgListActionedData: [],
      hideAllowList: this.hideAllowList,
    }
  }

  async componentDidMount() {
    const { auth, initialRoute } = this.props
    const { realm, user, tokens } = auth
    const { selectedDashboardIndex, options, startingRoute } = this.state
    const userTeams = await teamActions.getTeamsAction({realm, token: tokens.accessToken}) || {}
    const dashboards = await dashboardActions.getDashboardsAction({
      realm,
      user: user ? user.email : null,
      token: tokens.accessToken,
    }, userTeams?.data?.memberOf)

    const reportDashboards = await dashboardActions.getReportDashboardsAction({
      realm,
      user: user ? user.email : null,
      token: tokens.accessToken,
    })
    dashboards.hits = concat(dashboards.hits, reportDashboards.hits)

    let reportName = startingRoute
    let activeDashboardIndex = selectedDashboardIndex

    const userPermissions =
      (await userActions.getUserPermissionsAction({
        realm,
        user: user ? user.email : null,
        token: tokens.accessToken,
      })) || {}

    if (initialRoute && initialRoute.startsWith('/reports')) {
      let report = initialRoute.substr(9)
      dashboards.hits.forEach((dashboard, indx) => {
        if (dashboard.pretty_link === report) {
          reportName = report
          activeDashboardIndex = indx
        }
      })
    }

    if (dashboards.unauthorized) {
      if (reportName !== null)
        this.deferSetState({
          startingRoute: reportName,
          selectedDashboardIndex: activeDashboardIndex,
          isReportSelected: true,
        })
      navigate('authentication/unauthorized')
    } else {
      let userHasDashboards = false
      dashboards.hits.forEach(hit => {
        if (hit.user_id === user.email)
          userHasDashboards = true
      })

      let eventData = {
        'actions': ['page-load','show-dashboard'],
        'results': [`id=${dashboards?.hits[activeDashboardIndex]?.id}`, `name=${dashboards?.hits[activeDashboardIndex]?.name}`,
          `creator_id=${dashboards?.hits[activeDashboardIndex]?.user_id}`]
      }
      if (reportName != null)
        eventData.results.push('is_report')
      eventActions.registerEventAction(eventData, {token: tokens?.accessToken, realm})

      this.deferSetState({
        loading: false,
        dashboards: dashboards.hits,
        userPermissions: userPermissions,
        userTeams: userTeams?.data?.accessTo || [],
        userPrimaryTeam: userTeams?.data?.memberOf,
        timestamp: new Date().getTime(),
        options: pathOr(
          options,
          [activeDashboardIndex, 'options'],
          dashboards.hits
        ),
        layout: pathOr(
          'horizontal',
          [activeDashboardIndex, 'layout_mode'],
          dashboards.hits
        ),
        configureMode: pathOr(
          false,
          [activeDashboardIndex, 'configure_mode'],
          dashboards.hits
        ),
        isTourModalOpen: user
          ? !userHasDashboards && !reportName
          : false,
        selectedDashboardIndex: activeDashboardIndex,
        isReportSelected: reportName !== null,
        startingRoute: null,
      })

      const defaultDashboards = await dashboardActions.getDefaultDashboardsAction(
        {
          realm,
          user: user ? user.email : null,
          token: tokens.accessToken,
        }
      )
      this.deferSetState({
        defaultDashboards: defaultDashboards.hits,
        reportDashboards: reportDashboards.hits,
      })

      if (!isEmpty(reportName) && reportName !== null) {
        navigate('/dashboards')
        localStorage.setItem('initialRoute', null)
      }
    }
  }

  componentWillUnmount() {
    this.setAutoRefresh(false)
  }

  setAutoRefresh = (isActive) => {
    this.setState({ autoRefresh: isActive })
    if (isActive) {
      if (!dataRefreshTimerId) {
        dataRefreshTimerId = setInterval(
          this.handleRefreshClick,
          REFRESH_INTERVAL
        )
      }
    } else {
      if (dataRefreshTimerId) clearInterval(dataRefreshTimerId)
      dataRefreshTimerId = undefined
    }
  }

  setViewModeTooltip = (isViewModeTooltipOpen) => {
    this.setState({ isViewModeTooltipOpen })
  }

  handleFilterEdit = (filter, setId) => {
    if (isNil(setId)) this.handleDashboardFilterEdit(filter)
    else this.handleMessageSetFilterEdit(filter, setId)
  }

  toggleFilterNegation = (filter, setId) => {
    if (isNil(setId)) this.toggleDashboardFilterNegation(filter)
    else this.toggleMessageSetFilterNegation(filter, setId)
  }

  handleFilterRemoval = (index, setId) => {
    if (isNil(setId)) this.handleDashboardFilterRemoval(index)
    else this.handleMessageSetFilterRemoval(index, setId)
  }

  handleRefreshClick = () => {
    this.setState({ timestamp: new Date().getTime() })
  }

  handleReloadMessageList = () => {
    this.setState({ timestamp: new Date().getTime() })
  }

  handleMessageClick = (messageId, messageIndex) => {
    // this.setState({ showMessage: true, selectedMessageId: messageId, selectedMessageIndex: messageIndex })
    const { layout } = this.state
    this.setState({
      showMessage: !(layout === 'horizontal' || layout === 'vertical'),
      selectedMessageId: messageId,
      selectedMessageIndex: messageIndex,
    })
    if (layout === 'horizontal' || layout === 'vertical')
      this.toggleMessageModal()
  }

  handleMessageClose = () => {
    this.setState({
      showMessage: false,
      selectedMessageId: null,
      selectedMessageIndex: null,
    })
  }

  handleResultsClose = () => {
    this.setState({ showResults: false, selectedMessageSet: null })
  }

  handleLayoutLevelChange = (level) => {
    this.setState({ selectedLayoutLevel: level })
  }

  toggleColumnViewModeChange = (columnIndex, mode) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const dashboard = dashboards[selectedDashboardIndex]
    const layout = dashboard.message_sets
    const columnId = layout.columnOrder[columnIndex]
    const newColumns = assocPath([columnId, 'mode'], mode, layout.columns)
    const newLayout = {
      sets: layout.sets,
      columns: newColumns,
      set_counter: layout.set_counter,
      column_counter: layout.column_counter,
      columnOrder: layout.columnOrder,
    }

    if (!isReportSelected) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboard.id,
        message_sets: newLayout,
      })
    }
    this.setState({
      dashboards: update(
        selectedDashboardIndex,
        mergeRight(dashboard, { message_sets: newLayout }),
        dashboards
      ),
    })
  }

  setListActionData = (action, messages, successCallback) => {
    this.setState({
      listActionData: {
        action: action,
        messages: messages,
        successCallback: successCallback,
      },
    })
  }

  setReportActionData = (
    reportId,
    reportType,
    teamId,
    status,
    successCallback
  ) => {
    this.setState({
      reportActionData: {
        reportId: reportId,
        reportType: reportType,
        teamId: teamId,
        status: status,
        successCallback: successCallback,
      },
    })
  }

  setRemediationMessageData = (msgActions, messages) => {
    this.setState({
      remediationMessageData: { msgActions: msgActions, messages: messages },
    })
  }

  addMsgActionedData = (actions) => {
    let msgActions = concat(this.state.msgActionedData, actions)
    let msgListActions = concat(this.state.msgListActionedData, actions)
    this.setState({
      msgActionedData: msgActions,
      msgListActionedData: msgListActions,
    })
  }

  clearMsgActionedData = () => {
    let list = this.state.msgActionedData
    list.length = 0
    this.setState({ msgActionedData: list })
  }

  clearMsgListActionedData = () => {
    let list = this.state.msgListActionedData
    list.length = 0
    this.setState({ msgListActionedData: list })
  }

  setCreateAllowBlockListData = (data) => {
    this.setState({ createAllowBlockListData: data })
  }

  toggleDemoMode = () => {
    const { demo } = this.state
    this.setState({ demo: !demo })
  }

  toggleAutoSave = () => {
    const { autoSave } = this.state
    this.setState({ autoSave: !autoSave })
  }

  toggleSidebar = () => {
    const { isSidebarCollapsed } = this.state
    this.setState({ isSidebarCollapsed: !isSidebarCollapsed })
  }

  handleMenuSectionClick = () => {
    this.setState({
      showSettings: false,
      isReportSelected: false,
      selectedDashboardIndex: -1,
    })
  }

  // ActionModal handlers ////////////////////////////////////////////

  addDashboard = async (name, subTitle, description) => {
    const { auth } = this.props
    const { realm, user, tokens } = auth
    const { dashboards } = this.state
    const prevLength = dashboards.length
    const newDashboard = mergeRight(initialDashboard, {
      name,
      subTitle,
      description,
      realm,
      user_id: user ? user.email : null,
      token: tokens.accessToken,
    })
    const data = await dashboardActions.addDashboardAction(newDashboard)
    this.setState(
      {
        dashboards: concat(dashboards, [
          mergeRight(newDashboard, { id: data.id }),
        ]),
      },
      () => this.handleDashboardChange(prevLength, false)
    )
    navigate(`/dashboards/${data.id}`)
  }

  uploadDashboard = async (dashboardBody) => {
    const { auth } = this.props
    const { realm, user, tokens } = auth
    const { dashboards } = this.state
    const prevLength = dashboards.length
    const newDashboard = mergeRight(dashboardBody, {
      realm,
      user_id: user ? user.email : null,
      token: tokens.accessToken,
    })
    const data = await dashboardActions.addDashboardAction(newDashboard)
    this.setState(
      {
        dashboards: concat(dashboards, [
          mergeRight(newDashboard, { id: data.id }),
        ]),
      },
      () => this.handleDashboardChange(prevLength, false)
    )
    navigate(`/dashboards/${data.id}`)
  }

  addDefaultDashboards = async (defaultDashboards) => {
    const { auth } = this.props
    const { realm, user, tokens } = auth
    const { dashboards } = this.state
    const prevLength = dashboards.length

    let dd = defaultDashboards
    let newDashboards = []
    while (dd.length > 0) {
      const newDashboard = mergeRight(dd[0], {
        realm,
        user_id: user ? user.email : null,
        token: tokens.accessToken,
      })
      const data = await dashboardActions.addDashboardAction(newDashboard) // eslint-disable-line no-await-in-loop
      newDashboards.push(mergeRight(newDashboard, { id: data.id }))
      dd = remove(0, 1, dd)
    }

    this.setState(
      {
        dashboards: concat(dashboards, newDashboards),
      },
      () => this.handleDashboardChange(prevLength, false)
    )
  }

  deleteQuarantinePolicy = async () => {
    let policy = this.state.quarantinePolicyData.name
    const { auth } = this.props
    if (policy && this.state.quarantineSettingsData) {
      if (policy in this.state.quarantineSettingsData['policies']) {
        let settings = this.state.quarantineSettingsData
        delete settings.policies[policy]
        this.setState({ quarantineSettingsData: settings })
        quarantineActions.deleteQuarantinePolicyAction('inky', auth, policy)
      }
    }
  }

  renameDashboard = async (name, subTitle, description) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const renamedDashboard = mergeRight(dashboards[selectedDashboardIndex], {
      name,
      subTitle,
      description,
    })
    if (!isReportSelected) {
      await dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: renamedDashboard.id,
        name,
        subTitle,
        description,
      })
    }

    this.setState({
      dashboards: update(selectedDashboardIndex, renamedDashboard, dashboards),
    })
  }

  cloneDashboard = async (name) => {
    const { auth } = this.props
    const { realm, user, tokens } = auth
    const { dashboards, selectedDashboardIndex } = this.state
    const prevLength = dashboards.length
    const newDashboard = mergeRight(dashboards[selectedDashboardIndex], {
      name,
      realm,
      user_id: user ? user.email : null,
      token: tokens.accessToken,
    })
    const data = await dashboardActions.addDashboardAction(newDashboard)
    this.setState(
      {
        dashboards: concat(dashboards, [
          mergeRight(newDashboard, { id: data.id }),
        ]),
      },
      () => this.handleDashboardChange(prevLength, false)
    )
    navigate(`/dashboards/${data.id}`)
  }

  deleteDashboard = async () => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    if (!isReportSelected) {
      await dashboardActions.deleteDashboardAction(
        dashboards[selectedDashboardIndex].id,
        realm,
        tokens.accessToken
      )
    }

    this.setState(
      {
        dashboards: remove(selectedDashboardIndex, 1, dashboards),
      },
      () => this.handleDashboardChange(0, false)
    )
    navigate('/dashboards')
  }

  renameMessageSet = async (name) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const {
      dashboards,
      selectedDashboardIndex,
      selectedSetId,
      isReportSelected,
    } = this.state
    const dashboard = dashboards[selectedDashboardIndex]
    const layout = dashboard.message_sets
    const newMessageSets = assocPath(
      [selectedSetId, 'content'],
      name,
      layout.sets
    )
    const newLayout = {
      sets: newMessageSets,
      columns: layout.columns,
      set_counter: layout.set_counter,
      column_counter: layout.column_counter,
      columnOrder: layout.columnOrder,
    }
    if (!isReportSelected) {
      await dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboard.id,
        message_sets: newLayout,
      })
    }
    this.setState({
      dashboards: update(
        selectedDashboardIndex,
        mergeRight(dashboard, { message_sets: newLayout }),
        dashboards
      ),
    })
  }

  addToNextLevel = async (index, filters, message) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const {
      dashboards,
      selectedDashboardIndex,
      messageSetNumberOfColumns,
      isReportSelected,
    } = this.state
    const dashboard = dashboards[selectedDashboardIndex]
    const layout = dashboard.message_sets
    const prevSetLength = Object.keys(layout.sets || {}).length
    const setCounter = layout.set_counter || prevSetLength
    const prevColumnLength = layout.columnOrder ? layout.columnOrder.length : 0
    const columnCounter = layout.column_counter || prevColumnLength
    const newSetId = `set-${setCounter + 1}`
    const visualization =
      index === -1
        ? visualizationOrder[prevSetLength % visualizationOrder.length]
        : 'list'
    const newMessageSet = {
      filters,
      content: message ? `${message.threatLevel}${message.subject}` : '',
      id: newSetId,
      messageId: message ? { index: message.index, id: message.id } : null,
      visualization: message ? 'message' : visualization,
    }
    const newMessageSets = mergeRight(layout.sets, {
      [newSetId]: newMessageSet,
    })

    if (
      index + 1 > prevColumnLength - 1 &&
      index + 1 < messageSetNumberOfColumns
    ) {
      const newColumnId = `column-${columnCounter + 1}`
      const newColumn = {
        id: newColumnId,
        setIds: [newSetId],
        mode:
          layout.columnOrder && layout.columnOrder.length > 0
            ? 'tabs'
            : 'tiles',
      }
      const newColumns = mergeRight(layout.columns, {
        [newColumnId]: newColumn,
      })
      const newLayout = {
        sets: newMessageSets,
        columns: newColumns,
        set_counter: setCounter + 1,
        column_counter: columnCounter + 1,
        columnOrder: concat(layout.columnOrder || [], [newColumnId]),
      }
      if (!isReportSelected) {
        await dashboardActions.updateDashboardAction({
          realm,
          token: tokens.accessToken,
          id: dashboard.id,
          message_sets: newLayout,
        })
      }
      this.setState({
        selectedLayoutLevel: layout.columnOrder ? layout.columnOrder.length : 0,
        dashboards: update(
          selectedDashboardIndex,
          mergeRight(dashboard, { message_sets: newLayout }),
          dashboards
        ),
      })
    } else {
      const columnIndex =
        index + 1 > messageSetNumberOfColumns - 1 ? index : index + 1
      const newColumns = evolve(
        { [layout.columnOrder[columnIndex]]: { setIds: append(newSetId) } },
        layout.columns
      )
      const newLayout = {
        sets: newMessageSets,
        columns: newColumns,
        set_counter: setCounter + 1,
        column_counter: layout.column_counter,
        columnOrder: layout.columnOrder,
      }
      if (!isReportSelected) {
        await dashboardActions.updateDashboardAction({
          realm,
          token: tokens.accessToken,
          id: dashboard.id,
          message_sets: newLayout,
        })
      }
      this.setState({
        selectedLayoutLevel: columnIndex,
        dashboards: update(
          selectedDashboardIndex,
          mergeRight(dashboard, { message_sets: newLayout }),
          dashboards
        ),
      })
    }
  }

  removeColumn = async (index) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const dashboard = dashboards[selectedDashboardIndex]
    const layout = dashboard.message_sets
    const columnId = layout.columnOrder[index]
    const oldSetIds = layout.columns[columnId].setIds
    const newLayout = {
      sets: omit(oldSetIds, layout.sets),
      columns: omit([columnId], layout.columns),
      set_counter: layout.set_counter,
      column_counter: layout.column_counter,
      columnOrder: remove(index, 1, layout.columnOrder),
    }
    // await dashboardActions.replaceLayoutAction(dashboard.id, newLayout)
    if (!isReportSelected) {
      await dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboard.id,
        message_sets: newLayout,
      })
    }
    this.setState({
      selectedLayoutLevel: index - 1 < 0 ? 0 : index - 1,
      dashboards: update(
        selectedDashboardIndex,
        mergeRight(dashboard, { message_sets: newLayout }),
        dashboards
      ),
    })
  }

  addWidgets = async (widgets) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const dashboard = dashboards[selectedDashboardIndex]
    const layout = dashboard.message_sets
    const prevLength = Object.keys(layout.sets || {}).length
    const setCounter = layout.set_counter || prevLength
    const newMessageSetsArray = widgets.map((w, i) => {
      const newSetId = `set-${setCounter + i + 1}`
      return {
        id: newSetId,
        content: '',
        filters: [],
        visualization: w,
      }
    })
    const newMessageSets = mergeRight(
      layout.sets,
      Object.assign(
        {},
        ...newMessageSetsArray.map((item) => ({ [item.id]: item }))
      )
    )

    let newLayout
    if (
      isEmpty(layout) ||
      isEmpty(layout.columnOrder) ||
      layout.set_counter === 0
    ) {
      const prevColumnLength = layout.columnOrder ? layout.columnOrder.length : 0
      const columnCounter = layout.column_counter || prevColumnLength
      const newColumnId = `column-${columnCounter + 1}`
      const newColumn = {
        id: newColumnId,
        setIds: newMessageSetsArray.map((item) => item.id),
        mode:
          layout.columnOrder && layout.columnOrder.length > 0
            ? 'tabs'
            : 'tiles',
      }
      const newColumns = { [newColumnId]: newColumn }
      newLayout = {
        sets: newMessageSets,
        columns: newColumns,
        set_counter: setCounter + newMessageSetsArray.length,
        column_counter: columnCounter + 1,
        columnOrder: concat(layout.columnOrder || [], [newColumnId]),
      }
    } else {
      const newColumns = evolve(
        {
          [head(layout.columnOrder)]: {
            setIds: concat(__, newMessageSetsArray.map((item) => item.id)),
          },
        },
        layout.columns
      )
      newLayout = {
        sets: newMessageSets,
        columns: newColumns,
        set_counter: setCounter + newMessageSetsArray.length,
        column_counter: layout.column_counter,
        columnOrder: layout.columnOrder,
      }
    }

    if (!isReportSelected) {
      await dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboard.id,
        message_sets: newLayout,
      })
    }
    this.setState({
      dashboards: update(
        selectedDashboardIndex,
        mergeRight(dashboard, { message_sets: newLayout }),
        dashboards
      ),
    })
  }

  addMessageSet = async () => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const dashboard = dashboards[selectedDashboardIndex]
    const layout = dashboard.message_sets

    if (
      isEmpty(layout) ||
      isEmpty(layout.columnOrder) ||
      layout.set_counter === 0
    ) {
      this.addToNextLevel.call(this, -1, [])
      return
    }

    const prevLength = Object.keys(layout.sets || {}).length
    const setCounter = layout.set_counter || prevLength
    const newSetId = `set-${setCounter + 1}`
    const newMessageSet = {
      id: newSetId,
      content: '',
      filters: [],
      visualization: visualizationOrder[prevLength % visualizationOrder.length],
    }
    const newMessageSets = mergeRight(layout.sets, {
      [newSetId]: newMessageSet,
    })
    const newColumns = evolve(
      { [head(layout.columnOrder)]: { setIds: append(newSetId) } },
      layout.columns
    )
    const newLayout = {
      sets: newMessageSets,
      columns: newColumns,
      set_counter: setCounter + 1,
      column_counter: layout.column_counter,
      columnOrder: layout.columnOrder,
    }
    if (!isReportSelected) {
      await dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboard.id,
        message_sets: newLayout,
      })
    }
    this.setState({
      dashboards: update(
        selectedDashboardIndex,
        mergeRight(dashboard, { message_sets: newLayout }),
        dashboards
      ),
    })
  }

  cloneMessageSet = async (name) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const {
      dashboards,
      selectedDashboardIndex,
      selectedSetId,
      isReportSelected,
    } = this.state
    const dashboard = dashboards[selectedDashboardIndex]
    const layout = dashboard.message_sets
    const prevLength = Object.keys(layout.sets).length
    const setCounter = layout.set_counter || prevLength
    const newSetId = `set-${setCounter + 1}`
    const newMessageSets = mergeRight(layout.sets, {
      [newSetId]: mergeRight(layout.sets[selectedSetId], {
        id: newSetId,
        content: name,
      }),
    })
    const addAfterOrigin = (ids) => {
      const i = indexOf(selectedSetId, ids)
      if (i > -1) return insert(i + 1, newSetId, ids)
      else return ids
    }
    const newColumns = map(evolve({ setIds: addAfterOrigin }), layout.columns)
    const newLayout = {
      sets: newMessageSets,
      columns: newColumns,
      set_counter: setCounter + 1,
      column_counter: layout.column_counter,
      columnOrder: layout.columnOrder,
    }
    if (!isReportSelected) {
      await dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboard.id,
        message_sets: newLayout,
      })
    }
    this.setState({
      dashboards: update(
        selectedDashboardIndex,
        mergeRight(dashboard, { message_sets: newLayout }),
        dashboards
      ),
    })
  }

  deleteMessageSet = async (directSetId) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const {
      dashboards,
      selectedDashboardIndex,
      selectedSetId,
      isReportSelected,
    } = this.state
    const setId = directSetId || selectedSetId
    const dashboard = dashboards[selectedDashboardIndex]
    const layout = dashboard.message_sets
    const newMessageSets = omit([setId], layout.sets)
    const newColumns = map(evolve({ setIds: without([setId]) }), layout.columns)
    const emptyColumns = rFilter(
      (column) => isEmpty(prop('setIds', column)),
      newColumns
    )
    const newColumnOrder = without(keys(emptyColumns), layout.columnOrder)
    const newLayout = {
      sets: newMessageSets,
      columns: omit(keys(emptyColumns), newColumns),
      set_counter: layout.set_counter,
      column_counter: layout.column_counter,
      columnOrder: newColumnOrder,
    }
    // await dashboardActions.replaceLayoutAction(dashboard.id, newLayout)

    if (!isReportSelected) {
      await dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboard.id,
        message_sets: newLayout,
      })
    }
    this.setState({
      selectedLayoutLevel: newColumnOrder.length - 1,
      dashboards: update(
        selectedDashboardIndex,
        mergeRight(dashboard, { message_sets: newLayout }),
        dashboards
      ),
    })
  }

  // Header handlers ////////////////////////////////////////////

  handleModeChange = (mode, value) => {
    const modeNames = {
      filterMode: 'filter_mode',
      configureMode: 'configureMode',
      layout: 'layout_mode',
    }
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex } = this.state
    const authUserId = pathOr(null, ['user', 'email'], auth)
    const dashboardUserId = pathOr(
      '',
      [selectedDashboardIndex, 'user_id'],
      dashboards
    )
    const belongsToUser = authUserId === dashboardUserId
    this.setState({ [mode]: value })
    this.deferSetState({
      dashboards: assocPath(
        [selectedDashboardIndex, modeNames[mode]],
        value,
        dashboards
      ),
    })
    if (belongsToUser) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboards[selectedDashboardIndex].id,
        [modeNames[mode]]: value,
      })
    }
  }

  handleDashboardFilterModeChange = (value) => {
    this.handleModeChange('filterMode', value)
  }

  handleConfigureModeChange = (value) => {
    this.handleModeChange('configureMode', value)
  }

  handleLayoutSelect = (value) => {
    this.handleModeChange('layout', value)
  }

  handleOptionChange = (option) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const {
      options,
      dashboards,
      selectedDashboardIndex,
      isReportSelected,
    } = this.state
    const newOptions = mergeRight(options, { [option]: !options[option] })
    this.setState({ options: newOptions })
    this.deferSetState({
      dashboards: assocPath(
        [selectedDashboardIndex, 'options'],
        newOptions,
        dashboards
      ),
    })
    if (!isReportSelected) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboards[selectedDashboardIndex].id,
        options: newOptions,
      })
    }
  }

  handleDashboardChange = (index, isReport) => {
    const { auth } = this.props
    const { dashboards } = this.state
    const { realm, tokens } = auth
    if (isEmpty(dashboards)) return
    const { options, configure_mode, layout_mode } = dashboards[index] // eslint-disable-line
    let eventData = {
      'actions': ['select','show-dashboard'],
      'results': [`id=${dashboards[index].id}`, `name=${dashboards[index].name}`, `creator_id=${dashboards[index].user_id}`]
    }
    if (isReport)
      eventData.results.push('is_report')
    eventActions.registerEventAction(eventData, {token: tokens?.accessToken, realm})
    this.setState({
      showResults: false,
      selectedMessageSet: null,
      selectedDashboardIndex: index,
      isReportSelected: isReport,
      options: isEmpty(options) || isNil(options) ? initialOptions : options,
      configureMode: configure_mode,
      layout: layout_mode || 'horizontal', // eslint-disable-line,
      showSettings: false,
    })
    this.handleRefreshClick()
  }

  handleShowVipList = () => {
    this.setState({
      selectedSettingsPage: 'viplist',
      showSettings: true,
      isReportSelected: false,
      selectedDashboardIndex: -1,
    })
  }

  handleShowAllowList = () => {
    this.setState({
      selectedSettingsPage: 'allowlist',
      showSettings: true,
      isReportSelected: false,
      selectedDashboardIndex: -1,
    })
  }

  hideAllowList = () => {
    this.setState({
      showSettings: false,
      selectedSettingsPage: null,
      selectedDashboardIndex: 0,
    })
  }

  handleShowBlockList = () => {
    this.setState({
      selectedSettingsPage: 'blocklist',
      showSettings: true,
      isReportSelected: false,
      selectedDashboardIndex: -1,
    })
  }

  // Filter handlers ////////////////////////////////////////////

  handleMessageSetFilterModeChange = (value, setId) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const dashboardId = pathOr('', [selectedDashboardIndex, 'id'], dashboards)
    const messageSets = pathOr(
      [],
      [selectedDashboardIndex, 'message_sets'],
      dashboards
    )
    this.deferSetState({
      dashboards: assocPath(
        [selectedDashboardIndex, 'message_sets', 'sets', setId, 'filterMode'],
        value,
        dashboards
      ),
    })
    if (!isReportSelected) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboardId,
        message_sets: assocPath(
          ['sets', setId, 'filterMode'],
          value,
          messageSets
        ),
      })
    }
  }

  handleFilterModeChange = (value, setId) => {
    if (setId) this.handleMessageSetFilterModeChange(value, setId)
    else this.handleDashboardFilterModeChange(value)
  }

  handleDashboardFilterEdit = (filter) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const prevFilters = pathOr(
      [],
      [selectedDashboardIndex, 'filters'],
      dashboards
    )
    const dashboardId = pathOr('', [selectedDashboardIndex, 'id'], dashboards)
    const filterIndex = findIndex(propEq('name', filter.name), prevFilters)
    const newFilters =
      filterIndex > -1
        ? update(filterIndex, filter, prevFilters)
        : concat(prevFilters, [filter])
    this.deferSetState({
      dashboards: assocPath(
        [selectedDashboardIndex, 'filters'],
        newFilters,
        dashboards
      ),
    })
    if (!isReportSelected) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboardId,
        filters: newFilters,
      })
    }
  }

  toggleDashboardFilterNegation = (filter) => {
    this.handleDashboardFilterEdit(mergeRight(filter, { not: !filter.not }))
  }

  handleDashboardFilterRemoval = (index) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const dashboardId = pathOr('', [selectedDashboardIndex, 'id'], dashboards)
    const prevFilters = pathOr(
      [],
      [selectedDashboardIndex, 'filters'],
      dashboards
    )
    const newFilters = remove(index, 1, prevFilters)
    this.deferSetState({
      dashboards: assocPath(
        [selectedDashboardIndex, 'filters'],
        newFilters,
        dashboards
      ),
    })
    if (!isReportSelected) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboardId,
        filters: newFilters,
      })
    }
  }

  handleMessageSetFilterEdit = (filter, setId) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const prevFilters = pathOr(
      [],
      [selectedDashboardIndex, 'message_sets', 'sets', setId, 'filters'],
      dashboards
    )
    const messageSets = pathOr(
      [],
      [selectedDashboardIndex, 'message_sets'],
      dashboards
    )
    const dashboardId = pathOr('', [selectedDashboardIndex, 'id'], dashboards)
    const filterIndex = findIndex(propEq('name', filter.name), prevFilters)
    const newFilters =
      filterIndex > -1
        ? update(filterIndex, filter, prevFilters)
        : concat(prevFilters, [filter])
    this.deferSetState({
      dashboards: assocPath(
        [selectedDashboardIndex, 'message_sets', 'sets', setId, 'filters'],
        newFilters,
        dashboards
      ),
    })

    if (!isReportSelected) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboardId,
        message_sets: assocPath(
          ['sets', setId, 'filters'],
          newFilters,
          messageSets
        ),
      })
    }
  }

  toggleMessageSetFilterNegation = (filter, setId) => {
    this.handleMessageSetFilterEdit(
      mergeRight(filter, { not: !filter.not }),
      setId
    )
  }

  handleMessageSetFilterRemoval = (index, setId) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex, isReportSelected } = this.state
    const dashboardId = pathOr('', [selectedDashboardIndex, 'id'], dashboards)
    const messageSets = pathOr(
      [],
      [selectedDashboardIndex, 'message_sets'],
      dashboards
    )
    const prevFilters = pathOr(
      [],
      [selectedDashboardIndex, 'message_sets', 'sets', setId, 'filters'],
      dashboards
    )
    const newFilters = remove(index, 1, prevFilters)
    this.deferSetState({
      dashboards: assocPath(
        [selectedDashboardIndex, 'message_sets', 'sets', setId, 'filters'],
        newFilters,
        dashboards
      ),
    })
    if (!isReportSelected) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboardId,
        message_sets: assocPath(
          ['sets', setId, 'filters'],
          newFilters,
          messageSets
        ),
      })
    }
  }

  // Modal handlers ////////////////////////////////////////////
  toggleTagModal = (handleTagModalSubmit = noop) => {
    const { isTagModalOpen } = this.state
    this.setState({
      isTagModalOpen: !isTagModalOpen,
      handleTagModalSubmit,
    })
  }

  toggleTourModal = () => {
    const { isTourModalOpen } = this.state
    this.setState({ isTourModalOpen: !isTourModalOpen })
  }

  toggleActionModal = (handleActionModalSubmit) => (action, actionTargetType, setId) => {
    const { isActionModalOpen, selectedDashboard } = this.state
    if (isActionModalOpen) {
      this.setState({
        action: '',
        actionTargetType: '',
        isActionModalOpen: false,
        messageSetName: '',
        handleActionModalSubmit: noop,
      })
    } else {
      this.setState({
        action: action || null,
        actionTargetType: actionTargetType || null,
        isActionModalOpen: true,
        selectedSetId: setId,
        messageSetName: pathOr(
          '',
          ['message_sets', 'sets', setId, 'content'],
          selectedDashboard
        ),
        handleActionModalSubmit,
      })
    }
  }

  toggleMessageModal = () => {
    const { isMessageModalOpen } = this.state
    this.setState({ isMessageModalOpen: !isMessageModalOpen })
  }

  toggleAddWidgetsModal = (handleAddWidgetsModalSubmit) => {
    const { isAddWidgetsModalOpen } = this.state
    if (isAddWidgetsModalOpen) {
      this.setState({ isAddWidgetsModalOpen: false, handleAddWidgetsModalSubmit: noop })
    } else {
      this.setState({ isAddWidgetsModalOpen: true, handleAddWidgetsModalSubmit })
    }
  }

  toggleConfirmationModal = (url) => {
    const { isConfirmationModalOpen } = this.state
    this.setState({
      targetURL: url,
      isConfirmationModalOpen: !isConfirmationModalOpen,
    })
  }

  setRemediationModalVisibility = (visible) => {
    this.setState({
      isRemediationModalOpen: visible,
    })
  }

  setListActionModalVisibility = (visible, listType) => {
    this.setState({
      isListActionModalOpen: visible,
      listActionModalType: listType,
    })
  }

  setReportActionModalVisibility = (visible) => {
    this.setState({
      isReportActionModalOpen: visible,
    })
  }

  toggleFilterEditorModal = (selectedFilter, setId, handleFilterEditorModalSubmit) => {
    const { isFilterEditorModalOpen } = this.state
    if (isFilterEditorModalOpen) {
      this.setState({
        isFilterEditorModalOpen: false,
        handleFilterEditorModalSubmit: noop
      })
    } else {
      this.setState({
        selectedFilter,
        selectedSetId: setId,
        isFilterEditorModalOpen: true,
        handleFilterEditorModalSubmit
      })
    }
  }

  handlePermissionsChange = (index, permissions) => {
    const { dashboards } = this.state
    this.deferSetState({
      dashboards: assocPath([index, 'permissions'], permissions, dashboards),
    })
  }

  toggleDashboardPermissionsModal = () => {
    const { isDashboardPermissionsModalOpen } = this.state
    this.setState({
      isDashboardPermissionsModalOpen: !isDashboardPermissionsModalOpen,
    })
  }

  toggleMessageSetSettingsModal = () => {
    const { isMessageSetSettingsModalOpen } = this.state
    this.setState({
      isMessageSetSettingsModalOpen: !isMessageSetSettingsModalOpen,
    })
  }

  toggleUserPermissionsModal = () => {
    const { isUserPermissionsModalOpen } = this.state
    this.setState({ isUserPermissionsModalOpen: !isUserPermissionsModalOpen })
  }

  setQuarantineSettingsData = (data) => {
    this.setState({ quarantineSettingsData: data })
  }

  setQuarantineSettingsModalVisibility = (visible) => {
    this.setState({ isQuarantineSettingsModalOpen: visible })
  }

  setCreateAllowBlockListEntryModalVisibility = (visible) => {
    this.setState({ isCreateAllowBlockListEntryModalOpen: visible })
  }

  setQuarantineApproversModalVisibility = (visible) => {
    this.setState({ isQuarantineApproversModalOpen: visible })
  }

  setQuarantinePolicyData = (data) => {
    this.setState({ quarantinePolicyData: data })
  }

  setQuarantinePolicyModalVisibility = (visible) => {
    this.setState({ isQuarantinePolicyModalOpen: visible })
  }

  setQuarantineApproverList = (list) => {
    this.setState({ quarantineApproverList: list })
  }

  setSelectedSettingsTeam = (team) => {
    this.setState({ selectedSettingsTeam: team })
  }

  setSelectedDashboard = (dashboard) => {
    this.setState({ selectedDashboard: dashboard })
  }

  // MessageSet handlers ////////////////////////////////////////////

  handleVisualizationChange = (visualization, setId) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex } = this.state
    const dashboardId = pathOr('', [selectedDashboardIndex, 'id'], dashboards)
    const messageSets = pathOr(
      [],
      [selectedDashboardIndex, 'message_sets'],
      dashboards
    )
    const authUserId = pathOr(null, ['user', 'email'], auth)
    const dashboardUserId = pathOr(
      '',
      [selectedDashboardIndex, 'user_id'],
      dashboards
    )
    const belongsToUser = authUserId === dashboardUserId
    this.deferSetState({
      dashboards: assocPath(
        [
          selectedDashboardIndex,
          'message_sets',
          'sets',
          setId,
          'visualization',
        ],
        visualization,
        dashboards
      ),
    })
    if (belongsToUser) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboardId,
        message_sets: assocPath(
          ['sets', setId, 'visualization'],
          visualization,
          messageSets
        ),
      })
    }
  }

  handleSetLayoutChange = (layout) => {
    const { auth } = this.props
    const { realm, tokens } = auth
    const { dashboards, selectedDashboardIndex } = this.state
    const authUserId = pathOr(null, ['user', 'email'], auth)
    const dashboardUserId = pathOr(
      '',
      [selectedDashboardIndex, 'user_id'],
      dashboards
    )
    const belongsToUser = authUserId === dashboardUserId
    this.deferSetState({
      dashboards: assocPath(
        [selectedDashboardIndex, 'message_sets'],
        layout,
        dashboards
      ),
    })
    if (belongsToUser) {
      dashboardActions.updateDashboardAction({
        realm,
        token: tokens.accessToken,
        id: dashboards[selectedDashboardIndex].id,
        message_sets: layout,
      })
    }
  }

  handleMessageSetNumberOfColumns = (numberOfColumns) => {
    this.setState({
      messageSetNumberOfColumns: numberOfColumns,
    })
  }

  handleMessageSetResize = (set) => {
    const { isMessageSetExpanded } = this.state
    this.setState({
      showResults: false,
      selectedMessageSet: false,
      isMessageSetExpanded: !isMessageSetExpanded,
      expandedMessageSetId: isMessageSetExpanded ? null : set.id,
    })
  }

  handleMessageSetClick = (set) => {
    this.setState({
      showResults: true,
      selectedMessageSet: set,
    })
  }

  onMessageTag = (taggedMessage) => this.setState({ taggedMessage })

  render() {
    const { children } = this.props

    return (
      <DashboardsContext.Provider value={this.state}>
        {children}
      </DashboardsContext.Provider>
    )
  }
}

DashboardsProvider.propTypes = {
  children: node,
  auth: object,
}

export default DashboardsProvider
