import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import uniq from 'ramda/src/uniq'
import isNil from 'ramda/src/isNil'
import { I18n } from '../../../../lib/i18n'
import {parseDate, formatSize, maskUserIfNeeded, Tooltip} from '../../../../lib/util'
import {
  Container,
  DetailsRow,
  ColumnList,
  ListItem,
  ListItemRow,
  Headers,
  HeaderRow,
  HeaderLabel,
  HeaderValue,
  AccordionWrapper,
  ExtraLabel,
  InnerValue,
  InlineText,
  BoxItem,
  LinkClicksInnerLabel,
  ReportsInnerLabelCol1,
  ReportsInnerLabelCol2,
  ListItemCol1,
  ListItemCol2,
  DetailsHistoryListItem,
  UserHistoryListItem,
  DateHistoryListItem,
  FlexList,
  ActionIconListItem,
  ActionEntryListItem,
  AllowlistIcon,
  BlocklistIcon,
  PolicyIcon,
  RejectReportIcon,
  ConfirmReportIcon, ListItemReportActionCol, AttachmentsCol1, AttachmentsCol2,
} from './styles'
import {Divider} from "semantic-ui-react";
import {AuthContext} from "../../../../@logicea/react-auth";
import * as capitalize from "capitalize";
import actions from '../../../../requests/actions'
import {DashboardsContext} from "../../../../providers/Dashboards";

const { object, number, array, func } = PropTypes

const renderStringArray = arr => {
  if (!arr) return false
  return arr.reduce((acc, cur) => acc + `${cur.friendlyName} <${cur.address}>, `, '')
    .slice(0, -2)
}

function MessageContent({ activeTabIndex, message, actionListDetails, localTags, handleDeleteTag, handleTagSuggestionClick, toggleConfirmationModal }) {
  const {
    subject,
    mailFrom,
    fromDomain,
    email,
    fromDisplayName,
    performance,
    processedDate,
    connectingIp,
    senderIp,
    index,
    id,
    links = [],
    images = [],
    reports = [],
    linkClicks = [],
    attachments = [],
    messageId,
    googleSpam,
    googlePhish,
    microsoftSCL,
    headersFrom,
    headersTo,
    headersCc,
    headersBcc,
    headersReplyTo,
    authenticationResults,
    userActions,
    teamId
  } = message.data

  const auth = useContext(AuthContext)
  const {
    setReportActionData,
    setReportActionModalVisibility,
    userPermissions
  } = useContext(DashboardsContext)

  const handleLinkClick = (event, url) => {
    event.preventDefault()
    toggleConfirmationModal(url)
  }

  const addLineBreaks = (text) => {
    let split = text.split(/\d=/)
    if (split.length && split[0].trim() === "{")
      split.splice(0,1)

    return (
        <ul style={{paddingLeft:"0", listStyleType:"none"}}>
          {split.map((title, indx) => (
              <li key={indx}>{title.replace("\\t","").replace("\\n","").trim()}</li>
              ))}
        </ul>
    )
    return text.replace("\\n", "<br>").replace("\"","").replace("\\t","    ")
  }

  const onUpdateReportSuccess = (reportData) => {
    reports.forEach(report => {
      if (report.id === reportData.reportId) {
        report.reviewedBy = auth.lastLoginEmail
        report.reviewedTimestamp = new Date().getTime()/1000
        report.status = reportData.status
      }
    })
  }

  const updateReportStatus = (report, status) => {
    setReportActionData(report.id, report.label, teamId, status, onUpdateReportSuccess)
    setReportActionModalVisibility(true)
  }

  const handleConfirmReportClick = (report) => {
    updateReportStatus(report, 'confirmed')
  }

  const handleRejectReportClick = (report) => {
    updateReportStatus(report, 'rejected')
  }

  // Sort user actions by timestamp in reverse order (show latest first)
  let sortedUserActions = userActions ? userActions.sort((a,b) => (a.timestamp > b.timestamp) ? -1 : 1) : []

  let showActionLists = actionListDetails &&
    (actionListDetails.hasOwnProperty(id) && actionListDetails[id]?.allowlist_entries?.length) ||
    (actionListDetails.hasOwnProperty(id) && actionListDetails[id]?.blocklist_entries?.length) ||
    (actionListDetails.hasOwnProperty(id) && actionListDetails[id]?.policy_entries?.length)

  let canConfirmReports = false

  // $global is not a real team but parent to all, so check if user can confirm reports
  if ('$global' in userPermissions) {
    if (userPermissions['$global'].permissions.report_confirmation.includes('execute'))
      canConfirmReports = true
  }

  // User has an explicit permission for the team for this message
  if (teamId in userPermissions) {
    if (userPermissions[teamId].permissions.report_confirmation.includes('execute'))
      canConfirmReports = true
  } else {
    // Check the parent's permission which is flowed down to the child
    Object.keys(userPermissions).forEach(key => {
      if (userPermissions[key]?.child_teamids?.indexOf(teamId) > -1 && userPermissions[key].permissions.report_confirmation.includes('execute'))
        canConfirmReports = true
    })
  }

  return (
    <I18n>
      {({ locale, t }) => (
        <Container>
          {activeTabIndex === 0 && (
            <Headers>
              <HeaderRow>
                <HeaderLabel>
                  {t({ key: 'MESSAGE_VIEW_FROM' })}
                </HeaderLabel>
                <HeaderValue>
                  {renderStringArray(headersFrom) || `${fromDisplayName} <${fromDomain}>`}
                </HeaderValue>
              </HeaderRow>
              <HeaderRow>
                <HeaderLabel>
                  {t({ key: 'MESSAGE_VIEW_TO' })}
                </HeaderLabel>
                <HeaderValue>
                  {renderStringArray(headersTo) || email}
                </HeaderValue>
              </HeaderRow>
              <HeaderRow>
                <HeaderLabel>
                  {t({ key: 'MESSAGE_VIEW_CC' })}
                </HeaderLabel>
                <HeaderValue>
                  {renderStringArray(headersCc) || ''}
                </HeaderValue>
              </HeaderRow>
              <HeaderRow>
                <HeaderLabel>
                  {t({ key: 'MESSAGE_VIEW_BCC' })}
                </HeaderLabel>
                <HeaderValue>
                  {renderStringArray(headersBcc) || ''}
                </HeaderValue>
              </HeaderRow>
              <HeaderRow>
                <HeaderLabel>
                  {t({ key: 'MESSAGE_VIEW_REPLY_TO' })}
                </HeaderLabel>
                <HeaderValue>
                  {renderStringArray(headersReplyTo) || ''}
                </HeaderValue>
              </HeaderRow>
              <HeaderRow>
                <HeaderLabel>{t({ key: 'MESSAGE_VIEW_SUBJECT' })}</HeaderLabel>
                <HeaderValue>{subject}</HeaderValue>
              </HeaderRow>
              {!isNil(processedDate) && (
              <HeaderRow>
                <HeaderLabel>{t({ key: 'MESSAGE_VIEW_DATE' })}</HeaderLabel>
                <HeaderValue>
                  {t({
                    key: 'FULL_DATE',
                    variables: {
                      locale,
                      date: new Date(parseDate(processedDate)),
                    },
                  })}
                </HeaderValue>
              </HeaderRow>
              )}
            </Headers>
          )}
          {activeTabIndex === 1 && (
            <DetailsRow>
              <Headers>
                <HeaderRow>
                  <HeaderLabel>
                    {t({ key: 'MESSAGE_VIEW_MAIL_FROM' })}
                  </HeaderLabel>
                  <HeaderValue>
                    {mailFrom}
                  </HeaderValue>
                </HeaderRow>
                <HeaderRow>
                  <HeaderLabel>
                    {t({ key: 'MESSAGE_VIEW_SENDER_IP' })}
                  </HeaderLabel>
                  <HeaderValue>
                    {senderIp}
                  </HeaderValue>
                </HeaderRow>
                <HeaderRow>
                  <HeaderLabel>
                    {t({ key: 'MESSAGE_VIEW_CONNECTING_IP' })}
                  </HeaderLabel>
                  <HeaderValue>
                    {connectingIp}
                  </HeaderValue>
                </HeaderRow>
                <HeaderRow>
                  <HeaderLabel>
                    {t({ key: 'MESSAGE_VIEW_MESSAGEID' })}
                  </HeaderLabel>
                  <HeaderValue>
                    {messageId}
                  </HeaderValue>
                </HeaderRow>
                {!isNil(googleSpam) && (
                  <HeaderRow>
                    <HeaderLabel>
                      {t({ key: 'MESSAGE_VIEW_GOOGLE_SPAM' })}
                    </HeaderLabel>
                    <HeaderValue>
                      {googleSpam ? t({ key: 'TRUE' }) : t({ key: 'FALSE' })}
                    </HeaderValue>
                  </HeaderRow>
                )}
                {!isNil(googlePhish) && (
                  <HeaderRow>
                    <HeaderLabel>
                      {t({ key: 'MESSAGE_VIEW_GOOGLE_PHISH' })}
                    </HeaderLabel>
                    <HeaderValue>
                      {googlePhish ? t({ key: 'TRUE' }) : t({ key: 'FALSE' })}
                    </HeaderValue>
                  </HeaderRow>
                )}
                {!isNil(microsoftSCL) && (
                  <HeaderRow>
                    <HeaderLabel>
                      {t({ key: 'MESSAGE_VIEW_MICROSOFT_SCL' })}
                    </HeaderLabel>
                    <HeaderValue>
                      {microsoftSCL}
                    </HeaderValue>
                  </HeaderRow>
                )}
                <HeaderRow>
                  <HeaderLabel>
                    {t({ key: 'MESSAGE_VIEW_AUTHENTICATION_RESULTS' })}
                  </HeaderLabel>
                  <HeaderValue>
                    {addLineBreaks(authenticationResults)}
                  </HeaderValue>
                </HeaderRow>
                <HeaderRow>
                  <HeaderLabel>
                    {t({ key: 'MESSAGE_VIEW_PERFORMANCE' })}
                  </HeaderLabel>
                  <HeaderValue>
                    {performance}
                  </HeaderValue>
                </HeaderRow>
              </Headers>
            </DetailsRow>
          )}
          {activeTabIndex === 2 && (
            <FlexList>
              {sortedUserActions?.length ? (
                  <ListItemRow key={-1}>
                    <DateHistoryListItem><b>Date</b></DateHistoryListItem>
                    <UserHistoryListItem><b>Actioned By</b></UserHistoryListItem>
                    <DetailsHistoryListItem><b>Details</b></DetailsHistoryListItem>
                  </ListItemRow>

              ) : (
                <div>No actions have been performed on this message</div>
              )}
              {sortedUserActions && sortedUserActions.map((action, indx) => {

                let date = action.timestamp ? new Date(parseDate(action.timestamp)).toLocaleString() : t({key:'UNKNOWN_DATE'})
                let actionedBy = maskUserIfNeeded(action?.actionedBy ?? t({key:'UNKNOWN_USER'}), auth?.lastLoginEmail)
                let actionDetails
                switch (action.type) {
                  case 'no-action':
                    actionDetails = t({key:'ACTION_TAKE_NO_ACTION'})
                    break;
                  case 'needs-action':
                    actionDetails = t({key:'ACTION_REVERSED_TAKE_NO_ACTION'})
                    break;
                  case 'allowlist-add':
                    actionDetails = t({key: 'ACTION_CREATED_ALLOW_LIST_ENTRY', variables: {details: action?.details}})
                    break;
                  case 'blocklist-add':
                    actionDetails = t({key: 'ACTION_CREATED_BLOCK_LIST_ENTRY', variables: {details: action?.details}})
                    break;
                  case 'policy-add':
                    actionDetails = t({key: 'ACTION_CREATED_POLICY_ENTRY', variables: {details: action?.details}})
                    break;
                  case 'msg-remediated':
                    actionDetails = t({key: 'ACTION_REMEDIATED_MSG', variables: {details: action?.details}})
                    break;
                  case 'viewed':
                    actionDetails = t({key:'ACTION_VIEWED_ANALYSIS_RESULTS'})
                    break;
                  default:
                    actionDetails = t({key:'ACTION_NO_DETAILS_AVAILABLE'})
                }

                return (
                  <ListItemRow key={indx}>
                    <DateHistoryListItem>{date}</DateHistoryListItem>
                    <UserHistoryListItem>{actionedBy}</UserHistoryListItem>
                    <DetailsHistoryListItem><div dangerouslySetInnerHTML={{__html: actionDetails}}/></DetailsHistoryListItem>
                    <Divider/>
                  </ListItemRow>
                )
              })}
            </FlexList>

          )}
          {activeTabIndex === 3 && (
            <FlexList>
              {showActionLists ? (
                <ListItemRow key={-1}>
                  <ActionIconListItem><b>Type</b></ActionIconListItem>
                  <ActionEntryListItem><b>Description</b></ActionEntryListItem>
                </ListItemRow>

              ) : (
                <div>No allow lists, block lists, or policies apply to this message</div>
              )}
              {actionListDetails && actionListDetails.hasOwnProperty(id) && actionListDetails[id]?.allowlist_entries && actionListDetails[id].allowlist_entries.map((action, indx) => {
                return (
                  <ListItemRow key={indx}>
                    <ActionIconListItem><AllowlistIcon/></ActionIconListItem>
                    <ActionEntryListItem><div dangerouslySetInnerHTML={{__html: action.text}}/></ActionEntryListItem>
                  </ListItemRow>
                )
              })}
              {actionListDetails && actionListDetails.hasOwnProperty(id) && actionListDetails[id]?.blocklist_entries && actionListDetails[id].blocklist_entries.map((action, indx) => {
                return (
                  <ListItemRow key={indx}>
                    <ActionIconListItem><BlocklistIcon/></ActionIconListItem>
                    <ActionEntryListItem><div dangerouslySetInnerHTML={{__html: action.text}}/></ActionEntryListItem>
                  </ListItemRow>
                )
              })}
              {actionListDetails && actionListDetails.hasOwnProperty(id) && actionListDetails[id]?.policy_entries && actionListDetails[id].policy_entries.map((action, indx) => {
                return (
                  <ListItemRow key={indx}>
                    <ActionIconListItem><PolicyIcon/></ActionIconListItem>
                    <ActionEntryListItem><div dangerouslySetInnerHTML={{__html: action.text}}/></ActionEntryListItem>
                  </ListItemRow>
                )
              })}
            </FlexList>

          )}
          <AccordionWrapper>
            {activeTabIndex === 4 && (
              <ColumnList>
                <ExtraLabel>
                  {t({
                    key: 'MESSAGE_VIEW_IMAGES',
                    variables: { length: uniq(images).length },
                  })}
                </ExtraLabel>
                <HeaderValue>
                  {uniq(images).map(
                    image => (
                      <ListItem key={`${index}-${id}-${image.url}`}>
                        {image.url.startsWith('http') ? (
                          <a
                            href={image.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={e => handleLinkClick(e, image.url)}
                          >
                            {image.url}
                          </a>
                        ) : image.url}
                      </ListItem>
                    )
                  )}
                </HeaderValue>
              </ColumnList>
            )}
            {activeTabIndex === 5 && (
              <ColumnList>
                <ListItemRow>
                  <AttachmentsCol1>
                    <ExtraLabel>
                      {t({
                        key: 'MESSAGE_VIEW_ATTACHMENTS',
                        variables: { length: attachments.length },
                      })}
                    </ExtraLabel>
                  </AttachmentsCol1>
                  <AttachmentsCol2>
                    <ExtraLabel>
                      {t({key: 'MESSAGE_VIEW_ATTACHMENTS_HASH_VALUE'})}
                    </ExtraLabel>
                  </AttachmentsCol2>
                </ListItemRow>
                {uniq(attachments).map(attachment => (
                <ListItemRow>
                  <AttachmentsCol1>
                    <HeaderValue>
                          <ListItem key={`${index}-${id}-${attachment.url}`}>
                            {attachment.filename} ({attachment.filetype}, {formatSize(attachment.size)})
                          </ListItem>
                    </HeaderValue>
                  </AttachmentsCol1>
                  <AttachmentsCol2>
                    <HeaderValue>
                      <ListItem key={`${index}-${id}-${attachment.sha256_checksum}`}>
                        {attachment?.sha256_checksum ?? t({key: 'NOT_AVAILABLE'})}
                      </ListItem>
                    </HeaderValue>
                  </AttachmentsCol2>
                </ListItemRow>
                ))}
              </ColumnList>
            )}
            {activeTabIndex === 6 && (
              <ColumnList>
                <ExtraLabel>
                  {t({
                    key: 'MESSAGE_VIEW_LINKS',
                    variables: { length: links.length },
                  })}
                </ExtraLabel>
                <HeaderValue>
                  {uniq(links).map(
                    link => (
                      <ListItem key={`${index}-${id}-${link.url || link.domain}`}>
                        {link.url || link.domain}
                      </ListItem>
                    )
                  )}
                </HeaderValue>
              </ColumnList>
            )}
            {activeTabIndex === 7 && (
              <ColumnList>
                <ExtraLabel>
                  {t({
                    key: 'MESSAGE_VIEW_LINK_CLICKS',
                    variables: { length: linkClicks.length },
                  })}
                </ExtraLabel>
                {uniq(linkClicks).map(
                  linkClick => (
                    <BoxItem key={`${index}-${id}-${linkClick.trackingId}`}>
                      <ListItemRow>
                        <InnerValue>
                          {linkClick.timestamp
                            ? t({
                              key: 'SHORT_DATE_TIME',
                              variables: {
                                locale,
                                date: new Date(parseDate(linkClick.timestamp)),
                              },
                            })
                            : t({ key: 'MESSAGE_VIEW_TIMESTAMP_UNAVAILABLE' })}
                        </InnerValue>
                        <InlineText>
                          {t({
                            key: 'MESSAGE_VIEW_LINK_CLICKS_MESSAGE',
                            variables: { alertLevel: linkClick.alertLevel, continued: linkClick.continued },
                          })}
                        </InlineText>
                      </ListItemRow>
                      {linkClick.rewrittenURL && (
                        <ListItemRow>
                          <LinkClicksInnerLabel>INKY URL: </LinkClicksInnerLabel>
                          <InnerValue>{linkClick.rewrittenURL}</InnerValue>
                        </ListItemRow>
                      )}
                      {linkClick.originalURL && (
                        <ListItemRow>
                          <LinkClicksInnerLabel>Original URL: </LinkClicksInnerLabel>
                          <InnerValue>{linkClick.originalURL}</InnerValue>
                        </ListItemRow>
                      )}
                      {linkClick.clientUserAgent && (
                        <ListItemRow>
                          <LinkClicksInnerLabel>User Agent: </LinkClicksInnerLabel>
                          <InnerValue>{linkClick.clientUserAgent}</InnerValue>
                        </ListItemRow>
                      )}
                      {linkClick.trackingId && (
                        <ListItemRow>
                          <LinkClicksInnerLabel>Click ID: </LinkClicksInnerLabel>
                          <InnerValue>{linkClick.trackingId}</InnerValue>
                        </ListItemRow>
                      )}
                    </BoxItem>
                  )
                )}
              </ColumnList>
            )}
            {activeTabIndex === 8 && (
              <ColumnList>
                <ExtraLabel>
                  {t({
                    key: 'MESSAGE_VIEW_REPORTS',
                    variables: { length: reports.length },
                  })}
                </ExtraLabel>
                {uniq(reports).map(
                  report => (
                    <BoxItem key={`${index}-${id}-${report.contactEmail || report.clientIP}`}>
                      <ListItemRow>
                        <ListItemCol1>
                          <ListItemRow>
                            <ReportsInnerLabelCol1>{t({ key: 'MESSAGE_VIEW_REPORTS_DATE' })}</ReportsInnerLabelCol1>
                            <InnerValue>{report.timestamp
                              ? t({
                                key: 'FULL_DATE',
                                variables: {
                                  locale,
                                  date: new Date(parseDate(report.timestamp)),
                                },
                              })
                              : t({ key: 'MESSAGE_VIEW_TIMESTAMP_UNAVAILABLE' })}
                            </InnerValue>
                          </ListItemRow>
                          <ListItemRow>
                            <ReportsInnerLabelCol1>{t({ key: 'MESSAGE_VIEW_REPORTS_EMAIL' })}</ReportsInnerLabelCol1>
                            <InnerValue>{maskUserIfNeeded(report.contactEmail, auth?.lastLoginEmail)}</InnerValue>
                          </ListItemRow>
                          <ListItemRow>
                            <ReportsInnerLabelCol1>{t({ key: 'MESSAGE_VIEW_REPORTS_LABEL' })}</ReportsInnerLabelCol1>
                            <InnerValue>{report.label}</InnerValue>
                          </ListItemRow>
                        </ListItemCol1>
                        {canConfirmReports && <ListItemReportActionCol>
                          <ListItemRow>
                            <ConfirmReportIcon
                              data-tip
                              data-for={`confirmReport-${report.id}`}
                              onClick={() => handleConfirmReportClick(report)}
                              open={report.status === 'open'}
                            />
                            <Tooltip bottom id={`confirmReport-${report.id}`}>
                              <span>{t({key: 'TOOLTIP_CONFIRM_REPORT'})} </span>
                            </Tooltip>
                            <RejectReportIcon
                              data-tip
                              data-for={`rejectReport-${report.id}`}
                              onClick={() => handleRejectReportClick(report)}
                              open={report.status === 'open'}
                            />
                            <Tooltip bottom id={`rejectReport-${report.id}`}>
                              <span>{t({key: 'TOOLTIP_REJECT_REPORT'})} </span>
                            </Tooltip>
                          </ListItemRow>
                        </ListItemReportActionCol>}
                        <ListItemCol2>
                          {report.status && (
                            <ListItemRow>
                              <ReportsInnerLabelCol2 open={report.status === "open"}>{t({ key: 'MESSAGE_VIEW_REPORTS_STATUS' })}</ReportsInnerLabelCol2>
                              <InnerValue>{report.status === "open" ? t({key: 'MESSAGE_VIEW_REPORTS_UNCONFIRMED'}) : capitalize(report.status)}</InnerValue>
                            </ListItemRow>
                          )}
                          {report.reviewedBy && (
                            <ListItemRow>
                              <ReportsInnerLabelCol2>{t({ key: 'MESSAGE_VIEW_REPORTS_REVIEWED_BY' })}</ReportsInnerLabelCol2>
                              <InnerValue>{maskUserIfNeeded(report.reviewedBy, auth?.lastLoginEmail)}</InnerValue>
                            </ListItemRow>
                          )}
                          {report.reviewedTimestamp && (
                            <ListItemRow>
                              <ReportsInnerLabelCol2>{t({ key: 'MESSAGE_VIEW_REPORTS_REVIEWED_AT' })}</ReportsInnerLabelCol2>
                              <InnerValue>{report.reviewedTimestamp ? t({
                                key: 'FULL_DATE',
                                variables: {
                                  locale,
                                  date: new Date(parseDate(report.reviewedTimestamp)),
                                },
                              })
                                : t({ key: 'MESSAGE_VIEW_TIMESTAMP_UNAVAILABLE' })}</InnerValue>
                            </ListItemRow>
                          )}
                        </ListItemCol2>
                      </ListItemRow>
                      <ListItemRow>
                        <ReportsInnerLabelCol1>{t({ key: 'MESSAGE_VIEW_REPORTS_COMMENT' })}</ReportsInnerLabelCol1>
                        <InnerValue>{`"${report.comment}"`}</InnerValue>
                      </ListItemRow>
                    </BoxItem>
                  )
                )}
              </ColumnList>
            )}
          </AccordionWrapper>
        </Container>
      )}
    </I18n>
  )
}

MessageContent.propTypes = {
  message: object,
  localTags: array,
  activeTabIndex: number,
  toggleConfirmationModal: func,
}

export default MessageContent
