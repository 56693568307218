import React, { useCallback } from 'react'
import {
  Content,
  Title,
  Subtitle,
  SelectedTickOffIcon,
  SelectedTickOnIcon,
  PieSVG,
  BarSVG,
  LineSVG,
  AreaSVG,
  ListSVG,
  StackBarSVG,
  MapSVG,
  MapZoomableSVG,
} from './styles'

export type ChartType =
  | 'pie'
  | 'bar'
  | 'line'
  | 'area'
  | 'list'
  | 'stackBar'
  | 'map'
  | 'mapZoomable'

export type WidgetItemType = {
  id: string
  chartType: ChartType
  title: string
  subtitle: string
  selected?: boolean
  onClick?: () => void
}

export const WidgetItem = ({
  chartType,
  title,
  subtitle,
  selected = false,
  onClick,
}: WidgetItemType) => {
  const getSvgChart = useCallback(() => {
    switch (chartType) {
      case 'pie':
        return <PieSVG />
      case 'bar':
        return <BarSVG />
      case 'line':
        return <LineSVG />
      case 'area':
        return <AreaSVG />
      case 'list':
        return <ListSVG />
      case 'stackBar':
        return <StackBarSVG />
      case 'map':
        return <MapSVG />
      case 'mapZoomable':
        return <MapZoomableSVG />
      default:
        return null
    }
  }, [chartType])

  return (
    <Content selected={selected} onClick={onClick}>
      {selected ? <SelectedTickOnIcon /> : <SelectedTickOffIcon />}
      {getSvgChart()}
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Content>
  )
}
