import styled from 'styled-components'
import { colors, spacing, HeadlineH3PrimaryMainRegular } from 'static/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 195px;
  min-width: 500px;
  background-color: ${colors.primaryLighter};
  padding-top: ${spacing.m};
  padding-left: ${spacing.m};
  border-radius: 4px;
  @media (max-width: 1240px) {
    min-height: 330px;
  }
`

export const Title = styled(HeadlineH3PrimaryMainRegular)``

export const Dashboards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${spacing.m};
  @media (max-width: 1240px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

export const DashboardsWrapper = styled.div`
  display: flex;
  flex: 1;
  max-widtH: 50%;
  @media (max-width: 1240px) {
    max-width: 100%;
  }
`
