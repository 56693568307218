import React, { useCallback, useState } from 'react'
import { navigate } from '@reach/router'
import { I18n } from 'lib/i18n'
import { DashboardT } from 'components/App/Dashboard/types'
import { DashboardActions } from 'components/DashboardActions'
import { RichTooltip } from 'components/RichToolTip'
import {
  Container,
  Row,
  BottomRow,
  NameInfo,
  Title,
  Subtitle,
  Button,
  InfoText,
  PrivateIcon,
  SharedIcon,
  PublicIcon,
  InfoIcon,
} from './styles'

export const QuickAccessItem = ({ item }: { item: DashboardT }) => {
  const [openInfoTooltip, setOpenInfoTooltip] = useState(false)

  const handleDashboardClick = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      navigate(`dashboards/${id}`)
    },
    []
  )

  const handleInfoMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement | Document, MouseEvent>) => {
      setOpenInfoTooltip(true)
    },
    []
  )

  const handleInfoMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement | Document, MouseEvent>) => {
      setOpenInfoTooltip(false)
    },
    []
  )

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <NameInfo onClick={handleDashboardClick(item.id)}>
            <Title>{item.name}</Title>
            <Subtitle>{item.subTitle}</Subtitle>
          </NameInfo>
          <BottomRow>
            <Row>
              <DashboardActions dashboard={item} />
            </Row>
            <Row>
              {item.description && (
                <RichTooltip
                  content={<InfoText>{item.description}</InfoText>}
                  open={openInfoTooltip}
                  placement='top'
                >
                  <Button
                    onMouseEnter={handleInfoMouseEnter}
                    onMouseLeave={handleInfoMouseLeave}
                  >
                    <InfoIcon active={openInfoTooltip} />
                  </Button>
                </RichTooltip>
              )}
              {item.relativeOwnership === 'PRIVATE' ? (
                <PrivateIcon />
              ) : item.relativeOwnership === 'TEAM' ? (
                <SharedIcon />
              ) : (
                <PublicIcon />
              )}
            </Row>
          </BottomRow>
        </Container>
      )}
    </I18n>
  )
}
