import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Tags from '@yaireo/tagify/dist/react.tagify'
import { AuthContext } from '@logicea/react-auth'
import actions from '../../requests/message'
import {
    TagList
} from './styles'
import { without } from "ramda";
import "@yaireo/tagify/dist/tagify.css" // Tagify CSS

const { func, array } = PropTypes
const HIDDEN_TAGS = ['read','READ']

const TagEditor = ({ tags, handleDeleteTag, handleAddTag }) => {

    const auth = useContext(AuthContext)
    const { realm, tokens } = auth
    const [newTag, setNewTag] = useState("")
    const [deleteTag, setDeleteTag] = useState("")
    const [tagifyProps, setTagifyProps] = useState({})
    const [suggestions, setSuggestions] = useState([])

    useEffect(() => {
        if (newTag) {
            handleAddTag(newTag)
            setNewTag("")
        }
    }, [newTag])

    useEffect(() => {
        if (deleteTag) {
            handleDeleteTag(deleteTag)
            setDeleteTag("")
        }
    }, [deleteTag])

    const getTopTags = () => {

        actions.getSuggestionsAction(
            {
                dashboardFilters: [],
                setFilters: [],
                dashboardFilterMode: 'AND',
                setFilterMode: 'AND',
            },
            {
                realm,
                token: tokens.accessToken,
                field: 'tags',
                term: "",
            }
        )
        .then((results) => {
            setSuggestions(without(HIDDEN_TAGS, results.data.map(tag => tag.key.toLowerCase())).slice(0,10))
        })
    }

    // Note: These callbacks get lost if calling functional methods, must set states to bypass this bug
    const tagifyCallbacks = {
        add: (e) => setNewTag(e.detail.data.value),
        remove: (e) => setDeleteTag(e.detail.tag.title),
    }
    useEffect(() => {
        getTopTags()
    }, [])

    useEffect(() => {
        if (tags !== null)
            setTagifyProps(lastState => ({...lastState, value: without(HIDDEN_TAGS, tags.map(tag => tag.toLowerCase()))}))
    }, [tags])

    return (
        <TagList>
            <Tags className="blank-border" settings={{
                maxTags: 10,
                whitelist: [],
                enforceWhitelist: false,
                blacklist: [],
                backspace: true,
                placeholder: "Add Tag",
                dropdown: {
                    enabled: 0
                },
                callbacks: tagifyCallbacks}}
              {...tagifyProps} whitelist={suggestions} />
        </TagList>
    )
}

TagEditor.propTypes = {
    tags: array,
    handleDeleteTag: func,
    handleAddTag: func
}


export default TagEditor
