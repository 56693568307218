import styled from 'styled-components'
import { motion } from 'framer-motion'
import { makeStyles } from '@material-ui/core/styles'
import {
  colors,
  spacing,
  HeadlineH2PrimaryMainBold,
  BodyDarkGreyRegular,
} from 'static/theme'

export const useTooltipStyles = makeStyles(() => ({
  arrow: {
    color: colors.white,
  },
  tooltip: {
    padding: 24,
    minWidth: 200,
    borderRadius: 4,
    backgroundColor: colors.white,
    boxShadow: `0 0 30px 0 ${colors.primaryMain}`,
  },
}))

export const Container = styled.div<{
  top?: number
  left?: number
}>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : 0)}px;
  left: ${(props) => (props.left ? props.left : 0)}px;
  z-index: 301;
`

export const Spot = styled(motion.div)`
  width: 32px;
  height: 32px;
  padding: 3px;
  opacity: 0.8;
  cursor: pointer;
  border-radius: 16px;
  border: solid 2px ${colors.secondaryLight};
  :hover {
    opacity: 1;
  }
`

export const Circle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: ${colors.secondaryLight};
`

export const Title = styled(HeadlineH2PrimaryMainBold)`
  margin-bottom: ${spacing.s};
`

export const Content = styled(BodyDarkGreyRegular)``

export const HelpHotspotWrapper = styled.div`
  position: relative;
  display: flex;
`
