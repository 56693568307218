import styled from 'styled-components'
import {
  WHITE,
  DARK_TEXT,
  PRIMARY_DARK, BORDER_LIGHT,
} from '../../../static/colors'

export const ContentWrapper = styled.div`
  flex: 1;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Button = styled.button`
  height: ${props => (props.small ? 32 : 37)}px;
  min-width: 180px;
  ${props => (props.small ? 'max-width: 180px;' : '')}
  border: 2px solid ${props => (props.dark ? PRIMARY_DARK : BORDER_LIGHT)};
  border-radius: 7px;
  background-color: ${props => (props.dark ? PRIMARY_DARK : WHITE)};
  color: ${props => (props.dark ? WHITE : DARK_TEXT)};
  font-family: Lato;
  font-size: ${props => (props.small ? 14 : 18)}px;
  line-height: 22px;
  margin: ${props => (props.small ? 2 : 9)}px;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  outline: none;

  &:hover {
    ${props => (props.disabled ? '' : 'cursor: pointer;')}
  }
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-around;
`

export const Title = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  // min-width: 420px;
  text-align: left;
  // max-width: 500px;
  margin-bottom: 16px;
  // align-self: flex-start;
`

export const NormalText = styled.div`
  color: ${PRIMARY_DARK};
  font-family: Lato;
`

export const Description = styled.div`
  color: ${PRIMARY_DARK};
  font-family: Lato;
  font-style: italic;
  margin-top: 20px;
`

export const ActionText = styled.div`
  color: ${PRIMARY_DARK};
  font-family: Lato;
  font-weight: 700;
`

export const InteriorContainer = styled.div`
  margin-left: 40px;
  margin-right: 40px;
  text-align: left;
`

export const Link = styled.a`
  color: ${WHITE};
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${WHITE};
    text-decoration: none;
  }

  &:active {
    color: ${WHITE};
  }

  &:focus {
    outline: none;
  }
`
