import React, { useCallback, useMemo, useContext, useState, useEffect } from 'react'
import { I18n } from 'lib/i18n'
import { noop } from 'lib/ramda'
import { useReports } from 'providers/Reports'
import { HelpHotspot } from 'components/Help/HelpHotspot'
import { QuickTourStep } from 'components/Help/QuickTourStep'
import { DropdownSelect } from 'components/MaterialComponents/DropdownSelect'
import { ReportActionsButton } from './components/ReportActionsButton'
import { DashboardsContext } from 'providers/Dashboards'
import {
  getTimeframeOptions,
  getTimeframe,
  getComparisonTimeframe,
  renderTimeframe,
} from 'providers/utils'
import {
  Container,
  Column,
  Title,
  Subtitle,
  IconButton,
  ButtonText,
  Logo,
  PlusIcon,
} from './styles'
import nameLogo from 'assets/logo-on-light.png'
import {
  DropdownAutocomplete,
  DropdownAutocompleteOption,
} from '../MaterialComponents/DropdownAutocomplete'

type TopBarType = 'marketplace' | 'reports'

type TopBar = {
  title: string
  type: TopBarType
  isExport?: boolean
  showTeamSelector?: boolean
}

export const TopBar = ({
  title,
  type,
  isExport,
  showTeamSelector,
}: TopBar) => {
  const {
    timeframe,
    timeframeOption,
    updateTimeframeOption,
    updateTimeframe,
    updateComparisonTimeframe,
    updateTeamFilter,
  } = useReports()

  const context = useContext(DashboardsContext) as any
  const { toggleActionModal, userTeams, userPrimaryTeam } = context

  const [teamSelectorVisible, setTeamSelectorVisible] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState<string>("--none--")
  const [selectedTeamItem, setSelectedTeamItem] = useState<DropdownAutocompleteOption>({label: '', value: '--none--'})
  const [authorizedTeams, setAuthorizedTeams] = useState<DropdownAutocompleteOption[]>([])

  const isReport = useMemo(() => type === 'reports', [type])
  const isMarketplace = useMemo(() => type === 'marketplace', [type])

  const handleSelectedDays = useCallback(
    (
      event: React.ChangeEvent<{ name?: string; value: unknown }>,
      child: React.ReactNode
    ) => {
      const option = event.target.value as number
      if (updateTimeframeOption) updateTimeframeOption(option)
      if (updateTimeframe) updateTimeframe(getTimeframe(option))
      if (updateComparisonTimeframe)
        updateComparisonTimeframe(getComparisonTimeframe(option))
    },
    []
  )

  const handleAddDashboardClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggleActionModal(noop)('add', 'dashboard')
    },
    []
  )

  useEffect(() => {
    let availableTeams: DropdownAutocompleteOption[] = []
    setTeamSelectorVisible(userTeams.length > 1)

    if (userTeams.length) {
      // Add a hard limit to prevent overloading the database
      if (userTeams.length < 200) {
        const allTeams = {
          label: "All my teams",
          value: "*"
        }
        availableTeams.push(allTeams)
        setSelectedTeam("*")
        setSelectedTeamItem(allTeams)
      } else {
        setSelectedTeam(userPrimaryTeam)
        setSelectedTeamItem({label: userPrimaryTeam, value: userPrimaryTeam})
      }

      userTeams.forEach((team: string) => {
        availableTeams.push({ label: team, value: team })
      })
    }
    setAuthorizedTeams(availableTeams)

  }, [userTeams])

  useEffect(() => {
    updateTeamFilter && updateTeamFilter(selectedTeam === '*' ? [] : [selectedTeam])
  }, [selectedTeam])

  const handleTeamSelected = (
    event: any,
    newValue: DropdownAutocompleteOption | any
  ) => {
    let val = newValue?.value || '--none--'
    if (val?.length) {
      setSelectedTeam(val)
      setSelectedTeamItem(newValue)
    }
  }

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Column>
            <Title>{title}</Title>
            {isReport && (
              <Subtitle>{renderTimeframe(timeframeOption, timeframe)}</Subtitle>
            )}
          </Column>
          {isReport && !isExport && (
            <>
              {showTeamSelector && teamSelectorVisible && (
                <DropdownAutocomplete
                  width='200px'
                  placeholder={t({ key: 'SELECT_TEAM' })}
                  value={selectedTeamItem}
                  options={authorizedTeams || []}
                  onChange={handleTeamSelected}
                />
              )}
              <DropdownSelect
                defaultValue={timeframeOption}
                value={timeframeOption}
                onChange={handleSelectedDays}
                options={getTimeframeOptions(t)}
              />
              <ReportActionsButton />
            </>
          )}
          {isReport && isExport && <Logo src={nameLogo} alt='INKY' />}
          {isMarketplace && (
            <QuickTourStep
              placement='left'
              stepId='addDashboard'
              title={t({ key: 'QUICK_TOUR_ADD_DASHBOARD_TITLE' })}
              content={t({ key: 'QUICK_TOUR_ADD_DASHBOARD_CONTENT' })}
            >
              <IconButton color='secondary' onClick={handleAddDashboardClick}>
                <HelpHotspot
                  offsetTop={-7}
                  offsetLeft={-11}
                  title={t({ key: 'HOTSPOT_ADD_DASHBOARD_TITLE' })}
                  content={t({ key: 'HOTSPOT_ADD_DASHBOARD_CONTENT' })}
                >
                  <PlusIcon />
                </HelpHotspot>
                <ButtonText>
                  {t({ key: 'MARKETPLACE_TOP_BAR_ADD_BUTTON' })}
                </ButtonText>
              </IconButton>
            </QuickTourStep>
          )}
        </Container>
      )}
    </I18n>
  )
}
