import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import find from 'ramda/src/find'
import reject from 'ramda/src/reject'
import propEq from 'ramda/src/propEq'
import zipObj from 'ramda/src/zipObj'
import isEmpty from 'ramda/src/isEmpty'
import dropLast from 'ramda/src/dropLast'
import mergeRight from 'ramda/src/mergeRight'
import { initialSearchQuery } from '../static/initialSearchQuery'
import {RESULT_CATEGORY_NAMES} from "../static/resultCategories";
import messageActions from "../requests/message";
import {DashboardFilter} from "../requests/messageRQ/types";
import {LINK_CLICK_ALERT_LVL_NAMES} from "../static/linkClickAlertLevels";
import { ReactComponent as chartPieSVG } from 'assets/widgets/charts-pie.svg'
import { ReactComponent as chartBarSVG } from 'assets/widgets/charts-bar.svg'
import { ReactComponent as chartLineSVG } from 'assets/widgets/charts-line.svg'
import { ReactComponent as chartAreaSVG } from 'assets/widgets/charts-area.svg'
import { ReactComponent as chartListSVG } from 'assets/widgets/charts-list.svg'
import { ReactComponent as chartStackBarSVG } from 'assets/widgets/charts-stack-bar.svg'
import { ReactComponent as chartMapSVG } from 'assets/widgets/charts-map.svg'
import { ReactComponent as chartMapZoomableSVG } from 'assets/widgets/charts-map-zoomable.svg'
import { colors } from 'static/theme'

const { string, node, bool } = PropTypes

const RANGE_MAX_PRETTY = '100MB'

export const propertyNames = {
  email: 'email',
  from_domain: 'fromDomain',
  reason_ids: 'reasonIds',
  source: 'source',
  subject: 'subject',
  teamid: 'teamids',
  threat_level: 'threatLevels',
}

export const filtersToQuery = (filters = []) => {
  if (isEmpty(filters)) return initialSearchQuery
  const dateRange = find(propEq('name', 'processed_date'), filters)
  const restFilters = reject(propEq('name', 'processed_date'), filters)
  let keys = []
  const values = restFilters.map(f => {
    keys.push(propertyNames[f.name])
    return f.term
  })
  return dateRange
    ? mergeRight(zipObj(keys, values), dateRange.term)
    : zipObj(keys, values)
}

const filterFloat = value => {
  if (/^(-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) {
    return Number(value)
  }
  return NaN
}

export const parseDate = date => {
  const parsedDate = filterFloat(date)
  const value = parsedDate * 1000 || date
  return value
}

export const prettyFloat = x => {
  if (x === Infinity) return RANGE_MAX_PRETTY
  else if (x >= 1024 * 1024) {
    const mega = x / (1024 * 1024)
    return `${mega.toFixed(1)}MB`
  } else if (x >= 1024) {
    const kilo = x / 1024
    return `${kilo.toFixed(1)}KB`
  } else return `${x.toString()}B`
}

export const prettyInt = x => {
  if (x === Infinity) return RANGE_MAX_PRETTY
  else if (x >= 1024 * 1024) return `${Math.round(x / (1024 * 1024))}MB`
  else if (x >= 1024) return `${Math.round(x / 1024)}KB`
  else return `${x.toString()}B`
}


export const formatSize = size => {
  if (size >= 1024 * 1024) {
    const mega = size / (1024 * 1024)
    return `${mega.toFixed(1)}MB`
  } else if (size >= 1024) {
    const kilo = size / 1024
    return `${kilo.toFixed(1)}KB`
  } else return `${size}B`
}

export const filterToString = (term, type, name, t, locale) => {
  if (type === 'nested') {
    if (name === 'reports') return term ? t({ key: 'INPUT_REPORTS' }) : t({ key: 'INPUT_NO_REPORTS' })
    else if (name === 'link_clicks') return term ? t({ key: 'INPUT_LINK_CLICKS' }) : t({ key: 'INPUT_NO_LINK_CLICKS' })
    else if (name === 'has_attachments') return term ? t({ key: 'INPUT_HAS_ATTACHMENTS'}) : t({ key: 'INPUT_NO_HAS_ATTACHMENTS'})
    else if (term.address) {
      if (typeof term.address === 'string') {
        return term.address
      } else {
        return term.address
          .reduce((acc, cur) => acc + valueToString(cur, name, t) + ', ', '')
          .slice(0, -2)
      }
    } else if (term.url) {
      if (typeof term.url === 'string') {
        return term.url
      } else {
        return term.url
          .reduce((acc, cur) => acc + valueToString(cur, name, t) + ', ', '')
          .slice(0, -2)
      }
    } else if (term.domain) {
      if (typeof term.domain === 'string') {
        return term.domain
      } else {
        return term.domain
          .reduce((acc, cur) => acc + valueToString(cur, name, t) + ', ', '')
          .slice(0, -2)
      }
    } else if (term.friendlyName) {
      if (typeof term.friendlyName === 'string') {
        return term.friendlyName
      } else {
        return term.friendlyName
          .reduce((acc, cur) => acc + valueToString(cur, name, t) + ', ', '')
          .slice(0, -2)
      }
    } else {
      return dropLast(2, (term.filetype ? `${term.filetype}, ` : '')
          + (term.filename ? `${term.filename}, ` : '')
          + (term.sizeFrom || term.sizeTo
            ? `${formatSize(parseInt(term.sizeFrom, 10)) || '0'} - ${formatSize(parseInt(term.sizeTo, 10)) || '∞'}, `
            : ''))
    }
  }

  switch (name) {
    case 'banner_suppressed':    // Special case filter that is inverted from what is shown to the user
      return term ? t({ key: 'FALSE' }) : t({ key: 'TRUE' })
    case 'google_phish':
    case 'google_spam':
      return term ? t({ key: 'TRUE' }) : t({ key: 'FALSE' })
    case 'result_bucket':
      return RESULT_CATEGORY_NAMES(term.toString())
    case 'link_click_alert_lvl':
      return LINK_CLICK_ALERT_LVL_NAMES(term.toString())
    case 'internal':
      return term ? t({ key: 'INPUT_INTERNAL' }) : t({ key: 'INPUT_EXTERNAL' })
    case 'processed_date':
      if (type === 'relative_date') {
        const now = Math.floor(new Date().getTime() / 1000)
        if (parseInt(term.relativeTo, 10) === 0) {
          switch (parseInt(term.relativeFrom, 10)) {
            case 60:
              return t({ key: 'INPUT_LAST_HOUR' })
            case 60 * 24:
              return t({ key: 'INPUT_LAST_DAY' })
            case 60 * 24 * 7:
              return t({ key: 'INPUT_LAST_WEEK' })
            case 60 * 24 * 30:
              return t({ key: 'INPUT_LAST_MONTH' })
            case 60 * 24 * 365:
              return t({ key: 'INPUT_LAST_YEAR' })
            default:
              return `
                ${t({
    key: 'SHORT_DATE',
    variables: { locale, date: new Date(parseDate(now - term.relativeFrom * 60)) },
  })}
                -
                ${t({
    key: 'SHORT_DATE',
    variables: { locale, date: new Date(parseDate(now - term.relativeTo * 60)) },
  })}`
          }
        } else {
          return `
            ${t({ key: 'SHORT_DATE', variables: { locale, date: new Date(parseDate(now - term.relativeFrom * 60)) } })}
            -
            ${t({ key: 'SHORT_DATE', variables: { locale, date: new Date(parseDate(now - term.relativeTo * 60)) } })}`
        }
      } else {
        return `
          ${t({ key: 'SHORT_DATE', variables: { locale, date: new Date(parseDate(term.from)) } })}
          -
          ${t({ key: 'SHORT_DATE', variables: { locale, date: new Date(parseDate(term.to)) } })}`
      }
    default:
      if (typeof term === 'string') {
        return term
      } else {
        return term
          .reduce((acc, cur) => acc + valueToString(cur, name, t) + ', ', '')
          .slice(0, -2)
      }
  }
}

export const valueToString = (value, filterName, t) => {
  switch (filterName) {
    case 'threat_level':
      return t({ key: 'THREAT_LEVEL', variables: { level: parseInt(value, 10) } })
    case 'reason_ids':
      return t({ key: 'REASONS', variables: { id: parseInt(value, 10) } })
    default:
      return value || t({ key: 'NONE' })
  }
}

export const Tooltip = ({
  id,
  children,
  top,
  right,
  bottom,
  left,
  delayShow,
  disable
}) => {
  let place = ''
  if (top) place = 'top'
  else if (right) place = 'right'
  else if (bottom) place = 'bottom'
  else if (left) place = 'left'
  let delay = 300

  // Delay 0 is valid
  if (delayShow !== null && delayShow !== undefined)
    delay = delayShow

  return (
    <ReactTooltip
      id={id}
      className="tooltip"
      effect="solid"
      place={place}
      delayShow={delay}
      globalEventOff="click"
      disable={disable}
    >
      {children}
    </ReactTooltip>
  )
}

Tooltip.propTypes = {
  id: string,
  children: node,
  top: bool,
  right: bool,
  bottom: bool,
  left: bool,
  disable: bool
}

export const svgStyles = `
  width: 24px;
  height: 24px;
  fill:${colors.primaryDark};
`
 const PieIcon = styled(chartPieSVG)`
  ${svgStyles}
`

 const BarIcon = styled(chartBarSVG)`
  ${svgStyles}
`

 const LineIcon = styled(chartLineSVG)`
  ${svgStyles}
`

 const AreaIcon = styled(chartAreaSVG)`
  ${svgStyles}
`

 const ListIcon = styled(chartListSVG)`
  ${svgStyles}
`

 const StackBarIcon = styled(chartStackBarSVG)`
  ${svgStyles}
`

 const MapIcon = styled(chartMapSVG)`
  ${svgStyles}
`

 const MapZoomableIcon = styled(chartMapZoomableSVG)`
  ${svgStyles}
`

export const getVisualizationIcon = option => {
  switch (option) {
    case 'count':
      return null
    case 'pie':
      return <PieIcon />
    case 'bar':
      return <BarIcon />
    case 'line':
      return <LineIcon />
    case 'area':
      return <AreaIcon />
    case 'list':
      return <ListIcon />
    case 'stack':
      return <StackBarIcon />
    case 'stack_sender':
      return <StackBarIcon />
    case 'stack_sender_domain':
      return <StackBarIcon />
    case 'tree':
      return <MapIcon />
    case 'zoom':
      return <MapZoomableIcon />
    default:
      return null
  }
}

// Performs a case insensitive removal of first list from second list
export const ciWithout = (removeList, fromList) => {
  let res = [];
  fromList.forEach(existingItem => {
    let keep = true
    removeList.forEach(removeItem => {
      if (existingItem.toLowerCase() === removeItem.toLowerCase())
        keep = false;
    })
    if (keep)
      res.push(existingItem);
  })
  return res
}

export const saveAction = async (actionName, messageId, team, auth, details) => {
  try {
    return messageActions.addActionDataAction(messageId, team, auth.realm, auth.tokens.accessToken, {
      type: actionName,
      details: details,
      actioned_by: auth.user.email
    })
  } catch (err) {
    console.log("saveAction:Exception", err)
  }
}

export const parseElasticsearchID = (msgId) => {
  try {
    return atob(msgId).split('/')
  } catch (e) {
    console.log("Exception getting teamid from message: " + msgId)
  }
  return null
}

export const maskUserIfNeeded = (email, authEmail) => {
  if (authEmail && authEmail.endsWith('inky.com'))
    return email
  else if (email === 'qc-bot@inky.com')
    return 'INKY Quality Control'
  else if (email && email.endsWith('@inky.com'))
    return 'INKY Admin'
  return email
}

export const buildReportDashboardFilters = (from, to, teamFilter) => {
  let dashboardFilters = [
    {
      name: 'processed_date',
      type: 'date',
      term: { from, to },
    }
  ]

  if (teamFilter?.length)
    dashboardFilters.push({
      name: 'teamid',
      type: 'keyword',
      term: teamFilter
    })

  return dashboardFilters
}

export const getEnvironmentName = (hostname) => {
  if (hostname.startsWith('dev.'))
    return 'dev';
  else if (hostname === 'localhost')
    return 'localhost';
  else if (hostname.includes("glrunner.internal"))
    return 'cicd'
  return 'prod';
}

export const isGTMEnabled = () => {
  return window.location.hostname.startsWith('dashboard.inkyphishfence')
}

export const getTeamFromElasticsearchID = (msgId) => {
  try {
    const decoded = atob(msgId)
    const toks = decoded.split('/')
    return toks[0]
  } catch (e) {
    console.log("Exception getting teamid from message: " + msgId)
  }
  return null
}
