import React, { Component } from 'react'
import PropTypes from 'prop-types'
import find from 'ramda/src/find'
import prop from 'ramda/src/prop'
import eqBy from 'ramda/src/eqBy'
import isNil from 'ramda/src/isNil'
import union from 'ramda/src/union'
import equals from 'ramda/src/equals'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'
import isEmpty from 'ramda/src/isEmpty'
import findIndex from 'ramda/src/findIndex'
import unionWith from 'ramda/src/unionWith'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import { SearchInput } from 'components/SearchInput'
import messageActions from '../../../requests/message'
import { I18n } from '../../../lib/i18n'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  AllResultsText,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import debounce from "lodash.debounce";
import {INPUT_TEXT_DEBOUNCE_TIME} from "../../../static/appConfig"

const { object, func, array, string } = PropTypes

class KeywordInput extends Component {
  state = {
    value: '',
    suggestions: {},
    selectedKeywords: [],
  }

  constructor(props) {
    super(props);
    this.getSuggestionsDebounced = debounce(async (otherDashboardFilters, otherSetFilters, dashboardFilterMode,
                                                   setFilterMode, realm, token, name, newValue) => {
      const newSuggestions = await messageActions.getSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: name,
          term: newValue,
        }
      )
      this.setState({ suggestions: { [name]: newSuggestions.data } })
    }, INPUT_TEXT_DEBOUNCE_TIME)
  }

  componentDidMount() {
    const { filter } = this.props
    const { name, term } = filter
    this.handleTermChange({ target: { name, value: term || '' } })
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props
    const { name, term } = filter
    if (equals(prevProps.filter, filter)) return
    if (!equals(prevProps.filter.name, name)) this.handleFilterChange()
    this.handleTermChange({ target: { name, value: term || '' } })
  }

  handleFilterChange = () => {
    this.setState({ selectedKeywords: [] })
  }

  handleTermChange = async event => {
    const { name } = event.target
    const newValue = event.target.value
    const { value, suggestions, selectedKeywords } = this.state
    const { realm, token, onChange, setFilters, dashboardFilters, setFilterMode, dashboardFilterMode } = this.props

    if (equals(value, newValue) && !equals(newValue, '')) return

    const otherDashboardFilters = rFilter(f => !propEq('name', name)(f), dashboardFilters)
    const otherSetFilters = rFilter(f => !propEq('name', name)(f), setFilters)

    if (typeof newValue === 'string') {
      this.setState({ value: newValue })
      if (selectedKeywords.length === 0) onChange(newValue)
      this.getSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode, setFilterMode,
        realm, token, name, newValue)
    } else {
      if (selectedKeywords.length === 0) onChange(value)
      const isKey = keys => e => findIndex(equals(e.key), keys) > -1
      const newValueObj = newValue.map(k => ({ key: k }))
      if (isNil(suggestions[name])) {
        this.setState({ selectedKeywords: newValueObj })
        const newSuggestions = await messageActions.getSuggestionsAction(
          {
            dashboardFilters: otherDashboardFilters,
            setFilters: otherSetFilters,
            dashboardFilterMode,
            setFilterMode,
          },
          {
            realm,
            token,
            field: name,
            term: value,
          }
        )
        this.setState({
          suggestions: { [name]: newSuggestions.data },
          selectedKeywords: unionWith(
            eqBy(prop('key')),
            rFilter(isKey(newValue), newSuggestions.data),
            newValueObj
          ),
        })
      } else {
        this.setState({
          selectedKeywords: unionWith(
            eqBy(prop('key')),
            union(selectedKeywords, rFilter(isKey(newValue), suggestions[name])),
            newValueObj
          ),
        })
      }
    }
  }

  handleRowClick = keyword => {
    const { onChange } = this.props
    const { selectedKeywords } = this.state
    const newKeywords = symmetricDifference(selectedKeywords, [keyword])
    this.setState({ selectedKeywords: newKeywords })
    onChange(newKeywords.map(k => k.key))
  }

  handleClearClick = async () => {
    const { selectedKeywords } = this.state
    const {
      filter,
      realm,
      token,
      onChange,
      setFilters,
      setFilterMode,
      dashboardFilters,
      dashboardFilterMode,
    } = this.props
    const { name } = filter

    const otherDashboardFilters = rFilter(f => !propEq('name', name)(f), dashboardFilters)
    const otherSetFilters = rFilter(f => !propEq('name', name)(f), setFilters)

    this.setState({ value: '' })
    if (selectedKeywords.length === 0) onChange('')
    const newSuggestions = await messageActions.getSuggestionsAction(
      {
        dashboardFilters: otherDashboardFilters,
        setFilters: otherSetFilters,
        dashboardFilterMode,
        setFilterMode,
      },
      {
        realm,
        token,
        field: name,
        term: '',
      }
    )
    this.setState({ suggestions: { [name]: newSuggestions.data } })
  }

  render() {
    const { filter } = this.props
    const { value, suggestions, selectedKeywords } = this.state
    const suggestionArray = propOr([], filter.name, suggestions)
    return (
      <I18n>
        {({ t }) => (
          <Container>
            <SearchInput
              autoFocus={true}
              value={value}
              name={filter.name}
              onChange={this.handleTermChange}
              onClear={this.handleClearClick}
              placeholder={t({ key: filter.name })}
              key={filter.name}
            />
            <SelectionHeader>
              <SelectionHeaderItem>
                {suggestionArray.length > 100 ? (
                  <StatisticsTextBold>
                    100+
                    <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                  </StatisticsTextBold>
                ) : (
                  <StatisticsTextBold>
                    {suggestionArray.length}
                    <StatisticsText>({suggestionArray.length === 1
                      ? t({ key: 'SELECTION_MATCH' })
                      : t({ key: 'SELECTION_MATCHES' })
                    })</StatisticsText>
                  </StatisticsTextBold>
                )}
              </SelectionHeaderItem>
              <SelectionHeaderItem right>
                {selectedKeywords.length > 100 ? (
                  <StatisticsTextBold>
                    100+
                    <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                  </StatisticsTextBold>
                ) : (
                  <StatisticsTextBold>
                    {selectedKeywords.length}
                    <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                  </StatisticsTextBold>
                )}
              </SelectionHeaderItem>
            </SelectionHeader>
            <SelectionWrapper>
              <SelectionColumnWrapper>
                <Scrollbar>
                  {suggestionArray.map(suggestion => {
                    const isSelected = find(eqBy(prop('key'), suggestion), selectedKeywords)
                    return (
                      <SuggestionWrapper>
                        <Suggestion
                          key={suggestion.key}
                          isSelected={isSelected}
                          onClick={isSelected ? null
                            : () => this.handleRowClick(suggestion)
                          }
                        >
                          <SuggestionText>
                            {suggestion.key || t({ key: 'SELECTION_NO_VALUE' })}
                          </SuggestionText>
                          ({suggestion.count})
                        </Suggestion>
                      </SuggestionWrapper>
                    )
                  })}
                </Scrollbar>
              </SelectionColumnWrapper>
              <Seperator>
                <SeperatorIcon />
              </Seperator>
              <SelectionColumnWrapper right>
                <Scrollbar>
                  {selectedKeywords.length > 0
                    ? selectedKeywords.map(keyword => (
                      <SuggestionWrapper>
                        <Suggestion
                          closeable
                          key={`${keyword.key}-${keyword.count || ''}`}
                          isSelected={false}
                          onClick={() => this.handleRowClick(keyword)}
                        >
                          <SuggestionText>
                            {keyword.key || t({ key: 'SELECTION_NO_VALUE' })}
                          </SuggestionText>
                          {keyword.count && ` (${keyword.count})`}
                          <CloseIcon>×</CloseIcon>
                        </Suggestion>
                      </SuggestionWrapper>
                    )) : !isEmpty(value) && (
                      <SuggestionWrapper>
                        <AllResultsText>
                          {t({ key: 'SELECTION_ALL_MATCHES_OF', variables: { value } })}
                        </AllResultsText>
                      </SuggestionWrapper>
                    )
                  }
                </Scrollbar>
              </SelectionColumnWrapper>
            </SelectionWrapper>
          </Container>
        )}
      </I18n>
    )
  }
}


KeywordInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default KeywordInput
