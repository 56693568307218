import React, { useCallback, useState, HTMLAttributes } from 'react'
import { propOr } from 'ramda'
import { I18n } from 'lib/i18n'
import { noop } from 'lib/ramda'
import { RichTooltip } from 'components/RichToolTip'
import {
  Container,
  Button,
  ActionsIcon,
  ActionsContainer,
  ActionWrapper,
  ActionText,
  CopyIcon,
  DownloadIcon,
  EditIcon,
  ExportIcon,
  TrashIcon,
} from './styles'

type Actions = 'rename' | 'copy' | 'export' | 'download' | 'trash'

type WidgetActionsProps = {
  widget: any
  hasRename: boolean
  hasClone: boolean
  toggleMessageSetActionModal: (
    action: string,
    type: string,
    setId: string | null
  ) => void
  hasExport: boolean
  handleExportClick: () => void
  hasDownload: boolean
  handleDownloadClick: () => void
  hasDelete: boolean
  deleteMessageSet: (setId: string | null) => void
}

const WidgetActionsContent = ({
  widget,
  hasRename,
  hasClone,
  toggleMessageSetActionModal,
  hasExport,
  handleExportClick,
  hasDownload,
  handleDownloadClick,
  hasDelete,
  deleteMessageSet,
}: WidgetActionsProps) => {
  const setId: string | null = propOr(null, 'id', widget)

  const handleActionClicked = useCallback(
    (action: Actions) => (
      event: React.MouseEvent<HTMLDivElement | Document, MouseEvent>
    ) => {
      event.stopPropagation()
      if (action === 'rename') {
        toggleMessageSetActionModal('rename', 'message set', setId)
      }
      if (action === 'copy') {
        toggleMessageSetActionModal('clone', 'message set', setId)
      }
      if (action === 'export') {
        handleExportClick()
      }
      if (action === 'download') {
        handleDownloadClick()
      }
      if (action === 'trash') {
        deleteMessageSet(setId)
      }
    },
    [widget, setId]
  )

  return (
    <I18n>
      {({ t }) => (
        <ActionsContainer>
          <ActionWrapper
            disabled={!hasRename}
            onClick={hasRename ? handleActionClicked('rename') : noop}
          >
            <EditIcon />
            <ActionText>
              {t({
                key: 'WIDGET_ACTION_EDIT',
              })}
            </ActionText>
          </ActionWrapper>
          <ActionWrapper
            disabled={!hasClone}
            onClick={hasClone ? handleActionClicked('copy') : noop}
          >
            <CopyIcon />
            <ActionText>
              {t({
                key: 'WIDGET_ACTION_CLONE',
              })}
            </ActionText>
          </ActionWrapper>
          <ActionWrapper
            disabled={!hasExport}
            onClick={hasExport ? handleActionClicked('export') : noop}
          >
            <ExportIcon />
            <ActionText>
              {t({
                key: 'WIDGET_ACTION_EXPORT',
              })}
            </ActionText>
          </ActionWrapper>
          <ActionWrapper
            disabled={!hasDownload}
            onClick={hasDownload ? handleActionClicked('download') : noop}
          >
            <DownloadIcon />
            <ActionText>
              {t({
                key: 'WIDGET_ACTION_DOWNLOAD',
              })}
            </ActionText>
          </ActionWrapper>
          <ActionWrapper
            disabled={!hasDelete}
            onClick={hasDelete ? handleActionClicked('trash') : noop}
          >
            <TrashIcon />
            <ActionText>
              {t({
                key: 'WIDGET_ACTION_DELETE',
              })}
            </ActionText>
          </ActionWrapper>
        </ActionsContainer>
      )}
    </I18n>
  )
}

export const WidgetActions = ({
  widget,
  hasRename,
  hasClone,
  toggleMessageSetActionModal,
  hasExport,
  handleExportClick,
  hasDownload,
  handleDownloadClick,
  hasDelete,
  deleteMessageSet,
  style,
}: WidgetActionsProps & HTMLAttributes<HTMLDivElement>) => {
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleTooltip = useCallback(
    (
      event: React.MouseEvent<
        HTMLButtonElement | HTMLDivElement | Document,
        MouseEvent
      >
    ) => {
      setOpenTooltip(!openTooltip)
      event.stopPropagation()
    },
    [openTooltip]
  )

  return (
    <Container style={style}>
      <RichTooltip
        content={
          <WidgetActionsContent
            widget={widget}
            hasRename={hasRename}
            hasClone={hasClone}
            toggleMessageSetActionModal={toggleMessageSetActionModal}
            hasExport={hasExport}
            handleExportClick={handleExportClick}
            hasDownload={hasDownload}
            handleDownloadClick={handleDownloadClick}
            hasDelete={hasDelete}
            deleteMessageSet={deleteMessageSet}
          />
        }
        open={openTooltip}
        placement='bottom'
        onClose={() => setOpenTooltip(false)}
      >
        <Button onClick={handleTooltip}>
          <ActionsIcon />
        </Button>
      </RichTooltip>
    </Container>
  )
}
