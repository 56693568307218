import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import { spacing, colors, Body2WhiteRegular } from 'static/theme'
import { ReactComponent as EditSVG } from 'assets/marketplace/edit.svg'
import { ReactComponent as CopySVG } from 'assets/marketplace/copy.svg'
import { ReactComponent as DownloadSVG } from 'assets/marketplace/download.svg'
import { ReactComponent as ShareSVG } from 'assets/marketplace/share.svg'
import { ReactComponent as TrashSVG } from 'assets/marketplace/trash.svg'
import { ReactComponent as ActionsSVG } from 'assets/marketplace/actions.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Button = styled(IconButton).attrs({
  size: 'small',
})``

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.xs} 0rem;
`

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacing.xs} 0rem;
  cursor: pointer;
`

export const ActionText = styled(Body2WhiteRegular)`
  margin-left: ${spacing.xxs};
`

export const EditIcon = styled(EditSVG)`
  fill: ${colors.white};
`

export const CopyIcon = styled(CopySVG)`
  fill: ${colors.white};
`

export const DownloadIcon = styled(DownloadSVG)`
  fill: ${colors.white};
`

export const ShareIcon = styled(ShareSVG)`
  fill: ${colors.white};
`

export const TrashIcon = styled(TrashSVG)`
  fill: ${colors.white};
`

export const ActionsIcon = styled(ActionsSVG)<{ active: boolean }>`
  fill: ${({ active }) => (active ? colors.primaryMain : colors.primaryLight)};
`
