import React, { ReactNode, useContext } from 'react'
import { getTimeframe, getComparisonTimeframe } from './utils'
import { DEFAULT_REPORT_TIMEFRAME } from 'static/appConfig'
import { exportToPDF } from 'components/App/Reports/export'

export type Timeframe = {
  from: number
  to: number
}

export type ReportsState = {
  isComparisonEnabled: boolean
  timeframe: Timeframe
  timeframeOption: number
  comparisonTimeframe: Timeframe
  teamFilter: string[]
  updateIsComparisonEnabled?: (enabled: boolean) => void
  updateTimeframe?: (timeframe: Timeframe) => void
  updateTimeframeOption?: (timeframeOption: number) => void
  updateComparisonTimeframe?: (comparisonTimeframe: Timeframe) => void
  updateTeamFilter?: (teamFilter: string[]) => void
  exportToPDF: () => void
}

const INITIAL_STATE: ReportsState = {
  isComparisonEnabled: true,
  timeframe: getTimeframe(DEFAULT_REPORT_TIMEFRAME),
  timeframeOption: DEFAULT_REPORT_TIMEFRAME,
  comparisonTimeframe: getComparisonTimeframe(DEFAULT_REPORT_TIMEFRAME),
  exportToPDF,
  teamFilter: ['--none--']
}

const Context = React.createContext<ReportsState>(INITIAL_STATE)

export const useReports = () => useContext(Context)

export const ReportsProvider = ({ children }: { children: ReactNode }) => {
  const [isComparisonEnabled, setIsComparisonEnabled] = React.useState<
    ReportsState['isComparisonEnabled']
  >(true)
  const [timeframe, setTimeframe] = React.useState<ReportsState['timeframe']>(
    getTimeframe(DEFAULT_REPORT_TIMEFRAME)
  )
  const [timeframeOption, setTimeframeOption] = React.useState<
    ReportsState['timeframeOption']
  >(DEFAULT_REPORT_TIMEFRAME)
  const [comparisonTimeframe, setComparisonTimeframe] = React.useState<
    ReportsState['comparisonTimeframe']
  >(getComparisonTimeframe(DEFAULT_REPORT_TIMEFRAME))

  const [teamFilter, setTeamFilter] = React.useState<string[]>(['--none--'])

  const contextValue = {
    isComparisonEnabled,
    timeframe,
    timeframeOption,
    comparisonTimeframe,
    teamFilter,
    updateIsComparisonEnabled: (enabled: boolean) => {
      setIsComparisonEnabled(enabled)
    },
    updateTimeframe: (timeframe: Timeframe) => {
      setTimeframe(timeframe)
    },
    updateTimeframeOption: (timeframeOption: number) => {
      setTimeframeOption(timeframeOption)
    },
    updateComparisonTimeframe: (comparisonTimeframe: Timeframe) => {
      setComparisonTimeframe(comparisonTimeframe)
    },
    updateTeamFilter: (teamFilter: string[]) => {
      setTeamFilter(teamFilter)
    },
    exportToPDF,
  }

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}
