import React, { useCallback, useContext } from 'react'
import { useParams } from '@reach/router'
import { queryCache, useQuery } from 'react-query'
import { Loader } from 'components/Loader'
import { AuthContext } from '@logicea/react-auth'
import { I18n } from 'lib/i18n'
import { Report } from 'requests/reports/types'
import { getReportAction } from 'requests/reports'
import { ReportsProvider } from 'providers/Reports'
import { TopBar } from 'components/TopBar'
import { Quad } from 'components/ReportWrappers/Quad'
import { Column } from 'components/ReportWrappers/Column'
import { Row, ResponsiveRow } from 'components/ReportWrappers/Row'
import { SpamContent } from 'components/ReportWidgets/SpamContent'
import { EmailsReported } from 'components/ReportWidgets/EmailsReported'
import { MalwareAttachement } from 'components/ReportWidgets/MalwareAttachement'
import { ResultCategories } from 'components/ReportWidgets/ResultCategories'
import { OverallThreatLevel } from 'components/ReportWidgets/OverallThreatLevel'
import { SensitiveCategories } from 'components/ReportWidgets/SensitiveCategories'
import { UniquePrimaryRecipients } from 'components/ReportWidgets/UniquePrimaryRecipients'
import { MostTargetedRecipient } from 'components/ReportWidgets/MostTargetedRecipient'
import { DomainWithMostThreats } from 'components/ReportWidgets/DomainWithMostThreats'
import { SenderWithMostThreats } from 'components/ReportWidgets/SenderWithMostThreats'
import { Top5SenderEmails } from 'components/ReportWidgets/Top5SenderEmails'
import { ThreatsOverTime } from 'components/ReportWidgets/ThreatsOverTime'
import { CompareData } from 'components/ReportComponents/CompareData'
import {
  Container,
  ReportsContent,
  ExportWrapper,
  Margin,
  RowWithSpaceBetween,
  RefreshButton,
} from './styles'

export type ReportsParams = {
  reportId: string
}

const Reports = () => {
  const params = useParams() as ReportsParams
  const { reportId } = params ?? {}
  const auth = useContext(AuthContext)
  const { realm, tokens, user }: any = auth

  const { data, isLoading: dataIsLoading } = useQuery(
    [
      'getReport',
      {
        realm,
        token: tokens?.accessToken,
        reportId,
      },
    ],
    getReportAction,
    { enabled: false }
  )

  const report = data?.data as Report

  const onRefresh = useCallback(() => {
    queryCache.invalidateQueries()
  }, [])

  const isLoading = dataIsLoading && data === undefined
  return (
    <ReportsProvider>
      <I18n>
        {({ t }) => (
          <div
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              minWidth: 712,
            }}
          >
            <Container>
              <TopBar
                type='reports'
                title={t({ key: 'REPORTS_TOP_BAR_TITLE' })}
                showTeamSelector
              />
              {isLoading ? (
                <Loader active={isLoading} />
              ) : (
                <ReportsContent>
                  <RowWithSpaceBetween>
                    <CompareData isExport={false} />
                    <RefreshButton onClick={onRefresh} />
                  </RowWithSpaceBetween>
                  <Column>
                    <Row>
                      <OverallThreatLevel isAnimationActive={true} />
                      <Quad>
                        <EmailsReported />
                        <UniquePrimaryRecipients />
                        <MalwareAttachement />
                        <SpamContent />
                      </Quad>
                    </Row>
                    <ResponsiveRow showOnWide={true}>
                      <ResultCategories isAnimationActive={true} />
                      <SensitiveCategories isAnimationActive={true} />
                    </ResponsiveRow>
                    <ResponsiveRow showOnWide={true}>
                      <MostTargetedRecipient isAnimationActive={true} />
                      <DomainWithMostThreats isAnimationActive={true} />
                      <SenderWithMostThreats isAnimationActive={true} />
                    </ResponsiveRow>
                    <ResponsiveRow showOnWide={false}>
                      <ResultCategories isAnimationActive={true} />
                    </ResponsiveRow>
                    <ResponsiveRow showOnWide={false}>
                      <SensitiveCategories isAnimationActive={true} />
                      <MostTargetedRecipient isAnimationActive={true} />
                    </ResponsiveRow>
                    <ResponsiveRow showOnWide={false}>
                      <DomainWithMostThreats isAnimationActive={true} />
                      <SenderWithMostThreats isAnimationActive={true} />
                    </ResponsiveRow>
                    <Row>
                      <ThreatsOverTime isAnimationActive={true} />
                      <Top5SenderEmails />
                    </Row>
                  </Column>
                </ReportsContent>
              )}
            </Container>
            <ExportWrapper id='divToPrint1'>
              <Container>
                <TopBar
                  type='reports'
                  isExport={true}
                  title={t({ key: 'REPORTS_TOP_BAR_TITLE' })}
                />
                <ReportsContent>
                  <Margin />
                  <CompareData isExport={true} />
                  <Column>
                    <Margin />
                    <Row>
                      <OverallThreatLevel isAnimationActive={false} />
                    </Row>
                    <Margin />
                    <Row>
                      <Quad>
                        <EmailsReported />
                        <UniquePrimaryRecipients />
                        <MalwareAttachement />
                        <SpamContent />
                      </Quad>
                    </Row>
                    <Margin />
                    <Row>
                      <ResultCategories isAnimationActive={false} />
                    </Row>
                    <Margin />
                    <Row>
                      <SensitiveCategories isAnimationActive={false} />
                      <MostTargetedRecipient isAnimationActive={false} />
                    </Row>
                  </Column>
                </ReportsContent>
              </Container>
            </ExportWrapper>
            <ExportWrapper id='divToPrint2'>
              <Container>
                <TopBar
                  type='reports'
                  isExport={true}
                  title={t({ key: 'REPORTS_TOP_BAR_TITLE' })}
                />
                <ReportsContent>
                  <Margin />
                  <CompareData isExport={true} />
                  <Column>
                    <Margin />
                    <Row>
                      <DomainWithMostThreats isAnimationActive={false} />
                      <SenderWithMostThreats isAnimationActive={false} />
                    </Row>
                    <Margin />
                    <Row>
                      <ThreatsOverTime isAnimationActive={false} />
                    </Row>
                    <Margin />
                    <Row>
                      <Top5SenderEmails />
                    </Row>
                  </Column>
                </ReportsContent>
              </Container>
            </ExportWrapper>
          </div>
        )}
      </I18n>
    </ReportsProvider>
  )
}

export default Reports
