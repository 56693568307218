import React, { useContext, useState } from 'react'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import { DashboardsContext } from '../../../providers/Dashboards'
import modalStyles, {
  SmallButton,
  ModalTitle,
  ContentWrapper,
  CloseButton,
  CloseIcon,
  Checkbox,
} from '../modalStyles'
import {
  MessageSetSettingContainer,
  DashboardSelectContainer,
  CloseButtonWrapper,
  ButtonsWrapper,
  FormTitle,
  FormText,
  RadioForm,
  SubFormWrapper,
  Radio,
  RowWrapper,
  ColumnInput,
} from './styles'

const MessageSetSettings = () => {
  const context = useContext(DashboardsContext)
  const { isMessageSetSettingsModalOpen, toggleMessageSetSettingsModal, messageSetNumberOfColumns, handleMessageSetNumberOfColumns } = context

  const [radioSelector, setRadioSelector] = useState('radioOrder')
  const [numberOfColumns, setNumberOfColumns] = useState(messageSetNumberOfColumns)
  const [keepSameSize, setKeepSameSize] = useState(false)

  const handleSubmit = async () => {
    handleMessageSetNumberOfColumns(numberOfColumns)
    toggleMessageSetSettingsModal()
  }

  const handleRadioOnChange = async (e, { value }) => {
    setRadioSelector(value)
  }

  const handleSizeCheckboxClick = () => {
    setKeepSameSize(!keepSameSize)
  }

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isMessageSetSettingsModalOpen}
        onRequestClose={toggleMessageSetSettingsModal}
        contentLabel={t({ key: 'MODAL_MESSAGE_SET_LABEL' })}
        style={{
          overlay: modalStyles.overlay,
          content: modalStyles.content,
        }}
      >
        <MessageSetSettingContainer>
          <CloseButtonWrapper>
            <CloseButton onClick={toggleMessageSetSettingsModal}>
              <CloseIcon />
            </CloseButton>
          </CloseButtonWrapper>
          <DashboardSelectContainer>
            <ModalTitle>
              {t({ key: 'MODAL_MESSAGE_SET_TITLE' })}
            </ModalTitle>
            <ContentWrapper>
              <FormTitle>
                {t({ key: 'MODAL_MESSAGE_SET_FORM_TITLE' })}
              </FormTitle>
              <RadioForm>
                <RadioForm.Field>
                  <RowWrapper>
                    <Radio
                      name="radioGroup"
                      value="radioOrder"
                      checked={radioSelector === 'radioOrder'}
                      onChange={handleRadioOnChange}
                    />
                    <FormText>
                      {t({ key: 'MODAL_MESSAGE_SET_FORM_RADIO_ORDER' })}
                    </FormText>
                  </RowWrapper>
                  <SubFormWrapper>
                    <RowWrapper>
                      <Checkbox
                        checked={keepSameSize}
                        onChange={handleSizeCheckboxClick}
                      />
                      <FormText>
                        {t({ key: 'MODAL_MESSAGE_SET_FORM_CHECKBOX_SIZE' })}
                      </FormText>
                    </RowWrapper>
                  </SubFormWrapper>
                </RadioForm.Field>
                <RadioForm.Field>
                  <RowWrapper>
                    <Radio
                      name="radioGroup"
                      value="radioNumber"
                      checked={radioSelector === 'radioNumber'}
                      onChange={handleRadioOnChange}
                    />
                    <FormText>
                      {t({ key: 'MODAL_MESSAGE_SET_FORM_RADIO_NUMBER_MESSAGES' })}
                    </FormText>
                  </RowWrapper>
                  <SubFormWrapper>
                    <RowWrapper>
                      <FormText>{t({ key: 'MODAL_MESSAGE_SET_FORM_MAX_COLUMNS' })}</FormText>
                      <ColumnInput
                        min={1}
                        max={5}
                        value={numberOfColumns}
                        onChange={setNumberOfColumns}
                      />
                    </RowWrapper>
                  </SubFormWrapper>
                </RadioForm.Field>
              </RadioForm>
            </ContentWrapper>
          </DashboardSelectContainer>
          <ButtonsWrapper>
            <SmallButton
              type="button"
              onClick={handleSubmit}
              dark
            >
              {t({ key: 'OK' })}
            </SmallButton>
            <SmallButton
              type="button"
              onClick={toggleMessageSetSettingsModal}
            >
              {t({ key: 'CANCEL' })}
            </SmallButton>
          </ButtonsWrapper>
        </MessageSetSettingContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default MessageSetSettings
