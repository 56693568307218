import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { unstable_scheduleCallback } from 'scheduler' // eslint-disable-line camelcase
import Root from './Root'
import '@atlaskit/css-reset'
import 'rc-slider/assets/index.css'
import './assets/react-datepicker.css'
import './assets/global-styles.css'

Component.prototype.deferSetState = function (...args) {
  unstable_scheduleCallback(() => {
    this.setState.apply(this, args) // eslint-disable-line prefer-spread
  })
}

// ReactDOM.render(<App />, document.getElementById('root'))
const root = ReactDOM.unstable_createRoot(document.getElementById('root')) // eslint-disable-line no-undef
// const ConcurrentMode = React.unstable_ConcurrentMode
root.render(<Root />)
