import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import find from 'ramda/src/find'
import uniq from 'ramda/src/uniq'
import isNil from 'ramda/src/isNil'
import assoc from 'ramda/src/assoc'
import equals from 'ramda/src/equals'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'
import eqProps from 'ramda/src/eqProps'
import isEmpty from 'ramda/src/isEmpty'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import { Radio } from 'components/Radio'
import { SearchInput } from 'components/SearchInput'
import messageActions from '../../../requests/message'
import { I18n } from '../../../lib/i18n'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  AllResultsText,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import debounce from "lodash.debounce";
import {INPUT_TEXT_DEBOUNCE_TIME} from "../../../static/appConfig";

const Inline = styled.div`
  display: flex;
  flex-direction: row;
`

const RadioWrapper = styled.div`
  margin: 5px 16px 10px 0px;
`

const { object, func, array, string } = PropTypes

class HeaderInput extends Component {
  state = {
    mode: 'address',
    value: {
      address: '',
      friendlyName: '',
    },
    suggestions: {},
    selectedAddresses: [],
    selectedFriendlyNames: [],
    selectedKeyword: '',
  }

  constructor(props) {
    super(props);
    this.getAddressSuggestionsDebounced = debounce(async (otherDashboardFilters, otherSetFilters,
                                                          dashboardFilterMode, setFilterMode, realm, token, filterName,
                                                          name, newTargetValue, suggestions) => {
      const newSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filterName,
          innerField: name,
          term: newTargetValue,
        }
      )
      this.setState({ suggestions: assoc(name, newSuggestions.data, suggestions) })
    }, INPUT_TEXT_DEBOUNCE_TIME)

    this.getSelectedAddressesSuggestionsDebounced = debounce(async (otherDashboardFilters, otherSetFilters,
                                                                    dashboardFilterMode, setFilterMode, realm, token,
                                                                    filterName, name, newTargetValue, suggestions,
                                                                    valueName) => {
      const newSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filterName,
          innerField: name,
          term: valueName,
        }
      )
      this.setState({
        suggestions: assoc(name, newSuggestions.data, suggestions),
        selectedAddresses: newTargetValue,
      })
    }, INPUT_TEXT_DEBOUNCE_TIME)
  }

  componentDidMount() {
    const { filter } = this.props
    const { term } = filter
    const { mode } = this.state
    const termMode = term.address // eslint-disable-line
      ? 'address'
      : term.friendlyName ? 'friendlyName' : null

    if (isEmpty(term)) {
      this.handleAddressInputChange({
        target: {
          name: mode,
          value: '',
        },
      })
    } else if (term.address) {
      this.handleAddressInputChange({
        target: {
          name: termMode || mode,
          value: term ? term[termMode || mode] : '',
        },
      })
    } else if (term.friendlyName) {
      this.handleFriendlyNameInputChange({
        target: {
          name: termMode || mode,
          value: term ? term[termMode || mode] : '',
        },
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props

    if (equals(prevProps.filter, filter)
      || (filter.term.friendlyName && eqProps('name', prevProps.filter, filter))
    ) return

    const { term } = filter
    const { mode } = this.state
    const termMode = term.address // eslint-disable-line
      ? 'address'
      : term.friendlyName ? 'friendlyName' : null
    if (isEmpty(term)) {
      if (mode === 'friendlyName') {
        this.handleFriendlyNameInputChange({
          target: {
            name: mode,
            value: '',
          },
        })
      } else {
        this.handleAddressInputChange({
          target: {
            name: mode,
            value: '',
          },
        })
      }
    } else if (term.address) {
      this.handleAddressInputChange({
        target: {
          name: termMode || mode,
          value: term ? term[termMode || mode] : '',
        },
      })
    } else if (term.friendlyName || mode === 'friendlyName') {
      this.handleFriendlyNameInputChange({
        target: {
          name: termMode || mode,
          value: term ? term[termMode || mode] : '',
        },
      })
    }
  }

  handleAddressInputChange = async event => {
    const {
      realm,
      token,
      filter,
      onChange,
      setFilters,
      setFilterMode,
      dashboardFilters,
      dashboardFilterMode,
    } = this.props
    const { value, suggestions, selectedAddresses } = this.state
    const { name } = event.target
    const newTargetValue = event.target.value

    if (propEq(name, newTargetValue, value) && !equals(newTargetValue, '')) return

    const otherDashboardFilters = rFilter(f => !propEq('name', filter.name)(f), dashboardFilters)
    const otherSetFilters = rFilter(f => !propEq('name', filter.name)(f), setFilters)

    if (typeof newTargetValue === 'string') {
      this.setState({
        mode: name,
        value: assoc(name, newTargetValue, value),
      })
      if (selectedAddresses.length === 0) onChange({ [name]: newTargetValue })
      this.getAddressSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode, setFilterMode,
        realm, token, filter.name, name, newTargetValue, suggestions)
    } else {
      if (selectedAddresses.length === 0) onChange({ [name]: value[name] })
      if (isNil(suggestions[name])) {
        this.setState({
          mode: name,
          selectedAddresses: newTargetValue,
        })
        this.getSelectedAddressesSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode,
          setFilterMode, realm, token, filter.name, name, newTargetValue, suggestions, value[name])
      } else {
        this.setState({
          selectedAddresses: newTargetValue,
        })
      }
    }
  }

  handleFriendlyNameInputChange = async event => {
    const {
      realm,
      token,
      filter,
      onChange,
      setFilters,
      setFilterMode,
      dashboardFilters,
      dashboardFilterMode,
    } = this.props
    const { value, suggestions } = this.state
    const { name } = event.target
    const newTargetValue = event.target.value

    if (propEq(name, newTargetValue, value) && !equals(newTargetValue, '')) return

    this.setState({ mode: name })
    if (typeof newTargetValue === 'string') {
      this.setState({ value: assoc(name, newTargetValue, value) })
    } else {
      this.setState({ selectedFriendlyNames: newTargetValue })
    }
    onChange({ [name]: newTargetValue })

    const otherDashboardFilters = rFilter(f => !propEq('name', filter.name)(f), dashboardFilters)
    const otherSetFilters = rFilter(f => !propEq('name', filter.name)(f), setFilters)

    let newSuggestions
    if (isEmpty(newTargetValue) || typeof newTargetValue !== 'string') {
      newSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filter.name,
          innerField: `${name}.raw`,
          term: '',
        }
      )
    } else {
      newSuggestions = await messageActions.getNestedTextSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filter.name,
          innerField: name,
          term: newTargetValue,
        }
      )
    }
    this.setState({ suggestions: assoc(name, uniq(newSuggestions.data), suggestions) })
  }

  handleRowClick = keyword => {
    const { mode } = this.state
    if (mode === 'address') this.handleAddressClick(keyword)
    else this.handleFriendlyNameClick(keyword)
  }

  handleAddressClick = keyword => {
    const { onChange } = this.props
    const { selectedAddresses } = this.state
    const newAddresses = symmetricDifference(selectedAddresses, [keyword])
    this.setState({ selectedAddresses: newAddresses })
    onChange({ address: newAddresses })
  }

  handleFriendlyNameClick = keyword => {
    const { onChange } = this.props
    const { value, selectedKeyword, selectedFriendlyNames } = this.state
    const newFriendlyNames = symmetricDifference(selectedFriendlyNames, [keyword])
    const newSelectedKeyword = keyword === selectedKeyword ? '' : keyword
    this.setState({
      selectedKeyword: newSelectedKeyword,
      selectedFriendlyNames: newFriendlyNames,
    })
    onChange({ friendlyName: isEmpty(newFriendlyNames) ? value.friendlyName : newFriendlyNames })
  }

  handleRadioChange = ({ value }) => {
    const { onChange } = this.props
    const { selectedAddresses, selectedKeyword } = this.state
    this.setState({ mode: value })
    onChange({ [value]: value === 'address' ? selectedAddresses : selectedKeyword })
  }

  handleClearClick = name => async () => {
    const { value, suggestions, selectedAddresses } = this.state
    const {
      realm,
      token,
      filter,
      onChange,
      setFilters,
      setFilterMode,
      dashboardFilters,
      dashboardFilterMode,
    } = this.props

    const otherDashboardFilters = rFilter(f => !propEq('name', filter.name)(f), dashboardFilters)
    const otherSetFilters = rFilter(f => !propEq('name', filter.name)(f), setFilters)

    this.setState({
      value: assoc(name, '', value),
    })
    if (selectedAddresses.length === 0) onChange({ [name]: '' })
    const getSuggestionsAction = name === 'address'
      ? messageActions.getNestedSuggestionsAction
      : messageActions.getNestedTextSuggestionsAction
    const newSuggestions = await getSuggestionsAction(
      {
        dashboardFilters: otherDashboardFilters,
        setFilters: otherSetFilters,
        dashboardFilterMode,
        setFilterMode,
      },
      {
        realm,
        token,
        field: filter.name,
        innerField: name,
        term: '',
      }
    )
    this.setState({ suggestions: assoc(name, newSuggestions.data, suggestions) })
  }

  render() {
    const { filter } = this.props
    const {
      mode,
      value,
      suggestions,
      selectedAddresses,
      selectedFriendlyNames,
    } = this.state
    const suggestionArray = propOr([], mode, suggestions)

    return (
      <I18n>
        {({ t }) => (
          <Container>
            <div>
              <Inline>
                <RadioWrapper>
                  <Radio
                    label={t({
                      key: 'FILTER_HEADER_ADDRESS',
                      variables: { label: '' },
                    })}
                    checked={mode === 'address'}
                    onChange={()=>this.handleRadioChange({value:'address'})}
                  />
                </RadioWrapper>
                <RadioWrapper>
                  <Radio
                    label={t({
                      key: 'FILTER_HEADER_FRIENDLY_NAME',
                      variables: { label: '' },
                    })}
                    checked={mode === 'friendlyName'}
                    onChange={()=>this.handleRadioChange({value:'friendlyName'})}
                  />
                </RadioWrapper>
              </Inline>
              {mode === 'address' ? (
                <SearchInput
                  key={mode}
                  autoFocus
                  value={value.address}
                  placeholder={t({
                    key: 'FILTER_HEADER_ADDRESS',
                    variables: { label: '' },
                  })}
                  name="address"
                  onChange={this.handleAddressInputChange}
                  onClear={this.handleClearClick('address')}
                />
              ) : (
                <SearchInput
                  key={mode}
                  autoFocus
                  value={value.friendlyName}
                  placeholder={t({
                    key: 'FILTER_HEADER_FRIENDLY_NAME',
                    variables: { label: '' },
                  })}
                  name="friendlyName"
                  onChange={this.handleFriendlyNameInputChange}
                  onClear={this.handleClearClick('friendlyName')}
                />
              )}
            </div>
            <SelectionHeader>
              <SelectionHeaderItem>
                {suggestionArray.length > 100 ? (
                  <StatisticsTextBold>
                    100+
                    <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                  </StatisticsTextBold>
                ) : (
                  <StatisticsTextBold>
                    {suggestionArray.length}
                    <StatisticsText>({suggestionArray.length === 1
                      ? t({ key: 'SELECTION_MATCH' })
                      : t({ key: 'SELECTION_MATCHES' })
                    })</StatisticsText>
                  </StatisticsTextBold>
                )}
              </SelectionHeaderItem>
              <SelectionHeaderItem right>
                {selectedAddresses.length > 100 ? (
                  <StatisticsTextBold>
                    100+
                    <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                  </StatisticsTextBold>
                ) : (
                  <StatisticsTextBold>
                    {selectedAddresses.length}
                    <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                  </StatisticsTextBold>
                )}
              </SelectionHeaderItem>
            </SelectionHeader>
            <SelectionWrapper>
              <SelectionColumnWrapper>
                <Scrollbar>
                  {rFilter(s => s !== '', suggestionArray).map(suggestion => {
                    const isSelected = find(equals(suggestion), mode === 'address'
                      ? selectedAddresses : selectedFriendlyNames)
                    return (
                      <SuggestionWrapper>
                        <Suggestion
                          key={suggestion}
                          isSelected={isSelected}
                          onClick={isSelected ? null
                            : () => this.handleRowClick(suggestion)
                          }
                        >
                          <SuggestionText>
                            {suggestion}
                          </SuggestionText>
                        </Suggestion>
                      </SuggestionWrapper>
                    )
                  })}
                </Scrollbar>
              </SelectionColumnWrapper>
              <Seperator>
                <SeperatorIcon />
              </Seperator>
              {mode === 'address' ? (
                <SelectionColumnWrapper right>
                  <Scrollbar>
                    {selectedAddresses.length > 0
                      ? selectedAddresses.map(address => (
                        <SuggestionWrapper>
                          <Suggestion
                            key={address}
                            isSelected={false}
                            onClick={() => this.handleRowClick(address)}
                          >
                            <SuggestionText>
                              {address}
                            </SuggestionText>
                            <CloseIcon>×</CloseIcon>
                          </Suggestion>
                        </SuggestionWrapper>
                      )) : !isEmpty(value[mode]) && (
                        <SuggestionWrapper>
                          <AllResultsText>
                            {t({ key: 'SELECTION_ALL_MATCHES_OF', variables: { value: value[mode] } })}
                          </AllResultsText>
                        </SuggestionWrapper>
                      )
                    }
                  </Scrollbar>
                </SelectionColumnWrapper>
              ) : (
                <SelectionColumnWrapper right>
                  <Scrollbar>
                    {selectedFriendlyNames.length > 0
                      ? selectedFriendlyNames.map(friendlyName => (
                        <SuggestionWrapper>
                          <Suggestion
                            key={friendlyName}
                            isSelected={false}
                            onClick={() => this.handleRowClick(friendlyName)}
                          >
                            <SuggestionText>
                              {friendlyName}
                            </SuggestionText>
                            <CloseIcon>×</CloseIcon>
                          </Suggestion>
                        </SuggestionWrapper>
                      )) : !isEmpty(value[mode]) && (
                        <SuggestionWrapper>
                          <AllResultsText>
                            {t({ key: 'SELECTION_ALL_MATCHES_OF', variables: { value: value[mode] } })}
                          </AllResultsText>
                        </SuggestionWrapper>
                      )
                    }
                  </Scrollbar>
                </SelectionColumnWrapper>
              )}
            </SelectionWrapper>
          </Container>
        )}
      </I18n>
    )
  }
}


HeaderInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default HeaderInput
