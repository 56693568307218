import React, { ReactNode, useCallback } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { useLocation } from '@reach/router'
import { useHelp } from 'providers/Help'
import { I18n } from 'lib/i18n'
import {
  useTooltipStyles,
  Container,
  Box,
  Content,
  Row,
  Headline,
  // Progress,
  Prompt,
  Footer,
  SkipButton,
  NextButton,
  LargeButton,
  Transparency,
  QuickTourStepWrapper,
} from './styles'

type QuickTourStepProps = {
  stepId: string
  children: ReactNode
  title?: string
  content?: string
  offsetTop?: number
  offsetLeft?: number
  isOutlined?: boolean
  disabled?: boolean
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
}

export const QuickTourStep = ({
  stepId,
  children,
  title,
  content,
  offsetTop,
  offsetLeft,
  isOutlined,
  disabled,
  placement = 'right',
}: QuickTourStepProps) => {
  const tooltipClasses = useTooltipStyles()
  const {
    quickTourState,
    isQuickTourEnabled,
    currentQuickTourStepId,
    advanceToNextStep,
    goToPreviousStep,
    skipTour,
  } = useHelp()
  const location = useLocation()

  const getNextButtonLabel = useCallback(
    (regular: string) => {
      const tour = quickTourState.state[location.pathname]
      const currentStepIndex = tour.steps.findIndex(
        (s) => s.id === currentQuickTourStepId
      )
      if (currentStepIndex === tour.steps.length - 1) {
        return tour.navigateTo?.label || 'End Intro'
      } else {
        return regular
      }
    },
    [location, currentQuickTourStepId, quickTourState]
  )

  const getPreviousButtonLabel = useCallback(
    (regular: string) => {
      const tour = quickTourState.state[location.pathname]
      const currentStepIndex = tour.steps.findIndex(
        (s) => s.id === currentQuickTourStepId
      )
      if (currentStepIndex === 0) {
        return tour.navigateFrom?.label || 'Quit Intro'
      } else {
        return regular
      }
    },
    [location, currentQuickTourStepId, quickTourState]
  )

  const isStepVisible =
    isQuickTourEnabled && currentQuickTourStepId === stepId && !disabled

  return (
    <QuickTourStepWrapper
      isHighlighted={isStepVisible}
      isOutlined={isStepVisible && isOutlined}
    >
      {children}
      {isStepVisible ? (
        <Container top={offsetTop} left={offsetLeft}>
          <Tooltip
            arrow
            open={true}
            interactive
            placement={placement}
            classes={tooltipClasses}
            TransitionProps={{
              timeout: 1000,
            }}
            title={
              <I18n>
                {({ t }) => (
                  <Box>
                    <Content>
                      <Row>
                        <Headline>{title}</Headline>
                        {/* <Progress>1/2</Progress> */}
                      </Row>
                      <Prompt>{content}</Prompt>
                    </Content>
                    <Footer>
                      <SkipButton onClick={skipTour}>
                        {t({ key: 'QUICK_TOUR_SKIP' })}
                      </SkipButton>
                      <div>
                        <NextButton onClick={goToPreviousStep}>
                          {getPreviousButtonLabel(
                            t({ key: 'QUICK_TOUR_PREVIOUS' })
                          )}
                        </NextButton>
                        <LargeButton onClick={advanceToNextStep}>
                          {getNextButtonLabel(t({ key: 'QUICK_TOUR_NEXT' }))}
                        </LargeButton>
                      </div>
                    </Footer>
                  </Box>
                )}
              </I18n>
            }
          >
            <Transparency />
          </Tooltip>
        </Container>
      ) : null}
    </QuickTourStepWrapper>
  )
}
