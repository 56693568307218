import React, { useState, useCallback } from 'react'
import { I18n } from 'lib/i18n'
import { Tooltip } from 'lib/util'
import { RichTooltip } from 'components/RichToolTip'
import {
  Container,
  VisualizationButton,
  DropdownContainer,
  Row,
  ChevronDownIcon,
  VisualizationText,
  PieIcon,
  BarIcon,
  LineIcon,
  AreaIcon,
  ListIcon,
  StackBarIcon,
  MapIcon,
  MapZoomableIcon,
  DefaultIcon,
} from './styles'

export type VisualizationMenuType = {
  visualization:
    | 'pie'
    | 'bar'
    | 'line'
    | 'area'
    | 'list'
    | 'stack'
    | 'stack_sender'
    | 'stack_sender_domain'
    | 'tree'
    | 'zoom'
    | 'default'
  setId: string
  onVisualizationChange: (visualization: string, setId: string) => void
}
const VISUALIZATION_LIST: Array<VisualizationMenuType['visualization']> = [
  'pie',
  'bar',
  'line',
  'area',
  'list',
  'stack',
  'stack_sender',
  'stack_sender_domain',
  'tree',
  'zoom',
]

export const VisualizationMenu = ({
  visualization,
  setId,
  onVisualizationChange,
}: VisualizationMenuType) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleVisualizationSelect = useCallback(
    (visualization: VisualizationMenuType['visualization']) => {
      setIsExpanded(false)
      onVisualizationChange(visualization, setId)
    },
    [setId, onVisualizationChange]
  )

  const VisualizationIcon = useCallback(
    ({
      visualization,
      activeOnList = false,
      selected = false,
    }: {
      visualization: VisualizationMenuType['visualization']
      activeOnList?: boolean
      selected?: boolean
    }) => {
      switch (visualization) {
        case 'pie':
          return <PieIcon activeOnList={activeOnList} selected={selected} />
        case 'bar':
          return <BarIcon activeOnList={activeOnList} selected={selected} />
        case 'line':
          return <LineIcon activeOnList={activeOnList} selected={selected} />
        case 'area':
          return <AreaIcon activeOnList={activeOnList} selected={selected} />
        case 'list':
          return <ListIcon activeOnList={activeOnList} selected={selected} />
        case 'stack':
          return (
            <StackBarIcon activeOnList={activeOnList} selected={selected} />
          )
        case 'stack_sender':
          return (
            <StackBarIcon activeOnList={activeOnList} selected={selected} />
          )
        case 'stack_sender_domain':
          return (
            <StackBarIcon activeOnList={activeOnList} selected={selected} />
          )
        case 'tree':
          return <MapIcon activeOnList={activeOnList} selected={selected} />
        case 'zoom':
          return (
            <MapZoomableIcon activeOnList={activeOnList} selected={selected} />
          )
        case 'default':
          return <DefaultIcon activeOnList={activeOnList} selected={selected} />
        default:
          return null
      }
    },
    []
  )

  return (
    <I18n>
      {({ t }) => (
        <Container data-tip data-for='visualisationSelect'>
          <RichTooltip
            content={
              <DropdownContainer>
                {VISUALIZATION_LIST.map((i) => (
                  <Row onClick={() => handleVisualizationSelect(i)}>
                    {VisualizationIcon({
                      visualization: i,
                      activeOnList: visualization === i,
                    })}
                    <VisualizationText activeOnList={visualization === i}>
                      {t({
                        key: 'VISUALISATION_OPTION',
                        variables: { name: i },
                      })}
                    </VisualizationText>
                  </Row>
                ))}
              </DropdownContainer>
            }
            open={isExpanded}
            placement='bottom'
            onClose={() => setIsExpanded(false)}
          >
            <VisualizationButton onClick={() => setIsExpanded(!isExpanded)}>
              {VisualizationIcon({
                visualization: 'default',
                selected: true,
              })}
              <ChevronDownIcon />
            </VisualizationButton>
          </RichTooltip>
          <Tooltip bottom id='visualisationSelect' delayShow={undefined}>
            <span>{t({ key: 'TOOLTIP_SELECT_DIFFERENT_VISUALISATIONS' })}</span>
          </Tooltip>
        </Container>
      )}
    </I18n>
  )
}
