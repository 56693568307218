import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import { queryCache } from 'react-query'
import mergeRight from 'ramda/src/mergeRight'
import propOr from 'ramda/src/propOr'
import Modal from 'react-modal'
import { noop } from 'lib/ramda'
import { I18n } from '../../../lib/i18n'
import { DashboardsContext } from '../../../providers/Dashboards'
import { spacing } from '../../../static/theme'
import modalStyles from '../modalStyles'
import { TextField } from '../components/TextField'
import { Button } from '../components/Button'
import {
  ContentWrapper,
  Title,
  InputWrapper,
  DeleteText,
  ButtonsWrapper,
  ImportWrapper,
  ImportTextInfo,
  DashboardInput,
  ImportButton,
  ImportText,
  DeleteImportedImg,
} from './styles'

import importIcon from 'assets/buttons/download.png'
import importedIcon from 'assets/buttons/imported.png'
import deleteImportedIcon from 'assets/buttons/deleteImported.png'
import {
  MAX_DASHBOARD_NAME_LENGTH,
  MAX_DASHBOARD_SUBTITLE_LENGTH,
} from 'static/appConfig'
import { handleMessageSetActionSubmit } from 'components/App/Dashboard/utils'

export const ActionModal = () => {
  const [name, setName] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [description, setDescription] = useState('')
  const [fileReaderInput, setFileReaderInput] = useState('')
  const [fileInputName, setFileInputName] = useState('')
  const [showFileInput, setShowFileInput] = useState(false)
  const [showFileInputError, setShowFileInputError] = useState(false)

  const context = useContext(DashboardsContext) as any
  const {
    action = '',
    selectedDashboard,
    selectedSetId,
    messageSetName,
    actionTargetType,
    isActionModalOpen,
    addDashboard,
    cloneDashboard,
    renameDashboard,
    deleteDashboard,
    deleteQuarantinePolicy,
    toggleActionModal,
    quarantinePolicyData,
    handleActionModalSubmit,
    uploadDashboard,
  } = context

  const fileInput = useRef<any>(null)

  const dashboardName: string = propOr('', 'name', selectedDashboard)
  const dashboardSubtitle: string = propOr('', 'subTitle', selectedDashboard)
  const dashboardDescription: string = propOr(
    '',
    'description',
    selectedDashboard
  )

  useEffect(() => {
    if (isActionModalOpen) {
      if (action === 'rename' || action === 'clone') {
        setName(
          action === 'clone'
            ? ''
            : actionTargetType === 'dashboard'
            ? dashboardName
            : ''
        )
        setSubTitle(dashboardSubtitle)
        setDescription(dashboardDescription)
      }
    } else {
      setName('')
      setSubTitle('')
      setDescription('')
      setFileReaderInput('')
    }
  }, [isActionModalOpen])

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      if (value.length <= MAX_DASHBOARD_NAME_LENGTH) setName(value)
    },
    []
  )

  const handleSubtitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      if (value.length <= MAX_DASHBOARD_SUBTITLE_LENGTH) setSubTitle(value)
    },
    []
  )

  const handleDescriptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDescription(event.target.value)
    },
    []
  )

  const handleDeleteImported = useCallback(
    (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      e.stopPropagation()
      setFileReaderInput('')
      setFileInputName('')
    },
    []
  )

  const handleInputFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const reader = new FileReader()
      reader.onerror = () => {
        setShowFileInputError(true)
      }
      reader.onload = () => {
        try {
          setFileReaderInput(JSON.parse(reader.result as string))

          setName('')
          setSubTitle('')
          setDescription('')
        } catch {
          setShowFileInputError(true)
        }
      }
      const files = e.target.files
      if (files !== null && files.length > 0) {
        reader.readAsText(files[0])
        setFileInputName(files[0].name)
      }
    },
    []
  )

  const handleSubmit = useCallback(async () => {
    const onAdd = addDashboard
    const onClone =
      actionTargetType === 'dashboard'
        ? cloneDashboard
        : handleActionModalSubmit('clone', selectedSetId)
    const onRename =
      actionTargetType === 'dashboard'
        ? renameDashboard
        : handleActionModalSubmit('rename', selectedSetId)
    const onDelete =
      actionTargetType === 'dashboard'
        ? deleteDashboard
        : actionTargetType === 'policy'
        ? deleteQuarantinePolicy
        : noop

    switch (action) {
      case 'add':
        fileReaderInput.length === 0
          ? await onAdd(name, subTitle, description)
          : await uploadDashboard(fileReaderInput)
        break
      case 'clone':
        await onClone(name)
        break
      case 'rename':
        await onRename(name, subTitle, description)
        break
      case 'delete':
        await onDelete()
        break
      default:
    }
    if (actionTargetType === 'dashboard') {
      queryCache.invalidateQueries('getMarketplace')
      queryCache.invalidateQueries('getQuickAccess')
      queryCache.invalidateQueries('dashboard')
    }
    toggleActionModal(noop)()
  }, [
    actionTargetType,
    addDashboard,
    cloneDashboard,
    renameDashboard,
    deleteDashboard,
    deleteQuarantinePolicy,
    name,
    subTitle,
    description,
    fileReaderInput,
  ])

  const disabledInputs = fileReaderInput.length !== 0
  const disabledActionButton =
    action !== 'delete' && !name && fileReaderInput.length === 0

  return (
    <I18n>
      {({ t }) => (
        <Modal
          isOpen={isActionModalOpen}
          style={{
            overlay: modalStyles.overlay,
            content: mergeRight(modalStyles.content, {
              padding: spacing.xxl,
              minWidth: 512,
            }),
          }}
        >
          <ContentWrapper>
            <Title>
              {t({
                key: 'MODAL_ACTION_TITLE',
                variables: {
                  action,
                  actionTargetType,
                },
              })}
            </Title>
            {action !== 'delete' && (
              <InputWrapper>
                <TextField
                  required
                  label={t({ key: 'MODAL_NAME_LABEL' })}
                  onChange={handleNameChange}
                  placeholder={t({
                    key: 'MODAL_NAME_PLACEHOLDER',
                    variables: {
                      name: dashboardName,
                      actionTargetType,
                      action,
                    },
                  })}
                  value={name}
                  disabled={disabledInputs}
                />
                {action !== 'clone' && actionTargetType === 'dashboard' && (
                  <>
                    <TextField
                      label={t({ key: 'MODAL_SUBTITLE_LABEL' })}
                      onChange={handleSubtitleChange}
                      value={subTitle}
                      disabled={disabledInputs}
                    />
                    <TextField
                      label={t({ key: 'MODAL_DESC_LABEL' })}
                      onChange={handleDescriptionChange}
                      value={description}
                      disabled={disabledInputs}
                      multiline
                      rows={3}
                    />
                  </>
                )}
              </InputWrapper>
            )}
            {action === 'delete' && (
              <DeleteText>
                {t({
                  key: 'MODAL_DELETE_LABEL',
                  variables: { name: dashboardName },
                })}
              </DeleteText>
            )}
            {action === 'add' && actionTargetType === 'dashboard' && (
              <ImportWrapper>
                <DashboardInput
                  ref={fileInput}
                  onChange={handleInputFileChange}
                  value={''}
                />
                <ImportTextInfo>
                  {t({ key: 'DASHBOARD_IMPORT_LABEL' })}
                </ImportTextInfo>
                <ImportButton
                  onClick={() => {
                    const ref = fileInput.current
                    if (ref !== null) ref.click()
                  }}
                >
                  <img
                    src={
                      fileReaderInput.length === 0 ? importIcon : importedIcon
                    }
                    alt='import'
                  />
                  <ImportText>
                    {fileReaderInput.length === 0
                      ? t({ key: 'DASHBOARD_IMPORT_BUTTON' })
                      : fileInputName}
                  </ImportText>
                  {fileReaderInput.length !== 0 && (
                    <DeleteImportedImg
                      src={deleteImportedIcon}
                      alt='import'
                      onClick={handleDeleteImported}
                    />
                  )}
                </ImportButton>
              </ImportWrapper>
            )}
            <ButtonsWrapper>
              <Button cancel onClick={toggleActionModal(noop)}>
                {t({ key: 'MODAL_CANCEL_BUTTON' })}
              </Button>
              <Button onClick={handleSubmit} disabled={disabledActionButton}>
                {t({
                  key: 'MODAL_ACTION_BUTTON',
                  variables: { action },
                })}
              </Button>
            </ButtonsWrapper>
          </ContentWrapper>
        </Modal>
      )}
    </I18n>
  )
}
