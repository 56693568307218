import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import modalStyles, {
  SmallButton,
  Label,
  CloseIcon, CloseButtonTop,
} from '../modalStyles'
import {
  OuterContainer, ContentContainer, InputContainer, Checkbox,
  Input, Title, Column, Row, DayInput, Dropdown, ButtonsWrapper, Divider
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/quarantine'
import {AuthContext} from "../../../@logicea/react-auth";

const QuarantinePolicy = () => {

  const [approvingEntity, setApprovingEntity] = useState('APPROVER')
  const [expirationAction, setExpirationAction] = useState('REJECT')
  const [policyName, setPolicyName] = useState('New_Policy')
  const [approvalExpiration, setApprovalExpiration] = useState(4)
  const [backupEnabled, setBackupEnabled] = useState(false)
  const [backupExpiration, setBackupExpiration] = useState(3)
  const [notifySender, setNotifySender] = useState(true)
  const [isNewPolicy, setIsNewPolicy] = useState(true)
  const [dirty, setDirty] = useState(false)
  const [nameChanged, setNameChanged] = useState(false)

  const [nameValid, setNameValid] = useState(true)

  const auth = useContext(AuthContext)
  const context = useContext(DashboardsContext)
  const {
    isQuarantinePolicyModalOpen,
    setQuarantinePolicyModalVisibility,
    quarantinePolicyData,
    quarantineSettingsData,
    setQuarantineSettingsData,
  } = context

  useEffect(() => {
    setIsNewPolicy(!quarantinePolicyData)
    if (quarantinePolicyData) {
      setApprovingEntity(quarantinePolicyData.approving_entity)
      setExpirationAction(quarantinePolicyData.expiration_action)
      setPolicyName(quarantinePolicyData.name)
      setApprovalExpiration(quarantinePolicyData.expiration_work_days)
      setBackupEnabled(quarantinePolicyData.rollover_on_completion)
      setBackupExpiration(quarantinePolicyData.rollover_expiration_work_days)
      setNameValid(true)
    }
  }, [quarantinePolicyData])

  const approvingEntityOptions = [
    {
      key: 'NONE',
      text: 'None - Silent',
      value: 'NONE',
    },
    {
      key: 'WARNING',
      text: 'None - Warn Sender',
      value: 'WARNING',
    },
    {
      key: 'SELF',
      text: 'Sender',
      value: 'SELF',
    },
    {
      key: 'APPROVER',
      text: 'Approver',
      value: 'APPROVER',
    },
  ]

  const expirationActionOptions = [
    {
      key: 'REJECT',
      text: 'Reject',
      value: 'REJECT',
    },
    {
      key: 'APPROVE',
      text: 'Approve',
      value: 'APPROVE',
    },
  ]

  const expirationOptions = [1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 60, 90].map(days => ({
      key: days.toString(),
      text: days.toString(),
      value: days.toString(),
    }))

  const handleChangeApprovingEntity = (evt, data) => {
    if (data.value !== approvingEntity)
      setDirty(true)
    setApprovingEntity(data.value)
  }

  const handleChangeExpirationAction = (evt, data) => {
    if (data.value !== expirationAction)
      setDirty(true)
    setExpirationAction(data.value)
  }

  const handleChangeApprovalExpiration = (evt, data) => {
    if (data.value !== approvalExpiration)
      setDirty(true)
    setApprovalExpiration(data.value)
  }

  const handleChangeBackupExpiration = (evt, data) => {
    if (data.value !== backupExpiration)
      setDirty(true)
    setBackupExpiration(data.value)
  }

  const handleChangeBackupEnabled = (evt, data) => {
    if (data.value !== backupEnabled)
      setDirty(true)
    setBackupEnabled(data.value)
  }

  const handleChangeNotifySender = (evt, data) => {
    if (data.value !== notifySender)
      setDirty(true)
    setNotifySender(data.value)
  }

  const handleChangePolicyName = (evt, data) => {
    if (data.value !== policyName) {
      setPolicyName(data.value)
      let valid = !data.value.includes(" ")
      setNameValid(valid)

      if (valid) {
        // TODO: Maybe display a warning on changing policy name
        setDirty(true)
        setNameChanged(true)
      }
    }
  }

  const handleSave = () => {
    if (!nameValid) {
      alert("This policy name is not allowed. Please verify that no spaces are present and the name does not match an existing policy")
      return
    }

    let newPolicy = {
      name: policyName,
      approving_entity: approvingEntity,
      approval_required_by: 'ALL',
      expiration_work_days: approvalExpiration,
      expiration_action: expirationAction,
      notify_sender: notifySender,
      rollover_on_completion: backupEnabled,
      rollover_expiration_work_days: backupExpiration
    }
    let doIt = dirty || isNewPolicy
    if (!isNewPolicy && dirty && nameChanged) {
      console.log("Name changed, display a warning instead")
    }
    if (doIt) {
      actions.postQuarantinePolicyAction('inky', auth, newPolicy)
      let settings = quarantineSettingsData
      settings.policies[policyName] = newPolicy
      setQuarantineSettingsData(settings)
    }
    setDirty(false)
    setQuarantinePolicyModalVisibility(false)
  }

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isQuarantinePolicyModalOpen}
        onRequestClose={() => setQuarantinePolicyModalVisibility(false)}
        contentLabel={t({ key: 'MODAL_QUARANTINE_POLICY_LABEL' })}
        style={{
          overlay: modalStyles.overlay,
          content: modalStyles.content,
        }}
      >
        <OuterContainer>
          <Title>{t({ key: 'MODAL_QUARANTINE_POLICY_LABEL' })}</Title>
          <Divider fitted/>
          <ContentContainer>
            <Row>
              <Column>
                <InputContainer>
                  <Label>Policy Name</Label>
                  <Input error={!nameValid} placeholder="name" value={policyName} disabled={!isNewPolicy} onChange={handleChangePolicyName} />
                </InputContainer>
                <InputContainer>
                  <Label>Approving Entity</Label>
                  <Dropdown selection options={approvingEntityOptions} value={approvingEntity} onChange={handleChangeApprovingEntity}></Dropdown>
                </InputContainer>
                <InputContainer>
                  <Label>Notify Sender</Label>
                  <Checkbox fitted checked={notifySender} onChange={handleChangeNotifySender}/>
                </InputContainer>
              </Column>
              <Column>
                <InputContainer>
                  <Label>Approval Expiration (business days)</Label>
                  <DayInput placeholder="4" type="number" value={approvalExpiration} onChange={handleChangeApprovalExpiration}/>
                </InputContainer>
                <InputContainer>
                  <Label>Expiration Action</Label>
                  <Dropdown selection options={expirationActionOptions} value={expirationAction} onChange={handleChangeExpirationAction}></Dropdown>
                </InputContainer>
              </Column>
              <Column>
                <InputContainer>
                  <Label>Backup on Rejection</Label>
                  <Checkbox fitted checked={backupEnabled} onChange={handleChangeBackupEnabled}/>
                </InputContainer>
                <InputContainer>
                  <Label>Backup Expiration (business days)</Label>
                  <DayInput placeholder="3" type="number" value={backupExpiration} onChange={handleChangeBackupExpiration}/>
                </InputContainer>
              </Column>
            </Row>
          </ContentContainer>
          <CloseButtonTop>
            <CloseIcon onClick={() => setQuarantinePolicyModalVisibility(false)} />
          </CloseButtonTop>
          <ButtonsWrapper>
            <SmallButton type="button" onClick={() => setQuarantinePolicyModalVisibility(false)}>{t({ key: 'CANCEL' })}</SmallButton>
            <SmallButton type="button" onClick={handleSave} dark>{t({ key: isNewPolicy ? 'CREATE' : 'SAVE' })}</SmallButton>
          </ButtonsWrapper>
        </OuterContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default QuarantinePolicy
