import axios from 'axios'
import { KOTLIN_URL } from '../static/appConfig'

// XHR requests
const getQuarantineSettingsXhr = (teamId, auth) => axios.get(
  `${KOTLIN_URL}/quarantine/${teamId}/settings`,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${auth.tokens.accessToken}`,
      'X-Team': auth.realm,
    },
  },
)

const putQuarantineSettingsXhr = (teamId, auth, data) => axios.put(
  `${KOTLIN_URL}/quarantine/${teamId}/settings`,
  data,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${auth.tokens.accessToken}`,
      'X-Team': auth.realm,
    },
  },
)

const postQuarantinePolicyXhr = (teamId, auth, newPolicy) => axios.post(
  `${KOTLIN_URL}/quarantine/${teamId}/policies`,
  newPolicy,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${auth.tokens.accessToken}`,
      'X-Team': auth.realm,
    },
  },
)

const putQuarantineApproversXhr = (teamId, auth, approvers) => axios.put(
  `${KOTLIN_URL}/quarantine/${teamId}/senders/approvers`,
  approvers,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${auth.tokens.accessToken}`,
      'X-Team': auth.realm,
    },
  },
)

const deleteQuarantinePolicyXhr = (teamId, auth, policyName) => axios.delete(
  `${KOTLIN_URL}/quarantine/${teamId}/policies/${policyName}`,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${auth.tokens.accessToken}`,
      'X-Team': auth.realm,
    },
  },
)


// Actions
const getQuarantineSettingsAction = async (teamId, auth) => {
  try {
    console.log("Auth.token is", auth)
    const res = await getQuarantineSettingsXhr(teamId, auth)
    console.log("getQuarantineSettingsXhr: ", res);
    return res.data

  } catch (err) {
    console.log(err)
    return null
  }
}

const putQuarantineSettingsAction = async (teamId, auth, newSettings) => {
  try {
    const res = await putQuarantineSettingsXhr(teamId, auth, newSettings)
    console.log("putQuarantineSettingsXhr: ", res);
    return res.data

  } catch (err) {
    console.log(err)
    return null
  }
}

const postQuarantinePolicyAction = async (teamId, auth, newPolicy) => {
  try {
    const res = await postQuarantinePolicyXhr(teamId, auth, newPolicy)
    console.log("postQuarantinePolicyXhr: ", res);
    return res.data

  } catch (err) {
    console.log(err)
    return null
  }
}

const putQuarantineApproversAction = async (teamId, auth, approvers) => {
  try {
    const res = await putQuarantineApproversXhr(teamId, auth, approvers)
    console.log("putQuarantineApproversXhr: ", res);
    return res.data

  } catch (err) {
    console.log(err)
    return null
  }
}

const deleteQuarantinePolicyAction = async (teamId, auth, policyName) => {
  try {
    console.log("Delete quarantine policy action "+ policyName)
    const res = await deleteQuarantinePolicyXhr(teamId, auth, policyName)
    console.log("deleteQuarantinePolicyAction: ", res);
    return res.data

  } catch (err) {
    console.log(err)
    return null
  }
}

const actions = {
  getQuarantineSettingsAction,
  putQuarantineSettingsAction,
  postQuarantinePolicyAction,
  putQuarantineApproversAction,
  deleteQuarantinePolicyAction,
}

export default actions