import styled from 'styled-components'
import {
  BACKGROUND_LIGHT,
  LIGHT_GREY,
  DARK_TEXT,
  MEDIUM_TEXT,
} from '../../../static/colors'
import {
  MIN_COLUMN_HEIGHT,
} from '../../../static/appConfig'
import tabsIcon from '../../../assets/tabs/tabs.png'
import tilesIcon from '../../../assets/tiles/tiles.png'
import closeIcon from '../../../assets/close/close.png'

const HEADER_HEIGHT = 56
const OVERLAY_HEIGHT = 40

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  min-width: ${MIN_COLUMN_HEIGHT}px;
  min-height: ${MIN_COLUMN_HEIGHT}px;
  padding-top: 10px;
  flex-direction: ${props => (props.direction === 'horizontal' ? 'column' : 'row')};
`

export const Header = styled.div`
  position: relative;
  min-height: ${HEADER_HEIGHT}px;
  min-width: ${HEADER_HEIGHT - 10}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
  padding: ${props => (props.direction === 'horizontal' ? '12px 10px 10px 16px' : '12px 10px 0px 0px')};
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: ${props => (props.active ? DARK_TEXT : MEDIUM_TEXT)};
  background-color: ${props => (props.active ? BACKGROUND_LIGHT : LIGHT_GREY)};
  border-radius: 18px;
  ${props => (props.left ? '' : 'border-left: none;')}

  &:hover {
    cursor: pointer;
  }
`

export const ButtonText = styled.div`
  margin-right: 8px;
  margin-left: 6px;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
`

export const TilesIcon = styled.img.attrs({
  src: tilesIcon,
  alt: 'tiles',
})`
  width: 20px;
  height: 18px;
  padding: 2px;
  filter: opacity(.6);
`

export const TabsIcon = styled.img.attrs({
  src: tabsIcon,
  alt: 'tabs',
})`
  width: 20px;
  height: 18px;
  padding: 2px;
  filter: opacity(.6);
`

export const DeleteButton = styled.div`
  height: 25px;
  width: 25px;
  margin-top: ${props => (props.direction === 'horizontal' ? '0px' : '10px')};
  margin-left: ${props => (props.direction === 'horizontal' ? '10px' : '5px')};

  &:hover {
    cursor: pointer;
  }
`
export const DeleteIcon = styled.img.attrs({
  src: closeIcon,
  alt: 'remove',
})`
  width: 20px;
  height: 22px;
  padding: 2px;
`

export const SetList = styled.div`
  transition: background-color 0.2s ease;
  display: flex;
  flex-grow: 1;
  flex-direction: ${props => (props.direction === 'horizontal' ? 'column' : 'row')};
  overflow-x: ${props => (props.direction === 'horizontal' ? 'hidden' : 'auto')};
  overflow-y: ${props => (props.direction === 'horizontal' ? 'auto' : 'hidden')};
  margin: 0px 8px 0px 8px;
  padding-top: 8px;
`

// 8 = The margin around set list children
export const ListPadding = styled.div`
  min-width: ${OVERLAY_HEIGHT - 8}px;
  min-height: ${OVERLAY_HEIGHT - 8}px;
`

const overlayStyles = `
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
`
export const RightOverlay = styled.div`
  ${overlayStyles}
  margin-top: ${HEADER_HEIGHT}px;
  background-image: linear-gradient(to left, ${BACKGROUND_LIGHT}, rgba(255, 255, 255, 0) ${OVERLAY_HEIGHT}px);
`

export const LeftOverlay = styled.div`
  ${overlayStyles}
  margin-top: ${HEADER_HEIGHT}px;
  background-image: linear-gradient(to right, ${BACKGROUND_LIGHT}, rgba(255, 255, 255, 0) ${OVERLAY_HEIGHT}px);
`

export const TopOverlay = styled.div`
  ${overlayStyles}
  margin-left: ${HEADER_HEIGHT}px;
  background-image: linear-gradient(to bottom, ${BACKGROUND_LIGHT}, rgba(255, 255, 255, 0) ${OVERLAY_HEIGHT}px);
`

export const BottomOverlay = styled.div`
  ${overlayStyles}
  bottom: 0px;
  margin-left: ${HEADER_HEIGHT}px;
  background-image: linear-gradient(to top, ${BACKGROUND_LIGHT}, rgba(255, 255, 255, 0) ${OVERLAY_HEIGHT}px);
`
