import React from 'react'
import { I18n } from 'lib/i18n'
import { Tooltip, filterToString } from 'lib/util'
import { CATEGORY_MAP, FILTER_CATEGORY_COLORS } from 'static/filterNames'
import { FilterT } from 'components/App/Dashboard/types'
import { Container, InvertIcon, Name, Value, ClearIcon } from './styles'

export const FilterItem = ({
  filter,
  isEditable,
  showRemoveAndNegateFilterButton,
  onClick,
  onToogleFilter,
  onRemoveFilter,
}: {
  filter: FilterT
  isEditable: Boolean
  showRemoveAndNegateFilterButton: Boolean
  onClick: () => void
  onToogleFilter: () => void
  onRemoveFilter: () => void
}) => {
  const isNegative = filter.not

  return (
    <I18n>
      {({ locale, t }) => (
        <Container
          isNegative={isNegative}
          category={
            CATEGORY_MAP[filter.name] as keyof typeof FILTER_CATEGORY_COLORS
          }
          isEditable={isEditable}
          onClick={onClick}
        >
          {showRemoveAndNegateFilterButton && (
            <InvertIcon
              data-tip
              data-for='negateFilterButton'
              isNegative={isNegative}
              onClick={(e) => {
                e.stopPropagation()
                onToogleFilter()
              }}
            />
          )}
          <Tooltip bottom id='negateFilterButton' delayShow={undefined}>
            <span>{t({ key: 'TOOLTIP_NEGATE_FILTER' })}</span>
          </Tooltip>
          <Name>{t({ key: filter.name }) || filter.name}:</Name>
          <Value>
            {filterToString(filter.term, filter.type, filter.name, t, locale)}
          </Value>
          {showRemoveAndNegateFilterButton && (
            <ClearIcon
              data-tip
              data-for='removeFilterButton'
              onClick={(e) => {
                e.stopPropagation()
                onRemoveFilter()
              }}
            />
          )}
          <Tooltip bottom id='removeFilterButton' delayShow={undefined}>
            <span>{t({ key: 'TOOLTIP_REMOVE_FILTER' })}</span>
          </Tooltip>
        </Container>
      )}
    </I18n>
  )
}
