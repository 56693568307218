import styled from 'styled-components'
import {
    colors,
    HeadlineH3DarkGreyBold,
} from 'static/theme'
import { ReactComponent as WidgetSVG } from 'assets/marketplace/widget.svg'

export const WidgetIcon = styled(WidgetSVG)`
  fill: ${colors.primaryDark};
  margin: 0 26px;
  width: 96px;
  height: 96px;
`
export const Title = styled(HeadlineH3DarkGreyBold)`

`
export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`