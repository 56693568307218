import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '@logicea/react-auth'
import { I18n } from 'lib/i18n'
import {
  getQuickAccessAction,
  getMarketplaceAction,
} from 'requests/marketplace'
import { QuickTourStep } from 'components/Help/QuickTourStep'
import { QuickAccessItem } from 'components/QuickAccess/QuickAccessItem'
import { Container, Title, Dashboards, DashboardsWrapper } from './styles'

export const QuickAccess = () => {
  const auth = useContext(AuthContext)
  const { realm, tokens }: any = auth

  const { data: pinnedDashboardIds } = useQuery(
    [
      'pinnedDashboards',
      {
        realm,
        token: tokens?.accessToken,
      },
    ],
    getQuickAccessAction,
    { enabled: tokens }
  )

  const { data } = useQuery(
    [
      'getQuickAccess',
      {
        realm,
        token: tokens?.accessToken,
        limit: 4,
        offset: 0,
        ids: pinnedDashboardIds,
      },
    ],
    getMarketplaceAction,
    { enabled: tokens && pinnedDashboardIds?.length }
  )

  return (
    <I18n>
      {({ t }) => (
        <QuickTourStep
          placement='bottom'
          stepId='quickAccess'
          title={t({ key: 'QUICK_TOUR_QUICK_ACCESS_TITLE' })}
          content={t({ key: 'QUICK_TOUR_QUICK_ACCESS_CONTENT' })}
        >
          <Container>
            <Title>{t({ key: 'MARKETPLACE_QUICK_ACCESS_TITLE' })}</Title>
            <Dashboards>
              <DashboardsWrapper>
                {data?.data.slice(0, 2).map((i) => (
                  <QuickAccessItem item={i} />
                ))}
              </DashboardsWrapper>
              <DashboardsWrapper>
                {data?.data.slice(2, 4).map((i) => (
                  <QuickAccessItem item={i} />
                ))}
              </DashboardsWrapper>
            </Dashboards>
          </Container>
        </QuickTourStep>
      )}
    </I18n>
  )
}
