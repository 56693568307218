import React from 'react'
import ReactDOM from 'react-dom'
import {
  Cell,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
} from 'recharts'
import {
  DARK_GREY,
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
  VISUALIZATION_TICK_DARK,
} from '../../../static/colors'
import { convertChart } from '../exportHelpers'
import { TickText } from './styles'

const CountTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <TickText
      x={5}
      y={4}
      textAnchor="middle"
      fill={DARK_GREY}
    >
      {payload.value}
    </TickText>
  </g>
)

export const exportChart = (aggregationData, scale, domain, minPointSize) => {

  // Output image size
  const WIDTH = 1920;
  const HEIGHT = 1080;

  const chart = (
    <BarChart
      width={WIDTH}
      height={HEIGHT}
      data={aggregationData}
      ref={ref => convertChart(ref, WIDTH, HEIGHT)}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="email"
        tickMargin={12}
        tick={<CountTick />}
        tickLine={{ stroke: VISUALIZATION_TICK_DARK, strokeWidth: 2.64 }}
        axisLine={false}
      />
      <YAxis
        yAxisId="b"
        type="number"
        domain={domain}
        scale={scale}
        axisLine={false}
        tickLine={false}
        tickMargin={46}
        tick={<CountTick />}
        padding={{ top: 20, bottom: 4 }}
        allowDataOverflow
      />
      <Bar
        stackId="a"
        yAxisId="b"
        dataKey="countSafe"
        isAnimationActive={false}
        minPointSize={minPointSize}
      >
        {aggregationData.map((entry, index) => (
          <Cell
            key={`cell-${index}`} // eslint-disable-line react/no-array-index-key
            fill={CHART_SAFE}
          />
        ))}
      </Bar>
      <Bar
        stackId="a"
        yAxisId="b"
        dataKey="countCaution"
        isAnimationActive={false}
        minPointSize={minPointSize}
      >
        {
          aggregationData.map((entry, index) => (
            <Cell
              key={`cell-${index}`} // eslint-disable-line react/no-array-index-key
              fill={CHART_CAUTION}
            />
          ))
        }
      </Bar>
      <Bar
        stackId="a"
        yAxisId="b"
        dataKey="countDanger"
        isAnimationActive={false}
        minPointSize={minPointSize}
      >
        {
          aggregationData.map((entry, index) => (
            <Cell
              key={`cell-${index}`} // eslint-disable-line react/no-array-index-key
              fill={CHART_DANGER}
            />
          ))
        }
      </Bar>
    </BarChart>
  )

  // Render chart component into helper div
  const helperDiv = document.createElement('tmp');
  ReactDOM.render(chart, helperDiv);
}
