import React from 'react'
import {
  Box,
  BoxLarge,
  Horizontal,
  HorizontalBox,
  Vertical,
  Pi,
  Row,
  Epsilon,
  EpsilonColumn,
  BackButton,
  BackIcon,
  VerticalSeparatorGroup,
  HorizontalSeparatorGroup,
  RowSeparator,
  ColumnSeparator,
  HorizontalMargin,
} from './styles'

const Layout = ({ name, first, second, third, onMessageClose, onResultsClose }) => {
  switch (name) {
    case 'box':
      return (
        <Box>
          {third && (
            <BackButton onClick={onMessageClose}>
              <BackIcon />
            </BackButton>
          )}
          {!third && second && (
            <BackButton onClick={onResultsClose}>
              <BackIcon />
            </BackButton>
          )}
          {third || second || first}
        </Box>
      )
    case 'horizontal':
      return (
        <Horizontal>
          <Row><BoxLarge>{first}</BoxLarge></Row>
          {second && <HorizontalBox>{second}</HorizontalBox>}
          {third && (
            <React.Fragment>
              <HorizontalMargin />
              <HorizontalSeparatorGroup>
                <RowSeparator />
                <RowSeparator />
                <RowSeparator />
              </HorizontalSeparatorGroup>
              <HorizontalBox>{third}</HorizontalBox>
            </React.Fragment>
          )}
        </Horizontal>
      )
    case 'vertical':
      return (
        <Vertical>
          <BoxLarge>{first}</BoxLarge>
          {second && <Box>{second}</Box>}
          {third && <Box>{third}</Box>}
        </Vertical>
      )
    case 'pi':
      return (
        <Pi>
          <Row>
            <Box>{first}</Box>
          </Row>
          { (second || third) && (
            <React.Fragment>
              <HorizontalSeparatorGroup>
                <RowSeparator />
                <RowSeparator />
                <RowSeparator />
              </HorizontalSeparatorGroup>
              <Row>
                {second && <Box>{second}</Box>}
                {third && (
                  <React.Fragment>
                    <VerticalSeparatorGroup>
                      <ColumnSeparator />
                      <ColumnSeparator />
                      <ColumnSeparator />
                    </VerticalSeparatorGroup>
                    <Box>{third}</Box>
                  </React.Fragment>
                )}
              </Row>
            </React.Fragment>
          )}
        </Pi>
      )
    case 'epsilon':
      return (
        <Epsilon>
          <EpsilonColumn>
            <Box>{first}</Box>
          </EpsilonColumn>
          { (second || third) && (
            <React.Fragment>
              <VerticalSeparatorGroup>
                <ColumnSeparator />
                <ColumnSeparator />
                <ColumnSeparator />
              </VerticalSeparatorGroup>
              <EpsilonColumn>
                {second && <Box>{second}</Box>}
                {third && (
                  <React.Fragment>
                    <HorizontalSeparatorGroup>
                      <RowSeparator />
                      <RowSeparator />
                      <RowSeparator />
                    </HorizontalSeparatorGroup>
                    <Box>{third}</Box>
                  </React.Fragment>
                )}
              </EpsilonColumn>
            </React.Fragment>
          )}
        </Epsilon>
      )
    default:
      return <Box />
  }
}

export default Layout
