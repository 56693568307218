import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import isEmpty from 'ramda/src/isEmpty'
import assocPath from 'ramda/src/assocPath'
import modalStyles, {
  ContentWrapper,
  ButtonsWrapper,
  SmallButton,
  ModalTitle,
  Label,
  Content,
  Table,
  LabelRow,
  PermissionRow,
  Title,
  Cell,
  Checkbox,
  RightArea,
  CloseButton,
  CloseIcon,
} from '../modalStyles'
import {
  UserPermissionsContainer,
  UserSelectContainer,
  PermissionsArea,
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'
import { permissionsTable, initialUserValues } from '../../../static/initialPermissions'
import { I18n } from '../../../lib/i18n'

const UserPermissions = () => {
  const [users, setUsers] = useState([])
  const [selectedUserIndex] = useState(null)

  const context = useContext(DashboardsContext)
  const { isUserPermissionsModalOpen, toggleUserPermissionsModal } = context

  useEffect(() => {
    if (isUserPermissionsModalOpen) onModalOpen()
  }, [isUserPermissionsModalOpen])

  const onModalOpen = () => {
    const selectedUser = users[selectedUserIndex]
    const userPermissions = isNil(selectedUser) ? initialUserValues : selectedUser.permissions
    const permissions = isEmpty(userPermissions) ? initialUserValues : userPermissions
    setUsers(assocPath([selectedUserIndex, 'permissions'], permissions, users))
  }

  const permissions = pathOr(initialUserValues, [selectedUserIndex, 'permissions'], users)

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isUserPermissionsModalOpen}
        onRequestClose={toggleUserPermissionsModal}
        contentLabel={t({ key: 'MODAL_USER_PERMISSIONS_CONTENT_LABEL' })}
        style={{
          overlay: modalStyles.overlay,
          content: modalStyles.content,
        }}
      >
        <UserPermissionsContainer>
          <ContentWrapper>
            <UserSelectContainer>
              <ModalTitle>{t({ key: 'MODAL_USER_PERMISSIONS_TITLE' })}</ModalTitle>
              <Label>{t({ key: 'MODAL_USER_PERMISSIONS_LABEL' })}</Label>
              <Content />
            </UserSelectContainer>
            <PermissionsArea>
              <Label>{t({ key: 'MODAL_USER_PERMISSIONS_AREA_LABEL' })}</Label>
              <Table>
                {permissionsTable.sections.map(section => (
                  <React.Fragment key={section.name}>
                    <LabelRow>{section.label}</LabelRow>
                    {section.rows.map(row => (
                      <PermissionRow key={row.name}>
                        <Title>{row.label}</Title>
                        <Cell><Checkbox checked={permissions[row.name]} onChange={() => {}} /></Cell>
                      </PermissionRow>
                    ))}
                  </React.Fragment>
                ))}
              </Table>
            </PermissionsArea>
            <RightArea>
              <CloseButton onClick={toggleUserPermissionsModal}>
                <CloseIcon />
              </CloseButton>
              <ButtonsWrapper>
                <SmallButton type="button" onClick={toggleUserPermissionsModal} dark>{t({ key: 'OK' })}</SmallButton>
                <SmallButton type="button" onClick={toggleUserPermissionsModal}>{t({ key: 'CANCEL' })}</SmallButton>
              </ButtonsWrapper>
            </RightArea>
          </ContentWrapper>
        </UserPermissionsContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default UserPermissions
