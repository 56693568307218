import styled from 'styled-components'
import {
  HeadlineH3PrimaryMainBlack,
  Body1PrimaryDarkBold,
  Body1DarkGreyRegular,
  BodyXSPrimaryDarkRegular,
  BodyXSDarkGreyRegular,
  spacing,
  colors,
} from 'static/theme'
import { ReactComponent as PrivateSVG } from 'assets/marketplace/private.svg'
import { ReactComponent as SharedSVG } from 'assets/marketplace/shared.svg'
import { ReactComponent as PublicSVG } from 'assets/marketplace/public.svg'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.s};
  margin-top: ${spacing.s};
  border-radius: 4px;
  background-color: ${colors.white};
  max-height: 146px;
`

export const Details = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 2rem;
  cursor: pointer;
`

export const Title = styled(HeadlineH3PrimaryMainBlack)`
  margin-bottom: ${spacing.xs};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Subtitle = styled(Body1PrimaryDarkBold)`
  margin-bottom: ${spacing.xs};
  min-height: 17px;
  max-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Description = styled(Body1DarkGreyRegular)`
  margin-bottom: ${spacing.s};
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const Row = styled.div`
  display: flex;
`

export const Owner = styled.div`
  display: flex;
`

export const Created = styled.div`
  display: flex;
  margin-left: ${spacing.s};
`

export const LastUpdated = styled.div`
  display: flex;
  margin-left: ${spacing.s};
`

export const BodyXSPrimaryDark = styled(BodyXSPrimaryDarkRegular)`
  white-space: nowrap;
`

export const BodyXSDarkGrey = styled(BodyXSDarkGreyRegular)`
  margin: 0rem ${spacing.xxs};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const PrivateIcon = styled(PrivateSVG)`
  fill: ${colors.primaryLight};
`

export const SharedIcon = styled(SharedSVG)`
  fill: ${colors.primaryLight};
`

export const PublicIcon = styled(PublicSVG)`
  fill: ${colors.primaryLight};
`
