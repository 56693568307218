import styled from 'styled-components'
import {Icon, Popup, Checkbox as SemanticCheckbox, Loader} from 'semantic-ui-react'
import {
  WHITE,
  BORDER_MEDIUM,
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
  PRIMARY_DARK,
  BACKGROUND_LIGHT,
  DARK_TEXT,
  BACKGROUND_MEDIUM,
  BACKGROUND_DARK, HIGHLIGHTED_GREEN, MEDIUM_GREY, IMPORTANT_RED,
} from '../../static/colors'

const THREAT_COLORS = [CHART_SAFE, CHART_CAUTION, CHART_DANGER]

const HEADER_HEIGHT = 24
const HEADER_ROW_HEIGHT = 42

const ActionBarIcon = styled(Icon)`
  padding-right: 8px;
`

export const Container = styled.div`
  flex: 1;
  min-width: 100%;
  min-height: 100%;
  overflow: auto;
  border: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
`

export const FlexList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Header = styled.div`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const BoldText = styled.h4`
  margin-top: 0px;
  height: 24px;
`

export const Statistics = styled.span`
  margin-left: 20px;
`

export const ActionBar = styled.div`
  color: ${PRIMARY_DARK};
  font-size: 16px;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  display: flex;
  align-items: flex-start;
  flex: 1;
`

export const ActionBarRow = styled.div`
  flex-direction: row;
  align-items: center;
  flex: 1;
`

export const FlagTooltip = styled(Popup).attrs(props => ({
  trigger: props.trigger,
  content: 'Report as misjudged',
  position: 'top center',
  inverted: true,
}))``

export const UnflagTooltip = styled(Popup).attrs(props => ({
  trigger: props.trigger,
  content: 'Undo report',
  position: 'top center',
  inverted: true,
}))``

export const TagTooltip = styled(Popup).attrs(props => ({
  trigger: props.trigger,
  content: 'Add a custom tag',
  position: 'top center',
  inverted: true,
}))``

export const TagIcon = styled(ActionBarIcon).attrs({
  name: 'tag',
})`
  margin: 0!important;
  font-size: 14px !important;
    cursor: pointer;
`

export const UntagTooltip = styled(Popup).attrs(props => ({
  trigger: props.trigger,
  content: 'Remove custom tags',
  position: 'top center',
  inverted: true,
}))``

export const UntagIcon = styled(ActionBarIcon).attrs({
  name: 'delete calendar',
})`
  font-size: 14px !important;
    cursor: pointer;
`

export const ViewedTooltip = styled(Popup).attrs(props => ({
  trigger: props.trigger,
  content: 'Mark as viewed',
  position: 'top center',
  inverted: true,
}))``

export const ViewedIcon = styled(Icon).attrs({
  name: 'envelope open',
})`
  font-size: 14px !important;
    cursor: pointer;
`

export const UnreadTooltip = styled(Popup).attrs(props => ({
  trigger: props.trigger,
  content: 'Mark as unchecked',
  position: 'top center',
  inverted: true,
}))``

export const UnreadIcon = styled(Icon).attrs({
  name: 'mail',
})`
  font-size: 14px !important;
  cursor: pointer;
`

export const WarningTooltip = styled(Popup).attrs(props => ({
  trigger: props.trigger,
  content: 'Flag as important',
  position: 'top center',
  inverted: true,
}))``

export const FlagIcon = styled(ActionBarIcon).attrs(props => ({
  name: props.isFlagged ? 'flag' : 'flag outline',
}))`
  cursor: pointer;
  font-size: 14px !important;
  color: ${props => props.isFlagged ? PRIMARY_DARK : MEDIUM_GREY}
`

export const WarningIcon = styled(ActionBarIcon).attrs(props => ({
  name: props.isActive ? 'warning sign' : 'warning sign',
}))`
  cursor: pointer;
  font-size: 14px !important;
  color: ${props => props.isActive ? IMPORTANT_RED : MEDIUM_GREY}
`

export const DropdownSelect = styled.div`
  width: 30px;
  display: inline;
  position: relative;
`

export const AllowlistIcon = styled(ActionBarIcon).attrs({
  name: 'check',
})`
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  z-index: 1;
  font-size: 14px !important;
`

export const TakeNoActionClear = styled(ActionBarIcon).attrs({
  name: 'ban',
})`
  color: #ff000055;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  font-size: 14px !important;
`

export const BlocklistIcon = styled(ActionBarIcon).attrs({
  name: 'ban',
})`
  color: #a00;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  font-size: 14px !important;
`

export const PolicyIcon = styled(ActionBarIcon).attrs({
  name: 'cogs',
})`
  color: #a00;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  font-size: 14px !important;
`

export const PolicyAddedIcon = styled(ActionBarIcon).attrs({
  name: 'cogs',
})`
  color: #a00;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  font-size: 14px !important;
`

export const TrashIcon = styled(ActionBarIcon).attrs({
  name: 'trash',
})`
  font-size: 13px !important;
  cursor: pointer;
  padding-left: 4px;
  color: ${props => (props.viewonly === "true" ? "#888" : "#a00")};
`

export const NoActionTakenIcon = styled(ActionBarIcon).attrs({
  name: 'thumbs up outline',
})`
  padding: 0;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px !important;
  cursor: pointer;
  color: ${props => (props.viewonly === "true" ? "#888" : "#060")};
`

export const ClearTooltip = styled(Popup).attrs(props => ({
  trigger: props.trigger,
  content: 'Remove flag',
  position: 'top center',
  inverted: true,
}))``

export const ClearIcon = styled(Icon).attrs({
  name: 'check circle',
})`
  font-size: 14px !important;
  cursor: pointer;
`

export const Row = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid ${BORDER_MEDIUM};
  background-color: ${BACKGROUND_LIGHT};
  transition: background-color 0.3s;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: ${BACKGROUND_MEDIUM};
  }
`

export const HeaderRow = styled.div`
  height: ${HEADER_ROW_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 10px;
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 13px;
  line-height: 25px;
  z-index: 1;
  border-bottom: 2px solid ${BACKGROUND_DARK};
`

export const FlexListContainer = styled.div`
  position: absolute;
  overflow: hidden;
  top: ${HEADER_ROW_HEIGHT}px;
`

export const ListContainer = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height - HEADER_ROW_HEIGHT}px;
`

export const EmptyList = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
`

export const ListGradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 32px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${WHITE});
  pointer-events: none;
  opacity: ${props => (props.visible ? 1 : 0)};
`

export const ThreatHeader = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  width: 38px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  
  &:hover {
    cursor: pointer;
  }
`

export const ThreatBullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${props => THREAT_COLORS[props.threat]};
  margin-left: 18px;
  margin-right: 18px;
`

export const TagsHeader = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  width: 25px;
`

export const TagsField = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  width: 25px
  text-align: center;
  font-size: 12px;
`

export const RelativeLoader = styled(Loader).attrs({
  active: true,
  inline: true,
  size: 'small',
})`
  position: absolute !important;
  left: ${props => props.leftoffset}px !important;
`

export const AllowBlockListFieldHeader = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  width: 40px;
  position:relative;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};

  &:hover {
    cursor: pointer;
  }
`

export const ActionFieldHeader = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  width: 85px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};

  &:hover {
    cursor: pointer;
  }
`

export const FieldHeader = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};

  &:hover {
    cursor: pointer;
  }
`

export const DoubleFieldHeader = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  flex: 2;
  display: flex;
  align-items: center;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};

  &:hover {
    cursor: pointer;
  }
`

export const DateFieldHeader = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  width: 115px;
  display: inline;

  &:hover {
    cursor: pointer;
  }
`

const fieldStyles = `
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 13px;
  line-height: 25px;
  overflow: hidden;
`

export const AllowBlockListField = styled.div`
  ${fieldStyles}
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 40px;
`

export const ActionField = styled.div`
  ${fieldStyles}
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 85px;
`

export const Field = styled.div`
  ${fieldStyles}
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`

export const DateField = styled.div`
  ${fieldStyles}
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 115px;
`

export const DoubleField = styled.div`
  ${fieldStyles}
  flex: 2;
`

export const MessageLink = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

export const Checkbox = styled(SemanticCheckbox)``

export const ChevronUp = styled(Icon).attrs({
  name: 'chevron up',
  size: 'small',
})`

`

export const ChevronDown = styled(Icon).attrs({
  name: 'chevron down',
  size: 'small',
})`
  
`

export const SeenIcon = styled(Icon).attrs({
  name: 'eye',
  size: 'small',
})`
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  color: ${props => (props.highlighted === 'true' ? `${HIGHLIGHTED_GREEN}` : `${MEDIUM_GREY}`)};
  opacity: 0.8 !important;
  background-color: ${WHITE};
  font-size: 14px !important;
`

export const ActionBarSeparator = styled.div`
  padding-right: 6px;
  display: inline;
`
