import React, { useContext, useEffect } from 'react'
import { AuthContext } from '@logicea/react-auth'
import { navigate } from '@reach/router'
import { I18n } from '../../../lib/i18n'
import {
  Container,
  Content,
  Left,
  NameIcon,
  Title,
  WebsiteLink,
  Right,
  Form,
  LabelCenter,
  BottomLinkText,
} from '../authStyles'

const Unauthorized = () => {
  const auth = useContext(AuthContext)

  let email = auth.lastLoginEmail?.toString()
  useEffect(() => {
    auth.logout()
  }, [])

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Content>
            <Left>
              <NameIcon />
              <Title>{t({ key: 'AUTH_PAGE_TITLE' })}</Title>
              <WebsiteLink href="https://www.inky.com">
                {t({ key: 'AUTH_WEBSITE_LABEL' })}
              </WebsiteLink>
            </Left>
            <Right>
              <Form>
                {email ? (
                  <LabelCenter>{t({ key: 'AUTH_UNAUTHORIZED' })}<b>{email}</b></LabelCenter>
                ) : (
                  <LabelCenter>{t({ key: 'AUTH_NOT_LOGGED_IN' })}</LabelCenter>
                )}
                <BottomLinkText onClick={() => navigate('/authentication/login')}>
                  {t({ key: email ? 'AUTH_TRY_AGAIN' : 'AUTH_LOGIN' })}
                </BottomLinkText>
              </Form>
            </Right>
          </Content>
        </Container>
      )}
    </I18n>
  )
}

export default Unauthorized
