import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { SelectProps, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ReactComponent as DropdownSVG } from 'assets/dropdownIcon/chevron-down.svg'
import { colors } from 'static/theme'

const DropdownIcon = styled(DropdownSVG)`
  fill: ${colors.primaryDark};
`

const useStyles = makeStyles({
  root: {
    marginRight: 16,
  },
  inputLabel: {
    color: '#353c43',
    lineHeight: '6px',
  },
  inputRoot: {
    color: '#353c43',
    padding: '2px 40px 2px 9px !important',
  },
  endAdornment: {
    top: 'unset',
  },
})

export type DropdownAutocompleteOption = {
  label: string
  value: string
}

export type DropdownAutocompleteProps = {
  options: Array<DropdownAutocompleteOption>
  width: string
  placeholder: string
  onChange: (event: any, newValue: DropdownAutocompleteOption | any) => void
  value: DropdownAutocompleteOption
} & SelectProps

const DropdownAutocomplete = ({
  options,
  width,
  placeholder,
  onChange,
  value,
  ...rest
}: DropdownAutocompleteProps) => {
  const classes = useStyles()
  return (
    <Autocomplete
      size='small'
      options={options}
      popupIcon={<DropdownIcon />}
      getOptionLabel={(option: DropdownAutocompleteOption) => option.label}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          InputLabelProps={{ className: classes.inputLabel }}
          InputProps={{ ...params.InputProps, color: 'secondary' }}
          label={placeholder}
        />
      )}
      classes={classes}
      style={{ width: width }}
      onChange={onChange}
    />
  )
}

export { DropdownAutocomplete }
