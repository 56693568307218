import React, { Suspense } from 'react'
import { Redirect } from '@reach/router'
import { Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import ErrorBoundary from './ErrorBoundary'
import { useWithPermission } from '../@logicea/react-auth'

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
`

function Error() {
  return <div>ERROR!</div>
}

function Page({
  permissions = [],
  authFallback = null,
  authFallbackUrl = '/authentication/login',
  errorFallback = <Error />,
  loadingFallback = <Loader active />,
  children,
}) {
  authFallback = authFallback || <Redirect to={authFallbackUrl} />
  const [accessGranded] = useWithPermission(permissions)

  return (
    <Container>
      <Suspense fallback={loadingFallback}>
        <ErrorBoundary fallback={errorFallback}>
          {accessGranded ? children : authFallback}
        </ErrorBoundary>
      </Suspense>
    </Container>
  )
}

export default Page
