import React, { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { motion } from 'framer-motion'
import { number as formatNumber } from 'format-message'
import { AuthContext } from '@logicea/react-auth'
import { I18n } from 'lib/i18n'
import { useReports } from 'providers/Reports'
import { RadialPie } from 'components/ReportComponents/RadialPie'
import { aggregateByThreatLevel } from 'requests/messageRQ'
import { colors } from 'static/theme'
import {
  Container,
  Content,
  Column,
  Text,
  Headline,
  Bucket,
  Bullet,
  Label,
  Value,
} from './styles'
import { buildReportDashboardFilters } from "../../../lib/util";

const OverallThreatLevel = ({
  isAnimationActive,
}: {
  isAnimationActive: boolean
}) => {
  const { timeframe, teamFilter } = useReports()
  const { from, to } = timeframe
  const filterArgs = {
    dashboardFilters: buildReportDashboardFilters(from, to, teamFilter),
    setFilters: [],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const auth = useContext(AuthContext)
  const { realm, tokens, user } = auth as any
  const queryArgs = { realm, token: tokens?.accessToken }

  const { data } = useQuery(
    ['threat-level', filterArgs, queryArgs],
    aggregateByThreatLevel,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const counts = useMemo(() => {
    const neutral = data?.find((pair) => pair.threatLevel === 0)?.value ?? 0
    const caution = data?.find((pair) => pair.threatLevel === 1)?.value ?? 0
    const danger = data?.find((pair) => pair.threatLevel === 2)?.value ?? 0
    const total = neutral + caution + danger
    return {
      neutral,
      caution,
      danger,
      total,
    }
  }, [data])

  const percentages = useMemo(() => {
    const { neutral, caution, danger, total } = counts
    const neutralPerc =
      total === 0
        ? 0
        : Math.round(Number.parseFloat((neutral / total).toFixed(2)) * 100)
    const cautionPerc =
      total === 0
        ? 0
        : Math.round(Number.parseFloat((caution / total).toFixed(2)) * 100)
    const dangerPerc =
      total === 0
        ? 0
        : Math.round(Number.parseFloat((danger / total).toFixed(2)) * 100)
    return {
      neutral: neutralPerc,
      caution: cautionPerc,
      danger: dangerPerc,
    }
  }, [counts])

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Content>
            <Column>
              <motion.div
                transition={{ duration: 1 }}
                animate={{ scale: [0.5, 1], opacity: [0.5, 1] }}
                style={{ width: '100%', height: '100%' }}
              >
                <Text>{`${t({ key: 'OVERVIEW_GREETING' })} ${
                  user?.given_name
                },`}</Text>
                <Headline>{t({ key: 'OVERALL_SECURITY' })}</Headline>
                <Bucket>
                  <Bullet color={colors.neutral} />
                  <Label>
                    {t({ key: 'THREAT_LEVEL', variables: { level: 0 } })}
                  </Label>
                  {counts.total > 0 ? (
                    <Value>{`${formatNumber(counts.neutral)} (${
                      percentages.neutral
                    }%)`}</Value>
                  ) : null}
                </Bucket>
                <Bucket>
                  <Bullet color={colors.caution} />
                  <Label>
                    {t({ key: 'THREAT_LEVEL', variables: { level: 1 } })}
                  </Label>
                  {counts.total > 0 ? (
                    <Value>{`${formatNumber(counts.caution)} (${
                      percentages.caution
                    }%)`}</Value>
                  ) : null}
                </Bucket>
                <Bucket>
                  <Bullet color={colors.danger} />
                  <Label>
                    {t({ key: 'THREAT_LEVEL', variables: { level: 2 } })}
                  </Label>
                  {counts.total > 0 ? (
                    <Value>{`${formatNumber(counts.danger)} (${
                      percentages.danger
                    }%)`}</Value>
                  ) : null}
                </Bucket>
              </motion.div>
            </Column>
            <Column>
              <RadialPie
                type='large'
                counts={{
                  first: counts.neutral,
                  second: counts.caution,
                  third: counts.danger,
                  total: counts.total,
                }}
                colors={{
                  first: colors.neutral,
                  second: colors.caution,
                  third: colors.danger,
                }}
                isAnimationActive={isAnimationActive}
                totalLabel={t({ key: 'OVERALL_RADIAL_PIE_TOTAL_LEVEL' })}
              />
            </Column>
          </Content>
        </Container>
      )}
    </I18n>
  )
}

export { OverallThreatLevel }
