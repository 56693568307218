import styled from 'styled-components'

export const colors = {
  white: '#ffffff',
  secondaryMain: '#e01883',
  black: '#000000',
  secondaryDark: '#ba0a68',
  secondaryLight: '#e5449a',
  primaryMain: '#00205c',
  primaryDark: '#7384a5',
  primaryLight: '#B9C1D2',
  primaryLighter: '#dbdfe7',
  darkGrey: '#353c43',
  primaryExtraLight: '#efeff3',
  secondaryBlue: '#3683f8',
  safe: '#14d180',
  lowerCaution: '#ffcb00',
  caution: '#e0b922',
  higherCaution: '#be9802',
  danger: '#ef0051',
  secondaryDanger: '#f16997',
  neutral: '#B9C1D2',
  background: '#f5f5f8',
  primaryMain50Opacity: '#00205c50',
  primaryMainAlpha50: 'rgba(0,32,92, 0.5)',
}

export const spacing = {
  xxs: '0.25rem',
  xs: '0.5rem',
  xs2: '0.75rem',
  s: '1rem',
  m1: '1.25rem',
  m: '1.5rem',
  l: '2rem',
  xl: '2.5rem',
  xxl: '3rem',
  xxl2: '3.5rem',
  xxl3: '4rem',
  xxl4: '4.5rem',
}

export const Text = styled.div`
  font-family: Lato;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`

export const NumbersXLPrimaryMainBold = styled(Text)`
  font-size: 32px;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const HeadlineH1PrimaryMainBold = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const HeadlineH1PrimaryDarkBold = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  color: ${colors.primaryDark};
`

export const NumbersLDarkGreyBold = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  color: ${colors.darkGrey};
`

export const NumbersMDarkGreyBold = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.darkGrey};
`

export const NumbersSDarkGreyBold = styled(Text)`
  font-size: 13px;
  font-weight: bold;
  color: ${colors.darkGrey};
`

export const HeadlineH2PrimaryMainBold = styled(Text)`
  font-size: 21px;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const HeadlineH3PrimaryMainBlack = styled(Text)`
  font-size: 16px;
  font-weight: 900;
  color: ${colors.primaryMain};
`

export const HeadlineH3PrimaryMainRegular = styled(Text)`
  font-size: 16px;
  color: ${colors.primaryMain};
`

export const HeadlineH3WhiteRegular = styled(Text)`
  font-size: 16px;
  color: ${colors.white};
`

export const HeadlineH3PrimaryLigtherRegular = styled(Text)`
  font-size: 16px;
  color: ${colors.primaryLighter};
`

export const HeadlineH3PrimaryLightRegular = styled(Text)`
  font-size: 16px;
  color: ${colors.primaryLight};
`

export const HeadlineH3PrimaryDarkRegular = styled(Text)`
  font-size: 16px;
  color: ${colors.primaryDark};
`

export const HeadlineH3DarkGreyRegular = styled(Text)`
  font-size: 16px;
  color: ${colors.darkGrey};
`

export const HeadlineH3PrimaryMainBold = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const HeadlineH3DarkGreyBold = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkGrey};
`

export const HeadlineH2DarkGreyRegular = styled(Text)`
  font-size: 15px;
  color: ${colors.darkGrey};
`

export const BodyPrimaryMainBold = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const BodyPrimaryMainRegular = styled(Text)`
  font-size: 14px;
  color: ${colors.primaryMain};
`

export const BodyDarkGreyRegular = styled(Text)`
  font-size: 14px;
  color: ${colors.darkGrey};
`

export const BodyDarkGreyBold = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.darkGrey};
`

export const BodyPrimaryLightRegular = styled(Text)`
  font-size: 14px;
  color: ${colors.white};
`

export const BodyPrimaryLightRegular2 = styled(Text)`
  font-size: 14px;
  color: ${colors.background};
`

export const SubtitlePrimaryMainRegular = styled(Text)`
  font-size: 14px;
  color: ${colors.primaryMain};
`

export const SubtitlePrimaryDarkBold = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.primaryDark};
`

export const SubtitlePrimaryMainBold = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const Body2DarkGreyRegular = styled(Text)`
  font-size: 13px;
  color: ${colors.darkGrey};
`

export const Body2WhiteRegular = styled(Text)`
  font-size: 13px;
  color: ${colors.white};
`

export const Body2PrimaryMainBold = styled(Text)`
  font-size: 13px;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const Body2PrimaryLightBold = styled(Text)`
  font-size: 13px;
  font-weight: bold;
  color: ${colors.primaryLight};
`

export const Body2DarkGreyBold = styled(Text)`
  font-size: 13px;
  font-weight: bold;
  color: ${colors.darkGrey};
`

export const BodyXSPrimaryMainRegular = styled(Text)`
  font-size: 12px;
  color: ${colors.primaryMain};
`

export const BodyXSPrimaryMainBold = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const Body1PrimaryDarkRegular = styled(Text)`
  font-size: 14px;
  color: ${colors.primaryDark};
`

export const Body1DarkGreyRegular = styled(Text)`
  font-size: 14px;
  color: ${colors.darkGrey};
`

export const Body1PrimaryDarkBold = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.primaryDark};
`

export const Body1PrimaryMainRegular = styled(Text)`
  font-size: 14px;
  color: ${colors.primaryMain};
`

export const Body1PrimaryMainBold = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.primaryMain};
`

export const Body1SecondaryMainRegular = styled(Text)`
  font-size: 14px;
  color: ${colors.secondaryMain};
`

export const BodyXSPrimaryDarkRegular = styled(Text)`
  font-size: 12px;
  color: ${colors.primaryDark};
`

export const BodyXSDarkGreyRegular = styled(Text)`
  font-size: 12px;
  color: ${colors.darkGrey};
`

export const TickTextPrimaryDark = styled.text`
  font-family: Lato;
  font-size: 11px;
  fill: ${colors.primaryDark};
`

export const LatoBold = styled(Text)`
  font-size: 15px;
  font-weight: bold;
  color: ${colors.primaryMain};
`
