import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '@logicea/react-auth'
import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import { Divider, Icon, Loader } from 'semantic-ui-react'
import { DashboardsContext } from 'providers/Dashboards'
import {
  Checkbox,
  ChevronDown,
  ChevronUp,
  Container,
  CriteriaFieldHeader,
  EmptyList,
  FlexList,
  FlexListContainer,
  HeaderRow,
  ListContainer,
  ListGradient,
  ReadOnly,
  TeamField,
  ResultTypeField,
  CriteriaField,
  StatusField,
  ResultTypeFieldHeader,
  StatusFieldHeader,
  TeamFieldHeader,
  Title,
  Row,
  CriteriaSubField,
  TitleContainer,
  TeamSelector,
  Infinite,
  SearchInput,
  UserSpecificCheckbox,
  UserEmailField,
  UserEmailFieldHeader,
  TeamAndSearchContainer,
} from './styles'
import ErrorBoundary from 'components/ErrorBoundary'
import isNil from 'ramda/src/isNil'
import ReactResizeDetector from 'react-resize-detector'
import symmetricDifference from 'ramda/src/symmetricDifference'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import throttle from 'lodash.throttle'
import actions from 'requests/actions'
import concat from 'ramda/src/concat'
import isEmpty from 'ramda/src/isEmpty'
import { I18n } from 'lib/i18n'
import findIndex from 'ramda/src/findIndex'
import { parseDate, Tooltip } from 'lib/util'
import { QuickTourStep } from 'components/Help/QuickTourStep'
import { CenteredCreateButton } from 'components/Shared/CommonButtons'
import teamActions from 'requests/team'

const { object } = PropTypes
const LIST_ELEMENT_HEIGHT = 40
const CREATE_BUTTON_ROW_HEIGHT = 100
const EMPTY_LIST_HEIGHT = 100

const renderHits = (hits, onListItemClick, selectedItems, handleCheckboxChange, readOnly, userSpecific) => {
  return hits
    ? hits.map((hit) => (
        <SearchHit
          key={`${hit.entry_id}-${hit.teamid}`}
          hit={hit}
          onListItemClick={onListItemClick}
          onCheckboxChange={handleCheckboxChange}
          selectedItems={selectedItems}
          readOnly={readOnly}
          userSpecific={userSpecific}
        />
      ))
    : null
}

const SearchHit = ({ hit, onListItemClick, onCheckboxChange, selectedItems, readOnly, userSpecific }) => (
  <I18n>
    {({ locale, t }) => (
      <React.Fragment>
        <Row
          data-tip
          data-for={'row_fields_' + hit.entry_id}
          onClick={() => onListItemClick(hit)}
          enabled={hit.enabled}
        >
          {!readOnly && false && (
            <Checkbox
              checked={
                findIndex(propEq('entry_id', hit.entry_id), selectedItems) > -1
              }
              onChange={() => {
                console.log('Hit is ', hit)
                onCheckboxChange(hit.entry_id)
              }}
            />
          )}
          {false && <TeamField>{hit.teamid}</TeamField>}
          {userSpecific && <UserEmailField>{hit.user_email}</UserEmailField>}
          <ResultTypeField>{hit.result_type}</ResultTypeField>
          <CriteriaField>
            {hit?.criteria
              ? hit.criteria.map((item, indx) => (
                  <CriteriaSubField key={indx} dangerouslySetInnerHTML={{__html:item ?? ""}} />
                ))
              : null}
          </CriteriaField>
          <StatusField>{hit.enabled ? 'Enabled' : 'Disabled'}</StatusField>
        </Row>
        <Tooltip delayShow={1000} bottom id={'row_fields_' + hit.entry_id}>
          <span>
            {'Added by ' +
              hit.added_by +
              '; Last modified ' +
              new Date(parseDate(hit.last_modified))}{' '}
          </span>
        </Tooltip>
      </React.Fragment>
    )}
  </I18n>
)

const AllowBlockList = () => {
  const [selectedItems, setSelectedItems] = useState([])
  const [hitsAcc, setHitsAcc] = useState([])
  const [filteredHitsAcc, setFilteredHitsAcc] = useState([])
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false)
  const [descOrder, setDescOrder] = useState(false)
  const [selectedSort, setSelectedSort] = useState('result_type')
  // const [results, setResults] = useState({})
  const [resultTotal, setResultTotal] = useState()
  const [noHits, setNoHits] = useState(false)
  const [isGradientVisible, setIsGradientVisible] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(1)
  const [authorizedTeams, setAuthorizedTeams] = useState([])
  const [teamSelectorEnabled, setTeamSelectorEnabled] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [canModifyStatus, setCanModifyStatus] = useState(false)
  const [readOnly, setReadOnly] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [userSpecific, setUserSpecific] = useState(false)
  const location = useLocation()

  const auth = useContext(AuthContext)
  const context = useContext(DashboardsContext)
  const {
    setListActionModalVisibility,
    setListActionData,
    settingsLoading,
    userPermissions,
    setSelectedSettingsTeam,
    selectedSettingsTeam,
    setCreateAllowBlockListEntryModalVisibility,
    setCreateAllowBlockListData,
    selectedSettingsPage,
    handleShowAllowList,
    handleShowBlockList,
    userTeams,
  } = context

  const onSuccessfulEntryStateChange = (listActionData) => {
    hitsAcc.forEach((hit) => {
      if (hit.entry_id === listActionData?.action?.entry_id)
        hit.enabled = listActionData?.action?.new_state
    })
  }

  useEffect(() => {
    let availableTeams = []
    if (userTeams) {
      userTeams.forEach(team => {
        availableTeams.push({ text: team, key: team, value: team })
      })
    }
    setAuthorizedTeams(availableTeams)
    setTeamSelectorEnabled(availableTeams.length > 1)
    if (availableTeams.length === 1)
      setSelectedSettingsTeam(availableTeams[0].value)
    else if (availableTeams.length > 1) {
      const lastTeam = localStorage.getItem('lastSelectedSettingsTeam')
      if (lastTeam) {
        availableTeams.forEach((item) => {
          if (item.value === lastTeam) setSelectedSettingsTeam(lastTeam)
        })
      }
    }
  }, [])

  const updatePermissionsForTeam = (team) => {
    let modifyStatus = false
    let create = false
    const permKey =
      selectedSettingsPage === 'allowlist' ? 'whitelist' : 'blacklist'
    Object.keys(userPermissions).forEach((key) => {
      if (key === '$global') {
        if (userPermissions[key].permissions[permKey].includes('create')) {
          create = true
        }
        if (
          userPermissions[key].permissions[permKey + '_status'].includes(
            'execute'
          )
        )
          modifyStatus = true
      } else {
        if (userPermissions[key].permissions[permKey].includes('create')) {
          if (key === team || team in userPermissions[key]['child_teamids'])
            create = true
        }
        if (
          userPermissions[key].permissions[permKey + '_status'].includes(
            'execute'
          )
        ) {
          if (key === team || team in userPermissions[key]['child_teamids'])
            modifyStatus = true
        }
      }
    })
    setCanCreate(create)
    setCanModifyStatus(modifyStatus)
    setReadOnly(!modifyStatus && !create)
  }

  useEffect(() => {
    if (location.pathname.includes('/settings/allowlist')) {
      handleShowAllowList()
    } else if (location.pathname.includes('/settings/blocklist')) {
      handleShowBlockList()
    }
  }, [])

  useEffect(() => {
    if (selectedSettingsPage === 'allowlist' && userSpecific)
      setUserSpecific(false)
    else
      forceMessageListRefresh()
  }, [selectedSettingsPage])

  useEffect(() => {
    updateFilteredHits(hitsAcc)
  }, [selectedSort, descOrder])

  const forceMessageListRefresh = () => {
    setActivePage(0)
    setResultTotal(null)
    setFilteredHitsAcc([])
    setHitsAcc([])
    setNoHits(false)
  }

  useEffect(() => {
    forceMessageListRefresh()
    if (selectedSettingsTeam) {
      localStorage.setItem('lastSelectedSettingsTeam', selectedSettingsTeam)
      updatePermissionsForTeam(selectedSettingsTeam)
    }
    setSearchText('')
  }, [selectedSettingsTeam])

  const updateFilteredHits = (hits) => {
    let results = []
    if (searchText?.length > 0) {
      let filteredHits = []
      const lowerSearchText = searchText.toLowerCase()
      hitsAcc.forEach(hit => {
        let found = false
        let searches = 0
        if (hit?.criteria?.length > 0) {
          hit.criteria.forEach(criteria => {
            const crit = criteria.trim().toLowerCase()
            if (crit.indexOf(lowerSearchText) > -1) {
              found = true
            }
            ++searches
          })
        }

        if (hit?.user_email && hit.user_email !== "*" && hit.user_email.trim().toLowerCase().indexOf(lowerSearchText) > -1) {
          found = true
          ++searches
        }

        if (hit?.added_by && hit.added_by.trim().toLowerCase().indexOf(lowerSearchText) > -1) {
          found = true
          ++searches
        }

        if (hit?.result_type && hit.result_type.trim().toLowerCase().indexOf(lowerSearchText) > -1) {
          found = true
          ++searches
        }

        if (found || !searches)
          filteredHits.push(hit)
      })
      results = filteredHits
    } else {
      results = concat([], hits)
    }

    // Sort the filtered results
    let sortedResults = results.sort((a,b) => {
      if (descOrder)
        return (a[[selectedSort]] < b[[selectedSort]]) ? 1 : -1
      else
        return (a[[selectedSort]] > b[[selectedSort]]) ? 1 : -1
    })

    setFilteredHitsAcc(sortedResults)
  }

  useEffect(() => {
    updateFilteredHits(hitsAcc)
  }, [searchText])

  useEffect(() => {
    setSelectedSort(userSpecific ? 'user_email' : 'result_type')
    setDescOrder(false)
    forceMessageListRefresh()
  }, [userSpecific])

  const handleInfiniteLoad = throttle(
    async () => {
      if (!selectedSettingsTeam) {
        setNoHits(true)
        setHitsAcc([])
        setFilteredHitsAcc([])
        return
      }

      setIsInfiniteLoading(true)
      let response
      try {
        if (selectedSettingsPage === 'allowlist')
          response = await actions.getAllowListEntriesAction(
            { teamids: [selectedSettingsTeam] },
            auth.tokens.accessToken
          )
        else if (selectedSettingsPage === 'blocklist')
          response = await actions.getBlockListEntriesAction({teamids: [selectedSettingsTeam], user_specific: userSpecific}, auth.tokens.accessToken)
      } catch (e) {
        console.log('AllowBlockList:handleInfiniteLoad:Exception', e)
      }

      setActivePage(activePage + 1)
      const hits = concat(hitsAcc, response?.result || [])
      setHitsAcc(hits)
      updateFilteredHits(hits)
      setResultTotal(hits.length)
      setNoHits(isEmpty(hits))
      setIsInfiniteLoading(false)
    },
    1000,
    { leading: true, trailing: false }
  )

  const handleSelectAllChange = () => {
    if (selectedItems.length === hitsAcc.length) setSelectedItems([])
    else setSelectedItems(hitsAcc)
  }

  const handleCheckboxChange = (entryId) => {
    setSelectedItems(
      symmetricDifference(selectedItems, [
        find(propEq('entry_id', entryId), hitsAcc),
      ])
    )
  }

  const onResize = (width, height) => {
    setListWidth(width)
    setListHeight(height - LIST_ELEMENT_HEIGHT - CREATE_BUTTON_ROW_HEIGHT)
  }

  const handleSortClick = (name) => {
    setActivePage(1)
    setSelectedItems([])
    if (selectedSort === name) {
      setDescOrder(!descOrder)
    } else {
      setDescOrder(false)
      setSelectedSort(name)
    }
  }

  const onListItemClick = (entry) => {
    if (!canModifyStatus) return

    setListActionData(
      {
        new_state: !entry.enabled,
        teamid: selectedSettingsTeam,
        entry_id: entry.entry_id,
        user_email: entry.user_email
      },
      null,
      onSuccessfulEntryStateChange
    )
    setListActionModalVisibility(
      true,
      selectedSettingsPage === 'blocklist' ? 'block-status' : 'allow-status'
    )
  }

  const handleListScroll = (node) => {
    if (
      node.scrollTop + node.clientHeight >= node.scrollHeight &&
      isGradientVisible
    )
      setIsGradientVisible(false)
    else if (
      node.scrollTop + node.clientHeight < node.scrollHeight &&
      !isGradientVisible
    )
      setIsGradientVisible(true)
  }

  const onNewEntrySuccess = () => {
    forceMessageListRefresh()
  }

  const showListEntryCreationDialog = () => {
    setCreateAllowBlockListData({
      teamId: selectedSettingsTeam,
      listType: selectedSettingsPage === 'allowlist' ? 'allow' : 'block',
      successCallback: onNewEntrySuccess,
    })
    setCreateAllowBlockListEntryModalVisibility(true)
  }

  const total = resultTotal
  const totalPages = Math.ceil(total / 100)

  const handleUserSpecificClick = (evt, data) => {
    setUserSpecific(data.checked)
  }

  return settingsLoading ? (
    <Loader active />
  ) : (
    <I18n>
      {({ t }) => (
        <Container>
          <TitleContainer>
            <QuickTourStep
              stepId={selectedSettingsPage}
              isOutlined={false}
              title={t({
                key:
                  selectedSettingsPage === 'allowlist'
                    ? 'QUICK_TOUR_ALLOWLIST_TITLE'
                    : 'QUICK_TOUR_BLOCKLIST_TITLE',
              })}
              content={t({
                key:
                  selectedSettingsPage === 'allowlist'
                    ? 'QUICK_TOUR_ALLOWLIST_CONTENT'
                    : 'QUICK_TOUR_BLOCKLIST_CONTENT'
              })}
            >
              <TeamAndSearchContainer>
                <TeamSelector
                  visible={teamSelectorEnabled ? 'true' : 'false'}
                  placeholder='Select Team'
                  fluid
                  search
                  selection
                  options={authorizedTeams}
                  value={selectedSettingsTeam}
                  onChange={(event, data) => {setSelectedSettingsTeam(data.value)}}
                />
                <SearchInput value={searchText} onChange={(evt, data) => setSearchText(data.value)} icon={<Icon name='search' link />} iconPosition="left"/>
              </TeamAndSearchContainer>
              <Title>
                {t({
                  key:
                    selectedSettingsPage === 'allowlist'
                      ? 'ALLOW_LIST_TITLE'
                      : 'BLOCK_LIST_TITLE',
                })}
              </Title>
            </QuickTourStep>
          {selectedSettingsPage === 'blocklist' &&
          <UserSpecificCheckbox
            label={t({key: 'USER_SPECIFIC'})}
            checked={userSpecific}
            onChange={handleUserSpecificClick}
          />
          }
          </TitleContainer>
          {readOnly && (
            <ReadOnly>
              This is a read-only view of your current{' '}
              {selectedSettingsPage === 'allowlist' ? 'Allow' : 'Block'} List.
              Please contact your support representative to request any changes.
            </ReadOnly>
          )}
          <Divider />
          <FlexList>
            <HeaderRow>
              {!readOnly &&
                false && ( // Disabled until we add actions to disable multiple entries at a time
                  <Checkbox
                    checked={
                      selectedItems.length === hitsAcc.length &&
                      hitsAcc.length > 0
                    }
                    onChange={handleSelectAllChange}
                  />
                )}
              {false && ( // Disabled until we support multi-team selection
                <TeamFieldHeader
                  active={selectedSort === 'team'}
                  onClick={() => handleSortClick('team')}
                >
                  <div>{t({ key: 'ALLOW_BLOCK_LIST_TEAM_HEADER' })}</div>
                  {selectedSort === 'team' &&
                    (descOrder ? <ChevronUp /> : <ChevronDown />)}
                </TeamFieldHeader>
              )}
              {userSpecific &&
              <UserEmailFieldHeader
                active={selectedSort === 'user_email'}
                onClick={() => handleSortClick('user_email')}
              >
                <div>{t({ key: 'ALLOW_BLOCK_LIST_USER_EMAIL_HEADER' })}</div>
                {selectedSort === 'user_email' && (
                  descOrder ? <ChevronUp /> : <ChevronDown />
                )}
              </UserEmailFieldHeader>
              }
              <ResultTypeFieldHeader
                active={selectedSort === 'result_type'}
                onClick={() => handleSortClick('result_type')}
              >
                <div>{t({ key: 'ALLOW_BLOCK_LIST_RESULT_TYPE_HEADER' })}</div>
                {selectedSort === 'result_type' &&
                  (descOrder ? <ChevronUp /> : <ChevronDown />)}
              </ResultTypeFieldHeader>
              <CriteriaFieldHeader>
                <div>{t({ key: 'ALLOW_BLOCK_LIST_CRITERIA_HEADER' })}</div>
              </CriteriaFieldHeader>
              <StatusFieldHeader
                active={selectedSort === 'enabled'}
                onClick={() => handleSortClick('enabled')}
              >
                <div>{t({ key: 'ALLOW_BLOCK_LIST_STATUS_HEADER' })}</div>
                {selectedSort === 'enabled' && (
                  descOrder ? <ChevronUp /> : <ChevronDown />)}
              </StatusFieldHeader>
              {/* Unsupported item click for now */}
              {/*<FieldHeader*/}
              {/*>*/}
              {/*  <div>{t({ key: 'ALLOW_BLOCK_LIST_MESSAGE_HEADER' })}</div>*/}
              {/*</FieldHeader>*/}
            </HeaderRow>
            <FlexListContainer>
              <ListContainer
                width={listWidth}
                height={
                  listHeight + LIST_ELEMENT_HEIGHT + CREATE_BUTTON_ROW_HEIGHT
                }
              >
                {listHeight > 0 && (
                  <ErrorBoundary
                    fallback={
                      <React.Fragment>
                        <Infinite
                          style={{ overflowY: 'auto' }}
                          elementHeight={LIST_ELEMENT_HEIGHT}
                          containerHeight={
                            noHits ? EMPTY_LIST_HEIGHT : listHeight
                          }
                          handleScroll={handleListScroll}
                          infiniteLoadBeginEdgeOffset={
                            !isNil(total) && hitsAcc.length >= total
                              ? undefined
                              : LIST_ELEMENT_HEIGHT * 10
                          }
                          onInfiniteLoad={handleInfiniteLoad}
                          isInfiniteLoading={isInfiniteLoading}
                          loadingSpinnerDelegate={<Loader active />}
                        >
                          {noHits ? (
                            <EmptyList>
                              {t({
                                key: selectedSettingsTeam
                                  ? 'ALLOW_BLOCK_LIST_EMPTY_MESSAGE'
                                  : 'LIST_SELECT_TEAM_FIRST',
                              })}
                            </EmptyList>
                          ) : (
                            renderHits(
                              filteredHitsAcc,
                              onListItemClick,
                              selectedItems,
                              handleCheckboxChange,
                              readOnly,
                              userSpecific
                            )
                          )}
                        </Infinite>
                        {selectedSettingsTeam && canCreate && (
                          <CenteredCreateButton
                            onClick={showListEntryCreationDialog}
                            icon='plus'
                          />
                        )}
                      </React.Fragment>
                    }
                  >
                    <Infinite
                      style={{ overflowY: 'auto' }}
                      elementHeight={LIST_ELEMENT_HEIGHT}
                      containerHeight={noHits ? EMPTY_LIST_HEIGHT : listHeight}
                      handleScroll={handleListScroll}
                      infiniteLoadBeginEdgeOffset={
                        !isNil(total) && hitsAcc.length >= total
                          ? undefined
                          : LIST_ELEMENT_HEIGHT * 10
                      }
                      onInfiniteLoad={handleInfiniteLoad}
                      isInfiniteLoading={isInfiniteLoading}
                      loadingSpinnerDelegate={<Loader active />}
                    >
                      {noHits ? (
                        <EmptyList>
                          {t({
                            key: selectedSettingsTeam
                              ? 'ALLOW_BLOCK_LIST_EMPTY_MESSAGE'
                              : 'LIST_SELECT_TEAM_FIRST',
                          })}
                        </EmptyList>
                      ) : (
                        renderHits(
                          filteredHitsAcc,
                          onListItemClick,
                          selectedItems,
                          handleCheckboxChange,
                          readOnly,
                          userSpecific
                        )
                      )}
                    </Infinite>
                    {selectedSettingsTeam && canCreate && (
                      <CenteredCreateButton
                        onClick={showListEntryCreationDialog}
                        icon='plus'
                      />
                    )}{' '}
                    {/* Disabled until we support adding new entries */}
                  </ErrorBoundary>
                )}
              </ListContainer>
              <ListGradient visible={isGradientVisible} />
            </FlexListContainer>
            <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
          </FlexList>
        </Container>
      )}
    </I18n>
  )
}

AllowBlockList.propTypes = {
  auth: object,
}

export default AllowBlockList
