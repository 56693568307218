import React from 'react'
import { Container, Label, TickOffIcon, TickOnIcon } from './styles'

export type Color = 'primary' | 'secondary'

type CustomRadioProps = {
  color: Color
  label: string
  checked: boolean
  onChange?: () => void
}

export const Radio = ({
  color = 'secondary',
  label,
  checked,
  onChange,
}: CustomRadioProps) => {
  return (
    <Container onClick={onChange}>
      {checked ? <TickOnIcon color={color} /> : <TickOffIcon color={color} />}
      <Label>{label}</Label>
    </Container>
  )
}
