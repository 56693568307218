const now = new Date()
let tempDate = new Date()
const threeMonthsBefore = now.getMonth() - 3
tempDate.setMonth(threeMonthsBefore)
if (threeMonthsBefore < 0) tempDate.setFullYear(now.getFullYear() - 1)
else tempDate.setFullYear(now.getFullYear())
const initialDateRange = tempDate
const initialSearchQuery = {
  fromDomain: '',
  subject: '',
  source: '',
  email: '',
  threatLevels: ['0', '1', '2'],
  reasonIds: null,
  teamids: null,
  from: Math.floor(initialDateRange.getTime() / 1000),
  to: Math.floor(now.getTime() / 1000),
}

export { now, initialDateRange, initialSearchQuery }
