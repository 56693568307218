import axios from 'axios'
import omit from 'ramda/src/omit'
import { KOTLIN_URL } from '../static/appConfig'

// XHR requests
const getUserPermissionsXhr = query => axios.get(
  `${KOTLIN_URL}/users/permissions`,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${query.token}`,
      'X-Team': query.realm,
    },
  },
)

const getQuickTourProgressXhr = query => axios.get(
  `${KOTLIN_URL}/user-settings/quick-tour`,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${query.token}`,
      'X-Team': query.realm,
    },
  },
)

const updateQuickTourProgressXhr = body => axios.put(
  `${KOTLIN_URL}/user-settings/quick-tour`,
  omit(['token', 'realm'], body),
  {
    params: { },
    headers: {
      Authorization: `Bearer ${body.token}`,
      'Content-Type': 'application/json',
      'X-Team': body.realm,
    },
  }
)

// Actions
const getUserPermissionsAction = async query => {
  try {
    const res = await getUserPermissionsXhr(query)
    console.log("getUserPermissionsXhr: ", res);
    return res.data
  } catch (err) {
    console.log(err)
    return null
  }
}

const getQuickTourProgressAction = async (_key, payload) => {
  try {
    const res = await getQuickTourProgressXhr(payload)
    return res.data
  } catch (err) {
    console.log(err)
    return null
  }
}

const updateQuickTourProgressAction = async body => {
  try {
    const res = await updateQuickTourProgressXhr(body)
    return res.data
  } catch (err) {
    console.log(err)
    return null
  }
}

const actions = {
  getUserPermissionsAction,
  getQuickTourProgressAction,
  updateQuickTourProgressAction,
}

export default actions
