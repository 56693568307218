import React, { useState, useContext } from 'react'
import { navigate, useLocation } from '@reach/router'
import prop from 'ramda/src/prop'
import isNil from 'ramda/src/isNil'
import sortBy from 'ramda/src/sortBy'
import propEq from 'ramda/src/propEq'
import filter from 'ramda/src/filter'
import { AuthContext } from '@logicea/react-auth'
import { Help } from './components/Help'
import { Reports } from './components/Reports'
import { Settings } from './components/Settings'
import { DashboardsContext } from 'providers/Dashboards'
import { HelpHotspot } from 'components/Help/HelpHotspot'
import { QuickTourStep } from 'components/Help/QuickTourStep'
import { I18n } from 'lib/i18n'
import {
  Container,
  TitleWrapper,
  Title,
  ChevronIcon,
  UserIcon,
  Column,
  Accordion,
  AccordionNoMargin,
  AccordionSummary,
  AccordionDetails,
  Seperator,
  Username,
  Logout,
  LogoutLink,
  Menu,
  MenuItem,
  MenuItemRow,
  CollapsedMenuItemRowWrapper,
  CollapsedMenuItemRow,
  Selected,
  MenuItemText,
  SettingsIcon,
  SubmenuItem,
  LogoutIcon,
  HelpIcon,
  ReportsIcon,
  OverviewIcon,
  DashboardsIcon,
  FullLogoIcon,
  LogoIcon,
  ToggleWrapper,
  ToggleText,
  ArrowRightIcon,
  ArrowLeftIcon,
} from './styles'

export const Sidebar = () => {
  const location = useLocation()
  const [expanded, setExpanded] = useState(false)

  const context = useContext(DashboardsContext)
  const {
    handleRefreshClick,
    isSidebarCollapsed,
    toggleSidebar,
    dashboards,
  } = context

  const auth = useContext(AuthContext)
  const { user } = auth

  if (isNil(user)) {
    navigate('/authentication/login')
    return null
  }

  const reportDashboards = user
    ? sortBy(
        prop('name'),
        filter(propEq('user_id', 'report-template@inkysteps.com'), dashboards)
      )
    : []

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleOverviewMenuItemClick = () => {
    navigate('/')
  }

  const handleDashboardsMenuItemClick = () => {
    navigate('/dashboards')
  }

  const handleLogout = () => auth.logout()

  const handleToogleSidebar = () => {
    !isSidebarCollapsed && setExpanded(false)
    toggleSidebar()
  }

  const isOverviewHighlighted = location.pathname === '/'
  const isDashboardsHighlighted = location.pathname === '/dashboards'
  const isReportsHighlighted = location.pathname.startsWith('/reports')
  const isSettingsHighlighted = location.pathname.includes('/settings')

  return (
    <I18n>
      {({ t }) => (
        <Container collapsed={isSidebarCollapsed}>
          <TitleWrapper collapsed={isSidebarCollapsed}>
            <Title to='/' onClick={handleRefreshClick}>
              {isSidebarCollapsed ? <LogoIcon /> : <FullLogoIcon />}
            </Title>
          </TitleWrapper>
          <Menu collapsed={isSidebarCollapsed}>
            <MenuItem clickable>
              {isSidebarCollapsed && (
                <CollapsedMenuItemRowWrapper>
                  <CollapsedMenuItemRow>
                    <Logout onClick={handleLogout}>
                      <LogoutIcon />
                      <LogoutLink>{t({ key: 'SIDEBAR_LOGOUT' })}</LogoutLink>
                    </Logout>
                  </CollapsedMenuItemRow>
                </CollapsedMenuItemRowWrapper>
              )}
              <MenuItemRow>
                <Column>
                  <AccordionNoMargin
                    expanded={expanded === 'user'}
                    onChange={handleChange('user')}
                  >
                    <AccordionSummary
                      expandIcon={!isSidebarCollapsed && <ChevronIcon />}
                      style={{ minHeight: 0 }}
                    >
                      <UserIcon />
                      {!isSidebarCollapsed && (
                        <MenuItemText>
                          <Username>{user.email}</Username>
                        </MenuItemText>
                      )}
                    </AccordionSummary>
                    {!isSidebarCollapsed && (
                      <AccordionDetails>
                        <Logout onClick={handleLogout}>
                          <LogoutIcon />
                          <LogoutLink>
                            {t({ key: 'SIDEBAR_LOGOUT' })}
                          </LogoutLink>
                        </Logout>
                      </AccordionDetails>
                    )}
                  </AccordionNoMargin>
                  <Seperator />
                </Column>
              </MenuItemRow>
            </MenuItem>
            <QuickTourStep
              stepId='overview'
              isOutlined={isSidebarCollapsed}
              title={t({ key: 'QUICK_TOUR_OVERVIEW_TITLE' })}
              content={t({ key: 'QUICK_TOUR_OVERVIEW_CONTENT' })}
              offsetLeft={isSidebarCollapsed ? 0 : -100}
            >
              <MenuItem clickable onClick={handleOverviewMenuItemClick}>
                <Selected active={location.pathname === '/'} />
                <MenuItemRow>
                  <HelpHotspot
                    offsetTop={2}
                    disabled={isOverviewHighlighted}
                    title={t({ key: 'QUICK_TOUR_OVERVIEW_TITLE' })}
                    content={t({ key: 'QUICK_TOUR_OVERVIEW_CONTENT' })}
                  >
                    <OverviewIcon active={location.pathname === '/'} />
                  </HelpHotspot>
                  {!isSidebarCollapsed && (
                    <MenuItemText active={location.pathname === '/'}>
                      {t({ key: 'SIDEBAR_OVERVIEW' })}
                    </MenuItemText>
                  )}
                </MenuItemRow>
              </MenuItem>
            </QuickTourStep>
            <QuickTourStep
              stepId='gallery'
              isOutlined={isSidebarCollapsed}
              title={t({ key: 'QUICK_TOUR_MARKETPLACE_TITLE' })}
              content={t({ key: 'QUICK_TOUR_MARKETPLACE_CONTENT' })}
              offsetLeft={isSidebarCollapsed ? 0 : -100}
            >
              <MenuItem clickable onClick={handleDashboardsMenuItemClick}>
                <Selected active={isDashboardsHighlighted} />
                <MenuItemRow selected={true}>
                  <HelpHotspot
                    disabled={isDashboardsHighlighted}
                    title={t({ key: 'QUICK_TOUR_MARKETPLACE_TITLE' })}
                    content={t({ key: 'QUICK_TOUR_MARKETPLACE_CONTENT' })}
                  >
                    <DashboardsIcon active={isDashboardsHighlighted} />
                  </HelpHotspot>
                  {!isSidebarCollapsed && (
                    <MenuItemText active={isDashboardsHighlighted}>
                      {t({ key: 'SIDEBAR_DASHBOARDS' })}
                    </MenuItemText>
                  )}
                </MenuItemRow>
              </MenuItem>
            </QuickTourStep>
            <QuickTourStep
              stepId='reports'
              isOutlined={isSidebarCollapsed}
              title={t({ key: 'QUICK_TOUR_REPORTS_TITLE' })}
              content={t({ key: 'QUICK_TOUR_REPORTS_CONTENT' })}
              offsetLeft={isSidebarCollapsed ? 0 : -100}
            >
              <MenuItem clickable>
                <Selected active={isReportsHighlighted} />
                {isSidebarCollapsed && (
                  <CollapsedMenuItemRowWrapper>
                    <CollapsedMenuItemRow>
                      <Reports />
                    </CollapsedMenuItemRow>
                  </CollapsedMenuItemRowWrapper>
                )}
                <MenuItemRow>
                  <Accordion
                    expanded={expanded === 'reports' || isReportsHighlighted}
                    onChange={handleChange('reports')}
                    disabled={reportDashboards.length === 0}
                  >
                    <AccordionSummary
                      expandIcon={!isSidebarCollapsed && <ChevronIcon />}
                      style={{ minHeight: 0 }}
                    >
                      <ReportsIcon active={isReportsHighlighted} />
                      {!isSidebarCollapsed && (
                        <MenuItemText active={isReportsHighlighted}>
                          {t({ key: 'SIDEBAR_REPORTS' })}
                        </MenuItemText>
                      )}
                    </AccordionSummary>
                    {!isSidebarCollapsed && (
                      <AccordionDetails>
                        <Reports />
                      </AccordionDetails>
                    )}
                  </Accordion>
                </MenuItemRow>
              </MenuItem>
            </QuickTourStep>
            <QuickTourStep
              stepId='settings'
              isOutlined={isSidebarCollapsed}
              title={t({ key: 'QUICK_TOUR_SETTINGS_TITLE' })}
              content={t({ key: 'QUICK_TOUR_SETTINGS_CONTENT' })}
              offsetLeft={isSidebarCollapsed ? 0 : -100}
            >
            <MenuItem clickable>
              <Selected active={isSettingsHighlighted} />
              {isSidebarCollapsed && (
                <CollapsedMenuItemRowWrapper>
                  <CollapsedMenuItemRow>
                    <Settings />
                  </CollapsedMenuItemRow>
                </CollapsedMenuItemRowWrapper>
              )}
              <MenuItemRow>
                <Accordion
                  expanded={expanded === 'settings' || isSettingsHighlighted}
                  onChange={handleChange('settings')}
                >
                  <AccordionSummary
                    expandIcon={!isSidebarCollapsed && <ChevronIcon />}
                    style={{ minHeight: 0 }}
                  >
                    <SettingsIcon
                      active={isSettingsHighlighted}
                    />
                    {!isSidebarCollapsed && (
                      <MenuItemText
                        active={isSettingsHighlighted}
                      >
                        {t({ key: 'SIDEBAR_SETTINGS' })}
                      </MenuItemText>
                    )}
                  </AccordionSummary>
                  {!isSidebarCollapsed && (
                    <AccordionDetails>
                      <Settings />
                    </AccordionDetails>
                  )}
                </Accordion>
              </MenuItemRow>
            </MenuItem>
            </QuickTourStep>
            <MenuItem clickable>
              {isSidebarCollapsed && (
                <CollapsedMenuItemRowWrapper>
                  <CollapsedMenuItemRow>
                    <Help />
                  </CollapsedMenuItemRow>
                </CollapsedMenuItemRowWrapper>
              )}
              <MenuItemRow>
                <Accordion
                  expanded={expanded === 'help'}
                  onChange={handleChange('help')}
                >
                  <AccordionSummary
                    expandIcon={!isSidebarCollapsed && <ChevronIcon />}
                    style={{ minHeight: 0 }}
                  >
                    <HelpIcon />
                    {!isSidebarCollapsed && (
                      <MenuItemText>{t({ key: 'SIDEBAR_HELP' })}</MenuItemText>
                    )}
                  </AccordionSummary>
                  {!isSidebarCollapsed && (
                    <AccordionDetails>
                      <Help />
                    </AccordionDetails>
                  )}
                </Accordion>
              </MenuItemRow>
            </MenuItem>
            <ToggleWrapper clickable onClick={handleToogleSidebar}>
              {isSidebarCollapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
              {!isSidebarCollapsed && (
                <ToggleText>{t({ key: 'SIDEBAR_COLLAPSE' })}</ToggleText>
              )}
            </ToggleWrapper>
          </Menu>
        </Container>
      )}
    </I18n>
  )
}
