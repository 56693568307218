import styled from 'styled-components'
import {
  colors,
  spacing,
  Body2DarkGreyRegular,
  Body2PrimaryMainBold,
} from 'static/theme'
import { FILTER_CATEGORY_COLORS } from 'static/filterNames'
import { ReactComponent as InvertSVG } from 'assets/filters/invert.svg'
import { ReactComponent as ClearSVG } from 'assets/filters/clear.svg'

export const Container = styled.div<{
  isEditable: Boolean
  isNegative: Boolean
  category: keyof typeof FILTER_CATEGORY_COLORS
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 270px;
  height: 24px;
  border: 1px solid ${({ category }) => FILTER_CATEGORY_COLORS[category]};
  border-radius: 16px;
  padding: ${spacing.xxs};
  background-color: ${({ isNegative }) =>
    isNegative ? colors.primaryLight : colors.primaryLighter};
  margin: 0rem ${spacing.xxs};
  &:hover {
    cursor: ${({ isEditable }) => (isEditable ? 'pointer' : 'none')};
  }
`

export const InvertIcon = styled(InvertSVG)<{ isNegative: Boolean }>`
  min-width: 16px;
  min-height: 16px;
  fill: ${({ isNegative }) =>
    isNegative ? colors.primaryMain : colors.primaryLight};
  &:hover {
    cursor: pointer;
  }
`

export const Name = styled(Body2DarkGreyRegular)`
  white-space: nowrap;
  margin-left: ${spacing.xxs};
`

export const Value = styled(Body2PrimaryMainBold)`
  margin-left: ${spacing.xxs};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ClearIcon = styled(ClearSVG)`
  min-width: 16px;
  min-height: 16px;
  margin-left: ${spacing.xxs};
  fill: ${colors.darkGrey};
  &:hover {
    cursor: pointer;
  }
`
