import styled from 'styled-components'
import { spacing } from 'static/theme'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  max-width: 1302px;
  padding: ${spacing.xl};
  padding-top: ${spacing.s};
  padding-right: calc(${spacing.xl} - ${spacing.m});
`
