export const permissionsTable = {
  sections: [
    {
      name: 'show',
      label: 'Show',
      rows: [
        {
          name: 'show_display_mode',
          label: 'Display mode',
        },
        {
          name: 'show_configure_mode',
          label: 'Configure mode',
        },
        {
          name: 'show_filters',
          label: 'Filters chekcbox',
        },
        {
          name: 'show_names',
          label: 'Message set names chekcbox',
        },
        {
          name: 'show_statistics',
          label: 'Statistics chekcbox',
        },
        {
          name: 'show_selectors',
          label: 'View selectors chekcbox',
        },
      ],
    },
    {
      name: 'dashboards',
      label: 'Dashboards',
      rows: [
        {
          name: 'dashboard_create',
          label: 'Create',
        },
        {
          name: 'dashboard_clone',
          label: 'Clone',
        },
        {
          name: 'dashboard_rename',
          label: 'Rename',
        },
        {
          name: 'dashboard_delete',
          label: 'Delete',
        },
        {
          name: 'dashboard_create_filters',
          label: 'Create filters',
        },
        {
          name: 'dashboard_delete_filters',
          label: 'Delete filters',
        },
        {
          name: 'dashboard_modify_filters',
          label: 'Modify filter values',
        },
        {
          name: 'dashboard_toggle_filters',
          label: 'Toggle filters on/off',
        },
      ],
    },
    {
      name: 'message_sets',
      label: 'Message sets',
      rows: [
        {
          name: 'message_set_create',
          label: 'Create',
        },
        {
          name: 'message_set_clone',
          label: 'Clone',
        },
        {
          name: 'message_set_rename',
          label: 'Rename',
        },
        {
          name: 'message_set_delete',
          label: 'Delete',
        },
        {
          name: 'message_set_create_filters',
          label: 'Create filters',
        },
        {
          name: 'message_set_delete_filters',
          label: 'Delete filters',
        },
        {
          name: 'message_set_modify_filters',
          label: 'Modify filter values',
        },
        {
          name: 'message_set_toggle_filters',
          label: 'Toggle filters on/off',
        },
        {
          name: 'message_set_modify_visualization',
          label: 'Modify visualization parameters',
        },
      ],
    },
  ],
}

export const initialDashboardValues = {
  show_display_mode: {
    owner: true,
    group: true,
    everyone: false,
  },
  show_configure_mode: {
    owner: true,
    group: true,
    everyone: false,
  },
  show_filters: {
    owner: true,
    group: true,
    everyone: false,
  },
  show_names: {
    owner: true,
    group: true,
    everyone: false,
  },
  show_statistics: {
    owner: true,
    group: true,
    everyone: false,
  },
  show_selectors: {
    owner: true,
    group: true,
    everyone: false,
  },
  dashboard_create: {
    owner: true,
    group: true,
    everyone: false,
  },
  dashboard_clone: {
    owner: true,
    group: true,
    everyone: false,
  },
  dashboard_rename: {
    owner: true,
    group: true,
    everyone: false,
  },
  dashboard_delete: {
    owner: true,
    group: true,
    everyone: false,
  },
  dashboard_create_filters: {
    owner: true,
    group: true,
    everyone: false,
  },
  dashboard_delete_filters: {
    owner: true,
    group: true,
    everyone: false,
  },
  dashboard_modify_filters: {
    owner: true,
    group: true,
    everyone: false,
  },
  dashboard_toggle_filters: {
    owner: true,
    group: true,
    everyone: false,
  },
  message_set_create: {
    owner: true,
    group: true,
    everyone: false,
  },
  message_set_clone: {
    owner: true,
    group: true,
    everyone: false,
  },
  message_set_rename: {
    owner: true,
    group: true,
    everyone: false,
  },
  message_set_delete: {
    owner: true,
    group: true,
    everyone: false,
  },
  message_set_create_filters: {
    owner: true,
    group: true,
    everyone: false,
  },
  message_set_delete_filters: {
    owner: true,
    group: true,
    everyone: false,
  },
  message_set_modify_filters: {
    owner: true,
    group: true,
    everyone: false,
  },
  message_set_toggle_filters: {
    owner: true,
    group: true,
    everyone: false,
  },
  message_set_modify_visualization: {
    owner: true,
    group: true,
    everyone: false,
  },
}

export const initialUserValues = {
  show_display_mode: true,
  show_configure_mode: true,
  show_filters: true,
  show_names: true,
  show_statistics: true,
  show_selectors: true,
  dashboard_create: true,
  dashboard_clone: true,
  dashboard_rename: true,
  dashboard_delete: true,
  dashboard_create_filters: true,
  dashboard_delete_filters: true,
  dashboard_modify_filters: true,
  dashboard_toggle_filters: true,
  message_set_create: true,
  message_set_clone: true,
  message_set_rename: true,
  message_set_delete: true,
  message_set_create_filters: true,
  message_set_delete_filters: true,
  message_set_modify_filters: true,
  message_set_toggle_filters: true,
  message_set_modify_visualization: true,
}
