import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import propEq from 'ramda/src/propEq'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import messageActions from '../../../requests/message'
import { I18n } from '../../../lib/i18n'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Suggestion,
  SuggestionText,
  CloseIcon,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import debounce from "lodash.debounce";
import {INPUT_TEXT_DEBOUNCE_TIME} from "../../../static/appConfig";
import {filterToString} from "../../../lib/util";

const { object, func, array, string } = PropTypes

const NestedListInput = ({
                          realm,
                          token,
                          filter,
                          onChange,
                          setFilters,
                          setFilterMode,
                          dashboardFilters,
                          dashboardFilterMode,
                          nestedFieldPath,
                          innerFieldName,
                        }) => {

  const [hitCount, setHitCount] = useState(0)
  const [suggestions, setSuggestions] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState([])

  const getSuggestionsDebounced = useCallback(debounce(async (newValue) => {
    const { name } = filter
    const newSuggestions = await messageActions.getNestedSuggestionsAction(
      {
        dashboardFilters: rFilter(f => !propEq('name', name)(f), dashboardFilters),
        setFilters: rFilter(f => !propEq('name', name)(f), setFilters),
        dashboardFilterMode,
        setFilterMode,
      },
      {
        realm,
        token,
        field: nestedFieldPath,
        innerField: innerFieldName,
        term: newValue,
      }
    )
    setHitCount(newSuggestions.data?.length)
    setSuggestions(newSuggestions.data)
  }, INPUT_TEXT_DEBOUNCE_TIME), [])

  useEffect(() => {
    // Incoming filter term is a string for a catch-all filter or a list of strings for selected keywords
    // allowPrefixFiltering allows the user to perform "All matches of XYZ" instead of explicit keyword selections
    const allowPrefixFiltering = filter.type === 'nested_matches'
    const isStringTerm = typeof filter.term === 'string'
    getSuggestionsDebounced(allowPrefixFiltering && isStringTerm ? filter.term : '')
    setSelectedKeywords(isStringTerm ? (allowPrefixFiltering && filter.term?.size ? [filter.term] : []) : filter.term)
  }, [filter.term])

  const handleRowClick = keyword => {
    const newKeywords = symmetricDifference(selectedKeywords, [keyword])
    setSelectedKeywords(newKeywords)
    onChange(newKeywords)
  }

  return (
    <I18n>
      {({ locale, t }) => (
        <Container>
          <SelectionHeader>
            <SelectionHeaderItem>
              {hitCount > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {hitCount}
                  <StatisticsText>({hitCount === 1
                    ? t({ key: 'SELECTION_MATCH' })
                    : t({ key: 'SELECTION_MATCHES' })
                  })</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
            <SelectionHeaderItem right>
              {selectedKeywords.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {selectedKeywords.length}
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
          </SelectionHeader>
          <SelectionWrapper>
            <SelectionColumnWrapper>
              <Scrollbar>
                {suggestions.map(suggestion => {
                  const isSelected = selectedKeywords.includes(suggestion)
                  return (
                    <SuggestionWrapper>
                      <Suggestion
                        key={suggestion}
                        isSelected={isSelected}
                        onClick={isSelected ? null
                          : () => handleRowClick(suggestion)
                        }
                      >
                        <SuggestionText>
                          {suggestion ? filterToString(suggestion, filter.type, filter.name, t, locale) : t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                      </Suggestion>
                    </SuggestionWrapper>
                  )
                })}
              </Scrollbar>
            </SelectionColumnWrapper>
            <SelectionColumnWrapper right>
              <Scrollbar>
                {selectedKeywords.map(keyword => (
                    <SuggestionWrapper>
                      <Suggestion
                        closeable
                        key={keyword}
                        isSelected={false}
                        onClick={() => handleRowClick(keyword)}
                      >
                        <SuggestionText>
                          {keyword ? filterToString(keyword, filter.type, filter.name, t, locale) : t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                        <CloseIcon>×</CloseIcon>
                      </Suggestion>
                    </SuggestionWrapper>
                  ))}
              </Scrollbar>
            </SelectionColumnWrapper>
          </SelectionWrapper>
        </Container>
      )}
    </I18n>
  )
}


NestedListInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  nestedFieldPath: string,
  innerFieldName: string,
}

export default NestedListInput
