import React from 'react'
import { Container, Border, AnimatedFill, StaticFill } from './styles'

export const ProgressBar = ({
  isAnimationActive,
  percentage,
  fullWidth,
  color,
  ...rest
}: {
  isAnimationActive: boolean
  percentage: number
  fullWidth: boolean
  color: string
}) => {
  return (
    <Container fullWidth={fullWidth} percentage={percentage} {...rest}>
      <Border>
        {isAnimationActive ? (
          <AnimatedFill
            color={color}
            transition={{ duration: 1 }}
            animate={{
              width: ['0%', `${fullWidth ? percentage : '100'}%`],
            }}
          />
        ) : (
          <StaticFill
            color={color}
            style={{
              width: `${fullWidth ? percentage : '100'}%`,
            }}
          />
        )}
      </Border>
    </Container>
  )
}
