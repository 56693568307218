import styled from 'styled-components'
import { colors, Body1PrimaryMainRegular, spacing } from 'static/theme'
import { ReactComponent as UndoSVG } from 'assets/dashboardStates/undo.svg'
import { ReactComponent as RedoSVG } from 'assets/dashboardStates/redo.svg'
import { ReactComponent as SaveSVG } from 'assets/dashboardStates/save.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${spacing.xs};
  &:hover {
    cursor: pointer;
  }
`

export const UndoIcon = styled(UndoSVG)<{ active: Boolean }>`
  fill: ${({ active }) => (active ? colors.primaryMain : colors.primaryLight)};
`

export const RedoIcon = styled(RedoSVG)<{ active: Boolean }>`
  fill: ${({ active }) => (active ? colors.primaryMain : colors.primaryLight)};
`
export const SaveIcon = styled(SaveSVG)<{ active: Boolean }>`
  fill: ${({ active }) => (active ? colors.primaryMain : colors.primaryLight)};
`

export const Seperator = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${colors.primaryLight};
  margin-left: ${spacing.xs2};
`

export const Text = styled(Body1PrimaryMainRegular)<{ active: Boolean }>`
  color: ${({ active }) => (active ? colors.primaryMain : colors.primaryLight)};
  margin-left: ${spacing.xxs};
`
