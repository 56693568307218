import styled from 'styled-components'
import {
  MODAL_SIDEBAR,
  BACKGROUND_LIGHT,
} from '../../../static/colors'

export const DashboardPermissionsContainer = styled.div`
  width: 908px;
  height: 534px;
  max-width: 90vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`

export const DashboardSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 25%;
  height: 100%;
  padding: 18px;
  background-color: ${MODAL_SIDEBAR};
`

export const DashboardSelect = styled.div`
  height: 34px;
  padding: 8px;
  background-color: ${props => (props.isSelected ? BACKGROUND_LIGHT : 'transparent')};
  border-radius: 4px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => (props.isSelected ? 'box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);' : '')}
  
  &:hover {
    cursor: ${props => (props.isSelected ? 'auto' : 'pointer')};
  }
`

export const PermissionsArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  height: 100%;
  padding: 8px;
`
