import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '@logicea/react-auth'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { I18n } from '../../../lib/i18n'
import {
  Container,
  Content,
  Left,
  NameIcon,
  Title,
  WebsiteLink,
  Right,
  Form,
  Label,
  BottomLinkText,
} from '../authStyles'
import {
  FormField,
  Input,
  Actions,
  LinkText,
  Button,
  ButtonText,
  Loading,
  MailIcon,
  KeyIcon,
  Error,
  SocialActions,
  SocialButton,
  MicrosoftIcon,
  GoogleIcon,
} from './styles'

const { string, func, bool, object } = PropTypes

const Social = ({
  authenticating,
  handleGoogleSubmit,
  handleMicrosoftSubmit,
  handleMoreOptionsClick,
}) => (
  <I18n>
    {({ t }) => (
      <Form>
        <SocialActions>
          <SocialButton onClick={handleMicrosoftSubmit} disabled={authenticating}>
            <ButtonText>
              <MicrosoftIcon />
              {t({ key: 'AUTH_MICROSOFT_LOGIN' })}
            </ButtonText>
          </SocialButton>
          <SocialButton
            onClick={handleGoogleSubmit}
            disabled={authenticating}
            inverted
          >
            <ButtonText inverted>
              <GoogleIcon />
              {t({ key: 'AUTH_GOOGLE_LOGIN' })}
            </ButtonText>
          </SocialButton>
          <BottomLinkText onClick={handleMoreOptionsClick}>
            {t({ key: 'AUTH_MORE_OPTIONS' })}
          </BottomLinkText>
        </SocialActions>
      </Form>
    )}
  </I18n>
)

const Credentials = ({ authenticating, username, password, errors, setIsSocial, handleChange, handleSubmit }) => (
  <I18n>
    {({ t }) => (
      <Form onSubmit={handleSubmit}>
        <Label>{t({ key: 'AUTH_SIGN_IN_LABEL' })}</Label>
        <FormField>
          <MailIcon />
          <Input
            name="username"
            value={username}
            placeholder={t({ key: 'AUTH_EMAIL_PLACEHOLDER' })}
            onChange={handleChange}
          />
        </FormField>
        <FormField>
          <KeyIcon />
          <Input
            name="password"
            value={password}
            type="password"
            placeholder={t({ key: 'AUTH_PASSWORD_PLACEHOLDER' })}
            onChange={handleChange}
          />
        </FormField>
        {errors && (
          <Error>
            {t({ key: 'AUTH_INVALID_CREDENTIALS' })}
          </Error>
        )}
        <Actions>
          <LinkText>{t({ key: 'AUTH_FORGOT_PASSWORD_LABEL' })}</LinkText>
          <Button type="submit" disabled={authenticating}>
            <ButtonText>
              {authenticating ? <Loading /> : t({ key: 'AUTH_SIGN_IN_BUTTON' })}
            </ButtonText>
          </Button>
        </Actions>
        <BottomLinkText onClick={() => setIsSocial(true)}>
          {t({ key: 'AUTH_MICROSOFT_LOGIN' })}
        </BottomLinkText>
      </Form>
    )}
  </I18n>
)

const Login = () => {
  const auth = useContext(AuthContext)

  const [isSocial, setIsSocial] = useState(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (auth.tokens) navigate('/')
  }, [auth.tokens])

  const setInputValue = {
    username: setUsername,
    password: setPassword,
  }

  const handleChange = event => {
    const { name, value } = event.target
    setInputValue[name](value)
  }

  const handleMicrosoftSubmit = event => {
    event.preventDefault()
    auth.msLogin()
  }

  const handleGoogleSubmit = event => {
    event.preventDefault()
    auth.gLogin()
  }

  const handleCredentialsSubmit = event => {
    event.preventDefault()
    auth.usernameBasedLogin({ username, password })
  }

  const handleMoreOptionsClick = () => {
    auth.usernameBasedLogin({})
  }

  const { authenticating, errors } = auth

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Content>
            <Left>
              <NameIcon />
              <Title>{t({ key: 'AUTH_PAGE_TITLE' })}</Title>
              <WebsiteLink href="https://www.inky.com">
                {t({ key: 'AUTH_WEBSITE_LABEL' })}
              </WebsiteLink>
            </Left>
            <Right>
              {isSocial
                ? (
                  <Social
                    authenticating={authenticating}
                    handleMicrosoftSubmit={handleMicrosoftSubmit}
                    handleGoogleSubmit={handleGoogleSubmit}
                    handleMoreOptionsClick={handleMoreOptionsClick}
                  />
                ) : (
                  <Credentials
                    errors={errors}
                    username={username}
                    password={password}
                    handleChange={handleChange}
                    setIsSocial={setIsSocial}
                    authenticating={authenticating}
                    handleSubmit={handleCredentialsSubmit}
                  />
                )
              }
            </Right>
          </Content>
        </Container>
      )}
    </I18n>
  )
}

Social.propTypes = {
  handleMicrosoftSubmit: func,
  handleGoogleSubmit: func,
  authenticating: bool,
  handleMoreOptionsClick: func,
}

Credentials.propTypes = {
  authenticating: bool,
  username: string,
  password: string,
  errors: object,
  setIsSocial: func,
  handleChange: func,
  handleSubmit: func,
}

export default Login
