import isNil from 'ramda/src/isNil'
import reject from 'ramda/src/reject'
import zipObj from 'ramda/src/zipObj'
import isEmpty from 'ramda/src/isEmpty'
import flatten from 'ramda/src/flatten'
import mergeRight from 'ramda/src/mergeRight'
import { MESSAGE_PROPERTIES } from '../static/filterNames'

export const fromKotlinFilter = filter => {
  switch (filter.type) {
    case 'boolean': {
      return {
        name: filter.name,
        type: 'boolean',
        term: filter.value,
        not: filter.not,
      }
    }
    case 'boolean_exists': {
      return {
        name: filter.name,
        type: 'boolean_exists',
        term: filter.value,
        not: filter.not,
      }
    }
    case 'matches': {
      const term = filter.values.length > 1 ? filter.values : filter.values[0]
      return {
        name: filter.name,
        type: 'text',
        term,
        not: filter.not,
      }
    }
    case 'exists': {
      return {
        name: filter.name,
        type: 'nested',
        term: !filter.not,
        not: filter.not,
      }
    }
    case 'date': {
      const { from, to } = filter.value
      return {
        name: filter.name,
        type: filter.format === 'RELATIVE' ? 'relative_date' : 'date',
        term: filter.format === 'RELATIVE' ? { relativeFrom: from, relativeTo: to } : { from, to },
        not: filter.not,
      }
    }
    case 'links': {
      const term = filter.search === 'PREFIX' ? filter.values[0] : filter.values
      return {
        name: 'links',
        type: 'nested',
        term: filter.format === 'DOMAIN' ? { domain: term } : { url: term },
        not: filter.not,
      }
    }
    case 'attachments': {
      const term = filter.search === 'PREFIX' ? filter.fileNames[0] : filter.fileNames
      const filesize = filter.fileSize ? { sizeFrom: filter.fileSize.from, sizeTo: filter.fileSize.to } : {}
      return {
        name: 'attachments',
        type: 'nested',
        term: mergeRight({ filename: isEmpty(term) ? null : term, filetype: filter.fileType }, filesize),
        not: filter.not,
      }
    }
    case 'headers': {
      const term = filter.search === 'PREFIX' ? filter.values[0] : filter.values
      return {
        name: filter.name,
        type: 'nested',
        term: filter.format === 'ADDRESS' ? { address: term } : { friendlyName: term },
        not: filter.not,
      }
    }
    case 'nested_terms': {
      const term = filter.values
      return {
        name: filter.name,
        type: 'nested_terms',
        term: term,
        not: filter.not,
      }
    }
    case 'nested_matches': {
      let term = filter.values.length > 1 ? filter.values : filter.values[0]
      return {
        name: filter.name,
        type: 'nested_matches',
        term: term,
        not: filter.not,
      }
    }
    case 'in': {
      const term = filter.search === 'PREFIX' ? filter.values[0] : filter.values
      switch (filter.name) {
        case 'threat_level':
          return {
            name: 'threat_level',
            type: 'byte',
            term: term.map(tl => parseInt(tl, 10)),
            not: filter.not,
          }
        case 'reason_ids':
          return {
            name: 'reason_ids',
            type: 'integer',
            term: term.map(rid => parseInt(rid, 10)),
            not: filter.not,
          }
        case 'microsoft_scl':
          return {
            name: 'microsoft_scl',
            type: 'integer',
            term: term.map(scl => parseInt(scl, 10)),
            not: filter.not,
          }
        case 'images':
          return {
            name: 'images',
            type: 'nested',
            term: { url: term },
            not: filter.not,
          }
        case 'sender_ip':
        case 'connecting_ip':
          return {
            name: filter.name,
            type: 'ip',
            term,
            not: filter.not,
          }
        case 'sensitive_content_categories':
        default:
          return {
            name: filter.name,
            type: 'keyword',
            term,
            not: filter.not,
          }
      }
    }
    default:
      return {
        name: filter.name,
        type: 'keyword',
        term: filter.term,
        not: filter.not,
      }
  }
}

export const toKotlinFilter = filter => {
  const {term} = filter
  switch (filter.type) {
    case 'text': {
      const { term } = filter
      return {
        type: 'matches',
        name: filter.name,
        values: typeof term === 'string' ? [term] : term,
        not: filter.not || false,
      }
    }
    case 'boolean': {
      return {
        name: filter.name,
        type: 'boolean',
        value: filter.term,
        not: filter.not || false,
      }
    }
    case 'boolean_exists': {
      return {
        name: filter.name,
        type: 'boolean_exists',
        value: filter.term,
        not: filter.not || false,
      }
    }
    case 'date':
      return {
        type: 'date',
        name: 'processed_date',
        format: 'STANDARD',
        value: { from: filter.term.from.toString(10), to: filter.term.to.toString(10) },
        not: filter.not || false,
      }
    case 'relative_date':
      return {
        type: 'date',
        name: 'processed_date',
        format: 'RELATIVE',
        value: { from: filter.term.relativeFrom.toString(10), to: filter.term.relativeTo.toString(10) },
        not: filter.not || false,
      }
    case 'nested_terms':
      return {
        type: 'nested_terms',
        name: filter.name,
        values: typeof term === 'string' ? [term] : term,
        not: filter.not || false,
      }
    case 'nested_matches':
      return {
        type: 'nested_matches',
        name: filter.name,
        search: typeof term === 'string' ? 'PREFIX' : 'TERM',
        values: typeof term === 'string' ? [term] : term,
        not: filter.not || false,
      }
    case 'nested':
      switch (filter.name) {
        case 'links': {
          const term = filter.term.domain || filter.term.url
          return {
            type: 'links',
            name: 'links',
            format: filter.term.domain ? 'DOMAIN' : 'URL',
            values: typeof term === 'string' ? [term] : term,
            search: typeof term === 'string' ? 'PREFIX' : 'TERM',
            not: filter.not || false,
          }
        }
        case 'images': {
          const term = filter.term.url
          return {
            type: 'in',
            name: 'images',
            values: typeof term === 'string' ? [term] : term,
            search: typeof term === 'string' ? 'PREFIX' : 'TERM',
            not: filter.not || false,
          }
        }
        case 'attachments': {
          const { filetype, filename, sizeFrom, sizeTo } = filter.term
          return {
            type: 'attachments',
            name: 'attachments',
            fileType: filetype,
            fileSize: (sizeFrom || sizeTo) ? { from: sizeFrom.toString(10), to: sizeTo.toString(10) } : null,
            fileNames: typeof filename === 'string' ? [filename] : filename || [],
            search: typeof filename === 'string' ? 'PREFIX' : 'TERM',
            not: filter.not || false,
          }
        }
        case 'has_attachments':
        case 'reports':
        case 'link_clicks':
          return {
            type: 'exists',
            name: filter.name,
            not: filter.not,
          }
        default: {
          const term = filter.term.address || filter.term.friendlyName
          return {
            type: 'headers',
            name: filter.name,
            format: filter.term.address ? 'ADDRESS' : 'FRIENDLY_NAME',
            search: typeof term === 'string' ? 'PREFIX' : 'TERM',
            values: typeof term === 'string' ? [term] : term,
            not: filter.not || false,
          }
        }
      }
    case 'byte':
    case 'integer':
      return {
        type: 'in',
        name: filter.name,
        values: filter.term ? filter.term.map(number => number.toString(10)) : [],
        search: 'TERM',
        not: filter.not || false,
      }
    default: {
      const { term } = filter
      return {
        type: 'in',
        name: filter.name,
        values: typeof term === 'string' ? [term] : term,
        search: typeof term === 'string' ? 'PREFIX' : 'TERM',
        not: filter.not || false,
      }
    }
  }
}

const fromKotlinMessageSet = set => reject(isNil, {
  id: set.id,
  content: set.name,
  visualization: set.visualization ? set.visualization.toLowerCase() : null,
  filters: set.filters ? set.filters.map(fromKotlinFilter) : [],
  filterMode: set.filterMode,
  messageId: set.messageId,
})

const fromKotlinColumn = column => reject(isNil, {
  id: column.id,
  mode: column.mode ? column.mode.toLowerCase() : null,
  setIds: column.sets ? column.sets.map(set => set.id) : [],
})

export const fromKotlinLayout = layout => {
  const columnOrder = layout.columns ? layout.columns.map(column => column.id) : null
  const sets = layout.columns ? flatten(layout.columns.map(column => column.sets)) : null
  const setIds = sets ? sets.map(set => set.id) : null

  return reject(isNil, {
    columnOrder,
    set_counter: layout.setCounter,
    column_counter: layout.columnCounter,
    sets: setIds
      ? zipObj(setIds, sets.map(fromKotlinMessageSet))
      : null,
    columns: columnOrder
      ? zipObj(columnOrder, layout.columns.map(fromKotlinColumn))
      : null,
  })
}

const toKotlinMessageSet = set => reject(isNil, {
  id: set.id,
  name: set.content,
  visualization: set.visualization ? set.visualization.toUpperCase() : null,
  filters: set.filters
    ? set.filters.map(f => reject(isNil, toKotlinFilter(f)))
    : null,
  filterMode: set.filterMode,
  messageId: set.messageId,
})

const toKotlinColumn = (column, messageSets) => reject(isNil, {
  id: column.id,
  mode: column.mode ? column.mode.toUpperCase() : null,
  sets: column.setIds.map(setId => toKotlinMessageSet(messageSets[setId])),
})

export const toKotlinLayout = layout => reject(isNil, {
  setCounter: layout.set_counter,
  columnCounter: layout.column_counter,
  columns: layout.columnOrder ? layout.columnOrder.map(columnId =>
    toKotlinColumn(layout.columns[columnId], layout.sets)) : [],
})

const fromKotlinProperty = property => ({
  name: property.name,
  type: MESSAGE_PROPERTIES[property.name],
})

export const fromKotlinProperties = properties =>
  properties.map(p => fromKotlinProperty(p))
