import styled from 'styled-components'
import {Input as SemanticInput} from "semantic-ui-react"
import {Divider as SemanticDivider} from "semantic-ui-react"
import {Checkbox as SemanticCheckbox} from "semantic-ui-react";
import {Dropdown as SemanticDropdown} from "semantic-ui-react";

export const Dropdown = styled(SemanticDropdown)`
  width: 220px;
`

export const Divider = styled(SemanticDivider)`
  width: 90%;
`

export const OuterContainer = styled.div`
  width: 750px;
  height: 380px;
  max-width: 90vw;
  max-height: 90vh;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  font-size: 11px;
`

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
`

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 40px;
`

export const InputContainer = styled.div`
  padding-bottom: 8px;
`

export const Input = styled(SemanticInput)`
  width: 180px;
`

export const DayInput = styled(SemanticInput)`
  width: 50px;
`

export const Checkbox = styled(SemanticCheckbox)`
  padding: 5px 0 22px 0;
`

export const Title = styled.div`  
  padding: 20px;
  text-transform: capitalize;
  font-size: 28px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonsWrapper = styled.div`
  width: 220px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 20px;
`
