import React from 'react'
import { number as formatNumber } from 'format-message'
import { Container, Circle, Threats } from './styles'

type ThreatLevelCircleProps = {
  threatColor: string
  threats: number
}

const ThreatLevelCircle = ({
  threatColor,
  threats,
}: ThreatLevelCircleProps) => {
  return (
    <Container>
      <Circle threatColor={threatColor} />
      <Threats>{formatNumber(threats)}</Threats>
    </Container>
  )
}

export { ThreatLevelCircle }
