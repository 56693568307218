import React, {useContext, useEffect, useRef, useState} from "react";
import {
  NoActionTakenIcon,
  TakeNoActionClear,
  TrashIcon,
} from "../MessageList/styles";
import {
  parseElasticsearchID,
  saveAction,
  Tooltip
} from "../../lib/util";
import {DashboardsContext} from "../../providers/Dashboards";
import {I18n} from "../../lib/i18n";
import {Divider, Dropdown, Icon, Loader} from "semantic-ui-react";
import actions from '../../requests/actions'
import {AuthContext} from "../../@logicea/react-auth";
import {forEachObjIndexed} from "ramda";

const MessageActionBar = ({selectedMessages, isMultiSelect, isTakeNoAction}) => {

  const auth = useContext(AuthContext)
  const [allowItems, setAllowItems] = useState([{text: 'Loading...'}])
  const [blockItems, setBlockItems] = useState([{text: 'Loading...'}])
  const [remediationItems, setRemediationItems] = useState([{label: 'Loading...'}])
  const [policyItems, setPolicyItems] = useState([{text: 'Loading...'}])
  const [messageData, setMessageData] = useState({})
  const [loadingActions, setLoadingActions] = useState(true)
  const [allowActionsAvail, setAllowActionsAvail] = useState(false)
  const [blockActionsAvail, setBlockActionsAvail] = useState(false)
  const [policyActionsAvail, setPolicyActionsAvail] = useState(false)
  const [remediationAvail, setRemediationAvail] = useState(false)

  const context = useContext(DashboardsContext)
  const {
    userPermissions,
    setRemediationModalVisibility,
    setListActionModalVisibility,
    setListActionData,
    setRemediationMessageData,
    addMsgActionedData,
  } = context

  // TODO: How to handle different team permissions here
  let canAllowlist = false
  Object.keys(userPermissions).forEach(key => {
    if (userPermissions[key].permissions.whitelist.includes('create')) {
      canAllowlist = true
    }
  })

  let canBlocklist = false
  Object.keys(userPermissions).forEach(key => {
    if (userPermissions[key].permissions.blacklist.includes('create')) {
      canBlocklist = true
    }
  })

  let canAddPolicy = false
  Object.keys(userPermissions).forEach(key => {
    if (userPermissions[key].permissions.policies.includes('modify')) {
      canAddPolicy = true
    }
  })


  // Start out assuming we can remediate if there is at least 1 message selected, then remove the remediation option
  // if there is at least 1 message that can not be remediated
  let canRemediate = selectedMessages.length > 0

  // Check to see if ALL selected messages can be remediated
  selectedMessages.forEach(msg => {
    let toks = atob(msg.id).split('/')
    let teamid = toks[0]

    if (Object.keys(userPermissions).length) {
      let canRem = false

      // $global is not a real team but parent to all, so check if user can remediate child teams
      if ('$global' in userPermissions) {
        if (userPermissions['$global'].permissions.remediate_children.includes('execute'))
          canRem = true
      }

      // User has an explicit permission for the team for this message
      if (teamid in userPermissions) {
        if (userPermissions[teamid].permissions.remediate_self.includes('execute'))
          canRem = true
      } else {
        // Check the parent's remediate_self permission which is flowed down to the child
        Object.keys(userPermissions).forEach(key => {
          if (userPermissions[key]?.child_teamids?.indexOf(teamid) > -1 && userPermissions[key].permissions.remediate_children.includes('execute'))
            canRem = true
        })
      }

      if (!canRem)
        canRemediate = false

    } else {
      // No permissions returned, can not remediate anything
      canRemediate = false
    }
  })

  const handleTakeNoActionClick = () => {
    let msgActions = []

    messageData.forEach(msg_id => {
      const toks = parseElasticsearchID(msg_id)
      if (isTakeNoAction) {
        saveAction("needs-action", msg_id, toks[0], auth)
        msgActions.push({action:"needs-action", messageId: msg_id})
      } else {
        saveAction("no-action", msg_id, toks[0], auth)
        msgActions.push({action: "no-action", messageId: msg_id})
      }
    })
    addMsgActionedData(msgActions)
  }

  const handleAllowlistClick = (item) => {
    if (item.data) {
      setListActionData(item, messageData)
      setListActionModalVisibility(true, 'allowlist');
    }
  }

  const handleBlocklistClick = (item) => {
    if (item.data) {
      setListActionData(item, messageData)
      setListActionModalVisibility(true, 'blocklist');
    }
  }

  const handlePolicyClick = (item) => {
    if (item.data) {
      setListActionData(item, messageData)
      setListActionModalVisibility(true, 'policy');
    }
  }

  const handleDeleteClick = (msgActions) => {
    if (msgActions) {
      setRemediationMessageData(msgActions, messageData)
      setRemediationModalVisibility(true)
    }
  }

  const getActionsReqCounter = useRef()

  useEffect( () => {
    const getActions = async () => {
      const next = getActionsReqCounter.current ? getActionsReqCounter.current + 1 : 1
      getActionsReqCounter.current = next

      if (!selectedMessages || !selectedMessages.length) {
        setAllowItems([{text: 'No Allow List Actions Available'}])
        setBlockItems([{text: 'No Block List Actions Available'}])
        setPolicyItems([{text: 'No Policy Actions Available'}])
        setRemediationItems([{label:'No Actions Available'}])
        setAllowActionsAvail(false)
        setBlockActionsAvail(false)
        setPolicyActionsAvail(false)
        setRemediationAvail(false)
        setLoadingActions(false)
        return
      }

      setLoadingActions(true)
      let messages = []
      let msgId = ""
      if (selectedMessages.length === 1) {
        msgId = selectedMessages[0].id
        messages.push(msgId)
      } else
        selectedMessages.forEach(msg => {
          messages.push(msg.id)
        })

      setMessageData(messages)

      setAllowItems([{text: 'Loading...'}])
      setBlockItems([{text: 'Loading...'}])
      setPolicyItems([{text: 'Loading...'}])
      setRemediationItems([{label:'Loading'}])
      setAllowActionsAvail(false)
      setBlockActionsAvail(false)
      setPolicyActionsAvail(false)
      setRemediationAvail(false)
      let options
      if (messages.length > 1)
        options = await actions.getRemediationOptionsAction({
          messages: messages
        }, auth.tokens?.accessToken, true)
      else
        options = await actions.getMessageActionOptionsAction({
        messages: messages
      }, auth.tokens?.accessToken)

      if (getActionsReqCounter.current !== next)
        return

      if (!options) {
        setAllowItems([{text: 'No Allow List Actions Available'}])
        setBlockItems([{text: 'No Block List Actions Available'}])
        setPolicyItems([{text: 'No Policy Actions Available'}])
        setRemediationItems([{label:'No Actions Available'}])
        return
      }

      if (messages.length > 1) {

        // TODO: Add support for allow, block, and policy actions that are common

        // Only allow deletions if every message has a single delete_message action
        // This could change in the future, but currently every action should be the same
        let atLeastOne = false
        let allowRemediation = true
        if (options?.remediation_actions) {
          forEachObjIndexed((msgData, key) => {
            if (msgData?.actions?.length !== 1)
              allowRemediation = false
            else {
              if (!msgData?.actions) {
                allowRemediation = false
              } else {
                msgData.actions.forEach(action => {
                  atLeastOne = true
                  if (action.action !== 'delete_message') {
                    allowRemediation = false
                  }
                })
              }
            }
          }, options.remediation_actions)
        }

        if (allowRemediation && atLeastOne) {
          setRemediationAvail(true)
          setRemediationItems(options?.remediation_actions)
        } else {
          setRemediationItems([{label: 'No Remediation Actions Available'}])
        }

        // TODO: For multiple messages, find common actions

      } else {
        setAllowActionsAvail(canAllowlist)
        setBlockActionsAvail(canBlocklist)
        setPolicyActionsAvail(canAddPolicy)

        if (canAllowlist) {
          if (!options?.allow_list_actions || options?.allow_list_actions?.error || options?.allow_list_actions[msgId]?.error || !options?.allow_list_actions[msgId]?.length)
            setAllowItems([{text: 'No Allow List Actions Available'}])
          else {
            // Check if all actions are already in-place
            let showList = false
            options.allow_list_actions[msgId].forEach(action => {if (!action.exists) showList = true})
            setAllowItems(showList ? options.allow_list_actions[msgId] : [{text: 'No New Allow List Actions Available'}])
          }
        }

        if (canBlocklist) {
          if (!options?.block_list_actions || options?.block_list_actions?.error || options?.block_list_actions[msgId]?.error || !options?.block_list_actions[msgId]?.length)
            setBlockItems([{text: 'No Block List Actions Available'}])
          else {
            // Check if all actions are already in-place
            let showList = false
            options.block_list_actions[msgId].forEach(action => {if (!action.exists) showList = true})
            setBlockItems(showList ? options.block_list_actions[msgId] : [{text: 'No New Block List Actions Available'}])
            }
        }

        if (canAddPolicy) {
          if (!options?.policy_actions || options?.policy_actions?.error || options?.policy_actions[msgId]?.error || !options?.policy_actions[msgId]?.length)
            setPolicyItems([{text: 'No Policy Actions Available'}])
          else {
            // Check if all actions are already in-place
            let showList = false
            options.policy_actions[msgId].forEach(action => {if (!action.exists) showList = true})
            setPolicyItems(showList ? options.policy_actions[msgId] : [{text: 'No New Policy Actions Available'}])
          }
        }

        if (canRemediate) {
          if (!options?.remediation_actions || options?.remediation_actions?.error || options?.remediation_actions[msgId]?.error || !options?.remediation_actions[msgId]?.actions?.length)
            setRemediationItems([{label: 'No Remediation Actions Available'}])
          else {
            if (options?.remediation_actions[msgId]?.actions?.length)
              setRemediationAvail(true)
            setRemediationItems(options?.remediation_actions)
          }
        }
      }
      setLoadingActions(false)
    }
    getActions()
  }, [selectedMessages])

  return (
    <I18n>{({t}) => (
      <React.Fragment>
        <React.Fragment>
          {isTakeNoAction === false &&
            <NoActionTakenIcon
              data-tip
              data-for="takeNoAction"
              onClick={() => handleTakeNoActionClick()}
              viewonly={"false"}
            />
          }
          {isTakeNoAction === false &&
            <Tooltip bottom id="takeNoAction">
            <span>{t({key: 'TOOLTIP_TAKE_NO_ACTION'})} </span>
            </Tooltip>
          }
          {isTakeNoAction === true &&
            <Icon.Group onClick={() => handleTakeNoActionClick()}>
              <NoActionTakenIcon
                viewonly={"true"}
              />
              <TakeNoActionClear
                data-tip
                data-for="reverseTakeNoAction"
              />
            </Icon.Group>
          }
          {isTakeNoAction === true &&
            <Tooltip bottom id="reverseTakeNoAction">
              <span>{t({key: 'TOOLTIP_REVERSE_TAKE_NO_ACTION'})} </span>
            </Tooltip>
          }
        </React.Fragment>
        <Loader
          active={loadingActions} inline size='mini'
          data-tip
          data-for="loadingActionsTooltip"
          style={{marginRight:"4px"}}
        />

        <Tooltip bottom id="loadingActionsTooltip">
          <span>{t({key: 'TOOLTIP_LOADING_ACTIONS'})} </span>
        </Tooltip>
        {canAllowlist && allowActionsAvail && !loadingActions && (
          <React.Fragment>
            <Dropdown
              icon='check'
              className='icon'
              upward={false}
              scrolling
              data-tip
              data-for="allowlistMessages"
            >
              <Dropdown.Menu>
                {
                  allowItems?.map((item, indx) => {
                      if (!item.exists)
                        return item.divider ? (<Divider key={indx} />) : (<Dropdown.Item onClick={()=>handleAllowlistClick(item)} key={indx}><div dangerouslySetInnerHTML={{ __html: item.text }} /></Dropdown.Item>)
                      else return null
                    }
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
            <Tooltip top id="allowlistMessages">
              <span>{t({key: 'TOOLTIP_ALLOWLIST_MESSAGES'})} </span>
            </Tooltip>
          </React.Fragment>
        )}
        {canBlocklist && blockActionsAvail && !loadingActions && (
          <React.Fragment>
            <Dropdown
              icon='cancel'
              className='icon'
              upward={false}
              scrolling
              data-tip
              data-for="blocklistMessages"
            >
              <Dropdown.Menu>
                {
                  blockItems?.map((item, indx) => {
                    if (!item.exists)
                      return item.divider ? (<Divider key={indx} />) : (<Dropdown.Item onClick={()=>handleBlocklistClick(item)} key={indx}><div dangerouslySetInnerHTML={{ __html: item.text }}/></Dropdown.Item>)
                    else return null
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
            <Tooltip top id="blocklistMessages">
              <span>{t({key: 'TOOLTIP_BLOCKLIST_MESSAGES'})} </span>
            </Tooltip>
          </React.Fragment>
        )}
        {canAddPolicy && policyActionsAvail && !loadingActions && (
          <React.Fragment>
            <Dropdown
              icon='cogs'
              className='icon'
              upward={false}
              scrolling
              data-tip
              data-for="policyMessages"
            >
              <Dropdown.Menu>
                {
                  policyItems?.map((item, indx) => {
                    return item.divider ? (<Divider key={indx} />) : (<Dropdown.Item onClick={()=>handlePolicyClick(item)} key={indx}><div dangerouslySetInnerHTML={{ __html: item.text }}/></Dropdown.Item>)
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
            <Tooltip top id="policyMessages">
              <span>{t({key: 'TOOLTIP_POLICY_MESSAGES'})} </span>
            </Tooltip>
          </React.Fragment>
        )}
        {canRemediate && remediationAvail && !loadingActions && (
          <React.Fragment>
            <TrashIcon
              data-tip
              data-for="trashMessages"
              onClick={() => handleDeleteClick(remediationItems)}
            />
            <Tooltip bottom id="trashMessages">
              <span>{t({key: 'TOOLTIP_REMEDIATE_MESSAGES'})} </span>
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    )}
    </I18n>
  )
}

export default MessageActionBar