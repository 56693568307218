// export const API_URL = 'https://inky-stage.logicea.net/n'
// export const API_URL = 'http://localhost:10010'
export const API_URL = '/n'
export const KOTLIN_URL = '/api'

let prefix =
  window.location.hostname.startsWith('dev.') ||
  window.location.hostname === 'localhost'
    ? 'dev.'
    : window.location.hostname.startsWith('test.')
    ? 'test.'
    : ''
export const PUBLIC_API_SERVER_URL = `https://admin-api.${prefix}inkyphishfence.com:783/`

// TODO: The following should be string template with ${prefix} once we move off of old prod
export const INKY_MESSAGE_DETAILS =
  prefix === 'dev.'
    ? 'https://protection.dev.inkyphishfence.com/dashboard'
    : prefix === 'test.'
    ? 'https://protection.test.inkyphishfence.com/dashboard'
    : `https://shared.outlook.inky.com/details?id=`
export const getInkyMessageLink = (team, id) => {
  if (prefix === 'dev.')
    return `https://protection.dev.inkyphishfence.com/dashboard?teamid=${team}&id=${id}`
  if (prefix === 'test.')
    return `https://protection.test.inkyphishfence.com/dashboard?teamid=${team}&id=${id}`

  return `https://shared.outlook.inky.com/dashboard?teamid=${team}&id=${id}`
}

export const getInkyReportLink = (id, email) => {
  if (prefix === 'dev.')
    return `https://protection.dev.inkyphishfence.com/report?id=${id}&contact_email=${email}&override=true`
  if (prefix === 'test.')
    return `https://protection.test.inkyphishfence.com/report?id=${id}&contact_email=${email}&override=true`

  return `https://shared.outlook.inky.com/report?id=${id}&contact_email=${email}&override=true`
}

export const MAX_NUM_OF_COLUMNS = 3
export const MIN_COLUMN_HEIGHT = 400
export const MAX_DASHBOARD_NAME_LENGTH = 50
export const MAX_DASHBOARD_SUBTITLE_LENGTH = 128
export const TINY_VISUALIZATION_WIDTH_THRESHOLD = 300
export const TINY_VISUALIZATION_HEIGHT_THRESHOLD = 250
export const SIDEBAR_WIDTH = 240
export const SIDEBAR_WIDTH_COLLAPSED = 56
export const VISUALIZATION_MARGIN = 16
export const MIN_WIDTH = 640
export const MIN_HEIGHT = 768
export const INPUT_TEXT_DEBOUNCE_TIME = 500

export const REFRESH_INTERVAL = 60000 // 1 minute

export const DOWNLOAD_LIMIT = 10000
export const MSG_PAGE_LIMIT = 20
export const MSG_PAGE_SIZE = 500
export const EVENT_AUTH_API_KEY = 'rCK7O9aykN1nCpAFDjI796Uu0hlD8hCe8OF55UJM'

export const DEFAULT_REPORT_TIMEFRAME = 10
export const GTM_KEY = 'GTM-TW6GNHV'
