import styled from 'styled-components'
import {
  colors,
  HeadlineH1PrimaryMainBold,
  HeadlineH3DarkGreyRegular,
  HeadlineH3WhiteRegular,
  spacing,
} from 'static/theme'
import { IconButton as Button } from 'components/Buttons/IconButton'
import { ReactComponent as WidgetSVG } from 'assets/marketplace/widget.svg'
import { ReactComponent as PlusSVG } from 'assets/marketplace/plus.svg'

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const WidgetIcon = styled(WidgetSVG)`
  fill: ${colors.primaryDark};
`

export const Title = styled(HeadlineH1PrimaryMainBold)`
  margin-top: ${spacing.xs};
`

export const Subtitle = styled(HeadlineH3DarkGreyRegular)`
  margin-top: ${spacing.xs};
`

export const IconButton = styled(Button)`
  && {
    margin-top: ${spacing.m};
    height: 48px;
    width: 168px;
    border-radius: 8px;
  }
`

export const PlusIcon = styled(PlusSVG)`
  fill: ${colors.white};
`

export const ButtonText = styled(HeadlineH3WhiteRegular)`
  line-height: 1.5;
  margin-left: ${spacing.xxs};
`
