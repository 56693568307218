import styled from 'styled-components'
import {
  WHITE,
  TOOLTIP_LABEL,
} from '../../static/colors'

export const TootlipContainer = styled.div`
background-color: ${WHITE};
box-shadow: 0 0 6px 3px rgba(0,0,0,0.14);
border-radius: 2px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 20px;
`

export const TooltipLabel = styled.div`
color: ${TOOLTIP_LABEL};
font-family: Lato;
font-size: 14px;
font-weight: bold;
line-height: 17px;
`

export const TooltipValue = styled.div`
color: ${TOOLTIP_LABEL};
font-family: Lato;
font-size: 14px;
line-height: 17px;
`
