import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Button as MaterialButton } from '@material-ui/core'
import { IconButton } from 'components/Buttons/IconButton'
import {
  HeadlineH1PrimaryMainBold,
  BodyDarkGreyRegular,
  colors,
  spacing,
} from 'static/theme/index'
import Logo from 'assets/logo/inky-icon.png'

export const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 300;
`

export const Background = styled.div`
  background-color: ${colors.primaryMainAlpha50};
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
`

export const Toast = styled.div`
  position: absolute;
  bottom: ${spacing.xxl3};
  display: flex;
  width: 376px;
  height: 88px;
  border-radius: 4px;
  padding: ${spacing.m};
  align-self: flex-end;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 0 30px 0 ${colors.primaryMain};
  background-color: ${colors.white};
`

export const Box = styled(motion.div)`
  display: flex;
  width: 496px;
  height: 424px;
  border-radius: 4px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 30px 0 ${colors.primaryMain};
  background-color: ${colors.white};
`

export const Headline = styled(HeadlineH1PrimaryMainBold)`
  margin-top: ${spacing.l};
  margin-bottom: ${spacing.s};
`

export const Prompt = styled(BodyDarkGreyRegular)`
  text-align: center;
`

export const Button = IconButton

export const LargeButton = styled(MaterialButton).attrs({
  color: 'secondary',
  variant: 'contained',
  size: 'large',
})`
  font-size: 16px !important;
  padding: 15px 32px !important;
  border-radius: 8px !important;
  margin-top: ${spacing.m} !important;
`

export const Content = styled.div`
  padding: ${spacing.m};
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const LogoIcon = styled.img.attrs({
  src: Logo,
})`
  width: 98px;
`

export const Footer = styled.div`
  width: 100%;
  height: 137px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  background-color: ${colors.background};
`

export const SkipButton = styled(MaterialButton)`
  color: ${colors.primaryMain} !important;
  margin-top: ${spacing.xs} !important;
  text-transform: none !important;
  font-size: 14px !important;
`
