import styled from 'styled-components'
import { colors, Body2DarkGreyRegular, spacing } from 'static/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 5rem;
`

export const Circle = styled.div<{ threatColor: string }>`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  border: solid 0.2rem ${colors.primaryExtraLight};
  background-color: ${({ threatColor }) => threatColor};
  margin: 0 ${spacing.xxs};
`

export const Threats = styled(Body2DarkGreyRegular)``
