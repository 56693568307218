import React from 'react'
import ReactDOM from 'react-dom'
import {
  Cell,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
} from 'recharts'
import {
  DARK_GREY,
  REASON_ID_COLORS,
} from '../../../static/colors'
import { convertChart } from '../exportHelpers'
import I18nProvider from 'lib/i18n/I18nWrapper'
import { I18n } from '../../../lib/i18n'
import { TickText } from './styles'

const CountTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <TickText
      x={5}
      y={4}
      textAnchor="end"
      fill={DARK_GREY}
    >
      {payload.value}
    </TickText>
  </g>
)

const CategoryTick = ({ x, y, payload }) => (
  <I18n>{({ t }) => (
    <g transform={`translate(${x},${y})`}>
      <TickText
        textLength={170}
        lengthAdjust="spacingAndGlyphs"
        textAnchor="end"
        fill={DARK_GREY}
        transform="rotate(-90)"
      >
        {t({ key: 'REASONS', variables: { id: payload.value } })}
      </TickText>
    </g>
  )}
  </I18n>
)

export const exportChart = (aggregationData, scale, domain, minPointSize, t) => {

  // Output image size
  const WIDTH = 1920;
  const HEIGHT = 1080;

  const chart = (
    <BarChart
      width={WIDTH}
      height={HEIGHT}
      data={aggregationData}
      ref={ref => convertChart(ref, WIDTH, HEIGHT)}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="reasonId"
        tickMargin={12}
        height={200}
        tick={<CategoryTick t={t} />}
        tickLine={false}
        axisLine={false}
      />
      <YAxis
        yAxisId="b"
        type="number"
        domain={domain}
        scale={scale}
        axisLine={false}
        tickLine={false}
        tickMargin={16}
        tick={<CountTick />}
        padding={{ top: 20, bottom: 4 }}
      />
      <Bar yAxisId="b" dataKey="value" isAnimationActive={false} minPointSize={minPointSize}>
        {
          aggregationData.map((entry, index) => (
            <Cell
              key={`cell-${index}`} // eslint-disable-line react/no-array-index-key
              fill={REASON_ID_COLORS[entry.reasonId % REASON_ID_COLORS.length]}
            />
          ))
        }
      </Bar>
    </BarChart>
  )

  // Render chart component into helper div
  const helperDiv = document.createElement('tmp');
  ReactDOM.render(<I18nProvider>{chart}</I18nProvider>, helperDiv);
}
