import React, { Component } from 'react'
import PropTypes from 'prop-types'
import prop from 'ramda/src/prop'
import assoc from 'ramda/src/assoc'
import equals from 'ramda/src/equals'
import propEq from 'ramda/src/propEq'
import eqProps from 'ramda/src/eqProps'
import isEmpty from 'ramda/src/isEmpty'
import findIndex from 'ramda/src/findIndex'
import { filter as rFilter } from 'ramda/src'
import { I18n } from '../../../lib/i18n'
import messageActions from '../../../requests/message'
import {
  Container,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
} from '../filterInputStyles'

const { object, func, array, string } = PropTypes

class FiletypeInput extends Component {
  state = {
    filetype: '',
    filename: '',
    sizeFrom: '',
    sizeTo: '',
    suggestions: {
      filename: [],
      filetype: [],
    },
    selectedKeywords: '',
  }

  componentDidMount() {
    const { filter } = this.props
    const { term } = filter
    this.handleInputChange({ target: {
      name: 'filetype',
      value: term ? term.filetype : '',
    } })
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props
    if (eqProps('name', prevProps.filter, filter)) return
    const { term } = filter
    this.handleInputChange({ target: {
      name: 'filetype',
      value: term ? term.filetype : '',
    } })
  }

  handleInputChange = async event => {
    const { suggestions, filetype, sizeFrom, sizeTo } = this.state
    const {
      realm,
      token,
      filter,
      onChange,
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
    } = this.props
    const { name, value } = event.target
    const oldValue = prop(name, this.state)

    if (equals(oldValue, value) && !equals(value, '')) return

    const otherDashboardFilters = rFilter(f => !propEq('name', 'attachments')(f), dashboardFilters)
    const otherSetFilters = rFilter(f => !propEq('name', 'attachments')(f), setFilters)

    if (typeof value === 'string') {
      this.setState({ [name]: value, selectedKeywords: value })
      onChange({ [name]: value })

      if (name === 'filetype') {
        const newSuggestions = await messageActions.getNestedSuggestionsAction(
          {
            dashboardFilters: otherDashboardFilters,
            setFilters: otherSetFilters,
            dashboardFilterMode,
            setFilterMode,
          },
          {
            realm,
            token,
            field: 'attachments',
            innerField: name,
            term: '',
          }
        )
        this.setState({ suggestions: assoc(name, newSuggestions.data, suggestions) })
        if (findIndex(equals(value), suggestions.filetype) > -1) {
          const filenameSuggestions = await messageActions.getFilteredNestedSuggestionsAction(
            {
              dashboardFilters: otherDashboardFilters,
              setFilters: otherSetFilters,
              dashboardFilterMode,
              setFilterMode,
            },
            {
              realm,
              token,
              filter: {
                innerTermField: 'filetype',
                term: value,
                innerRangeField: 'size',
                rangeFrom: sizeFrom || null,
                rangeTo: sizeTo || null,
              },
              field: 'attachments',
              innerField: 'filename',
              term: '',
            }
          )
          this.setState({ suggestions: assoc('filename', filenameSuggestions.data, suggestions) })
        }
      } else {
        const newSuggestions = await messageActions.getFilteredNestedSuggestionsAction(
          {
            dashboardFilters: otherDashboardFilters,
            setFilters: otherSetFilters,
            dashboardFilterMode,
            setFilterMode,
          },
          {
            realm,
            token,
            filter: {
              innerTermField: 'filetype',
              term: filetype,
              innerRangeField: 'size',
              rangeFrom: sizeFrom || null,
              rangeTo: sizeTo || null,
            },
            field: 'attachments',
            innerField: name,
            term: value,
          }
        )
        this.setState({ suggestions: assoc(name, newSuggestions.data, suggestions) })
      }
    } else {
      this.setState({
        selectedKeywords: value,
      })
      const nameSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filter.name,
          innerField: 'filename',
          term: '',
        }
      )
      const typeSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filter.name,
          innerField: 'filetype',
          term: '',
        }
      )
      this.setState({ suggestions: {
        filename: nameSuggestions.data,
        filetype: typeSuggestions.data,
      } })
    }
  }

  handleRowClick = keyword => {
    const { onChange } = this.props
    const { selectedKeywords } = this.state
    // const newKeywords = symmetricDifference(selectedKeywords, [keyword])
    const newKeywords = selectedKeywords === keyword ? '' : keyword
    this.setState({ selectedKeywords: newKeywords })
    onChange({ filetype: newKeywords })
  }

  render() {
    const {
      suggestions,
      selectedKeywords,
    } = this.state

    return (
      <I18n>{({ t }) => (
        <Container>
          <SelectionWrapper>
            <SelectionColumnWrapper>
              <Scrollbar>
                {suggestions.filetype.map(suggestion => {
                  const isSelected = equals(suggestion, selectedKeywords)
                  return (
                    <SuggestionWrapper>
                      <Suggestion
                        key={suggestion}
                        isSelected={isSelected}
                        onClick={isSelected ? null
                          : () => this.handleRowClick(suggestion)
                        }
                      >
                        <SuggestionText>
                          {suggestion || t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                      </Suggestion>
                    </SuggestionWrapper>
                  )
                })}
              </Scrollbar>
            </SelectionColumnWrapper>
            <Seperator>
              <SeperatorIcon />
            </Seperator>
            <SelectionColumnWrapper right>
              <Scrollbar>
                {!isEmpty(selectedKeywords)
                  && [selectedKeywords].map(keyword => (
                    <SuggestionWrapper>
                      <Suggestion
                        closeable
                        key={keyword}
                        isSelected={false}
                        onClick={() => this.handleRowClick(keyword)}
                      >
                        <SuggestionText>
                          {keyword || t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                        <CloseIcon>×</CloseIcon>
                      </Suggestion>
                    </SuggestionWrapper>
                  ))
                }
              </Scrollbar>
            </SelectionColumnWrapper>
          </SelectionWrapper>
        </Container>
      )}
      </I18n>
    )
  }
}


FiletypeInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default FiletypeInput
