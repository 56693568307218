import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import { I18n } from '../../../../lib/i18n'
import {Tooltip} from '../../../../lib/util'
import MessageContent from '../MessageContent'
import {
  Container,
  TabBar,
  Tab,
  TabText,
  TabIcon,
  Content,
} from './styles'
import {AuthContext} from "../../../../@logicea/react-auth";

const { object } = PropTypes

const tabsCategories = [
  'MESSAGE_TAB_SUMMARY',
  'MESSAGE_TAB_DETAILS',
  'MESSAGE_TAB_HISTORY',
  'MESSAGE_TAB_LISTS',
  'MESSAGE_TAB_IMAGES',
  'MESSAGE_TAB_ATTACHMENTS',
  'MESSAGE_TAB_LINKS',
  'MESSAGE_TAB_LINK_CLICKS',
  'MESSAGE_TAB_REPORTS',
]

const Tabs = ({
  message,
  actionListDetails,
  handleTagSuggestionClick,
  handleDeleteTag,
  ...rest
}) => {
  const {
    id,
    images,
    attachments,
    links,
    linkClicks,
    reports,
  } = message.data
  const [activeindex, setActiveIndex] = useState(tabsCategories.length - 1)

  useEffect(() => {
    setActiveIndex(0)
  }, [])
  const activeTabIndex = activeindex

  const auth = useContext(AuthContext)

  return (
    <I18n>{({ t }) => (
      <Container>
        <TabBar>
          {tabsCategories.map((tab, i) => {
            const isActive = i === activeindex

            return (
              <React.Fragment key={id + tab}>
                <Tab
                  data-tip
                  data-for={id + tab}
                  key={id + tab}
                  active={isActive}
                  onClick={() => setActiveIndex(i)}
                >
                  {i === 0 || i === 1 || i === 2 || i === 3
                    ? (
                      <TabText active={isActive}>
                        {t({ key: tab })}
                      </TabText>
                    )
                    : <TabIcon tabIndex={i} />}
                  {i === 4 && <TabText>{images ? images.length : 0}</TabText>}
                  {i === 5 && <TabText>{attachments ? attachments.length : 0}</TabText>}
                  {i === 6 && <TabText>{links ? links.length : 0}</TabText>}
                  {i === 7 && <TabText>{linkClicks ? linkClicks.length : 0}</TabText>}
                  {i === 8 && <TabText>{reports ? reports.length : 0}</TabText>}
                </Tab>
                {i > 3 && (
                  <Tooltip id={id + tab}>
                    {i === 4 && <span>{t({ key: 'images' })}</span>}
                    {i === 5 && <span>{t({ key: 'attachments' })}</span>}
                    {i === 6 && <span>{t({ key: 'links' })}</span>}
                    {i === 7 && <span>{t({ key: 'link_clicks' })}</span>}
                    {i === 8 && <span>{t({ key: 'reports' })}</span>}
                  </Tooltip>
                )}
              </React.Fragment>
            )
          })}
        </TabBar>
        <Content>
          <MessageContent
            activeTabIndex={activeTabIndex}
            message={message}
            actionListDetails={actionListDetails}
            handleTagSuggestionClick={handleTagSuggestionClick}
            handleDeleteTag={handleDeleteTag}
            {...rest}
          />
        </Content>
      </Container>
    )}
    </I18n>
  )
}

Tabs.propTypes = {
  message: object,
}

export default Tabs
