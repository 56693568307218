import React, { useState, useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import isNil from 'ramda/src/isNil'
import propEq from 'ramda/src/propEq'
import { filter as rFilter } from 'ramda/src'
import { Loader } from 'semantic-ui-react'
import { AuthContext } from '@logicea/react-auth'
import { createResource, createCacheWithAsyncInvalidation } from '../../lib/simple-cache-utils'
import actions from '../../requests/message'

const { array, bool, string, number, func } = PropTypes

let messageCountCache

const messageCountCacheInvalidator = () => {
  messageCountCache = createCacheWithAsyncInvalidation(messageCountCacheInvalidator)
}
messageCountCache = createCacheWithAsyncInvalidation(messageCountCacheInvalidator)

const MessageCountResource = createResource(
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token }) =>
    actions.countMessagesAction(
      { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode },
      { realm, token }
    ),
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm }) =>
    JSON.stringify({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm })
)

const AsyncMessageCounter = ({
  dashboardId,
  dashboardFilters,
  dashboardFilterMode = 'AND',
  setFilterMode = 'AND',
  setFilters,
  large = false,
  isDashboard = false,
  filterName,
  timestamp,
  onFetch,
}) => {
  const [dashboard, setDashboard] = useState(null)

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  useEffect(() => {
    if (isNil(dashboard)) setDashboard(dashboardId)
    else setDashboard(null)
  }, [dashboardId])

  if (isNil(dashboard) && !isDashboard) return null

  const otherDashboardFilters = rFilter(f => !propEq('name', filterName)(f), dashboardFilters)
  const otherSetFilters = rFilter(f => !propEq('name', filterName)(f), setFilters)


  const messageCount = dashboardFilters && setFilters
    ? MessageCountResource.read(messageCountCache, {
      dashboardFilters: filterName ? otherDashboardFilters : dashboardFilters,
      setFilters: filterName ? otherSetFilters : setFilters,
      dashboardFilterMode,
      setFilterMode,
      timestamp,
      realm,
      token: tokens?.accessToken,
    }) : null
  const formatedMessageCount = messageCount ? messageCount.toLocaleString() : '-'

  if (!isNil(onFetch)) onFetch(messageCount)

  return large ? <h1>{formatedMessageCount}</h1> : formatedMessageCount
}

const MessageCounter = ({ dashboardFilters, ...rest }) => dashboardFilters && (
  <Suspense
    fallback={<Loader active size="mini" />}
  >
    <AsyncMessageCounter dashboardFilters={dashboardFilters} {...rest} />
  </Suspense>
)

AsyncMessageCounter.propTypes = {
  dashboardId: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  setFilterMode: string,
  setFilters: array,
  large: bool,
  isDashboard: bool,
  timestamp: number,
  filterName: string,
  onFetch: func,
}

export default MessageCounter
