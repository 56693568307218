import React, { Component } from 'react'
import PropTypes from 'prop-types'
import prop from 'ramda/src/prop'
import equals from 'ramda/src/equals'
import propEq from 'ramda/src/propEq'
import findIndex from 'ramda/src/findIndex'
import startsWith from 'ramda/src/startsWith'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import { Loader } from 'semantic-ui-react'
import teamActions from '../../../requests/team'
import { I18n } from '../../../lib/i18n'
import { SearchInput } from 'components/SearchInput'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'

const { object, func, string, array } = PropTypes

class TeamInput extends Component {
  state = {
    value: '',
    teams: [],
    allTeams: [],
    selectedTeams: [],
    loading: true,
  }

  async componentDidMount() {
    const {
      filter,
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
      realm,
      token,
    } = this.props
    if (filter && filter.term) this.handleTermChange(filter.term)
    const res = await teamActions.getTeamsWithHitsAction({
      dashboardFilters: rFilter(f => !propEq('name', 'teamid')(f), dashboardFilters),
      setFilters: rFilter(f => !propEq('name', 'teamid')(f), setFilters),
      dashboardFilterMode,
      setFilterMode,
    }, { realm, token })
    this.setState({
      allTeams: res.data,
      teams: res.data,
      loading: false,
    })
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props
    if (equals(prevProps.filter, filter)) return
    this.handleTermChange(filter.term)
  }

  handleTermChange = term => {
    this.setState({ selectedTeams: term })
  }

  handleRowClick = team => {
    const { onChange } = this.props
    const { selectedTeams } = this.state
    const newTeams = symmetricDifference(selectedTeams, [team])
    this.setState({ selectedTeams: newTeams })
    onChange(newTeams)
  }

  handleInputChange = event => {
    const { allTeams } = this.state
    const { value } = event.target
    const teamStartsWith = team => startsWith(value, prop('team', team))
    this.setState({
      value,
      teams: rFilter(teamStartsWith, allTeams),
    })
  }

  handleClearClick = async () => {
    const { allTeams } = this.state
    this.setState({ value: '', teams: allTeams })
  }

  render() {
    const { value, teams, selectedTeams, loading } = this.state
    const { filter } = this.props
    return loading
      ? <Loader active />
      : (
        <I18n>{({ t }) => (
          <Container>
            <SearchInput
              autoFocus={true}
              value={value}
              name={filter.name}
              onChange={this.handleInputChange}
              onClear={this.handleClearClick}
              placeholder={t({ key: filter.name })}
              key={filter.name}
            />
            <SelectionHeader>
              <SelectionHeaderItem>
                {teams.length > 100 ? (
                  <StatisticsTextBold>
                    100+
                    <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                  </StatisticsTextBold>
                ) : teams.length === 1 ? (
                  <StatisticsTextBold>
                    {teams.length}
                    <StatisticsText>({t({ key: 'SELECTION_MATCH' })})</StatisticsText>
                  </StatisticsTextBold>
                ) : (
                  <StatisticsTextBold>
                    {teams.length}
                    <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                  </StatisticsTextBold>
                )}
              </SelectionHeaderItem>
              <SelectionHeaderItem right>
                {selectedTeams.length > 100 ? (
                  <StatisticsTextBold>
                    100+
                    <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                  </StatisticsTextBold>
                ) : (
                  <StatisticsTextBold>
                    {selectedTeams.length}
                    <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                  </StatisticsTextBold>
                )}
              </SelectionHeaderItem>
            </SelectionHeader>
            <SelectionWrapper>
              <SelectionColumnWrapper>
                <Scrollbar>
                  {teams.map(team => {
                    const isSelected = findIndex(equals(team.team), selectedTeams) > -1
                    return (
                      <SuggestionWrapper>
                        <Suggestion
                          key={team.team}
                          isSelected={isSelected}
                          onClick={isSelected ? null
                            : () => this.handleRowClick(team.team)
                          }
                        >
                          <SuggestionText>
                            {team.team || '<NO VALUE>'} ({team.count})
                          </SuggestionText>
                        </Suggestion>
                      </SuggestionWrapper>
                    )
                  })}
                </Scrollbar>
              </SelectionColumnWrapper>
              <Seperator>
                <SeperatorIcon />
              </Seperator>
              <SelectionColumnWrapper right>
                <Scrollbar>
                  {selectedTeams.map(selectedTeam => (
                    <SuggestionWrapper>
                      <Suggestion
                        closeable
                        key={selectedTeam}
                        isSelected={false}
                        onClick={() => this.handleRowClick(selectedTeam)}
                      >
                        <SuggestionText>
                          {selectedTeam || t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                        <CloseIcon>×</CloseIcon>
                      </Suggestion>
                    </SuggestionWrapper>
                  ))}
                </Scrollbar>
              </SelectionColumnWrapper>
            </SelectionWrapper>
          </Container>
        )}
        </I18n>
      )
  }
}

TeamInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default TeamInput
