import React from 'react'
import styled from 'styled-components'
import { Tooltip } from '../../lib/util'
import { I18n } from '../../lib/i18n'
import {
  PRIMARY_DARK,
} from '../../static/colors'
import moveIcon from '../../assets/move/move.png'

const HandleWrapper = styled.div`
  position: absolute;
  top: ${props => (props.isColumnHandle ? props.offset : 10)}px;
  right: ${props => (props.isColumnHandle ? 80 : 16)}px;
  width: 20px;
  height: 20px;
  padding: 1px;
  border-radius: 4px;
  z-index: 3;
  color: ${PRIMARY_DARK};
`

const HandleIcon = styled.img.attrs({
  src: moveIcon,
  alt: 'move',
})`
  width: ${props => (props.isColumnHandle ? 22 : 18)}px;
  height: ${props => (props.isColumnHandle ? 22 : 18)}px;
`

const Handle = ({ isColumnHandle, ...rest }) => (
  <I18n>{({ t }) => (
    <HandleWrapper isColumnHandle={isColumnHandle} {...rest}>
      <HandleIcon
        data-tip
        data-for="dragHandle"
        isColumnHandle={isColumnHandle}
      />
      <Tooltip bottom id="dragHandle">
        <span>{t({ key: 'TOOLTIP_HOLD_AND_DRAG' })}</span>
      </Tooltip>
    </HandleWrapper>
  )}
  </I18n>
)

export default Handle
