import React from 'react'
import { Container, Option } from './styles'

export type ToggleSwitchProps = {
  checked: boolean
  falseLabel: string
  trueLabel: string
  onClick: () => void
}
export const ToggleSwitch = ({
  checked,
  falseLabel,
  trueLabel,
  onClick,
}: ToggleSwitchProps) => {
  return (
    <Container onClick={onClick}>
      <Option selected={!checked}>{falseLabel}</Option>
      <Option selected={checked}>{trueLabel}</Option>
    </Container>
  )
}
