import { createCache, createResource } from 'simple-cache-provider'

function createCacheWithAsyncInvalidation(invalidator) {
  const cache = createCache(invalidator)

  cache.invalidateAt = function (ms) {
    cache.__invalidationTimeout = setTimeout(() => cache.invalidate(), ms)
  }

  cache.clearInvalidationTimeout = function () {
    clearTimeout(cache.__invalidationTimeout)
  }

  return cache
}

export { createCache, createResource, createCacheWithAsyncInvalidation }
