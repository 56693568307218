import styled from 'styled-components'
import {Icon, Button} from 'semantic-ui-react'
import {
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
  PRIMARY_DARK,
  MESSAGE_ANALYSIS_LEVEL,
  DARK_GREY,
  MEDIUM_GREY, IMPORTANT_RED,
} from '../../../static/colors'

const THREAT_COLORS = [CHART_SAFE, CHART_CAUTION, CHART_DANGER]

export const Container = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 8px 16px 8px;
  position: relative;
`

export const ActionBar = styled.div`
  color: ${PRIMARY_DARK};
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: baseline;
  width: 100%;
  margin-top: 10px;
  margin-left: 12px;
`

export const ActionBarLeftGroup = styled.div`
  display: flex;
  align-items: baseline;
  flex: 1;
`

export const Content = styled.div`
  flex: 1;
  padding: 0px 20px 20px 20px;
`

const ActionBarIcon = styled(Icon)`
  padding-right: 8px;
`

export const TagList = styled.div`
  padding-right: 8px;
  display: flex;
  padding-left: 0px;
  margin-left: 0px;
  flex-direction: row;
  font-size: 14px;
`

export const FlagIcon = styled(ActionBarIcon).attrs(props => ({
  name: props.isFlagged ? 'flag' : 'flag outline',
}))`
  cursor: pointer;
  font-size: 14px !important;
  color: ${props => props.isFlagged ? PRIMARY_DARK : MEDIUM_GREY}
`

export const WarningIcon = styled(ActionBarIcon).attrs(props => ({
  name: props.isActive ? 'warning sign' : 'warning sign',
}))`
  cursor: pointer;
  font-size: 14px !important;
  color: ${props => props.isActive ? IMPORTANT_RED : MEDIUM_GREY}
`

export const ClearIcon = styled(ActionBarIcon).attrs({
  name: 'check circle',
})`
  cursor: pointer;
  font-size: 14px !important;
`

export const AnalysisContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 35px;
  border-radius: 4px;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.08);
  color: ${MESSAGE_ANALYSIS_LEVEL};
  font-family: Lato;
  background-color: ${props => THREAT_COLORS[props.threat] + '33'};
  margin: 12px 12px 4px 12px;
`

export const AnalysisColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
`

export const AnalysisLevelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;
`

export const LevelDot = styled.div`
  flex: 1;
  min-width: 10px;
  max-width: 10px;
  min-height: 35px;
  background-color: ${props => THREAT_COLORS[props.threat]};;
`

export const AnalysisLevel = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
`

export const SenderDetails = styled.div`
  font-size: 12px;
  margin-left: 6px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  word-break: break-all;
`

export const AnalysisReasons = styled.div`
  font-size: 12px;
  margin-left: 6px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
`

export const DetailsLink = styled.a`
  margin-top: -2px;
  margin-bottom: 2px;
  margin-left: 4px;
  color: ${MESSAGE_ANALYSIS_LEVEL};
  text-decoration: underline;

  &:hover {
    color: ${MESSAGE_ANALYSIS_LEVEL};
  }

  &:focus {
    outline: none;
  }
`

export const ConsoleLink = styled.a`
  margin-right: 16px;
  font-size: 14px;
  color: ${PRIMARY_DARK};

  &:hover {
    color: ${PRIMARY_DARK};
  }

  &:focus {
    outline: none;
  }
`

export const ReportEmailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 4px;
  margin-top:0;
`

export const ReportEmailLabel = styled.div`
  font-size: 12px;
  color: ${DARK_GREY};
  display: flex;
`

export const ReportEmailLink = styled.a`
  margin-right: 4px;
  margin-left: 12px;
  font-size: 14px;
  color: ${PRIMARY_DARK};

  &:hover {
    color: ${PRIMARY_DARK};
  }

  &:focus {
    outline: none;
  }
`

export const SuppressedLink = styled.a`
  text-align: right;
  margin-right: 12px;
  margin-top: -8px;
  margin-bottom: -8px;
  font-size: 11px;
  color: ${PRIMARY_DARK};

  &:hover {
    color: ${PRIMARY_DARK};
  }

  &:focus {
    outline: none;
  }
`

export const ErrorMessage = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  color: ${PRIMARY_DARK};
`

export const ActionBarSeparator = styled.div`
  padding-right: 6px;
`

