import React, { useContext } from 'react'
import mergeRight from 'ramda/src/mergeRight'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import modalStyles, {
  SmallContainer,
  ButtonsWrapper,
  Button,
  CloseButton,
  CloseIcon,
} from '../modalStyles'
import {
  ContentWrapper,
  Target,
  Title,
  Link,
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'


const ConfirmationModal = () => {
  const context = useContext(DashboardsContext)
  const {
    targetURL,
    isConfirmationModalOpen,
    toggleConfirmationModal,
  } = context

  const showCloseButton = false

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isConfirmationModalOpen}
        contentLabel={t({ key: 'MODAL_CONFIRMATION' })}
        style={{
          overlay: modalStyles.overlay,
          content: mergeRight(modalStyles.content, { padding: 20, paddingTop: 15 }),
        }}
      >
        <SmallContainer>
          {showCloseButton && (
            <CloseButton onClick={() => toggleConfirmationModal('')}>
              <CloseIcon />
            </CloseButton>
          )}
          <ContentWrapper>
            <Title>
              {t({ key: 'MESSAGE_VIEW_IMAGE_URL_CONFIRMATION' })}
            </Title>
            <Target>{targetURL}</Target>
            <ButtonsWrapper>
              <Button
                dark
                type="button"
                onClick={() => toggleConfirmationModal('')}
              >
                <Link href={targetURL} target="_blank" rel="noopener noreferrer">
                  {t({ key: 'OK' })}
                </Link>
              </Button>
              <Button type="button" onClick={() => toggleConfirmationModal('')}>
                {t({ key: 'CANCEL' })}
              </Button>
            </ButtonsWrapper>
          </ContentWrapper>
        </SmallContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default ConfirmationModal
