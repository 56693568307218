import styled from 'styled-components'
import { spacing, colors, Body1PrimaryDarkRegular } from 'static/theme'

const submenuItemStyles = `
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background-color 0.5s;
  padding: ${spacing.xs} 0rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SubmenuItem = styled.div`
  position: relative;
  ${submenuItemStyles}
  padding-left: 30px;
  &:hover {
    cursor: pointer;
    color: ${colors.white};
  }
`
export const Text = styled(Body1PrimaryDarkRegular)<{
  selected?: Boolean
}>`
  ${(props) => (props.selected ? `color: ${colors.white}` : '')};
`
