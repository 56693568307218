
// XHR requests
import axios from "axios";
import {EVENT_AUTH_API_KEY, KOTLIN_URL} from "../static/appConfig";
import uuid from "react-uuid";
import {getEnvironmentName} from "../lib/util";

// XHR requests
const registerAuthenticationEventXhr = (body) => axios.post(
  `${KOTLIN_URL}/events/viz_dash/frontend_auth`,
  body,
  {
    params: {},
    headers: {
      'X-API-Key': EVENT_AUTH_API_KEY,
      'Content-Type': 'application/json',
      'X-Team': 'inky',
    },
  },
)

const registerEventXhr = (body, auth, event_name) => axios.post(
  `${KOTLIN_URL}/events/viz_dash/${event_name}`,
  body,
    {
      params: { },
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json',
        'X-Team': auth.realm,
      },
    },
)

const createEventBody = (eventData, email, teamId) => {
  let session_uid = sessionStorage.getItem('auth_session_uid')
  let local_uid = localStorage.getItem('auth_local_uid')
  if (!session_uid) {
    session_uid = uuid()
    sessionStorage.setItem('auth_session_uid', session_uid)
  }
  if (!local_uid) {
    local_uid = uuid()
    localStorage.setItem('auth_local_uid', local_uid)
  }

  eventData['session_uid'] = session_uid
  eventData['local_uid'] = local_uid
  if (email)
    eventData['email'] = email
  if (teamId)
    eventData['teamid'] = teamId
  return {
    environment: getEnvironmentName(window.location.hostname),
    event_data: eventData
  }
}


// Actions
const registerAuthEventAction = async (email, teamId, eventData) => {
  try {
    registerAuthenticationEventXhr(createEventBody(eventData, email, teamId))
  } catch (err) {
    console.log(err)
  }
}

const registerEventAction = async (eventData, auth) => {
  try {
    registerEventXhr(createEventBody(eventData), auth, 'user-experience')
  } catch (err) {
    console.log(err)
  }
}

const actions = {
  registerEventAction,
  registerAuthEventAction,
}

export default actions
