import axios from 'axios'
import { fromKotlinDashboard } from 'requests/dashboard'
import { KOTLIN_URL } from '../../static/appConfig'
import {
  DashboardsPayload,
  DashboardsResponse,
  PinnedDashboardsPayload,
  PinnedDashboardsResponse,
} from './types'

// XHR //
const getDashboardsXhr = (payload: DashboardsPayload) => {
  return axios.get<DashboardsResponse>(`${KOTLIN_URL}/dashboards`, {
    params: {
      limit: payload.limit,
      offset: payload.offset,
      owner: payload.owner,
      sort: payload.sort,
      sort_order: payload.sortOrder,
      ids: payload.ids?.join(','),
    },
    headers: {
      Authorization: `Bearer ${payload.token}`,
      'X-Team': payload.realm,
    },
  })
}

const getPinnedDashboardsXhr = (payload: PinnedDashboardsPayload) => {
  return axios.get<PinnedDashboardsResponse>(
    `${KOTLIN_URL}/user-settings/pinned-dashboards`,
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        'X-Team': payload.realm,
      },
    }
  )
}

// Actions //
export const getMarketplaceAction = async (
  _key: string,
  payload: DashboardsPayload
) => {
  const res = await getDashboardsXhr(payload)
  return { ...res.data, data: res.data.data.map(fromKotlinDashboard) }
}

export const getQuickAccessAction = async (
  _key: string,
  payload: PinnedDashboardsPayload
) => {
  const res = await getPinnedDashboardsXhr(payload)
  return res.data
}
