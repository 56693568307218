import styled from 'styled-components'
import {
  DARK_TEXT,
  BORDER_LIGHT,
  INPUT_TEXT,
} from '../../../static/colors'


export const ContentWrapper = styled.div`
  flex: 1;
  width: 75%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Title = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  width: 420px;
  text-align: left;
  text-transform: capitalize;
  max-width: 500px;
  margin-bottom: 10px;
`

export const Input = styled.input`
  height: 55px;
  width: 420px;
  border: 3px solid ${BORDER_LIGHT};
  border-radius: 7px;
  margin: 10px 20px 20px 20px;
  font-family: Lato;
  color: ${INPUT_TEXT};
  font-size: 25px;
  line-height: 30px;
  text-align: center;
`
