import React from 'react'
import { I18n } from 'lib/i18n'
import { TopHit } from 'components/ReportWidgets/TopHit'

const MostTargetedRecipient = ({
  isAnimationActive,
}: {
  isAnimationActive: boolean
}) => {
  return (
    <I18n>
      {({ t }) => (
        <TopHit
          isAnimationActive={isAnimationActive}
          title={t({ key: 'MOST_TARGETED_RECIPIENT' })}
          fieldName='email'
        />
      )}
    </I18n>
  )
}

export { MostTargetedRecipient }
