import { colors } from 'static/theme'

export const CATEGORY_MAP = {
  email: 'general',
  from_display_name: 'general',
  from_email: 'general',
  from_domain: 'general',
  processed_date: 'general',
  source: 'general',
  teamid: 'general',
  mail_from: 'general',
  internal: 'general',
  organizational_unit: 'general',
  subject: 'headers',
  rcpt_to_addresses: 'headers',
  headers_from: 'headers',
  headers_to: 'headers',
  headers_cc: 'headers',
  headers_bcc: 'headers',
  headers_replyto: 'headers',
  messageid: 'headers',
  messageid_domain: 'headers',
  delivery_target: 'analysis',
  result_bucket: 'analysis',
  links: 'media',
  images: 'media',
  attachments: 'media',
  has_attachments: 'media',
  attachment_hash: 'media',
  reason_ids: 'analysis',
  threat_level: 'analysis',
  sensitive_content_categories: 'analysis',
  tags: 'metadata',
  reports: 'metadata',
  sender_ip: 'metadata',
  link_clicks: 'metadata',
  helo_string: 'metadata',
  connecting_ip: 'metadata',
  google_spam: 'metadata',
  google_phish: 'metadata',
  microsoft_scl: 'metadata',
  authentication_results: 'metadata',
  phishing_test_provider: 'metadata',
  report_label: 'metadata',
  banner_suppressed: 'metadata',
  reported_by: 'metadata',
  link_click_alert_lvl: 'metadata',
}

export const FILTER_CATEGORIES = {
  general: [
    'email',
    'from_display_name',
    'from_email',
    'from_domain',
    'processed_date',
    'source',
    'teamid',
    'mail_from',
    'internal',
    'organizational_unit',
  ],
  headers: [
    'subject',
    'headers_from',
    'headers_to',
    'headers_cc',
    'headers_bcc',
    'headers_replyto',
    'messageid_domain',
    'messageid',
    'rcpt_to_addresses',
  ],
  media: [
    'links',
    'images',
    'attachments',
    'attachments_filetype',
    'has_attachments',
    'attachment_hash',
  ],
  analysis: [
    'reason_ids',
    'threat_level',
    'sensitive_content_categories',
    'delivery_target',
    'result_bucket'
  ],
  metadata: [
    'tags',
    'reports',
    'report_label',
    'sender_ip',
    'link_clicks',
    'helo_string',
    'connecting_ip',
    'google_spam',
    'google_phish',
    'microsoft_scl',
    'authentication_results',
    'phishing_test_provider',
    'banner_suppressed',
    'reported_by',
    'link_click_alert_lvl',
  ],
}

export const FILTER_CATEGORY_COLORS = {
  general: colors.primaryDark,
  headers: colors.caution,
  media: colors.secondaryLight,
  analysis: colors.secondaryBlue,
  metadata: colors.safe,
}

export const MESSAGE_PROPERTIES = {
  google_phish: 'boolean',
  reports: 'nested',
  internal: 'boolean',
  banner_suppressed: 'boolean_exists',
  attachments: 'nested',
  rcpt_to_addresses: 'keyword',
  from_email: 'keyword',
  from_display_name: 'text',
  subject: 'text',
  messageid: 'keyword',
  headers_replyto: 'nested',
  source: 'keyword',
  google_spam: 'boolean',
  headers_cc: 'nested',
  teamid: 'keyword',
  helo_string: 'keyword',
  connecting_ip: 'ip',
  links: 'nested',
  has_attachments: 'nested',
  attachment_hash: 'nested_terms',
  reported_by: 'nested_matches',
  sender_ip: 'ip',
  authentication_results: 'text',
  delivery_target: 'text',
  result_bucket: 'keyword',
  email: 'keyword',
  messageid_domain: 'keyword',
  threat_level: 'byte',
  sensitive_content_categories: 'keyword',
  phishing_test_provider: 'keyword',
  report_label: 'nested_terms',
  images: 'nested',
  processed_date: 'date',
  microsoft_scl: 'integer',
  reason_ids: 'integer',
  mail_from: 'keyword',
  headers_bcc: 'nested',
  tags: 'keyword',
  link_clicks: 'nested',
  headers_to: 'nested',
  headers_from: 'nested',
  from_domain: 'keyword',
  organizational_unit: 'keyword',
  link_click_alert_lvl: 'nested_terms',
}

export const TIME_SENSITIVE_FILTERS = [
    "google_phish",
    "internal",
    "banner_suppressed",
    "source",
    "google_spam",
    "authentication_results",
    "delivery_target",
    "result_bucket",
    "threat_level",
    "sensitive_content_categories",
    "phishing_test_provider",
    "report_label",
    "microsoft_scl",
    "reason_ids",
    "organizational_unit",
    "attachment_hash",
]
