import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isNil from 'ramda/src/isNil'
import propEq from 'ramda/src/propEq'
import eqProps from 'ramda/src/eqProps'
import findIndex from 'ramda/src/findIndex'
import { filter as rFilter } from 'ramda/src'
import equals from 'ramda/src/equals'
import symmetricDifference from 'ramda/src/symmetricDifference'
import messageActions from '../../../requests/message'
import { I18n } from '../../../lib/i18n'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'

const { object, func, array, string } = PropTypes

class ReasonIdInput extends Component {
  state = {
    suggestions: [],
    selectedReasons: [],
  }

  componentDidMount() {
    const { filter } = this.props
    if (filter && !isNil(filter.term)) this.handleTermChange(filter.term)
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props
    if (eqProps('name', prevProps.filter, filter)) return
    this.handleTermChange(filter.term)
  }

  handleTermChange = async term => {
    const { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token } = this.props
    const isKey = keys => e => findIndex(equals(e.reasonId), keys) > -1
    const data = await messageActions.aggregateByReasonIdAction({
      dashboardFilters: rFilter(f => !propEq('name', 'reason_ids')(f), dashboardFilters),
      setFilters: rFilter(f => !propEq('name', 'reason_ids')(f), setFilters),
      dashboardFilterMode,
      setFilterMode,
    }, { realm, token })
    const newReasons = data.data
    this.setState({
      suggestions: newReasons,
      selectedReasons: rFilter(isKey(term), newReasons),
    })
  }

  handleRowClick = reasonId => {
    const { onChange } = this.props
    const { selectedReasons } = this.state
    const newReasons = symmetricDifference(selectedReasons, [reasonId])
    this.setState({ selectedReasons: newReasons })
    onChange(newReasons.map(r => r.reasonId))
  }

  render() {
    const { suggestions, selectedReasons } = this.state

    return (
      <I18n>{({ t }) => (
        <Container>
          <SelectionHeader>
            <SelectionHeaderItem>
              {suggestions.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                </StatisticsTextBold>
              ) : suggestions.length === 1 ? (
                <StatisticsTextBold>
                  {suggestions.length}
                  <StatisticsText>({t({ key: 'SELECTION_MATCH' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {suggestions.length}
                  <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
            <SelectionHeaderItem right>
              {selectedReasons.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {selectedReasons.length}
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
          </SelectionHeader>
          <SelectionWrapper>
            <SelectionColumnWrapper>
              <Scrollbar>
                {suggestions.map(agg => {
                  const isSelected = findIndex(equals(agg), selectedReasons) > -1
                  return (
                    <SuggestionWrapper>
                      <Suggestion
                        key={agg.reasonId}
                        isSelected={isSelected}
                        onClick={isSelected ? null
                          : () => this.handleRowClick(agg)
                        }
                      >
                        <SuggestionText>
                          {t({ key: 'REASONS',
                            variables: { id: agg.reasonId },
                          })}
                        </SuggestionText>
                        {t({
                          key: 'AGGREGATION_HITS_PARENTHESIS',
                          variables: { aggregationHits: agg.value },
                        })}
                      </Suggestion>
                    </SuggestionWrapper>
                  )
                })}
              </Scrollbar>
            </SelectionColumnWrapper>
            <Seperator>
              <SeperatorIcon />
            </Seperator>
            <SelectionColumnWrapper right>
              <Scrollbar>
                {selectedReasons.map(selectedReason => (
                  <SuggestionWrapper>
                    <Suggestion
                      closeable
                      key={selectedReason.reasonId}
                      isSelected={false}
                      onClick={() => this.handleRowClick(selectedReason)}
                    >
                      <SuggestionText>
                        {t({ key: 'REASONS',
                          variables: { id: selectedReason.reasonId },
                        }) || t({ key: 'SELECTION_NO_VALUE' })}
                      </SuggestionText>
                      {
                        t({
                          key: 'AGGREGATION_HITS_PARENTHESIS',
                          variables: { aggregationHits: selectedReason.value },
                        })
                    }
                      <CloseIcon>×</CloseIcon>
                    </Suggestion>
                  </SuggestionWrapper>
                ))}
              </Scrollbar>
            </SelectionColumnWrapper>
          </SelectionWrapper>
        </Container>
      )}
      </I18n>
    )
  }
}

ReasonIdInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default ReasonIdInput
