import styled from 'styled-components'
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'
import {
  DARK_TEXT,
  BACKGROUND_LIGHT,
  PRIMARY_DARK,
  WHITE,
  BORDER_LIGHT,
  TABLE_BORDER,
  MEDIUM_GREY,
  MODAL_OVERLAY,
} from '../../static/colors'
import closeIcon from '../../assets/close/close.png'
import { colors } from 'static/theme'

export const Container = styled.div`
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 575px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
`

export const Button = styled.button<{ dark?: boolean; small?: boolean }>`
  height: ${(props) => (props.small ? 32 : 37)}px;
  min-width: 169px;
  ${(props) => (props.small ? 'max-width: 169px;' : '')}
  border: 2px solid ${(props) => (props.dark ? PRIMARY_DARK : BORDER_LIGHT)};
  border-radius: 7px;
  background-color: ${(props) => (props.dark ? PRIMARY_DARK : WHITE)};
  color: ${(props) => (props.dark ? WHITE : DARK_TEXT)};
  font-family: Lato;
  font-size: ${(props) => (props.small ? 14 : 18)}px;
  line-height: 22px;
  margin: ${(props) => (props.small ? 2 : 9)}px;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  outline: none;

  &:hover {
    ${(props) => (props.disabled ? '' : 'cursor: pointer;')}
  }
`

export const SmallButton = styled.button<{ dark: boolean }>`
  height: 25px;
  width: 94px;
  border: 2px solid ${(props) => (props.dark ? PRIMARY_DARK : BORDER_LIGHT)};
  border-radius: 5px;
  background-color: ${(props) => (props.dark ? PRIMARY_DARK : WHITE)};
  color: ${(props) => (props.dark ? WHITE : DARK_TEXT)};
  font-family: Lato;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 10px;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  outline: none;

  &:hover {
    ${(props) => (props.disabled ? '' : 'cursor: pointer;')}
  }
`

export const ModalTitle = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 16px;
`

export const Label = styled.div`
  color: ${MEDIUM_GREY};
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  margin-bottom: 8px;
`

export const ResultTypeLabel = styled(Label)`
  display: inline;
  margin-right: 8px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid ${TABLE_BORDER};
  overflow-y: auto;
`

const rowStyle = `
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid ${TABLE_BORDER};
`

export const HeaderRow = styled.div`
  ${rowStyle}
  min-height: 48px;
  font-weight: 700;
`

export const LabelRow = styled.div`
  ${rowStyle}
  min-height: 25px;
  font-weight: 700;
`
export const PermissionRow = styled.div`
  ${rowStyle}
  min-height: 20px;
`

export const Title = styled.div`
  flex: 4;
  padding-left: 8px;
  text-transform: capitalize;
`

export const Cell = styled.div`
  min-height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: 1;
  border-left: 1px solid ${TABLE_BORDER};
`

export const Checkbox = styled(SemanticCheckbox)`
  & label::before {
    top: 3px !important;
    width: 12px !important;
    height: 12px !important;
  }

  & label::after {
    font-size: 9px !important;
    top: 1px !important;
    width: 12px !important;
    height: 12px !important;
  }
`

export const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 100%;
  padding: 8px;
`

export const CloseButton = styled.div`
  align-self: flex-end;
  padding: 9px;

  &:hover {
    cursor: pointer;
  }
`

export const CloseButtonTop = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

export const CloseIcon = styled.img.attrs({
  src: closeIcon,
  alt: 'close',
})`
  width: 15px;
`

export default {
  overlay: {
    zIndex: 99,
    backgroundColor: colors.primaryMain50Opacity,
  },
  tagOverlay: {
    zIndex: 100,
    backgroundColor: colors.primaryMain50Opacity,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: colors.primaryMain,
    backgroundColor: colors.white,
    border: 'none',
    borderRadius: 4,
    boxShadow: `0 0px 30px 0px ${colors.primaryMain}`,
    padding: 0,
  },
}
