import React, {useContext, useEffect, useRef, useState} from 'react'
import { Divider, Icon, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import tail from 'ramda/src/tail'
import propOr from 'ramda/src/propOr'
import { I18n } from '../../lib/i18n'
import { getVisualizationIcon } from '../../lib/util'
import {
  Container,
  Header,
  Bar,
  Title,
  StatisticsWrapper,
  Statistics,
  StatisticsIcon,
  Selector,
  IconWrapper,
  ContentWrapper,
  ThreatBullet,
  MessageFilterCheckbox,
  MessageFilterTitle,
  BarContent,
  VisualizationWrapper,
} from './styles'
import Message from '../App/Message'
import { FilterBar } from '../FilterBar'
import MessageList from '../MessageList'
import MessageCounter from '../MessageCounter'
import TeamTreeMap from '../Visualizations/TeamTreeMap'
import DateAreaChart from '../Visualizations/DateAreaChart'
import DateLineGraph from '../Visualizations/DateLineGraph'
import ThreatLevelPie from '../Visualizations/ThreatLevelPie'
import TeamTreeMapZoom from '../Visualizations/TeamTreeMapZoom'
import ReasonIdsBarChart from '../Visualizations/ReasonIdsBarChart'
import EmailStackedBarChart from '../Visualizations/EmailStackedBarChart'
import {AuthContext} from '../../@logicea/react-auth'
import { VisualizationMenu } from 'components/DropdownMenus/Visualization'
import { WidgetActions } from 'components/WidgetActions'
import { spacing } from 'static/theme'
import {CSVLink} from 'react-csv'

const getVisualizationComponent = option => {
  switch (option) {
    case 'count':
      return MessageCounter
    case 'pie':
      return ThreatLevelPie
    case 'bar':
      return ReasonIdsBarChart
    case 'line':
      return DateLineGraph
    case 'area':
      return DateAreaChart
    case 'list':
      return MessageList
    case 'stack':
      return EmailStackedBarChart
    case 'stack_sender':
      return EmailStackedBarChart
    case 'stack_sender_domain':
      return EmailStackedBarChart
    case 'tree':
      return TeamTreeMap
    case 'zoom':
      return TeamTreeMapZoom
    default:
      return MessageCounter
  }
}

const { object, bool, func, string, array, number } = PropTypes

const MessageSet = ({
  set,
  isTab,
  isReport,
  dashboard,
  dashboardId,
  dashboardFilters,
  dashboardFilterMode,
  belongsToUser,
  configureMode,
  taggedMessage,
  timestamp,
  expanded,
  provided,
  onResize,
  onCellClick,
  onMessageTag,
  onContentClick,
  deleteMessageSet,
  handleFilterClick,
  handleFilterRemoval,
  toggleFilterNegation,
  onVisualizationChange,
  handleFilterModeChange,
  toggleConfirmationModal,
  toggleMessageSetActionModal,
  toggleTagModal,
}) => {
  const auth = useContext(AuthContext)
  const downloadLinkRef = useRef()

  const [data, setData] = useState([])
  const [messageCount, setMessageCount] = useState(0)
  const [downloadAll, setDownloadAll] = useState(false)
  const [shouldExport, setShouldExport] = useState(false)
  const [filterAllActions, setFilterAllActions] = useState(false)
  const [filterNoActions, setFilterNoActions] = useState(false)
  const [filterAllowListedActions, setFilterAllowListedActions] = useState(false)
  const [filterBlockListedActions, setFilterBlockListedActions] = useState(false)
  const [filterPolicyAppliedActions, setFilterPolicyAppliedActions] = useState(false)
  const [filterViewedActions, setFilterViewedActions] = useState(false)
  const [filterRemediatedActions, setFilterRemediatedActions] = useState(false)
  const [filterExistingAllowEntry, setFilterExistingAllowEntry] = useState(false)
  const [filterExistingBlockEntry, setFilterExistingBlockEntry] = useState(false)
  const [filterExistingPolicyEntry, setFilterExistingPolicyEntry] = useState(false)

  const handleDownloadClick = () => {
    setData([])
    setDownloadAll(true)
  }

  const toggleExportClick = () => {
    setShouldExport(!shouldExport)
  }

  const handleDataFetch = fetchedData => {
    fetchedData.forEach(o => {
      if (o?.subject?.match(/^[\-+@=]/))
      o['subject'] = '\'' + o['subject']
    })
    setData(fetchedData)
    setDownloadAll(false)
  }

  useEffect(() => {
    if (data?.length) {
      setTimeout(() => {
        downloadLinkRef.current.link.click()
      }, 1000)
    }
  }, [data])

  const setId = propOr(null, 'id', set)
  const setTitle = propOr(null, 'content', set)
  const message = propOr(null, 'messageId', set)
  const filtersProp = propOr(null, 'filters', set)
  const filterMode = propOr('AND', 'filterMode', set)
  const visualization = propOr(null, 'visualization', set)
  const VisualizationComponent = getVisualizationComponent(visualization)
  const stackAggType = visualization === 'stack' ? 'recipient' : visualization === 'stack_sender' ? 'sender' : 'domain'
  const parsedContent = message ? tail(setTitle) : setTitle
  const allowDownload = messageCount > 0

  const handleActionFilterClick = (filter) => {
    switch (filter) {
      case 'all-actions':
        const newState = !filterAllActions
        setFilterAllActions(newState)
        setFilterAllowListedActions(newState)
        setFilterBlockListedActions(newState)
        setFilterViewedActions(newState)
        setFilterRemediatedActions(newState)
        setFilterNoActions(newState)
        setFilterPolicyAppliedActions(newState)
        break;
      case 'viewed-actions':
        setFilterAllActions(false)
        setFilterViewedActions(!filterViewedActions)
        break;
      case 'take-no-actions':
        setFilterAllActions(false)
        setFilterNoActions(!filterNoActions)
        break;
      case 'allow-listed-actions':
        setFilterAllActions(false)
        setFilterAllowListedActions(!filterAllowListedActions)
        break;
      case 'policy-applied-actions':
        setFilterAllActions(false)
        setFilterPolicyAppliedActions(!filterPolicyAppliedActions)
        break;
      case 'block-listed-actions':
        setFilterAllActions(false)
        setFilterBlockListedActions(!filterBlockListedActions)
        break;
      case 'remediated-actions':
        setFilterAllActions(false)
        setFilterRemediatedActions(!filterRemediatedActions)
        break;
      case 'existing-allow-entry':
        setFilterExistingAllowEntry(!filterExistingAllowEntry)
        break;
      case 'existing-block-entry':
        setFilterExistingBlockEntry(!filterExistingBlockEntry)
        break;
      case 'existing-policy-entry':
        setFilterExistingPolicyEntry(!filterExistingPolicyEntry)
        break;
      default:
        console.log("Got unexpected action filter click type " + filter)
        break;
    }
  }

  const hasRename = !message && belongsToUser && !isReport
  const hasClone = configureMode && belongsToUser && !expanded && !message
  const hasExport = visualization !== 'list' && visualization !== 'tree' && visualization !== 'zoom'
  const hasDownload = visualization !== 'tree' && visualization !== 'zoom' && allowDownload && !downloadAll
  const hasDelete = configureMode && belongsToUser && !isReport && !expanded && !isTab

  return (
    <I18n>{({ t }) => (
      <Container expanded={expanded}>
        <Header>
          {!isTab && (
          <Bar {...provided.dragHandleProps}>
            <BarContent>
              <IconWrapper>
                {message ? <ThreatBullet threat={setTitle[0]} /> : getVisualizationIcon(visualization)}
              </IconWrapper>
              <Title>
                {parsedContent || t({ key: 'VISUALISATION_OPTION', variables: { name: visualization } })}
              </Title>
            </BarContent>
            <BarContent>
              <VisualizationMenu
                visualization={visualization}
                setId={setId}
                onVisualizationChange={onVisualizationChange}/>
              <WidgetActions
                widget={set}
                hasRename={hasRename}
                hasClone={hasClone}
                toggleMessageSetActionModal={toggleMessageSetActionModal}
                hasExport={hasExport}
                handleExportClick={toggleExportClick}
                hasDownload={hasDownload}
                hasDelete={hasDelete}
                handleDownloadClick={handleDownloadClick}
                deleteMessageSet={deleteMessageSet}
                style={{marginLeft: spacing.xs}} />
            </BarContent>
          </Bar>
          )}
          {visualization !== 'message' && (
            <Bar>
              <FilterBar
                setId={setId}
                isReport={isReport}
                filters={filtersProp}
                filterMode={filterMode}
                belongsToUser={belongsToUser}
                handleFilterClick={handleFilterClick}
                handleFilterRemoval={handleFilterRemoval}
                toggleFilterNegation={toggleFilterNegation}
                handleFilterModeChange={handleFilterModeChange}
              />
              <Selector>
                {visualization === 'list' && (
                  <Popup on="hover" position="right center" trigger={<Icon name="filter" />} hoverable>
                    <MessageFilterTitle>Hide messages with</MessageFilterTitle>
                    <MessageFilterCheckbox label="All Actions" checked={filterAllActions} onClick={() => {handleActionFilterClick('all-actions')}}/>
                    <MessageFilterCheckbox label="Viewed" checked={filterViewedActions} onClick={() => {handleActionFilterClick('viewed-actions')}}/>
                    <MessageFilterCheckbox label="Take No Action" checked={filterNoActions} onClick={() => {handleActionFilterClick('take-no-actions')}}/>
                    <MessageFilterCheckbox label="Allow Listed" checked={filterAllowListedActions} onClick={() => {handleActionFilterClick('allow-listed-actions')}}/>
                    <MessageFilterCheckbox label="Block Listed" checked={filterBlockListedActions} onClick={() => {handleActionFilterClick('block-listed-actions')}}/>
                    <MessageFilterCheckbox label="Policy Applied" checked={filterPolicyAppliedActions} onClick={() => {handleActionFilterClick('policy-applied-actions')}}/>
                    <MessageFilterCheckbox label="Remediated" checked={filterRemediatedActions} onClick={() => {handleActionFilterClick('remediated-actions')}}/>
                    <Divider />
                    <MessageFilterTitle>Hide messages on</MessageFilterTitle>
                    <MessageFilterCheckbox label="Allow List" checked={filterExistingAllowEntry} onClick={() => {handleActionFilterClick('existing-allow-entry')}}/>
                    <MessageFilterCheckbox label="Block List" checked={filterExistingBlockEntry} onClick={() => {handleActionFilterClick('existing-block-entry')}}/>
                    <MessageFilterCheckbox label="Policy List" checked={filterExistingPolicyEntry} onClick={() => {handleActionFilterClick('existing-policy-entry')}}/>
                  </Popup>
                )}
              </Selector>
            </Bar>
          )}
        </Header>
        <ContentWrapper hideOverflow={!!message}>
          {message ? (
            <Message
              messageId={message.id}
              onMessageTag={onMessageTag}
              messageIndex={message.index}
              taggedMessage={taggedMessage}
              toggleTagModal={toggleTagModal}
              toggleConfirmationModal={toggleConfirmationModal}
            />
          ) : (
            <VisualizationWrapper>
              <VisualizationComponent
                dashboard={dashboard}
                downloadAll={downloadAll}
                dashboardId={dashboardId}
                dashboardFilterMode={dashboardFilterMode}
                dashboardFilters={dashboardFilters}
                setFilterMode={filterMode}
                setFilters={filtersProp}
                timestamp={timestamp}
                onMessageClick={onContentClick}
                onCellClick={onCellClick}
                onFetch={handleDataFetch}
                belongsToUser={belongsToUser}
                expanded={expanded}
                isTab={isTab}
                large
                aggregationType={stackAggType}
                filterViewedActions={filterViewedActions}
                filterNoActions={filterNoActions}
                filterRemediatedActions={filterRemediatedActions}
                filterAllowListedActions={filterAllowListedActions}
                filterBlockListedActions={filterBlockListedActions}
                filterPolicyAppliedActions={filterPolicyAppliedActions}
                filterExistingAllowEntry={filterExistingAllowEntry}
                filterExistingBlockEntry={filterExistingBlockEntry}
                filterExistingPolicyEntry={filterExistingPolicyEntry}
                afterExport={toggleExportClick}
                shouldExport={shouldExport}
              />
            </VisualizationWrapper>
          )}
        </ContentWrapper>
        <StatisticsWrapper>
          <Statistics>
            <StatisticsIcon />
            <MessageCounter
              onFetch={setMessageCount}
              dashboardId={dashboardId}
              dashboardFilterMode={dashboardFilterMode}
              dashboardFilters={dashboardFilters}
              setFilterMode={filterMode}
              setFilters={filtersProp}
              timestamp={timestamp}
            />
          </Statistics>
        </StatisticsWrapper>
        <CSVLink ref={downloadLinkRef} data={data} filename={"inky-data-" + visualization + ".csv"} style={{display:"none"}} />
      </Container>
    )}
    </I18n>
  )
}

MessageSet.propTypes = {
  set: object,
  isTab: bool,
  isReport: bool,
  dashboard: object,
  dashboardId: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  taggedMessage: object,
  timestamp: number,
  belongsToUser: bool,
  configureMode: bool,
  expanded: bool,
  provided: object,
  onResize: func,
  onCellClick: func,
  onMessageTag: func,
  onContentClick: func,
  deleteMessageSet: func,
  handleFilterClick: func,
  handleFilterRemoval: func,
  toggleFilterNegation: func,
  onVisualizationChange: func,
  toggleMessageSetActionModal: func,
  toggleConfirmationModal: func,
  toggleTagModal: func,
}

export default MessageSet
