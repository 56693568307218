import React, {useContext, useState} from 'react'
import mergeRight from 'ramda/src/mergeRight'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import modalStyles, {
  SmallContainer,
  CloseButton,
  CloseIcon,
} from '../modalStyles'
import {
  ContentWrapper,
  Title,
  Button,
  InteriorContainer,
  ButtonsWrapper, NormalText, ActionText, Description
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/actions'
import {AuthContext} from "../../../@logicea/react-auth";
import {Dimmer, Icon, Message} from "semantic-ui-react";
import {isGTMEnabled, parseElasticsearchID, saveAction} from "../../../lib/util";
import TagManager from "react-gtm-module";
import {GTM_KEY} from "../../../static/appConfig";

const ListActionModal = () => {

  const auth = useContext(AuthContext)
  const context = useContext(DashboardsContext)
  const {
    isListActionModalOpen,
    setListActionModalVisibility,
    listActionModalType,
    listActionData,
    addMsgActionedData,
    userTeams,
  } = context

  const showCloseButton = false
  const [working, setWorking] = useState(false)
  const [showActionStatus, setShowActionStatus] = useState(false)
  const [responseError, setResponseError] = useState()
  const [timeoutId, setTimeoutId] = useState(-1)

  let teamid = ''
  let user_email = null
  let msg_id
  if (listActionData?.messages?.length) {
    msg_id = listActionData.messages[0]
    teamid = parseElasticsearchID(msg_id)[0]
  } else if (listActionData?.action?.teamid) {
    teamid = listActionData.action.teamid
    user_email = listActionData.action?.user_email === '*' ? null : listActionData.action?.user_email
  }

  let isStatusUpdate = listActionModalType === 'allow-status' || listActionModalType === 'block-status'

  let statusChange = 'unknown'
  if (isStatusUpdate) {
    statusChange = listActionData.action.new_state ? 'enable' : 'disable'
    title_key = 'LIST_STATUS_CONFIRM_CHANGE'
    success_key = 'LIST_STATUS_SUCCESS'
  }

  const sendGTMEvent = (eventName) => {
    if (isGTMEnabled()) {
      TagManager.initialize({
        gtmId: GTM_KEY,
        dataLayer: {
          event: eventName,
          email: userTeams?.data?.authEmail,
          teamid: userTeams?.data?.memberOf,
          license_key: userTeams?.data?.licenseKey,
          team_type: userTeams?.data?.teamType
        }
      })
    }
  }

  const submitAction = async () => {
    setWorking(true)
    setShowActionStatus(false)
    setResponseError(null)
    let res
    if (listActionModalType === 'allowlist') {
      res = await actions.postAllowListEntryAction(listActionData, 'add', auth.tokens.accessToken)
      sendGTMEvent('allow_list_entry_added')
      saveAction("allowlist-add", msg_id, teamid, auth, listActionData.action.text)
      addMsgActionedData([{action: 'allowlist-add', messageId: msg_id, details: listActionData.action.text}])
    } else if (listActionModalType === 'blocklist') {
      res = await actions.postBlockListEntryAction(listActionData, 'add', auth.tokens.accessToken)
      sendGTMEvent('block_list_entry_added')
      saveAction("blocklist-add", msg_id, teamid, auth, listActionData.action.text)
      addMsgActionedData([{action: 'blocklist-add', messageId: msg_id, details: listActionData.action.text}])
    } else if (listActionModalType === 'policy') {
      let new_settings = {[listActionData.action?.data?.key]: listActionData.action?.data?.value, last_modified_utc_seconds: listActionData.action?.data?.last_modified_utc_seconds}
      res = await actions.postPolicyAction({policy_settings: new_settings, messages: listActionData.messages}, auth.tokens.accessToken)
      sendGTMEvent('trusted_third_party_settings_modified')
      saveAction("policy-add", msg_id, teamid, auth, listActionData.action.text)
      addMsgActionedData([{action: 'policy-add', messageId: msg_id, details: listActionData.action.text}])
    } else if (listActionModalType === 'allow-status') {
      res = await actions.postAllowListEntryAction(listActionData.action, 'change-state', auth.tokens.accessToken)
      sendGTMEvent('allow_list_entry_' + (listActionData.action.new_state ? 'enabled' : 'disabled'))
    } else if (listActionModalType === 'block-status') {
      res = await actions.postBlockListEntryAction(listActionData.action, 'change-state', auth.tokens.accessToken)
      sendGTMEvent('block_list_entry_' + (listActionData.action.new_state ? 'enabled' : 'disabled'))
    }
    setWorking(false)
    setShowActionStatus(true)
    setResponseError(res?.error)

    if (!res?.error) {
      setTimeoutId(setTimeout(() => {
        handleDismiss()
      }, 5000))
    }
  }

  const handleDismiss = () => {
    clearTimeout(timeoutId)
    setShowActionStatus(false)
    if (!responseError) {
      setListActionModalVisibility(false)
      if (listActionData.successCallback)
        listActionData.successCallback(listActionData)
    }
  }

  let warningKey = null
  if (listActionData?.action?.hasOwnProperty('show-warning')) {
    if (listActionData.action['show-warning']) {
      if (listActionModalType === 'allowlist')
        warningKey = 'LIST_ACTION_ALLOWLIST_WARNING'
      else if (listActionModalType === 'policy')
        warningKey = 'LIST_ACTION_POLICY_WARNING'
    }
  }

  let title_key = 'MODAL_CONFIRM_ACTION'
  let success_key = 'LIST_ACTION_SUCCESS'

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isListActionModalOpen}
        contentLabel={t({ key: title_key })}
        style={{
          overlay: modalStyles.overlay,
          content: mergeRight(modalStyles.content, { padding: 20, paddingTop: 15 }),
        }}
      >
        <Dimmer active={working}>
          <Message icon>
            <Icon name='circle notched' loading />
            <Message.Content>
              <Message.Header>{t({key: 'MODAL_PLEASE_WAIT'})}</Message.Header>
              {t({key: 'MODAL_PROCESSING_REQUEST'})}
            </Message.Content>
          </Message>
        </Dimmer>
        <Dimmer active={showActionStatus}>
          <Message positive={!responseError} onDismiss={handleDismiss} negative={responseError}>
            <Message.Header>{t({key: responseError ? 'ERROR' : 'SUCCESS'})}</Message.Header>
            <p>
              {responseError ? responseError : t({key: success_key})}
            </p>
          </Message>
        </Dimmer>
        <SmallContainer>
          {showCloseButton && (
            <CloseButton onClick={() => setListActionModalVisibility(false)}>
              <CloseIcon />
            </CloseButton>
          )}
          <ContentWrapper>
            <Title>
              {t({ key: 'MODAL_CONFIRM_ACTION' })}
            </Title>
            <InteriorContainer>
              {isStatusUpdate && <NormalText>{t({key: user_email ? 'LIST_STATUS_ARE_YOU_SURE_USER' : 'LIST_STATUS_ARE_YOU_SURE',
                variables: {listType: listActionModalType === "allow-status" ? 'allowlist' : 'blocklist', statusChange: statusChange, team: teamid, user: user_email}})}</NormalText>}
              {!isStatusUpdate && <NormalText>{t({key: 'LIST_ACTION_ARE_YOU_SURE', variables: {listType: listActionModalType, team: teamid}})}</NormalText>}
              <ActionText><div dangerouslySetInnerHTML={{ __html: listActionData?.action?.text }} /></ActionText>
              {warningKey && (<Description>{t({key: warningKey })}</Description>)}
            </InteriorContainer>
            <ButtonsWrapper>
              <Button
                dark
                type="button"
                onClick={() => submitAction()}
              >
                {t({ key: 'YES' })}
              </Button>
              <Button type="button" onClick={() => setListActionModalVisibility(false)}>
                {t({ key: 'CANCEL' })}
              </Button>
            </ButtonsWrapper>
          </ContentWrapper>
        </SmallContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default ListActionModal
