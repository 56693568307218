import styled from 'styled-components'
import {
  colors,
  spacing,
  HeadlineH3PrimaryMainBold,
  HeadlineH1PrimaryMainBold,
  HeadlineH3DarkGreyRegular,
  Body1PrimaryMainRegular,
  Body1PrimaryMainBold,
} from 'static/theme'
import {
  FILTER_CATEGORY_COLORS,
} from '../../../static/filterNames'
import {ReactComponent as FilterSVG} from 'assets/filters/filter.svg'
import {ReactComponent as MessageCounterSVG} from 'assets/filters/messageCounter.svg'
import {ReactComponent as GeneralSVG} from 'assets/filters/general.svg'
import {ReactComponent as HeadersSVG} from 'assets/filters/headers.svg'
import {ReactComponent as MediaSVG} from 'assets/filters/media.svg'
import {ReactComponent as AnalysisSVG} from 'assets/filters/analysis.svg'
import {ReactComponent as MetadataSVG} from 'assets/filters/metadata.svg'

export const Container = styled.div`
  width: 80vw;
  height: 80vh;
  min-height: 670px;
  max-height: 811px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spacing.xl};
`

export const FilterEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
`

export const FilterEditorHeader = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const HeaderTitle = styled(HeadlineH1PrimaryMainBold)``

export const HeaderPath = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`

export const HeaderCategory = styled(HeadlineH3DarkGreyRegular)``

export const HeaderSeparator = styled(HeadlineH3DarkGreyRegular)`
margin-right: 5px;
`

export const HeaderFilter = styled(HeadlineH3DarkGreyRegular)``

export const FilterEditorContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
`

export const CategorySelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export const FilterSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FilterSelectScroll = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`

export const CategorySelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 96px;
  width: 96px;
  border-radius: 4px;
  margin: ${spacing.xs} 0rem;
  background-color:  ${props => (props.isSelected ? colors.primaryLighter:colors.white)};
  border: 1px solid ${props => (props.isSelected ? colors.primaryMain : colors.primaryLighter)};
`

export const CategorySelectIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${props => FILTER_CATEGORY_COLORS[props.category] + '88'};

`

export const FilterIcon = styled(FilterSVG)`
  fill: ${colors.primaryMain};
  margin-right: ${spacing.xxs};
`

export const GeneralIcon = styled(GeneralSVG)`
  fill: ${colors.primaryMain};
`

export const HeadersIcon = styled(HeadersSVG)`
  fill: ${colors.primaryMain};
`

export const MediaIcon = styled(MediaSVG)`
  fill: ${colors.primaryMain};
`

export const AnalysisIcon = styled(AnalysisSVG)`
  fill: ${colors.primaryMain};
`

export const MetadataIcon = styled(MetadataSVG)`
  fill: ${colors.primaryMain};
`

export const CategorySelectText = styled(HeadlineH3PrimaryMainBold)`
  margin-top: ${spacing.xxs};
`

export const FilterSelect = styled(HeadlineH3DarkGreyRegular)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 4px 4px 8px;
  margin: 8px 16px 8px 16px;
  color: ${props => (
    props.isSelected ? colors.primaryMain : colors.darkGrey
  )};
  font-weight: ${props => (
    props.isSelected ? 'bold' : 'normal'
  )};
  &:hover {
    cursor: ${props => (props.isSelected ? 'auto' : 'pointer')};
  }
`

export const EditorArea = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
`

export const TopArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.background};
  padding: 20px;
  overflow: auto;
`

export const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  flex-direction: row;
`

export const StatisticsRow = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: space-between;
`

export const Statistics = styled(Body1PrimaryMainRegular)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StatisticsIcon = styled(MessageCounterSVG)`
  fill: ${colors.primaryDark};
`

export const StatisticsText = styled(Body1PrimaryMainRegular)`
  margin-left: 4px;
`

export const StatisticsTextBold = styled(Body1PrimaryMainBold)`
  margin-left: 4px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${spacing.m};
`
