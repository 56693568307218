import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import {
  BACKGROUND_LIGHT,
  BACKGROUND_DARK,
  UNSELECTED_BUTTON,
  DARK_TEXT,
  MEDIUM_TEXT,
  DARK_GREY,
  WHITE,
  CLOSE_BUTTON_HOVER,
  MESSAGE_SET_BAR,
  MORE_ICON,
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
} from '../../../static/colors'
import cloneIcon from '../../../assets/clone/clone.png'
import { spacing } from 'static/theme'

const TAB_HEIGHT = 50

const THREAT_COLORS = [CHART_SAFE, CHART_CAUTION, CHART_DANGER]

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  overflow-x: auto;
`

export const TabBar = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${props => (props.isIndented ? 66 : 10)}px;
  margin-right: 10px;
`

export const Tab = styled.div`
  z-index: 1;
  position: relative;
  bottom: -2px;
  background-color: ${props => (props.active ? BACKGROUND_LIGHT : MESSAGE_SET_BAR)};
  height: ${TAB_HEIGHT}px;
  flex: 1;
  min-width: 40px;
  max-width: 240px;
  border: 2px solid ${props => (props.active ? BACKGROUND_DARK : UNSELECTED_BUTTON)};
  ${props => (props.active ? 'border-bottom: none;' : '')}
  ${props => (props.active ? '' : 'cursor: pointer;')}
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
`

export const TabText = styled.div`
  color: ${DARK_GREY};
  font-family: Lato;
  font-size: 17px;
  line-height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: ${spacing.xxs};
`

export const TitleAndFilters = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-align: center;
  align-items: center;
`

export const FilterText = styled.div`
  color: ${props => (props.active ? DARK_GREY : MEDIUM_TEXT)};
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const FilterThumbnail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
`

export const FilterLine = styled.div`
  width: 2px;
  height: 13px;
  background-color: ${props => props.color};
  margin-right: 3px;
`

export const CloseButtonOld = styled.div`
  z-index: 2;
  position: absolute;
  top: 2px;
  right: 2px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  color: ${DARK_GREY};
  text-align: center;
  background-color: ${props => (props.active ? WHITE : UNSELECTED_BUTTON)};

  &:hover {
    cursor: pointer;
    background-color: ${props => (props.active ? UNSELECTED_BUTTON : WHITE)};
  }
`

export const Tools = styled.div`
    position: absolute;
    right: 2px;
    top: 2px;
    display: flex;
    flex-direction: row;
`

export const MoreButton = styled.div`
  margin-top: -2px;

  &:hover {
    cursor: pointer;
  }
`

export const MoreIcon = styled(Icon).attrs({
  name: 'ellipsis horizontal',
})`
  position: absolute;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: ${props => (props.active === 'true' ? BACKGROUND_LIGHT : MESSAGE_SET_BAR)};
  color: ${MORE_ICON};
  min-height: 16px;

  ${MoreButton}:hover & {
    opacity: 0;
    pointer-events: none;
  }
`

export const CloneButton = styled.div`
  height: 18px;
  width: 16px;
`
export const CloneIcon = styled.img.attrs({
  src: cloneIcon,
  alt: 'clone',
})`
  height: 10px;
  width: 9px;
  margin-left: 6px;
`

export const CloseButton = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 18px;
  text-align: center;
  font-size: 18px;
  line-height: 17px;
  background-color: transparent;
  transition: background-color 0.3s;
  color: ${DARK_TEXT};

  &:hover {
    cursor: pointer;
    background-color: ${CLOSE_BUTTON_HOVER};
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  background-color: ${BACKGROUND_LIGHT};
  overflow: hidden;
  margin: 0px 15px;
  border: 2px solid ${BACKGROUND_DARK};
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0 22px -1px rgba(0,0,0,0.06);
`

export const IconWrapper = styled.div`
  display: flex;
`

export const ThreatBullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${props => THREAT_COLORS[props.threat]};
  margin-right:${spacing.xs};
`
