import { Locale, Translations, assertLocale } from '../i18n'

export type State =
  | {
      readonly locale: Locale
      readonly translations: undefined
      readonly loading: false
      readonly error: undefined
    }
  | {
      readonly locale: Locale
      readonly translations: Translations | undefined
      readonly loading: true
      readonly error: Error | undefined
    }
  | {
      readonly locale: Locale
      readonly translations: Translations
      readonly loading: false
      readonly error: undefined
    }
  | {
      readonly locale: Locale
      readonly translations: undefined
      readonly loading: false
      readonly error: Error
    }

export type UpdateState = (nextState: State) => void

export const initialState: State = {
  locale: assertLocale('en-US'),
  translations: undefined,
  loading: false,
  error: undefined,
}
