import React, { useRef } from 'react'
import { InputAdornment, InputProps } from '@material-ui/core'
import { MaterialUIInput, SearchIcon, ClearIcon } from './style'

type CustomInputProps = InputProps & {
  onClear?: () => void
  name: string
}

export const SearchInput = ({
  color = 'primary',
  value,
  onClear,
  ...rest
}: CustomInputProps) => {
  const inputRef = useRef<any>()
  return (
    <MaterialUIInput
      color={color}
      value={value}
      inputRef={inputRef}
      endAdornment={
        <InputAdornment position='end'>
          {!value ? (
            <SearchIcon />
          ) : (
            <ClearIcon
              onClick={() => {
                inputRef.current.value = ''
                onClear && onClear()
              }}
            />
          )}
        </InputAdornment>
      }
      style={{ width: '100%' }}
      {...rest}
    />
  )
}
