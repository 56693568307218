import axios from 'axios'
import { KOTLIN_URL } from '../static/appConfig'
import { toKotlinFilter } from '../lib/transformations'

// XHR requests
const getTeamsXhr = query => axios.get(
  `${KOTLIN_URL}/users/teams`,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${query.token}`,
      'X-Team': query.realm,
    },
  },
)

const getTeamsWithHitsXhr = (filters, query) => axios.post(
  `${KOTLIN_URL}/messages/count/team`,
  {
    dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
    setFilters: filters.setFilters.map(toKotlinFilter),
    dashboardFilterMode: filters.dashboardFilterMode || 'AND',
    setFilterMode: filters.setFilterMode || 'AND',
  },
  {
    params: { size: 1000 },
    headers: {
      Authorization: `Bearer ${query.token}`,
      'Content-Type': 'application/json',
      'X-Team': query.realm,
    },
  },
)


// Actions
const getTeamsAction = async query => {
  try {
    const res = await getTeamsXhr(query)
    return {
      total: res.data.total,
      data: res.data,
    }
  } catch (err) {
    console.log(err)
    return null
  }
}

const getTeamsWithHitsAction = async (filters, query) => {
  try {
    const res = await getTeamsWithHitsXhr(filters, query)
    return {
      data: res.data.data,
    }
  } catch (err) {
    console.log(err)
    return null
  }
}

const actions = {
  getTeamsAction,
  getTeamsWithHitsAction,
}

export default actions
