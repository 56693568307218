import React, { Component } from 'react'
import '@atlaskit/css-reset'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import isEmpty from 'ramda/src/isEmpty'
import equals from 'ramda/src/equals'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import initialMessageSets from '../../static/initialMessageSets'
import PureColumn from './PureColumn'
import {
  Container,
  NavigationBar,
  NavigationButton,
  BackIcon,
  ForwardIcon,
} from './styles'

class DragDropArea extends Component {
  state = null

  componentDidMount() {
    const { messageSetLayout } = this.props
    if (isEmpty(messageSetLayout)) this.setState({ ...initialMessageSets })
    else this.setState({ ...messageSetLayout })
  }

  componentDidUpdate(prevProps) {
    const { messageSetLayout } = this.props
    if (equals(messageSetLayout, prevProps.messageSetLayout)) return
    this.refreshMessageSets()
    const columnOrder = pathOr([], ['messageSetLayout', 'columnOrder'], this.props)
    const prevColumnOrder = pathOr([], ['messageSetLayout', 'columnOrder'], prevProps)
    if (columnOrder && columnOrder.length > prevColumnOrder.length && prevColumnOrder.length > 0) {
      setTimeout(() => {
        if (this.listEnd) this.listEnd.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }

  refreshMessageSets = () => {
    const { messageSetLayout, onLayoutUpdate } = this.props
    if (isEmpty(messageSetLayout)) {
      this.setState({ ...initialMessageSets }, () => onLayoutUpdate(initialMessageSets))
    } else if (!isNil(messageSetLayout)) {
      this.setState({ ...messageSetLayout })
    }
  }

  onDragStart = () => {
    // document.body.style.color = 'orange'
  }

  onDragUpdate = update => {
    /*
    const { destination } = update
    const { sets } = this.state
    const opacity = destination
      ? destination.index / Object.keys(sets).length
      : 0
    document.body.style.backgroundColor = `rgba(200, 200, 200, ${opacity})` // eslint-disable-line no-undef
    document.body.style.transition = 'background-color 0.2s ease' // eslint-disable-line no-undef
    */
  }

  onDragEnd = result => {
    const { onLayoutUpdate } = this.props

    document.body.style.backgroundColor = 'inherit' // eslint-disable-line no-undef

    const { destination, source, draggableId, type } = result
    const { columnOrder, columns } = this.state

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    if (type === 'column') {
      const newColumnOrder = Array.from(columnOrder)
      newColumnOrder.splice(source.index, 1)
      newColumnOrder.splice(destination.index, 0, draggableId)

      const newState = {
        ...this.state,
        columnOrder: newColumnOrder,
      }

      this.setState(newState, () => onLayoutUpdate(newState))
      return
    }

    const start = columns[source.droppableId]
    const finish = columns[destination.droppableId]

    if (start === finish) {
      const newSetIds = Array.from(start.setIds)
      newSetIds.splice(source.index, 1)
      newSetIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        setIds: newSetIds,
      }

      const newState = {
        ...this.state,
        columns: {
          ...columns,
          [newColumn.id]: newColumn,
        },
      }

      this.setState(newState, () => onLayoutUpdate(newState))
      return
    }

    const startSetIds = Array.from(start.setIds)
    startSetIds.splice(source.index, 1)
    const newStart = {
      ...start,
      setIds: startSetIds,
    }

    const finishSetIds = Array.from(finish.setIds)
    finishSetIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      setIds: finishSetIds,
    }

    const newState = {
      ...this.state,
      columns: {
        ...columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }

    this.setState(newState, () => onLayoutUpdate(newState))
  }

  render() {
    if (isNil(this.state)) return null
    const { columnOrder = [], columns = [], sets = [] } = this.state
    const { layout, direction, dashboardId, selectedLayoutLevel, handleLayoutLevelChange } = this.props
    const numOfColumns = columnOrder.length
    const showForwardButton = layout === 'box' && selectedLayoutLevel < numOfColumns - 1
    const showBackButton = layout === 'box' && selectedLayoutLevel > 0
    const showSingleLevel = layout !== 'vertical' && layout !== 'horizontal'

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragUpdate={this.onDragUpdate} onDragEnd={this.onDragEnd}>
        <Droppable droppableId="all-columns" direction={direction} type="column" isDropDisabled>
          {provided => (
            <Container {...provided.droppableProps} ref={provided.innerRef} direction={direction}>
              {(showBackButton || showForwardButton) && (
                <NavigationBar>
                  {showBackButton ? (
                    <NavigationButton onClick={() => handleLayoutLevelChange(selectedLayoutLevel - 1)}>
                      <BackIcon />
                    </NavigationButton>
                  ) : <div />}
                  {showForwardButton && (
                    <NavigationButton forward onClick={() => handleLayoutLevelChange(selectedLayoutLevel + 1)}>
                      <ForwardIcon />
                    </NavigationButton>
                  )}
                </NavigationBar>
              )}
              {columnOrder.map((columnId, index) => {
                const column = columns[columnId]

                return showSingleLevel && index !== selectedLayoutLevel ? null : (
                  <PureColumn
                    key={dashboardId + '-' + column.id}
                    column={column}
                    setMap={sets}
                    index={index}
                    {...this.props}
                  />
                )
              })}
              <div ref={el => { this.listEnd = el }} />
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

export default DragDropArea
