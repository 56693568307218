import React from 'react'
import {
  Locale as GenericLocale,
  GetDynamicTranslation as GenericGetTranslation,
  I18nContext as GenericI18nContext,
  I18nProps as GenericI18nProps,
  I18nProvider as GenericI18nProvider,
  I18n as GenericI18n,
} from '@logicea/react-i18n'

// import { Translations } from './translations.en-US'
import { translations } from './translations.en-US'

export {
  isLocale,
  assertLocale,
  isLanguage,
  assertLanguage,
} from '@logicea/react-i18n'

export type LocaleValue = 'en-US' //| 'el-GR'
export type Locale = GenericLocale & LocaleValue

// export type Translations = typeof import('./translations.en-US').translations
// export { Translations }
export type Translations = typeof translations

export interface I18nContext extends GenericI18nContext<Locale, Translations> {}

export type GetTranslation = GenericGetTranslation<Translations>

export const loadTranslations = async (
  locale: LocaleValue
): Promise<Translations> => {
  switch (locale) {
    case 'en-US': {
      return (await import('./translations.en-US')).translations
    }
    // case 'el-GR': {
    //   return (await import('./translations.el-GR')).translations
    // }
    default: {
      const value: never = locale
      throw new Error(`Unreachable case: ${value}`)
    }
  }
}

declare class I18nProviderComponent extends GenericI18nProvider<
  Locale,
  Translations
> {}
export const I18nProvider: typeof I18nProviderComponent = GenericI18nProvider

export interface I18nProps extends GenericI18nProps<Locale, Translations> {}

type I18nComponent = (props: I18nProps) => JSX.Element
export const I18n: I18nComponent = (props) => {
  const { children } = props

  return (
    <GenericI18n<Locale, Translations>>{(i18n) => children(i18n)}</GenericI18n>
  )

  /* return (
    <GenericI18n<Locale, Translations>>
      {(i18n) => {
        const t: typeof i18n.t = (options) => {
          const { key } = options
          const value = i18n.t(options)

          console.log(`I18n :: t() :: ${key} -> ${value}`)

          return value
        }

        return children({ ...i18n, t })
      }}
    </GenericI18n>
  ) */
}
