import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import find from 'ramda/src/find'
import isNil from 'ramda/src/isNil'
import assoc from 'ramda/src/assoc'
import equals from 'ramda/src/equals'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'
import isEmpty from 'ramda/src/isEmpty'
import { filter as rFilter } from 'ramda/src'
import mergeRight from 'ramda/src/mergeRight'
import symmetricDifference from 'ramda/src/symmetricDifference'
import { Radio } from 'components/Radio'
import { SearchInput } from 'components/SearchInput'
import { AutocompleteInput } from 'components/AutocompleteInput'
import messageActions from '../../../requests/message'
import { I18n } from '../../../lib/i18n'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  AllResultsText,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import debounce from "lodash.debounce";
import {INPUT_TEXT_DEBOUNCE_TIME} from "../../../static/appConfig";

const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 0px;
`

const RadioWrapper = styled.div`
  margin-right: 16px;
`

const { object, func, array, string } = PropTypes

class LinksInput extends Component {
  state = {
    mode: 'domain',
    value: { url: '', domain: '' },
    suggestions: { url: [], domain: [] },
    selectedKeywords: { url: [], domain: [] },
  }

  constructor(props) {
    super(props);
    this.getSuggestionsDebounced = debounce(async (otherDashboardFilters, otherSetFilters, dashboardFilterMode,
                                                   setFilterMode, realm, token, filterName, newTargetMode,
                                                   newTargetValue) => {
      const newSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filterName,
          innerField: newTargetMode === 'url' ? 'prefix' : 'domain',
          term: newTargetValue,
        }
      )
      this.setState({ suggestions: { [newTargetMode]: newSuggestions.data } })
    }, INPUT_TEXT_DEBOUNCE_TIME)
  }

  componentDidMount() {
    const { filter } = this.props
    const { term } = filter
    this.handleTermChange({ target: {
      value: term ? (term.url || term.domain || '') : '',
      mode: term.url ? 'url' : 'domain',
    } })
  }

  componentDidUpdate(prevProps, prevState) {
    const { mode } = this.state
    const { filter } = this.props
    if (equals(prevProps.filter, filter) && mode === prevState.mode) return
    const { term } = filter
    this.handleTermChange({ target: {
      value: term ? (term.url || term.domain || '') : '',
      mode: mode === prevState.mode
        ? isNil(term.url) ? 'domain' : 'url'
        : mode,
    } })
  }

  handleTermChange = async event => {
    const {
      realm,
      token,
      filter,
      onChange,
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
    } = this.props
    const { mode, value, selectedKeywords } = this.state
    const newTargetValue = event.target.value
    const newTargetMode = event.target.mode || mode

    if (equals(newTargetValue, value[newTargetMode]) && !equals(newTargetValue, '')) return
    const otherDashboardFilters = rFilter(f => !propEq('name', filter.name)(f), dashboardFilters)
    const otherSetFilters = rFilter(f => !propEq('name', filter.name)(f), setFilters)

    if (typeof newTargetValue === 'string') {
      this.setState({
        value: mergeRight(value, { [newTargetMode]: newTargetValue }),
        mode: newTargetMode,
      })
      if (selectedKeywords[newTargetMode].length === 0) onChange({ [newTargetMode]: newTargetValue })
      this.getSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode, setFilterMode, realm,
        token, filter.name, newTargetMode, newTargetValue)
    } else {
      if (selectedKeywords[newTargetMode].length === 0) {
        onChange(value[newTargetMode] ? { [newTargetMode]: value[newTargetMode] } : null)
      }
      this.setState({
        selectedKeywords: mergeRight(selectedKeywords, { [newTargetMode]: newTargetValue }),
        mode: newTargetMode,
      })
      const newSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filter.name,
          innerField: newTargetMode === 'url' ? 'prefix' : 'domain',
          term: value[newTargetMode],
        }
      )
      this.setState({ suggestions: { [newTargetMode]: newSuggestions.data } })
    }
  }

  handleRowClick = keyword => {
    const { onChange } = this.props
    const { mode, selectedKeywords } = this.state
    const newKeywords = symmetricDifference(selectedKeywords[mode], [keyword])
    this.setState({ selectedKeywords: assoc(mode, newKeywords, selectedKeywords) })
    onChange({ [mode]: newKeywords })
  }

  handleRadioChange = ({ value }) => {
    this.setState({ mode: value })
    this.handleTermChange({ target: {
      value: '',
      mode: value,
    } })
  }

  render() {
    const { mode, value, suggestions, selectedKeywords } = this.state
    const suggestionArray = propOr([], mode, suggestions)

    return (
      <I18n>{({ t }) => (
        <Container>
          <div>
            <RadioGroup>
              <RadioWrapper>
                <Radio
                  label={t({ key: 'FILTER_MEDIA_LINKS_URL' })}
                  checked={mode === 'url'}
                  onChange={()=>this.handleRadioChange({value:'url'})}
                />
              </RadioWrapper>
              <RadioWrapper>
                <Radio
                  label={t({ key: 'FILTER_MEDIA_LINKS_DOMAIN' })}
                  checked={mode === 'domain'}
                  onChange={()=>this.handleRadioChange({value:'domain'})}
                />
              </RadioWrapper>
            </RadioGroup>
            {mode === 'url'
              ? <AutocompleteInput
                  autoFocus
                  color='primary'
                  key={mode}
                  freeSolo={true}
                  placeholder={t({ key: 'FILTER_MEDIA_LINKS_URL' })}
                  suggestions={suggestions?.url ?? []}
                  onChange={this.handleTermChange}
                />
              : <SearchInput
                  key={mode}
                  autoFocus
                  value={value[mode]}
                  placeholder={t({ key: 'FILTER_MEDIA_LINKS_DOMAIN' })}
                  name={mode}
                  onChange={this.handleTermChange}
                />
            }
          </div>
          {mode === 'domain' && (
            <React.Fragment>
              <SelectionHeader>
                <SelectionHeaderItem>
                  {suggestionArray.length > 100 ? (
                    <StatisticsTextBold>
                      100+
                      <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                    </StatisticsTextBold>
                  ) : (
                    <StatisticsTextBold>
                      {suggestionArray.length}
                      <StatisticsText>({suggestionArray.length === 1
                        ? t({ key: 'SELECTION_MATCH' })
                        : t({ key: 'SELECTION_MATCHES' })
                      })</StatisticsText>
                    </StatisticsTextBold>
                  )}
                </SelectionHeaderItem>
                <SelectionHeaderItem right>
                  {selectedKeywords?.domain?.length > 100 ? (
                    <StatisticsTextBold>
                      100+
                      <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                    </StatisticsTextBold>
                  ) : (
                    <StatisticsTextBold>
                      {selectedKeywords?.domain?.length}
                      <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                    </StatisticsTextBold>
                  )}
                </SelectionHeaderItem>
              </SelectionHeader>
              <SelectionWrapper>
                <SelectionColumnWrapper>
                  <Scrollbar>
                    {suggestionArray.map(suggestion => {
                      const isSelected = find(equals(suggestion), selectedKeywords[mode])
                      return (
                        <SuggestionWrapper>
                          <Suggestion
                            key={suggestion}
                            isSelected={isSelected}
                            onClick={isSelected ? null
                              : () => this.handleRowClick(suggestion)
                            }
                          >
                            <SuggestionText>
                              {suggestion || t({ key: 'SELECTION_NO_VALUE' })}
                            </SuggestionText>
                          </Suggestion>
                        </SuggestionWrapper>
                      )
                    })}
                </Scrollbar>
                </SelectionColumnWrapper>
                <Seperator>
                  <SeperatorIcon />
                </Seperator>
                <SelectionColumnWrapper right>
                  <Scrollbar>
                    {selectedKeywords[mode].length > 0
                      ? selectedKeywords[mode].map(keyword => (
                        <SuggestionWrapper>
                          <Suggestion
                            closeable
                            key={keyword}
                            isSelected={false}
                            onClick={() => this.handleRowClick(keyword)}
                          >
                            <SuggestionText>
                              {keyword || t({ key: 'SELECTION_NO_VALUE' })}
                            </SuggestionText>
                            <CloseIcon>×</CloseIcon>
                          </Suggestion>
                        </SuggestionWrapper>
                      )) : !isEmpty(value[mode]) && (
                        <SuggestionWrapper>
                          <AllResultsText>
                            {t({ key: 'SELECTION_ALL_MATCHES_OF', variables: { value: value[mode] } })}
                          </AllResultsText>
                        </SuggestionWrapper>
                      )
                    }
                  </Scrollbar>
                </SelectionColumnWrapper>
              </SelectionWrapper>
            </React.Fragment>
          )}
        </Container>
      )}
      </I18n>
    )
  }
}

LinksInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default LinksInput
