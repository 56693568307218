import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '@logicea/react-auth'
import PropTypes from 'prop-types'
import { Divider, Loader } from 'semantic-ui-react'
import { DashboardsContext } from 'providers/Dashboards'
import {
  Checkbox,
  ChevronDown,
  ChevronUp,
  Container,
  EmptyList,
  FieldHeader,
  FlexList,
  FlexListContainer,
  HeaderRow,
  ListContainer,
  ListGradient,
  ReadOnly,
  Title,
} from './styles'
import ErrorBoundary from 'components/ErrorBoundary'
import Infinite from 'react-infinite'
import isNil from 'ramda/src/isNil'
import ReactResizeDetector from 'react-resize-detector'
import symmetricDifference from 'ramda/src/symmetricDifference'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import throttle from 'lodash.throttle'
import concat from 'ramda/src/concat'
import isEmpty from 'ramda/src/isEmpty'
import { I18n } from 'lib/i18n'
import { Field, Row } from 'components/MessageList/styles'
import findIndex from 'ramda/src/findIndex'
import { CenteredCreateButton } from 'components/Shared/CommonButtons'

const { object } = PropTypes
const LIST_ELEMENT_HEIGHT = 40
const CREATE_BUTTON_ROW_HEIGHT = 100
const EMPTY_LIST_HEIGHT = 100

const renderHits = (
  hits,
  onMessageClick,
  selectedMessages,
  handleCheckboxChange,
  readOnly
) => {
  if (hits && hits.length > 2) {
    hits[0].seen = true
    hits[1].seen = true
    hits[2].seen = true
    hits[0].no_action = true
    hits[1].blacklisted = true
  }
  return hits.map((hit) => (
    <SearchHit
      key={`${hit.id}-${hit.index}`}
      hit={hit}
      onMessageClick={onMessageClick}
      onCheckboxChange={handleCheckboxChange}
      selectedMessages={selectedMessages}
      readOnly={readOnly}
    />
  ))
}

const SearchHit = ({
  hit,
  onMessageClick,
  onCheckboxChange,
  selectedMessages,
  readOnly,
}) => (
  <I18n>
    {({ locale, t }) => (
      <Row>
        {!readOnly && (
          <Checkbox
            checked={findIndex(propEq('id', hit.id), selectedMessages) > -1}
            onChange={() => onCheckboxChange(hit.id)}
          />
        )}
        <Field onClick={() => onMessageClick(hit)}>{hit.last_name}</Field>
        <Field onClick={() => onMessageClick(hit)}>{hit.first_name}</Field>
        <Field onClick={() => onMessageClick(hit)}>{hit.email}</Field>
      </Row>
    )}
  </I18n>
)

const VipList = () => {
  const [selectedMessages, setSelectedMessages] = useState([])
  const [hitsAcc, setHitsAcc] = useState([])
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false)
  const [sort, setSort] = useState([{ processed_date: 'DESC' }])
  const [ascOrder, setAscOrder] = useState(false)
  const [selectedSort, setSelectedSort] = useState('processed_date')
  const [results, setResults] = useState({})
  const [noHits, setNoHits] = useState(false)
  const [isGradientVisible, setIsGradientVisible] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(1)
  const [readOnly, setReadOnly] = useState(true)

  const auth = useContext(AuthContext)
  const dashboard = useContext(DashboardsContext)
  const { settingsLoading, handleShowVipList } = dashboard

  useEffect(() => {
    handleShowVipList()
  }, [])

  useEffect(() => {
    let hits = []
    let page = 0
    const { total } = results

    async function fetchData() {
      while (hits.length < total) {
        // TODO: const response = await configActions.fetchVipList(page, realm, tokens.accessToken)
        const response = { hits: [] } // HACK for building
        page += 1
        hits = concat(hits, response.hits)
      }
    }

    //    if (downloadAll) fetchData()  // TODO: Put this in to support download of data
  }, [sort])

  const handleInfiniteLoad = throttle(
    async () => {
      setIsInfiniteLoading(true)
      // TODO: const response = await settingsActions.getVipList(realm, tokens.accessToken)
      const response = { hits: [] } // {hits: [{first_name:"Chris",last_name:"Byers",email:"chris.byers@inky.com"}]}  // HACK: For testing
      setActivePage(activePage + 1)
      setResults(response)
      const hits = concat(hitsAcc, response.hits)
      setHitsAcc(hits)
      setNoHits(isEmpty(hits))
      setIsInfiniteLoading(false)
    },
    1000,
    { leading: true, trailing: false }
  )

  const handleSelectAllChange = () => {
    if (selectedMessages.length === hitsAcc.length) setSelectedMessages([])
    else setSelectedMessages(hitsAcc)
  }

  const handleCheckboxChange = (messageId) =>
    setSelectedMessages(
      symmetricDifference(selectedMessages, [
        find(propEq('id', messageId), hitsAcc),
      ])
    )

  const onResize = (width, height) => {
    setListWidth(width)
    setListHeight(height - LIST_ELEMENT_HEIGHT - CREATE_BUTTON_ROW_HEIGHT)
  }

  const handleSortClick = (name) => {
    setActivePage(1)
    setSelectedMessages([])
    if (selectedSort === name) {
      setAscOrder(!ascOrder)
      setSort([{ [name]: ascOrder ? 'DESC' : 'ASC' }])
    } else {
      setAscOrder(true)
      setSelectedSort(name)
      setSort([{ [name]: 'ASC' }])
    }
  }

  const onMessageClick = (vipEntry) => {}

  const handleListScroll = (node) => {
    if (
      node.scrollTop + node.clientHeight >= node.scrollHeight &&
      isGradientVisible
    )
      setIsGradientVisible(false)
    else if (
      node.scrollTop + node.clientHeight < node.scrollHeight &&
      !isGradientVisible
    )
      setIsGradientVisible(true)
  }

  const showVipCreationDialog = () => {
    console.log('Show VIP Creation')
  }

  const { total } = results
  const totalPages = Math.ceil(total / 100)

  return settingsLoading ? (
    <Loader active />
  ) : (
    <I18n>
      {({ t }) => (
        <Container>
          <Title>VIP List for Team inky</Title>
          {readOnly && (
            <ReadOnly>
              This is a read-only view of your current VIP List. Please contact
              your support representative to request any changes.
            </ReadOnly>
          )}
          <Divider />
          <FlexList>
            <HeaderRow>
              {!readOnly && (
                <Checkbox
                  checked={
                    selectedMessages.length === hitsAcc.length &&
                    hitsAcc.length > 0
                  }
                  onChange={handleSelectAllChange}
                />
              )}
              <FieldHeader
                active={selectedSort === 'last_name'}
                onClick={() => handleSortClick('last_name')}
              >
                <div>{t({ key: 'VIP_LIST_LAST_NAME_HEADER' })}</div>
                {selectedSort === 'last_name' &&
                  (ascOrder ? <ChevronUp /> : <ChevronDown />)}
              </FieldHeader>
              <FieldHeader
                active={selectedSort === 'first_name'}
                onClick={() => handleSortClick('first_name')}
              >
                <div>{t({ key: 'VIP_LIST_FIRST_NAME_HEADER' })}</div>
                {selectedSort === 'first_name' &&
                  (ascOrder ? <ChevronUp /> : <ChevronDown />)}
              </FieldHeader>
              <FieldHeader
                active={selectedSort === 'email'}
                onClick={() => handleSortClick('email')}
              >
                <div>{t({ key: 'VIP_LIST_EMAIL_HEADER' })}</div>
                {selectedSort === 'email' &&
                  (ascOrder ? <ChevronUp /> : <ChevronDown />)}
              </FieldHeader>
            </HeaderRow>
            <FlexListContainer>
              <ListContainer
                width={listWidth}
                height={listHeight + LIST_ELEMENT_HEIGHT}
              >
                {listHeight > 0 && (
                  <ErrorBoundary
                    fallback={
                      <React.Fragment>
                        <Infinite
                          elementHeight={LIST_ELEMENT_HEIGHT}
                          containerHeight={
                            noHits ? EMPTY_LIST_HEIGHT : listHeight
                          }
                          handleScroll={handleListScroll}
                          infiniteLoadBeginEdgeOffset={
                            !isNil(total) && hitsAcc.length >= total
                              ? undefined
                              : LIST_ELEMENT_HEIGHT * 10
                          }
                          onInfiniteLoad={handleInfiniteLoad}
                          isInfiniteLoading={isInfiniteLoading}
                          loadingSpinnerDelegate={<Loader active />}
                        >
                          {noHits ? (
                            <EmptyList>
                              {t({ key: 'MESSAGE_LIST_EMPTY_MESSAGE' })}
                            </EmptyList>
                          ) : (
                            renderHits(
                              hitsAcc,
                              onMessageClick,
                              selectedMessages,
                              handleCheckboxChange,
                              readOnly
                            )
                          )}
                        </Infinite>
                        <CenteredCreateButton
                          onClick={showVipCreationDialog}
                          icon='plus'
                        />
                      </React.Fragment>
                    }
                  >
                    <Infinite
                      elementHeight={LIST_ELEMENT_HEIGHT}
                      containerHeight={noHits ? EMPTY_LIST_HEIGHT : listHeight}
                      handleScroll={handleListScroll}
                      infiniteLoadBeginEdgeOffset={
                        !isNil(total) && hitsAcc.length >= total
                          ? undefined
                          : LIST_ELEMENT_HEIGHT * 10
                      }
                      onInfiniteLoad={handleInfiniteLoad}
                      isInfiniteLoading={isInfiniteLoading}
                      loadingSpinnerDelegate={<Loader active />}
                    >
                      {noHits ? (
                        <EmptyList>
                          {t({ key: 'VIP_LIST_EMPTY_MESSAGE' })}
                        </EmptyList>
                      ) : (
                        renderHits(
                          hitsAcc,
                          onMessageClick,
                          selectedMessages,
                          handleCheckboxChange,
                          readOnly
                        )
                      )}
                    </Infinite>
                    <CenteredCreateButton
                      onClick={showVipCreationDialog}
                      icon='plus'
                    />
                  </ErrorBoundary>
                )}
              </ListContainer>
              <ListGradient visible={isGradientVisible} />
            </FlexListContainer>
            <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
          </FlexList>
        </Container>
      )}
    </I18n>
  )
}

VipList.propTypes = {
  auth: object,
}

export default VipList
