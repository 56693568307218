import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Range } from 'rc-slider'
import find from 'ramda/src/find'
import prop from 'ramda/src/prop'
import assoc from 'ramda/src/assoc'
import equals from 'ramda/src/equals'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'
import eqProps from 'ramda/src/eqProps'
import isEmpty from 'ramda/src/isEmpty'
import findIndex from 'ramda/src/findIndex'
import mergeRight from 'ramda/src/mergeRight'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import { AutocompleteInput } from 'components/AutocompleteInput'
import { TextField } from 'components/TextField'
import { I18n } from '../../../lib/i18n'
import messageActions from '../../../requests/message'
import { prettyFloat, prettyInt } from '../../../lib/util'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  AllResultsText,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import {
  InputGroup,
  RangeRow,
  RangeContainer,
  RangeTextRow,
  RangeText,
  markStyle,
  trackStyle,
  handleStyle,
  dotStyle,
} from './styles'
import debounce from "lodash.debounce";
import {INPUT_TEXT_DEBOUNCE_TIME} from "../../../static/appConfig";

const sliderCurve = Math.exp
const inverseCurve = Math.log

const RANGE_MAX = 1024 * 1024 * 100

const { object, func, array, string } = PropTypes

class AttachmentsInput extends Component {
  state = {
    filetype: '',
    filename: '',
    sizeFrom: '',
    sizeTo: '',
    suggestions: {
      filename: [],
      filetype: [],
    },
    range: [0, RANGE_MAX],
    selectedKeywords: [],
  }

  constructor(props) {
    super(props);
    this.getNestedSuggestionsDebounced = debounce(async (otherDashboardFilters, otherSetFilters,
                                                         dashboardFilterMode, setFilterMode, realm, token, filterName,
                                                         name, value, suggestions) => {
      const newSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filterName,
          innerField: name,
          term: value,
        }
      )
      this.setState({ suggestions: assoc(name, newSuggestions.data, suggestions) })

    }, INPUT_TEXT_DEBOUNCE_TIME)

    this.getFilenameSuggestionsDebounced = debounce(async (otherDashboardFilters, otherSetFilters,
                                                           dashboardFilterMode, setFilterMode, realm, token, value,
                                                           sizeFrom, sizeTo, filterName, suggestions) => {
      const filenameSuggestions = await messageActions.getFilteredNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          filter: {
            innerTermField: 'filetype',
            term: value,
            innerRangeField: 'size',
            rangeFrom: sizeFrom || null,
            rangeTo: sizeTo || null,
          },
          field: filterName,
          innerField: 'filename',
          term: '',
        }
      )
      this.setState({ suggestions: assoc('filename', filenameSuggestions.data, suggestions) })
    }, INPUT_TEXT_DEBOUNCE_TIME)

    this.getFilteredNestedSuggestionsDebounced = debounce(async (otherDashboardFilters, otherSetFilters,
                                                                 dashboardFilterMode, setFilterMode, realm, token,
                                                                 filetype, sizeFrom, sizeTo, filterName, name, value,
                                                                 suggestions) => {
      const newSuggestions = await messageActions.getFilteredNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          filter: {
            innerTermField: 'filetype',
            term: filetype ? filetype : null,
            innerRangeField: 'size',
            rangeFrom: sizeFrom || null,
            rangeTo: sizeTo || null,
          },
          field: filterName,
          innerField: name,
          term: value,
        }
      )
      this.setState({ suggestions: assoc(name, newSuggestions.data, suggestions) })
    }, INPUT_TEXT_DEBOUNCE_TIME)

    this.getNameAndTypeSuggestionsDebounced = debounce(async (otherDashboardFilters, otherSetFilters,
                                                              dashboardFilterMode, setFilterMode, realm, token,
                                                              filterName) => {
      const nameSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filterName,
          innerField: 'filename',
          term: '',
        }
      )
      const typeSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filterName,
          innerField: 'filetype',
          term: '',
        }
      )
      this.setState({ suggestions: {
          filename: nameSuggestions.data,
          filetype: typeSuggestions.data,
        } })

    }, INPUT_TEXT_DEBOUNCE_TIME)
  }

  componentDidMount() {
    const { filter } = this.props
    const { term } = filter
    this.handleInputChange({ target: {
      name: term ? ((term.filetype && 'filetype') || (term.filename && 'filename')) : 'filetype',
      value: term ? (term.filetype || term.filename) : '',
    } })
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props
    if (eqProps('name', prevProps.filter, filter)) return
    const { term } = filter
    this.handleInputChange({ target: {
      name: term ? ((term.filetype && 'filetype') || (term.filename && 'filename')) : 'filetype',
      value: term ? (term.filetype || term.filename) : '',
    } })
  }

  handleInputChange = async event => {
    const { suggestions, filetype, sizeFrom, sizeTo } = this.state
    const {
      realm,
      token,
      filter,
      onChange,
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
    } = this.props
    const { name, value } = event.target
    const oldValue = prop(name, this.state)

    if (equals(oldValue, value) && !equals(value, '')) return

    const otherDashboardFilters = rFilter(f => !propEq('name', filter.name)(f), dashboardFilters)
    const otherSetFilters = rFilter(f => !propEq('name', filter.name)(f), setFilters)

    if (typeof value === 'string') {
      this.setState({ [name]: value })
      onChange({ [name]: value })

      if (name === 'filetype') {
        this.getNestedSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode,
          setFilterMode, realm, token, filter.name, name, value, suggestions)

        if (findIndex(equals(value), suggestions.filetype) > -1) {
          this.getFilenameSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode,
            setFilterMode, realm, token, value, sizeFrom, sizeTo, filter.name, suggestions)
        }
      } else {
        this.getFilteredNestedSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode,
          setFilterMode, realm, token, filetype, sizeFrom, sizeTo, filter.name, name, value, suggestions)
      }
    } else {
      this.setState({
        selectedKeywords: value,
      })
      this.getNameAndTypeSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode,
        setFilterMode, realm, token, filter.name)
    }
  }

  handleRowClick = keyword => {
    const { onChange } = this.props
    const { selectedKeywords } = this.state
    const newKeywords = symmetricDifference(selectedKeywords, [keyword])
    this.setState({ selectedKeywords: newKeywords })
    onChange({ filename: newKeywords })
  }

  onSliderChange = range => {
    const { filter, onChange } = this.props
    this.setState({ range })
    onChange(mergeRight(filter.term, {
      sizeFrom: Math.round(sliderCurve(range[0])),
      sizeTo: Math.round(sliderCurve(range[1])),
    }))
  }

  render() {
    const {
      filetype,
      filename,
      range,
      suggestions,
      selectedKeywords,
    } = this.state
    const filenameArray = propOr([], 'filename', suggestions)

    return (
      <I18n>{({ t }) => (
        <Container>
          <div>
            <InputGroup>
              <AutocompleteInput
                autoFocus
                color='primary'
                key={filetype}
                label={t({ key: 'INPUT_FILE_TYPE' })}
                // placeholder={t({ key: 'INPUT_FILE_TYPE_PLACEHOLDER' })}
                // name="filetype"
                suggestions={suggestions.filetype}
                onChange={this.handleInputChange}
              />
              <TextField
                color='primary'
                key={filename}
                label={t({ key: 'INPUT_FILENAME' })}
                // placeholder={t({ key: 'INPUT_FILENAME_PLACEHOLDER' })}
                // name="filename"
                onChange={this.handleInputChange}
                onFocus={this.handleInputChange}
              />
            </InputGroup>
            <RangeRow>
              <RangeContainer>
                <RangeTextRow>
                  <RangeText>
                    {prettyFloat(Math.round(sliderCurve(range[0])))}
                  </RangeText>
                  <RangeText>
                    {prettyFloat(Math.round(sliderCurve(range[1])))}
                  </RangeText>
                </RangeTextRow>
                <Range
                  min={inverseCurve(1)}
                  max={inverseCurve(RANGE_MAX)}
                  marks={{
                    [inverseCurve(1)]: {style:markStyle,label:prettyInt(1)},
                    [inverseCurve(10)]: {style:markStyle,label:prettyInt(10)},
                    [inverseCurve(100)]: {style:markStyle,label:prettyInt(100)},
                    [inverseCurve(1024)]: {style:markStyle,label:prettyInt(1024)},
                    [inverseCurve(10240)]: {style:markStyle,label:prettyInt(10240)},
                    [inverseCurve(102400)]: {style:markStyle,label:prettyInt(102400)},
                    [inverseCurve(1024 * 1024)]: {style:markStyle,label:prettyInt(1024 * 1024)},
                    [inverseCurve(10 * 1024 * 1024)]: {style:markStyle,label:prettyInt(10 * 1024 * 1024)},
                    [inverseCurve(RANGE_MAX)]: {style:markStyle,label:prettyInt(RANGE_MAX)},
                  }}
                  step={(inverseCurve(RANGE_MAX) - inverseCurve(1)) / 100}
                  value={range}
                  allowCross={false}
                  onChange={this.onSliderChange}
                  trackStyle={[trackStyle]}
                  dotStyle={dotStyle}
                  activeDotStyle={dotStyle}
                  handleStyle={[handleStyle, handleStyle]}
                />
              </RangeContainer>
            </RangeRow>
          </div>
          <SelectionHeader>
            <SelectionHeaderItem>
              {filenameArray.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {filenameArray.length}
                  <StatisticsText>({filenameArray.length === 1
                    ? t({ key: 'SELECTION_MATCH' })
                    : t({ key: 'SELECTION_MATCHES' })
                  })</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
            <SelectionHeaderItem right>
              {selectedKeywords.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {selectedKeywords.length}
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
          </SelectionHeader>
          <SelectionWrapper>
            <SelectionColumnWrapper>
              <Scrollbar>
                {filenameArray.map(suggestion => {
                  const isSelected = find(equals(suggestion), selectedKeywords)
                  return (
                    <SuggestionWrapper>
                      <Suggestion
                        key={suggestion}
                        isSelected={isSelected}
                        onClick={isSelected ? null
                          : () => this.handleRowClick(suggestion)
                        }
                      >
                        <SuggestionText>
                          {suggestion || t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                      </Suggestion>
                    </SuggestionWrapper>
                  )
                })}
              </Scrollbar>
            </SelectionColumnWrapper>
            <Seperator>
              <SeperatorIcon />
            </Seperator>
            <SelectionColumnWrapper right>
              <Scrollbar>
                {selectedKeywords.length > 0
                  ? selectedKeywords.map(keyword => (
                    <SuggestionWrapper>
                      <Suggestion
                        closeable
                        key={keyword}
                        isSelected={false}
                        onClick={() => this.handleRowClick(keyword)}
                      >
                        <SuggestionText>
                          {keyword || t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                        <CloseIcon>×</CloseIcon>
                      </Suggestion>
                    </SuggestionWrapper>
                  )) : !isEmpty(filename) && (
                    <SuggestionWrapper>
                      <AllResultsText>
                        {t({ key: 'SELECTION_ALL_MATCHES_OF', variables: { value: filename } })}
                      </AllResultsText>
                    </SuggestionWrapper>
                  )
                }
              </Scrollbar>
            </SelectionColumnWrapper>
          </SelectionWrapper>
        </Container>
      )}
      </I18n>
    )
  }
}


AttachmentsInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default AttachmentsInput
