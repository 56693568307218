
// XHR requests
import axios from "axios";
import {PUBLIC_API_SERVER_URL} from "../static/appConfig";
import {mergeRight} from "ramda";

const getMessageActionOptionsXhr = (body, accessToken, params = {}) => axios.post(
  `${PUBLIC_API_SERVER_URL}/messages/actions`,
  body,
  {
    params: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  },
)

const getMessagesAllowBlockListEntriesXhr = (body, accessToken, params = {}) => axios.post(
  `${PUBLIC_API_SERVER_URL}/messages/details`,
  body,
  {
    params: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  },
)

const getAllowListEntriesXhr = (body, accessToken) => axios.post(
  `${PUBLIC_API_SERVER_URL}/allow-list`,
  mergeRight(body, {request_type: 'list'}),
  {
    params: {},
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  },
)

const getBlockListEntriesXhr = (body, accessToken) => axios.post(
  `${PUBLIC_API_SERVER_URL}/block-list`,
  mergeRight(body, {request_type: 'list'}),
  {
    params: {},
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  },
)

const postMessageRemediationXhr = (body, accessToken) => axios.post(
  `${PUBLIC_API_SERVER_URL}/messages/remediate`,
  body,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  },
)

const putReportUpdateXhr = (body, reportId, accessToken) => axios.put(
  `${PUBLIC_API_SERVER_URL}/reports/${reportId}`,
  body,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  },
)

const postAllowListXhr = (body, reqType, accessToken) => axios.post(
  `${PUBLIC_API_SERVER_URL}/allow-list`,
  mergeRight(body, {request_type: reqType}),
  {
    params: { },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  },
)

const postBlockListXhr = (body, reqType, accessToken) => axios.post(
  `${PUBLIC_API_SERVER_URL}/block-list`,
  mergeRight(body, {request_type: reqType}),
  {
    params: { },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  },
)

const postPolicyXhr = (body, accessToken) => axios.post(
  `${PUBLIC_API_SERVER_URL}/policies`,
  body,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  },
)

const getAllowListOptionsAction = async (body, accessToken) => {
  try {
    let res = await getMessageActionOptionsXhr(body, accessToken, {filter:'allowlist'})
    return res?.data?.actions
  } catch (err) {
    console.log("getAllowListOptionsAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const getBlockListOptionsAction = async (body, accessToken) => {
  try {
    let res = await getMessageActionOptionsXhr(body, accessToken, {filter:'blocklist'})
    return res?.data?.actions
  } catch (err) {
    console.log("getBlockListOptionsAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const getAllowListEntriesAction = async (body, accessToken) => {
  try {
    let res = await getAllowListEntriesXhr(body, accessToken)
    return res?.data
  } catch (err) {
    console.log("getAllowListEntriesAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const getBlockListEntriesAction = async (body, accessToken) => {
  try {
    let res = await getBlockListEntriesXhr(body, accessToken)
    return res?.data
  } catch (err) {
    console.log("getBlockListEntriesAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const getMessagesAllowBlockListEntriesAction = async (body, accessToken) => {
  try {
    let res = await getMessagesAllowBlockListEntriesXhr(body, accessToken, {filter: 'action-entries'})
    return res?.data
  } catch (err) {
    console.log("getMessagesAllowBlockListEntriesAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const getMessageActionOptionsAction = async (body, accessToken) => {
  try {
    let res = await getMessageActionOptionsXhr(body, accessToken)
    return res?.data
  } catch (err) {
    console.log("getMessageActionOptionsAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const getPolicyOptionsAction = async (body, accessToken) => {
  try {
    let res = await getMessageActionOptionsXhr(body, accessToken, {filter:'policy'})
    return res?.data
  } catch (err) {
    console.log("getPolicyOptionsAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const getRemediationOptionsAction = async (body, accessToken, ignoreHistory) => {
  try {
    let res = await getMessageActionOptionsXhr(body, accessToken, {filter:'remediate', ignore_history:ignoreHistory})
    return res?.data
  } catch (err) {
    console.log("getRemediationOptionsAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const postMessageRemediationAction = async (body, accessToken) => {
  try {
    let res = await postMessageRemediationXhr(body, accessToken)

    console.log("Remediation result: ", res)
    return res?.data
  } catch (err) {
    console.log("postMessageRemediationAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const postAllowListEntryAction = async (body, reqType, accessToken) => {
  try {
    let res = await postAllowListXhr(body, reqType, accessToken)
    return res?.data
  } catch (err) {
    console.log("postAllowListEntryAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const postBlockListEntryAction = async (body, reqType, accessToken) => {
  try {
    let res = await postBlockListXhr(body, reqType, accessToken)
    return res?.data
  } catch (err) {
    console.log("postBlockListEntryAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const postPolicyAction = async (body, accessToken) => {
  try {
    let res = await postPolicyXhr(body, accessToken)
    return res?.data
  } catch (err) {
    console.log("postPolicyAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const putReportUpdateAction = async (body, reportId, accessToken) => {
  try {
    let res = await putReportUpdateXhr(body, reportId, accessToken)
    return res?.data
  } catch (err) {
    console.log("postReportUpdateAction:Exception:", err)
    return {'error': 'An internal server error has occurred. Please try again'}
  }
}

const actions = {
  getMessageActionOptionsAction,
  getAllowListOptionsAction,
  getMessagesAllowBlockListEntriesAction,
  getBlockListOptionsAction,
  getPolicyOptionsAction,
  getRemediationOptionsAction,
  getAllowListEntriesAction,
  getBlockListEntriesAction,
  postMessageRemediationAction,
  postAllowListEntryAction,
  postBlockListEntryAction,
  postPolicyAction,
  putReportUpdateAction,
}

export default actions