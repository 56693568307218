import styled from 'styled-components'
import {
  HeadlineH3PrimaryMainBold,
  Body1DarkGreyRegular,
  spacing,
  colors,
} from 'static/theme'
import { ReactComponent as SelectedTickOnSVG } from 'assets/widgets/selection-tick-on.svg'
import { ReactComponent as SelectedTickOffSVG } from 'assets/widgets/selection-tick-off.svg'

import { ReactComponent as chartPieSVG } from 'assets/widgets/charts-pie.svg'
import { ReactComponent as chartBarSVG } from 'assets/widgets/charts-bar.svg'
import { ReactComponent as chartLineSVG } from 'assets/widgets/charts-line.svg'
import { ReactComponent as chartAreaSVG } from 'assets/widgets/charts-area.svg'
import { ReactComponent as chartListSVG } from 'assets/widgets/charts-list.svg'
import { ReactComponent as chartStackBarSVG } from 'assets/widgets/charts-stack-bar.svg'
import { ReactComponent as chartMapSVG } from 'assets/widgets/charts-map.svg'
import { ReactComponent as chartMapZoomableSVG } from 'assets/widgets/charts-map-zoomable.svg'

export const Content = styled.div<{ selected: boolean }>`
  position: relative;
  width: 208px;
  height: 184px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 4px;
  border: 1.5px solid
    ${({ selected }) =>
      selected ? colors.secondaryMain : colors.primaryLighter};
  &:hover {
    cursor: pointer;
    border-color: ${colors.secondaryMain};
  }
`

export const Title = styled(HeadlineH3PrimaryMainBold)`
  text-align: center;
  margin-top: ${spacing.xs};
`

export const Subtitle = styled(Body1DarkGreyRegular)`
  margin-top: ${spacing.xxs};
  margin-bottom: ${spacing.xl};
`

export const SelectedTickOnIcon = styled(SelectedTickOnSVG)`
  position: absolute;
  top: ${spacing.xs};
  right: ${spacing.xs};
  fill: ${colors.secondaryMain};
`

export const SelectedTickOffIcon = styled(SelectedTickOffSVG)`
  position: absolute;
  top: ${spacing.xs};
  right: ${spacing.xs};
  stroke: ${colors.primaryLight};
`

export const svgStyles = `
  margin-top: ${spacing.xl};
  fill: ${colors.primaryDark};
`

export const PieSVG = styled(chartPieSVG)`
  ${svgStyles}
`

export const BarSVG = styled(chartBarSVG)`
  ${svgStyles}
`

export const LineSVG = styled(chartLineSVG)`
  ${svgStyles}
`

export const AreaSVG = styled(chartAreaSVG)`
  ${svgStyles}
`

export const ListSVG = styled(chartListSVG)`
  ${svgStyles}
`

export const StackBarSVG = styled(chartStackBarSVG)`
  ${svgStyles}
`

export const MapSVG = styled(chartMapSVG)`
  ${svgStyles}
`

export const MapZoomableSVG = styled(chartMapZoomableSVG)`
  ${svgStyles}
`
