import React, { HTMLAttributes } from 'react'
import { Container, ActiveCheckIcon, InactiveCheckIcon } from './styles'

export const Checkbox = ({
  checked,
  style,
}: { checked: Boolean } & HTMLAttributes<HTMLDivElement>) => {
  return (
    <Container style={style}>
      {checked ? <ActiveCheckIcon /> : <InactiveCheckIcon />}
    </Container>
  )
}
