import React, { ReactNode } from 'react'
import { TextFieldProps } from '@material-ui/core'
import { MaterialUITextField } from './style'

type CustomTextFieldProps = TextFieldProps & {
  children?: ReactNode
}

export const TextField = ({
  color = 'secondary',
  variant = 'outlined',
  children,
  ...rest
}: CustomTextFieldProps) => {
  return (
    <MaterialUITextField
      color={color}
      variant={variant}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    >
      {children}
    </MaterialUITextField>
  )
}
