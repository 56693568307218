import React from 'react'
import PropTypes from 'prop-types'
import curry from 'ramda/src/curry'
import propEq from 'ramda/src/propEq'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Tooltip } from '../../../lib/util'
import { I18n } from '../../../lib/i18n'
import Set from '../Set'
import Tabs from '../Tabs'
import Handle from '../Handle'
import {
  Container,
  Header,
  Button,
  TilesIcon,
  TabsIcon,
  DeleteButton,
  DeleteIcon,
  SetList,
} from './styles'

const { array, number, string } = PropTypes

class InnerList extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { sets } = this.props
    if (nextProps.sets === sets) {
      return false
    }
    return true
  }

  componentDidUpdate(prevProps) {
    const { sets, totalSets } = this.props
    if (this.listEnd && sets.length > prevProps.sets.length && totalSets > prevProps.totalSets) {
      setTimeout(() => {
        if (this.listEnd) this.listEnd.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }

  render() {
    const { sets, dashboardId, ...rest } = this.props
    return (
      <React.Fragment>
        {
          sets.map((set, index) => (
            <Set
              key={dashboardId + '-' + set.id}
              set={set}
              index={index}
              dashboardId={dashboardId}
              {...rest}
            />
          ))
        }
        <div ref={el => { this.listEnd = el }} />
      </React.Fragment>
    )
  }
}

const Column = ({
  totalSets,
  column,
  sets,
  index,
  direction,
  belongsToUser,
  onRemoveColumn,
  onCellClick,
  toggleColumnViewModeChange,
  ...rest
}) => {
  const handleTabs = () => toggleColumnViewModeChange(index, 'tabs')
  const handleTiles = () => toggleColumnViewModeChange(index, 'tiles')
  const handleDelete = () => onRemoveColumn(index)
  const handleCellClick = curry(onCellClick)(index)

  const isHorizontal = direction === 'horizontal'
  const tabs = column.mode ? propEq('mode', 'tabs', column) : index > 0
  const showDragHandle = false

  return (
    <Draggable draggableId={column.id} index={index} isDragDisabled>
      {draggableProvided => (
        <I18n>{({ t }) => (
          <Container ref={draggableProvided.innerRef} direction={direction}>
            {isHorizontal && belongsToUser && (
              <Header direction={direction}>
                <Button
                  data-tip
                  data-for="modeButton"
                  onClick={tabs ? handleTiles : handleTabs}
                >
                  {tabs ? <TabsIcon /> : <TilesIcon />}
                </Button>
                <Tooltip bottom id="modeButton">
                  <span>{t({ key: 'TOOLTIP_COLUMN_MODE' })}</span>
                </Tooltip>
                <DeleteButton
                  data-tip
                  data-for="closeButton"
                  onClick={handleDelete}
                  direction={direction}
                >
                  <DeleteIcon />
                </DeleteButton>
                <Tooltip bottom id="closeButton">
                  <span>{t({ key: 'TOOLTIP_CLOSE_ALL' })}</span>
                </Tooltip>
                {showDragHandle && <Handle isColumnHandle offset={isHorizontal ? -22 : 22} />}
              </Header>
            )}
            {tabs ? (
              <Tabs
                belongsToUser={belongsToUser}
                onCellClick={handleCellClick}
                isFirstLevel={index === 0}
                sets={sets}
                {...rest}
              />
            ) : (
              <Droppable
                type="set"
                droppableId={column.id}
                direction={isHorizontal ? 'vertical' : 'horizontal'}
              >
                {(droppableProvided, snapshot) => (
                  <SetList
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  // isDraggingOver={snapshot.isDraggingOver}
                    direction={direction}
                  >
                    <InnerList
                      belongsToUser={belongsToUser}
                      onCellClick={handleCellClick}
                      direction={direction}
                      totalSets={totalSets}
                      sets={sets}
                      {...rest}
                    />
                    {droppableProvided.placeholder}
                  </SetList>
                )}
              </Droppable>
            )}
            {!isHorizontal && belongsToUser && (
              <Header direction={direction}>
                <Button
                  data-tip
                  data-for="modeButton"
                  onClick={tabs ? handleTiles : handleTabs}
                >
                  {tabs ? <TabsIcon /> : <TilesIcon />}
                </Button>
                <Tooltip bottom id="modeButton">
                  <span><span>{t({ key: 'TOOLTIP_COLUMN_MODE' })}</span></span>
                </Tooltip>
                <DeleteButton
                  data-tip
                  data-for="closeButton"
                  onClick={handleDelete}
                  direction={direction}
                >
                  <DeleteIcon />
                </DeleteButton>
                <Tooltip bottom id="closeButton">
                  <span>{t({ key: 'TOOLTIP_CLOSE_ALL' })}</span>
                </Tooltip>
                {showDragHandle && <Handle isColumnHandle offset={isHorizontal ? -22 : 22} />}
              </Header>
            )}
          </Container>
        )}
        </I18n>
      )}
    </Draggable>
  )
}

InnerList.propTypes = {
  sets: array,
  totalSets: number,
  dashboardId: string,
}

export default Column
