import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
`

export const ZoomButton = styled.div`
  width: 100%;
  text-align: right;
  position: absolute;
  z-index: 1;
`

export const AbsolutePosition = styled.div`
  position: absolute;
`

export const TickText = styled.text`
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;

  ${props => (props.isClickable ? `
    &:hover {
      cursor: pointer;
    }
  ` : '')}
`
