import styled from 'styled-components'
import { spacing, Body2PrimaryLightBold, colors } from 'static/theme'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  background-color: ${colors.primaryLighter};
  padding: ${spacing.xxs};
  min-width: 78px;
  &:hover {
    cursor: pointer;
  }
`

export const Option = styled(Body2PrimaryLightBold)<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ selected }) =>
    `${selected ? colors.primaryMain : colors.primaryLight}`};
  background-color: ${({ selected }) => `${selected && colors.white}`};
  border-radius: 16px;
  padding: 1px ${spacing.xxs};
  min-width: 31px;
`
