import axios from 'axios'
import { KOTLIN_URL } from '../../static/appConfig'
import { ReportPayload, ReportResponse } from './types'

// XHR //
const getReportXhr = (payload: ReportPayload) => {
  return axios.get<ReportResponse>(
    `${KOTLIN_URL}/dashboards/${payload.reportId}`,
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        'X-Team': payload.realm,
      },
    }
  )
}

// Actions //
export const getReportAction = async (_key: string, payload: ReportPayload) => {
  const res = await getReportXhr(payload)
  return res.data
}
