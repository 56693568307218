import styled from 'styled-components'
import { motion } from 'framer-motion'
import { colors } from 'static/theme'

export const Container = styled.div<{ fullWidth: boolean; percentage: number }>`
  display: flex;
  width: ${({ fullWidth, percentage }) => `${fullWidth ? '100' : percentage}%`};
  background-color: ${colors.primaryExtraLight};
  border-radius: 1rem;
  padding: 0.25rem;
`

export const Border = styled.div`
  display: flex;
  background-color: ${colors.primaryLighter};
  border-radius: 1rem;
  width: 100%;
`

export const AnimatedFill = styled(motion.div)<{
  color: string
}>`
  display: flex;
  background-color: ${({ color }) => color};
  height: 0.5rem;
  border-radius: 1rem;
`

export const StaticFill = styled.div<{
  color: string
}>`
  display: flex;
  background-color: ${({ color }) => color};
  height: 0.5rem;
  border-radius: 1rem;
`
