import React from 'react'
import styled from 'styled-components'
import { Loader as LoaderSemantic, StrictLoaderProps } from 'semantic-ui-react'

export const Container = styled.div`
  padding: 2rem;
`

export const Loader = ({ ...rest }: StrictLoaderProps) => (
  <Container>
    <LoaderSemantic inline='centered' {...rest} />
  </Container>
)
