export const INTIAL_MICROSOFT_SCL = [
  { scl: -1, value: 0 },
  { scl: 0, value: 0 },
  { scl: 1, value: 0 },
  { scl: 2, value: 0 },
  { scl: 3, value: 0 },
  { scl: 4, value: 0 },
  { scl: 5, value: 0 },
  { scl: 6, value: 0 },
  { scl: 7, value: 0 },
  { scl: 8, value: 0 },
  { scl: 9, value: 0 },
]

export const REASON_ID_NAMES = id => {
  switch (id) {
    case 1:
      return 'Blocked Domain'
    case 2:
      return 'Blocked Sender IP'
    case 3:
      return 'Blocked Subject Word'
    case 4:
      return 'Brand Impersonation'
    case 5:
      return 'Confusable Display Name'
    case 6:
      return 'Confusable Domain'
    case 7:
      return 'Failed Sender Authentication'
    case 8:
      return 'Google Safe Browsing URL'
    case 9:
      return 'Invalid From Address'
    case 10:
      return 'IP Address URL'
    case 11:
      return 'Low Sender Reputation'
    case 12:
      return 'Malware Attachment'
    case 13:
      return 'Misleading Link'
    case 14:
      return 'Misleading Reply-To'
    case 15:
      return 'Phishing Content'
    case 16:
      return 'PhishTank URL'
    case 17:
      return 'Potential Sender Forgery'
    case 18:
      return 'Sensitive Content'
    case 19:
      return 'Signing Verification Failed'
    case 20:
      return 'Spam Content'
    case 21:
      return 'Spammy Top-Level Domain'
    case 22:
      return 'Spoofed Internal Sender'
    case 23:
      return 'Unable to Check Sender Authenticity'
    case 24:
      return 'Unexpected Link Target'
    case 25:
      return 'Unsigned Sensitive Content'
    case 30:
      return 'Suspicious URL'
    case 31:
      return 'Free Web Hosting URL'
    case 32:
      return 'Netcraft Phishing Feed URL'
    case 33:
      return 'First-Time Sender'
    case 34:
      return 'Poor Sender Reputation'
    case 35:
      return 'Invalid Sender'
    case 36:
      return 'Possible Brand Impersonation'
    case 37:
      return 'Brand Impersonation Site'
    case 38:
      return 'Potentially Dangerous HTML'
    case 39:
      return 'Potentially Dangerous Content'
    case 40:
      return 'Potentially Dangerous Content Removed'
    case 41:
      return 'Signing Policy Violation'
    case 43:
      return 'Reported Phish'
    case 44:
      return 'Reported Spam'
    case 45:
      return 'Signing Verification Failure'
    case 46:
      return 'JavaScript Detected'
    case 47:
      return 'JavaScript Removed'
    case 48:
      return 'Spoofed VIP'
    case 49:
      return 'Cryptocurrency Scam'
    case 50:
      return 'Invalid From Header'
    case 51:
      return 'Malware Content'
    case 52:
      return 'Possibly Misconfigured Service'
    case 53:
      return 'Potentially Dangerous Attachment Removed'
    case 54:
      return 'Phishing Site'
    default:
      return 'Unknown reason id'
  }
}

export default ['Neutral', 'Caution', 'Danger']
