import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
`

export const AbsolutePosition = styled.div`
  position: absolute;
`
