import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { spacing } from 'static/theme'

export const MaterialUITextField = styled(TextField)`
  && {
    display: flex;
    flex: 1;
    margin-top: ${spacing.s};
  }
`
