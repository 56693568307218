import styled from 'styled-components'
import { Button as MaterialButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  colors,
  spacing,
  HeadlineH2PrimaryMainBold,
  BodyDarkGreyRegular,
  Body1PrimaryDarkRegular,
} from 'static/theme'

export const useTooltipStyles = makeStyles(() => ({
  arrow: {
    color: colors.white,
  },
  tooltip: {
    padding: 0,
    minWidth: 200,
    maxWidth: 450,
    borderRadius: 4,
    backgroundColor: colors.white,
    boxShadow: `0 0 30px 0 ${colors.primaryMain}`,
  },
}))

export const Container = styled.div<{
  top?: number
  left?: number
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: ${(props) => (props.top ? props.top : 0)}px;
  left: ${(props) => (props.left ? props.left : 0)}px;
  z-index: 301;
`

export const Transparency = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
`

export const QuickTourStepWrapper = styled.div<{
  isHighlighted?: boolean
  isOutlined?: boolean
}>`
  position: relative;
  ${(props) => (props.isHighlighted ? 'z-index: 301;' : '')}
  ${(props) =>
    props.isOutlined ? `border 1px solid ${colors.secondaryMain};` : ''}
`

export const Box = styled.div`
  display: flex;
  border-radius: 4px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.div`
  padding: ${spacing.m};
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`

export const Headline = styled(HeadlineH2PrimaryMainBold)``

export const Progress = styled(Body1PrimaryDarkRegular)``

export const Prompt = styled(BodyDarkGreyRegular)`
  margin-top: ${spacing.s};
  text-align: left;
`

export const Footer = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${spacing.s};
  border-radius: 0 0 4px 4px;
  background-color: ${colors.background};
`

export const SkipButton = styled(MaterialButton)`
  color: ${colors.primaryDark} !important;
  text-transform: none !important;
  font-size: 14px !important;
`

export const LargeButton = styled(MaterialButton).attrs({
  color: 'secondary',
  variant: 'contained',
  size: 'large',
})`
  font-size: 16px !important;
  padding: 10px 32px !important;
  border-radius: 8px !important;
`

export const NextButton = styled(MaterialButton)`
  color: ${colors.secondaryMain} !important;
  margin-right: ${spacing.xs} !important;
  text-transform: none !important;
  font-size: 14px !important;
`
