import React, { useCallback, useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '@logicea/react-auth'
import { I18n } from 'lib/i18n'
import { countMessagesAction } from 'requests/messageRQ'
import { useReports } from 'providers/Reports'
import { MagicNumber, MagicNumberPlaceholder } from '../MagicNumber'
import { buildReportDashboardFilters } from "../../../lib/util";

const MalwareAttachement = () => {
  const { timeframe, comparisonTimeframe, isComparisonEnabled, teamFilter } = useReports()
  const { from, to } = timeframe
  const { from: comparisonFrom, to: comparisonTo } = comparisonTimeframe

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth as any
  const queryArgs = { realm, token: tokens?.accessToken }

  const filterArgs = useCallback(
    ({ from, to, teamFilter }: { from: number; to: number, teamFilter: string[] }) => {
      return {
        dashboardFilters: buildReportDashboardFilters(from, to, teamFilter),
        setFilters: [
          {
            name: 'reason_ids',
            not: false,
            term: [12],
            type: 'integer',
          },
        ],
        dashboardFilterMode: 'AND',
        setFilterMode: 'AND',
      }
    },
    []
  )

  const { data, isLoading: dataIsLoading } = useQuery(
    ['countMalwareAttachement', filterArgs({ from, to, teamFilter }), queryArgs],
    countMessagesAction,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const { data: comparedData, isLoading: comparedDataIsLoading } = useQuery(
    [
      'comparedCountMalwareAttachement',
      filterArgs({ from: comparisonFrom, to: comparisonTo, teamFilter }),
      queryArgs,
    ],
    countMessagesAction,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const percent = useMemo(() => {
    if (comparedData === 0) return undefined
    if (data && comparedData) {
      return data / comparedData - 1
    } else {
      return 0
    }
  }, [data, comparedData])

  const isLoading = dataIsLoading || comparedDataIsLoading

  return (
    <I18n>
      {({ t }) =>
        !isLoading ? (
          <MagicNumber
            title={t({ key: 'MALWARE_ATTACHEMENT' })}
            number={data}
            percent={isComparisonEnabled ? percent : undefined}
          />
        ) : (
          <MagicNumberPlaceholder title={t({ key: 'MALWARE_ATTACHEMENT' })} />
        )
      }
    </I18n>
  )
}

export { MalwareAttachement }
