import styled from 'styled-components'
import { ReactComponent as ActiveCheckSVG } from 'assets/sidebar/activeCheck.svg'
import { ReactComponent as InactiveCheckSVG } from 'assets/sidebar/inactiveCheck.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ActiveCheckIcon = styled(ActiveCheckSVG)``

export const InactiveCheckIcon = styled(InactiveCheckSVG)``
