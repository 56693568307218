import React, { useContext } from 'react'
import styled from 'styled-components'
import { AuthContext } from '@logicea/react-auth'
import DashboardsProvider from 'providers/Dashboards'
import { HelpProvider } from 'providers/Help'
import Page from 'components/Page'
import Modals from 'components/Modals'
import { colors } from 'static/theme'
import { Sidebar } from 'components/Sidebar'
import { HelpOverlay } from 'components/Help/HelpOverlay'
import { AppRouter } from './AppRouter'

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  min-width: 768px;
  background-color: ${colors.background};
`

export const App = ({ initialRoute }: { initialRoute: string }) => {
  const auth = useContext(AuthContext)
  return (
    <Page permissions={['view:dashboard']}>
      <HelpProvider>
        <DashboardsProvider auth={auth} initialRoute={initialRoute}>
          <Container>
            <Sidebar />
            <AppRouter initialRoute={initialRoute} />
          </Container>
          <Modals />
          <HelpOverlay />
        </DashboardsProvider>
      </HelpProvider>
    </Page>
  )
}
