import styled from 'styled-components'
import link from '../../../../assets/link/link.png'
import click from '../../../../assets/click/click.png'
import image from '../../../../assets/image/image.png'
import report from '../../../../assets/report/report.png'
import attachment from '../../../../assets/attachment/attachment.png'
import {
  BACKGROUND_LIGHT,
  UNSELECTED_BUTTON,
  DARK_TEXT,
  LAYOUT_SEPARRATOR,
} from '../../../../static/colors'

const TAB_ICONS = ['summary', 'details', 'history', 'list', image, attachment, link, click, report]

const TAB_HEIGHT = 25

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: auto;
`

export const TabBar = styled.div`
  display: flex;
  flex-direction: row;
  margin-left:  12px;
  margin-right: 12px;
  border-bottom: 1px solid ${LAYOUT_SEPARRATOR};
`

export const Tab = styled.div`
  background-color: ${props => (props.active ? UNSELECTED_BUTTON : BACKGROUND_LIGHT)};
  height: ${TAB_HEIGHT}px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 2px 2px 0px 0px;
  padding: 4px;

  &:hover {
    cursor: pointer;
  }
`

export const TabText = styled.div`
  color: ${DARK_TEXT};
  font-size: 13px;
  font-family: Lato;
  line-height: 25px;
`

export const Content = styled.div`
  flex: 1;
  background-color: ${BACKGROUND_LIGHT};
  overflow: hidden;
  margin: 0px 15px 0px 15px;
`

export const TabIcon = styled.img.attrs(props => ({
  src: TAB_ICONS[props.tabIndex],
}))`
  height: 14px;
  width: 14px;
  margin-right: 4px;
  outline: none;
`
