import styled from 'styled-components'
import {
  colors,
  HeadlineH1PrimaryMainBold,
  spacing,
  HeadlineH3WhiteRegular,
  BodyPrimaryMainRegular,
} from 'static/theme'
import { SearchBar } from 'components/Inputs/SearchBar'
import { IconButton as Button } from 'components/Buttons/IconButton'
import { ReactComponent as PlusSVG } from 'assets/marketplace/plus.svg'

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 6rem;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};
  padding: ${spacing.m} ${spacing.xl};
`

export const Column = styled.div`
  flex: 1;
  flex-direction: column;
`

export const Title = styled(HeadlineH1PrimaryMainBold)`
  align-self: center;
  margin-bottom: 0.2rem;
`

export const Subtitle = styled(BodyPrimaryMainRegular)`
  align-self: center;
`

export const SearchInput = styled(SearchBar)`
  & .MuiOutlinedInput-root {
    min-width: 29rem;
    height: 3rem;
  }
`

export const IconButton = styled(Button)`
  && {
    margin-left: ${spacing.m};
    height: 48px;
    width: 165px;
    border-radius: 8px;
  }
`

export const ButtonText = styled(HeadlineH3WhiteRegular)`
  line-height: 1.5;
`

export const Logo = styled.img`
  height: 42px;
`

export const PlusIcon = styled(PlusSVG)`
  fill: ${colors.white};
  margin-right: ${spacing.xs};
`
