import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import path from 'ramda/src/path'
import pathOr from 'ramda/src/pathOr'
import isNil from 'ramda/src/isNil'
import propEq from 'ramda/src/propEq'
import isEmpty from 'ramda/src/isEmpty'
import { Form } from 'semantic-ui-react'
import { Radio } from 'components/Radio'
import DatePicker from 'react-datepicker'
import { I18n } from '../../../lib/i18n'
import {
  FormContainer,
  RangeOption,
  NumberInput,
  CalendarsWrapper,
  SingleCalendarWrapper,
  Label,
  RelativeNowWrapper,
  RelativeNowRow,
  RelativeNowTitle,
  RelativeNowText,
  InlineQuickLinks,
  SpecificWrapper,
} from './styles'

const { object, func } = PropTypes

const calculateTime = time => {
  let seconds = time * 60
  let days = Math.floor(seconds / (3600 * 24))
  seconds -= days * 3600 * 24
  let hrs = Math.floor(seconds / 3600)
  seconds -= hrs * 3600
  let mnts = Math.floor(seconds / 60)
  seconds -= mnts * 60
  return { days: days, hours: hrs, minutes: mnts }
}

const DateInput = ({
  startDate,
  endDate,
  filter,
  onDateChange,
}) => {
  const [relativeStart, setRelativeStart] = useState(null)
  const [specificStart, setSpecificStart] = useState(null)
  const [relativeEnd, setRelativeEnd] = useState(null)
  const [specificEnd, setSpecificEnd] = useState(null)
  const [relative, setRelative] = useState(true)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [betweenMinutes, setBetweenMinutes] = useState(0)
  const [betweenHours, setBetweenHours] = useState(0)
  const [betweenDays, setBetweenDays] = useState(0)
  const [andMinutes, setAndMinutes] = useState(0)
  const [andHours, setAndHours] = useState(0)
  const [andDays, setAndDays] = useState(0)

  useEffect(() => {
    const relativeFromObject = calculateTime(pathOr(0, ['term', 'relativeFrom'], filter))
    setBetweenMinutes(relativeFromObject.minutes)
    setBetweenHours(relativeFromObject.hours)
    setBetweenDays(relativeFromObject.days)
  }, [filter.term.relativeFrom])

  useEffect(() => {
    const relativeToObject = calculateTime(pathOr(0, ['term', 'relativeTo'], filter))
    setAndMinutes(relativeToObject.minutes)
    setAndHours(relativeToObject.hours)
    setAndDays(relativeToObject.days)
  }, [filter.term.relativeTo])

  useEffect(() => {
    setFrom(isEmpty(filter.term) || isNil(filter.term) || filter.type === 'relative_date'
      ? null
      : new Date(path(['term', 'from'], filter) * 1000))
    setRelative(propEq('type', 'relative_date', filter))
  }, [startDate, filter])

  useEffect(() => {
    setTo(isEmpty(filter.term) || isNil(filter.term) || filter.type === 'relative_date'
      ? null
      : new Date(path(['term', 'to'], filter) * 1000))
    setRelative(propEq('type', 'relative_date', filter))
  }, [endDate, filter])

  const handleRelativeRangeOptionClick = (option, value, staticRelativeValue) => {
    let start = betweenMinutes + (betweenHours * 60) + (betweenDays * 60 * 24)
    let end = andMinutes + (andHours * 60) + (andDays * 60 * 24)

    if (staticRelativeValue) {
      end = 0
    }
    switch (option) {
      case 0:
        start = value + (betweenHours * 60) + (betweenDays * 60 * 24)
        setBetweenMinutes(value)
        if (staticRelativeValue) {
          start = value // only minutes
          setBetweenHours(0)
          setBetweenDays(0)
          setAndMinutes(0)
          setAndHours(0)
          setAndDays(0)
        }
        break
      case 1:
        start = betweenMinutes + (value * 60) + (betweenDays * 60 * 24)
        setBetweenHours(value)
        if (staticRelativeValue) {
          start = value * 60 // only hours
          setBetweenMinutes(0)
          setBetweenDays(0)
          setAndMinutes(0)
          setAndHours(0)
          setAndDays(0)
        }
        break
      case 2:
        start = betweenMinutes + (betweenHours * 60) + (value * 60 * 24)
        setBetweenDays(value)
        if (staticRelativeValue) {
          start = value * 60 * 24 // only days
          setBetweenMinutes(0)
          setBetweenHours(0)
          setAndMinutes(0)
          setAndHours(0)
          setAndDays(0)
        }
        break
      case 3:
        end = value + (andHours * 60) + (andDays * 60 * 24)
        setAndMinutes(value)
        break
      case 4:
        end = andMinutes + (value * 60) + (andDays * 60 * 24)
        setAndHours(value)
        break
      case 5:
        end = andMinutes + (andHours * 60) + (value * 60 * 24)
        setAndDays(value)
        break
      default:
        break
    }

    onDateChange(start, end, true)
    setRelativeStart(start)
    setRelativeEnd(end)
    setRelative(true)
  }

  const handleSpecificRangeOptionClick = option => {
    let start = new Date()
    start.setHours(0, 0, 0, 0)
    let end = new Date()
    end.setHours(23, 59, 59, 999)

    switch (option) {
      case 1:
        start.setDate(start.getDate() - 1)
        end.setDate(end.getDate() - 1)
        break
      case 2:
        start.setDate(start.getDate() - 7)
        break
      case 3:
        start.setDate(start.getDate() - 30)
        break
      case 4:
        start.setDate(start.getDate() - 365)
        break
      default:
        break
    }
    onDateChange(start, end, false)
    setSpecificStart(start)
    setSpecificEnd(end)
    setRelative(false)
  }

  const handleRelativeRadioClick = () => {
    const start = relativeStart || (betweenMinutes + (betweenHours * 60) + (betweenDays * 60 * 24))
    const end = relativeEnd || (andMinutes + (andHours * 60) + (andDays * 60 * 24))
    onDateChange(start, end, true)
    setRelative(true)
  }

  const handleSpecificRadioClick = () => {
    let now = new Date()
    now.setHours(0, 0, 0, 0)
    let yesterday = new Date()
    yesterday.setHours(23, 59, 59, 999)
    const start = specificStart || now
    const end = specificEnd || yesterday
    onDateChange(start, end, false)
    setRelative(false)
  }

  return (
    <I18n>{({ t }) => (
      <FormContainer>
        <Form.Field>
          <Radio
            label={t({ key: 'INPUT_DATE_RELATIVE_TO_NOW' })}
            checked={relative}
            onChange={handleRelativeRadioClick}
          />
          <RelativeNowWrapper disabled={!relative}>
            <RelativeNowRow>
              <RelativeNowTitle>
                {t({ key: 'INPUT_DATE_BETWEEN' })}
              </RelativeNowTitle>
              <NumberInput
                min={0}
                max={365}
                value={betweenDays}
                onChange={value => handleRelativeRangeOptionClick(2, value)}
              />
              <RelativeNowText>
                {t({ key: 'INPUT_DATE_DAYS' })}
              </RelativeNowText>
              <NumberInput
                min={0}
                max={24}
                value={betweenHours}
                onChange={value => handleRelativeRangeOptionClick(1, value)}
              />
              <RelativeNowText>
                {t({ key: 'INPUT_DATE_HOURS' })}
              </RelativeNowText>
              <NumberInput
                min={0}
                max={60}
                value={betweenMinutes}
                onChange={value => handleRelativeRangeOptionClick(0, value)}
              />
              <RelativeNowText>
                {t({ key: 'INPUT_DATE_MINUTES_AGO' })}
              </RelativeNowText>
            </RelativeNowRow>
            <RelativeNowRow>
              <RelativeNowTitle>
                {t({ key: 'INPUT_DATE_AND' })}
              </RelativeNowTitle>
              <NumberInput
                min={0}
                max={365}
                value={andDays}
                onChange={value => handleRelativeRangeOptionClick(5, value)}
              />
              <RelativeNowText>
                {t({ key: 'INPUT_DATE_DAYS' })}
              </RelativeNowText>
              <NumberInput
                min={0}
                max={24}
                value={andHours}
                onChange={value => handleRelativeRangeOptionClick(4, value)}
              />
              <RelativeNowText>
                {t({ key: 'INPUT_DATE_HOURS' })}
              </RelativeNowText>
              <NumberInput
                min={0}
                max={60}
                value={andMinutes}
                onChange={value => handleRelativeRangeOptionClick(3, value)}
              />
              <RelativeNowText>
                {t({ key: 'INPUT_DATE_MINUTES_AGO' })}
              </RelativeNowText>
            </RelativeNowRow>
            <RelativeNowRow>
              <RelativeNowText>
                {t({ key: 'INPUT_DATE_LAST' })}
              </RelativeNowText>
              <RangeOption onClick={() => handleRelativeRangeOptionClick(1, 1, true)}>
                {t({ key: 'INPUT_DATE_HOUR' })}
              </RangeOption>
              <RangeOption onClick={() => handleRelativeRangeOptionClick(2, 1, true)}>
                {t({ key: 'INPUT_DATE_DAY' })}
              </RangeOption>
              <RangeOption onClick={() => handleRelativeRangeOptionClick(2, 7, true)}>
                {t({ key: 'INPUT_DATE_WEEK' })}
              </RangeOption>
              <RangeOption onClick={() => handleRelativeRangeOptionClick(2, 30, true)}>
                {t({ key: 'INPUT_DATE_MONTH' })}
              </RangeOption>
              <RangeOption onClick={() => handleRelativeRangeOptionClick(2, 365, true)}>
                {t({ key: 'INPUT_DATE_YEAR' })}
              </RangeOption>
            </RelativeNowRow>
          </RelativeNowWrapper>
        </Form.Field>
        <Form.Field>
          <Radio
            label={t({ key: 'INPUT_DATE_SPECIFIC_DATE_TIME' })}
            checked={!relative}
            onChange={handleSpecificRadioClick}
          />
          <SpecificWrapper disabled={relative}>
            <CalendarsWrapper>
              <SingleCalendarWrapper>
                <Label>{t({ key: 'INPUT_DATE_START_TIME' })}</Label>
                <DatePicker
                  selected={from}
                  onChange={date => onDateChange(date, null)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="Time"
                  inline
                  fixedHeight
                />
              </SingleCalendarWrapper>
              <SingleCalendarWrapper>
                <Label>{t({ key: 'INPUT_DATE_END_TIME' })}</Label>
                <DatePicker
                  selected={to}
                  onChange={date => onDateChange(null, date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="Time"
                  inline
                  fixedHeight
                />
              </SingleCalendarWrapper>
            </CalendarsWrapper>
            <InlineQuickLinks>
              <RangeOption onClick={() => handleSpecificRangeOptionClick(0)}>
                {t({ key: 'INPUT_DATE_TODAY' })}
              </RangeOption>
              <RangeOption onClick={() => handleSpecificRangeOptionClick(1)}>
                {t({ key: 'INPUT_DATE_YESTERDAY' })}
              </RangeOption>
              <RangeOption onClick={() => handleSpecificRangeOptionClick(2)}>
                {t({ key: 'INPUT_DATE_THIS_WEEK' })}
              </RangeOption>
              <RangeOption onClick={() => handleSpecificRangeOptionClick(3)}>
                {t({ key: 'INPUT_DATE_THIS_MONTH' })}
              </RangeOption>
              <RangeOption onClick={() => handleSpecificRangeOptionClick(4)}>
                {t({ key: 'INPUT_DATE_THIS_YEAR' })}
              </RangeOption>
            </InlineQuickLinks>
          </SpecificWrapper>
        </Form.Field>
      </FormContainer>
    )}
    </I18n>
  )
}

DateInput.propTypes = {
  startDate: object,
  endDate: object,
  filter: object,
  onDateChange: func,
}

export default DateInput
