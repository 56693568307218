import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import find from 'ramda/src/find'
import uniq from 'ramda/src/uniq'
import equals from 'ramda/src/equals'
import propEq from 'ramda/src/propEq'
import isEmpty from 'ramda/src/isEmpty'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import { SearchInput } from 'components/SearchInput'
import { I18n } from '../../../lib/i18n'
import messageActions from '../../../requests/message'
import debounce from 'lodash.debounce'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  SuggestionTextWide,
  SuggestionWide,
  SuggestionText,
  Suggestion,
  AllResultsText,
  CloseIcon,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import { INPUT_TEXT_DEBOUNCE_TIME } from '../../../static/appConfig'

const { object, func, array, string } = PropTypes

const TextInput = ({
  realm,
  token,
  filter,
  onChange,
  setFilters,
  setFilterMode,
  dashboardFilters,
  dashboardFilterMode,
}) => {
  const [value, setValue] = useState('')
  const [hitCount, setHitCount] = useState(null)
  const [suggestions, setSuggestions] = useState([])
  const [selectedMatches, setSelectedMatches] = useState([])

  useEffect(() => {
    const { name, term } = filter
    handleInputChange({ target: { name, value: term } })
  }, [])

  const getTextSuggestionsDebounced = useCallback(
    debounce(async (name, newValue) => {
      const data = await messageActions.getTextSuggestionsAction(
        {
          dashboardFilters: rFilter(
            (f) => !propEq('name', name)(f),
            dashboardFilters
          ),
          setFilters: rFilter((f) => !propEq('name', name)(f), setFilters),
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: name,
          term: newValue.replace(/[\[\]]/g, ' '),
        }
      )
      setHitCount(data.total)
      setSuggestions(uniq(data.hits))
    }, INPUT_TEXT_DEBOUNCE_TIME),
    []
  )

  const handleInputChange = async (event) => {
    const { name } = filter
    const newValue = event.target.value
    if (equals(value, newValue) && !equals(newValue, '')) return

    if (isEmpty(newValue) || typeof newValue !== 'string') {
      if (isEmpty(newValue)) setValue(newValue)
      const data = await messageActions.getSuggestionsAction(
        {
          dashboardFilters: rFilter(
            (f) => !propEq('name', name)(f),
            dashboardFilters
          ),
          setFilters: rFilter((f) => !propEq('name', name)(f), setFilters),
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: `${name}.raw`,
          term: '',
        }
      )
      setHitCount(data.data ? data.data.length : 0)
      setSuggestions(
        data.data
          ? data.data.map((s) => ({ hit: s.key, highlight: s.key }))
          : []
      )
      if (typeof newValue !== 'string') setSelectedMatches(newValue)
    } else {
      setValue(newValue)
      if (selectedMatches.length === 0) onChange(newValue)
      getTextSuggestionsDebounced(name, newValue)
    }
  }

  const handleSuggestionClick = (suggestion) => {
    const newMatches = symmetricDifference(selectedMatches, [suggestion.hit])
    setSelectedMatches(newMatches)
    onChange(newMatches)
  }

  const handleClearClick = () => {
    const { name } = filter
    setValue('')
    onChange('')
    handleInputChange({ target: { name, value: '' } })
  }

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <SearchInput
            autoFocus={true}
            value={value}
            name={filter.name}
            onChange={handleInputChange}
            onClear={handleClearClick}
            placeholder={t({ key: filter.name })}
            key={filter.name}
          />
          <SelectionHeader>
            <SelectionHeaderItem>
              {suggestions.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {suggestions.length}
                  <StatisticsText>({suggestions.length === 1
                    ? t({ key: 'SELECTION_MATCH' })
                    : t({ key: 'SELECTION_MATCHES' })})
                  </StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
            <SelectionHeaderItem right>
              {selectedMatches.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {selectedMatches.length}
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
          </SelectionHeader>
          <SelectionWrapper>
            <SelectionColumnWrapper>
              <Scrollbar>
                {rFilter((s) => s.hit !== '', suggestions).map(
                  (suggestion, i) => {
                    const parts = suggestion.highlight.split(/<\/?em>/)
                    return (
                      <SuggestionWrapper>
                        <Suggestion
                          key={`${suggestion.hit}-${i}`}
                          isSelected={find(
                            equals(suggestion.hit),
                            selectedMatches
                          )}
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          <SuggestionText>
                            {parts.map((part, j) => {
                              if (j % 2 === 0)
                                return <span key={`${part}-${j}`}>{part}</span>
                              else return <b key={`${part}-${j}`}>{part}</b>
                            })}
                          </SuggestionText>
                        </Suggestion>
                      </SuggestionWrapper>
                    )
                  }
                )}
              </Scrollbar>
            </SelectionColumnWrapper>
            <Seperator>
              <SeperatorIcon />
            </Seperator>
            <SelectionColumnWrapper right>
              <Scrollbar>
                {selectedMatches.length > 0
                  ? selectedMatches.map((match) => (
                      <SuggestionWrapper>
                        <SuggestionWide
                          closeable
                          key={match}
                          isSelected={false}
                          onClick={() => handleSuggestionClick({ hit: match })}
                        >
                          <SuggestionTextWide>{match}</SuggestionTextWide>
                          <CloseIcon>×</CloseIcon>
                        </SuggestionWide>
                      </SuggestionWrapper>
                    ))
                  : !isEmpty(value) && (
                      <SuggestionWrapper>
                        <AllResultsText>
                          {t({
                            key: 'SELECTION_ALL_MATCHES_OF',
                            variables: { value },
                          })}
                        </AllResultsText>
                      </SuggestionWrapper>
                    )}
              </Scrollbar>
            </SelectionColumnWrapper>
          </SelectionWrapper>
        </Container>
      )}
    </I18n>
  )
}

TextInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default TextInput
