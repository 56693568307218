import React, { useCallback, useContext } from 'react'
import { AuthContext } from '@logicea/react-auth'
import { useLocation } from '@reach/router'
import { Popup } from 'semantic-ui-react'
import { I18n } from 'lib/i18n'
import { noop } from 'lib/ramda'
import { pathOr, propOr } from 'ramda'
import { DashboardsContext } from 'providers/Dashboards'
import { FilterBar } from 'components/FilterBar'
import { DashboardT, FilterT } from 'components/App/Dashboard/types'
import { DashboardActions } from 'components/DashboardActions'
import { LayoutMenu } from 'components/DropdownMenus/Layout'
import { Button } from 'components/Modals/modalStyles'
import { DashboardStateActions } from '../DashboardStateActions'
import styles, {
  Container,
  HeaderContainer,
  SubHeaderContainer,
  Column,
  Row,
  Title,
  IconButton,
  ButtonText,
  PlusIcon,
  ViewOnlyWrapper,
  ViewOnlyIcon,
  PopupContent,
  PopupText,
  RefreshButton,
} from './styles'

type Header = {
  dashboard: DashboardT
  handleFilterClick: (selectedFilter: FilterT | null, setId?: string) => void
  toggleFilterNegation: (filter: FilterT, setId?: string | undefined) => void
  handleFilterModeChange: (value: 'AND' | 'OR', setId?: string) => void
  handleFilterRemoval: (index: number, setId?: string) => void
  handleAddButtonClick: () => void
  onSave: () => void
  canDoRedo: boolean
  canDoUndo: boolean
  onUndo: () => void
  onRedo: () => void
}

export const Header = ({
  dashboard,
  handleFilterClick,
  toggleFilterNegation,
  handleFilterModeChange,
  handleFilterRemoval,
  handleAddButtonClick,
  onSave,
  onUndo,
  canDoRedo,
  canDoUndo,
  onRedo,
}: Header) => {
  const location = useLocation()
  const auth = useContext(AuthContext)
  const context = useContext(DashboardsContext) as any
  const {
    layout,
    options,
    handleLayoutSelect,
    selectedLayoutLevel,
    isViewModeTooltipOpen,
    setViewModeTooltip,
    toggleActionModal,
    handleRefreshClick,
  } = context

  const isReportSelected = location.pathname.startsWith('/reports')
  const dashboardName: string = propOr('', 'name', dashboard)
  const showFilters = options && options.filters
  const dashboardUserId = dashboard?.user_id
  const authUserId = pathOr(null, ['user', 'email'], auth)
  const belongsToUser = authUserId === dashboardUserId

  const handleCloneDashboardClick = (event: any) => {
    event.stopPropagation()
    setViewModeTooltip(false)
    toggleActionModal(noop)('clone', 'dashboard')
  }

  const onRefresh = useCallback(() => {
    handleRefreshClick()
  }, [])

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <HeaderContainer>
            <Column>
              <Title>{dashboardName}</Title>
            </Column>
            {belongsToUser && (
              <DashboardStateActions
                canDoRedo={canDoRedo}
                onRedo={onRedo}
                canDoUndo={canDoUndo}
                onUndo={onUndo}
                onSave={onSave}
              />
            )}
            {belongsToUser && !(layout === 'box' && selectedLayoutLevel > 0) && (
              <IconButton color='secondary' onClick={handleAddButtonClick}>
                <PlusIcon />
                <ButtonText>{t({ key: 'MODAL_ADD_WIDGET' })}</ButtonText>
              </IconButton>
            )}
            {belongsToUser && !isReportSelected ? (
              <DashboardActions
                dashboard={dashboard}
                style={{ minHeight: 24, minWidth: 24 }}
              />
            ) : (
              <ViewOnlyWrapper>
                <Popup
                  flowing
                  hoverable
                  style={styles.popup}
                  mouseEnterDelay={300}
                  position='left center'
                  trigger={<ViewOnlyIcon />}
                  open={isViewModeTooltipOpen}
                  onOpen={() => setViewModeTooltip(true)}
                  onClose={() => setViewModeTooltip(false)}
                >
                  <PopupContent>
                    <PopupText>
                      {t({
                        key: isReportSelected
                          ? 'TOOLTIP_REPORT_VIEW_ONLY'
                          : 'TOOLTIP_VIEW_ONLY',
                      })}
                    </PopupText>
                    <Button
                      dark
                      small
                      type='button'
                      onClick={handleCloneDashboardClick}
                    >
                      {t({
                        key: isReportSelected
                          ? 'TOOLTIP_CLONE_REPORT'
                          : 'TOOLTIP_CLONE_DASHBOARD',
                      })}
                    </Button>
                  </PopupContent>
                </Popup>
              </ViewOnlyWrapper>
            )}
          </HeaderContainer>
          {showFilters && (
            <SubHeaderContainer>
              <FilterBar
                isReport={isReportSelected}
                filters={dashboard?.filters}
                belongsToUser={belongsToUser}
                handleFilterClick={handleFilterClick}
                handleFilterRemoval={handleFilterRemoval}
                filterMode={dashboard?.filter_mode || 'AND'}
                toggleFilterNegation={toggleFilterNegation}
                handleFilterModeChange={handleFilterModeChange}
              />
              <Row>
                <RefreshButton onClick={onRefresh} />
                <LayoutMenu
                  layoutMode={layout}
                  onLayoutSelect={handleLayoutSelect}
                />
              </Row>
            </SubHeaderContainer>
          )}
        </Container>
      )}
    </I18n>
  )
}
