import React, {useState, useEffect, useContext, useRef} from 'react'
import throttle from 'lodash.throttle'
import Infinite from 'react-infinite'
import PropTypes from 'prop-types'
import find from 'ramda/src/find'
import omit from 'ramda/src/omit'
import both from 'ramda/src/both'
import head from 'ramda/src/head'
import union from 'ramda/src/union'
import isNil from 'ramda/src/isNil'
import zipObj from 'ramda/src/zipObj'
import concat from 'ramda/src/concat'
import update from 'ramda/src/update'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'
import eqProps from 'ramda/src/eqProps'
import toPairs from 'ramda/src/toPairs'
import isEmpty from 'ramda/src/isEmpty'
import unionWith from 'ramda/src/unionWith'
import findIndex from 'ramda/src/findIndex'
import difference from 'ramda/src/difference'
import mergeRight from 'ramda/src/mergeRight'
import intersection from 'ramda/src/intersection'
import symmetricDifference from 'ramda/src/symmetricDifference'
import ReactResizeDetector from 'react-resize-detector'
import { AuthContext } from '@logicea/react-auth'
import { Loader, Popup } from 'semantic-ui-react'
import {
  Container,
  FlexList,
  Header,
  BoldText,
  Statistics,
  ActionBar,
  FlagIcon,
  WarningIcon,
  HeaderRow,
  FlexListContainer,
  ListContainer,
  ListGradient,
  EmptyList,
  Row,
  FieldHeader,
  Field,
  DoubleField,
  DoubleFieldHeader,
  MessageLink,
  ThreatHeader,
  ThreatBullet,
  TagsHeader,
  TagsField,
  Checkbox,
  ChevronUp,
  ChevronDown,
  SeenIcon,
  TagIcon,
  ActionBarSeparator,
  TrashIcon,
  ActionBarRow,
  AllowlistIcon,
  BlocklistIcon,
  ActionField,
  NoActionTakenIcon,
  DateField,
  DateFieldHeader,
  ActionFieldHeader,
  PolicyAddedIcon,
  AllowBlockListFieldHeader, AllowBlockListField, RelativeLoader, PolicyIcon
} from './styles'
import { I18n } from '../../lib/i18n'
import {
  ciWithout,
  maskUserIfNeeded,
  parseDate,
  saveAction,
  Tooltip
} from '../../lib/util'
import ErrorBoundary from '../ErrorBoundary'
import messageActions from '../../requests/message'
import { REASON_ID_NAMES } from '../../static/threatLevels'
import { DashboardsContext } from '../../providers/Dashboards'
import TagEditor from "../TagEditor";
import MessageActionBar from "../MessageActionBar";
import actions from "../../requests/actions";
import {DOWNLOAD_LIMIT, MSG_PAGE_LIMIT} from "../../static/appConfig";

const { object, func, array, bool, number, string } = PropTypes

const LIST_ELEMENT_HEIGHT = 40

const SeenAction = ({hit, onMessageClick, t}) => {
  return(
    <React.Fragment>
      <SeenIcon
        data-tip
        data-for={"seenAction-" + hit.id}
        onClick={() => onMessageClick(hit)}
        highlighted={hit.viewedByMe ? "true" : "false"}
      />
      <Tooltip bottom id={"seenAction-" + hit.id}>
        <span><div dangerouslySetInnerHTML={{__html:hit?.lastViewed}}/></span>
      </Tooltip>
    </React.Fragment>
  )
}

const Remediated = ({hit, onMessageClick, t}) => {
  return(
    <React.Fragment>
      <TrashIcon
        data-tip
        data-for={"remediated-" + hit.id}
        onClick={() => onMessageClick(hit)}
        viewonly={"true"}
      />
      <Tooltip bottom id={"remediated-" + hit.id}>
        <span><div dangerouslySetInnerHTML={{__html:hit?.lastRemediated}}/></span>
      </Tooltip>
    </React.Fragment>
  )
}

const NoActionTaken = ({hit, onMessageClick, t}) => {
  return(
    <React.Fragment>
      <NoActionTakenIcon
        data-tip
        data-for={"noActionTaken-" + hit.id}
        onClick={() => onMessageClick(hit)}
        viewonly="true"
      />
      <Tooltip bottom id={"noActionTaken-" + hit.id}>
        <span><div dangerouslySetInnerHTML={{__html:hit?.lastNoAction}}/></span>
      </Tooltip>
    </React.Fragment>
  )
}

const AllowListEntries = ({hit, entries, onMessageClick}) => {
  return(
    <React.Fragment>
      <AllowlistIcon
        data-tip
        data-for={`allowListEntries-${hit.id}`}
        onClick={() => onMessageClick(hit)}
        style={{color: 'gray'}}
      />
      <Tooltip bottom id={`allowListEntries-${hit.id}`}>
        <span><div>{entries.map((entry, indx) => {
          return <div key={indx} dangerouslySetInnerHTML={{__html:entry.text ?? ""}}/>
        })}</div></span>
      </Tooltip>
    </React.Fragment>
  )
}

const BlockListEntries = ({hit, entries, onMessageClick}) => {
  return(
    <React.Fragment>
      <BlocklistIcon
        data-tip
        data-for={`blockListEntries-${hit.id}`}
        onClick={() => onMessageClick(hit)}
        style={{color: 'gray'}}
      />
      <Tooltip bottom id={`blockListEntries-${hit.id}`}>
        <span><div>{entries.map((entry, indx) => {
          return <div key={indx} dangerouslySetInnerHTML={{__html:entry.text ?? ""}}/>
        })}</div></span>
      </Tooltip>
    </React.Fragment>
  )
}

const PolicyEntries = ({hit, entries, onMessageClick}) => {
  return(
    <React.Fragment>
      <PolicyIcon
        data-tip
        data-for={`policyEntries-${hit.id}`}
        onClick={() => onMessageClick(hit)}
        style={{color: 'gray'}}
      />
      <Tooltip bottom id={`policyEntries-${hit.id}`}>
        <span><div>{entries.map((entry, indx) => {
          return <div key={indx} dangerouslySetInnerHTML={{__html:entry.text ?? ""}}/>
        })}</div></span>
      </Tooltip>
    </React.Fragment>
  )
}

const Allowlisted = ({hit, onMessageClick, t}) => {
  return(
    <React.Fragment>
      <AllowlistIcon
        data-tip
        data-for={"allowlisted-" + hit.id}
        onClick={() => onMessageClick(hit)}
        style={{color: 'gray'}}
      />
      <Tooltip bottom id={"allowlisted-" + hit.id}>
        <span><div dangerouslySetInnerHTML={{__html:hit?.lastAllowListed}}/></span>
      </Tooltip>
    </React.Fragment>
  )
}

const PolicyAdded = ({hit, onMessageClick, t}) => {
  return(
    <React.Fragment>
      <PolicyAddedIcon
        data-tip
        data-for={"policyAdded-" + hit.id}
        onClick={() => onMessageClick(hit)}
        style={{color: 'gray'}}
      />
      <Tooltip bottom id={"policyAdded-" + hit.id}>
        <span><div dangerouslySetInnerHTML={{__html:hit?.lastPolicyAdd}}/></span>
      </Tooltip>
    </React.Fragment>
  )
}

const Blocklisted = ({hit, onMessageClick, t}) => {
  return(
    <React.Fragment>
      <BlocklistIcon
        data-tip
        data-for={"blocklisted-" + hit.id}
        onClick={() => onMessageClick(hit)}
        style={{color: 'gray'}}
      />
      <Tooltip bottom id={"blocklisted-" + hit.id}>
        <span><div dangerouslySetInnerHTML={{__html:hit?.lastBlockListed}}/></span>
      </Tooltip>
    </React.Fragment>
  )
}

// <Link to={`messages/${hit.id}`}>{hit.subject}</Link>
const SearchHit = ({ hit, onMessageClick, showActions, onCheckboxChange, selectedMessages }) => (
  <I18n>{({ locale, t }) => (
    <Row>
      {showActions && (
        <Checkbox
          checked={findIndex(propEq('id', hit.id), selectedMessages) > -1}
          onChange={() => onCheckboxChange(hit.id)}
        />
      )}
      <Popup
        basic
        style={{ textAlign: 'center' }}
        header={t({
          key: 'THREAT_LEVEL',
          variables: {
            level: parseInt(hit.threatLevel, 10),
          },
        })}
        content={!isEmpty(hit.reasonIds) && hit.reasonIds && hit.reasonIds.map(rid => <div>{REASON_ID_NAMES(rid)}</div>)} // TODO: use i18n
        trigger={<ThreatBullet onClick={() => onMessageClick(hit)} threat={hit.threatLevel} />}
      />
      <TagsField onClick={() => onMessageClick(hit)}>
        {hit.tags && difference(hit.tags, ['READ']).length > 0 && <TagIcon />}
      </TagsField>
      <Field onClick={() => onMessageClick(hit)}>{hit.fromEmail}</Field>
      <Field onClick={() => onMessageClick(hit)}>{hit.email}</Field>
      <DoubleField onClick={() => onMessageClick(hit)}>
        <MessageLink>{hit.subject}</MessageLink>
      </DoubleField>
      <AllowBlockListField onClick={() => onMessageClick(hit)}>
        {hit && hit.allowListEntries && hit.allowListEntries.length > 0 && <AllowListEntries hit={hit} entries={hit.allowListEntries} onMessageClick={onMessageClick} />}
        {hit && hit.blockListEntries && hit.blockListEntries.length > 0 && <BlockListEntries hit={hit} entries={hit.blockListEntries} onMessageClick={onMessageClick} />}
        {hit && hit.policyAppliedEntries && hit.policyAppliedEntries.length > 0 && <PolicyEntries hit={hit} entries={hit.policyAppliedEntries} onMessageClick={onMessageClick} />}
      </AllowBlockListField>
      <ActionField onClick={() => onMessageClick(hit)}>
        {hit && hit.viewed && <SeenAction hit={hit} onMessageClick={onMessageClick} t={t} />}
        {hit && hit.noAction && <NoActionTaken hit={hit} onMessageClick={onMessageClick} t={t} />}
        {hit && hit.remediated && <Remediated hit={hit} onMessageClick={onMessageClick} t={t} />}
        {hit && hit.allowlisted && <Allowlisted hit={hit} onMessageClick={onMessageClick} t={t} />}
        {hit && hit.blocklisted && <Blocklisted hit={hit} onMessageClick={onMessageClick} t={t} />}
        {hit && hit.policyAdded && <PolicyAdded hit={hit} onMessageClick={onMessageClick} t={t} />}
      </ActionField>
      <DateField onClick={() => onMessageClick(hit)}>
        {isNil(hit.processedDate)
          ? ''
          : t({ key: 'SHORT_DATE_TIME', variables: { locale, date: new Date(parseDate(hit.processedDate)) } })}
      </DateField>
    </Row>
  )}
  </I18n>
)

const renderHits = (hits, actionHistory, allowBlockList, onMessageClick, showActions, selectedMessages,
                    handleCheckboxChange, authEmail, filters, t) => {

  let filtered = []
  hits.forEach(hit => {
    if (allowBlockList && allowBlockList.hasOwnProperty(hit.id)) {
      if (allowBlockList[hit.id].allowlist_entries?.length)
        hit.allowListEntries = allowBlockList[hit.id].allowlist_entries
      if (allowBlockList && allowBlockList[hit.id].blocklist_entries?.length)
        hit.blockListEntries = allowBlockList[hit.id].blocklist_entries
      if (allowBlockList && allowBlockList[hit.id].policy_entries?.length)
        hit.policyAppliedEntries = allowBlockList[hit.id].policy_entries
    }

    if (actionHistory[hit.id]?.length > 0) {
      // Sort user actions by timestamp (latest last)
      let sortedUserActions = actionHistory[hit.id].sort((a,b) => (a.timestamp < b.timestamp) ? -1 : 1)

      sortedUserActions.forEach(action => {
        if (action.type === "allowlist-add") {
          hit.allowlisted = true
          hit.lastAllowListed = action.details + " by <b>" + maskUserIfNeeded(action.actionedBy, authEmail) + "</b> on " + new Date(parseDate(action.timestamp))
        } else if (action.type === "blocklist-add") {
          hit.blocklisted = true
          hit.lastBlockListed = action.details + " by <b>" + maskUserIfNeeded(action.actionedBy, authEmail) + "</b> on " + new Date(parseDate(action.timestamp))
        } else if (action.type === "viewed") {
          hit.viewed = true
          hit.viewedByMe = action.actionedBy === authEmail
          hit.lastViewed = t({key:'ACTION_ANALYSIS_LAST_VIEWED'}) + " <b>" + (hit.viewedByMe ? t({key:'ME'}) : maskUserIfNeeded(action.actionedBy, authEmail)) + "</b> " + t({key:'ON'}) + " " + new Date(parseDate(action.timestamp))
        } else if (action.type === "msg-remediated") {
          hit.remediated = true
          hit.lastRemediated = action.details + " by " + maskUserIfNeeded(action.actionedBy, authEmail) + " " + t({key:'ON'}) + " " + new Date(parseDate(action.timestamp))
        } else if (action.type === "no-action") {
          hit.noAction = true
          hit.lastNoAction = "<b>" + maskUserIfNeeded(action.actionedBy, authEmail) + "</b> " + t({key:'ACTION_MARKED_TAKE_NO_ACTION_ON'}) + " " + new Date(parseDate(action.timestamp))
        } else if (action.type === "needs-action") {
          hit.needsAction = true
          hit.lastNeedsAction = "<b>" + maskUserIfNeeded(action.actionedBy, authEmail) + "</b> " + t({key:'ACTION_REVERSED_TAKE_NO_ACTION_ON'}) + " " + new Date(parseDate(action.timestamp))
          if (hit.noAction) {
            delete hit.lastNoAction
            delete hit.noAction
          }
        } else if (action.type === "policy-add") {
          hit.policyAdded = true
          hit.lastPolicyAdd = action.details + " " + t({key:'BY'}) + " " + maskUserIfNeeded(action.actionedBy, authEmail) + " " + t({key:'ON'}) + " " + new Date(parseDate(action.timestamp))
        }
      })
    }

    if ((hit.allowlisted && filters.filterAllowListedActions) ||
      (hit.blocklisted && filters.filterBlockListedActions) ||
      (hit.remediated && filters.filterRemediatedActions) ||
      (hit.viewed && filters.filterViewedActions) ||
      (hit.noAction && filters.filterNoActions) ||
      (hit.allowListEntries && filters.filterExistingAllowEntry) ||
      (hit.blockListEntries && filters.filterExistingBlockEntry) ||
      (hit.policyAppliedEntries && filters.filterPolicyAppliedActions))
      return

    filtered.push(hit)
  })

  return (
    filtered.map(hit => {

      return (
        <SearchHit
          showActions={showActions}
          key={`${hit.id}-${hit.index}`}
          hit={hit}
          onMessageClick={onMessageClick}
          onCheckboxChange={handleCheckboxChange}
          selectedMessages={selectedMessages}
        />
      )
    })
  )
}

const MessageList = ({
  dashboard,
  isTab,
  expanded,
  setFilters,
  setFilterMode,
  timestamp,
  downloadAll,
  onCellClick,
  onFetch,
  belongsToUser,
  filterViewedActions,
  filterNoActions,
  filterRemediatedActions,
  filterAllowListedActions,
  filterBlockListedActions,
  filterPolicyAppliedActions,
  filterExistingAllowEntry,
  filterExistingBlockEntry,
  filterExistingPolicyEntry,
}) => {
  const [selectedSort, setSelectedSort] = useState('processed_date')
  const [ascOrder, setAscOrder] = useState(false)
  const [activePage, setActivePage] = useState(0)
  const [sort, setSort] = useState([{ processed_date: 'DESC' }])
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(1)
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false)
  const [hitsAcc, setHitsAcc] = useState([])
  const [actionHistory, setActionHistory] = useState({})
  const [allowBlockList, setAllowBlockList] = useState({})
  const [allowBlockListUpdatesPending, setAllowBlockListUpdatesPending] = useState(false)
  const [results, setResults] = useState({})
  const [noHits, setNoHits] = useState(false)
  const [dashboardState, setDashboardState] = useState(null)
  const [dashboardId, setDashboardId] = useState(null)
  const [dashboardFilters, setDashboardFilters] = useState([])
  const [selectedMessages, setSelectedMessages] = useState([])
  const [isGradientVisible, setIsGradientVisible] = useState(true)
  const [dashboardFilterMode, setDashboardFilterMode] = useState('AND')
  const [commonTags, setCommonTags] = useState([])
  const [isTakeNoAction, setIsTakeNoAction] = useState()

  const context = useContext(DashboardsContext)
  const {
    setViewModeTooltip,
    taggedMessage,
    onMessageTag,
    msgListActionedData,
    addMsgActionedData,
    clearMsgListActionedData,
  } = context

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  const allowBlockReqCounter = useRef()
  const updateAllowBlockListEntries = (newEntries => {
    let lists = {...allowBlockList}
    Object.keys(newEntries).forEach(key => {
      lists[key] = newEntries[key]
    })
    setAllowBlockList(lists)
  })

  const getAllowBlockListEntries = async (hits) => {
    let messages = []
    hits.forEach(hit => {
      messages.push(hit.id)
    })

    if (messages.length) {
      if (!allowBlockReqCounter.current)
        allowBlockReqCounter.current = 1
      else
        ++allowBlockReqCounter.current
      let lists = await actions.getMessagesAllowBlockListEntriesAction({
        messages: messages
      }, auth.tokens?.accessToken)
      updateAllowBlockListEntries(lists)
      --allowBlockReqCounter.current
      setAllowBlockListUpdatesPending(allowBlockReqCounter.current !== 0)
    }
  }

  const updateNoActionIconState = () => {
    let takeNoAction = 0
    let needsAction = 0
    if (selectedMessages.length) {
      selectedMessages.forEach(msg => {
        if (msg.id in actionHistory) {
          let sortedUserActions = actionHistory[msg.id].sort((a,b) => (a.timestamp < b.timestamp) ? -1 : 1)
          let currentNoActionState = false
          sortedUserActions.forEach(action => {
            if (action.type === 'no-action')
              currentNoActionState = true
            else if (action.type === 'needs-action')
              currentNoActionState = false
          })
          if (currentNoActionState) ++takeNoAction
          else ++needsAction
        } else
          ++needsAction
      })
    }
    if (takeNoAction > 0 && needsAction > 0)
      setIsTakeNoAction(undefined)
    else
      setIsTakeNoAction(takeNoAction > needsAction)
  }

  useEffect(() => {
    msgListActionedData.forEach(actionData => {
      onActioned(actionData.action, actionData.messageId, actionData.details)
    })
    clearMsgListActionedData()
    updateNoActionIconState()
  }, [msgListActionedData])

  const updateAllowBlockList = (action, msgId, details) => {
    if (action === 'allowlist-add' || action === 'blocklist-add' || action === 'policy-add') {
      if (!(msgId in allowBlockList))
        allowBlockList[msgId] = {allowlist_entries: [], blocklist_entries: [], policy_entries: []}

      switch (action) {
        case 'allowlist-add':
          allowBlockList[msgId].allowlist_entries.push({text: details})
          break
        case 'blocklist-add':
          allowBlockList[msgId].blocklist_entries.push({text: details})
          break
        case 'policy-add':
          allowBlockList[msgId].policy_entries.push({text: details})
          break
        default:
          break
      }

      return true
    }

    return false
  }

  const onActioned = (action, msgId, details) => {
    if (!(msgId in actionHistory))
      actionHistory[msgId] = []
    let actionList = actionHistory[msgId]
    actionList.push({
      actionedBy: auth?.user?.email,
      timestamp: Date.now()/1000,
      type: action,
      details: details
    })

    if (updateAllowBlockList(action, msgId, details)) {
      setAllowBlockList({...allowBlockList})
      // Kick off a refresh of action lists
      setAllowBlockListUpdatesPending(true)
      getAllowBlockListEntries(hitsAcc)
    }

    // Force messages to re-render with latest action data
    setActionHistory({...actionHistory})

  }

  useEffect(() => {
    setDashboardId(propOr('', 'id', dashboard))
    setDashboardFilters(propOr(null, 'filters', dashboard))
    setDashboardFilterMode(propOr('AND', 'filter_mode', dashboard))
  }, [dashboard])

  useEffect(() => {
    if (isNil(dashboardState)) setDashboardState(dashboardId)
    else setDashboardState(null)
  }, [dashboardId])

  const forceMessageListRefresh = () => {
    setActivePage(0)
    setResults({})
    setHitsAcc([])
    setNoHits(false)
  }

  useEffect(() => {
    // Deselect all messages when filtering is changed
    let selected = selectedMessages
    selected.length = 0
    setSelectedMessages(selected)
  }, [filterViewedActions,
    filterRemediatedActions, filterNoActions, filterAllowListedActions, filterBlockListedActions,
    filterExistingAllowEntry, filterExistingBlockEntry])

  useEffect(() => {
    forceMessageListRefresh()
    let selected = selectedMessages
    selected.length = 0
    setSelectedMessages(selected)
  }, [dashboardFilters, setFilters, dashboardFilterMode, setFilterMode])

  useEffect(() => {
    forceMessageListRefresh()
  }, [sort, timestamp])


  useEffect(() => {
    let hits = []
    let page = 0
    const { total } = results

    async function fetchData() {
      while (hits.length < Math.min(total, DOWNLOAD_LIMIT) && page < MSG_PAGE_LIMIT) {
        const response = await messageActions.fetchAllMessagesAction( // eslint-disable-line no-await-in-loop
          { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode },
          {
            page,
            sort: sort.map(s => zipObj(['field', 'order'], head(toPairs(s)))),
            realm,
            token: tokens.accessToken,
          }
        )
        page += 1
        hits = concat(hits, response.hits)
      }
      // Allow ID for inky team members, for exporting to CSV
      onFetch(hits.map(h => mergeRight(
        omit(auth?.lastLoginEmail?.endsWith('@inky.com') ? ['index', 'userActions'] : ['id', 'index', 'userActions'], h),
        { reasonNames: h.reasonIds.map(rid => REASON_ID_NAMES(rid)) } // TODO: use i18n
      )))
    }

    if (downloadAll) fetchData()
  }, [downloadAll, dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, sort, timestamp])

  useEffect(() => {
    if (isNil(taggedMessage) || isNil(handleMessageTag)) return
    const { id, newTags } = taggedMessage
    handleMessageTag(id)(newTags)
  }, [taggedMessage])

  useEffect(() => {

    let tags = []
    if (selectedMessages.length) {
      tags = propOr([], 'tags', selectedMessages[0])

      selectedMessages.forEach(msg => {
        tags = intersection(propOr([], 'tags', msg), tags)
      })
    }
    setCommonTags(tags)
    updateNoActionIconState()

  }, [selectedMessages])

  if (isNil(dashboardState)) return null

  // TODO: this function is throttled to prevent multiple calls
  // due to scroll momentum/inertia
  // TOFIX: this throws an 'update on an unmounted component' warning
  // when the component is unmounted
  const handleInfiniteLoad = throttle(async () => {
    setIsInfiniteLoading(true)
    setAllowBlockListUpdatesPending(true)
    const response = await messageActions.findMessagesAction(
      { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode },
      {
        page: activePage,
        sort: sort.map(s => zipObj(['field', 'order'], head(toPairs(s)))),
        realm,
        token: tokens.accessToken,
      }
    )
    setActivePage(activePage + 1)
    let filteredHits = []
    let actions = {...actionHistory}
    response.hits.forEach(hit => {
      if (hit.userActions?.length)
        actions[hit.id] = hit.userActions
      filteredHits.push(hit)
    })
    setResults(response)
    const hits = concat(hitsAcc, filteredHits)
    getAllowBlockListEntries(filteredHits)

    setHitsAcc(hits)
    setActionHistory(actions)
    setNoHits(isEmpty(hits))
    setIsInfiniteLoading(false)
  }, 1000, { leading: true, trailing: false })

  const onResize = (width, height) => {
    setListWidth(width)
    setListHeight(height - LIST_ELEMENT_HEIGHT)
  }

  const handleSortClick = name => {
    setActivePage(1)
    setSelectedMessages([])
    if (selectedSort === name) {
      setAscOrder(!ascOrder)
      setSort([{ [name]: ascOrder ? 'DESC' : 'ASC' }])
    } else {
      setAscOrder(true)
      setSelectedSort(name)
      setSort([{ [name]: 'ASC' }])
    }
  }

  const handleActionClick = (tags, action) => {
    const updatedMessages = selectedMessages.map(message => {
      const prevTags = propOr([], 'tags', message)
      let newTags
      switch (action) {
        case 'add':
          newTags = union(prevTags, tags)
          break
        case 'remove':
          newTags = ciWithout(tags, prevTags)
          break
        case 'custom':
          newTags = intersection(['IMPORTANT', 'READ', 'FLAG'], intersection(['important', 'read', 'flag'], prevTags))
          break
        default:
          newTags = []
      }

      onMessageTag({ id: message.id, index: message.index, newTags })
      messageActions.updateMessageAction(
        { id: message.id, index: message.index, tags: newTags },
        { realm, token: tokens.accessToken }
      )

      return mergeRight(message, { tags: newTags })
    })

    setSelectedMessages(updatedMessages)
    setHitsAcc(unionWith(both(eqProps('id'), eqProps('index')), updatedMessages, hitsAcc))
  }

  const handleSelectAllChange = () => {
    if (selectedMessages.length === hitsAcc.length) setSelectedMessages([])
    else setSelectedMessages(hitsAcc)
  }

  const handleCheckboxChange = messageId =>
    setSelectedMessages(symmetricDifference(selectedMessages, [find(propEq('id', messageId), hitsAcc)]))

  const handleMessageTag = messageId => newTags => {
    const index = findIndex(propEq('id', messageId), hitsAcc)
    if (index > -1) setHitsAcc(update(index, mergeRight(hitsAcc[index], { tags: newTags }), hitsAcc))
  }

  const onMessageClick = message => {
    if (belongsToUser) {
      try {
        let s3Key = atob(message.id)
        let toks = s3Key.split('/')
        saveAction("viewed", message.id, toks[0], auth)
        addMsgActionedData([{action: 'viewed', messageId: message.id}])
      } catch (e) {
        console.log("onMessageClick:atob:Exception", e)
      }
      const { index, id, subject, threatLevel } = message

      onCellClick([], { index, id, subject, threatLevel })
    } else {
      setViewModeTooltip(true)
    }
  }

  const handleListScroll = node => {
    if (node.scrollTop + node.clientHeight >= node.scrollHeight && isGradientVisible) setIsGradientVisible(false)
    else if (node.scrollTop + node.clientHeight < node.scrollHeight && !isGradientVisible) setIsGradientVisible(true)
  }

  const { total, networkTook, elasticsearchTook } = results
  const totalPages = Math.ceil(total / 100)
  const showActions = (isTab || expanded) && belongsToUser
  const showTechDetails = false

  const handleAddTag = (newTag) => {

    let tagLower = newTag.toLowerCase()
    let tagExists = false
    commonTags.forEach(tag => { if (tag.toLowerCase() === tagLower) tagExists = true })

    // Work around Tagify calling this method when tags are initialized
    if (!newTag || tagExists)
      return

    setCommonTags(union(commonTags, [tagLower]))
    handleActionClick([tagLower], 'add');
  }

  const handleDeleteTag = (oldTag) => {
    setCommonTags(ciWithout([oldTag], commonTags))
    handleActionClick([oldTag], 'remove');
  }

  const handleFlagClick = (tagName, addIt) => {
    if (addIt)
      handleAddTag(tagName)
    else
      handleDeleteTag(tagName)
    handleActionClick([tagName], addIt ? 'add' : 'remove')
  }

  const isFlagged = commonTags.includes('flag') || commonTags.includes('FLAG')
  const isImportant = commonTags.includes('important') || commonTags.includes('IMPORTANT')

  return (
    <I18n>{({ t }) => (
      <Container>
        {selectedMessages.length > 0 && (
        <Header>
          <ActionBar>
            <ActionBarRow>
            <WarningIcon
              data-tip
              data-for="flagImportant"
              onClick={() => handleFlagClick('important', !isImportant)}
              isActive={isImportant}
            />
            <Tooltip bottom id="flagImportant">
              <span>{t({ key: isImportant ? 'TOOLTIP_UNFLAG_IMPORTANT' : 'TOOLTIP_FLAG_IMPORTANT' }) } </span>
            </Tooltip>
            <FlagIcon
              data-tip
              data-for="flag"
              onClick={() => handleFlagClick('flag', !isFlagged)}
              isFlagged={isFlagged}
            />
            <Tooltip bottom id="flag">
              <span>{t({ key: isFlagged ? 'TOOLTIP_UNFLAG' : 'TOOLTIP_FLAG' }) } </span>
            </Tooltip>
            <ActionBarSeparator>|</ActionBarSeparator>
            <MessageActionBar isMultiSelect selectedMessages={selectedMessages} isTakeNoAction={isTakeNoAction}/>
            <ActionBarSeparator style={{paddingRight:"2px"}}>|</ActionBarSeparator>
            <TagEditor tags={commonTags} handleAddTag={handleAddTag} handleDeleteTag={handleDeleteTag} />
            </ActionBarRow>
          </ActionBar>
          <BoldText>
            {t({
              key: 'MESSAGE_LIST_SELECTED_MESSAGES',
              variables: {
                selectedMessages: selectedMessages.length,
              } })}
          </BoldText>
          {showTechDetails && (
            <React.Fragment>
              <BoldText>
                {t({
                  key: 'MESSAGE_LIST_TOTAL_PAGES',
                  variables: {
                    total,
                    activePage,
                    totalPages,
                  } })}
              </BoldText>
              <Statistics>
                {t({
                  key: 'MESSAGE_LIST_STATISTICS',
                  variables: {
                    elasticsearchTook,
                    networkTook: Math.round(networkTook),
                  } })}
              </Statistics>
            </React.Fragment>
          )}
        </Header>
        )}
        <FlexList>
          <HeaderRow>
            {showActions && (
            <Checkbox
              checked={selectedMessages.length === hitsAcc.length && hitsAcc.length > 0}
              onChange={handleSelectAllChange}
            />
            )}
            <ThreatHeader
              active={selectedSort === 'threat_level'}
              onClick={() => handleSortClick('threat_level')}
            >
              <div>{t({ key: 'MESSAGE_LIST_THREAT_HEADER' })}</div>
              {selectedSort === 'threat_level' && (
                ascOrder ? <ChevronUp /> : <ChevronDown />
              )}
            </ThreatHeader>
            <TagsHeader>
              <div>{t({ key: 'MESSAGE_LIST_TAGS_HEADER' })}</div>
              {selectedSort === 'tags' && (
                ascOrder ? <ChevronUp /> : <ChevronDown />
              )}
            </TagsHeader>
            <FieldHeader
              active={selectedSort === 'from_domain'}
              onClick={() => handleSortClick('from_domain')}
            >
              <div>{t({ key: 'MESSAGE_LIST_FROM_HEADER' })}</div>
              {selectedSort === 'from_domain' && (
                ascOrder ? <ChevronUp /> : <ChevronDown />
              )}
            </FieldHeader>
            <FieldHeader
              active={selectedSort === 'email'}
              onClick={() => handleSortClick('email')}
            >
              <div>{t({ key: 'MESSAGE_LIST_TO_HEADER' })}</div>
              {selectedSort === 'email' && (
                ascOrder ? <ChevronUp /> : <ChevronDown />
              )}
            </FieldHeader>
            <DoubleFieldHeader
              active={selectedSort === 'subject.raw'}
              onClick={() => handleSortClick('subject.raw')}
            >
              <div>{t({ key: 'MESSAGE_LIST_SUBJECT_HEADER' })}</div>
              {selectedSort === 'subject.raw' && (
                ascOrder ? <ChevronUp /> : <ChevronDown />
              )}
            </DoubleFieldHeader>
            <AllowBlockListFieldHeader
              data-for="listsTip"
              data-tip
            >
              {allowBlockListUpdatesPending && <RelativeLoader leftoffset={-28} />}
              {t({ key: 'MESSAGE_LIST_ALLOW_BLOCK_LIST_HEADER' })}
              <Tooltip bottom id="listsTip">
                <span>Mouse over icons to see list entries that would apply to this message</span>
              </Tooltip>
            </AllowBlockListFieldHeader>
            <ActionFieldHeader
              data-for="actionHistoryTip"
              data-tip
            >
              {t({ key: 'MESSAGE_LIST_ACTION_TAKEN_HEADER' })}
              <Tooltip bottom id="actionHistoryTip">
                <span>Mouse over icons to see actions performed on this message</span>
              </Tooltip>
            </ActionFieldHeader>
            <DateFieldHeader
              active={selectedSort === 'processed_date'}
              onClick={() => handleSortClick('processed_date')}
            >
              {t({ key: 'MESSAGE_LIST_DATE_HEADER' })}
              {selectedSort === 'processed_date' && (
                ascOrder ? <ChevronUp /> : <ChevronDown />
              )}
            </DateFieldHeader>
          </HeaderRow>
          <FlexListContainer>
            <ListContainer
              width={listWidth}
              height={listHeight + LIST_ELEMENT_HEIGHT}
            >
              {listHeight > 0 && (
                <ErrorBoundary fallback={(
                  <Infinite
                    elementHeight={LIST_ELEMENT_HEIGHT}
                    containerHeight={listHeight}
                    handleScroll={handleListScroll}
                    infiniteLoadBeginEdgeOffset={!isNil(total) && hitsAcc.length >= total
                      ? undefined : LIST_ELEMENT_HEIGHT * 10}
                    onInfiniteLoad={handleInfiniteLoad}
                    isInfiniteLoading={isInfiniteLoading}
                    loadingSpinnerDelegate={<Loader active />}
                  >
                    {noHits
                      ? <EmptyList>{t({ key: 'MESSAGE_LIST_EMPTY_MESSAGE' })}</EmptyList>
                      : renderHits(
                        hitsAcc,
                        actionHistory,
                        allowBlockList,
                        onMessageClick,
                        showActions,
                        selectedMessages,
                        handleCheckboxChange,
                        auth?.user?.email,
                        {
                          filterNoActions,
                          filterViewedActions,
                          filterAllowListedActions,
                          filterBlockListedActions,
                          filterRemediatedActions,
                          filterPolicyAppliedActions,
                          filterExistingAllowEntry,
                          filterExistingBlockEntry,
                          filterExistingPolicyEntry,
                        },
                        t,
                      )}
                  </Infinite>
                  )}
                >
                  <Infinite
                    elementHeight={LIST_ELEMENT_HEIGHT}
                    containerHeight={listHeight}
                    handleScroll={handleListScroll}
                    infiniteLoadBeginEdgeOffset={!isNil(total) && hitsAcc.length >= total
                      ? undefined : LIST_ELEMENT_HEIGHT * 10}
                    onInfiniteLoad={handleInfiniteLoad}
                    isInfiniteLoading={isInfiniteLoading}
                    loadingSpinnerDelegate={<Loader active />}
                  >
                    {noHits
                      ? <EmptyList>{t({ key: 'MESSAGE_LIST_EMPTY_MESSAGE' })}</EmptyList>
                      : renderHits(
                        hitsAcc,
                        actionHistory,
                        allowBlockList,
                        onMessageClick,
                        showActions,
                        selectedMessages,
                        handleCheckboxChange,
                        auth?.user?.email,
                        {
                          filterNoActions,
                          filterViewedActions,
                          filterAllowListedActions,
                          filterBlockListedActions,
                          filterRemediatedActions,
                          filterPolicyAppliedActions,
                          filterExistingAllowEntry,
                          filterExistingBlockEntry,
                          filterExistingPolicyEntry,
                        },
                        t,
                      )}
                  </Infinite>
                </ErrorBoundary>
              )}
            </ListContainer>
            <ListGradient visible={isGradientVisible} />
          </FlexListContainer>
          <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        </FlexList>
      </Container>
    )}
    </I18n>
  )
}

SearchHit.propTypes = {
  hit: object,
  showActions: bool,
  onMessageClick: func,
  onCheckboxChange: func,
  selectedMessages: array,
}

MessageList.propTypes = {
  dashboard: object,
  isTab: bool,
  expanded: bool,
  setFilters: array,
  setFilterMode: string,
  timestamp: number,
  downloadAll: bool,
  onCellClick: func,
  onFetch: func,
  belongsToUser: bool,
  filterNoActions: func,
  filterViewedActions: func,
  filterRemediatedActions: func,
  filterAllowListedActions: func,
  filterBlockListedActions: func,
  filterExistingAllowEntry: func,
  filterExistingBlockEntry: func,
  filterExistingPolicyEntry: func,
  filterPolicyAppliedActions: func,
}

export default MessageList
