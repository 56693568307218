import styled from 'styled-components'
import { Loader } from 'semantic-ui-react'
import {
  WHITE,
  BORDER_LIGHT,
  INPUT_TEXT,
  LINK_TEXT,
  PRIMARY_DARK,
} from '../../../static/colors'
import keyIcon from '../../../assets/key/key.png'
import mailIcon from '../../../assets/mail/mail.png'
import microsoftIcon from '../../../assets/microsoft.png'
import googleIcon from '../../../assets/google.png'

export const FormField = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 55px;
  border: 3px solid ${BORDER_LIGHT};
  border-radius: 7px;
  margin: 9px;
`

export const Input = styled.input`
  width: 100%;
  height: 48px;
  border: none;
  color: ${INPUT_TEXT};
  font-family: Lato;
  font-size: 25px;
  line-height: 30px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${BORDER_LIGHT};
    opacity: 1;
  }
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SocialActions = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LinkText = styled.div`
  color: ${LINK_TEXT};
  font-family: Lato;
  font-size: 19px;
  line-height: 23px;
  margin-left: 16px;
  padding-top: 8px;

  &:hover {
    cursor: pointer;
  }
`

export const Button = styled.button`
  margin-top: 9px;
  align-self: flex-end;
  height: 46px;
  width: 170px;
  border-radius: 7px;
  background: linear-gradient(129.69deg, #004F9F 0%, #002766 100%);
`

export const SocialButton = styled.button`
  align-self: center;
  height: 46px;
  width: 280px;
  border-radius: 7px;
  background: ${props => (props.inverted
    ? WHITE
    : 'linear-gradient(129.69deg, #004F9F 0%, #002766 100%)')};
  margin: 10px 0px;

  &:hover {
    cursor: pointer;
  }
`

export const ButtonText = styled.div`
  color: ${props => (props.inverted ? PRIMARY_DARK : WHITE)};
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  position: relative;
`

export const MicrosoftIcon = styled.img.attrs({
  src: microsoftIcon,
  alt: 'microsoft',
})`
  position: absolute;
  left: 8px;
`

export const GoogleIcon = styled.img.attrs({
  src: googleIcon,
  alt: 'google',
})`
  position: absolute;
  left: 6px;
  width: 24px;
`

export const Loading = styled(Loader).attrs({
  active: true,
  inline: true,
  size: 'small',
})``

export const MailIcon = styled.img.attrs({
  src: mailIcon,
  alt: 'email',
})`
  width: 64px;
  height: 48px;
  padding: 14px 14px 12px 16px;
  opacity: 0.3;
`

export const KeyIcon = styled.img.attrs({
  src: keyIcon,
  alt: 'password',
})`
  width: 64px;
  height: 48px;
  padding: 10px 16px 8px 18px;
  opacity: 0.3;
`

export const Error = styled.div`
  color: red;
  margin: 10px;
`
