import styled from 'styled-components'
import cloneIcon from '../../../../assets/clone/clone.png'
import { colors, Body1PrimaryDarkRegular, spacing } from 'static/theme'

const submenuItemStyles = `
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background-color 0.5s;
  padding: ${spacing.xs} 0rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const DashboardName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SubmenuItem = styled(Body1PrimaryDarkRegular)<{
  selected: boolean
}>`
  ${submenuItemStyles}
  ${(props) => (props.selected ? `color: ${colors.white}` : '')};

  &:hover {
    cursor: pointer;
    color: ${colors.white};
  }
`

export const DashboardIcons = styled.div`
  flex-direction: row;
  display: none;

  ${SubmenuItem}:hover & {
    display: flex;
  }
`

export const CloneDashboardIcon = styled.img.attrs({
  src: cloneIcon,
  alt: 'clone',
})`
  width: 20px;
  height: 18px;
  padding: 0px 4px 2px 2px;
  margin-right: 2px;
  filter: contrast(100) invert(100);
  cursor: pointer;
`
