import React from 'react'
import { TextFieldProps } from '@material-ui/core'
import { TextField } from 'components/TextField'
import { MaterialUIAutocompleteInput } from './styles'

type CustomAutocompleteInputProps = TextFieldProps & {
  suggestions: Array<string>
  freeSolo: boolean
}

export const AutocompleteInput = ({
  suggestions,
  freeSolo = false,
  key,
  ...rest
}: CustomAutocompleteInputProps) => {
  return (
    <MaterialUIAutocompleteInput
      options={suggestions}
      freeSolo={freeSolo}
      key={key}
      // getOptionLabel={(option) => option}
      renderInput={(params) => <TextField {...params} {...rest} />}
    />
  )
}
