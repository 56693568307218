import React, {useContext, useState} from 'react'
import mergeRight from 'ramda/src/mergeRight'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import modalStyles, {
  SmallContainer,
  CloseButton,
  CloseIcon,
} from '../modalStyles'
import {
  ContentWrapper,
  Title,
  Button,
  InteriorContainer,
  ButtonsWrapper, NormalText, ActionText, Description
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/actions'
import {AuthContext} from "../../../@logicea/react-auth";
import {Dimmer, Icon, Message} from "semantic-ui-react";

const ReportActionModal = () => {

  const auth = useContext(AuthContext)
  const context = useContext(DashboardsContext)
  const {
    isReportActionModalOpen,
    setReportActionModalVisibility,
    reportActionData
  } = context

  const showCloseButton = false
  const [working, setWorking] = useState(false)
  const [showActionStatus, setShowActionStatus] = useState(false)
  const [responseError, setResponseError] = useState()
  const [timeoutId, setTimeoutId] = useState(-1)

  const submitAction = async () => {
    setWorking(true)
    setShowActionStatus(false)
    setResponseError(null)
    let res = await actions.putReportUpdateAction({
      'teamid': reportActionData.teamId,
      'status': reportActionData.status
    }, reportActionData.reportId, auth.tokens.accessToken)
    setWorking(false)
    setShowActionStatus(true)
    setResponseError(res?.error)

    if (!res?.error) {
      setTimeoutId(setTimeout(() => {
        handleDismiss()
      }, 5000))
    }
  }

  const handleDismiss = () => {
    clearTimeout(timeoutId)
    setShowActionStatus(false)
    if (!responseError) {
      setReportActionModalVisibility(false)
      if (reportActionData.successCallback)
        reportActionData.successCallback(reportActionData)
    }
  }

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isReportActionModalOpen}
        contentLabel={t({ key: 'MODAL_CONFIRM_ACTION' })}
        style={{
          overlay: modalStyles.overlay,
          content: mergeRight(modalStyles.content, { padding: 20, paddingTop: 15 }),
        }}
      >
        <Dimmer active={working}>
          <Message icon>
            <Icon name='circle notched' loading />
            <Message.Content>
              <Message.Header>{t({key: 'MODAL_PLEASE_WAIT'})}</Message.Header>
              {t({key: 'MODAL_PROCESSING_REQUEST'})}
            </Message.Content>
          </Message>
        </Dimmer>
        <Dimmer active={showActionStatus}>
          <Message positive={!responseError} onDismiss={handleDismiss} negative={responseError}>
            <Message.Header>{t({key: responseError ? 'ERROR' : 'SUCCESS'})}</Message.Header>
            <p>
              {responseError ? responseError : t({key: 'REPORT_STATUS_COMPLETE', variables: {status: reportActionData.status}})}
            </p>
          </Message>
        </Dimmer>
        <SmallContainer>
          {showCloseButton && (
            <CloseButton onClick={() => setReportActionModalVisibility(false)}>
              <CloseIcon />
            </CloseButton>
          )}
          <ContentWrapper>
            <Title>
              {t({ key: 'MODAL_CONFIRM_ACTION' })}
            </Title>
            <InteriorContainer>
              <NormalText>{t({key: 'REPORT_ARE_YOU_SURE', variables: {status: reportActionData.status}})}</NormalText>
              <ActionText>{t({key: 'REPORT_STATUS_WARNING', variables: {reportType: reportActionData.reportType}})}</ActionText>
              <Description>{t({key: 'REPORT_STATUS_DESCRIPTION'})}</Description>
            </InteriorContainer>
            <ButtonsWrapper>
              <Button
                dark
                type="button"
                onClick={() => submitAction()}
              >
                {t({ key: 'YES' })}
              </Button>
              <Button type="button" onClick={() => setReportActionModalVisibility(false)}>
                {t({ key: 'CANCEL' })}
              </Button>
            </ButtonsWrapper>
          </ContentWrapper>
        </SmallContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default ReportActionModal
