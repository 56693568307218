import React, { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '@logicea/react-auth'
import { I18n } from 'lib/i18n'
import { useReports } from 'providers/Reports'
import { colors } from 'static/theme'
import { countTop5SenderEmails } from 'requests/messageRQ'
import { WidgetTitle as Title } from 'components/ReportWidgets/styles'
import {
  Container,
  Padding,
  Content,
  Row,
  SenderTitle,
  ThreatTitle,
  Email,
  ThreatLevelCircle,
} from './styles'
import { buildReportDashboardFilters } from "../../../lib/util";

const Top5SenderEmails = () => {
  const { timeframe, teamFilter } = useReports()
  const { from, to } = timeframe

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth as any
  const queryArgs = { realm, token: tokens?.accessToken }

  const filterArgs = useMemo(() => {
    return {
      dashboardFilters: buildReportDashboardFilters(from, to, teamFilter),
      setFilters: [],
      dashboardFilterMode: 'AND',
      setFilterMode: 'AND',
    }
  }, [from, to, teamFilter])

  const { data } = useQuery(
    [`top-5-sender-emails`, filterArgs, queryArgs],
    countTop5SenderEmails,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Padding>
            <Title>{t({ key: 'TOP_5_SENDER_EMAILS' })}</Title>
            <Content>
              <Row>
                <SenderTitle>
                  {t({ key: 'TOP_5_SENDER_EMAILS_SENDER_TITLE' })}
                </SenderTitle>
                <ThreatTitle>
                  {t({ key: 'TOP_5_SENDER_EMAILS_DANGER_TITLE' })}
                </ThreatTitle>
                <ThreatTitle>
                  {t({ key: 'TOP_5_SENDER_EMAILS_CAUTION_TITLE' })}
                </ThreatTitle>
                <ThreatTitle>
                  {t({ key: 'TOP_5_SENDER_EMAILS_NEUTRAL_TITLE' })}
                </ThreatTitle>
              </Row>
              {data?.map((s) => (
                <Row>
                  <Email>{s.key}</Email>
                  <ThreatLevelCircle
                    threatColor={colors.danger}
                    threats={s.countDanger}
                  />
                  <ThreatLevelCircle
                    threatColor={colors.caution}
                    threats={s.countCaution}
                  />
                  <ThreatLevelCircle
                    threatColor={colors.neutral}
                    threats={s.countSafe}
                  />
                </Row>
              ))}
            </Content>
          </Padding>
        </Container>
      )}
    </I18n>
  )
}

export { Top5SenderEmails }
