import styled from 'styled-components'
import {
  colors,
  BodyPrimaryMainBold,
  BodyXSDarkGreyRegular,
  TickTextPrimaryDark,
  spacing,
} from 'static/theme'
import { MAX_WIDGET_WIDTH } from 'components/ReportWidgets/styles'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${spacing.s};
  min-width: 28rem;
  max-width: ${MAX_WIDGET_WIDTH};
  height: 21rem;
  border-radius: 3px;
  background-color: ${colors.white};
  margin-right: ${spacing.m};
  margin-bottom: ${spacing.m};
`

export const Title = styled(BodyPrimaryMainBold)``

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${spacing.xl};
  position: relative;
`

export const TickText = styled(TickTextPrimaryDark)``

export const LineChartContainer = styled.div`
  margin-left: -${spacing.xs};
`

export const ComparisonContainer = styled.div`
  position: absolute;
`

export const ThreatsInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${spacing.l};
`

export const Threat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${spacing.s};
`
export const ThreatTitle = styled(BodyXSDarkGreyRegular)``

export const ThreatType = styled.div<{ threatColor: string }>`
  width: 0.75rem;
  height: 0.25rem;
  border-radius: 2px;
  background-color: ${({ threatColor }) => threatColor};
  margin-right: ${spacing.xs};
`
