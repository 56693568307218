import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import MessageSet from '../MessageSet'
import {
  BACKGROUND_DARK,
} from '../../static/colors'

const Container = styled.div`
  position: relative;
  border: 2px solid ${BACKGROUND_DARK};
  border-radius: 4px;
  flex: 1;
  margin: ${props => (props.direction === 'horizontal' ? '0px 8px 12px 8px' : '0px 8px 0px 8px')};
  ${props => (props.direction === 'horizontal' ? 'min-height' : 'min-width')}: ${props => (props.isMessageList ? (props.direction !== 'horizontal' ? '850' : '460') : '360')}px;
  display: flex;
  justify-content: space-between;
  background-color: white;
`

const { object, bool, number, string } = PropTypes

// const Set = ({ set, index, searchQuery, onThreatLevelChange, onReasonIdChange }) => (
const Set = ({ set, index, expanded, direction, ...rest }) => (
  <Draggable draggableId={set.id} index={index}>
    {(provided, snapshot) => (
      <Container
        {...provided.draggableProps}
        ref={provided.innerRef}
        // isDragging={snapshot.isDragging}
        direction={direction}
        width={set.width}
        isMessageList={set?.visualization === 'list'}
      >
        <MessageSet
          set={set}
          expanded={expanded}
          provided={provided}
          {...rest}
        />
      </Container>
    )}
  </Draggable>
)

Set.propTypes = {
  set: object,
  index: number,
  expanded: bool,
  direction: string,
}

export default Set
