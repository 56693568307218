import styled from 'styled-components'
import {
  colors,
  spacing,
  HeadlineH3PrimaryMainBlack,
  SubtitlePrimaryDarkBold,
  Body2WhiteRegular,
} from 'static/theme'
import { ReactComponent as PrivateSVG } from 'assets/marketplace/private.svg'
import { ReactComponent as SharedSVG } from 'assets/marketplace/shared.svg'
import { ReactComponent as PublicSVG } from 'assets/marketplace/public.svg'
import { ReactComponent as InfoSVG } from 'assets/marketplace/info.svg'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  min-width: 14.563rem;
  height: 113px;
  border-radius: 4px;
  background-color: ${colors.white};
  padding: ${spacing.xs};
  padding-bottom: ${spacing.xxs};
  margin-right: ${spacing.m};
  margin-bottom: ${spacing.m};
  @media (max-width: 1240px) {
    min-width: 7.25rem;
  }
`
export const NameInfo = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: ${spacing.xxs} ${spacing.xs};
  flex-direction: column;
  cursor: pointer;
`

export const BottomRow = styled.div`
  display: flex;
  width: 100%;
  padding: 0 ${spacing.xxs};
  justify-content: space-between;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled(HeadlineH3PrimaryMainBlack)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Subtitle = styled(SubtitlePrimaryDarkBold)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: ${spacing.xs};
  height: 36px;
`

export const Button = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: ${spacing.xs};
`

export const InfoText = styled(Body2WhiteRegular)``

export const PrivateIcon = styled(PrivateSVG)`
  fill: ${colors.primaryLight};
`

export const SharedIcon = styled(SharedSVG)`
  fill: ${colors.primaryLight};
`

export const PublicIcon = styled(PublicSVG)`
  fill: ${colors.primaryLight};
`

export const InfoIcon = styled(InfoSVG)<{ active: boolean }>`
  fill: ${({ active }) => (active ? colors.primaryMain : colors.primaryLight)};
`
