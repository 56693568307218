import React, { useCallback, useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '@logicea/react-auth'
import {
  WidgetTitle as Title,
  WidgetPadding as Padding,
} from 'components/ReportWidgets/styles'
import { useReports } from 'providers/Reports'
import { I18n } from 'lib/i18n'
import { aggregateBySensitiveContentCategory } from 'requests/messageRQ'
import { SensitiveContentCategory } from 'requests/messageRQ/types'
import {
  Container,
  Content,
  Category,
  CatTitle,
  ProgressBar,
  Counter,
  CounterPlaceholder,
} from './styles'
import { buildReportDashboardFilters } from "../../../lib/util";

const SensitiveCategories = ({
  isAnimationActive,
}: {
  isAnimationActive: boolean
}) => {
  const { timeframe, comparisonTimeframe, isComparisonEnabled, teamFilter } = useReports()
  const { from, to } = timeframe
  const { from: comparisonFrom, to: comparisonTo } = comparisonTimeframe

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth as any
  const queryArgs = { realm, token: tokens?.accessToken }

  const filterArgs = {
    dashboardFilters: buildReportDashboardFilters(from, to, teamFilter),
    setFilters: [],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const comparedFilterArgs = {
    dashboardFilters: buildReportDashboardFilters(comparisonFrom, comparisonTo, teamFilter),
    setFilters: [],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const { data } = useQuery(
    ['sensitiveCategories', filterArgs, queryArgs],
    aggregateBySensitiveContentCategory,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const { data: comparedData } = useQuery(
    ['comparedSensitiveCategories', comparedFilterArgs, queryArgs],
    aggregateBySensitiveContentCategory,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const progressPercent = useCallback(
    (count: number) => {
      if (data) {
        let totalCount = 0
        data?.forEach((a) => (totalCount = totalCount + a.value))
        return totalCount ? (100 * count) / totalCount : 0
      } else {
        return 0
      }
    },
    [data]
  )

  const counterPercent = useCallback(
    (sensCategory: SensitiveContentCategory) => {
      if (comparedData) {
        const comparedSensitiveCategory = comparedData.find(
          (c) =>
            c.sensitiveContentCategory === sensCategory.sensitiveContentCategory
        )
        if (comparedSensitiveCategory === undefined) return undefined
        if (comparedSensitiveCategory) {
          return sensCategory.value / comparedSensitiveCategory.value - 1
        } else {
          return 0
        }
      } else {
        return undefined
      }
    },
    [data, comparedData]
  )

  const exactlyThreeDataItems = useMemo(
    () =>
      (data ?? [])
        .concat([
          { sensitiveContentCategory: '', value: 0 },
          { sensitiveContentCategory: '', value: 0 },
          { sensitiveContentCategory: '', value: 0 },
        ])
        .slice(0, 3),
    [data]
  )

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Padding>
            <Title>{t({ key: 'SENSITIVE_CATEGORIES' })}</Title>
            <Content>
              {exactlyThreeDataItems?.map((i) => {
                return (
                  <Category>
                    <CatTitle>{i.sensitiveContentCategory}</CatTitle>
                    <ProgressBar
                      fullWidth={true}
                      percentage={progressPercent(i.value)}
                      isAnimationActive={isAnimationActive}
                    />
                    {i.sensitiveContentCategory === '' ? (
                      <CounterPlaceholder />
                    ) : (
                      <Counter
                        type='medium'
                        number={i.value}
                        percent={
                          isComparisonEnabled ? counterPercent(i) : undefined
                        }
                      />
                    )}
                  </Category>
                )
              })}
            </Content>
          </Padding>
        </Container>
      )}
    </I18n>
  )
}

export { SensitiveCategories }
