import React from 'react'
import { I18n } from 'lib/i18n'
import {
  Container,
  WidgetIcon,
  Title,
  Subtitle,
  IconButton,
  ButtonText,
  PlusIcon,
} from './styles'

export const EmptyDashboard = ({
  onAddWidgetsClick,
}: {
  onAddWidgetsClick: () => void
}) => (
  <I18n>
    {({ t }) => (
      <Container>
        <WidgetIcon />
        <Title>{t({ key: 'DASHBOARD_EMPTY_STATE_TITLE' })}</Title>
        <Subtitle>{t({ key: 'DASHBOARD_EMPTY_STATE_SUBTITLE' })}</Subtitle>
        <IconButton color='secondary' onClick={onAddWidgetsClick}>
          <PlusIcon />
          <ButtonText>{t({ key: 'MODAL_ADD_WIDGET' })}</ButtonText>
        </IconButton>
      </Container>
    )}
  </I18n>
)
