import React, { useState, useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import {
  Cell,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from 'recharts'
import prop from 'ramda/src/prop'
import eqBy from 'ramda/src/eqBy'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import isEmpty from 'ramda/src/isEmpty'
import unionWith from 'ramda/src/unionWith'
import { Loader } from 'semantic-ui-react'
import { AuthContext } from '@logicea/react-auth'
import ReactResizeDetector from 'react-resize-detector'
import { createResource, createCacheWithAsyncInvalidation } from '../../../lib/simple-cache-utils'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/message'
import { noop } from '../../../lib/ramda'
import { I18n } from '../../../lib/i18n'
import { Tooltip as ButtonTooltip } from '../../../lib/util'
import { REASON_ID_NAMES } from '../../../static/threatLevels'
import {
  DARK_GREY,
  REASON_ID_COLORS,
  VISUALIZATION_BACKGROUND,
} from '../../../static/colors'
import {
  TINY_VISUALIZATION_WIDTH_THRESHOLD,
  TINY_VISUALIZATION_HEIGHT_THRESHOLD,
  VISUALIZATION_MARGIN,
} from '../../../static/appConfig'
import {
  Container,
  AbsolutePosition,
  TickText,
} from './styles'
import {
  TootlipContainer,
  TooltipLabel,
  TooltipValue,
} from '../tooltipStyles'
import {
  LegendWrapper,
  ConfigureIcon,
} from '../legendStyles'
import { exportChart } from './export'
import { EmptyResults } from '../EmptyResults'

const { array, bool, func, number, object, string } = PropTypes

let reasonIdAggCache

const reasonIdAggCacheInvalidator = () => {
  reasonIdAggCache = createCacheWithAsyncInvalidation(reasonIdAggCacheInvalidator)
}
reasonIdAggCache = createCacheWithAsyncInvalidation(reasonIdAggCacheInvalidator)

const ReasonIdAggResource = createResource(
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token }) =>
    actions.aggregateByReasonIdAction(
      { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode },
      { realm, token },
    ),
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm }) =>
    JSON.stringify({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm })
)

const AsyncReasonIdsBarChart = ({
  dashboardId,
  dashboardFilters,
  dashboardFilterMode,
  setFilterMode,
  setFilters,
  onCellClick,
  timestamp,
  downloadAll,
  onFetch,
  belongsToUser,
  shouldExport,
  afterExport,
}) => {
  const [dashboard, setDashboard] = useState(null)

  const context = useContext(DashboardsContext)
  const {
    setViewModeTooltip,
  } = context

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  useEffect(() => {
    if (isNil(dashboard)) setDashboard(dashboardId)
    else setDashboard(null)
  }, [dashboardId])

  useEffect(() => {
    if (downloadAll) {
      const fetchedData = ReasonIdAggResource
        .read(reasonIdAggCache, {
          dashboardFilters,
          setFilters,
          dashboardFilterMode,
          setFilterMode,
          timestamp,
          realm,
          token: tokens.accessToken,
        })
      const formatedData = fetchedData.data.map(d => (
        { threat_category: REASON_ID_NAMES(d.reasonId), count: d.value })) // TODO: use i18n
      onFetch(formatedData)
    }
  }, [downloadAll, dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp])

  if (isNil(dashboard)) return null

  const data = ReasonIdAggResource
    .read(reasonIdAggCache, {
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
      timestamp,
      realm,
      token: tokens.accessToken,
    })
  const aggregationData = data.data
  const { networkTook, elasticsearchTook } = data

  // Sort the reason IDs by # of occurrences
  let sortedData = aggregationData.sort((a, b) => (a.value > b.value) ? 1 : -1)
  return (
    <ReasonIdsBarChartLayout
      elasticsearchTook={elasticsearchTook}
      networkTook={networkTook}
      aggregationData={sortedData}
      onCellClick={belongsToUser ? onCellClick : () => setViewModeTooltip(true)}
      setFilters={setFilters}
      animationsEnabled
      shouldExport={shouldExport}
      afterExport={afterExport}
    />
  )
}

const CountTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <TickText
      x={5}
      y={4}
      textAnchor="end"
      fill={DARK_GREY}
    >
      {payload.value}
    </TickText>
  </g>
)

const CategoryTick = ({ x, y, payload, onLabelClick }) => (
  <I18n>{({ t }) => (
    <g transform={`translate(${x},${y})`}>
      <TickText
        x={10}
        y={-10}
        textLength={64}
        lengthAdjust="spacingAndGlyphs"
        textAnchor="end"
        fill={DARK_GREY}
        transform="rotate(-35)"
        isClickable
        onClick={() => {
          if (onLabelClick) onLabelClick(payload.value)
        }}
      >
        {t({ key: 'REASONS', variables: { id: payload.value } })}
      </TickText>
    </g>
  )}
  </I18n>
)

const CustomTooltip = ({ payload, label, active }) => (
  active ? (
    <I18n>{({ t }) => (
      <TootlipContainer>
        <TooltipLabel>
          {t({ key: 'REASONS', variables: { id: label } })}
        </TooltipLabel>
        <TooltipValue>
          {t({
            key: 'AGGREGATION_HITS',
            variables: { aggregationHits: payload[0].value },
          })}
        </TooltipValue>
      </TootlipContainer>
    )}
    </I18n>
  ) : null
)

const ReasonIdsBarChartLayout = ({
  aggregationData = [],
  animationsEnabled = false,
  onCellClick = noop,
  setFilters = [],
  shouldExport,
  afterExport,
}) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [scale, setScale] = useState('log')
  const [domain, setDomain] = useState([1, 'auto'])
  const [minPointSize, setMinPointSize] = useState(0)

  useEffect(() => {
    if (shouldExport && aggregationData) {
      exportChart(aggregationData, scale, domain, minPointSize)
      afterExport()
    }
  }, [shouldExport, afterExport, aggregationData, scale, domain, minPointSize])

  const onResize = (w, h) => {
    setWidth(w)
    setHeight(h)
  }

  const toggleScale = () => {
    const newScale = scale === 'auto' ? 'log' : 'auto'
    setScale(newScale)
    setDomain(newScale === 'log' ? ['dataMin', dataMax => (dataMax * (4 / 3))] : [0, 'auto'])
    setMinPointSize(newScale === 'log' ? 5 : 0)
  }

  const tiny = width < TINY_VISUALIZATION_WIDTH_THRESHOLD || height < TINY_VISUALIZATION_HEIGHT_THRESHOLD

  return (
    <I18n>{({ t }) => (
      isEmpty(aggregationData) ? (
          <EmptyResults />
      ) : (
        <Container>
          <LegendWrapper>
            <ConfigureIcon
              data-tip
              data-for="scaleButton"
              onClick={toggleScale}
            />
            <ButtonTooltip bottom id="scaleButton">
              <span>
                {t({ key: 'TOOLTIP_TOGGLE_CHART_SCALE' })}
              </span>
            </ButtonTooltip>
          </LegendWrapper>
          <AbsolutePosition>
            <BarChart
              width={width - VISUALIZATION_MARGIN}
              height={height}
              data={aggregationData}
              style={{
                marginRight: VISUALIZATION_MARGIN,
                backgroundColor: VISUALIZATION_BACKGROUND,
              }}
              onClick={props => {
                const reasonId = pathOr(null, ['activePayload', 0, 'payload', 'reasonId'], props)
                if (props) {
                  onCellClick(unionWith(eqBy(prop('name')),
                    [{ name: 'reason_ids', term: [reasonId], type: 'integer' }],
                    setFilters), null)
                }
              }}
            >
              <XAxis
                dataKey="reasonId"
                tickMargin={12}
                height={40}
                tick={
                  (
                    <CategoryTick
                      onLabelClick={
                        value => onCellClick(unionWith(eqBy(prop('name')),
                          [{ name: 'reason_ids', term: [value], type: 'integer' }],
                          setFilters), null)
                        }
                    />
                  )
                }
                tickLine={false}
                axisLine={false}
                hide={tiny}
              />
              <YAxis
                yAxisId="b"
                type="number"
                domain={domain}
                scale={scale}
                axisLine={false}
                tickLine={false}
                tickMargin={16}
                tick={<CountTick />}
                padding={{ top: 20, bottom: 4 }}
                hide={tiny}
              />
              <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
              <Bar yAxisId="b" dataKey="value" isAnimationActive={animationsEnabled} minPointSize={minPointSize}>
                {
                  aggregationData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`} // eslint-disable-line react/no-array-index-key
                      fill={REASON_ID_COLORS[entry.reasonId % REASON_ID_COLORS.length]}
                    />
                  ))
                }
              </Bar>
            </BarChart>
          </AbsolutePosition>
          <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        </Container>
      ))}
    </I18n>
  )
}

const ReasonIdsBarChart = ({ dashboardFilters, ...rest }) => dashboardFilters && (
  <Suspense
    fallback={<Loader active />}
  >
    <AsyncReasonIdsBarChart dashboardFilters={dashboardFilters} {...rest} />
  </Suspense>
)

CountTick.propTypes = {
  x: number,
  y: number,
  payload: object,
}

CategoryTick.propTypes = {
  x: number,
  y: number,
  payload: object,
  onLabelClick: func,
}

CustomTooltip.propTypes = {
  active: bool,
  label: number,
  payload: array,
}

AsyncReasonIdsBarChart.propTypes = {
  dashboardId: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  setFilterMode: string,
  setFilters: array,
  onCellClick: func,
  timestamp: number,
  downloadAll: bool,
  onFetch: func,
  belongsToUser: bool,
  shouldExport: bool,
  afterExport: func,
}

ReasonIdsBarChartLayout.propTypes = {
  aggregationData: array,
  animationsEnabled: bool,
  onCellClick: func,
  setFilters: array,
  shouldExport: bool,
  afterExport: func,
}

export default ReasonIdsBarChart
