import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import prop from 'ramda/src/prop'
import eqBy from 'ramda/src/eqBy'
import unionWith from 'ramda/src/unionWith'
import { noop } from '../../../../lib/ramda'
import {
  Container,
  TreeMapWrapper,
  NodeWrapper,
  NodeOverlay,
} from './styles'

const { number, array, string, func, bool } = PropTypes

const getChildrenSize = children => {
  let sum = 0
  children.forEach(child => {
    if (child.children) sum += getChildrenSize(child.children)
    else sum += child.size
  })
  return sum
}

const getThreatLevel = name => {
  switch (name) {
    case '0.0':
      return [0]
    case '1.0':
      return [1]
    case '2.0':
      return [2]
    default:
      return [0, 1, 2]
  }
}

const getThreatLevelLabel = name => {
  switch (name) {
    case '0.0':
      return 'Neutral'
    case '1.0':
      return 'Caution'
    case '2.0':
      return 'Danger'
    default:
      return name
  }
}

const TreeNode = ({ name, childrenData, size, level, onNodeClick, tiny }) => (
  <NodeWrapper
    key={`${name}-${level}-node`}
    size={!(size === undefined) ? size : getChildrenSize(childrenData)}
    level={level}
    name={name}
  >
    {level === 1 && (
      <NodeOverlay onClick={() => onNodeClick(name)}>
        {tiny ? null : <div>{getThreatLevelLabel(name)}</div>}
        {tiny ? null : <div>{size}</div>}
      </NodeOverlay>
    )}
    {size === undefined && renderChildren(childrenData, level)}
  </NodeWrapper>
)

const renderChildren = (children, level) => children.map(child => (
  <TreeNode
    level={level + 1}
    key={`${child.name}-${level}-child`}
    name={child.name}
    size={child.size}
    childrenData={child.children}
  />
))

const ZoomTreeMap = ({
  width = 0,
  height = 0,
  aggregationData = [],
  onCellClick = noop,
  setFilters = [],
  zoom,
}) => {
  const [data, setData] = useState([])
  const [name, setName] = useState('')

  useEffect(() => {
    setData(aggregationData)
  }, [aggregationData, zoom])

  const handleNodeClick = newDataKey => {
    const newData = data.find(e => e.name === newDataKey).children
    if (newData) {
      setName(newDataKey)
      setData(newData)
    } else {
      onCellClick(unionWith(eqBy(prop('name')), [
        { name: 'email', term: name, type: 'keyword' },
        { name: 'threat_level', term: getThreatLevel(newDataKey), type: 'byte' },
      ], setFilters), null)
    }
  }

  return (
    <Container width={width} height={height}>
      <TreeMapWrapper>
        {width > 0 && height > 0 && data.map(node => (
          <TreeNode
            level={1}
            key={node.name}
            name={node.name}
            size={node.size}
            tiny={width < 300 || height < 300}
            childrenData={node.children}
            onNodeClick={handleNodeClick}
          />
        ))}
      </TreeMapWrapper>
    </Container>
  )
}

TreeNode.propTypes = {
  tiny: bool,
  name: string,
  childrenData: array,
  size: number,
  level: number,
  onNodeClick: func,
}

ZoomTreeMap.propTypes = {
  width: number,
  height: number,
  aggregationData: array,
  onCellClick: func,
  setFilters: array,
  zoom: bool,
}


export default ZoomTreeMap
