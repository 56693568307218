import React, { useCallback } from 'react'
import moment from 'moment'
import { navigate } from '@reach/router'
import { I18n } from 'lib/i18n'
import { DashboardT } from 'components/App/Dashboard/types'
import { DashboardActions } from 'components/DashboardActions'
import {
  Container,
  Details,
  Title,
  Subtitle,
  Description,
  Row,
  Owner,
  Created,
  LastUpdated,
  BodyXSPrimaryDark,
  BodyXSDarkGrey,
  Info,
  PrivateIcon,
  SharedIcon,
  PublicIcon,
} from './styles'

export const DashboardItem = ({
  item,
  index,
}: {
  item: DashboardT
  index: number
}) => {
  const handleDashboardClick = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      navigate(`dashboards/${id}`)
    },
    []
  )

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Details onClick={handleDashboardClick(item.id)}>
            <Title>{item.name}</Title>
            <Subtitle>{item.subTitle}</Subtitle>
            <Description>{item.description}</Description>
            <Row>
              <Owner>
                <BodyXSPrimaryDark>
                  {item.relativeOwnership === 'PRIVATE'
                    ? t({ key: 'MARKETPLACE_MINE' })
                    : t({ key: 'MARKETPLACE_OWNER' })}
                </BodyXSPrimaryDark>
                <BodyXSDarkGrey>
                  {item.relativeOwnership === 'TEAM'
                    ? item.user_id
                    : item.relativeOwnership === 'DEFAULT'
                    ? 'INKY'
                    : ''}
                </BodyXSDarkGrey>
              </Owner>
              <Created>
                <BodyXSPrimaryDark>
                  {t({ key: 'MARKETPLACE_CREATED' })}
                </BodyXSPrimaryDark>
                <BodyXSDarkGrey>
                  {item.createdAt
                    ? moment(item.createdAt * 1000).format('L')
                    : '-'}
                </BodyXSDarkGrey>
              </Created>
              <LastUpdated>
                <BodyXSPrimaryDark>
                  {t({ key: 'MARKETPLACE_LAST_UPDATED' })}
                </BodyXSPrimaryDark>
                <BodyXSDarkGrey>
                  {item.updatedAt
                    ? moment(item.updatedAt * 1000).format('L')
                    : '-'}
                </BodyXSDarkGrey>
              </LastUpdated>
            </Row>
          </Details>
          <Info>
            <DashboardActions dashboard={item} index={index} />
            {item.relativeOwnership === 'PRIVATE' ? (
              <PrivateIcon />
            ) : item.relativeOwnership === 'TEAM' ? (
              <SharedIcon />
            ) : (
              <PublicIcon />
            )}
          </Info>
        </Container>
      )}
    </I18n>
  )
}
