import styled from 'styled-components'
import {Checkbox, Icon} from 'semantic-ui-react'
import {
  PRIMARY_DARK,
  DARK_GREY,
  BACKGROUND_DARK,
  MESSAGE_SET_BAR,
  SEPARATOR,
  WHITE,
  BACKGROUND_LIGHT,
  LEGEND_TEXT,
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
} from '../../static/colors'
import funnelIcon from '../../assets/funnelWithCounter/funnelWithCounter.png'
import { spacing, Body2PrimaryMainBold, SubtitlePrimaryMainBold, colors } from 'static/theme'
import {ReactComponent as StatisticsSVG} from 'assets/filters/mail.svg'

const THREAT_COLORS = [CHART_SAFE, CHART_CAUTION, CHART_DANGER]

export const Container = styled.div`
  position: relative;
  min-width: 100%;
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: ${spacing.xs};
`

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:focus {
    outline: none;
  }
`

export const BarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Separator = styled.div`
  border-left: 1px solid ${SEPARATOR};
  margin-left: 16px;
  height: 23px;
`

export const Title = styled(SubtitlePrimaryMainBold)`
  padding-left: ${spacing.xxs};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 52px;
`

export const FilterToggle = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 4px;
  margin-left: 8px;
  border-radius: 4px;
  transition: background-color 0.5s;
  position: relative;
`

export const FilterToggleIcon = styled.img.attrs({
  src: funnelIcon,
  alt: 'filters',
})`
  height: 28px;
`

export const FilterToggleText = styled.div`
  color: ${DARK_GREY};
  font-family: Lato;
  font-size: 17px;
  font-weight: bold;
  line-height: 16px;
`

export const FilterToggleCounter = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${WHITE};
  font-family: Lato;
  font-size: 8px;
  line-height: 11px;
  position: absolute;
  top: 3px;
  right: 3px;
`

export const FilterToggleArrow = styled.div`
  color: ${PRIMARY_DARK};
  font-size: 12px;
  margin-left: 8px;
`

export const Name = styled.div`
  color: ${PRIMARY_DARK};
  height: 32px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 700;
  margin-left: 11px;

  &:hover {
    cursor: ${props => (props.configureMode ? 'pointer' : 'inherit')};
  }
`

export const StatisticsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: ${spacing.xs};
  left: ${spacing.xs};
`

export const Statistics = styled(Body2PrimaryMainBold)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StatisticsIcon = styled(StatisticsSVG)`
  fill:${colors.primaryDark};
`

export const Selector = styled.div`
  padding: 0rem ${spacing.xxs};
`

export const HideActionedCheckbox = styled(Checkbox)`
  align-self: center;
`

export const MessageFilterTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export const MessageFilterCheckbox = styled(Checkbox)`
  display: block !important;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  ${props => props.hideOverflow ? 'overflow:hidden' : ''};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const IconWrapper = styled.div`
  display: flex;
`

export const ConfigureIcon = styled(Icon).attrs({
  name: 'configure',
})`
  margin: 5px 0px !important;

  &:hover {
    cursor: pointer;
  }
`

export const ThreatLevelLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
`

export const LegendDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => THREAT_COLORS[props.threat]};
  margin-right: 4px;
`

export const LegendText = styled.div`
  font-family: Lato;
  font-size: 9px;
  font-weight: bold;
  color: ${LEGEND_TEXT};
  margin-right: 8px;

`

export const ThreatBullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${props => THREAT_COLORS[props.threat]};
  margin-right:${spacing.xs};
`

export const VisualizationWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  padding-bottom:${spacing.s};
`
