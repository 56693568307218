import Axios from 'axios'
import { toKotlinFilter } from 'lib/transformations'
import actions from 'requests/message'
import { KOTLIN_URL } from 'static/appConfig'
import {
  AggregatePayload,
  AggregateByCardinalityResponse,
  DateAggregation,
  ResultCategoryAggregation,
  SensitiveContentCategoryAggregation,
  ThreatLevelAggregation,
  AggregateByTopTermResponse,
  AggregateByTopTermsPerThreat,
} from './types'

// XHR //
const aggregateByCardinalityXhr = (payload: AggregatePayload) => {
  const { filters, query, fieldName } = payload
  return Axios.post<AggregateByCardinalityResponse>(
    `${KOTLIN_URL}/messages/aggregations/cardinality/${fieldName}`,
    {
      dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
      setFilters: filters.setFilters.map(toKotlinFilter),
      dashboardFilterMode: filters.dashboardFilterMode || 'AND',
      setFilterMode: filters.setFilterMode || 'AND',
    },
    {
      headers: {
        Authorization: `Bearer ${query.token}`,
        'Content-Type': 'application/json',
        'X-Team': query.realm,
      },
    }
  )
}

const aggregateByTopTermXhr = (payload: AggregatePayload) => {
  const { filters, query, fieldName } = payload
  return Axios.post<AggregateByTopTermResponse>(
    `${KOTLIN_URL}/messages/aggregations/top-term/${fieldName}?min_threat_level=1`,
    {
      dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
      setFilters: filters.setFilters.map(toKotlinFilter),
      dashboardFilterMode: filters.dashboardFilterMode || 'AND',
      setFilterMode: filters.setFilterMode || 'AND',
    },
    {
      headers: {
        Authorization: `Bearer ${query.token}`,
        'Content-Type': 'application/json',
        'X-Team': query.realm,
      },
    }
  )
}

const aggregateByTopTermsPerThreatXhr = (payload: AggregatePayload) => {
  const { filters, query, fieldName } = payload
  return Axios.post<AggregateByTopTermsPerThreat>(
    `${KOTLIN_URL}/messages/aggregations/top-terms-per-threat/${fieldName}?max_result_count=5`,
    {
      dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
      setFilters: filters.setFilters.map(toKotlinFilter),
      dashboardFilterMode: filters.dashboardFilterMode || 'AND',
      setFilterMode: filters.setFilterMode || 'AND',
    },
    {
      headers: {
        Authorization: `Bearer ${query.token}`,
        'Content-Type': 'application/json',
        'X-Team': query.realm,
      },
    }
  )
}

// Actions
export const aggregateByThreatLevel = async (
  _key: string,
  filters: AggregatePayload['filters'],
  query: AggregatePayload['query']
) => {
  const res = await actions.aggregateByThreatLevelAction(filters, query)
  return res.data as ThreatLevelAggregation
}

export const countMessagesAction = async (
  _key: string,
  filters: AggregatePayload['filters'],
  query: AggregatePayload['query']
) => {
  const res = await actions.countMessagesAction(filters, query)
  return res as number
}

export const aggregateBySensitiveContentCategory = async (
  _key: string,
  filters: any,
  query: any
) => {
  const res = await actions.aggregateBySensitiveContentCategoryAction(
    filters,
    query
  )
  return res.data as SensitiveContentCategoryAggregation
}

export const aggregateByDate = async (
  _key: string,
  filters: any,
  query: any
) => {
  const res = await actions.aggregateByDateAction(filters, query)

  return res.data as DateAggregation
}

export const aggregateByResultBucket = async (
  _key: string,
  filters: any,
  query: any
) => {
  const res = await actions.aggregateByResultBucketAction(filters, query)
  return res.data as ResultCategoryAggregation
}

export const countUniquePrimaryRecipientsAction = async (
  _key: string,
  filters: AggregatePayload['filters'],
  query: AggregatePayload['query']
) => {
  const res = await aggregateByCardinalityXhr({
    filters,
    query,
    fieldName: 'email',
  })
  return res.data
}

export const countTopHitAction = async (
  _key: string,
  filters: AggregatePayload['filters'],
  query: AggregatePayload['query'],
  fieldName: AggregatePayload['fieldName']
) => {
  const res = await aggregateByTopTermXhr({
    filters,
    query,
    fieldName,
  })
  return res.data.data[0]
}

export const countTop5SenderEmails = async (
  _key: string,
  filters: AggregatePayload['filters'],
  query: AggregatePayload['query']
) => {
  const res = await aggregateByTopTermsPerThreatXhr({
    filters,
    query,
    fieldName: 'from_email',
  })
  return res.data.data
}
