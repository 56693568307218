import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import propEq from 'ramda/src/propEq'
import isEmpty from 'ramda/src/isEmpty'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import { SearchInput } from 'components/SearchInput'
import messageActions from '../../../requests/message'
import { I18n } from '../../../lib/i18n'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  AllResultsText,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import debounce from "lodash.debounce";
import {INPUT_TEXT_DEBOUNCE_TIME} from "../../../static/appConfig";

const { object, func, array, string } = PropTypes

const NestedMatchesInput = ({
  realm,
  token,
  filter,
  onChange,
  setFilters,
  setFilterMode,
  dashboardFilters,
  dashboardFilterMode,
  nestedFieldPath,
  innerFieldName,
}) => {

  const [searchText, setSearchText] = useState('')
  const [hitCount, setHitCount] = useState(0)
  const [suggestions, setSuggestions] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState([])

  const getSuggestionsDebounced = useCallback(debounce(async (newValue) => {
    const { name } = filter
    const newSuggestions = await messageActions.getNestedSuggestionsAction(
      {
        dashboardFilters: rFilter(f => !propEq('name', name)(f), dashboardFilters),
        setFilters: rFilter(f => !propEq('name', name)(f), setFilters),
        dashboardFilterMode,
        setFilterMode,
      },
      {
        realm,
        token,
        field: nestedFieldPath,
        innerField: innerFieldName,
        term: newValue,
      }
    )
    setHitCount(newSuggestions.data?.length)
    setSuggestions(newSuggestions.data)
  }, INPUT_TEXT_DEBOUNCE_TIME), [])

  useEffect(() => {
    // Incoming filter term is a string for a catch-all filter or a list of strings for selected keywords
    // allowPrefixFiltering allows the user to perform "All matches of XYZ" instead of explicit keyword selections
    const allowPrefixFiltering = filter.type === 'nested_matches'
    const isStringTerm = typeof filter.term === 'string'
    if (allowPrefixFiltering && isStringTerm)
      setSearchText(filter.term)
    getSuggestionsDebounced(allowPrefixFiltering && isStringTerm ? filter.term : '')
    setSelectedKeywords(isStringTerm ? (allowPrefixFiltering && filter.term?.size ? [filter.term] : []) : filter.term)
  }, [filter.term])

  const handleSearchTextChange = newValue => {
    setSearchText(newValue)
    if (filter.type === 'nested_matches' && selectedKeywords.length === 0)
      onChange(newValue)
    getSuggestionsDebounced(newValue)
  }

  const handleRowClick = keyword => {
    const newKeywords = symmetricDifference(selectedKeywords, [keyword])
    setSelectedKeywords(newKeywords)
    onChange(newKeywords)
  }

  const handleClearClick = () => handleSearchTextChange('')

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <SearchInput
            autoFocus={true}
            value={searchText}
            name={filter.name}
            onChange={(evt) => handleSearchTextChange(evt?.target?.value)}
            onClear={handleClearClick}
            placeholder={t({ key: filter.name })}
            key={filter.name}
          />
          <SelectionHeader>
            <SelectionHeaderItem>
              {hitCount > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {hitCount}
                  <StatisticsText>({hitCount === 1
                    ? t({ key: 'SELECTION_MATCH' })
                    : t({ key: 'SELECTION_MATCHES' })
                  })</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
            <SelectionHeaderItem right>
              {selectedKeywords.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {selectedKeywords.length}
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
          </SelectionHeader>
          <SelectionWrapper>
            <SelectionColumnWrapper>
              <Scrollbar>
                {suggestions.map(suggestion => {
                  const isSelected = selectedKeywords.includes(suggestion)
                  return (
                    <SuggestionWrapper>
                      <Suggestion
                        key={suggestion}
                        isSelected={isSelected}
                        onClick={isSelected ? null
                          : () => handleRowClick(suggestion)
                        }
                      >
                        <SuggestionText>
                          {suggestion || t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                      </Suggestion>
                    </SuggestionWrapper>
                  )
                })}
              </Scrollbar>
            </SelectionColumnWrapper>
            <Seperator>
              <SeperatorIcon />
            </Seperator>
            <SelectionColumnWrapper right>
              <Scrollbar>
                {selectedKeywords.length > 0
                  ? selectedKeywords.map(keyword => (
                    <SuggestionWrapper>
                      <Suggestion
                        closeable
                        key={keyword}
                        isSelected={false}
                        onClick={() => handleRowClick(keyword)}
                      >
                        <SuggestionText>
                          {keyword || t({ key: 'SELECTION_NO_VALUE' })}
                        </SuggestionText>
                        <CloseIcon>×</CloseIcon>
                      </Suggestion>
                    </SuggestionWrapper>
                  )) : filter.type === 'nested_matches' && !isEmpty(searchText) && (
                    <SuggestionWrapper>
                      <AllResultsText>
                        {t({ key: 'SELECTION_ALL_MATCHES_OF', variables: { value: searchText } })}
                      </AllResultsText>
                    </SuggestionWrapper>
                  )
                }
              </Scrollbar>
            </SelectionColumnWrapper>
          </SelectionWrapper>
        </Container>
      )}
    </I18n>
  )
}


NestedMatchesInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  nestedFieldPath: string,
  innerFieldName: string,
}

export default NestedMatchesInput
