import React from 'react'
import { colors } from 'static/theme'

const ActiveDot = ({
  cx,
  cy,
  threatColor,
}: {
  cx?: number
  cy?: number
  threatColor: string
}) => {
  return (
    <>
      <circle
        r={7}
        cx={cx}
        cy={cy}
        stroke={colors.white}
        stroke-width='6'
        fill={threatColor}
      />
      <circle
        r={8}
        cx={cx}
        cy={cy}
        stroke={threatColor}
        stroke-width='0.5'
        fill={'transparent'}
      />
    </>
  )
}

export { ActiveDot }
