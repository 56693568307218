import React, { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { number as formatNumber } from 'format-message'
import { AuthContext } from '@logicea/react-auth'
import { aggregateByResultBucket } from 'requests/messageRQ'
import { useReports } from 'providers/Reports'
import { I18n } from 'lib/i18n'
import {
  WidgetTitle as Title,
  WidgetPadding as Padding,
} from 'components/ReportWidgets/styles'
import { colors } from 'static/theme'
import { RadialPie } from 'components/ReportComponents/RadialPie'
import {
  Container,
  Content,
  Row,
  Column,
  Circle,
  ThreatTitle,
  ThreatInfo,
  ThreatCount,
  Separator,
  ThreatRadialPie,
  ThreatDetails,
  ThreatDetail,
} from './styles'
import { buildReportDashboardFilters } from "../../../lib/util";

const ResultCategories = ({
  isAnimationActive,
}: {
  isAnimationActive: boolean
}) => {
  const { timeframe, teamFilter } = useReports()
  const { from, to } = timeframe
  const filterArgs = {
    dashboardFilters: buildReportDashboardFilters(from, to, teamFilter),
    setFilters: [],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth as any
  const queryArgs = { realm, token: tokens?.accessToken }

  const { data } = useQuery(
    ['result-categories', filterArgs, queryArgs],
    aggregateByResultBucket,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const counts = useMemo(() => {
    const neutral =
      data?.find((kv) => kv.resultBucket === 'NEUTRAL')?.value ?? 0
    const cautionNonSpam =
      data?.find((kv) => kv.resultBucket === 'CAUTION-NON-SPAM')?.value ?? 0
    const cautionSpam =
      data?.find((kv) => kv.resultBucket === 'CAUTION-SPAM')?.value ?? 0
    const cautionHiSpam =
      data?.find((kv) => kv.resultBucket === 'CAUTION-HI-SPAM')?.value ?? 0
    const danger = data?.find((kv) => kv.resultBucket === 'DANGER')?.value ?? 0
    const dangerHi =
      data?.find((kv) => kv.resultBucket === 'DANGER-HI')?.value ?? 0
    return {
      neutral,
      cautionNonSpam,
      cautionSpam,
      cautionHiSpam,
      danger,
      dangerHi,
    }
  }, [data])

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Padding>
            <Content>
              <Title>{t({ key: 'RESULT_CATEGORIES' })}</Title>
              <Row>
                <Column>
                  <ThreatRadialPie>
                    <RadialPie
                      type='small'
                      counts={{
                        first: undefined,
                        second: counts.danger,
                        third: counts.dangerHi,
                        total: counts.danger + counts.dangerHi,
                      }}
                      colors={{
                        first: colors.neutral,
                        second: colors.secondaryDanger,
                        third: colors.danger,
                      }}
                      isAnimationActive={isAnimationActive}
                    />
                    <ThreatTitle>
                      {t({ key: 'THREATS_RECEIVED_DANGER' })}
                    </ThreatTitle>
                  </ThreatRadialPie>
                  <ThreatDetails>
                    <ThreatDetail>
                      <Circle threatColor={colors.danger} />
                      <ThreatInfo>
                        {t({ key: 'THREATS_RECEIVED_HI_PHISH' })}
                      </ThreatInfo>
                      <ThreatCount>{formatNumber(counts.dangerHi)}</ThreatCount>
                    </ThreatDetail>
                    <ThreatDetail>
                      <Circle threatColor={colors.secondaryDanger} />
                      <ThreatInfo>
                        {t({ key: 'THREATS_RECEIVED_PHISH' })}
                      </ThreatInfo>
                      <ThreatCount>{formatNumber(counts.danger)}</ThreatCount>
                    </ThreatDetail>
                  </ThreatDetails>
                </Column>
                <Separator />
                <Column>
                  <ThreatRadialPie>
                    <RadialPie
                      type='small'
                      counts={{
                        first: counts.cautionNonSpam,
                        second: counts.cautionSpam,
                        third: counts.cautionHiSpam,
                        total:
                          counts.cautionSpam +
                          counts.cautionNonSpam +
                          counts.cautionHiSpam,
                      }}
                      colors={{
                        first: colors.lowerCaution,
                        second: colors.caution,
                        third: colors.higherCaution,
                      }}
                      isAnimationActive={isAnimationActive}
                    />
                    <ThreatTitle>
                      {t({ key: 'THREATS_RECEIVED_CAUTION' })}
                    </ThreatTitle>
                  </ThreatRadialPie>
                  <ThreatDetails>
                    <ThreatDetail>
                      <Circle threatColor={colors.lowerCaution} />
                      <ThreatInfo>
                        {t({ key: 'THREATS_RECEIVED_NON_SPAM' })}
                      </ThreatInfo>
                      <ThreatCount>
                        {formatNumber(counts.cautionNonSpam)}
                      </ThreatCount>
                    </ThreatDetail>
                    <ThreatDetail>
                      <Circle threatColor={colors.caution} />
                      <ThreatInfo>
                        {t({ key: 'THREATS_RECEIVED_SPAM' })}
                      </ThreatInfo>
                      <ThreatCount>
                        {formatNumber(counts.cautionSpam)}
                      </ThreatCount>
                    </ThreatDetail>
                    <ThreatDetail>
                      <Circle threatColor={colors.higherCaution} />
                      <ThreatInfo>
                        {t({ key: 'THREATS_RECEIVED_HI_SPAM' })}
                      </ThreatInfo>
                      <ThreatCount>
                        {formatNumber(counts.cautionHiSpam)}
                      </ThreatCount>
                    </ThreatDetail>
                  </ThreatDetails>
                </Column>
              </Row>
            </Content>
          </Padding>
        </Container>
      )}
    </I18n>
  )
}

export { ResultCategories }
