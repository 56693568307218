import React, { useState, useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Treemap } from 'recharts'
import prop from 'ramda/src/prop'
import eqBy from 'ramda/src/eqBy'
import isNil from 'ramda/src/isNil'
import isEmpty from 'ramda/src/isEmpty'
import unionWith from 'ramda/src/unionWith'
import { Loader } from 'semantic-ui-react'
import { scaleOrdinal } from 'd3-scale'
import { AuthContext } from '@logicea/react-auth'
import { schemeCategory10 } from 'd3-scale-chromatic'
import ReactResizeDetector from 'react-resize-detector'
import { createResource, createCacheWithAsyncInvalidation } from '../../../lib/simple-cache-utils'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/message'
import { noop } from '../../../lib/ramda'
import { I18n } from '../../../lib/i18n'
import {
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
  VISUALIZATION_BACKGROUND,
} from '../../../static/colors'
import {
  VISUALIZATION_MARGIN,
} from '../../../static/appConfig'
import {
  LegendWrapper,
  ThreatLevelLegend,
  LegendText,
  LegendDot,
} from '../legendStyles'
import { EmptyResults } from '../EmptyResults'

const colorsScaled = scaleOrdinal(schemeCategory10).range()

const { array, func, object, number, string, bool } = PropTypes

let teamAggCache

const teamAggCacheInvalidator = () => {
  teamAggCache = createCacheWithAsyncInvalidation(teamAggCacheInvalidator)
}
teamAggCache = createCacheWithAsyncInvalidation(teamAggCacheInvalidator)

const TeamAggResource = createResource(
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token }) =>
    actions.aggregateByTeamAction(
      { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode },
      { realm, token }
    ),
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm }) =>
    JSON.stringify({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm })
)

const AsyncTeamTreeMap = ({
  dashboardId,
  dashboardFilters,
  dashboardFilterMode,
  setFilterMode,
  setFilters,
  onCellClick,
  timestamp,
  belongsToUser,
}) => {
  const [dashboard, setDashboard] = useState(null)

  const context = useContext(DashboardsContext)
  const {
    setViewModeTooltip,
  } = context

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  useEffect(() => {
    if (isNil(dashboard)) setDashboard(dashboardId)
    else setDashboard(null)
  }, [dashboardId])

  if (isNil(dashboard)) return null

  const data = TeamAggResource
    .read(teamAggCache, {
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
      timestamp,
      realm,
      token: tokens.accessToken,
    })
  const aggregationData = data.data
  const { networkTook, elasticsearchTook } = data

  return (
    <TeamTreeMapLayout
      elasticsearchTook={elasticsearchTook}
      networkTook={networkTook}
      aggregationData={aggregationData}
      onCellClick={belongsToUser ? onCellClick : () => setViewModeTooltip(true)}
      setFilters={setFilters}
      animationsEnabled
    />
  )
}

const Content = ({
  tiny,
  root,
  depth,
  x,
  y,
  width,
  height,
  index,
  colors,
  setFilters,
  onCellClick,
}) => {
  const backgroundFill = depth === 1 ? colorsScaled[index % 10] : 'none'

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: depth === 3
            ? colors[Math.floor((index / root.children.length) * 3)]
            : backgroundFill,
          opacity: depth === 3 ? 0.7 : 1,
          stroke: '#FFFFFF',
          strokeWidth: 4 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
        onClick={depth === 3 ? (() => onCellClick(unionWith(eqBy(prop('name')), [
          { name: 'email', term: root.name, type: 'keyword' },
          { name: 'threat_level', term: [index], type: 'byte' },
        ], setFilters), null)) : null}
      />
      {depth === 3 && index === 0 ? (
        <text
          x={x + width / 2 - 10}
          y={y + height / 2}
          fill="#fff"
          fontSize={10}
          fillOpacity={0.9}
        >
          {tiny ? null : root.name}
        </text>
      ) : null}
    </g>
  )
}

const TeamTreeMapLayout = ({
  aggregationData = [],
  onCellClick = noop,
  setFilters = [],
}) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const onResize = (w, h) => {
    setWidth(w)
    setHeight(h)
  }

  return (
    <I18n>{({ t }) => (
      isEmpty(aggregationData) ? (
          <EmptyResults />
      ) : (
        <div style={{
          flex: 1,
          width: '100%',
          height: '100%',
          textAlign: 'center',
          position: 'relative',
        }}
        >
          <LegendWrapper>
            <ThreatLevelLegend>
              <LegendDot threat={2} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 2 } })}
              </LegendText>
              <LegendDot threat={1} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 1 } })}
              </LegendText>
              <LegendDot threat={0} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 0 } })}
              </LegendText>
            </ThreatLevelLegend>
          </LegendWrapper>
          <div style={{ position: 'absolute' }}>
            <Treemap
              width={width - 2 * VISUALIZATION_MARGIN}
              height={height - 2 * VISUALIZATION_MARGIN}
              data={aggregationData}
              dataKey="size"
              ratio={width / (height - 20)}
              stroke="#FFFFFF"
              fill={CHART_CAUTION}
              isAnimationActive={false}
              content={(
                <Content
                  setFilters={setFilters}
                  onCellClick={onCellClick}
                  tiny={width < 400 || height < 400}
                  colors={[CHART_SAFE, CHART_CAUTION, CHART_DANGER]}
                />
              )}
              style={{
                margin: VISUALIZATION_MARGIN,
                backgroundColor: VISUALIZATION_BACKGROUND,
              }}
            />
          </div>
          <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        </div>
      ))}
    </I18n>
  )
}

const TeamTreeMap = ({ dashboardFilters, ...rest }) => dashboardFilters && (
  <Suspense
    fallback={<Loader active />}
  >
    <AsyncTeamTreeMap dashboardFilters={dashboardFilters} {...rest} />
  </Suspense>
)

Content.propTypes = {
  tiny: bool,
  root: object,
  depth: number,
  x: number,
  y: number,
  width: number,
  height: number,
  index: number,
  colors: array,
  onCellClick: func,
  setFilters: array,
}

AsyncTeamTreeMap.propTypes = {
  dashboardId: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  setFilterMode: string,
  setFilters: array,
  onCellClick: func,
  timestamp: number,
  belongsToUser: bool,
}

TeamTreeMapLayout.propTypes = {
  aggregationData: array,
  onCellClick: func,
  setFilters: array,
}

export default TeamTreeMap
