import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import {CSVReader} from 'react-papaparse'
import modalStyles, {
  CloseIcon, CloseButtonTop,
} from '../modalStyles'
import {
  OuterContainer, ContentContainer, Title, DataRow, FieldHeader, Field,
  ButtonsWrapper, ImportButton, CancelButton, SaveButton, ApproversTable, Divider
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from "../../../requests/quarantine";
import {AuthContext} from "../../../@logicea/react-auth";

const QuarantineApprovers = () => {

  const auth = useContext(AuthContext)
  const context = useContext(DashboardsContext)
  const {
    isQuarantineApproversModalOpen,
    setQuarantineApproversModalVisibility,
    quarantineSettingsData,
    setQuarantineSettingsData,
  } = context

  const [approvers, setApprovers] = useState({})

  useEffect(() => {
    setApprovers(quarantineSettingsData?.approvers || {})
  }, [quarantineSettingsData])

  const fileInputRef = React.createRef();
  const handleOnError = (evt) => {
    console.log("handleOnError", evt)
  }
  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (fileInputRef.current) {
      fileInputRef.current.open(e)
    }
  }
  const handleFileLoad = (data) => {
    let newSenders = {}
    data.forEach(entry => {
      if (entry?.data?.length === 2) {
        newSenders[entry.data[0]] = entry.data[1]
      }
    })
    setApprovers(newSenders)
  }

  const handleSubmit = () => {
    let settings = quarantineSettingsData
    settings.approvers = approvers
    setQuarantineSettingsData(settings)
    actions.putQuarantineApproversAction('inky', auth, approvers)
    setQuarantineApproversModalVisibility(false)
  }

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isQuarantineApproversModalOpen}
        onRequestClose={() => setQuarantineApproversModalVisibility(false)}
        contentLabel={t({ key: 'MODAL_QUARANTINE_CONTENT_APPROVERS_LABEL' })}
        style={{
          overlay: modalStyles.overlay,
          content: modalStyles.content,
        }}
      >
        <OuterContainer>
          <Title>Approver List</Title>
          <Divider fitted/>
          <ContentContainer>
            <DataRow>
              <FieldHeader>Sender</FieldHeader>
              <FieldHeader>Approver</FieldHeader>
            </DataRow>
            <ApproversTable>
              {
                Object.entries(approvers)
                  .map( ([key, value]) => (
                  <DataRow key={key}>
                    <Field>{key}</Field>
                    <Field>{value}</Field>
                  </DataRow>
                ))
              }
            </ApproversTable>

          </ContentContainer>
          <CloseButtonTop>
            <CloseIcon onClick={() => setQuarantineApproversModalVisibility(false)} />
          </CloseButtonTop>
          <ButtonsWrapper>
            <CSVReader
              onError={handleOnError}
              onFileLoad={handleFileLoad}
              noDrag
              noClick
              style={{}}
              config={{}}
              ref={fileInputRef}
            >
              {({ file }) => (
                <aside>
                  <ImportButton type="button" onClick={handleOpenDialog}>Upload File</ImportButton>
                  <div>
                    {file && file.name}
                  </div>
                </aside>
              )}
            </CSVReader>
            <CancelButton type="button" onClick={() => setQuarantineApproversModalVisibility(false)}>{t({ key: 'CANCEL' })}</CancelButton>
            <SaveButton type="button" onClick={handleSubmit} dark>{t({ key: 'SAVE' })}</SaveButton>
          </ButtonsWrapper>
        </OuterContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default QuarantineApprovers
