import styled from 'styled-components'
import { Checkbox as SemanticCheckbox, Icon } from 'semantic-ui-react'
import {
  DARK_GREY,
  LIGHT_TEXT,
  DASHBOARD_FILTER_BAR,
  BACKGROUND_LIGHT,
  PRIMARY_DARK,
  CLOSE_BUTTON_HOVER,
  WHITE,
  DARK_TEXT,
  BACKGROUND_DARK,
} from 'static/colors'
import { MIN_WIDTH, MIN_HEIGHT } from 'static/appConfig'

const HEADER_ROW_HEIGHT = 42

export const Container = styled.div`
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  min-width: ${MIN_WIDTH}px;
  min-height: ${MIN_HEIGHT}px;
  display: flex;
  flex-direction: column;
`

export const SubHeader = styled.div``

export const StatisticsContainer = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 34px;
  background-color: ${DASHBOARD_FILTER_BAR};
`

export const StatisticsWrapper = styled.div`
  height: 26px;
  width: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: ${LIGHT_TEXT};
  background-color: ${DARK_GREY};
`

export const StatisticsIcon = styled(Icon).attrs({
  name: 'mail',
})`
  position: relative;
  bottom: 3px;
`

export const Segment = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const ReadOnly = styled.div`
  font-size: 18px;
  color: black;
  text-align: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  border-color: black;
  border-width: 1px;
  background-color: #e8e8e8;
  text-align: left;
  box-sizing: border-box;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  color: black;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const FlexList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Checkbox = styled(SemanticCheckbox)``

export const FieldHeader = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};

  &:hover {
    cursor: pointer;
  }
`

export const ChevronUp = styled(Icon).attrs({
  name: 'chevron up',
  size: 'small',
})``

export const FlexListContainer = styled.div`
  position: absolute;
  overflow: hidden;
  top: ${HEADER_ROW_HEIGHT}px;
`

export const ListContainer = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height - HEADER_ROW_HEIGHT}px;
`

export const EmptyList = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
`

export const ListGradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 32px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    ${WHITE}
  );
  pointer-events: none;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`

export const ChevronDown = styled(Icon).attrs({
  name: 'chevron down',
  size: 'small',
})``

const fieldStyles = `
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 13px;
  line-height: 25px;
  overflow: hidden;
`

export const Field = styled.div`
  ${fieldStyles}
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`

export const HeaderRow = styled.div`
  height: ${HEADER_ROW_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 10px;
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 13px;
  line-height: 25px;
  z-index: 1;
  border-bottom: 2px solid ${BACKGROUND_DARK};
`

export const MessageSegment = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: ${(props) => (props.paddingTop ? 64 : 0)}px 16px 0px 16px;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const AddButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  ${(props) =>
    props.wide
      ? 'top: -48px; left: 0;'
      : props.direction === 'horizontal'
      ? 'left: 80px; top: -48px;'
      : 'right: 0px; top: 32px;'};
  height: 34px;
  margin: ${(props) => (props.hasMargin ? 120 : 68)}px 12px 15px 15px;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.09);
  font-size: 15px;
  padding: 6px;
  background-color: ${PRIMARY_DARK};

  &:hover {
    cursor: pointer;
  }
`
export const AddIcon = styled.div`
  width: 21px;
  background-color: ${BACKGROUND_LIGHT};
  border-radius: 11px;
  color: ${PRIMARY_DARK};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 21px;
`

export const AddText = styled.div`
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: ${WHITE};
  margin: 0px 4px 0px 8px;
`

export const CloseMessageButton = styled.div`
  background-color: ${BACKGROUND_LIGHT};
  color: ${PRIMARY_DARK};
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 24px;
  line-height: 22px;
  border-radius: 24px;
  position: absolute;
  top: 20px;
  right: 28px;
  transition: background-color 0.3s;
  z-index: 1;

  &:hover {
    cursor: pointer;
    background-color: ${CLOSE_BUTTON_HOVER};
  }
`
