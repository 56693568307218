import React, { useCallback, useContext, useState } from 'react'
import Modal from 'react-modal'
import mergeRight from 'ramda/src/mergeRight'
import { I18n } from '../../../lib/i18n'
import { spacing } from '../../../static/theme'
import { DashboardsContext } from '../../../providers/Dashboards'
import modalStyles from '../modalStyles'
import { WidgetItem, WidgetItemType } from './components/WidgetItem'
import { Button } from '../components/Button'
import {
  ContentWrapper,
  Title,
  SelectType,
  Row,
  WidgetWrapper,
  Footer,
  SelectDeselectButton,
  CloseIcon,
  SelectDeselectAll,
  ButtonsWrapper,
} from './styles'

const widgets: Array<WidgetItemType> = [
  {
    id: 'pie',
    chartType: 'pie',
    title: 'Threat Level Overall',
    subtitle: 'Pie Chart',
  },
  {
    id: 'bar',
    chartType: 'bar',
    title: 'Threat Categories',
    subtitle: 'Bar Chart',
  },
  {
    id: 'line',
    chartType: 'line',
    title: 'Threat Level Over Time',
    subtitle: 'Line Chart',
  },
  {
    id: 'area',
    chartType: 'area',
    title: 'Threat Level Over Time',
    subtitle: 'Area Chart',
  },
  {
    id: 'list',
    chartType: 'list',
    title: 'Message List',
    subtitle: 'Table List',
  },
  {
    id: 'stack',
    chartType: 'stackBar',
    title: 'Threat Level per Recipient',
    subtitle: 'Stack Bar Chart',
  },
  {
    id: 'stack_sender',
    chartType: 'stackBar',
    title: 'Threat Level per Sender',
    subtitle: 'Stack Bar Chart',
  },
  {
    id: 'stack_sender_domain',
    chartType: 'stackBar',
    title: 'Threat Level per Domain',
    subtitle: 'Stack Bar Chart',
  },
  {
    id: 'tree',
    chartType: 'map',
    title: 'Team Map',
    subtitle: 'Tree Map Chart',
  },
  {
    id: 'zoom',
    chartType: 'mapZoomable',
    title: 'Zoomable Map',
    subtitle: 'Tree Map Chart',
  },
]

export const AddWidgetsModal = () => {
  const [selectedWidgets, setSelectedWidgets] = useState<Array<string>>([])
  const context = useContext(DashboardsContext) as any
  const {
    isAddWidgetsModalOpen,
    handleMessageClose,
    toggleAddWidgetsModal,
    handleAddWidgetsModalSubmit,
  } = context

  const onClose = useCallback(() => {
    handleMessageClose()
    toggleAddWidgetsModal()
    setSelectedWidgets([])
  }, [])

  const widgetAction = useCallback(
    ({ id }: { id: string }) => {
      const updated = selectedWidgets.find((i) => i === id)
      const selected = updated
        ? selectedWidgets.filter((i) => i !== id)
        : [...selectedWidgets, id]

      setSelectedWidgets(selected)
    },
    [selectedWidgets]
  )
  const isSelectedAllWidgets = selectedWidgets.length === widgets.length
  const selectDeselectAllChange = useCallback(() => {
    if (isSelectedAllWidgets) {
      setSelectedWidgets([])
    } else {
      const selectedAll: Array<string> = []
      widgets.map((i) => selectedAll.push(i.id))
      setSelectedWidgets(selectedAll)
    }
  }, [selectedWidgets])

  const addWidgetsAction = useCallback(() => {
    handleAddWidgetsModalSubmit(selectedWidgets)
    onClose()
  }, [selectedWidgets])

  return (
    <I18n>
      {({ t }) => (
        <Modal
          isOpen={isAddWidgetsModalOpen}
          onRequestClose={onClose}
          style={{
            overlay: modalStyles.overlay,
            content: mergeRight(modalStyles.content, {
              padding: spacing.xxl,
              maxHeight: '90vh',
              maxWidth: 1240,
              minWidth: 560,
              width: '90vw',
            }),
          }}
        >
          <ContentWrapper>
            <Title>{t({ key: 'MODAL_ADD_WIDGET' })}</Title>
            <SelectType>{t({ key: 'MODAL_WIDGET_SELECT_TYPE' })}</SelectType>
            <Row>
              {widgets.map((i) => (
                <WidgetWrapper>
                  <WidgetItem
                    selected={selectedWidgets.includes(i.id)}
                    onClick={() => widgetAction({ id: i.id })}
                    {...i}
                  />
                </WidgetWrapper>
              ))}
            </Row>
            <Footer>
              <SelectDeselectButton onClick={selectDeselectAllChange}>
                {isSelectedAllWidgets && <CloseIcon />}
                <SelectDeselectAll>
                  {isSelectedAllWidgets
                    ? t({ key: 'MODAL_WIDGET_DESELECT_ALL' })
                    : t({ key: 'MODAL_WIDGET_SELECT_ALL' })}
                </SelectDeselectAll>
              </SelectDeselectButton>
              <ButtonsWrapper>
                <Button cancel onClick={onClose}>
                  {t({ key: 'MODAL_CANCEL_BUTTON' })}
                </Button>
                <Button
                  onClick={addWidgetsAction}
                  disabled={selectedWidgets.length === 0}
                >
                  {t({
                    key: 'MODAL_ACTION_BUTTON',
                    variables: { action: 'add' },
                  })}
                </Button>
              </ButtonsWrapper>
            </Footer>
          </ContentWrapper>
        </Modal>
      )}
    </I18n>
  )
}
