import React from 'react'
import {
  WidgetTitle as Title,
  WidgetPadding as Padding,
} from 'components/ReportWidgets/styles'
import { Container, Content } from './styles'
import { Counter } from 'components/ReportComponents/Counter'

type MagicNumberProps = {
  title: string
  number?: number
  percent?: number // -1..1
}

const MagicNumber = (props: MagicNumberProps) => {
  const { title, number, percent } = props
  return (
    <Container>
      <Padding>
        <Title>{title}</Title>
        <Content>
          <Counter type='large' number={number ?? 0} percent={percent} />
        </Content>
      </Padding>
    </Container>
  )
}

const MagicNumberPlaceholder = (props: MagicNumberProps) => {
  const { title } = props
  return (
    <Container>
      <Padding>
        <Title>{title}</Title>
        <Content />
      </Padding>
    </Container>
  )
}

export { MagicNumber, MagicNumberPlaceholder }
