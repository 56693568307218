import styled from 'styled-components'
import {
  Accordion as MaterialUIAccordion,
  AccordionSummary as MaterialUIAccordionSummary,
  AccordionDetails as MaterialUIAccordionDetails,
} from '@material-ui/core'
import { Link } from '@reach/router'
import { Icon } from 'semantic-ui-react'
import { SIDEBAR_WIDTH_COLLAPSED, SIDEBAR_WIDTH } from '../../static/appConfig'
import barchartIcon from '../../assets/barchart/barchart.png'
import { ExpandMore } from '@material-ui/icons'
import {
  spacing,
  colors,
  HeadlineH3WhiteRegular,
  HeadlineH3PrimaryDarkRegular,
  Body1PrimaryDarkRegular,
} from 'static/theme'
import { ReactComponent as LogoutSVG } from 'assets/sidebar/logout.svg'
import { ReactComponent as UserSVG } from 'assets/sidebar/user.svg'
import { ReactComponent as OverviewSVG } from 'assets/sidebar/overview.svg'
import { ReactComponent as DashboardsSVG } from 'assets/sidebar/dashboards.svg'
import { ReactComponent as ReportsSVG } from 'assets/sidebar/reports.svg'
import { ReactComponent as SettingsSVG } from 'assets/sidebar/settings.svg'
import { ReactComponent as HelpSVG } from 'assets/sidebar/help.svg'
import { ReactComponent as FullLogoSVG } from 'assets/sidebar/full-logo.svg'
import { ReactComponent as LogoSVG } from 'assets/sidebar/logo.svg'
import { ReactComponent as ArrowRightSVG } from 'assets/sidebar/arrow-right.svg'
import { ReactComponent as ArrowLeftSVG } from 'assets/sidebar/arrow-left.svg'
import logo from 'assets/sidebar/INKY_TM.png'

export const Container = styled.div`
  width: ${(props) =>
    props.collapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.primaryMain};
  padding-bottom: 80px;
`

export const TitleWrapper = styled.div`
  padding: ${(props) => (props.collapsed ? spacing.xs2 : spacing.l)};
  justify-content: center;
  position: relative;
`

export const Title = styled(Link)`
  color: ${colors.white};

  &:hover {
    color: ${colors.white};
    text-decoration: none;
    outline: none;
  }

  &:focus {
    color: ${colors.white};
    text-decoration: none;
    outline: none;
  }

  &:active {
    color: ${colors.white};
    text-decoration: none;
    outline: none;
  }
`

export const ChevronIcon = styled(ExpandMore)`
  color: ${colors.white};
`

export const Accordion = styled(MaterialUIAccordion)`
  && {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
  }
`

export const AccordionNoMargin = styled(MaterialUIAccordion)`
  && {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    margin: 0 !important;
  }
`

export const AccordionSummary = styled(MaterialUIAccordionSummary)`
  && .MuiAccordionSummary-expandIcon {
    padding: 10px;
  }
  && .MuiAccordionSummary-content {
    align-items: center;
    margin: 0px;
  }
  && {
    padding: 0;
    flex: 1;
    height: 32px;
  }
`

export const AccordionDetails = styled(MaterialUIAccordionDetails)`
  && {
    padding: 0rem 0rem 0rem 2.8rem;
  }
`

export const Username = styled(HeadlineH3WhiteRegular)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Menu = styled.div`
  ${(props) => (props.collapsed ? '' : 'overflow-y: auto;')}
  padding-bottom: 16px;
`

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s} 0;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`

export const CollapsedMenuItemRowWrapper = styled.div``

export const CollapsedMenuItemRow = styled.div`
  display: none;
  position: absolute;
  left: ${SIDEBAR_WIDTH_COLLAPSED}px;
  background-color: ${colors.primaryMain};
  padding: ${spacing.s};
  ${MenuItem}:hover & {
    display: flex;
    z-index: 3;
  }
`

export const MenuItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0rem ${spacing.s};
`

export const Selected = styled.div`
  position: absolute;
  left: 0px;
  height: 32px;
  width: ${(props) => (props.active ? '8px' : '0px')};
  background-color: ${colors.secondaryMain};
`

export const MenuItemText = styled(HeadlineH3PrimaryDarkRegular)`
  margin: 0rem ${spacing.xs};
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.active && colors.white};
  ${MenuItem}:hover & {
    color: ${colors.white};
  }
`

const submenuItemStyles = `
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background-color 0.5s;
  padding: ${spacing.xs} 0rem;
`

export const Logout = styled.div`
  ${submenuItemStyles}
  & :hover {
    cursor: pointer;
  }
`

export const LogoutLink = styled(Body1PrimaryDarkRegular)`
  margin-left: ${spacing.xs};
  ${Logout}:hover & {
    color: ${colors.white};
  }
`

export const SubmenuItem = styled(Body1PrimaryDarkRegular)`
  ${submenuItemStyles}
  ${(props) => (props.selected ? `color: ${colors.white}` : '')};

  &:hover {
    cursor: pointer;
    color: ${colors.white};
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Seperator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.primaryDark};
  opacity: 0.5;
  margin-top: 26px;
`

export const DeleteDashboardIcon = styled(Icon).attrs({
  name: 'trash alternate outline',
})`
  cursor: pointer;
`

export const RefreshDashboardIcon = styled(Icon).attrs({
  name: 'refresh',
  size: 'small',
})`
  position: absolute;
  font-size: 14px;
  right: 9px;
  top: 16px;
  cursor: pointer;
`

export const StatisticsIcon = styled.img.attrs({
  src: barchartIcon,
  alt: 'statistics',
})`
  width: 25px;
  padding: 0px 3px 2px 2px;
  filter: contrast(100) invert(100);
`

export const UserIcon = styled(UserSVG)`
  fill: ${colors.white};
`

export const OverviewIcon = styled(OverviewSVG)`
  fill: ${(props) => (props.active ? colors.white : colors.primaryDark)};
  ${MenuItem}:hover & {
    fill: ${colors.white};
  }
`

export const DashboardsIcon = styled(DashboardsSVG)`
  fill: ${(props) => (props.active ? colors.white : colors.primaryDark)};
  ${MenuItem}:hover & {
    fill: ${colors.white};
  }
`

export const ReportsIcon = styled(ReportsSVG)`
  fill: ${(props) => (props.active ? colors.white : colors.primaryDark)};
  ${MenuItem}:hover & {
    fill: ${colors.white};
  }
`

export const SettingsIcon = styled(SettingsSVG)`
  fill: ${(props) => (props.active ? colors.white : colors.primaryDark)};
  ${MenuItem}:hover & {
    fill: ${colors.white};
  }
`

export const HelpIcon = styled(HelpSVG)`
  fill: ${colors.primaryDark};
  ${MenuItem}:hover & {
    fill: ${colors.white};
  }
`

export const LogoutIcon = styled(LogoutSVG)`
  fill: ${colors.primaryDark};
  ${Logout}:hover & {
    fill: ${colors.white};
  }
`

export const FullLogoIcon = styled.img.attrs({
  src: logo
})`
  width: 120px;
  margin-top: -8px;
  margin-left: -8px;
`

export const LogoIcon = styled.img.attrs({
  src: logo
})`
  width: 40px;
  margin: 8px 0;
`

export const ToggleWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: ${spacing.m};
  padding: 0rem ${spacing.s};
`

export const ToggleText = styled(HeadlineH3PrimaryDarkRegular)`
  margin: 0rem ${spacing.xs};
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.active && colors.white};
  ${ToggleWrapper}:hover & {
    color: ${colors.white};
  }
`

export const ArrowRightIcon = styled(ArrowRightSVG)`
  fill: ${colors.primaryDark};
  ${ToggleWrapper}:hover & {
    fill: ${colors.white};
  }
`
export const ArrowLeftIcon = styled(ArrowLeftSVG)`
  fill: ${colors.primaryDark};
  ${ToggleWrapper}:hover & {
    fill: ${colors.white};
  }
`
