import { titleCase } from "title-case"

export const RESULT_CATEGORY_NAMES = name => {
  switch (name) {
    case 'NEUTRAL':
      return 'Neutral'
    case 'CAUTION-NON-SPAM':
      return 'Caution (Non Spam)'
    case 'CAUTION-SPAM':
      return 'Caution (Spam)'
    case 'DANGER':
      return 'Danger (Phish or Malware)'
    case 'DANGER-HI':
      return 'Danger (High Conf. Phish or Malware)'
    case 'CAUTION-HI-SPAM':
      return 'Caution (High Confidence Spam)'
    default:
      return titleCase(name)
  }
}
