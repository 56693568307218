import styled from 'styled-components'
import { Body1SecondaryMainRegular, LatoBold, spacing } from 'static/theme'

export const MAX_WIDGET_WIDTH = '58rem'

export const WidgetPadding = styled.div`
  padding: ${spacing.m1};
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const WidgetTitle = styled(LatoBold)``

export const WidgetSubtitle = styled(Body1SecondaryMainRegular)`
  margin-top: ${spacing.xs};
  word-break: break-all;
  height: 1.5rem;
  width: 100%;
`
