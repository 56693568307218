import React from 'react'
import Modal from 'react-modal'
import TagModal from './TagModal'
import TourModal from './TourModal'
import { ActionModal } from './ActionModal'
import MessageModal from './MessageModal'
import FilterEditor from './FilterEditor'
import UserPermissions from './UserPermissions'
import ConfirmationModal from './ConfirmationModal'
import MessageSetSettings from './MessageSetSettings'
import DashboardPermissions from './DashboardPermissions'
import RemediationModal from "./RemediationModal";
import ListActionModal from "./ListActionModal";
import QuarantineSettings from "./QuarantineSettings";
import QuarantineApprovers from "./QuarantineApprovers";
import QuarantinePolicy from "./QuarantinePolicy";
import CreateAllowBlockListEntry from "./CreateAllowBlockListEntry";
import ReportActionModal from "./ReportActionModal";
import { AddWidgetsModal } from "./AddWidgetsModal";

Modal.setAppElement('#root')

const Modals = () => (
  <React.Fragment>
    <TagModal />
    <TourModal />
    <MessageModal />
    <FilterEditor />
    <UserPermissions />
    <ConfirmationModal />
    <MessageSetSettings />
    <DashboardPermissions />
    <RemediationModal />
    <ListActionModal />
    <QuarantineSettings />
    <QuarantineApprovers />
    <QuarantinePolicy />
    <ActionModal />
    <CreateAllowBlockListEntry />
    <ReportActionModal />
    <AddWidgetsModal />
  </React.Fragment>
)

export default Modals
