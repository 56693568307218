import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import {
  DARK_GREY,
  LIGHT_TEXT,
  DASHBOARD_FILTER_BAR,
  BACKGROUND_LIGHT,
  PRIMARY_DARK,
  CLOSE_BUTTON_HOVER,
  WHITE,
} from '../../../static/colors'

export const Container = styled.div`
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
`

export const SubHeader = styled.div``

export const StatisticsContainer = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 34px;
  background-color: ${DASHBOARD_FILTER_BAR};
`

export const StatisticsWrapper = styled.div`
  height: 26px;
  width: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: ${LIGHT_TEXT};
  background-color: ${DARK_GREY};
`

export const StatisticsIcon = styled(Icon).attrs({
  name: 'mail',
})`
  position: relative;
  bottom: 3px;
`

export const Segment = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const CloseMessageButton = styled.div`
  background-color: ${BACKGROUND_LIGHT};
  color: ${PRIMARY_DARK};
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 24px;
  line-height: 22px;
  border-radius: 24px;
  position: absolute;
  top: 20px;
  right: 28px;
  transition: background-color 0.3s;
  z-index: 1;

  &:hover {
    cursor: pointer;
    background-color: ${CLOSE_BUTTON_HOVER};
  }
`
