import React from 'react'
import { TickText } from './styles'

const CountTick = ({
  x,
  y,
  payload,
}: {
  x?: number
  y?: number
  payload?: { value: number }
}) => {
  const value = payload?.value
  if (value !== undefined) {
    let displayValue = value.toString()
    if (value > 999999) {
      const floatString = (value / 1000000).toFixed(1)
      displayValue = `${parseFloat(floatString).toString()}M`
    } else if (value > 999) {
      const floatString = (value / 1000).toFixed(1)
      displayValue = `${parseFloat(floatString).toString()}K`
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <TickText textAnchor='end'>{displayValue}</TickText>
      </g>
    )
  } else {
    return null
  }
}

export { CountTick }
