import styled from 'styled-components'
import { Button } from '@material-ui/core'
import {
  colors,
  spacing,
  HeadlineH3PrimaryDarkRegular,
  HeadlineH3WhiteRegular,
} from 'static/theme'

export const MaterialUIButton = styled(Button)`
  && {
    margin-left: ${spacing.s};
    padding: ${spacing.xs2} ${spacing.m};
    border-color: ${colors.primaryDark};
  }
`

export const CancelText = styled(HeadlineH3PrimaryDarkRegular)``

export const SubmitText = styled(HeadlineH3WhiteRegular)``
