import React, { useContext } from 'react'
import { DashboardsContext } from 'providers/Dashboards'
import { Container, WideContainer, NarrowContainer } from './styles'

const Row = ({ children }: {children: React.ReactNode | React.ReactNodeArray}) => {
  return (
    <Container>
      {children}
    </Container>
  )
}

type ResponsiveRowProps = {
  children: React.ReactNode | React.ReactNodeArray
  showOnWide: boolean
}

const ResponsiveRow = ({ children, showOnWide }: ResponsiveRowProps) => {
  const { isSidebarCollapsed } = useContext(DashboardsContext)

  return showOnWide ? (
    <WideContainer isSidebarCollapsed={isSidebarCollapsed}>
      {children}
    </WideContainer>
  ) : (
    <NarrowContainer isSidebarCollapsed={isSidebarCollapsed}>
      {children}
    </NarrowContainer>
  )
}

export { Row, ResponsiveRow }
