import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import {
  BACKGROUND_DARK,
  HEADER_BACKGROUND,
} from '../../static/colors'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
`

export const NavigationBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 11px 0px 16px;
  margin-bottom: -4px;
`

export const NavigationButton = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${HEADER_BACKGROUND};
  border: 2px solid ${BACKGROUND_DARK};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;

  &:hover {
    cursor: pointer;
  }
`

export const BackIcon = styled(Icon).attrs({
  name: 'chevron left',
  color: 'black',
  size: 'large',
  inverted: true,
})``

export const ForwardIcon = styled(Icon).attrs({
  name: 'chevron right',
  color: 'black',
  size: 'large',
  inverted: true,
})``
