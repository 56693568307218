import styled from 'styled-components'
import {
  LIGHT_TEXT,
  DARK_GREY,
} from '../../../static/colors'

export const Container = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
`

export const AbsolutePosition = styled.div`
  position: absolute;
`

export const TickText = styled.text`
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;

  ${props => (props.isClickable ? `
    &:hover {
      cursor: pointer;
    }
  ` : '')}
`

export const ScaleButton = styled.span`
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-family: Lato;
  font-size: 12px;
  color: ${LIGHT_TEXT};
  background-color: ${DARK_GREY};
  border-radius: 8px;
  padding: 2px 8px 2px 8px;

  &:hover {
    cursor: pointer;
  }
`
