import React, {
  ReactNode,
  ReactNodeArray,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import omit from 'ramda/src/omit'
import { useQuery, useMutation, queryCache } from 'react-query'
import { navigate, useLocation } from '@reach/router'
import { AuthContext } from '@logicea/react-auth'
import userActions from 'requests/user'
import {
  INITIAL_QUICK_TOUR_STATE,
  QuickTourStateT,
  RouteQuickTourT,
} from './initialState'

const getCurrentStepId = (tour: RouteQuickTourT) => {
  if (!tour) return ''
  const step = tour.currentStepId || tour.steps.find((s) => !s.visited)?.id
  return step || tour.steps[0].id
}

export type HelpState = {
  isHelpGuideEnabled: boolean
  isQuickTourEnabled: boolean
  currentQuickTourStepId: string
  quickTourState: QuickTourStateT
  setIsHelpGuideEnabled: (val: boolean) => void
  setIsQuickTourEnabled: (val: boolean) => void
  setCurrentQuickTourStepId: (val: string) => void
  setQuickTourState: (val: QuickTourStateT) => void
  toggleIsHelpGuideEnabled: () => void
  toggleIsQuickTourEnabled: () => void
  advanceToNextStep: () => void
  goToPreviousStep: () => void
  skipTour: () => void
}

const INITIAL_STATE: HelpState = {
  isHelpGuideEnabled: false,
  isQuickTourEnabled: true,
  currentQuickTourStepId: '',
  quickTourState: INITIAL_QUICK_TOUR_STATE,
  setIsHelpGuideEnabled: () => {},
  setIsQuickTourEnabled: () => {},
  setQuickTourState: () => {},
  setCurrentQuickTourStepId: () => {},
  toggleIsHelpGuideEnabled: () => {},
  toggleIsQuickTourEnabled: () => {},
  advanceToNextStep: () => {},
  goToPreviousStep: () => {},
  skipTour: () => {},
}

const Context = React.createContext<HelpState>(INITIAL_STATE)

export const useHelp = () => useContext(Context)

export const HelpProvider = ({
  children,
}: {
  children: ReactNode | ReactNodeArray
}) => {
  const auth = useContext(AuthContext)
  const { realm, tokens } = auth as any
  const location = useLocation()
  const [isHelpGuideEnabled, setIsHelpGuideEnabled] = useState(false)
  const [isQuickTourEnabled, setIsQuickTourEnabled] = useState(false)
  const [currentQuickTourStepId, setCurrentQuickTourStepId] = useState('')
  const [quickTourState, setQuickTourState] = useState(INITIAL_QUICK_TOUR_STATE)

  const { data, isLoading } = useQuery(
    [
      'quickTourProgress',
      {
        realm,
        token: tokens?.accessToken,
      },
    ],
    userActions.getQuickTourProgressAction,
    { enabled: realm !== undefined && tokens?.accessToken !== undefined }
  )

  const savedProgress = data as QuickTourStateT

  const [update] = useMutation(userActions.updateQuickTourProgressAction, {
    onSuccess: (data, variables) => {
      queryCache.setQueryData(
        [
          'quickTourProgress',
          {
            realm,
            token: tokens?.accessToken,
          },
        ],
        omit(['token', 'realm'], variables)
      )
    },
  })

  useEffect(() => {
    if (
      savedProgress !== null &&
      savedProgress !== undefined &&
      savedProgress.version >= quickTourState.version
    ) {
      setQuickTourState(savedProgress)
      setCurrentQuickTourStepId(
        getCurrentStepId(savedProgress?.state[location.pathname])
      )
      setIsQuickTourEnabled(
        savedProgress?.state[location.pathname]?.status === 'NEW'
      )
    } else {
      setCurrentQuickTourStepId(
        getCurrentStepId(quickTourState?.state[location.pathname])
      )
      setIsQuickTourEnabled(!isLoading)
    }
  }, [savedProgress, location, isLoading])

  const toggleIsQuickTourEnabled = useCallback(() => {
    if (!isQuickTourEnabled && currentQuickTourStepId) {
      setIsQuickTourEnabled(true)
    } else {
      setIsQuickTourEnabled(false)
    }
  }, [isQuickTourEnabled, currentQuickTourStepId])

  const advanceToNextStep = useCallback(() => {
    const tour = quickTourState.state[location.pathname]
    const currentStepIndex = tour.steps.findIndex(
      (s) => s.id === currentQuickTourStepId
    )
    const newSteps = tour.steps.map((s) => ({
      ...s,
      visited: s.id === currentQuickTourStepId ? true : s.visited,
    }))
    if (currentStepIndex === tour.steps.length - 1) {
      const newState: QuickTourStateT = {
        version: quickTourState.version,
        state: {
          ...quickTourState.state,
          [location.pathname]: {
            ...tour,
            steps: newSteps,
            status: 'COMPLETED',
            currentStepId: null,
          },
        },
      }
      setQuickTourState(newState)
      if (tour.navigateTo === null) {
        setIsQuickTourEnabled(false)
      } else {
        navigate(tour.navigateTo.path)
      }
      update({ ...newState, realm, token: tokens?.accessToken })
    } else {
      const nextStepId = tour.steps[currentStepIndex + 1].id
      const newState: QuickTourStateT = {
        version: quickTourState.version,
        state: {
          ...quickTourState.state,
          [location.pathname]: {
            ...tour,
            steps: newSteps,
            currentStepId: nextStepId,
          },
        },
      }
      setQuickTourState(newState)
      setCurrentQuickTourStepId(nextStepId)
      update({ ...newState, realm, token: tokens?.accessToken })
    }
  }, [location, currentQuickTourStepId, quickTourState])

  const goToPreviousStep = useCallback(() => {
    const tour = quickTourState.state[location.pathname]
    const currentStepIndex = tour.steps.findIndex(
      (s) => s.id === currentQuickTourStepId
    )
    if (currentStepIndex === 0) {
      if (tour.navigateFrom === null) {
        setIsQuickTourEnabled(false)
      } else {
        navigate(tour.navigateFrom.path)
      }
    } else {
      const nextStepId = tour.steps[currentStepIndex - 1].id
      const newState: QuickTourStateT = {
        version: quickTourState.version,
        state: {
          ...quickTourState.state,
          [location.pathname]: {
            ...tour,
            currentStepId: nextStepId,
          },
        },
      }
      setQuickTourState(newState)
      setCurrentQuickTourStepId(nextStepId)
    }
  }, [location, currentQuickTourStepId, quickTourState])

  const skipTour = useCallback(() => {
    const tour = quickTourState.state[location.pathname]
    const newState: QuickTourStateT = {
      version: quickTourState.version,
      state: {
        ...quickTourState.state,
        [location.pathname]: {
          ...tour,
          status: 'SKIPPED',
        },
      },
    }
    setQuickTourState(newState)
    setIsQuickTourEnabled(false)
    update({ ...newState, realm, token: tokens?.accessToken })
  }, [location, quickTourState])

  const contextValue = {
    isHelpGuideEnabled,
    isQuickTourEnabled,
    currentQuickTourStepId,
    quickTourState,
    setIsHelpGuideEnabled,
    setIsQuickTourEnabled,
    setCurrentQuickTourStepId,
    setQuickTourState,
    toggleIsHelpGuideEnabled: () => {
      setIsHelpGuideEnabled(!isHelpGuideEnabled)
    },
    toggleIsQuickTourEnabled,
    advanceToNextStep,
    goToPreviousStep,
    skipTour,
  }

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}
