import React, { useContext } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, Router as ReachRouter } from '@reach/router'
import ErrorBoundary from '../ErrorBoundary'
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_COLLAPSED } from 'static/appConfig'
import { DashboardsContext } from 'providers/Dashboards'
import Dashboard from 'components/App/Dashboard'
import Message from 'components/App/Message'
import Marketplace from 'components/App/Marketplace'
import Settings from 'components/App/Settings'
import Reports from 'components/App/Reports'

type RouterProps = Readonly<{
  isSidebarCollapsed: boolean
}>

export const Router = styled(ReachRouter)<RouterProps>`
  display: flex;
  width: ${(props) =>
    `calc(100% - ${
      props.isSidebarCollapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH
    }px)`};
  overflow-x: auto;
`

const RouterPage = (
  props: {
    pageComponent: JSX.Element
    children?: JSX.Element
  } & RouteComponentProps
) => props.pageComponent

const MainApp = ({ initialRoute }: { initialRoute: string }) => {
  const dashboard = useContext(DashboardsContext) as any // TODO remove this with typescript DashboardContext
  const { isSidebarCollapsed } = dashboard
  return (
    <Router isSidebarCollapsed={isSidebarCollapsed}>
      <RouterPage pageComponent={<Reports />} path='*'></RouterPage>
      <RouterPage pageComponent={<Marketplace />} path='dashboards' />
      <RouterPage
        pageComponent={<Dashboard />}
        path='dashboards/:dashboardId'
      />
      <RouterPage
        pageComponent={<Message />}
        path='messages/:messageIndex/:messageId'
      />
      <RouterPage pageComponent={<Settings />} path='settings/*'></RouterPage>
      <RouterPage
        pageComponent={<Dashboard />}
        path='reports/:dashboardId'
      ></RouterPage>
    </Router>
  )
}

export const AppRouter = ({ initialRoute }: { initialRoute: string }) => (
  <ErrorBoundary fallback={<MainApp initialRoute={initialRoute} />}>
    <MainApp initialRoute={initialRoute} />
  </ErrorBoundary>
)
