import React, { useState, useContext } from 'react'
import isNil from 'ramda/src/isNil'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import modalStyles, {
  Container,
  CloseButton,
  CloseIcon,
} from '../modalStyles'
import {
  ContentWrapper,
  Sidebar,
  SidebarItem,
  Screen,
  ScreenContentWrapper,
  Title,
  Text,
  TextWrapper,
  Graphics,
  Margin,
  ControlWrapper,
  NavigationWrapper,
  NextButton,
  BackButton,
  CreateButton,
  CreateButtonText,
  CreateButtonIcon,
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'
import graphic0 from '../../../assets/tour/0.jpg'
import graphic1 from '../../../assets/tour/1.jpg'
import graphic2 from '../../../assets/tour/2.jpg'
import graphic3 from '../../../assets/tour/3.jpg'
import graphic4 from '../../../assets/tour/4.jpg'
import graphic5 from '../../../assets/tour/5.jpg'
import graphic6 from '../../../assets/tour/6.jpg'
import graphic7 from '../../../assets/tour/7.jpg'
import graphic8 from '../../../assets/tour/8.jpg'
import graphic9 from '../../../assets/tour/9.jpg'
import graphic10 from '../../../assets/tour/10.jpg'
import graphic11 from '../../../assets/tour/11.jpg'

const graphics = [
  graphic0,
  graphic1,
  graphic2,
  graphic3,
  graphic4,
  graphic5,
  graphic6,
  graphic7,
  graphic8,
  graphic9,
  graphic10,
  graphic11,
]

const renderSidebarItem = (selectedItem, setSelectedItem, index, text) => (
  <SidebarItem
    selected={selectedItem === index}
    onClick={() => setSelectedItem(index)}
  >
    {text}
  </SidebarItem>
)

const TourModal = () => {
  const [selectedItem, setSelectedItem] = useState(0)

  const context = useContext(DashboardsContext)
  const {
    isTourModalOpen,
    toggleTourModal,
    defaultDashboards,
    addDefaultDashboards,
  } = context

  const handleCreateDashboardsClick = () => {
    toggleTourModal()
    if (!isNil(defaultDashboards)) {
      addDefaultDashboards(defaultDashboards)
    }
  }

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isTourModalOpen}
        contentLabel={t({ key: 'MODAL_CONFIRMATION' })}
        style={{
          overlay: modalStyles.overlay,
          content: modalStyles.content,
        }}
      >
        <Container>
          <ContentWrapper>
            <Sidebar>
              {renderSidebarItem(selectedItem, setSelectedItem, 0, t({ key: 'INTRO_QUICK_TOUR' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 1, t({ key: 'INTRO_DASHBOARDS' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 2, t({ key: 'INTRO_ACTIONS' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 3, t({ key: 'INTRO_NAMES_LAYOUTS' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 4, t({ key: 'INTRO_FILTERS' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 5, t({ key: 'INTRO_DASHBOARD_FILTERS' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 6, t({ key: 'INTRO_MESSAGE_SETS' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 7, t({ key: 'INTRO_MESSAGE_SET_FILTERS' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 8, t({ key: 'INTRO_MESSAGE_SET_VIEWS' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 9, t({ key: 'INTRO_ZOOM_IN' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 10, t({ key: 'INTRO_OPERATIONS' }))}
              {renderSidebarItem(selectedItem, setSelectedItem, 11, t({ key: 'INTRO_MORE' }))}
            </Sidebar>
            <Screen>
              <CloseButton onClick={toggleTourModal}>
                <CloseIcon />
              </CloseButton>
              <ScreenContentWrapper>
                {selectedItem === 0 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_QUICK_TOUR' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_QUICK_TOUR' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem === 1 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_DASHBOARDS' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_DASHBOARDS' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem === 2 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_ACTIONS' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_ACTIONS_1' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem === 3 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_NAMES_LAYOUTS' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_NAMES_LAYOUTS' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem === 4 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_FILTERS' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_FILTERS_1' })}</Text>
                      <Text>{t({ key: 'INTRO_TEXT_FILTERS_4' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem === 5 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_DASHBOARD_FILTERS' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_DASHBOARD_FILTERS' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem === 6 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_MESSAGE_SETS' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_MESSAGE_SETS_1' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem === 7 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_MESSAGE_SET_FILTERS' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_MESSAGE_SET_FILTERS_1' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem === 8 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_MESSAGE_SET_VIEWS' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_MESSAGE_SET_VIEWS' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem === 9 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_ZOOM_IN' })}</Title>
                  </React.Fragment>
                )}
                {selectedItem === 10 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_OPERATIONS' })}</Title>
                  </React.Fragment>
                )}
                {selectedItem === 11 && (
                  <React.Fragment>
                    <Title>{t({ key: 'INTRO_MORE' })}</Title>
                    <Graphics src={graphics[11]} />
                    <Margin />
                    <Title>{t({ key: 'INTRO_DEFAULT' })}</Title>
                    <TextWrapper>
                      <Text>{t({ key: 'INTRO_TEXT_DEFAULT' })}</Text>
                    </TextWrapper>
                  </React.Fragment>
                )}
                {selectedItem !== 11 && (
                  <Graphics src={graphics[selectedItem]} />
                )}
              </ScreenContentWrapper>
              <ControlWrapper>
                <CreateButton onClick={handleCreateDashboardsClick}>
                  <CreateButtonIcon>+</CreateButtonIcon>
                  <CreateButtonText>
                    {t({ key: 'END_TOUR_BUTTON' })}
                  </CreateButtonText>
                </CreateButton>
                <NavigationWrapper>
                  <BackButton
                    active={selectedItem > 0}
                    onClick={() => {
                      if (selectedItem > 0) setSelectedItem(selectedItem - 1)
                    }}
                  />
                  <NextButton
                    active={selectedItem < 11}
                    onClick={() => {
                      if (selectedItem < 11) setSelectedItem(selectedItem + 1)
                    }}
                  />
                </NavigationWrapper>
              </ControlWrapper>
            </Screen>
          </ContentWrapper>
        </Container>
      </Modal>
    )}
    </I18n>
  )
}

export default TourModal
