import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isNil from 'ramda/src/isNil'
import equals from 'ramda/src/equals'
import propEq from 'ramda/src/propEq'
import eqProps from 'ramda/src/eqProps'
import findIndex from 'ramda/src/findIndex'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import messageActions from '../../../requests/message'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import { I18n } from '../../../lib/i18n'

const { object, func, array, string } = PropTypes

class ReportLabelInput extends Component {
  state = {
    suggestions: [],
    selectedLabels: [],
  }

  componentDidMount() {
    const { filter } = this.props
    if (filter && !isNil(filter.term)) this.handleTermChange(filter.term)
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props
    if (eqProps('name', prevProps.filter, filter)) return
    this.handleTermChange(filter.term)
  }

  handleTermChange = async term => {
    const { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token } = this.props
    const isKey = keys => e => findIndex(equals(e.reportLabel), keys) > -1
    const data = await messageActions.aggregateByReportLabelAction({
      dashboardFilters: rFilter(f => !propEq('name', 'report_label')(f), dashboardFilters),
      setFilters: rFilter(f => !propEq('name', 'report_label')(f), setFilters),
      dashboardFilterMode,
      setFilterMode,
    }, { realm, token })
    const newLabels = data.data
    this.setState({
      suggestions: newLabels,
      selectedLabels: rFilter(isKey(term), newLabels),
    })
  }

  handleRowClick = level => {
    const { onChange } = this.props
    const { selectedLabels } = this.state
    const newLabels = symmetricDifference(selectedLabels, [level])
    this.setState({ selectedLabels: newLabels })
    onChange(newLabels.map(l => l.reportLabel))
  }

  render() {
    const { suggestions, selectedLabels } = this.state

    return (
      <I18n>{({ t }) => (
        <Container>
          <SelectionHeader>
            <SelectionHeaderItem>
              {suggestions.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                </StatisticsTextBold>
              ) : suggestions.length === 1 ? (
                <StatisticsTextBold>
                  {suggestions.length}
                  <StatisticsText>({t({ key: 'SELECTION_MATCH' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {suggestions.length}
                  <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
            <SelectionHeaderItem right>
              {selectedLabels.length > 100 ? (
                <StatisticsTextBold>
                  100+
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              ) : (
                <StatisticsTextBold>
                  {selectedLabels.length}
                  <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                </StatisticsTextBold>
              )}
            </SelectionHeaderItem>
          </SelectionHeader>
          <SelectionWrapper>
            <SelectionColumnWrapper>
              <Scrollbar>
                {suggestions.map(agg => {
                  const isSelected = findIndex(equals(agg), selectedLabels) > -1
                  return (
                    <SuggestionWrapper>
                      <Suggestion
                        key={agg.reportLabel}
                        isSelected={isSelected}
                        onClick={isSelected ? null
                          : () => this.handleRowClick(agg)
                        }
                      >
                        <SuggestionText>
                          <strong>{agg.reportLabel || t({ key: 'NONE' })}</strong>
                        </SuggestionText>
                        {t({
                          key: 'AGGREGATION_HITS_PARENTHESIS',
                          variables: { aggregationHits: agg.value },
                        })}
                      </Suggestion>
                    </SuggestionWrapper>
                  )
                })}
              </Scrollbar>
            </SelectionColumnWrapper>
            <Seperator>
              <SeperatorIcon />
            </Seperator>
            <SelectionColumnWrapper right>
              <Scrollbar>
                {selectedLabels.map(selectedLabel => (
                  <SuggestionWrapper>
                    <Suggestion
                      closeable
                      key={selectedLabel.reportLabel}
                      isSelected={false}
                      onClick={() => this.handleRowClick(selectedLabel)}
                    >
                      <SuggestionText>{selectedLabel.reportLabel || t({ key: 'NONE' }) }
                      </SuggestionText>
                      {
                        t({
                          key: 'AGGREGATION_HITS_PARENTHESIS',
                          variables: { aggregationHits: selectedLabel.value },
                        })
                    }
                      <CloseIcon>×</CloseIcon>
                    </Suggestion>
                  </SuggestionWrapper>
                ))}
              </Scrollbar>
            </SelectionColumnWrapper>
          </SelectionWrapper>
        </Container>
      )}
      </I18n>
    )
  }
}

ReportLabelInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default ReportLabelInput
