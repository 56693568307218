import styled from 'styled-components'
import { MODAL_SIDEBAR } from '../../../static/colors'

export const UserPermissionsContainer = styled.div`
  width: 908px;
  height: 600px;
  max-width: 90vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`

export const UserSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 25%;
  height: 100%;
  padding: 18px;
  background-color: ${MODAL_SIDEBAR};
`

export const PermissionsArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  height: 100%;
  padding: 8px;
`

export const Label = styled.div`
  color: grey;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid lightgrey;
`
