import styled from 'styled-components'
import {Icon, Popup} from 'semantic-ui-react'
import {
  DARK_TEXT,
  MESSAGE_HEADER_LABEL,
  BORDER_LIGHT,
  HIGHLIGHTED_GREEN,
  NEGATIVE_VALUE,
} from '../../../../static/colors'

const ReportIcon = styled(Icon)`
  padding-right: 8px;
`

export const Container = styled.div`
  flex: 1;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  overflow-wrap: break-word;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`

export const Headers = styled.div`
  flex: 1;
  margin-top: 10px;
`

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 5px;
`

export const HeaderLabel = styled.div`
  color: ${MESSAGE_HEADER_LABEL};
  text-align: left;
`

export const ExtraLabel = styled.div`
  color: ${MESSAGE_HEADER_LABEL};
  text-align: left;
  margin: 10px 0px 5px 8px;
`

export const HeaderValue = styled.div`
  color: ${DARK_TEXT};
  margin-left: 6px;
  white-space: nowrap;
`

export const AccordionWrapper = styled.div`
  overflow: auto;
  font-size: 12px;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 5px;
`

export const AttachmentsCol1 = styled.div`
  display: flex;
  flex-direction: column;   
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 5px;
  flex: 0.2;
`

export const AttachmentsCol2 = styled.div`
  display: flex;
  flex-direction: column;   
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 5px;
  flex: 0.8;
`

export const FlexList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width:100%;
  height:100%;
`

export const FlexListContainer = styled.div`
`

export const ListContainer = styled.div`
`

export const ActionIconListItem = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ActionBarIcon = styled(Icon)`
  padding-right: 8px;
`

export const AllowlistIcon = styled(ActionBarIcon).attrs({
  name: 'check',
})`
  color: grey;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  z-index: 1;
  font-size: 14px !important;
  width: unset !important;
  height: unset !important;
`

export const BlocklistIcon = styled(ActionBarIcon).attrs({
  name: 'ban',
})`
  color: grey;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  z-index: 1;
  font-size: 14px !important;
  width: unset !important;
  height: unset !important;
`

export const PolicyIcon = styled(ActionBarIcon).attrs({
  name: 'cogs',
})`
  color: grey;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  z-index: 1;
  font-size: 14px !important;
  width: unset !important;
  height: unset !important;
`

export const ActionEntryListItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px 0px;
  padding-right: 10px;
`

export const DateHistoryListItem = styled.div`
  width: 200px;
  margin: 4px 0px;
`

export const UserHistoryListItem = styled.div`
  display: flex;
  flex: 6;
  max-width: 200px;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px 0px;
  padding-right: 10px;
`

export const DetailsHistoryListItem = styled.div`
  display: flex;
  flex: 12;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px 0px;
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px 0px;
`

export const BoxItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 4px;
  border: 1px solid ${BORDER_LIGHT};
  border-radius: 4px;
`

export const ListItemRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const ListItemCol1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
`

export const ListItemCol2 = styled.div`
  display: flex;
  flex-direction: column;
`

export const ListItemReportActionCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 35px;
  margin-right: 4px;
`

export const LinkClicksInnerLabel = styled.div`
  color: ${MESSAGE_HEADER_LABEL};
  margin-right: 4px;
  white-space: nowrap;
  min-width: 75px;
`

export const ReportsInnerLabelCol1 = styled.div`
  color: ${MESSAGE_HEADER_LABEL};
  margin-right: 4px;
  white-space: nowrap;
  min-width: 85px;
`

export const ReportsInnerLabelCol2 = styled.div`
  color: ${MESSAGE_HEADER_LABEL};
  margin-right: 4px;
  white-space: nowrap;
  min-width: ${props => props.open ? '22px' : '75px'};
`

export const ConfirmReportIcon = styled(ReportIcon).attrs({
  name: 'check',
})`
  font-size: 13px !important;
  cursor: pointer;
  padding-left: 4px;
  color: ${HIGHLIGHTED_GREEN};
`

export const RejectReportIcon = styled(ReportIcon).attrs({
  name: 'cancel',
})`
  font-size: 13px !important;
  cursor: pointer;
  padding-left: 4px;
  color: ${NEGATIVE_VALUE};
`

export const InnerValue = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const InlineText = styled.div`
  font-weight: normal;
`
