import React from 'react'
import PropTypes from 'prop-types'
import keys from 'ramda/src/keys'
import length from 'ramda/src/length'
import Column from './Column'

const { object } = PropTypes

class PureColumn extends React.PureComponent {
  render() {
    const { column, setMap, ...rest } = this.props
    const sets = column.setIds.map(setId => setMap[setId])
    return (
      <Column column={column} sets={sets} totalSets={length(keys(setMap))} {...rest} />
    )
  }
}

PureColumn.propTypes = {
  column: object,
  setMap: object,
}

export default PureColumn
