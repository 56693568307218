import styled from 'styled-components'
import {
  colors,
  spacing,
  Body2DarkGreyRegular,
  Body2DarkGreyBold,
  BodyDarkGreyBold,
} from 'static/theme'
import { MAX_WIDGET_WIDTH } from 'components/ReportWidgets/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 14.5rem;
  min-width: 28rem;
  max-width: ${MAX_WIDGET_WIDTH};
  border-radius: 4px;
  margin-right: ${spacing.m};
  margin-bottom: ${spacing.m};
  background-color: ${colors.white};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
`

export const ThreatRadialPie = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.438rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ThreatDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.s};
`

export const ThreatDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spacing.xs};
`

export const ThreatTitle = styled(BodyDarkGreyBold)`
  margin-left: ${spacing.m1};
`
export const ThreatInfo = styled(Body2DarkGreyRegular)`
  margin-right: ${spacing.xs};
`

export const ThreatCount = styled(Body2DarkGreyBold)``

export const Circle = styled.div<{ threatColor: string }>`
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.35rem;
  border: solid 0.15rem ${colors.primaryExtraLight};
  background-color: ${({ threatColor }) => threatColor};
  margin-right: ${spacing.xxs};
`

export const Separator = styled.div`
  height: 142px;
  width: 1px;
  background-color: ${colors.primaryLighter};
  margin: 0 ${spacing.m};
`
