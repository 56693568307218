import React from 'react'
import { AuthContext } from './AuthProvider'

function AuthConsumer(Child) {
  function AuthContextWrapper(props) {
    return (
      <AuthContext.Consumer>
        {auth => <Child {...props} auth={auth} />}
      </AuthContext.Consumer>
    )
  }
  return AuthContextWrapper
}

export default AuthConsumer
