import React, { useContext } from 'react'
import { Column, SubmenuItem } from './styles'
import { I18n } from 'lib/i18n'
import { useHelp } from 'providers/Help'
import { DashboardsContext } from 'providers/Dashboards'

export const Help = () => {
  const {
    isHelpGuideEnabled,
    isQuickTourEnabled,
    toggleIsHelpGuideEnabled,
    toggleIsQuickTourEnabled,
  } = useHelp()
  const context = useContext(DashboardsContext)
  const { toggleTourModal } = context as any

  return (
    <I18n>
      {({ t }) => (
        <Column>
          {/*
          <SubmenuItem onClick={toggleTourModal}>
            {t({ key: 'SIDEBAR_QUICK_TOUR' })}
          </SubmenuItem>
          */}
          <SubmenuItem
            onClick={toggleIsQuickTourEnabled}
            selected={isQuickTourEnabled}
          >
            {t({ key: 'SIDEBAR_QUICK_TOUR' })}
          </SubmenuItem>
          <SubmenuItem
            onClick={toggleIsHelpGuideEnabled}
            selected={isHelpGuideEnabled}
          >
            {t({ key: 'SIDEBAR_HELP_GUIDE' })}
          </SubmenuItem>
        </Column>
      )}
    </I18n>
  )
}
