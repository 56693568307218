import React, { useCallback, useState, useContext, HTMLAttributes } from 'react'
import omit from 'ramda/src/omit'
import propEq from 'ramda/src/propEq'
import reject from 'ramda/src/reject'
import evolve from 'ramda/src/evolve'
import mapObjIndexed from 'ramda/src/mapObjIndexed'
import { noop } from 'lib/ramda'
import { I18n } from 'lib/i18n'
import { useHelp } from 'providers/Help'
import { AuthContext } from '@logicea/react-auth'
import { DashboardsContext } from 'providers/Dashboards'
import { DashboardT } from 'components/App/Dashboard/types'
import { RichTooltip } from 'components/RichToolTip'
import { HelpHotspot } from 'components/Help/HelpHotspot'
import { QuickTourStep } from 'components/Help/QuickTourStep'
import {
  Container,
  Button,
  ActionsIcon,
  ActionsContainer,
  ActionWrapper,
  ActionText,
  CopyIcon,
  DownloadIcon,
  EditIcon,
  ShareIcon,
  TrashIcon,
} from './styles'

type Actions = 'rename' | 'copy' | 'download' | 'share' | 'trash'

const DashboardActionsContent = ({ dashboard }: { dashboard: DashboardT }) => {
  const {
    dashboards,
    toggleActionModal,
    setSelectedDashboard,
    handleDashboardChange,
  } = useContext(DashboardsContext)
  const auth: any = useContext(AuthContext)
  const userEmail = auth?.user?.email as string

  const downloadDashboard = useCallback((dashboard: DashboardT) => {
    const stripTeamIdFilters = reject(propEq('name', 'teamid'))
    const transformations = {
      filters: stripTeamIdFilters,
      message_sets: {
        sets: mapObjIndexed(evolve({ filters: stripTeamIdFilters })),
      },
    }
    const dashboardWithoutTeams = evolve(transformations, dashboard)
    const dashboardToExport = omit(
      ['id', 'user_id', 'realm', 'token'],
      dashboardWithoutTeams
    )
    var blob = new Blob([`{ ${JSON.stringify(dashboardToExport, null, 2)} }`], {
      type: 'application/json;charset=utf-8',
    }).slice(2, -1)
    var url = URL.createObjectURL(blob)
    var elem = document.createElement('a')
    elem.href = url
    elem.download = `${dashboard.name}.json`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }, [])

  const handleActionClicked = useCallback(
    (action: Actions) => (
      event: React.MouseEvent<HTMLDivElement | Document, MouseEvent>
    ) => {
      event.stopPropagation()
      setSelectedDashboard(dashboard)
      handleDashboardChange(
        dashboards.findIndex((e: any) => e.id === dashboard.id),
        false
      )
      if (action === 'rename') {
        toggleActionModal(noop)('rename', 'dashboard')
      }
      if (action === 'copy') {
        toggleActionModal(noop)('clone', 'dashboard')
      }
      if (action === 'download') {
        downloadDashboard(dashboard)
      }
      if (action === 'trash') {
        toggleActionModal(noop)('delete', 'dashboard')
      }
    },
    [dashboard, dashboards]
  )

  return (
    <I18n>
      {({ t }) => (
        <ActionsContainer>
          {userEmail === dashboard.user_id && (
            <ActionWrapper onClick={handleActionClicked('rename')}>
              <EditIcon />
              <ActionText>
                {t({
                  key: 'DASHBOARD_ACTION_EDIT',
                })}
              </ActionText>
            </ActionWrapper>
          )}
          <ActionWrapper onClick={handleActionClicked('copy')}>
            <CopyIcon />
            <ActionText>
              {t({
                key: 'DASHBOARD_ACTION_CLONE',
              })}
            </ActionText>
          </ActionWrapper>
          {false && (
            <ActionWrapper onClick={handleActionClicked('share')}>
              <ShareIcon />
              <ActionText>
                {t({
                  key: 'DASHBOARD_ACTION_SHARE',
                })}
              </ActionText>
            </ActionWrapper>
          )}
          <ActionWrapper onClick={handleActionClicked('download')}>
            <DownloadIcon />
            <ActionText>
              {t({
                key: 'DASHBOARD_ACTION_DOWNLOAD',
              })}
            </ActionText>
          </ActionWrapper>
          {userEmail === dashboard.user_id && (
            <ActionWrapper onClick={handleActionClicked('trash')}>
              <TrashIcon />
              <ActionText>
                {t({
                  key: 'DASHBOARD_ACTION_DELETE',
                })}
              </ActionText>
            </ActionWrapper>
          )}
        </ActionsContainer>
      )}
    </I18n>
  )
}

export const DashboardActions = ({
  dashboard,
  index,
  style,
}: {
  dashboard: DashboardT
  index?: number
} & HTMLAttributes<HTMLDivElement>) => {
  const { dashboards } = useContext(DashboardsContext)

  const [openTooltip, setOpenTooltip] = useState(false)

  const { isQuickTourEnabled, currentQuickTourStepId } = useHelp()

  const handleTooltip = useCallback(
    (
      event: React.MouseEvent<
        HTMLButtonElement | HTMLDivElement | Document,
        MouseEvent
      >
    ) => {
      dashboards.length !== 0 && setOpenTooltip(!openTooltip)
      event.stopPropagation()
    },
    [openTooltip, dashboards]
  )

  const openTooltipForQuickTour =
    isQuickTourEnabled &&
    currentQuickTourStepId === 'dashboardActions' &&
    index === 0

  return (
    <I18n>
      {({ t }) => (
        <QuickTourStep
          isOutlined
          placement='left'
          stepId='dashboardActions'
          disabled={index === undefined || index > 0}
          title={t({ key: 'QUICK_TOUR_DASHBOARD_ACTIONS_TITLE' })}
          content={t({ key: 'QUICK_TOUR_DASHBOARD_ACTIONS_CONTENT' })}
        >
          <Container>
            <RichTooltip
              content={<DashboardActionsContent dashboard={dashboard} />}
              open={openTooltip || openTooltipForQuickTour}
              placement='bottom'
              onClose={() => setOpenTooltip(false)}
            >
              <Button onClick={handleTooltip}>
                <HelpHotspot
                  offsetTop={-6}
                  offsetLeft={-6}
                  disabled={index === undefined || index > 0}
                  title={t({ key: 'HOTSPOT_DASHBOARD_ACTIONS_TITLE' })}
                  content={t({ key: 'HOTSPOT_DASHBOARD_ACTIONS_CONTENT' })}
                >
                  <ActionsIcon active={openTooltip} style={style} />
                </HelpHotspot>
              </Button>
            </RichTooltip>
          </Container>
        </QuickTourStep>
      )}
    </I18n>
  )
}
