import styled from 'styled-components'
import { colors, spacing } from 'static/theme'
import { Counter as AbstractCounter } from 'components/ReportComponents/Counter'
import { ProgressBar as AbstractProgressBar } from 'components/ReportComponents/ProgressBar'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 18rem;
  height: 11.5rem;
  border-radius: 3px;
  background-color: ${colors.white};
  margin-right: ${spacing.m};
  margin-bottom: ${spacing.m};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: ${spacing.m1};
`

export const ThreatLevel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.xs};
`

export const ProgressBarContainer = styled.div`
  width: 11rem;
`

export const ProgressBar = styled(AbstractProgressBar)``

export const Counter = styled(AbstractCounter)`
  flex: 1;
  justify-content: flex-end;
`
