import styled from 'styled-components'
import { colors, spacing } from 'static/theme/index'
import { ReactComponent as RefreshSVG } from 'assets/buttons/refresh.svg'

export const EXPORT_WIDTH = 794
export const EXPORT_HEIGHT = 1123

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.primaryExtraLight};
`

export const ReportsContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
`

export const RowWithSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1302px;
  padding-top: ${spacing.s};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
`

export const ExportWrapper = styled.div`
  width: ${EXPORT_WIDTH}px;
  height: ${EXPORT_HEIGHT}px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

export const Margin = styled.div`
  height: ${spacing.s};
  width: ${spacing.s};
`

export const RefreshButton = styled(RefreshSVG)`
  fill: ${colors.primaryMain};
  &:hover {
    cursor: pointer;
  }
`
