import React from 'react'
import { Container, Quarter } from './styles'

const Quad = ({ children }: { children: React.ReactNodeArray }) => {
  return (
    <Container>
      {children.map((child, index) => (
        <Quarter index={index} key={index}>
          {child}
        </Quarter>
      ))}
    </Container>
  )
}

export { Quad }
