import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import find from 'ramda/src/find'
import equals from 'ramda/src/equals'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'
import isEmpty from 'ramda/src/isEmpty'
import { filter as rFilter } from 'ramda/src'
import symmetricDifference from 'ramda/src/symmetricDifference'
import { Radio } from 'components/Radio'
import { SearchInput } from 'components/SearchInput'
import { I18n } from '../../../lib/i18n'
import messageActions from '../../../requests/message'
import {
  Container,
  SelectionHeader,
  SelectionHeaderItem,
  SelectionWrapper,
  Scrollbar,
  SelectionColumnWrapper,
  SuggestionWrapper,
  Seperator,
  SeperatorIcon,
  Suggestion,
  SuggestionText,
  CloseIcon,
  AllResultsText,
  StatisticsText,
  StatisticsTextBold,
} from '../filterInputStyles'
import debounce from 'lodash.debounce'
import {INPUT_TEXT_DEBOUNCE_TIME} from '../../../static/appConfig'


const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 0px;
`

const RadioWrapper = styled.div`
  margin-right: 16px;
`

const { object, func, array, string } = PropTypes

class ImagesInput extends Component {
  state = {
    mode: 'url',
    value: '',
    suggestions: [],
    selectedKeywords: [],
  }

  constructor(props) {
    super(props);
    this.getUrlSuggestionsDebounced = debounce(async (otherDashboardFilters, otherSetFilters, dashboardFilterMode,
                                                      setFilterMode, realm, token, filterName, newTargetValue) => {
      const newSuggestions = await messageActions.getNestedSuggestionsAction(
        {
          dashboardFilters: otherDashboardFilters,
          setFilters: otherSetFilters,
          dashboardFilterMode,
          setFilterMode,
        },
        {
          realm,
          token,
          field: filterName,
          innerField: 'url',
          term: newTargetValue,
        }
      )
      this.setState({ suggestions: { url: newSuggestions.data } })
    }, INPUT_TEXT_DEBOUNCE_TIME)
  }

  componentDidMount() {
    const { filter } = this.props
    const { term } = filter
    this.handleUrlInputChange({ target: { value: term ? term.url : '' } })
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props
    if (equals(prevProps.filter, filter)) return
    const { term } = filter
    this.handleUrlInputChange({ target: { value: term ? term.url : '' } })
  }

  handleUrlInputChange = async event => {
    const {
      realm,
      token,
      filter,
      onChange,
      setFilters,
      setFilterMode,
      dashboardFilters,
      dashboardFilterMode,
    } = this.props
    const { value, selectedKeywords } = this.state
    const newTargetValue = event.target.value

    if (equals(newTargetValue, value) && !equals(newTargetValue, '')) return

    if (newTargetValue === 'data') {
      this.setState({ mode: 'data' })
      return
    }

    const otherDashboardFilters = rFilter(f => !propEq('name', filter.name)(f), dashboardFilters)
    const otherSetFilters = rFilter(f => !propEq('name', filter.name)(f), setFilters)

    if (typeof newTargetValue === 'string') {
      this.setState({ value: newTargetValue })
      if (selectedKeywords.length === 0) onChange({ url: newTargetValue })

      this.getUrlSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode, setFilterMode, realm,
        token, filter.name, newTargetValue)
    } else {
      if (selectedKeywords.length === 0) {
        onChange(value ? { url: value } : null)
      }
      this.setState({ selectedKeywords: newTargetValue })
      this.getUrlSuggestionsDebounced(otherDashboardFilters, otherSetFilters, dashboardFilterMode, setFilterMode, realm,
        token, filter.name, value)
    }
  }

  handleRowClick = keyword => {
    const { onChange } = this.props
    const { selectedKeywords } = this.state
    const newKeywords = symmetricDifference(selectedKeywords, [keyword])
    this.setState({ selectedKeywords: newKeywords })
    onChange({ url: newKeywords })
  }

  handleRadioChange = ({ value }) => {
    const { onChange } = this.props
    this.setState({ mode: value })
    if (value === 'data') {
      onChange({ url: 'data' })
    } else {
      onChange({ url: '' })
    }
  }

  render() {
    const { mode, value, suggestions, selectedKeywords } = this.state
    const suggestionArray = propOr([], 'url', suggestions)

    return (
      <I18n>
        {({ t }) => (
          <Container>
            <div>
              <RadioGroup>
                <RadioWrapper>
                  <Radio
                    label={t(
                      {
                        key: 'FILTER_MEDIA_IMAGE_URL',
                        variables: {
                          label: '',
                        },
                      }
                    )}
                    checked={mode === 'url'}
                    onChange={()=>this.handleRadioChange({value:'url'})}
                  />
                </RadioWrapper>
                <RadioWrapper>
                  <Radio
                    label={t({
                      key: 'FILTER_MEDIA_IMAGE_DATA',
                    })}
                    checked={mode === 'data'}
                    onChange={()=>this.handleRadioChange({value:'data'})}
                  />
                </RadioWrapper>
              </RadioGroup>
              {mode === 'url' && (
                <SearchInput
                  key='image-url'
                  value={value}
                  placeholder={t(
                    {
                      key: 'FILTER_MEDIA_IMAGE_URL',
                      variables: {
                        label: '',
                      },
                    }
                  )}
                  name="url"
                  onChange={this.handleUrlInputChange}
                />
              )}
            </div>
            {mode === 'url' && (
              <React.Fragment>
                <SelectionHeader>
                  <SelectionHeaderItem>
                    {suggestionArray.length > 100 ? (
                      <StatisticsTextBold>
                        100+
                        <StatisticsText>({t({ key: 'SELECTION_MATCHES' })})</StatisticsText>
                      </StatisticsTextBold>
                    ) : (
                      <StatisticsTextBold>
                        {suggestionArray.length}
                        <StatisticsText>({suggestionArray.length === 1
                          ? t({ key: 'SELECTION_MATCH' })
                          : t({ key: 'SELECTION_MATCHES' })
                        })</StatisticsText>
                      </StatisticsTextBold>
                    )}
                  </SelectionHeaderItem>
                  <SelectionHeaderItem right>
                    {selectedKeywords.length > 100 ? (
                      <StatisticsTextBold>
                        100+
                        <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                      </StatisticsTextBold>
                    ) : (
                      <StatisticsTextBold>
                        {selectedKeywords.length}
                        <StatisticsText>({t({ key: 'SELECTION_SELECTED' })})</StatisticsText>
                      </StatisticsTextBold>
                    )}
                  </SelectionHeaderItem>
                </SelectionHeader>
                <SelectionWrapper>
                  <SelectionColumnWrapper>
                    <Scrollbar>
                      {suggestionArray.map(suggestion => {
                        const isSelected = find(equals(suggestion), selectedKeywords)
                        return (
                          <SuggestionWrapper>
                            <Suggestion
                              key={suggestion}
                              isSelected={isSelected}
                              onClick={isSelected ? null
                                : () => this.handleRowClick(suggestion)}
                            >
                              <SuggestionText>
                                {suggestion || t({ key: 'SELECTION_NO_VALUE' })}
                              </SuggestionText>
                            </Suggestion>
                          </SuggestionWrapper>
                        )
                      })}
                    </Scrollbar>
                  </SelectionColumnWrapper>
                  <Seperator>
                    <SeperatorIcon />
                  </Seperator>
                  <SelectionColumnWrapper right>
                    <Scrollbar>
                      {selectedKeywords.length > 0
                        ? selectedKeywords.map(keyword => (
                          <SuggestionWrapper>
                            <Suggestion
                              closeable
                              key={keyword}
                              isSelected={false}
                              onClick={() => this.handleRowClick(keyword)}
                            >
                              <SuggestionText>
                                {keyword || t({ key: 'SELECTION_NO_VALUE' })}
                              </SuggestionText>
                              <CloseIcon>×</CloseIcon>
                            </Suggestion>
                          </SuggestionWrapper>
                        )) : !isEmpty(value) && (
                          <SuggestionWrapper>
                            <AllResultsText>
                              {t({ key: 'SELECTION_ALL_MATCHES_OF', variables: { value } })}
                            </AllResultsText>
                          </SuggestionWrapper>
                        )
                      }
                    </Scrollbar>
                  </SelectionColumnWrapper>
                </SelectionWrapper>
              </React.Fragment>
            )}
          </Container>
        )}
      </I18n>
    )
  }
}


ImagesInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default ImagesInput
