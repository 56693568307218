import React, { Component, Suspense, useState, useEffect, useContext } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import prop from 'ramda/src/prop'
import path from 'ramda/src/path'
import isNil from 'ramda/src/isNil'
import assoc from 'ramda/src/assoc'
import values from 'ramda/src/values'
import equals from 'ramda/src/equals'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'
import sortBy from 'ramda/src/sortBy'
import evolve from 'ramda/src/evolve'
import replace from 'ramda/src/replace'
import isEmpty from 'ramda/src/isEmpty'
import { Loader } from 'semantic-ui-react'
import findIndex from 'ramda/src/findIndex'
import mergeRight from 'ramda/src/mergeRight'
import { filter as rFilter } from 'ramda/src'
import { AuthContext } from '@logicea/react-auth'
import actions from '../../../requests/message'
import IpInput from '../../FilterInputs/IpInput'
import MessageCounter from '../../MessageCounter'
import TeamInput from '../../FilterInputs/TeamInput'
import DateInput from '../../FilterInputs/DateInput'
import TextInput from '../../FilterInputs/TextInput'
import LinksInput from '../../FilterInputs/LinksInput'
import UnaryInput from '../../FilterInputs/UnaryInput'
import HeaderInput from '../../FilterInputs/HeaderInput'
import ImagesInput from '../../FilterInputs/ImagesInput'
import BooleanInput from '../../FilterInputs/BooleanInput'
import KeywordInput from '../../FilterInputs/KeywordInput'
import ReasonIdInput from '../../FilterInputs/ReasonIdInput'
import FiletypeInput from '../../FilterInputs/FiletypeInput'
import AttachmentsInput from '../../FilterInputs/AttachmentsInput'
import ThreatLevelInput from '../../FilterInputs/ThreatLevelInput'
import MicrosoftSCLInput from '../../FilterInputs/MicrosoftSCLInput'
import { now, initialDateRange } from '../../../static/initialSearchQuery'
import { createResource, createCacheWithAsyncInvalidation } from '../../../lib/simple-cache-utils'
import modalStyles, {
  SmallButton,
} from '../modalStyles'
import { Button } from '../components/Button'
import {
  FilterEditorContainer,
  FilterEditorContent,
  FilterEditorHeader,
  FilterSelectContainer,
  FilterSelectScroll,
  FilterSelect,
  HeaderTitleWrapper,
  HeaderTitle,
  HeaderPath,
  HeaderCategory,
  HeaderSeparator,
  HeaderFilter,
  EditorArea,
  Container,
  TopArea,
  ContentWrapper,
  CategorySelect,
  CategorySelectIcon,
  CategorySelectText,
  CategorySelectContainer,
  Statistics,
  StatisticsRow,
  StatisticsIcon,
  StatisticsText,
  StatisticsTextBold,
  FilterIcon,
  GeneralIcon,
  HeadersIcon,
  MediaIcon,
  AnalysisIcon,
  MetadataIcon,
  ButtonsWrapper,
} from './styles'
import {
  FILTER_CATEGORIES,
  CATEGORY_MAP, TIME_SENSITIVE_FILTERS,
} from '../../../static/filterNames'
import { I18n } from '../../../lib/i18n'
import { DashboardsContext } from '../../../providers/Dashboards'
import SensitiveContentInput from "../../FilterInputs/SensitiveContentInput";
import PhishingTestProviderInput from "../../FilterInputs/PhishingTestProviderInput";
import ReportLabelInput from "../../FilterInputs/ReportLabelInput";
import ResultBucketInput from "../../FilterInputs/ResultBucketInput";
import FilterStats from "../../FilterStats";
import OrganizationalUnitInput from "../../FilterInputs/OrganizationalUnitInput";
import NestedMatchesInput from "../../FilterInputs/NestedMatchesInput";
import NestedListInput from "../../FilterInputs/NestedListInput";

const HEADER_PROPERTIES = [
  'headers_from',
  'headers_to',
  'headers_cc',
  'headers_bcc',
  'headers_replyto',
]

const { func, object, string, array } = PropTypes

let propertiesCache

const propertiesCacheInvalidator = () => {
  propertiesCache = createCacheWithAsyncInvalidation(propertiesCacheInvalidator)
}
propertiesCache = createCacheWithAsyncInvalidation(propertiesCacheInvalidator)

const PropertiesResource = createResource(
  ({ realm, token }) => actions.getPropertiesAction(realm, token),
  ({ realm, token }) => JSON.stringify({ realm, token })
)

class ModalContent extends Component {
  state = {
    selectedSetId: null,
    selectedFilter: { name: '', type: '', term: '' },
    startDate: initialDateRange,
    endDate: now,
  }

  componentDidMount() {
    const { selectedFilter, selectedSetId } = this.props
    if (selectedFilter) {
      if (selectedFilter.name === 'attachments' && !isEmpty(path(['term', 'filteype'], selectedFilter))) {
        this.setState({ selectedFilter: mergeRight(selectedFilter, { name: 'attachments_filetype' }) })
      } else {
        this.setState({ selectedFilter })
      }
    }
    if (selectedSetId) this.setState({ selectedSetId })
  }

  handleFilterSelect = filter => {
    const { selectedFilter } = this.state
    const { handleFilterClick } = this.props
    this.setState({ selectedFilter: mergeRight(selectedFilter, { ...filter, term: '' }) })
    handleFilterClick(filter.name)
  }

  handleFilterChange = value => {
    const { selectedFilter } = this.state
    if (isNil(value)) return
    this.setState({ selectedFilter: assoc('term', selectedFilter.name === 'banner_suppressed' ? !value : value, selectedFilter) })
  }

  handleDateChange = (from, to, relative) => {
    const { selectedFilter } = this.state
    const prevTerm = selectedFilter.term === ''
      ? {
        from: Math.floor(initialDateRange.getTime() / 1000),
        to: Math.floor(now.getTime() / 1000),
        relativeFrom: 60,
        relativeTo: 0,
      } : selectedFilter.term

    if (relative) {
      this.setState({
        selectedFilter: {
          name: 'processed_date',
          type: 'relative_date',
          term: mergeRight(prevTerm, { relativeFrom: from, relativeTo: to }),
        },
      })
    } else if (!isNil(from) && isNil(to)) {
      const fromDate = Math.floor(from.getTime() / 1000)
      this.setState({
        selectedFilter: {
          name: 'processed_date',
          type: 'date',
          term: mergeRight(prevTerm, { from: fromDate }),
        },
        startDate: from,
      })
    } else if (isNil(from) && !isNil(to)) {
      const toDate = Math.floor(to.getTime() / 1000)
      this.setState({
        selectedFilter: {
          name: 'processed_date',
          type: 'date',
          term: mergeRight(prevTerm, { to: toDate }),
        },
        endDate: to,
      })
    } else {
      const fromDate = Math.floor(from.getTime() / 1000)
      const toDate = Math.floor(to.getTime() / 1000)
      this.setState({
        selectedFilter: {
          name: 'processed_date',
          type: 'date',
          term: mergeRight(prevTerm, { from: fromDate, to: toDate }),
        },
        startDate: from,
        endDate: to,
      })
    }
  }

  render() {
    const { selectedFilter, selectedSetId, startDate, endDate } = this.state
    const {
      handleSubmit,
      onRequestClose,
      selectedCategory,
      selectedFilterName,
      dashboardId,
      dashboardFilterMode,
      dashboardFilters,
      setFilterMode,
      setFilters,
      realm,
      token,
    } = this.props
    const data = PropertiesResource.read(propertiesCache, { realm, token })
    const categoryProperties = FILTER_CATEGORIES[selectedCategory]
    const belongsToCategory = property => (selectedCategory
      ? findIndex(equals(property.name), categoryProperties) > -1
      : false
    )
    const properties = sortBy(prop('name'), rFilter(belongsToCategory, data))
    const isBooleanFilter = findIndex(equals(selectedFilter.name), ['internal', 'reports', 'has_attachments', 'link_clicks', 'banner_supressed']) > -1
    const selectedTerm = typeof selectedFilter.term === 'object' && !Array.isArray(selectedFilter.term)
      ? values(selectedFilter.term)[0] : selectedFilter.term
    const isEmptyTerm = !isBooleanFilter && isEmpty(selectedTerm)

    const handleEnterKeyPressed = (e) => {
      e.preventDefault()
      if (!isEmptyTerm)
        handleSubmit(selectedFilter, selectedSetId)
    }

    return (
      <I18n>
        {({ t }) => (
          <ContentWrapper>
            {(selectedFilter.name || selectedCategory) && (
            <FilterSelectContainer>
              <FilterSelectScroll last>
                {properties.map(property => (
                  <FilterSelect
                    key={property.name}
                    isSelected={propEq('name', property.name, selectedFilter)}
                    onClick={() => this.handleFilterSelect(property)}
                  >
                    {t({ key: property.name }) || property.name}
                  </FilterSelect>
                ))}
              </FilterSelectScroll>
            </FilterSelectContainer>
            )}
            <EditorArea onSubmit={handleEnterKeyPressed}>
              {selectedFilterName ? (
                <TopArea>
                  {selectedFilter.name && (
                    <StatisticsRow>
                      <Statistics>
                        <StatisticsIcon />
                        <StatisticsTextBold>
                          <MessageCounter
                            dashboardId={dashboardId}
                            filterName={selectedFilter.name}
                            dashboardFilterMode={dashboardFilterMode}
                            dashboardFilters={dashboardFilters}
                            setFilterMode={setFilterMode}
                            setFilters={setFilters}
                            verbal
                          />
                        </StatisticsTextBold>
                        <StatisticsText>
                          {t({ key: 'SELECTION_SEARCH_SPACE_STATIC' })}
                        </StatisticsText>
                      </Statistics>
                      {
                        TIME_SENSITIVE_FILTERS.includes(selectedFilter.name) && (
                            <FilterStats
                                dashboardId={dashboardId}
                                dashboardFilterMode={dashboardFilterMode}
                                dashboardFilters={dashboardFilters}
                                setFilterMode={setFilterMode}
                                setFilters={setFilters}
                                filterName={selectedFilter.name}
                            />
                        )
                      }
                    </StatisticsRow>
                  )}
                  {propEq('type', 'keyword', selectedFilter)
                    && !propEq('name', 'teamid', selectedFilter)
                    && !propEq('name', 'organizational_unit', selectedFilter)
                    && !propEq('name', 'sensitive_content_categories', selectedFilter)
                    && !propEq('name', 'phishing_test_provider', selectedFilter)
                    && !propEq('name', 'result_bucket', selectedFilter)
                    && (
                      <KeywordInput
                        realm={realm}
                        token={token}
                        filter={selectedFilter}
                        setFilters={setFilters}
                        setFilterMode={setFilterMode}
                        onChange={this.handleFilterChange}
                        dashboardFilters={dashboardFilters}
                        dashboardFilterMode={dashboardFilterMode}
                      />
                    )}
                  {propEq('type', 'text', selectedFilter) && !propEq('name', 'tags', selectedFilter) && (
                    <TextInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {(propEq('type', 'date', selectedFilter) || propEq('type', 'relative_date', selectedFilter)) && (
                    <DateInput
                      filter={selectedFilter}
                      startDate={startDate}
                      endDate={endDate}
                      onDateChange={this.handleDateChange}
                    />
                  )}
                  {propEq('name', 'teamid', selectedFilter) && (
                    <TeamInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'microsoft_scl', selectedFilter) && (
                    <MicrosoftSCLInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'reason_ids', selectedFilter) && (
                    <ReasonIdInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'threat_level', selectedFilter) && (
                    <ThreatLevelInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'sensitive_content_categories', selectedFilter) && (
                    <SensitiveContentInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'phishing_test_provider', selectedFilter) && (
                    <PhishingTestProviderInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'organizational_unit', selectedFilter) && (
                    <OrganizationalUnitInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'result_bucket', selectedFilter) && (
                      <ResultBucketInput
                          realm={realm}
                          token={token}
                          filter={selectedFilter}
                          setFilters={setFilters}
                          setFilterMode={setFilterMode}
                          onChange={this.handleFilterChange}
                          dashboardFilters={dashboardFilters}
                          dashboardFilterMode={dashboardFilterMode}
                      />
                  )}
                  {propEq('name', 'report_label', selectedFilter) && (
                    <ReportLabelInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'attachment_hash', selectedFilter) && (
                    <NestedMatchesInput
                      nestedFieldPath={'attachments'}
                      innerFieldName={'sha256_checksum'}
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'reported_by', selectedFilter) && (
                    <NestedMatchesInput
                      nestedFieldPath={'reports'}
                      innerFieldName={'contact_email'}
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'link_click_alert_lvl', selectedFilter) && (
                    <NestedListInput
                      nestedFieldPath={'link_clicks'}
                      innerFieldName={'alert_level'}
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'reports', selectedFilter) && (
                    <UnaryInput
                      filter={selectedFilter}
                      setFilters={setFilters}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                    />
                  )}
                  {propEq('name', 'has_attachments', selectedFilter) && (
                    <UnaryInput
                      filter={selectedFilter}
                      setFilters={setFilters}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                    />
                  )}
                  {propEq('name', 'link_clicks', selectedFilter) && (
                    <UnaryInput
                      filter={selectedFilter}
                      setFilters={setFilters}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                    />
                  )}
                  {propEq('name', 'links', selectedFilter) && (
                    <LinksInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'images', selectedFilter) && (
                    <ImagesInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'attachments_filetype', selectedFilter) && (
                    <FiletypeInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('name', 'attachments', selectedFilter) && (
                    <AttachmentsInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {findIndex(equals(selectedFilter.name), HEADER_PROPERTIES) > -1 && (
                    <HeaderInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {propEq('type', 'ip', selectedFilter) && (
                    <IpInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                  {(propEq('type', 'boolean', selectedFilter) || propEq('type', 'boolean_exists', selectedFilter)) && (
                    <BooleanInput
                      realm={realm}
                      token={token}
                      filter={selectedFilter}
                      setFilters={setFilters}
                      setFilterMode={setFilterMode}
                      onChange={this.handleFilterChange}
                      dashboardFilters={dashboardFilters}
                      dashboardFilterMode={dashboardFilterMode}
                    />
                  )}
                </TopArea>
              ) : <TopArea />}
                <ButtonsWrapper>
                  <Button cancel onClick={onRequestClose}>
                    {t({ key: 'CANCEL' })}
                  </Button>
                  <Button
                    onClick={() => handleSubmit(selectedFilter, selectedSetId)}
                    disabled={isEmptyTerm}
                  >
                    {t({ key: 'Add' })}
                  </Button>
                </ButtonsWrapper>
            </EditorArea>
          </ContentWrapper>
        )}
      </I18n>
    )
  }
}

ModalContent.propTypes = {
  handleFilterClick: func,
  handleSubmit: func,
  onRequestClose: func,
  selectedFilterName: string,
  selectedCategory: string,
  selectedFilter: object,
  selectedSetId: string,
  setFilters: array,
  setFilterMode: string,
  dashboardId: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  realm: string,
  token: string,
}

const FilterEditor = () => {
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedFilterName, setSelectedFilterName] = useState('')

  const context = useContext(DashboardsContext)
  const {
    selectedSetId,
    selectedFilter,
    selectedDashboard,
    toggleFilterEditorModal,
    isFilterEditorModalOpen,
    handleFilterEditorModalSubmit,
  } = context

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  useEffect(() => {
    if (selectedFilter && selectedFilter.name) {
      setSelectedCategory(CATEGORY_MAP[selectedFilter.name])
      setSelectedFilterName(selectedFilter.name)
    } else {
      setSelectedCategory('general')
      setSelectedFilterName('')
    }
  }, [selectedFilter])

  const handleCategorySelect = category => {
    setSelectedFilterName('')
    setSelectedCategory(category)
  }

  const handleFilterClick = name => {
    setSelectedFilterName(name)
  }

  const handleSubmit = (filter, setId) => {
    const evolvedFilter = evolve({ name: replace('_filetype', '') }, filter)
    handleFilterEditorModalSubmit(evolvedFilter, setId)
    toggleFilterEditorModal()
  }

  const handleClose = () => {
    setSelectedCategory('general')
    toggleFilterEditorModal()
  }

  const renderCategoryIcon = key => {
    switch (key) {
      case 'general':
        return <GeneralIcon />
      case 'headers':
        return <HeadersIcon />
      case 'media':
        return <MediaIcon />
      case 'analysis':
        return <AnalysisIcon />
      case 'metadata':
        return <MetadataIcon />
      default:
        return null
    }
  }

  const messageSet = pathOr(null, ['message_sets', 'sets', selectedSetId], selectedDashboard)
  const messageSetFilters = propOr([], 'filters', messageSet)
  const messageSetFilterMode = propOr('AND', 'filterMode', messageSet)
  const dashboardFilters = propOr([], 'filters', selectedDashboard)
  const dashboardFilterMode = propOr('AND', 'filter_mode', selectedDashboard)
  const dashboardId = propOr('', 'id', selectedDashboard)

  return (
    <I18n>
      {({ t }) => (
        <Modal
          isOpen={isFilterEditorModalOpen}
          onRequestClose={handleClose}
          contentLabel={t({ key: 'FILTER_EDIT_MODAL_LABEL' })}
          style={{
            overlay: modalStyles.overlay,
            content: modalStyles.content,
          }}
        >
          <Container>
            <Suspense
              fallback={<Loader active />}
            >
              <FilterEditorContainer>
                <FilterEditorHeader>
                  <HeaderTitleWrapper>
                    <FilterIcon />
                    <HeaderTitle>
                      {t({ key: selectedSetId
                        ? 'MESSAGE_SET_FILTER_EDITOR_LABEL'
                        : 'DASHBOARD_FILTER_EDITOR_LABEL',
                      })}
                    </HeaderTitle>
                  </HeaderTitleWrapper>
                  <HeaderPath>
                    <HeaderCategory>
                      {selectedCategory && t({ key: selectedCategory })}
                    </HeaderCategory>
                    {selectedFilterName && (
                      <React.Fragment>
                        <HeaderSeparator>:</HeaderSeparator>
                        <HeaderFilter>
                          {t({ key: selectedFilterName })}
                        </HeaderFilter>
                      </React.Fragment>
                    )}
                  </HeaderPath>
                </FilterEditorHeader>
                <FilterEditorContent>
                  <CategorySelectContainer>
                    <FilterSelectScroll last={!selectedCategory}>
                      {Object.keys(FILTER_CATEGORIES).map(key => (
                        <CategorySelect
                          key={key}
                          isSelected={selectedCategory === key}
                          onClick={() => handleCategorySelect(key)}
                        >
                          <CategorySelectIcon category={key}>
                            {renderCategoryIcon(key)}
                          </CategorySelectIcon>
                          <CategorySelectText>
                            {t({ key: key }) || key}
                          </CategorySelectText>
                        </CategorySelect>
                      ))}
                    </FilterSelectScroll>
                  </CategorySelectContainer>
                  <ModalContent
                    selectedSetId={selectedSetId}
                    selectedFilter={selectedFilter}
                    selectedCategory={selectedCategory}
                    selectedFilterName={selectedFilterName}
                    handleFilterClick={handleFilterClick}
                    onRequestClose={handleClose}
                    handleSubmit={handleSubmit}
                    dashboardId={dashboardId}
                    setFilters={messageSetFilters}
                    setFilterMode={messageSetFilterMode}
                    dashboardFilters={dashboardFilters}
                    dashboardFilterMode={dashboardFilterMode}
                    token={tokens.accessToken}
                    realm={realm}
                  />
                </FilterEditorContent>
              </FilterEditorContainer>
            </Suspense>
          </Container>
        </Modal>
      )}
    </I18n>
  )
}

export default FilterEditor
