import styled from 'styled-components'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { spacing } from 'static/theme'

export const MaterialUIAutocompleteInput = styled(Autocomplete)`
  && {
    display: flex;
    flex: 1;
  }
`

export const MaterialUITextField = styled(TextField)``
