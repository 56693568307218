import React, { useContext } from 'react'
import Modal from 'react-modal'
import modalStyles, {
  ContentWrapper,
  CloseButtonTop,
  CloseIcon,
} from '../modalStyles'
import {
  MessageContainer,
} from './styles'
import Message from '../../App/Message'
import { DashboardsContext } from '../../../providers/Dashboards'

const MessageModal = () => {
  const context = useContext(DashboardsContext)
  const {
    onMessageTag,
    selectedMessageId,
    selectedMessageIndex,
    isMessageModalOpen,
    handleMessageClose,
    toggleTagModal,
    toggleMessageModal,
    toggleConfirmationModal,
  } = context

  const onClose = () => {
    handleMessageClose()
    toggleMessageModal()
  }

  const onMessageActioned = (action, user, details) => {
    console.log("MessageModal:onMessageActioned", action, user, details)
  }

  return (
    <Modal
      isOpen={isMessageModalOpen}
      onRequestClose={onClose}
      contentLabel="MESSAGE"
      style={{
        overlay: modalStyles.overlay,
        content: modalStyles.content,
      }}
    >
      <MessageContainer>
        <ContentWrapper>
          <Message
            onMessageTag={onMessageTag}
            messageId={selectedMessageId}
            toggleTagModal={toggleTagModal}
            messageIndex={selectedMessageIndex}
            toggleConfirmationModal={toggleConfirmationModal}
            onMessageActioned={onMessageActioned}
          />
          <CloseButtonTop onClick={onClose}>
            <CloseIcon />
          </CloseButtonTop>
        </ContentWrapper>
      </MessageContainer>
    </Modal>
  )
}

export default MessageModal
