import styled from 'styled-components'
import { spacing } from 'static/theme'
import { MAX_WIDGET_WIDTH } from 'components/ReportWidgets/styles'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  height: 15rem;
  min-width: 28rem;
  max-width: ${MAX_WIDGET_WIDTH};
  margin-right: ${spacing.m};
  margin-bottom: ${spacing.m};
`

type QuarterProps = {
  index: number
}
export const Quarter = styled.div<QuarterProps>`
  width: 50%;
  height: 50%;
  padding-top: ${(props) =>
    props.index === 2 || props.index === 3 ? '0.06' : '0'}rem;
  padding-right: ${(props) =>
    props.index === 0 || props.index === 2 ? '0.06' : '0'}rem;
  padding-bottom: ${(props) =>
    props.index === 0 || props.index === 1 ? '0.06' : '0'}rem;
  padding-left: ${(props) =>
    props.index === 1 || props.index === 3 ? '0.06' : '0'}rem;
`
