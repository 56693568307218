import React, { useState, useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import isNil from 'ramda/src/isNil'
import { Loader } from 'semantic-ui-react'
import { AuthContext } from '@logicea/react-auth'
import { createResource, createCacheWithAsyncInvalidation } from '../../lib/simple-cache-utils'
import actions from '../../requests/message'
import { Container, HelpIcon, StatisticsText} from './styles'
import {parseDate, Tooltip} from '../../lib/util'
import { I18n } from '../../lib/i18n'
import propEq from 'ramda/src/propEq'
import { filter as rFilter } from 'ramda/src'

const { bool, string } = PropTypes

let filterStatsCache
let teamFilterStatsCache

const filterStatsCacheInvalidator = () => {
  filterStatsCache = createCacheWithAsyncInvalidation(filterStatsCacheInvalidator)
}
filterStatsCache = createCacheWithAsyncInvalidation(filterStatsCacheInvalidator)

const FilterStatsResource = createResource(
  ({ filterName, realm, token, filters }) =>
    actions.getFilterStatsAction(
      filterName,
      { realm, token },
      filters
    ),
  ({ filterName, realm, filters }) =>
    JSON.stringify({ filterName, realm, filters })
)

const teamFilterStatsCacheInvalidator = () => {
  teamFilterStatsCache = createCacheWithAsyncInvalidation(teamFilterStatsCacheInvalidator)
}
teamFilterStatsCache = createCacheWithAsyncInvalidation(teamFilterStatsCacheInvalidator)

const TeamFilterStatsResource = createResource(
  ({ realm, token, filters }) =>
    actions.getFilterStatsAction(
      'teamid',
      { realm, token },
      filters
    ),
  ({ realm, filters }) =>
    JSON.stringify({ realm, filters })
)

const AsyncFilterStats = ({
  dashboardId,
  isDashboard = false,
  filterName,
  dashboardFilterMode,
  dashboardFilters,
  setFilterMode,
  setFilters,
}) => {
  const [dashboard, setDashboard] = useState(null)

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  useEffect(() => {
    if (isNil(dashboard)) setDashboard(dashboardId)
    else setDashboard(null)
  }, [dashboardId])

  if (isNil(dashboard) && !isDashboard)
    return null

  // Apply only teamid filters for checking how far back a field shows up
  const stats = filterName ? FilterStatsResource.read(filterStatsCache, {
      filterName,
      realm,
      token: tokens.accessToken,
      filters: {
        dashboardFilters: rFilter(f => propEq('name', 'teamid')(f), dashboardFilters),
        setFilters: rFilter(f => propEq('name', 'teamid')(f), setFilters),
        dashboardFilterMode,
        setFilterMode,
      }
    }) : null

  // Get a filter on teamid (basically getting the stats for all messages on the team)
  const teamStats = TeamFilterStatsResource.read(teamFilterStatsCache, {
    realm,
    token: tokens.accessToken,
    filters: {
      dashboardFilters: rFilter(f => propEq('name', 'teamid')(f), dashboardFilters),
      setFilters: rFilter(f => propEq('name', 'teamid')(f), setFilters),
      dashboardFilterMode,
      setFilterMode,
    }
  })

  // Ignore case of "Infinity" being returned
  let date = stats && stats.oldestDate && typeof(stats.oldestDate) !== "string" ?
      new Date(parseDate(stats.oldestDate)) : null

  // Override the dynamically generated date for some filters as those fields are not always stored and may not
  // accurately reflect the real initial start date of aggregation
  let hardCodedDate = null
  if (filterName === 'organizational_unit')
    hardCodedDate = new Date('09/22/2020')
  else if (filterName === 'banner_suppressed')
    hardCodedDate = new Date('08/26/2020')

  if (hardCodedDate && teamStats?.oldestDate && typeof(teamStats.oldestDate) !== 'string') {
    let teamFirstMsg = new Date(parseDate(teamStats.oldestDate))
    if (teamFirstMsg > date)
      hardCodedDate = teamFirstMsg
  }

  if (hardCodedDate)
    date = hardCodedDate

  return (
    <I18n>
      {({ locale, t }) => (
        <Container>
          <HelpIcon
            data-tip
            data-for="dateValidHelpIcon"
          />
          <Tooltip id="dateValidHelpIcon" delayShow={0} left>
            <span>{date && t({ key: 'TOOLTIP_FILTER_DATE_VALID',
              variables: { filter: t({ key: filterName.toString() }) || filterName.toString(),
                date:
                  t({key: 'SHORT_DATE_TIME',
                    variables: {
                      locale,
                      date: date,
                    }})
              }})}
            </span>
          </Tooltip>
          <StatisticsText style={{textAlign: "right", flex: "1", marginRight: "8px"}}>
            {date && t({ key: 'FILTER_DATA_DATE', variables: {
              date:
                t({key: 'SHORT_DATE',
                  variables: {
                    locale,
                    date: date,
                  }
                })
            }})}
          </StatisticsText>
        </Container>
      )
      }
    </I18n>
  )
}

const FilterStats = ({ filterName, ...rest }) => filterName && (
  <Suspense
    fallback={<Loader active size="mini" />}
  >
    <AsyncFilterStats filterName={filterName} {...rest} />
  </Suspense>
)

AsyncFilterStats.propTypes = {
  dashboardId: string,
  large: bool,
  isDashboard: bool,
  filterName: string,
}

export default FilterStats
