import styled from 'styled-components'
import {
  TABLE_BORDER,
} from '../../../static/colors'
import {Input as SemanticInput} from "semantic-ui-react"
import {Divider as SemanticDivider} from "semantic-ui-react"
import {Checkbox as SemanticCheckbox} from "semantic-ui-react";
import {Button, SmallButton} from "../modalStyles"

export const Divider = styled(SemanticDivider)`
  width: 90%;
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
`

export const ImportButton = styled(Button)`
`

export const CancelButton = styled(SmallButton)`
  margin: 0 0 0 auto;
`

export const SaveButton = styled(SmallButton)`
  margin: 0 12px 0 12px;
`

export const ApproversTable = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid ${TABLE_BORDER};
  overflow-y: auto;
`

export const OuterContainer = styled.div`
  width: 500px;
  height: 600px;
  max-width: 90vw;
  max-height: 90vh;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  font-size: 11px;
`

export const DataRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  height: 32px;
  align-items: center;
`

export const FieldHeader = styled.div`
  font-weight: 700;
  flex: 0.5
  font-size: 16px;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const Field = styled.div`
  flex: 0.5
  font-size: 16px;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 40px;
`

export const Input = styled(SemanticInput)`
  width: 220px;
`

export const Checkbox = styled(SemanticCheckbox)`
  padding: 5px 0 22px 0;
`

export const Title = styled.div`  
  padding: 20px;
  text-transform: capitalize;
  font-size: 28px;
`
