import axios from 'axios'
import map from 'ramda/src/map'
import omit from 'ramda/src/omit'
import pickBy from 'ramda/src/pickBy'
import identity from 'ramda/src/identity'
import { KOTLIN_URL, MSG_PAGE_SIZE } from '../static/appConfig'
import { toKotlinFilter, fromKotlinProperties } from '../lib/transformations'

// XHR requests
const updateMessageXhr = (body, auth) => axios.put(
  `${KOTLIN_URL}/messages/${body.index}/${body.id}`,
  omit(['index', 'id'], body),
  {
    params: { },
    headers: {
      Authorization: `Bearer ${auth.token}`,
      'Content-Type': 'application/json',
      'X-Team': auth.realm,
    },
  },
)

const getFilterStatsXhr = (filterName, auth, filters) => axios.post(
  `${KOTLIN_URL}/filters/stats/${filterName}`,
  {
    dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
    setFilters: filters.setFilters.map(toKotlinFilter),
    dashboardFilterMode: filters.dashboardFilterMode || 'AND',
    setFilterMode: filters.setFilterMode || 'AND',
  },
  {
    params: { },
    headers: {
      Authorization: `Bearer ${auth.token}`,
      'Content-Type': 'application/json',
      'X-Team': auth.realm,
    },
  },
)

const getFiltersXhr = (realm, token) => axios.get(
  `${KOTLIN_URL}/filters/definitions`,
  {
    params: { },
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'X-Team': realm,
    },
  },
)

const countMessagesXhr = (filters, query) => axios.post(
  `${KOTLIN_URL}/messages/count`,
  {
    dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
    setFilters: filters.setFilters.map(toKotlinFilter),
    dashboardFilterMode: filters.dashboardFilterMode || 'AND',
    setFilterMode: filters.setFilterMode || 'AND',
  },
  {
    params: { },
    headers: {
      Authorization: `Bearer ${query.token}`,
      'Content-Type': 'application/json',
      'X-Team': query.realm,
    },
  },
)

const fetchMessagesXhr = (filters, query, pageSize) => axios.post(
  `${KOTLIN_URL}/messages`,
  {
    dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
    setFilters: filters.setFilters.map(toKotlinFilter),
    dashboardFilterMode: filters.dashboardFilterMode || 'AND',
    setFilterMode: filters.setFilterMode || 'AND',
    sort: query.sort,
  },
  {
    params: { offset: (query.page * pageSize), limit: pageSize },
    headers: {
      Authorization: `Bearer ${query.token}`,
      'Content-Type': 'application/json',
      'X-Team': query.realm,
    },
  },
)

const aggregateByPropertyXhr = (url, filters, query) => axios.post(
  url,
  {
    dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
    setFilters: filters.setFilters.map(toKotlinFilter),
    dashboardFilterMode: filters.dashboardFilterMode || 'AND',
    setFilterMode: filters.setFilterMode || 'AND',
  },
  {
    params: pickBy(identity, omit(['token'], query)),
    headers: {
      Authorization: `Bearer ${query.token}`,
      'Content-Type': 'application/json',
      'X-Team': query.realm,
    },
  },
)

const aggregateByInternalXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/internal`, filters, query)

const aggregateByThreatLevelXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/threat-level`, filters, query)

const aggregateByReasonIdXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/reason-id`, filters, query)

const aggregateByTeamXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/team`, filters, query)

const aggregateByEmailXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/email`, filters, query)

const aggregateByFromEmailXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/from-email`, filters, query)

const aggregateByFromDomainXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/from-domain`, filters, query)

const aggregateByDateXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/date`, filters, query)

const aggregateByMicrosoftSCLXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/microsoft-scl`, filters, query)

const aggregateBySensitiveContentCategoryXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/sensitive-content-category`, filters, query)

const aggregateByPhishingTestProviderXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/phishing-test-provider`, filters, query)

const aggregateByOrganizationalUnitXhr = (filters, query) =>
  aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/organizational-unit`, filters, query)

const aggregateByResultBucketXhr = (filters, query) =>
    aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/result-bucket`, filters, query)

const aggregateByReportLabelXhr = (filters, query) =>
    aggregateByPropertyXhr(`${KOTLIN_URL}/messages/aggregations/report-label`, filters, query)

const getSuggestions = (url, filters, query) => axios.post(
  url,
  {
    dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
    setFilters: filters.setFilters.map(toKotlinFilter),
    dashboardFilterMode: filters.dashboardFilterMode || 'OR',
    setFilterMode: filters.setFilterMode || 'OR',
  },
  {
    params: pickBy(identity, omit(['token'], query)),
    headers: {
      Authorization: `Bearer ${query.token}`,
      'Content-Type': 'application/json',
      'X-Team': query.realm,
    },
  },
)

const getSuggestionsXhr = (filters, query) =>
  getSuggestions(`${KOTLIN_URL}/messages/getSuggestions`, filters, query)

const getTextSuggestionsXhr = (filters, query) =>
  getSuggestions(`${KOTLIN_URL}/messages/getTextSuggestions`, filters, query)

const getIpSuggestionsXhr = (filters, query) => axios.post(
  `${KOTLIN_URL}/messages/getIpSuggestions`,
  {
    dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
    setFilters: filters.setFilters.map(toKotlinFilter),
    dashboardFilterMode: filters.dashboardFilterMode || 'OR',
    setFilterMode: filters.setFilterMode || 'OR',
    terms: query.term,
  },
  {
    params: pickBy(identity, omit(['term', 'token'], query)),
    headers: {
      Authorization: `Bearer ${query.token}`,
      'Content-Type': 'application/json',
      'X-Team': query.realm,
    },
  },
)

const getNestedSuggestionsXhr = (filters, query) =>
  getSuggestions(`${KOTLIN_URL}/messages/getNestedSuggestions`, filters, query)

const getNestedTextSuggestionsXhr = (filters, query) =>
  getSuggestions(`${KOTLIN_URL}/messages/getNestedTextSuggestions`, filters, query)

const getFilteredNestedSuggestionsXhr = (filters, query) => axios.post(
  `${KOTLIN_URL}/messages/getFilteredNestedSuggestions`,
  {
    dashboardFilters: filters.dashboardFilters.map(toKotlinFilter),
    setFilters: filters.setFilters.map(toKotlinFilter),
    aggregationFilter: query.filter,
    dashboardFilterMode: filters.dashboardFilterMode || 'OR',
    setFilterMode: filters.setFilterMode || 'OR',
  },
  {
    params: pickBy(identity, omit(['token'], query)),
    headers: {
      Authorization: `Bearer ${query.token}`,
      'Content-Type': 'application/json',
      'X-Team': query.realm,
    },
  },
)

const getMessageXhr = (id, index, realm, token) => axios.get(
  `${KOTLIN_URL}/messages/${index}/${id}`,
  {
    headers: {
      'X-Team': realm,
      Authorization: `Bearer ${token}`,
    },
  }
)

const addActionDataXhr = (id, index, realm, token, body) => axios.post(
  `${KOTLIN_URL}/messages/${index}/${id}/actions`,
  body,
  {
    headers: {
      'X-Team': realm,
      Authorization: `Bearer ${token}`,
    },
  }
)


// Actions
const updateMessageAction = async (body, auth) => {
  const start = performance.now() // eslint-disable-line no-undef
  const res = await updateMessageXhr(body, auth)
  const end = performance.now() // eslint-disable-line no-undef

  return {
    res: res.data,
    networkTook: end - start,
  }
}

const getPropertiesAction = async (realm, token) => {
  const res = await getFiltersXhr(realm, token)
  return fromKotlinProperties(res.data)
}

const getFilterStatsAction = async (filterName, auth, filters) => {
  const res = await getFilterStatsXhr(filterName, auth, filters)
  return res.data
}

const countMessagesAction = async (filters, query) => {
  try {
    const res = await countMessagesXhr(filters, query)
    return res.data.count
  } catch (error) {
    console.log(error)
    return null
  }
}

const findMessagesAction = async (filters, query) => {
  try {
    const res = await fetchMessagesXhr(filters, query, 100)
    return {
      total: res.data.total,
      hits: res.data.data,
    }
  } catch (error) {
    console.log(error)
    return { total: 0, hits: [], error }
  }
}

const fetchAllMessagesAction = async (filters, query) => {
  try {
    const res = await fetchMessagesXhr(filters, query, MSG_PAGE_SIZE)
    return {
      total: res.data.total,
      hits: res.data.data,
    }
  } catch (error) {
    console.log(error)
    return { total: 0, hits: [], error }
  }
}

const aggregateByInternalAction = async (filters, query) => {
  try {
    const res = await aggregateByInternalXhr(filters, query)
    return {
      data: res.data.data,
    }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatThreatLevelAggsToValues = aggs => map(a => (
  { threatLevel: parseInt(a.key, 10), value: a.count }
), aggs)

const aggregateByThreatLevelAction = async (filters, query) => {
  try {
    const res = await aggregateByThreatLevelXhr(filters, query)
    return {
      data: formatThreatLevelAggsToValues(res.data.data),
    }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatSensitiveContentCategoryAggsToValues = aggs => map(a => (
    { sensitiveContentCategory: a.key, value: a.count }
), aggs)

const aggregateBySensitiveContentCategoryAction = async (filters, query) => {
  try {
    const res = await aggregateBySensitiveContentCategoryXhr(filters, query)
    return {
      data: formatSensitiveContentCategoryAggsToValues(res.data.data),
    }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatPhishingTestProviderAggsToValues = aggs => map(a => (
    { phishingTestProvider: a.key, value: a.count }
), aggs)

const aggregateByPhishingTestProviderAction = async (filters, query) => {
  try {
    const res = await aggregateByPhishingTestProviderXhr(filters, query)
    return {
      data: formatPhishingTestProviderAggsToValues(res.data.data),
    }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatOrganizationalUnitAggsToValues = aggs => map(a => (
  { organizationalUnit: a.key, value: a.count }
), aggs)

const aggregateByOrganizationalUnitAction = async (filters, query) => {
  try {
    const res = await aggregateByOrganizationalUnitXhr(filters, query)
    return {
      data: formatOrganizationalUnitAggsToValues(res.data.data),
    }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatResultBucketAggsToValues = aggs => map(a => (
    { resultBucket: a.key, value: a.count }
), aggs)

const aggregateByResultBucketAction = async (filters, query) => {
  try {
    const res = await aggregateByResultBucketXhr(filters, query)
    return {
      data: formatResultBucketAggsToValues(res.data.data),
    }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatReportLabelAggsToValues = aggs => map(a => (
    { reportLabel: a.key, value: a.count }
), aggs)

const aggregateByReportLabelAction = async (filters, query) => {
  try {
    const res = await aggregateByReportLabelXhr(filters, query)
    return {
      data: formatReportLabelAggsToValues(res.data.data),
    }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatReasonIdAggsToValues = aggs => map(a => (
  { reasonId: parseInt(a.key, 10), value: a.count }
), aggs)

const aggregateByReasonIdAction = async (filters, query) => {
  try {
    const res = await aggregateByReasonIdXhr(filters, query)
    return {
      data: formatReasonIdAggsToValues(res.data.data),
    }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatMicrosoftSCLAggsToValues = aggs => map(a => (
  { scl: parseInt(a.key, 10), value: a.count }
), aggs)

const aggregateByMicrosoftSCLAction = async (filters, query) => {
  try {
    const res = await aggregateByMicrosoftSCLXhr(filters, query)
    return {
      data: formatMicrosoftSCLAggsToValues(res.data.data),
    }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatChildren = children => map(c => (
  { name: c.key, size: c.count }
), children)

const formatTeam = children => map(c => (
  { name: c.name, children: formatChildren(c.children) }
), children)

const formatTeamAggsToValues = aggs => map(a => (
  { name: a.name, children: formatTeam(a.children) }
), aggs)

const aggregateByTeamAction = async (filters, query) => {
  try {
    const res = await aggregateByTeamXhr(filters, query)
    return { data: formatTeamAggsToValues(res.data.data) }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatEmailAggsToValues = aggs => map(a => (
  {
    email: a.key,
    count: a.count,
    countSafe: a.countSafe,
    countCaution: a.countCaution,
    countDanger: a.countDanger,
  }
), aggs)

const aggregateByEmailAction = async (type, filters, query) => {
  try {
    let res
    if (type === 'recipient')
      res = await aggregateByEmailXhr(filters, query)
    else if (type === 'sender')
      res = await aggregateByFromEmailXhr(filters, query)
    else
      res = await aggregateByFromDomainXhr(filters, query)

    return { data: formatEmailAggsToValues(res.data.data) }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const formatDateAggsToValues = aggs => map(a => (
  {
    date: a.key,
    count: a.count,
    countSafe: a.countSafe,
    countCaution: a.countCaution,
    countDanger: a.countDanger,
  }
), aggs)

const aggregateByDateAction = async (filters, query) => {
  try {
    const res = await aggregateByDateXhr(filters, query)
    return { data: formatDateAggsToValues(res.data.data) }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const getSuggestionsAction = async (filters, query) => {
  try {
    const res = await getSuggestionsXhr(filters, query)
    return { data: res.data.data }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const getTextSuggestionsAction = async (filters, query) => {
  try {
    const res = await getTextSuggestionsXhr(filters, query)
    return {
      total: res.data.total,
      hits: res.data.data,
    }
  } catch (error) {
    console.log(error)
    return { total: 0, data: [], error }
  }
}

const getIpSuggestionsAction = async (filters, query) => {
  try {
    const res = await getIpSuggestionsXhr(filters, query)
    return { data: res.data.data }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const getNestedSuggestionsAction = async (filters, query) => {
  try {
    const res = await getNestedSuggestionsXhr(filters, query)
    return { data: res.data.data }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const getNestedTextSuggestionsAction = async (filters, query) => {
  try {
    const res = await getNestedTextSuggestionsXhr(filters, query)
    return { data: res.data.data }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const getFilteredNestedSuggestionsAction = async (filters, query) => {
  try {
    const res = await getFilteredNestedSuggestionsXhr(filters, query)
    return { data: res.data.data }
  } catch (error) {
    console.log(error)
    return { data: [], error }
  }
}

const getMessageAction = async (id, index, realm, token) => {
  const start = performance.now() // eslint-disable-line no-undef
  const res = await getMessageXhr(id, index, realm, token)
  const end = performance.now() // eslint-disable-line no-undef
  return {
    ...res,
    networkTook: end - start,
  }
}

const addActionDataAction = async (id, index, realm, token, body) => {
  try {
    return addActionDataXhr(id, index, realm, token, body)
  } catch (err) {
    console.log("addActionDataAction:Exception", err)
  }
}


const actions = {
  updateMessageAction,
  getPropertiesAction,
  getFilterStatsAction,
  countMessagesAction,
  findMessagesAction,
  fetchAllMessagesAction,
  aggregateByMicrosoftSCLAction,
  aggregateByThreatLevelAction,
  aggregateBySensitiveContentCategoryAction,
  aggregateByPhishingTestProviderAction,
  aggregateByOrganizationalUnitAction,
  aggregateByResultBucketAction,
  aggregateByReportLabelAction,
  aggregateByReasonIdAction,
  aggregateByInternalAction,
  aggregateByTeamAction,
  aggregateByEmailAction,
  aggregateByDateAction,
  getSuggestionsAction,
  getIpSuggestionsAction,
  getTextSuggestionsAction,
  getNestedSuggestionsAction,
  getNestedTextSuggestionsAction,
  getFilteredNestedSuggestionsAction,
  getMessageAction,
  addActionDataAction,
}

export default actions
