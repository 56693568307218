import React from 'react'
import { isRedirect } from '@reach/router'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      info: null,
    }
  }

  componentDidCatch(error, info) {
    if (isRedirect(error)) {
      throw error
    }

    this.setState({ hasError: true, error, info })
  }

  render() {
    const { hasError, error, info } = this.state
    const { fallback, children } = this.props

    return hasError
      ? typeof fallback === 'function'
        ? fallback(error, info)
        : fallback
      : children
  }
}

export default ErrorBoundary
