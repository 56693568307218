import React, { useCallback, useState } from 'react'
import { I18n, I18nContext } from 'lib/i18n'
import { useReports } from 'providers/Reports'
import { HelpHotspot } from 'components/Help/HelpHotspot'
import { IconButton, Icon, Menu, MenuItem, MenuTitle } from './styles'

import exportIcon from 'assets/reports/download.png'

type ReportExportAction = {
  label: string
  value: string
}

export const ReportActionsButton = () => {
  const { exportToPDF } = useReports()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const getReportExportActions = useCallback(
    (t: I18nContext['t']): Array<ReportExportAction> => [
      {
        label: t({
          key: 'REPORTS_TOP_BAR_MENU_EXPORT_TO_PDF',
        }),
        value: 'export_to_pdf',
      },
      {
        label: t({
          key: 'REPORTS_TOP_BAR_MENU_PRINT',
        }),
        value: 'print',
      },
    ],
    []
  )

  const handleReportActionClicked = useCallback(
    (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      item: ReportExportAction
    ) => {
      // TODO handle report action clicked
    },
    []
  )

  const handleReportActionsMenuClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    []
  )

  return (
    <I18n>
      {({ t }) => (
        <>
          <HelpHotspot
            offsetTop={-2}
            offsetLeft={12}
            title={t({ key: 'HOTSPOT_EXPORT_REPORT_TITLE' })}
            content={t({ key: 'HOTSPOT_EXPORT_REPORT_CONTENT' })}
          >
            <IconButton
              color='secondary'
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={exportToPDF} // TODO: Implement more export options
              // onClick={handleReportActionsMenuClicked}
            >
              <Icon src={exportIcon} />
            </IconButton>
          </HelpHotspot>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: -8,
              horizontal: 'right',
            }}
            elevation={2}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null)
            }}
          >
            {getReportExportActions(t).map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                onClick={(event) => handleReportActionClicked(event, item)}
              >
                <MenuTitle>{item.label}</MenuTitle>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </I18n>
  )
}
