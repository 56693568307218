import React, { ReactNode } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { useHelp } from 'providers/Help'
import {
  useTooltipStyles,
  Container,
  Spot,
  Circle,
  Title,
  Content,
  HelpHotspotWrapper,
} from './styles'

type HelpHotspotProps = {
  children: ReactNode
  title?: string
  content?: string
  disabled?: boolean
  offsetTop?: number
  offsetLeft?: number
}

export const HelpHotspot = ({
  children,
  title,
  content,
  offsetTop,
  offsetLeft,
  disabled = false,
}: HelpHotspotProps) => {
  const tooltipClasses = useTooltipStyles()
  const { isHelpGuideEnabled, toggleIsHelpGuideEnabled } = useHelp()

  return (
    <HelpHotspotWrapper>
      {children}
      {isHelpGuideEnabled && !disabled ? (
        <Container top={offsetTop} left={offsetLeft}>
          <Tooltip
            arrow
            placement='right'
            classes={tooltipClasses}
            title={
              <div>
                <Title>{title}</Title>
                <Content>{content}</Content>
              </div>
            }
          >
            <Spot
              animate={{ scale: [1, 1.1, 0.9, 1.1, 1] }}
              transition={{
                duration: 2,
                repeatDelay: 3,
                loop: Infinity,
                ease: 'easeInOut',
                times: [0, 0.2, 0.5, 0.7, 1],
              }}
              onClick={toggleIsHelpGuideEnabled}
            >
              <Circle />
            </Spot>
          </Tooltip>
        </Container>
      ) : null}
    </HelpHotspotWrapper>
  )
}
