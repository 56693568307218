import React, { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '@logicea/react-auth'
import { I18n } from 'lib/i18n'
import { useReports } from 'providers/Reports'
import { countUniquePrimaryRecipientsAction } from 'requests/messageRQ'
import { MagicNumber, MagicNumberPlaceholder } from '../MagicNumber'
import { buildReportDashboardFilters } from "../../../lib/util";

const UniquePrimaryRecipients = () => {
  const { timeframe, comparisonTimeframe, isComparisonEnabled, teamFilter } = useReports()
  const { from, to } = timeframe
  const { from: comparisonFrom, to: comparisonTo } = comparisonTimeframe

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth as any
  const queryArgs = { realm, token: tokens?.accessToken }

  const filterArgs = {
    dashboardFilters: buildReportDashboardFilters(from, to, teamFilter),
    setFilters: [],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const comparedFilterArgs = {
    dashboardFilters: buildReportDashboardFilters(comparisonFrom, comparisonTo, teamFilter),
    setFilters: [],
    dashboardFilterMode: 'AND',
    setFilterMode: 'AND',
  }

  const { data, isLoading: dataIsLoading } = useQuery(
    ['countUniquePrimaryRecipients', filterArgs, queryArgs],
    countUniquePrimaryRecipientsAction,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const { data: comparedData, isLoading: comparedDataIsLoading } = useQuery(
    ['comparedCountUniquePrimaryRecipients', comparedFilterArgs, queryArgs],
    countUniquePrimaryRecipientsAction,
    { enabled: realm !== undefined && tokens !== undefined }
  )

  const percent = useMemo(() => {
    if (comparedData?.count === 0) return undefined
    if (data && comparedData) {
      return data.count / comparedData.count - 1
    } else {
      return 0
    }
  }, [data, comparedData])

  const isLoading = dataIsLoading || comparedDataIsLoading

  return (
    <I18n>
      {({ t }) =>
        !isLoading ? (
          <MagicNumber
            title={t({ key: 'UNIQUE_PRIMARY_RECIPIENTS' })}
            number={data?.count}
            percent={isComparisonEnabled ? percent : undefined}
          />
        ) : (
          <MagicNumberPlaceholder
            title={t({ key: 'UNIQUE_PRIMARY_RECIPIENTS' })}
          />
        )
      }
    </I18n>
  )
}

export { UniquePrimaryRecipients }
