import React, { useCallback, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useHelp } from 'providers/Help'
import { I18n } from 'lib/i18n'
import {
  Overlay,
  Background,
  Toast,
  Box,
  Headline,
  Prompt,
  Button,
  Content,
  LogoIcon,
  Footer,
  LargeButton,
  SkipButton,
} from './styles'

const HelpGuideToast = () => {
  const { toggleIsHelpGuideEnabled } = useHelp()

  return (
    <I18n>
      {({ t }) => (
        <Toast>
          <Prompt>{t({ key: 'HELP_GUIDE_PROMPT' })}</Prompt>
          <Button color='secondary' onClick={toggleIsHelpGuideEnabled}>
            {t({ key: 'HELP_GUIDE_EXIT' })}
          </Button>
        </Toast>
      )}
    </I18n>
  )
}

const QuickTourWelcome = () => {
  const { advanceToNextStep, skipTour } = useHelp()

  return (
    <I18n>
      {({ t }) => (
        <Box
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Content>
            <LogoIcon />
            <Headline>{t({ key: 'QUICK_TOUR_WELCOME_TITLE' })}</Headline>
            <Prompt>{t({ key: 'QUICK_TOUR_WELCOME_CONTENT' })}</Prompt>
          </Content>
          <Footer>
            <LargeButton onClick={advanceToNextStep}>
              {t({ key: 'QUICK_TOUR_LAUNCH' })}
            </LargeButton>
            <SkipButton onClick={skipTour}>
              {t({ key: 'QUICK_TOUR_SKIP' })}
            </SkipButton>
          </Footer>
        </Box>
      )}
    </I18n>
  )
}

export const HelpOverlay = () => {
  const {
    isHelpGuideEnabled,
    isQuickTourEnabled,
    currentQuickTourStepId,
    setIsHelpGuideEnabled,
    setIsQuickTourEnabled,
  } = useHelp()

  const handleEscape = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsHelpGuideEnabled(false)
      setIsQuickTourEnabled(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false)
    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [])

  const isOverlayVisible = isHelpGuideEnabled || isQuickTourEnabled
  return (
    <AnimatePresence>
      {isOverlayVisible ? (
        <Overlay
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Background>
            {isHelpGuideEnabled ? <HelpGuideToast /> : null}
            {isQuickTourEnabled && currentQuickTourStepId === 'welcome' ? (
              <QuickTourWelcome />
            ) : null}
          </Background>
        </Overlay>
      ) : null}
    </AnimatePresence>
  )
}
