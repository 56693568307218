import React from 'react'
import { I18n } from 'lib/i18n'

import {
    Container,
    Title,
    WidgetIcon,
} from './styles'

export const EmptyResults = () => (
    <I18n>
        {({ t }) => (
            <Container>
                <WidgetIcon />
                <Title>
                   <div>{t({ key: 'VISUALISATION_NO_RESULTS' })}</div>
                </Title>
            </Container>
        )}
    </I18n>
)