import styled from 'styled-components'
import {
  colors,
  HeadlineH1PrimaryMainBold,
  spacing,
  HeadlineH3WhiteRegular,
} from 'static/theme'
import eyeIcon from 'assets/eye/eye.png'
import { IconButton as Button } from 'components/Buttons/IconButton'
import { ReactComponent as PlusSVG } from 'assets/marketplace/plus.svg'
import { ReactComponent as RefreshSVG } from 'assets/buttons/refresh.svg'

const RIGHT_GROUP_WIDTH = 92

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.background};
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 6rem;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};
  padding: ${spacing.m};
`
export const SubHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.s} ${spacing.m};
`

export const Column = styled.div`
  flex: 1;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Title = styled(HeadlineH1PrimaryMainBold)`
  align-self: center;
  margin-bottom: 0.2rem;
`

export const IconButton = styled(Button)`
  && {
    margin-left: ${spacing.m};
    margin-right: ${spacing.xs};
    height: 48px;
    width: 165px;
    border-radius: 8px;
  }
`

export const ButtonText = styled(HeadlineH3WhiteRegular)`
  line-height: 1.5;
`

export const PlusIcon = styled(PlusSVG)`
  fill: ${colors.white};
  margin-right: ${spacing.xs};
`

export const ViewOnlyWrapper = styled.div`
  margin-right: ${RIGHT_GROUP_WIDTH}px;
  margin-left: -${RIGHT_GROUP_WIDTH}px;
`

export const ViewOnlyIcon = styled.img.attrs({
  src: eyeIcon,
  alt: 'View Only',
})`
  width: 28px;
  height: 20px;
  align-self: center;
  margin-top: 6px;
  margin-right: 16px;
  opacity: 0.4;
`

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
`

export const PopupText = styled.div`
  margin-bottom: 8px;
`

export const RefreshButton = styled(RefreshSVG)`
  fill: ${colors.primaryMain};
  margin-right: ${spacing.xs};
  &:hover {
    cursor: pointer;
  }
`

export default {
  popup: {
    fontSize: 14,
    fontFamily: 'Lato',
    pointerEvents: 'auto',
    color: '#00205c',
  },
}
