import styled from 'styled-components'
import { Body2WhiteRegular, colors, spacing } from 'static/theme'
import { ReactComponent as ChevronDownSVG } from 'assets/dropdownIcon/chevron-down.svg'
import { ReactComponent as chartPieSVG } from 'assets/widgets/charts-pie.svg'
import { ReactComponent as chartBarSVG } from 'assets/widgets/charts-bar.svg'
import { ReactComponent as chartLineSVG } from 'assets/widgets/charts-line.svg'
import { ReactComponent as chartAreaSVG } from 'assets/widgets/charts-area.svg'
import { ReactComponent as chartListSVG } from 'assets/widgets/charts-list.svg'
import { ReactComponent as chartStackBarSVG } from 'assets/widgets/charts-stack-bar.svg'
import { ReactComponent as chartMapSVG } from 'assets/widgets/charts-map.svg'
import { ReactComponent as chartMapZoomableSVG } from 'assets/widgets/charts-map-zoomable.svg'
import { ReactComponent as defaultSVG } from 'assets/widgets/charts-default.svg'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const VisualizationButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: -4px;
  margin-top: 4px;
  cursor: pointer;
`

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.primaryMain};
  padding: ${spacing.xs} 0rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${spacing.xxs} ${spacing.xs2};
  &:hover {
    cursor: pointer;
  }
`

export const ChevronDownIcon = styled(ChevronDownSVG)`
  fill: ${colors.primaryMain};
`

export const svgStyles = `
  width: 24px;
  height: 24px;
`

export const VisualizationText = styled(Body2WhiteRegular)<{
  activeOnList?: boolean
}>`
  color: ${({ activeOnList }) =>
    activeOnList ? colors.primaryDark : colors.white};
  margin-left: ${spacing.xxs};
`

export const PieIcon = styled(chartPieSVG)<{
  activeOnList?: boolean
  selected?: boolean
}>`
  ${svgStyles}
  fill: ${({ activeOnList, selected }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const BarIcon = styled(chartBarSVG)<{
  activeOnList?: boolean
  selected?: boolean
}>`
  ${svgStyles}
  fill: ${({ activeOnList, selected }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const LineIcon = styled(chartLineSVG)<{
  activeOnList?: boolean
  selected?: boolean
}>`
  ${svgStyles}
  fill: ${({ activeOnList, selected }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const AreaIcon = styled(chartAreaSVG)<{
  activeOnList?: boolean
  selected?: boolean
}>`
  ${svgStyles}
  fill: ${({ activeOnList, selected }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const ListIcon = styled(chartListSVG)<{
  activeOnList?: boolean
  selected?: boolean
}>`
  ${svgStyles}
  fill: ${({ activeOnList, selected }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const StackBarIcon = styled(chartStackBarSVG)<{
  activeOnList?: boolean
  selected?: boolean
}>`
  ${svgStyles}
  fill: ${({ activeOnList, selected }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const MapIcon = styled(chartMapSVG)<{
  activeOnList?: boolean
  selected?: boolean
}>`
  ${svgStyles}
  fill: ${({ activeOnList, selected }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const MapZoomableIcon = styled(chartMapZoomableSVG)<{
  activeOnList?: boolean
  selected?: boolean
}>`
  ${svgStyles}
  fill: ${({ activeOnList, selected }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`

export const DefaultIcon = styled(defaultSVG)<{
  activeOnList?: boolean
  selected?: boolean
}>`
  ${svgStyles}
  fill: ${({ activeOnList, selected }) =>
    selected
      ? colors.primaryMain
      : activeOnList
      ? colors.primaryDark
      : colors.white};
`
