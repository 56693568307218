import styled from 'styled-components'
import { Form } from 'semantic-ui-react'

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`

export const InputGroup = styled(Form.Group).attrs({
  inline: true,
})`
  display: flex;
  flex-direction: row;
  max-width: 400px;
`

export const InputItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-right: 10px;
`

export const Dot = styled.div`
  font-size: 24px;
  position: relative;
  left: 5px;
`
