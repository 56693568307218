import styled from 'styled-components'
import { Checkbox as CheckboxMaterial } from '@material-ui/core'
import {
  spacing,
  colors,
  Body1PrimaryDarkRegular,
  BodyXSPrimaryDarkRegular,
} from 'static/theme/index'
import { ReactComponent as AscendingSVG } from 'assets/svg/ascending.svg'
import { ReactComponent as DescendingSVG } from 'assets/svg/descending.svg'
import { ReactComponent as DropdownSVG } from 'assets/dropdownIcon/chevron-down.svg'
import { ReactComponent as PrivateSVG } from 'assets/marketplace/private.svg'
import { ReactComponent as SharedSVG } from 'assets/marketplace/shared.svg'
import { ReactComponent as PublicSVG } from 'assets/marketplace/public.svg'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.primaryExtraLight};
  overflow-y: auto;
  min-width: 712px;
`

export const Dashboards = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${spacing.xl};
  width: 100%;
`

export const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${spacing.m};
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
`

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
`

export const FilterLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Sort = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const Order = styled.div`
  margin-right: ${spacing.s};
`

export const Label = styled(Body1PrimaryDarkRegular)`
  margin-right: ${spacing.xs};
`

export const Checkbox = styled(CheckboxMaterial)``

export const PaginationTableWrapper = styled.div`
  min-height: 52px;
`

export const PaginationLabel = styled(BodyXSPrimaryDarkRegular)`
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
`

export const AscendingIcon = styled(AscendingSVG)`
  fill: ${colors.secondaryMain};
`
export const DescendingIcon = styled(DescendingSVG)`
  fill: ${colors.secondaryMain};
`

export const DropdownIcon = styled(DropdownSVG)`
  fill: ${colors.primaryDark};
  margin-top: 4px;
`

export const PrivateIcon = styled(PrivateSVG)`
  fill: ${colors.primaryMain};
`

export const SharedIcon = styled(SharedSVG)`
  fill: ${colors.primaryMain};
`

export const PublicIcon = styled(PublicSVG)`
  fill: ${colors.primaryMain};
`
