import styled from 'styled-components'
import {
  colors,
  BodyXSPrimaryMainBold,
  BodyXSDarkGreyRegular,
  spacing,
} from 'static/theme'

export const TootlipContainer = styled.div`
  box-shadow: 0 2px 14px 0 ${colors.primaryLighter};
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.xs};
`

export const TooltipLabel = styled(BodyXSPrimaryMainBold)``

export const TooltipValue = styled(BodyXSDarkGreyRegular)`
  margin-top: ${spacing.xxs};
`
