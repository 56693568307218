import { saveAs } from 'file-saver'

export const svgToPng = (svg, width, height) => {

  return new Promise((resolve, reject) => {

      let canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');

      // Set background to white
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, width, height);

      let xml = new XMLSerializer().serializeToString(svg);
      let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
      let img = new Image(width, height);

      img.onload = () => {
          ctx.drawImage(img, 0, 0);
          let imageData = canvas.toDataURL('image/png', 1.0);
          canvas.toBlob(function(blob) {
            saveAs(blob, "export.png");
          });
          resolve(imageData)
      }

      img.onerror = () => reject();

      img.src = dataUrl;
  });
};  

export const convertChart = async (ref, width, height) => {

    if (ref && ref.container) {
        let svg = ref.container.children[0];
        await svgToPng(svg, width, height);
    }
};