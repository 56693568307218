import React, { useState, useEffect, Suspense } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import propOr from 'ramda/src/propOr'
import { Loader } from 'semantic-ui-react'
import { Radio } from 'components/Radio'
import { filter as rFilter } from 'ramda/src'
import actions from '../../../requests/message'
import { createResource, createCacheWithAsyncInvalidation } from '../../../lib/simple-cache-utils'
import { I18n } from '../../../lib/i18n'

const RadioWrapper = styled.div`
  margin: 10px;
`

const { object, func, array, string } = PropTypes

let messageCountCache

const messageCountCacheInvalidator = () => {
  messageCountCache = createCacheWithAsyncInvalidation(messageCountCacheInvalidator)
}
messageCountCache = createCacheWithAsyncInvalidation(messageCountCacheInvalidator)

const MessageCountResource = createResource(
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token }) =>
    actions.aggregateByInternalAction({
      dashboardFilters: rFilter(f => !propEq('name', 'internal')(f), dashboardFilters),
      setFilters: rFilter(f => !propEq('name', 'internal')(f), setFilters),
      dashboardFilterMode,
      setFilterMode,
    }, { realm, token }),
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm }) =>
    JSON.stringify({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm })
)

const AsyncBooleanInput = ({
  realm,
  token,
  filter,
  onChange,
  setFilters,
  setFilterMode,
  dashboardFilters,
  dashboardFilterMode,
}) => {
  const [boolValue, setBoolValue] = useState(true)

  useEffect(() => {
    if (filter && filter.term !== '') {
      setBoolValue(filter.name === 'banner_suppressed' ? !filter.term : filter.term)
      onChange(filter.name === 'banner_suppressed' ? !filter.term : filter.term)
    } else {
      onChange(true)
    }
  }, [])

  const handleRadioChange = () => {
    setBoolValue(!boolValue)
    onChange(!boolValue)
  }

  let trueCount
  let falseCount

  if (filter.name === 'internal') {
    const data = MessageCountResource.read(messageCountCache, {
      dashboardFilterMode,
      dashboardFilters,
      setFilterMode,
      setFilters,
      realm,
      token,
    })

    trueCount = propOr(0, 'count', find(propEq('key', 'true'), propOr([], 'data', data)))
    falseCount = propOr(0, 'count', find(propEq('key', 'false'), propOr([], 'data', data)))
  }

  return (
    <I18n>{({ t }) => (
      <div>
        <RadioWrapper>
          <Radio
            label={filter.name === 'internal'
              ? `${t({ key: 'INPUT_INTERNAL' })} (${trueCount})`
              : t({ key: 'TRUE' })
            }
            checked={boolValue}
            onChange={handleRadioChange}
          />
        </RadioWrapper>
        <RadioWrapper>
          <Radio
            label={filter.name === 'internal'
              ? `${t({ key: 'INPUT_EXTERNAL' })} (${falseCount})`
              : t({ key: 'FALSE' })
            }
            checked={!boolValue}
            onChange={handleRadioChange}
          />
        </RadioWrapper>
      </div>
    )}
    </I18n>
  )
}

const BooleanInput = props => (
  <Suspense
    fallback={<Loader active />}
  >
    <AsyncBooleanInput {...props} />
  </Suspense>
)

AsyncBooleanInput.propTypes = {
  realm: string,
  token: string,
  filter: object,
  onChange: func,
  setFilters: array,
  setFilterMode: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
}

export default BooleanInput
