import moment from 'moment'
import { I18nContext } from 'lib/i18n'

export const getTimeframeOptions = (t: I18nContext['t']) => [
  {
    label: t({
      key: 'TIME_FRAME_OPTION_TODAY',
    }),
    value: 0,
  },
  {
    label: t({
      key: 'TIME_FRAME_OPTION_YESTERDAY',
    }),
    value: 1,
  },
  {
    label: t({
      key: 'TIME_FRAME_OPTION_LAST_WEEK',
    }),
    value: 7,
  },
  {
    label: t({
      key: 'TIME_FRAME_OPTION_LAST_10_DAYS',
    }),
    value: 10,
  },
  {
    label: t({
      key: 'TIME_FRAME_OPTION_LAST_MONTH',
    }),
    value: 30,
  },
  {
    label: t({
      key: 'TIME_FRAME_OPTION_LAST_3_MONTHS',
    }),
    value: 90,
  },
  {
    label: t({
      key: 'TIME_FRAME_OPTION_LAST_YEAR',
    }),
    value: 365,
  },
  // {
  //   label: t({
  //     key: 'TIME_FRAME_OPTION_CUSTOM',
  //   }),
  //   value: -1,
  // },
]

export const getTimeframe = (timeframeOption: number) => {
  switch (timeframeOption) {
    // case -1:
    //   //TODO custom time frame option
    //   return undefined
    case 0: {
      const to = new Date()
      const from = new Date(to)
      from.setHours(0, 0, 0, 0)
      return { from: moment(from).unix(), to: moment(to).unix() }
    }
    case 1: {
      const to = new Date()
      to.setDate(to.getDate() - 1)
      const from = new Date(to)
      from.setHours(0, 0, 0, 0)
      to.setHours(23, 59, 59, 999)
      return { from: moment(from).unix(), to: moment(to).unix() }
    }
    default: {
      const to = new Date()
      to.setDate(to.getDate() - 1)
      const from = new Date(to)
      from.setDate(from.getDate() - timeframeOption)
      from.setHours(0, 0, 0, 0)
      to.setHours(23, 59, 59, 999)
      return { from: moment(from).unix(), to: moment(to).unix() }
    }
  }
}

export const getComparisonTimeframe = (timeframeOption: number) => {
  switch (timeframeOption) {
    // case -1:
    //   //TODO custom time frame option
    //   return undefined
    case 0: {
      const to = new Date()
      to.setDate(to.getDate() - 1)
      const from = new Date(to)
      from.setDate(to.getDate())
      from.setHours(0, 0, 0, 0)
      to.setHours(23, 59, 59, 999)
      return { from: moment(from).unix(), to: moment(to).unix() }
    }
    case 1: {
      const to = new Date()
      to.setDate(to.getDate() - 2)
      const from = new Date(to)
      from.setDate(to.getDate())
      from.setHours(0, 0, 0, 0)
      to.setHours(23, 59, 59, 999)
      return { from: moment(from).unix(), to: moment(to).unix() }
    }
    default: {
      const to = new Date()
      to.setDate(to.getDate() - 2 - timeframeOption)
      const from = new Date(to)
      from.setDate(from.getDate() - timeframeOption)
      from.setHours(0, 0, 0, 0)
      to.setHours(23, 59, 59, 999)
      return { from: moment(from).unix(), to: moment(to).unix() }
    }
  }
}

export const renderTimeframe = (
  timeframeOption: number,
  timeframe: { from: number; to: number }
) => {
  const { from, to } = timeframe

  switch (timeframeOption) {
    case -1:
      //TODO custom time frame option
      break
    case 0: {
      return `${moment(from * 1000).format('DD MMM YYYY')} - ${moment(
        to * 1000
      ).format('h:mm a')}`
    }
    case 1: {
      return `${moment(from * 1000).format('DD MMM YYYY h:mm a')} - ${moment(
        to * 1000
      ).format('DD MMM YYYY h:mm a')}`
    }
    default: {
      return `${moment(from * 1000).format('DD MMM YYYY')} - ${moment(
        to * 1000
      ).format('DD MMM YYYY')}`
    }
  }
}
export const renderComparedTimeframe = (
  timeframeOption: number,
  timeframe: { from: number; to: number }
) => {
  const { from, to } = timeframe

  switch (timeframeOption) {
    case -1:
      //TODO custom time frame option
      break
    case 0: {
      return `${moment(from * 1000).format('DD MMM YYYY h:mm a')} - ${moment(
        to * 1000
      ).format('DD MMM YYYY h:mm a')}`
    }
    case 1: {
      return `${moment(from * 1000).format('DD MMM YYYY h:mm a')} - ${moment(
        to * 1000
      ).format('DD MMM YYYY h:mm a')}`
    }
    default: {
      return `${moment(from * 1000).format('DD MMM YYYY')} - ${moment(
        to * 1000
      ).format('DD MMM YYYY')}`
    }
  }
}
