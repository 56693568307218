import styled from 'styled-components'
import {
  Body1PrimaryMainRegular,
  BodyXSPrimaryDarkRegular,
  spacing,
} from 'static/theme'
import { Switch } from '@material-ui/core'

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const Title = styled(Body1PrimaryMainRegular)``

export const CompareSwitch = styled(Switch)`
  margin: 0 ${spacing.xs};
`

export const DatePeriod = styled(BodyXSPrimaryDarkRegular)``
