type QuickTourStepsT = Array<{
  id: string
  visited: boolean
}>

type RedirectT = {
  path: string
  label: string
} | null

export type RouteQuickTourT = {
  status: 'NEW' | 'SKIPPED' | 'COMPLETED'
  currentStepId: string | null
  steps: QuickTourStepsT
  navigateTo: RedirectT
  navigateFrom: RedirectT
}

export type QuickTourStateT = {
  version: number
  state: Record<string, RouteQuickTourT>
}

export const INITIAL_QUICK_TOUR_STATE: QuickTourStateT = {
  version: 15,
  state: {
    '/': {
      status: 'NEW',
      currentStepId: null,
      steps: [
        { id: 'welcome', visited: false },
        { id: 'overview', visited: false },
        { id: 'compareData', visited: false },
      ],
      navigateTo: { path: '/dashboards', label: 'Explore Dashboards' },
      navigateFrom: null,
    },
    '/dashboards': {
      status: 'NEW',
      currentStepId: null,
      steps: [
        { id: 'gallery', visited: false },
        { id: 'quickAccess', visited: false },
        { id: 'dashboardActions', visited: false },
        { id: 'addDashboard', visited: false },
      ],
      navigateTo: { path: '/reports', label: 'Explore Reports' },
      navigateFrom: { path: '/', label: 'Explore Overview' },
    },
    '/reports': {
      status: 'NEW',
      currentStepId: null,
      steps: [{ id: 'reports', visited: false }],
      navigateTo: { path: '/settings/allowlist', label: 'Next Tip' },
      navigateFrom: { path: '/dashboards', label: 'Explore Dashboards' },
    },
    '/settings/allowlist': {
      status: 'NEW',
      currentStepId: null,
      steps: [
        { id: 'settings', visited: false },
        { id: 'allowlist', visited: false },
      ],
      navigateTo: { path: '/settings/blocklist', label: 'Next Tip' },
      navigateFrom: { path: '/reports', label: 'Explore Reports' },
    },
    '/settings/blocklist': {
      status: 'NEW',
      currentStepId: null,
      steps: [{ id: 'blocklist', visited: false }],
      navigateTo: null,
      navigateFrom: { path: '/reports', label: 'Previous Tip' },
    },
  },
}
