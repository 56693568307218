import React, { useState, useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import {
  Cell,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from 'recharts'
import prop from 'ramda/src/prop'
import eqBy from 'ramda/src/eqBy'
import isNil from 'ramda/src/isNil'
import isEmpty from 'ramda/src/isEmpty'
import unionWith from 'ramda/src/unionWith'
import { Loader } from 'semantic-ui-react'
import { AuthContext } from '@logicea/react-auth'
import ReactResizeDetector from 'react-resize-detector'
import { createResource, createCacheWithAsyncInvalidation } from '../../../lib/simple-cache-utils'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/message'
import { noop } from '../../../lib/ramda'
import { I18n } from '../../../lib/i18n'
import { Tooltip as ButtonTooltip } from '../../../lib/util'
import {
  DARK_GREY,
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
  VISUALIZATION_TICK_DARK,
  VISUALIZATION_BACKGROUND,
} from '../../../static/colors'
import {
  TINY_VISUALIZATION_WIDTH_THRESHOLD,
  TINY_VISUALIZATION_HEIGHT_THRESHOLD,
  VISUALIZATION_MARGIN,
} from '../../../static/appConfig'
import {
  Container,
  AbsolutePosition,
  TickText,
} from './styles'
import {
  TootlipContainer,
  TooltipLabel,
  TooltipValue,
} from '../tooltipStyles'
import {
  LegendWrapper,
  ThreatLevelLegend,
  LegendText,
  LegendDot,
  ConfigureIcon,
} from '../legendStyles'
import THREAT_LEVELS from '../../../static/threatLevels'
import { exportChart } from './export'
import { EmptyResults } from '../EmptyResults'

const { array, bool, func, string, number, object } = PropTypes

let emailAggCache

const emailAggCacheInvalidator = () => {
  emailAggCache = createCacheWithAsyncInvalidation(emailAggCacheInvalidator)
}
emailAggCache = createCacheWithAsyncInvalidation(emailAggCacheInvalidator)

const EmailAggResource = createResource(
  ({ aggregationType, dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token }) =>
    actions.aggregateByEmailAction(aggregationType,
      { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode },
      { realm, token },
    ),
  ({ aggregationType, dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm }) =>
    JSON.stringify({ aggregationType, dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm })
)

const AsyncEmailStackedBarChart = ({
  dashboardId,
  dashboardFilters,
  setFilters,
  dashboardFilterMode,
  setFilterMode,
  onCellClick,
  timestamp,
  downloadAll,
  onFetch,
  belongsToUser,
  aggregationType,
  shouldExport,
  afterExport,
}) => {
  const [dashboard, setDashboard] = useState(null)

  const context = useContext(DashboardsContext)
  const {
    setViewModeTooltip,
  } = context

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  useEffect(() => {
    if (isNil(dashboard)) setDashboard(dashboardId)
    else setDashboard(null)
  }, [dashboardId])

  useEffect(() => {
    if (downloadAll) {
      const fetchedData = EmailAggResource
        .read(emailAggCache, {
          aggregationType,
          dashboardFilters,
          setFilters,
          dashboardFilterMode,
          setFilterMode,
          timestamp,
          realm,
          token: tokens.accessToken,
        })
      const formatedData = fetchedData.data.map(d => ({
        email: d.email,
        total: d.count,
        neutral: d.countSafe,
        caution: d.countCaution,
        danger: d.countDanger,
      }))
      onFetch(formatedData)
    }
  }, [downloadAll, dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp])

  if (isNil(dashboard)) return null

  const data = EmailAggResource
    .read(emailAggCache, {
      aggregationType,
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
      timestamp,
      realm,
      token: tokens.accessToken,
    })
  const aggregationData = data.data
  const { networkTook, elasticsearchTook } = data

  return (
    <EmailStackedBarChartLayout
      elasticsearchTook={elasticsearchTook}
      networkTook={networkTook}
      aggregationData={aggregationData}
      onCellClick={belongsToUser ? onCellClick : () => setViewModeTooltip(true)}
      setFilters={setFilters}
      animationsEnabled
      aggregationType={aggregationType}
      shouldExport={shouldExport}
      afterExport={afterExport}
    />
  )
}

const CountTick = ({ x, y, payload, onLabelClick }) => (
  <g transform={`translate(${x},${y})`}>
    <TickText
      x={40}
      y={4}
      textAnchor="end"
      fill={DARK_GREY}
      isClickable={!!onLabelClick}
      onClick={() => {
        if (onLabelClick) onLabelClick(payload.value)
      }}
    >
      {payload.value}
    </TickText>
  </g>
)

const LEVEL_NAMES = {
  countSafe: THREAT_LEVELS[0],
  countCaution: THREAT_LEVELS[1],
  countDanger: THREAT_LEVELS[2],
}

const CustomTooltip = ({ payload, label, active }) => (
  active ? (
    <I18n>{({ t }) => (
      <TootlipContainer>
        <TooltipLabel>{label}</TooltipLabel>
        {payload.map(level => (
          <TooltipValue key={level.name}>
            {LEVEL_NAMES[level.name]}:
            {t({
              key: 'AGGREGATION_HITS',
              variables: { aggregationHits: level.value },
            })}
          </TooltipValue>
        ))}
      </TootlipContainer>
    )}
    </I18n>
  ) : null
)

const EmailStackedBarChartLayout = ({
  aggregationData = [],
  animationsEnabled = false,
  onCellClick = noop,
  setFilters = [],
  aggregationType,
  shouldExport,
  afterExport,
}) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [scale, setScale] = useState('log')
  const [domain, setDomain] = useState([1, 'auto'])
  const [minPointSize, setMinPointSize] = useState(0)

  useEffect(() => {
    if (shouldExport && aggregationData) {
      exportChart(aggregationData, scale, domain, minPointSize)
      afterExport()
    }
  }, [shouldExport, afterExport, aggregationData, scale, domain, minPointSize])


  const onResize = (w, h) => {
    setWidth(w)
    setHeight(h)
  }

  const toggleScale = () => {
    const newScale = scale === 'auto' ? 'log' : 'auto'
    setScale(newScale)
    setDomain(newScale === 'log' ? [1, 'auto'] : [0, 'auto'])
    setMinPointSize(newScale === 'log' ? 5 : 0)
  }

  const tiny = width < TINY_VISUALIZATION_WIDTH_THRESHOLD || height < TINY_VISUALIZATION_HEIGHT_THRESHOLD

  const agg_field_name = aggregationType === 'recipient' ? 'email' : aggregationType === 'sender' ? 'from_email' : 'from_domain'

  return (
    <I18n>{({ t }) => (
      isEmpty(aggregationData) ? (
          <EmptyResults />
      ) : (
        <Container>
          <LegendWrapper>
            <ThreatLevelLegend>
              <LegendDot threat={2} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 2 } })}
              </LegendText>
              <LegendDot threat={1} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 1 } })}
              </LegendText>
              <LegendDot threat={0} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 0 } })}
              </LegendText>
            </ThreatLevelLegend>
            <ConfigureIcon
              data-tip
              data-for="scaleButton"
              onClick={toggleScale}
            />
            <ButtonTooltip bottom id="scaleButton">
              <span>
                {t({ key: 'TOOLTIP_TOGGLE_CHART_SCALE' })}
              </span>
            </ButtonTooltip>
          </LegendWrapper>
          <AbsolutePosition>
            <BarChart
              width={width - VISUALIZATION_MARGIN}
              height={height}
              data={aggregationData}
              style={{
                marginRight: VISUALIZATION_MARGIN,
                backgroundColor: VISUALIZATION_BACKGROUND,
              }}
            >
              <XAxis
                dataKey="email"
                tickMargin={12}
                tick={
                  (
                    <CountTick
                      onLabelClick={
                        value => onCellClick(unionWith(eqBy(prop('name')),
                          [{ name: agg_field_name, term: value, type: 'keyword' }],
                          setFilters), null)
                      }
                    />
                  )
                }
                tickLine={{ stroke: VISUALIZATION_TICK_DARK, strokeWidth: 2.64 }}
                axisLine={false}
                hide={tiny}
              />
              <YAxis
                yAxisId="b"
                type="number"
                domain={domain}
                scale={scale}
                axisLine={false}
                tickLine={false}
                tickMargin={46}
                tick={<CountTick />}
                padding={{ top: 20, bottom: 4 }}
                hide={tiny}
                allowDataOverflow
              />
              <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
              <Bar
                stackId="a"
                yAxisId="b"
                dataKey="countSafe"
                isAnimationActive={animationsEnabled}
                minPointSize={minPointSize}
              >
                {aggregationData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`} // eslint-disable-line react/no-array-index-key
                    fill={CHART_SAFE}
                    onClick={() => onCellClick(unionWith(eqBy(prop('name')), [
                      { name: agg_field_name, term: entry.email, type: 'keyword' },
                      { name: 'threat_level', term: [0], type: 'byte' },
                    ], setFilters), null)}
                  />
                ))}
              </Bar>
              <Bar
                stackId="a"
                yAxisId="b"
                dataKey="countCaution"
                isAnimationActive={animationsEnabled}
                minPointSize={minPointSize}
              >
                {
                  aggregationData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`} // eslint-disable-line react/no-array-index-key
                      fill={CHART_CAUTION}
                      onClick={() => onCellClick(unionWith(eqBy(prop('name')), [
                        { name: agg_field_name, term: entry.email, type: 'keyword' },
                        { name: 'threat_level', term: [1], type: 'byte' },
                      ], setFilters), null)}
                    />
                  ))
                }
              </Bar>
              <Bar
                stackId="a"
                yAxisId="b"
                dataKey="countDanger"
                isAnimationActive={animationsEnabled}
                minPointSize={minPointSize}
              >
                {
                  aggregationData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`} // eslint-disable-line react/no-array-index-key
                      fill={CHART_DANGER}
                      onClick={() => onCellClick(unionWith(eqBy(prop('name')), [
                        { name: agg_field_name, term: entry.email, type: 'keyword' },
                        { name: 'threat_level', term: [2], type: 'byte' },
                      ], setFilters), null)}
                    />
                  ))
                }
              </Bar>
            </BarChart>
          </AbsolutePosition>
          <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        </Container>
      ))}
    </I18n>
  )
}

const EmailStackedBarChart = ({ dashboardFilters, ...rest }) => dashboardFilters && (
  <Suspense
    fallback={<Loader active />}
  >
    <AsyncEmailStackedBarChart dashboardFilters={dashboardFilters} {...rest} />
  </Suspense>
)

CountTick.propTypes = {
  x: number,
  y: number,
  payload: object,
  onLabelClick: func,
}

CustomTooltip.propTypes = {
  active: bool,
  label: string,
  payload: array,
}

AsyncEmailStackedBarChart.propTypes = {
  dashboardId: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  setFilterMode: string,
  setFilters: array,
  onCellClick: func,
  timestamp: number,
  downloadAll: bool,
  onFetch: func,
  belongsToUser: bool,
  shouldExport: bool,
  afterExport: func,
}

EmailStackedBarChartLayout.propTypes = {
  aggregationData: array,
  animationsEnabled: bool,
  onCellClick: func,
  setFilters: array,
  shouldExport: bool,
  afterExport: func,
}

export default EmailStackedBarChart
