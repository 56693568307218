import React from 'react'
import { InputAdornment, TextFieldProps } from '@material-ui/core'
import searchIcon from 'assets/inputs/search.png'
import { TextField, Icon } from './styles'

export const SearchBar = ({ ...rest }: TextFieldProps) => {
  return (
    <TextField
      size='small'
      variant='outlined'
      InputProps={{
        endAdornment: (
          <InputAdornment position={'start'}>
            <Icon src={searchIcon} />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}
