import styled from 'styled-components'
import {
  WHITE,
  DARK_TEXT,
  PRIMARY_DARK,
} from '../../../static/colors'

export const ContentWrapper = styled.div`
  flex: 1;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Title = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  min-width: 420px;
  text-align: left;
  max-width: 500px;
  margin-bottom: 16px;
`

export const Target = styled.div`
  color: ${PRIMARY_DARK};
  font-family: Lato;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
`

export const Link = styled.a`
  color: ${WHITE};
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${WHITE};
    text-decoration: none;
  }

  &:active {
    color: ${WHITE};
  }

  &:focus {
    outline: none;
  }
`
