import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import {
  LEGEND_TEXT,
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
} from '../../static/colors'
import { spacing } from 'static/theme'

const THREAT_COLORS = [CHART_SAFE, CHART_CAUTION, CHART_DANGER]

export const LegendWrapper = styled.div`
    position: absolute;
    bottom: -${spacing.xs};
    right: 0px;
    display: flex;
    flex-direction: row;
    z-index: 2;
`

export const ThreatLevelLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  margin-left: 8px;
`

export const LegendDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => THREAT_COLORS[props.threat]};
  margin-right: 4px;
`

export const LegendText = styled.div`
  font-family: Lato;
  font-size: 9px;
  font-weight: bold;
  color: ${LEGEND_TEXT};
  margin-right: 8px;

`

export const ConfigureIcon = styled(Icon).attrs({
  name: 'configure',
})`
  &:hover {
    cursor: pointer;
  }
`
