import React, { useState, useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import prop from 'ramda/src/prop'
import eqBy from 'ramda/src/eqBy'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import isEmpty from 'ramda/src/isEmpty'
import unionWith from 'ramda/src/unionWith'
import { Loader } from 'semantic-ui-react'
import { AuthContext } from '@logicea/react-auth'
import ReactResizeDetector from 'react-resize-detector'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import { createResource, createCacheWithAsyncInvalidation } from '../../../lib/simple-cache-utils'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/message'
import { noop } from '../../../lib/ramda'
import { I18n } from '../../../lib/i18n'
import THREAT_LEVELS from '../../../static/threatLevels'
import {
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
  VISUALIZATION_BACKGROUND,
} from '../../../static/colors'
import {
  TINY_VISUALIZATION_WIDTH_THRESHOLD,
  TINY_VISUALIZATION_HEIGHT_THRESHOLD,
} from '../../../static/appConfig'
import {
  Container,
  AbsolutePosition,
} from './styles'
import {
  TootlipContainer,
  TooltipLabel,
  TooltipValue,
} from '../tooltipStyles'
import {
  LegendWrapper,
  ThreatLevelLegend,
  LegendText,
  LegendDot,
} from '../legendStyles'
import { exportChart } from './export'
import { EmptyResults } from '../EmptyResults'

const colors = [CHART_SAFE, CHART_CAUTION, CHART_DANGER]

const { array, func, string, number, bool } = PropTypes

let threatLevelAggCache

const threatLevelAggCacheInvalidator = () => {
  threatLevelAggCache = createCacheWithAsyncInvalidation(threatLevelAggCacheInvalidator)
}
threatLevelAggCache = createCacheWithAsyncInvalidation(threatLevelAggCacheInvalidator)

const ThreatLevelAggResource = createResource(
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token }) =>
    actions.aggregateByThreatLevelAction(
      { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode },
      { realm, token },
    ),
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm }) =>
    JSON.stringify({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm })
)

const AsyncThreatLevelPie = ({
  dashboardId,
  dashboardFilters,
  dashboardFilterMode,
  setFilterMode,
  setFilters,
  onCellClick,
  timestamp,
  downloadAll,
  onFetch,
  belongsToUser,
  shouldExport,
  afterExport,
}) => {
  const [dashboard, setDashboard] = useState(null)

  const context = useContext(DashboardsContext)
  const {
    setViewModeTooltip,
  } = context

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  useEffect(() => {
    if (isNil(dashboard)) setDashboard(dashboardId)
    else setDashboard(null)
  }, [dashboardId])

  useEffect(() => {
    if (shouldExport && aggregationData) {
      exportChart(aggregationData)
      afterExport()
    }
  }, [shouldExport, afterExport, aggregationData])

  useEffect(() => {
    if (downloadAll) {
      const fetchedData = ThreatLevelAggResource
        .read(threatLevelAggCache, {
          dashboardFilters,
          setFilters,
          dashboardFilterMode,
          setFilterMode,
          timestamp,
          realm,
          token: tokens.accessToken,
        })
      const formatedData = fetchedData.data.map(d => (
        { threat_level: THREAT_LEVELS[d.threatLevel], count: d.value }))
      onFetch(formatedData)
    }
  }, [downloadAll, dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp])

  if (isNil(dashboard)) return null

  const data = ThreatLevelAggResource
    .read(threatLevelAggCache, {
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
      timestamp,
      realm,
      token: tokens.accessToken,
    })
  const aggregationData = data.data

  return (
    <ThreatLevelPieLayout
      aggregationData={aggregationData}
      onCellClick={belongsToUser ? onCellClick : () => setViewModeTooltip(true)}
      setFilters={setFilters}
    />
  )
}

const RADIAN = Math.PI / 180
const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6
  const x = cx + radius * Math.cos(-midAngle * RADIAN) - 10 * Math.sin(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontFamily="Lato"
      fontWeight="bold"
      fontSize={12}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const CustomTooltip = ({ payload, active }) => {
  const level = pathOr(null, [0, 'payload', 'threatLevel'], payload)
  const aggregationHits = pathOr(null, [0, 'payload', 'value'], payload)

  return active ? (
    <I18n>{({ t }) => (
      <TootlipContainer>
        <TooltipLabel>
          {t({ key: 'THREAT_LEVEL', variables: { level } })}
        </TooltipLabel>
        <TooltipValue>
          {t({
            key: 'AGGREGATION_HITS',
            variables: { aggregationHits },
          })}
        </TooltipValue>
      </TootlipContainer>
    )}
    </I18n>
  ) : null
}

const ThreatLevelPieLayout = ({
  aggregationData = [],
  onCellClick = noop,
  setFilters = [],
}) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const onResize = (w, h) => {
    setWidth(w)
    setHeight(h)
  }

  const tiny = width < TINY_VISUALIZATION_WIDTH_THRESHOLD || height < TINY_VISUALIZATION_HEIGHT_THRESHOLD

  return (
    <I18n>{({ t }) => (
      isEmpty(aggregationData) ? (
          <EmptyResults />
      ) : (
        <Container>
          <LegendWrapper>
            <ThreatLevelLegend>
              <LegendDot threat={2} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 2 } })}
              </LegendText>
              <LegendDot threat={1} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 1 } })}
              </LegendText>
              <LegendDot threat={0} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 0 } })}
              </LegendText>
            </ThreatLevelLegend>
          </LegendWrapper>
          <AbsolutePosition>
            <PieChart
              width={width}
              height={height}
              style={{
                backgroundColor: VISUALIZATION_BACKGROUND,
              }}
            >
              <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
              <Pie
                data={aggregationData}
                dataKey="value"
                innerRadius="0%"
                outerRadius="90%"
                // activeIndex={this.state.activeIndex}
                // activeShape={renderActiveShape}
                // onMouseEnter={this.onPieEnter}
                labelLine={false}
                label={!tiny && CustomLabel}
                isAnimationActive={false}
                paddingAngle={1}
              >
                {
                  aggregationData.map((entry, index) => (
                    <Cell
                      key={`slice-${index}`} // eslint-disable-line react/no-array-index-key
                      fill={colors[entry.threatLevel]}
                      onClick={() => onCellClick(unionWith(eqBy(prop('name')),
                        [{ name: 'threat_level', term: [entry.threatLevel], type: 'byte' }],
                        setFilters), null)}
                    />
                  ))
                }
              </Pie>
            </PieChart>
          </AbsolutePosition>
          <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        </Container>
      ))}
    </I18n>
  )
}

const ThreatLevelPie = ({ dashboardFilters, ...rest }) => dashboardFilters && (
  <Suspense
    fallback={<Loader active />}
  >
    <AsyncThreatLevelPie dashboardFilters={dashboardFilters} {...rest} />
  </Suspense>
)

CustomLabel.propTypes = {
  cx: number,
  cy: number,
  midAngle: number,
  innerRadius: number,
  outerRadius: number,
  percent: number,
  index: number,
}

CustomTooltip.propTypes = {
  active: bool,
  payload: array,
}

AsyncThreatLevelPie.propTypes = {
  dashboardId: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  setFilterMode: string,
  setFilters: array,
  onCellClick: func,
  timestamp: number,
  downloadAll: bool,
  onFetch: func,
  belongsToUser: bool,
  shouldExport: bool,
  afterExport: func,
}

ThreatLevelPieLayout.propTypes = {
  aggregationData: array,
  onCellClick: func,
  setFilters: array,
}

export default ThreatLevelPie
