import styled from 'styled-components'
import {
  colors,
  spacing,
  Body2DarkGreyRegular,
  Body1PrimaryMainRegular,
  Body1PrimaryMainBold,
} from 'static/theme'
import {
  SUGGESTION_BACKGROUND,
} from '../../static/colors'
import {ReactComponent as SeperatorSVG} from 'assets/filters/arrow-right.svg'
import { CustomScrollbar } from 'components/CustomScrollbar'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 4px;
  overflow: auto;
`
export const SelectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`

export const SelectionHeaderItem = styled.div`
  flex: 1;
  ${props => (props.right ? `margin-left: ${spacing.xxs};` : `margin-right: ${spacing.xxs};`)}
`

export const SelectionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  overflow: auto;
`
export const SelectionColumnWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.primaryLighter};
  padding: 6px;
  ${props => (props.right ? `margin-left: ${spacing.xxs};` : `margin-right: ${spacing.xxs};`)}
  padding-right: 20px;
`
export const SuggestionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Seperator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  align-self: center;
  justify-self: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${colors.white};
  border: 1px solid ${colors.primaryLighter};
`

export const SeperatorIcon = styled(SeperatorSVG)`
  fill: ${colors.primaryLight};
`

export const Scrollbar = styled(CustomScrollbar)`
`

export const Suggestion = styled(Body2DarkGreyRegular)`
  position: relative;
  width: 300px;
  max-width: 300px;
  min-height: 32px;
  margin: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${SUGGESTION_BACKGROUND};
  border-radius: 4px;
  ${props => (props.isSelected ? 'opacity: 0.4;' : '')}
  ${props => (props.closeable ? 'padding-right: 20px;' : '')}
  transition: opacity 0.3s;

  &:hover {
    cursor: ${props => (props.isSelected ? 'default' : 'pointer')};
  }
`

export const SuggestionWide = styled(Body2DarkGreyRegular)`
  position: relative;
  width: 500px;
  max-width: 500px;
  min-height: 32px;
  margin: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.isSelected ? SUGGESTION_BACKGROUND : SUGGESTION_BACKGROUND + '88')};
  border-radius: 4px;
  ${props => (props.closeable ? 'padding-right: 20px;' : '')}
  transition: background-color 0.3s;

  &:hover {
    cursor: pointer;
  }
`

export const SuggestionText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  margin-right: 4px;
`

export const SuggestionTextWide = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 10px;
  font-size: 16px;
`

export const AllResultsText = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  min-height: 32px;
  margin: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${SUGGESTION_BACKGROUND};
  border-radius: 4px;
  font-weight: bold;
`

export const StatisticsText = styled(Body1PrimaryMainRegular)`
  margin-left: 4px;
`

export const StatisticsTextBold = styled(Body1PrimaryMainBold)`
  display: flex;
`
