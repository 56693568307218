import React, { ChangeEvent, useCallback, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { Loader } from 'components/Loader'
import {
  FormControlLabel,
  TablePagination,
  IconButton,
} from '@material-ui/core'
import { I18n } from 'lib/i18n'
import { AuthContext } from '@logicea/react-auth'
import { getMarketplaceAction } from 'requests/marketplace'
import { DashboardsResponse } from 'requests/marketplace/types'
import { DashboardItem } from './DashboardItem'
import {
  Container,
  Dashboards,
  FilterBar,
  Filters,
  FilterLabel,
  Sort,
  Order,
  Label,
  Checkbox,
  PaginationTableWrapper,
  PaginationLabel,
  AscendingIcon,
  DescendingIcon,
  DropdownIcon,
  PrivateIcon,
  SharedIcon,
  PublicIcon,
} from './styles'
import { TopBar } from 'components/TopBar'
import { QuickAccess } from 'components/QuickAccess'
import { TablePaginationActions } from './TablePaginationActions'
import { DropdownSelect } from 'components/MaterialComponents/DropdownSelect'

type OwnerFilters = {
  default: boolean
  private: boolean
  team: boolean
}

const getOnwerFilter = (filters: OwnerFilters) => {
  let result: string[] = []
  if (filters.team) result.push('TEAM')
  if (filters.private) result.push('PRIVATE')
  if (filters.default) result.push('DEFAULT')
  return result.join(',')
}

const Marketplace = () => {
  const auth = useContext(AuthContext)
  const { realm, tokens, user }: any = auth
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [filters, setFilters] = useState({
    default: true,
    private: true,
    team: true,
  })
  const [sort, setSort] = useState('NAME')
  const [sortOrder, setSortOrder] = useState('ASC')

  const { data, isFetching, isPreviousData } = useQuery(
    [
      'getMarketplace',
      {
        realm,
        token: tokens?.accessToken,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        owner: getOnwerFilter(filters),
        sort,
        sortOrder,
      },
    ],
    getMarketplaceAction,
    { keepPreviousData: true, enabled: tokens }
  )

  const dashboards = data?.data ?? []

  const handleChangePage = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number
    ) => {
      setPage(newPage)
    },
    []
  )

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    []
  )

  const handleFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilters({
        ...filters,
        [event.target.name]: event.target.checked,
      })
      setPage(0)
    },
    [filters]
  )

  const handleSortOrderClick = useCallback(
    () => setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC'),
    [sortOrder]
  )

  const handleSortChange = useCallback(
    (
      event: React.ChangeEvent<{ name?: string; value: unknown }>,
      child: React.ReactNode
    ) => {
      const option = event.target.value as string
      if (option) setSort(option)
    },
    []
  )

  const isLoading = (isFetching && isPreviousData) || data === undefined

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <TopBar
            type='marketplace'
            title={t({ key: 'MARKETPLACE_TOP_BAR_TITLE' })}
            showTeamSelector={false}
          />
          <Dashboards>
            <QuickAccess />
            <FilterBar>
              <Filters>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.default}
                      onChange={handleFilterChange}
                      name='default'
                      color='secondary'
                    />
                  }
                  label={
                    <FilterLabel>
                      <PublicIcon />
                      {t({ key: 'MARKETPLACE_FILTER_PUBLIC_DASHBOARDS' })}
                    </FilterLabel>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.team}
                      onChange={handleFilterChange}
                      name='team'
                      color='secondary'
                    />
                  }
                  label={
                    <FilterLabel>
                      <SharedIcon />
                      {t({
                        key: 'MARKETPLACE_FILTER_SHARED_WITH_ME_DASHBOARDS',
                      })}
                    </FilterLabel>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.private}
                      onChange={handleFilterChange}
                      name='private'
                      color='secondary'
                    />
                  }
                  label={
                    <FilterLabel>
                      <PrivateIcon />
                      {t({ key: 'MARKETPLACE_FILTER_MY_DASHBOARDS' })}
                    </FilterLabel>
                  }
                />
              </Filters>
              <Sort>
                <Order>
                  <IconButton size='small' onClick={handleSortOrderClick}>
                    {sortOrder === 'DESC' ? (
                      <DescendingIcon />
                    ) : (
                      <AscendingIcon />
                    )}
                  </IconButton>
                </Order>
                <Label>{t({ key: 'MARKETPLACE_SORT_BY' })}</Label>
                <DropdownSelect
                  variant='standard'
                  value={sort}
                  options={[
                    {
                      label: t({ key: 'MARKETPLACE_SORT_BY_NAME' }),
                      value: 'NAME',
                    },
                    {
                      label: t({ key: 'MARKETPLACE_SORT_BY_CREATED_AT' }),
                      value: 'CREATED_AT',
                    },
                    {
                      label: t({ key: 'MARKETPLACE_SORT_BY_UPDATED_AT' }),
                      value: 'UPDATED_AT',
                    },
                    {
                      label: t({ key: 'MARKETPLACE_SORT_BY_OWNER' }),
                      value: 'OWNER',
                    },
                  ]}
                  onChange={handleSortChange}
                />
              </Sort>
            </FilterBar>
            {isLoading ? (
              <Loader active={isLoading} />
            ) : (
              <>
                {dashboards?.map((dashboard, index) => (
                  <DashboardItem
                    index={index}
                    item={dashboard}
                    key={dashboard.id}
                  />
                ))}
                <TablePagination
                  component={PaginationTableWrapper}
                  count={data?.total ?? dashboards?.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage={
                    <PaginationLabel>
                      {t({
                        key: 'MARKETPLACE_PAGINATION_ROWS_PER_PAGE',
                      })}
                    </PaginationLabel>
                  }
                  labelDisplayedRows={() => <></>}
                  SelectProps={{
                    IconComponent: DropdownIcon,
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </>
            )}
          </Dashboards>
        </Container>
      )}
    </I18n>
  )
}

export default Marketplace
