import React, { useContext } from 'react'
import * as P from 'prop-types'
import { AuthContext, authPropType } from './AuthProvider'
import { hasAllPermissions, hasAnyPermission } from '.'

const allow = (auth, permissions = [], any) => (any
  ? hasAnyPermission(permissions)(auth)
  : hasAllPermissions(permissions)(auth)
)

function useWithPermission(permissions, requireAny) {
  const auth = useContext(AuthContext)
  return [allow(auth, permissions, requireAny), auth]
}

function WithPermission(props) {
  const { permissions, requireAny, fallback, children } = props
  const [accessGranded, auth] = useWithPermission(permissions, requireAny)

  return typeof children === 'function'
    ? children(accessGranded, auth)
    : accessGranded
      ? children
      : fallback
}

WithPermission.propTypes = {
  auth: authPropType.isRequired,
  permissions: P.arrayOf(P.string).isRequired,
  requireAny: P.bool,
  fallback: P.node,
  children: P.oneOfType([P.func, P.node]),
}

const withPermission = (permissions, requireAny) => Component => {
  class WithPermissionHOC extends React.Component {
    render() {
      return (
        <WithPermission permissions={permissions} requireAny={requireAny}>
          <Component {...this.props} />
        </WithPermission>
      )
    }
  }
  return WithPermissionHOC
}

export { WithPermission, withPermission, useWithPermission }
