import { __ } from 'ramda'
import map from 'ramda/src/map'
import keys from 'ramda/src/keys'
import omit from 'ramda/src/omit'
import prop from 'ramda/src/prop'
import isNil from 'ramda/src/isNil'
import update from 'ramda/src/update'
import concat from 'ramda/src/concat'
import remove from 'ramda/src/remove'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'
import evolve from 'ramda/src/evolve'
import filter from 'ramda/src/filter'
import insert from 'ramda/src/insert'
import append from 'ramda/src/append'
import isEmpty from 'ramda/src/isEmpty'
import indexOf from 'ramda/src/indexOf'
import without from 'ramda/src/without'
import findIndex from 'ramda/src/findIndex'
import assocPath from 'ramda/src/assocPath'
import mergeRight from 'ramda/src/mergeRight'
import {
  ColumnT,
  DashboardT,
  FilterT,
  LayoutT,
  MessageSetT,
  VisualizationT,
} from './types'

export const addWidgets = (
  dashboard: DashboardT,
  setDashboard: (dashboard: DashboardT) => void
) => (widgets: Array<string>) => {
  const layout = dashboard.message_sets
  const prevLength = Object.keys(layout.sets || {}).length
  const setCounter = layout.set_counter || prevLength
  const newMessageSetsArray = widgets.map((w, i) => {
    const newSetId = `set-${setCounter + i + 1}`
    return {
      id: newSetId,
      content: '',
      filters: [],
      visualization: w,
    }
  })
  const newMessageSets = mergeRight(
    layout.sets || {},
    Object.assign(
      {},
      ...newMessageSetsArray.map((item) => ({ [item.id]: item }))
    )
  )

  let newLayout: LayoutT
  if (
    isEmpty(layout) ||
    isEmpty(layout.columnOrder) ||
    layout.columnOrder === undefined ||
    layout.set_counter === 0
  ) {
    const prevColumnLength = layout.columnOrder ? layout.columnOrder.length : 0
    const columnCounter = layout.column_counter || prevColumnLength
    const newColumnId = `column-${columnCounter + 1}`
    const newColumn: ColumnT = {
      id: newColumnId,
      setIds: newMessageSetsArray.map((item) => item.id),
      mode:
        layout.columnOrder && layout.columnOrder.length > 0 ? 'tabs' : 'tiles',
    }
    const newColumns = { [newColumnId]: newColumn }
    newLayout = {
      sets: newMessageSets,
      columns: newColumns,
      set_counter: setCounter + newMessageSetsArray.length,
      column_counter: columnCounter + 1,
      columnOrder: concat(layout.columnOrder || [], [newColumnId]),
    }
  } else {
    const newColumns = evolve(
      {
        [layout.columnOrder[0]]: {
          setIds: concat(
            __,
            newMessageSetsArray.map((item) => item.id)
          ),
        },
      },
      layout.columns || {}
    )
    newLayout = {
      sets: newMessageSets,
      columns: newColumns,
      set_counter: setCounter + newMessageSetsArray.length,
      column_counter: layout.column_counter,
      columnOrder: layout.columnOrder,
    }
  }
  setDashboard(mergeRight(dashboard, { message_sets: newLayout }))
}

export const addToNextLevel = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void,
  handleLayoutLevelChange: (level: number) => void,
  messageSetNumberOfColumns: number
) => (index: number, filters: Array<FilterT>, message: any) => {
  if (dashboard === null) return
  const layout = dashboard.message_sets
  const prevSetLength = Object.keys(layout.sets || {}).length
  const setCounter = layout.set_counter || prevSetLength
  const prevColumnLength = layout.columnOrder ? layout.columnOrder.length : 0
  const columnCounter = layout.column_counter || prevColumnLength
  const newSetId = `set-${setCounter + 1}`
  const visualization = 'list'
  const newMessageSet: MessageSetT = {
    filters,
    content: message ? `${message.threatLevel}${message.subject}` : '',
    id: newSetId,
    messageId: message ? { index: message.index, id: message.id } : null,
    visualization: message ? 'message' : visualization,
  }
  const newMessageSets = mergeRight(layout.sets || {}, {
    [newSetId]: newMessageSet,
  })

  if (
    index + 1 > prevColumnLength - 1 &&
    index + 1 < messageSetNumberOfColumns
  ) {
    const newColumnId = `column-${columnCounter + 1}`
    const newColumn: ColumnT = {
      id: newColumnId,
      setIds: [newSetId],
      mode:
        layout.columnOrder && layout.columnOrder.length > 0 ? 'tabs' : 'tiles',
    }
    const newColumns = mergeRight(layout.columns || {}, {
      [newColumnId]: newColumn,
    })
    const newLayout = {
      sets: newMessageSets,
      columns: newColumns,
      set_counter: setCounter + 1,
      column_counter: columnCounter + 1,
      columnOrder: concat(layout.columnOrder || [], [newColumnId]),
    }
    setDashboard(mergeRight(dashboard, { message_sets: newLayout }))
    handleLayoutLevelChange(layout.columnOrder ? layout.columnOrder.length : 0)
  } else if (layout.columnOrder !== undefined) {
    const columnIndex =
      index + 1 > messageSetNumberOfColumns - 1 ? index : index + 1
    const newColumns = evolve(
      {
        [layout.columnOrder[columnIndex]]: {
          setIds: append(newSetId) as <T>(list: readonly string[]) => string[],
        },
      },
      layout.columns || {}
    )
    const newLayout = {
      sets: newMessageSets,
      columns: newColumns,
      set_counter: setCounter + 1,
      column_counter: layout.column_counter,
      columnOrder: layout.columnOrder,
    }
    setDashboard(mergeRight(dashboard, { message_sets: newLayout }))
    handleLayoutLevelChange(columnIndex)
  }
}

export const handleVisualizationChange = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void
) => (visualization: VisualizationT, setId: string) => {
  if (dashboard !== null) {
    setDashboard(
      assocPath(
        ['message_sets', 'sets', setId, 'visualization'],
        visualization,
        dashboard
      )
    )
  }
}

export const handleSetLayoutChange = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void,
) => (layout: LayoutT) => {
  if (dashboard === null) return
  setDashboard(mergeRight(dashboard, { message_sets: layout }))
}

export const removeColumn = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void,
  handleLayoutLevelChange: (level: number) => void
) => (index: number) => {
  if (dashboard === null) return
  const layout = dashboard.message_sets
  if (layout.columnOrder === undefined || layout.columns === undefined) return
  const columnId = layout.columnOrder[index]
  const oldSetIds = layout.columns[columnId].setIds
  const newLayout = {
    sets: omit(oldSetIds, layout.sets),
    columns: omit([columnId], layout.columns),
    set_counter: layout.set_counter,
    column_counter: layout.column_counter,
    columnOrder: remove(index, 1, layout.columnOrder),
  }
  setDashboard(mergeRight(dashboard, { message_sets: newLayout }))
  handleLayoutLevelChange(index - 1 < 0 ? 0 : index - 1)
}

export const deleteMessageSet = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void,
  handleLayoutLevelChange: (level: number) => void,
  selectedSetId: string
) => (directSetId: string) => {
  if (dashboard === null) return
  const layout = dashboard.message_sets
  if (layout.columnOrder === undefined || layout.columns === undefined) return
  const setId = directSetId || selectedSetId
  const newMessageSets = omit([setId], layout.sets)
  const newColumns: Record<string, ColumnT> = map(
    evolve({ setIds: without([setId]) }),
    layout.columns
  )
  const emptyColumns: Record<string, ColumnT> = filter(
    (column) => isEmpty(prop('setIds', column)),
    newColumns
  )
  const newColumnOrder = without(keys(emptyColumns), layout.columnOrder)
  const newLayout = {
    sets: newMessageSets,
    columns: omit(keys(emptyColumns), newColumns),
    set_counter: layout.set_counter,
    column_counter: layout.column_counter,
    columnOrder: newColumnOrder,
  }
  setDashboard(mergeRight(dashboard, { message_sets: newLayout }))
  handleLayoutLevelChange(newColumnOrder.length - 1)
}

export const toggleColumnViewModeChange = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void
) => (columnIndex: number, mode: 'tiles' | 'tabs') => {
  if (dashboard === null) return
  const layout = dashboard.message_sets
  if (layout.columnOrder === undefined || layout.columns === undefined) return
  const columnId = layout.columnOrder[columnIndex]
  const newColumns = assocPath([columnId, 'mode'], mode, layout.columns)
  const newLayout = {
    sets: layout.sets,
    columns: newColumns,
    set_counter: layout.set_counter,
    column_counter: layout.column_counter,
    columnOrder: layout.columnOrder,
  }
  setDashboard(mergeRight(dashboard, { message_sets: newLayout }))
}

// FILTERS

const handleDashboardFilterEdit = (dashboard: DashboardT, filter: FilterT) => {
  const prevFilters = dashboard.filters ?? []
  const filterIndex = findIndex(propEq('name', filter.name), prevFilters)
  const newFilters =
    filterIndex > -1
      ? update(filterIndex, filter, prevFilters)
      : concat(prevFilters, [filter])

  return mergeRight(dashboard, { filters: newFilters })
}

const handleMessageSetFilterEdit = (
  dashboard: DashboardT,
  filter: FilterT,
  setId: string
) => {
  const prevFilters = pathOr(
    [],
    ['message_sets', 'sets', setId, 'filters'],
    dashboard
  )
  const filterIndex = findIndex(propEq('name', filter.name), prevFilters)
  const newFilters =
    filterIndex > -1
      ? update(filterIndex, filter, prevFilters)
      : concat(prevFilters, [filter])
  return assocPath(
    ['message_sets', 'sets', setId, 'filters'],
    newFilters,
    dashboard
  )
}

export const handleFilterEdit = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void
) => (filter: FilterT, setId?: string) => {
  if (dashboard === null) return
  if (isNil(setId)) setDashboard(handleDashboardFilterEdit(dashboard, filter))
  else setDashboard(handleMessageSetFilterEdit(dashboard, filter, setId))
}

const toggleDashboardFilterNegation = (
  dashboard: DashboardT,
  filter: FilterT
) => {
  return handleDashboardFilterEdit(
    dashboard,
    mergeRight(filter, { not: !filter.not })
  )
}

const toggleMessageSetFilterNegation = (
  dashboard: DashboardT,
  filter: FilterT,
  setId: string
) => {
  return handleMessageSetFilterEdit(
    dashboard,
    mergeRight(filter, { not: !filter.not }),
    setId
  )
}

export const toggleFilterNegation = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void
) => (filter: FilterT, setId?: string) => {
  if (dashboard === null) return
  if (isNil(setId))
    setDashboard(toggleDashboardFilterNegation(dashboard, filter))
  else setDashboard(toggleMessageSetFilterNegation(dashboard, filter, setId))
}

const handleDashboardFilterRemoval = (dashboard: DashboardT, index: number) => {
  const prevFilters: Array<FilterT> = propOr([], 'filters', dashboard)
  const newFilters = remove(index, 1, prevFilters)
  return assocPath(['filters'], newFilters, dashboard)
}

const handleMessageSetFilterRemoval = (
  dashboard: DashboardT,
  index: number,
  setId: string
) => {
  const prevFilters = pathOr(
    [],
    ['message_sets', 'sets', setId, 'filters'],
    dashboard
  )
  const newFilters = remove(index, 1, prevFilters)
  return assocPath(
    ['message_sets', 'sets', setId, 'filters'],
    newFilters,
    dashboard
  )
}

export const handleFilterRemoval = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void
) => (index: number, setId?: string) => {
  if (dashboard === null) return
  if (isNil(setId)) setDashboard(handleDashboardFilterRemoval(dashboard, index))
  else setDashboard(handleMessageSetFilterRemoval(dashboard, index, setId))
}

const handleMessageSetFilterModeChange = (
  dashboard: DashboardT,
  value: 'AND' | 'OR',
  setId: string
) => {
  return assocPath(
    ['message_sets', 'sets', setId, 'filterMode'],
    value,
    dashboard
  )
}

const handleDashboardFilterModeChange = (
  dashboard: DashboardT,
  value: 'AND' | 'OR'
) => {
  return assocPath(['filter_mode'], value, dashboard)
}

export const handleFilterModeChange = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void
) => (value: 'AND' | 'OR', setId?: string) => {
  if (dashboard === null) return
  if (setId)
    setDashboard(handleMessageSetFilterModeChange(dashboard, value, setId))
  else setDashboard(handleDashboardFilterModeChange(dashboard, value))
}

// ACTIONS

const cloneMessageSet = (
  dashboard: DashboardT,
  setId: string,
  name: string
) => {
  const layout = dashboard.message_sets
  if (layout.sets === undefined) return
  const prevLength = Object.keys(layout.sets).length
  const setCounter = layout.set_counter || prevLength
  const newSetId = `set-${setCounter + 1}`
  const newMessageSets = mergeRight(layout.sets, {
    [newSetId]: mergeRight(layout.sets[setId], {
      id: newSetId,
      content: name,
    }),
  })
  const addAfterOrigin = (ids: string[]) => {
    const i = indexOf(setId, ids)
    if (i > -1) return insert(i + 1, newSetId, ids)
    else return ids
  }
  const layoutColumns = layout.columns || {}
  const newColumns: Record<string, ColumnT> = map(
    evolve({ setIds: addAfterOrigin }),
    layoutColumns
  )
  const newLayout = {
    sets: newMessageSets,
    columns: newColumns,
    set_counter: setCounter + 1,
    column_counter: layout.column_counter,
    columnOrder: layout.columnOrder,
  }
  return mergeRight(dashboard, { message_sets: newLayout })
}

const renameMessageSet = (
  dashboard: DashboardT,
  setId: string,
  name: string
) => {
  const layout = dashboard.message_sets
  const newMessageSets = assocPath([setId, 'content'], name, layout.sets)
  const newLayout = {
    sets: newMessageSets,
    columns: layout.columns,
    set_counter: layout.set_counter,
    column_counter: layout.column_counter,
    columnOrder: layout.columnOrder,
  }
  return mergeRight(dashboard, { message_sets: newLayout })
}

export const handleMessageSetActionSubmit = (
  dashboard: DashboardT | null,
  setDashboard: (dashboard: DashboardT) => void
) => (action: 'clone' | 'rename', setId: string) => (name: string) => {
  if (dashboard === null) return
  switch (action) {
    case 'clone':
      setDashboard(cloneMessageSet(dashboard, setId, name) as DashboardT)
      break
    case 'rename':
      setDashboard(renameMessageSet(dashboard, setId, name) as DashboardT)
      break
    default:
      return
  }
}
