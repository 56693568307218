import styled from 'styled-components'
import { OutlinedInput } from '@material-ui/core'
import { spacing, colors } from 'static/theme'

import { ReactComponent as SearchSVG } from 'assets/filters/search.svg'
import { ReactComponent as ClearSVG } from 'assets/filters/clear.svg'

export const MaterialUIInput = styled(OutlinedInput)`
  && {
    margin-top: ${spacing.s};
    background-color: ${colors.white};
  }
`

export const SearchIcon = styled(SearchSVG)`
  fill: ${colors.primaryDark};
`

export const ClearIcon = styled(ClearSVG)`
  fill: ${colors.primaryDark};
  &:hover {
    cursor: pointer;
  }
`
