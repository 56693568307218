import React, { useEffect, useState, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'
import isNil from 'ramda/src/isNil'
import union from 'ramda/src/union'
import isEmpty from 'ramda/src/isEmpty'
import { AuthContext } from '@logicea/react-auth'
import { I18n } from '../../../lib/i18n'
import {ciWithout, Tooltip} from '../../../lib/util'
import actions from '../../../requests/message'
import listActions from '../../../requests/actions'
import ErrorBoundary from '../../ErrorBoundary'
import {
  getInkyMessageLink,
  getInkyReportLink,
  INKY_MESSAGE_DETAILS,
} from '../../../static/appConfig'
import Tabs from './Tabs'
import {
  Container,
  ActionBar,
  WarningIcon,
  ClearIcon,
  FlagIcon,
  AnalysisContainer,
  AnalysisLevelRow,
  AnalysisColumn,
  LevelDot,
  AnalysisLevel,
  AnalysisReasons,
  SenderDetails,
  DetailsLink,
  ConsoleLink,
  SuppressedLink,
  ErrorMessage,
  ActionBarSeparator,
  ActionBarLeftGroup,
  ReportEmailLink,
  ReportEmailLabel,
  ReportEmailRow,
} from './styles'
import TagEditor from "../../TagEditor";
import { createCache, createResource } from "simple-cache-provider";
import MessageActionBar from "../../MessageActionBar";
import {DashboardsContext} from "../../../providers/Dashboards";

const { string, func, bool, array, object } = PropTypes

const MessageActions = ({ handleActionClick, teamId, id, localTags, handleDeleteTag, handleTagSuggestionClick, isTakeNoAction, selectedMessages }) => {

  const auth = useContext(AuthContext)
  const isFlagged = localTags.includes('flag') || localTags.includes('FLAG')
  const isImportant = localTags.includes('important') || localTags.includes('IMPORTANT')

  const handleFlagClick = (tagName, addIt) => {
    if (addIt)
      handleTagSuggestionClick(tagName)
    else
      handleDeleteTag(tagName)
    handleActionClick([tagName], addIt ? 'add' : 'remove')
  }

  return (
      <I18n>{({ t }) => (
          <ActionBar>
            <ActionBarLeftGroup>
              <WarningIcon
                  data-tip
                  data-for="flagImportant"
                  onClick={() => handleFlagClick('important', !isImportant)}
                  isActive={isImportant}
              />
              <Tooltip bottom id="flagImportant">
                <span>{t({ key: isImportant ? 'TOOLTIP_UNFLAG_IMPORTANT' : 'TOOLTIP_FLAG_IMPORTANT' }) } </span>
              </Tooltip>
              <FlagIcon
                  data-tip
                  data-for="flag"
                  onClick={() => handleFlagClick('flag', !isFlagged)}
                  isFlagged={isFlagged}
              />
              <Tooltip bottom id="flag">
                <span>{t({ key: isFlagged ? 'TOOLTIP_UNFLAG' : 'TOOLTIP_FLAG' }) } </span>
              </Tooltip>

              <ActionBarSeparator>|</ActionBarSeparator>
              <MessageActionBar selectedMessages={selectedMessages} isTakeNoAction={isTakeNoAction} />
              <ActionBarSeparator style={{paddingRight:"2px"}}>|</ActionBarSeparator>
              <TagEditor tags={localTags} handleDeleteTag={handleDeleteTag} handleAddTag={handleTagSuggestionClick} />
            </ActionBarLeftGroup>
            <ConsoleLink href={getInkyMessageLink(teamId, id)} target="_blank">
              {t({ key: 'MESSAGE_VIEW_CONSOLE_LINK' })}
            </ConsoleLink>
          </ActionBar>
      )}
      </I18n>
  )
}

const renderReasonIds = reasonIds => reasonIds.map((reasonId, index) => (
  <I18n key={reasonId}>{({ t }) => (
    <div key={reasonId} style={{ marginTop: -2, marginRight: 4, marginBottom: 2 }}>
      {t({ key: 'REASONS', variables: { id: parseInt(reasonId, 10) } })}
      {index < reasonIds.length - 1 ? ', ' : ''}
    </div>
  )}
  </I18n>
))

const AnalysisBar = ({ threatLevel, internal, reasonIds, id, fromEmail }) => (
  <I18n>
    {({ t }) => (
      <AnalysisContainer threat={threatLevel}>
        <LevelDot threat={threatLevel} />
        <AnalysisColumn>
          <AnalysisLevelRow>
            <AnalysisLevel>
              {t({
                key: 'THREAT_LEVEL',
                variables: {
                  level: parseInt(threatLevel, 10),
                },
              })}
            </AnalysisLevel>
            <SenderDetails>
              ({t({ key: internal === 'true' || internal === true ? 'MESSAGE_INTERNAL' : 'MESSAGE_EXTERNAL' })}, {fromEmail})
            </SenderDetails>
          </AnalysisLevelRow>
          {threatLevel > 0 && (
            <AnalysisReasons>
              {renderReasonIds(reasonIds)}
              <DetailsLink href={INKY_MESSAGE_DETAILS + id} target="_blank">
                {t({ key: 'MESSAGE_VIEW_DETAILS_LINK' })}
              </DetailsLink>
            </AnalysisReasons>
          )}
        </AnalysisColumn>
      </AnalysisContainer>
    )}
  </I18n>
)

let messageCache
let actionListDetailsCache

messageCache = createCache(() => {})
actionListDetailsCache = createCache(() => {})

const MessageResource = createResource(
  ({ messageId, messageIndex, realm, token }) => actions.getMessageAction(messageId, messageIndex, realm, token),
  ({ messageId, messageIndex, realm }) => JSON.stringify({ messageId, messageIndex, realm })
)

const ActionListDetailsResource = createResource(
  ({ messageId, accessToken }) => listActions.getMessagesAllowBlockListEntriesAction({messages: [messageId]}, accessToken),
  ({ messageId }) => JSON.stringify({ messageId })
)


const AsyncMessage = ({
  messageId,
  messageIndex,
  taggedMessage,
  onMessageTag,
  toggleConfirmationModal,
}) => {

  const [selectedMessages, setSelectedMessages] = useState([])

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth
  let message = MessageResource.read(messageCache, { messageId, messageIndex, realm, token: tokens.accessToken })
  let actionListDetails = ActionListDetailsResource.read(actionListDetailsCache, { messageId, accessToken: tokens.accessToken })

  const context = useContext(DashboardsContext)
  const {
    msgActionedData,
    clearMsgActionedData,
  } = context

  const {
    suppressedReasonIds,
    threatLevel,
    fromEmail,
    reasonIds,
    internal,
    teamId,
    index,
    tags,
    id,
    userActions,
  } = message.data

  useEffect(() => {
    // Need to prevent message ID array from changing constantly on input into action bar
    if (!selectedMessages.length || selectedMessages[0].id !== id)
      setSelectedMessages([message.data])
  },[id])

  const [localTags, setLocalTags] = useState([])
  const [isTakeNoAction, setIsTakeNoAction] = useState(false)

  useEffect(() => {
    let noAction = false
    // Sort user actions by timestamp (latest last)
    let sortedUserActions = userActions ? userActions.sort((a,b) => (a.timestamp < b.timestamp) ? -1 : 1) : []
    sortedUserActions.forEach(action => {
      if (action.type === 'no-action')
        noAction = true
      else if (action.type === 'needs-action')
        noAction = false
    })
    setIsTakeNoAction(noAction)
  }, [userActions, messageId])

  useEffect(() => {
    msgActionedData.forEach(action => {
      if (action.messageId === id) {
        if (action.action === 'no-action') {
          setIsTakeNoAction(true)
        } else if (action.action === 'needs-action')
          setIsTakeNoAction(false)
        else if (action.action === 'allowlist-add' || action.action === 'blocklist-add' || action.action === 'policy-add') {
          if (!(id in actionListDetails))
            actionListDetails[id] = {
              allowlist_entries: [],
              blocklist_entries: [],
              policy_entries: []
            }
          let entries
          if (action.action === 'allowlist-add')
            entries = actionListDetails[id].allowlist_entries
          else if (action.action === 'blocklist-add')
            entries = actionListDetails[id].blocklist_entries
          else if (action.action === 'policy-add')
            entries = actionListDetails[id].policy_entries
          entries.push({text: action.details})
        }
        if (!message.data.userActions)
          message.data.userActions = []
        message.data.userActions.push({
          type: action.action,
          actionedBy: auth?.user?.email,
          timestamp: Date.now()/1000,
          details: action.details
        })
        message = {...message}
      }
    })
    clearMsgActionedData()
  }, [msgActionedData])

  useEffect(() => {

    if (tags) setLocalTags(tags)
    else setLocalTags([])
  }, [tags, id])

  useEffect(() => {
    if (isNil(taggedMessage)) return
    //messageCache.invalidateAt(100)
    if (taggedMessage.id === id && taggedMessage.index === index && taggedMessage.newTags) {
      setLocalTags(taggedMessage.newTags)
    }
  }, [taggedMessage])

  const handleActionClick = (tags, action) => {
    let newTags
    switch (action) {
      case 'add':
        newTags = union(localTags || [], tags)
        break
      case 'remove':
        newTags = ciWithout(tags, localTags || [])
        break
      default:
        newTags = []
    }

    setLocalTags(newTags)
    onMessageTag({ id, index, newTags })
    actions.updateMessageAction({ id, index, tags: newTags }, { realm, token: tokens.accessToken }).then(() => {
      MessageResource.reload(messageCache, { messageId, messageIndex, realm, token: tokens.accessToken })
    })
  }

  const handleAddTag = (newTag) => {

    if (!newTag)
      return

    let tagLower = newTag.toLowerCase()
    let tagExists = false
    localTags.forEach(tag => { if (tag.toLowerCase() === tagLower) tagExists = true })

    // Work around Tagify calling this method when tags are initialized
    if (!tagExists)
      handleActionClick([tagLower], 'add')
  }

  const handleDeleteTag = (oldTag) => {

    if (oldTag)
      handleActionClick([oldTag], 'remove')
  }

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <MessageActions
            id={id}
            teamId={teamId}
            handleActionClick={handleActionClick}
            localTags={localTags}
            handleDeleteTag={handleDeleteTag}
            handleTagSuggestionClick={handleAddTag}
            isTakeNoAction={isTakeNoAction}
            selectedMessages={selectedMessages}
          />
          <AnalysisBar
            id={id}
            internal={internal}
            reasonIds={reasonIds}
            fromEmail={fromEmail}
            threatLevel={threatLevel}
          />
          <ReportEmailRow>
            <ReportEmailLabel>
              {t({ key: 'MESSAGE_VIEW_REPORT_LINK' })}
            </ReportEmailLabel>
            <ReportEmailLink href={getInkyReportLink(id, auth.lastLoginEmail)} target="_blank">
              {t({ key: 'MESSAGE_VIEW_REPORT_THIS_EMAIL' })}
            </ReportEmailLink>
          </ReportEmailRow>
          {!isNil(suppressedReasonIds) && !isEmpty(suppressedReasonIds) ? (
            <SuppressedLink href={getInkyMessageLink(teamId, id)} target="_blank">
              {t({ key: 'MESSAGE_VIEW_SUPPRESSED_LINK' })}
            </SuppressedLink>
          ) : null}
          <Tabs
            message={message}
            actionListDetails={actionListDetails}
            toggleConfirmationModal={toggleConfirmationModal}
          />
        </Container>
      )}
    </I18n>
  )
}

const Message = ({
  messageId,
  messageIndex,
  taggedMessage,
  onMessageTag,
  toggleTagModal,
  toggleConfirmationModal,
}) => {

  return messageId && messageIndex ? (
    <I18n>
      {({t}) => (
        <Suspense
          fallback={<Loader active/>}
        >
          <ErrorBoundary
            key={messageId}
            fallback={(
              <ErrorMessage>
                {t({key: 'MESSAGE_VIEW_ERROR'})}
              </ErrorMessage>
            )}
          >
            <AsyncMessage
              messageId={messageId}
              onMessageTag={onMessageTag}
              taggedMessage={taggedMessage}
              toggleTagModal={toggleTagModal}
              messageIndex={messageIndex}
              toggleConfirmationModal={toggleConfirmationModal}
            />
          </ErrorBoundary>
        </Suspense>
      )}
    </I18n>
  ) : null
}

MessageActions.propTypes = {
  id: string,
  teamId: string,
  handleActionClick: func
}

AnalysisBar.propTypes = {
  id: string,
  internal: bool,
  reasonIds: array,
  fromEmail: string,
  threatLevel: string,
}

AsyncMessage.propTypes = {
  messageId: string,
  messageIndex: string,
  onMessageTag: func,
  toggleTagModal: func,
  toggleConfirmationModal: func,
}

Message.propTypes = {
  messageId: string,
  messageIndex: string,
  taggedMessage: object,
  onMessageTag: func,
  toggleTagModal: func,
  toggleConfirmationModal: func,
}

export default Message
