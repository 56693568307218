import styled from 'styled-components'
import { ThreatLevelCircle as AbstractThreatLevelCircle } from 'components/ReportComponents/ThreatLevelCircle'
import {
  colors,
  spacing,
  BodyXSPrimaryDarkRegular,
  Body1SecondaryMainRegular,
} from 'static/theme'
import { MAX_WIDGET_WIDTH, WidgetPadding } from 'components/ReportWidgets/styles'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 28rem;
  max-width: ${MAX_WIDGET_WIDTH};
  height: 21rem;
  border-radius: 3px;
  background-color: ${colors.white};
  margin-right: ${spacing.m};
  margin-bottom: ${spacing.m};
`

export const Padding = styled(WidgetPadding)`
  padding-right: ${spacing.xxs};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: ${spacing.xl};
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const SenderTitle = styled(BodyXSPrimaryDarkRegular)`
  flex: 1;
  margin-right: ${spacing.xs};
`

export const ThreatTitle = styled(BodyXSPrimaryDarkRegular)`
  width: 5rem;
  text-align: left;
`

export const Email = styled(Body1SecondaryMainRegular)`
  flex: 1;
  word-break: break-all;
`

export const ThreatLevelCircle = styled(AbstractThreatLevelCircle)``
