import styled from 'styled-components'
import {
  colors,
  spacing,
  BodyPrimaryLightRegular,
  BodyPrimaryLightRegular2,
  HeadlineH3PrimaryMainBlack,
} from 'static/theme'
import { MAX_WIDGET_WIDTH } from 'components/ReportWidgets/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 15rem;
  min-width: 28rem;
  max-width: ${MAX_WIDGET_WIDTH};
  border-radius: 4px;
  margin-right: ${spacing.m};
  margin-bottom: ${spacing.m};
  background-color: ${colors.secondaryBlue};
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${spacing.m};
`

export const Text = styled(BodyPrimaryLightRegular)``

export const Headline = styled(HeadlineH3PrimaryMainBlack)`
  color: ${colors.white};
  margin: ${spacing.m} 0;
`

export const Bucket = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spacing.xs2};
`

export const Bullet = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`

export const Label = styled(BodyPrimaryLightRegular2)`
  width: 4.5rem;
`

export const Value = styled(BodyPrimaryLightRegular2)`
  font-weight: bold;
`
