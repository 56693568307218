import React, {useContext, useState} from 'react'
import mergeRight from 'ramda/src/mergeRight'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import modalStyles, {
  SmallContainer,
  CloseButton,
  CloseIcon,
} from '../modalStyles'
import {
  ContentWrapper,
  Title,
  Button,
  InteriorContainer,
  ButtonsWrapper, NormalText, ActionText, Description
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/actions'
import {AuthContext} from "../../../@logicea/react-auth";
import {Dimmer, Icon, Loader, Message} from "semantic-ui-react";
import {maskUserIfNeeded, parseElasticsearchID, saveAction} from "../../../lib/util";
import {forEachObjIndexed} from "ramda";
import TagManager from "react-gtm-module";
import {GTM_KEY} from "../../../static/appConfig";


const RemediationModal = () => {
  const auth = useContext(AuthContext)
  const context = useContext(DashboardsContext)
  const {
    isRemediationModalOpen,
    setRemediationModalVisibility,
    remediationMessageData,
    addMsgActionedData,
    userTeams,
  } = context

  const showCloseButton = false
  const [working, setWorking] = useState(false)
  const [showActionStatus, setShowActionStatus] = useState(false)
  const [responseError, setResponseError] = useState()
  const [responseResult, setResponseResult] = useState()
  const [timeoutId, setTimeoutId] = useState(-1)
  const [confirming, setConfirming] = useState(false)

  const handleRemediationClick = async (confirm) => {

    setConfirming(confirm)
    setWorking(true)
    setShowActionStatus(false)
    setResponseError(null)
    setResponseResult("")

    let postActions = []
    forEachObjIndexed((actionObj, key) => {
      if (actionObj?.actions?.length) {
        // We only support one action currently
        postActions.push(actionObj.actions[0])
      }
    }, remediationMessageData.msgActions)

    let body = {actions: postActions}
    if (confirm)
      body.confirm = true
    let res = await actions.postMessageRemediationAction(body, auth.tokens.accessToken)

    TagManager.initialize({
      gtmId: GTM_KEY,
      dataLayer: {
        event: 'remediation_messages_deleted',
        message_count: postActions.length,
        email: userTeams?.data?.authEmail,
        teamid: userTeams?.data?.memberOf,
        license_key: userTeams?.data?.licenseKey,
        team_type: userTeams?.data?.teamType
      }
    })

    if (confirm) {
      let msgActioned = []
      remediationMessageData.messages.forEach(msg_id => {
        if (remediationMessageData?.msgActions[msg_id]?.actions?.length) {
          const action = remediationMessageData.msgActions[msg_id].actions[0]
          const toks = parseElasticsearchID(msg_id)
          saveAction("msg-remediated", msg_id, toks[0], auth, action.label)
          msgActioned.push({action: 'msg-remediated', messageId: msg_id, details: action.label})
        }
      })
      addMsgActionedData(msgActioned)
    }
    setWorking(false)
    setShowActionStatus(true)
    setResponseError(res?.error)

    if (!res?.error) {
      let totalSuccess = 0
      let errors = []
      forEachObjIndexed((msgResult, key) => {
        if (msgResult.error)
          errors.push(msgResult.error)
        else
          ++totalSuccess
      }, res)
      let responseStr = res?.result ?? "Unknown result returned"
      if (errors?.length) {
        responseStr += "<br>There were some errors deleting the following messages<br>"
        responseStr += errors.join("<br>")
      }
      setResponseResult(responseStr)
    }

    if (!res?.error && confirm) {
      setTimeoutId(setTimeout(() => {
        handleDismiss()
      }, 5000))
    }
  }

  const handleDismiss = () => {
    clearTimeout(timeoutId)
    setShowActionStatus(false)
    if (confirming && !responseError)
      setRemediationModalVisibility(false);
  }

  let previousAction = null
  let actionText = ""
  let description = ""
  let confirmButtonText = ""
  let disableConfirm = true
  let showLargeWarning = false

  if (remediationMessageData?.messages?.length === 1) {
    let msg_id = remediationMessageData.messages[0]
    let remData = remediationMessageData?.msgActions[msg_id]
    let action = remData?.actions[0]

    // Show latest historical entry
    if (remData?.history?.length)
      previousAction = remData.history[0]

    actionText = (action && action?.label) ?? "Loading..."
    description = action && action?.confirmation_description
    confirmButtonText = (action && action?.confirmation_button_text) ?? "Yes"
    disableConfirm = !(action || !action?.confirmation_button_text)
  } else if (remediationMessageData?.messages?.length > 1) {
    // All remediation messages should be the same, at least for now
    actionText = 'Delete ' + remediationMessageData.messages.length + ' Messages from INKY-Protected Mailboxes' //(action && action?.label) ?? "Loading..."
    confirmButtonText = 'Yes, Delete Messages'
    description = "This action will delete the selected messages from INKY-protected mailboxes (if the messages have not already been manually deleted). This action cannot be undone via INKY, but the messages may be recoverable via Outlook's Recover Deleted Items functionality."

    if (remediationMessageData.messages.length > 20)
      showLargeWarning = true
    disableConfirm = false
    previousAction = null
  }

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isRemediationModalOpen}
        contentLabel={t({ key: 'MODAL_CONFIRM_ACTION' })}
        style={{
          overlay: modalStyles.overlay,
          content: mergeRight(modalStyles.content, { padding: 20, paddingTop: 15 }),
        }}
      >
        <Dimmer active={working}>
          <Message icon>
            <Icon name='circle notched' loading />
            <Message.Content>
              <Message.Header>Please wait...</Message.Header>
              Processing your request
            </Message.Content>
          </Message>
        </Dimmer>
        <Dimmer active={showActionStatus}>
          {/*This work around is required due to a bug in Message not taking boolean vals for negative and positive attr*/}
          {responseError &&
            <Message negative onDismiss={handleDismiss}>
              <Message.Header>{"Error"}</Message.Header>
              <div dangerouslySetInnerHTML={{__html: responseError}}/>
            </Message>
          }
          {!responseError &&
            <Message positive onDismiss={handleDismiss}>
              <Message.Header>{"Success"}</Message.Header>
              <div dangerouslySetInnerHTML={{__html: responseResult}}/>
            </Message>
          }
        </Dimmer>
        <SmallContainer>
          {showCloseButton && (
            <CloseButton onClick={() => setRemediationModalVisibility(false)}>
              <CloseIcon />
            </CloseButton>
          )}
          <ContentWrapper>
            <Title>
              {t({ key: 'MODAL_CONFIRM_ACTION' })}
            </Title>
            <InteriorContainer>
              <NormalText>{t({key: 'REMEDIATION_ARE_YOU_SURE'})}</NormalText>
              <ActionText>{actionText}</ActionText>
              <Description>{description}</Description>
            </InteriorContainer>
            <Message info hidden={!previousAction}><b>{t({key: 'REMEDIATION_ALREADY_RUN', variables: {user: maskUserIfNeeded(previousAction?.auth_email), remediationName: previousAction?.action_type === 'delete_message' ? 'Delete Message' : previousAction?.action_type, date: previousAction?.completed_at}})}</b></Message>
            {showLargeWarning && <Message negative visible={showLargeWarning}>{t({key: 'REMEDIATION_LARGE_WARNING', variables: {count: remediationMessageData.messages.length}})}</Message>}
            <ButtonsWrapper>
              <Button
                dark
                type="button"
                onClick={() => handleRemediationClick(true)}
                disabled={disableConfirm}
              >
                {confirmButtonText}
              </Button>
              <Button type="button" onClick={() => handleRemediationClick(false)}>
                {t({ key: 'DRY_RUN_ONLY' })}
              </Button>
              <Button type="button" onClick={() => setRemediationModalVisibility(false)}>
                {t({ key: 'CANCEL' })}
              </Button>
            </ButtonsWrapper>
          </ContentWrapper>
        </SmallContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default RemediationModal
