import styled from 'styled-components'
import NumericInput from 'react-numeric-input'
import {
  DARK_TEXT,
} from '../../../static/colors'

export const FormContainer = styled.div`
  min-width: 640px;
  min-height: 460px;
  margin-top: 32px;
`

export const RangeOption = styled.div`
  color: blue;
  margin: 10px;

  &:hover {
    cursor: pointer;
  }
`
export const RelativeNowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props => (props.disabled ? 'opacity: 0.4;' : '')}
  ${props => (props.disabled ? 'pointer-events: none;' : '')}
`
export const RelativeNowRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
`
export const RelativeNowTitle = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  min-width: 70px;
  font-size: 14px;
  margin-right: 15px;
  margin-left: 5px;
`
export const RelativeNowText = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 14px;
  margin-right: 15px;
  margin-left: 5px;
`
export const NumberInput = styled(NumericInput)`
  max-width: 60px;
  height: 30px;
`

export const SpecificWrapper = styled.div`
  ${props => (props.disabled ? 'opacity: 0.4;' : '')}
  ${props => (props.disabled ? 'pointer-events: none;' : '')}
`

export const CalendarsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 10px;
`
export const SingleCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
`
export const Label = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 14px;
`

export const InlineQuickLinks = styled.div`
  display: flex;
  flex-direction: row;
`
