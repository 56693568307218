const initialDashboard = {
  user_id: 'user-1',
  group_id: 'group-1',
  show_owner: true,
  show_group: true,
  show_others: false,
  message_sets: {},
  permissions: {},
  filters: [],
  filter_mode: 'AND',
  configure_mode: true,
  layout_mode: 'horizontal',
}

export default initialDashboard
