import React from 'react'
import { I18n } from 'lib/i18n'
import { TickText } from './styles'

const DateTick = ({
  x,
  y,
  payload,
  lastIndex,
}: {
  x?: number
  y?: number
  payload?: { value: number; tickCoord: number; index: number }
  lastIndex: number
}) => {
  return (
    <I18n>
      {({ locale, t }) => (
        <g transform={`translate(${x},${y})`}>
          <TickText
            textAnchor='end'
            dx={payload?.index === lastIndex ? 37 : 15}
            dy={10}
          >
            {payload &&
              t({
                key: 'THREATS_OVER_TIME_CHART_DATE',
                variables: {
                  locale,
                  date: payload?.value * 1000,
                },
              })}
          </TickText>
        </g>
      )}
    </I18n>
  )
}

export { DateTick }
