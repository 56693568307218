import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { initialState } from '../i18n/store'
import { I18nProvider, loadTranslations } from '../i18n'

const { node, object } = PropTypes

class I18n extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    const { state = initialState } = this.props

    this.loadTranslations(state.locale)
  }

  async loadTranslations(locale) {
    const { state = initialState } = this.props

    let translations

    this.setState({
      ...state,
      locale,
      loading: true,
    })

    try {
      translations = await loadTranslations(locale)

      this.setState({
        ...state,
        locale,
        translations,
        loading: false,
        error: undefined,
      })
    } catch (error) {
      this.setState({
        ...state,
        locale,
        translations: undefined,
        loading: false,
        error,
      })
    }
  }

  render() {
    const {
      locale,
      translations,
      // loading,
      error } = this.state
    const { children } = this.props
    return (
      <React.Fragment>
        {process.env.NODE_ENV === 'development' ? (
          <div>
            {error !== undefined && (
            <span>{error.message}</span>
            )}
          </div>
        ) : null}
        {translations === undefined ? null : (
          <I18nProvider
            locale={locale}
            translations={translations}
          >
            {children}
          </I18nProvider>
        )}
      </React.Fragment>
    )
  }
}

I18n.propTypes = {
  children: node,
  state: object,
}

export default I18n
