import map from 'ramda/src/map'
import omit from 'ramda/src/omit'
import isNil from 'ramda/src/isNil'
import pickBy from 'ramda/src/pickBy'
import reject from 'ramda/src/reject'
import identity from 'ramda/src/identity'
import mergeRight from 'ramda/src/mergeRight'
import axios from 'axios'
import {
  toKotlinLayout,
  toKotlinFilter,
  fromKotlinFilter,
  fromKotlinLayout,
} from '../lib/transformations'
import { API_URL, KOTLIN_URL } from '../static/appConfig'
import evt_actions from './event'
import jwtDecode from "jwt-decode";

// XHR requests
const getDashboardsXhr = query => axios.get(`${KOTLIN_URL}/dashboards`, {
  params: { limit: 100 },
  headers: {
    Authorization: `Bearer ${query.token}`,
    'X-Team': query.realm,
  },
})

const logSessionXhr = query => axios.post(`${KOTLIN_URL}/users/activity`, {}, {
  headers: {
    Authorization: `Bearer ${query.token}`,
    'X-Team': query.realm,
  },
})

const getDefaultDashboardsXhr = query => axios.get(`${KOTLIN_URL}/dashboards/default`, {
  params: { limit: 100 },
  headers: {
    Authorization: `Bearer ${query.token}`,
    'X-Team': query.realm,
  },
})

const getReportDashboardsXhr = query => axios.get(`${KOTLIN_URL}/dashboards/reports`, {
  params: { limit: 20 },
  headers: {
    Authorization: `Bearer ${query.token}`,
    'X-Team': query.realm,
  },
})

const addDashboardXhr = body => axios.post(`${KOTLIN_URL}/dashboards/`, omit(['id', 'token'], body), {
  params: { },
  headers: {
    Authorization: `Bearer ${body.token}`,
    'Content-Type': 'application/json',
    'X-Team': body.realm,
  },
})

const updateDashboardXhr = body => axios.put(
  `${KOTLIN_URL}/dashboards/${body.id}`,
  omit(['id', 'token', 'realm'], body),
  {
    params: { },
    headers: {
      Authorization: `Bearer ${body.token}`,
      'Content-Type': 'application/json',
      'X-Team': body.realm,
    },
  }
)

const deleteDashboardXhr = (id, realm, token) => axios.delete(`${KOTLIN_URL}/dashboards/${id}`, {
  params: { },
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    'X-Team': realm,
  },
})

const replaceLayoutXhr = (dashboardId, body) =>
  axios.put(`${API_URL}/dashboard/${dashboardId}/replaceLayout`, body, { params: { } })

const updateMessageSetXhr = (dashboardId, setId, body) => axios.put(
  `${API_URL}/dashboard/${dashboardId}/updateMessageSet/${setId}`,
  body,
  { params: {}, }
)

const getMyDashboardsXhr = query => axios.get(
  `${API_URL}/dashboard/getMyDashboards`,
  { params: mergeRight({ page: 0 }, pickBy(identity, query)) },
)

const getTeamDashboardsXhr = query => axios.get(
  `${API_URL}/dashboard/getTeamDashboards`,
  { params: mergeRight({ page: 0 }, pickBy(identity, query)) },
)

// Helpers

export const fromKotlinDashboard = hit => ({
  id: hit.id,
  name: hit.name,
  description: hit.description,
  subTitle: hit.subTitle,
  relativeOwnership: hit.relativeOwnership,
  createdAt: hit.createdAt,
  updatedAt: hit.updatedAt,
  realm: hit.realm,
  user_id: hit.user,
  options: hit.options,
  filters: hit.filters ? hit.filters.map(fromKotlinFilter) : [],
  filter_mode: hit.filterMode,
  layout_mode: hit.layoutMode ? hit.layoutMode.toLowerCase() : null,
  permissions: hit.permissions,
  message_sets: hit.messageSets ? fromKotlinLayout(hit.messageSets) : {},
  configure_mode: !(hit.mode === 'DISPLAY'),
  pretty_link: hit.prettyLink ? hit.prettyLink : "",
  private_view: hit.privateView ? hit.privateView : false
})

const formatHitsToDashboards = hits => map(fromKotlinDashboard, hits)

export const toKotlinDashboard = body => ({
  id: body.id,
  name: body.name,
  description: body.description,
  subTitle: body.subTitle,
  relativeOwnership: body.relativeOwnership,
  createdAt: body.createdAt,
  updatedAt: body.updatedAt,
  realm: body.realm,
  token: body.token,
  user: body.user_id,
  options: body.options,
  filters: body.filters ? body.filters.map(f => reject(isNil, toKotlinFilter(f))) : null,
  filterMode: body.filter_mode,
  layoutMode: body.layout_mode ? body.layout_mode.toUpperCase() : null,
  permissions: body.permissions,
  messageSets: body.message_sets ? toKotlinLayout(body.message_sets) : null,
  mode: isNil(body.configure_mode) ? null // eslint-disable-line no-nested-ternary
    : (body.configure_mode ? 'CONFIGURE' : 'DISPLAY'),
  pretty_link: "",
  private_view: false
})

// Actions
const getDashboardsAction = async (query, teamId) => {
  try {
    const res = await getDashboardsXhr(query)
    logSessionXhr(query)
    return {
      total: res.data.total,
      elasticsearchTook: res.data.took,
      hits: formatHitsToDashboards(res.data.data),
    }
  } catch (err) {

    let email = ""
    let eventData = {
      'actions': ['get-dashboard'],
      'results': ['error'],
      'errors': [err.message]
    }
    if (err?.response?.statusText)
      eventData['errors'].push(err.response.statusText)
    try {
      let tok = jwtDecode(query.token)
      email = tok.email
    } catch (err2) {
    }

    evt_actions.registerAuthEventAction(email, teamId, eventData)
    return {
      total: 0,
      elasticsearchTook: 0,
      hits: [],
      unauthorized: true,
    }
  }
}

const getDefaultDashboardsAction = async query => {
  try {
    const res = await getDefaultDashboardsXhr(query)

    return {
      total: res.data.total,
      elasticsearchTook: res.data.took,
      hits: formatHitsToDashboards(res.data.data),
    }
  } catch (err) {
    return {
      total: 0,
      elasticsearchTook: 0,
      hits: [],
      unauthorized: true,
    }
  }
}

const getReportDashboardsAction = async query => {
  try {
    const res = await getReportDashboardsXhr(query)

    return {
      total: res.data.total,
      elasticsearchTook: res.data.took,
      hits: formatHitsToDashboards(res.data.data),
    }
  } catch (err) {
    return {
      total: 0,
      elasticsearchTook: 0,
      hits: [],
      unauthorized: true,
    }
  }
}

const addDashboardAction = async body => {
  const start = performance.now() // eslint-disable-line no-undef
  const res = await addDashboardXhr(reject(isNil, toKotlinDashboard(body)))
  const end = performance.now() // eslint-disable-line no-undef

  return {
    id: res.data.id,
    networkTook: end - start,
  }
}

const updateDashboardAction = async body => {
  const start = performance.now() // eslint-disable-line no-undef
  const res = await updateDashboardXhr(reject(isNil, toKotlinDashboard(body)))
  const end = performance.now() // eslint-disable-line no-undef

  return {
    res: res.data,
    networkTook: end - start,
  }
}

const deleteDashboardAction = async (id, realm, token) => {
  const start = performance.now() // eslint-disable-line no-undef
  const res = await deleteDashboardXhr(id, realm, token)
  const end = performance.now() // eslint-disable-line no-undef

  return {
    res: res.data,
    networkTook: end - start,
  }
}

const replaceLayoutAction = async (dashboardId, body) => {
  const start = performance.now() // eslint-disable-line no-undef
  const res = await replaceLayoutXhr(dashboardId, body)
  const end = performance.now() // eslint-disable-line no-undef

  return {
    res: res.data,
    networkTook: end - start,
  }
}

const updateMessageSetAction = async (dashboardId, setId, body) => {
  const start = performance.now() // eslint-disable-line no-undef
  const res = await updateMessageSetXhr(dashboardId, setId, body)
  const end = performance.now() // eslint-disable-line no-undef

  return {
    res: res.data,
    networkTook: end - start,
  }
}

const getMyDashboardsAction = async query => {
  const start = performance.now() // eslint-disable-line no-undef
  const res = await getMyDashboardsXhr(query)
  const end = performance.now() // eslint-disable-line no-undef

  return {
    total: res.data.total,
    networkTook: end - start,
    elasticsearchTook: res.data.took,
    hits: formatHitsToDashboards(res.data.hits),
  }
}

const getTeamDashboardsAction = async query => {
  const start = performance.now() // eslint-disable-line no-undef
  const res = await getTeamDashboardsXhr(query)
  const end = performance.now() // eslint-disable-line no-undef

  return {
    total: res.data.total,
    networkTook: end - start,
    elasticsearchTook: res.data.took,
    hits: formatHitsToDashboards(res.data.hits),
  }
}

const actions = {
  addDashboardAction,
  updateDashboardAction,
  deleteDashboardAction,
  replaceLayoutAction,
  updateMessageSetAction,
  getDashboardsAction,
  getMyDashboardsAction,
  getTeamDashboardsAction,
  getDefaultDashboardsAction,
  getReportDashboardsAction,
}

export default actions
