import React from 'react'
import { I18n } from 'lib/i18n'
import { TootlipContainer, TooltipLabel, TooltipValue } from './styles'

export const THREAT_LEVELS = ['Neutral', 'Caution', 'Danger']

export const LEVEL_NAMES = {
  countSafe: THREAT_LEVELS[0],
  countCaution: THREAT_LEVELS[1],
  countDanger: THREAT_LEVELS[2],
}

const CustomTooltip = ({
  payload,
  label,
  active,
}: {
  payload?: Array<{ name: keyof typeof LEVEL_NAMES; value: number }>
  label?: number
  active?: boolean
}) =>
  active ? (
    <I18n>
      {({ locale, t }) => (
        <TootlipContainer>
          {label ? (
            <TooltipLabel>
              {t({
                key: 'TOOLTIP_THREATS_OVER_TIME_CHART_DATE',
                variables: { locale, date: label * 1000 },
              })}
            </TooltipLabel>
          ) : null}
          {payload?.map((level) => (
            <TooltipValue key={level.name}>
              {t({
                key: 'TOOLTIP_THREATS_OVER_TIME_CHART_AGGREGATION_HITS',
                variables: {
                  levelName: level.name,
                  aggregationHits: level.value,
                },
              })}
            </TooltipValue>
          ))}
        </TootlipContainer>
      )}
    </I18n>
  ) : null

export { CustomTooltip }
