import styled from 'styled-components'
import { colors, spacing } from 'static/theme'
import { ReactComponent as FilterSVG } from 'assets/filters/filter.svg'
import { ReactComponent as ReadOnlyFilterSVG } from 'assets/filters/readOnlyfilter.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  margin-top: 4px;
`

export const FilterIcon = styled(FilterSVG)<{ isDashboardFilter: Boolean }>`
  min-width: ${({ isDashboardFilter }) =>
    isDashboardFilter ? '40px' : '24px'};
  min-height: ${({ isDashboardFilter }) =>
    isDashboardFilter ? '40px' : '24px'};
  fill: ${colors.primaryMain};
  &:hover {
    cursor: pointer;
  }
`

export const ReadOnlyFilterIcon = styled(ReadOnlyFilterSVG)<{
  isDashboardFilter: Boolean
}>`
  min-width: ${({ isDashboardFilter }) =>
    isDashboardFilter ? '40px' : '24px'};
  min-height: ${({ isDashboardFilter }) =>
    isDashboardFilter ? '40px' : '24px'};
  fill: ${colors.secondaryBlue};
`
