import React, { useContext } from 'react'
import { navigate, useLocation } from '@reach/router'
import { AuthContext } from '@logicea/react-auth'
import { Checkbox } from 'components/Checkbox'
import { Column, SubmenuItem, Text } from './styles'
import { DashboardsContext } from '../../../../providers/Dashboards'
import { I18n } from '../../../../lib/i18n'

export const Settings = () => {
  const location = useLocation()
  const context = useContext(DashboardsContext)
  const {
    setQuarantineSettingsModalVisibility,
    toggleDashboardPermissionsModal,
    toggleMessageSetSettingsModal,
    toggleUserPermissionsModal,
    setAutoRefresh,
    autoRefresh,
    autoSave,
    toggleAutoSave,
    userPermissions,
  } = context as any
  const auth = useContext(AuthContext) as any

  const toggleAutoRefresh = () => setAutoRefresh(!autoRefresh)

  let quarantineEnabled =
    userPermissions &&
    userPermissions.hasOwnProperty('inky') &&
    userPermissions['inky']['permissions'].hasOwnProperty(
      'quarantine_config'
    ) &&
    userPermissions['inky']['permissions']['quarantine_config'].includes(
      'modify'
    )

  const showVipList = () => {
    navigate('/settings/viplist')
  }

  const showAllowList = () => {
    navigate('/settings/allowlist')
  }

  const showBlockList = () => {
    navigate('/settings/blocklist')
  }

  return (
    <I18n>
      {({ t }) => (
        <Column>
          <SubmenuItem onClick={toggleAutoRefresh}>
            <Checkbox
              checked={autoRefresh}
              style={{ position: 'absolute', left: 0 }}
            />
            <Text>{t({ key: 'SIDEBAR_AUTO_REFRESH' })}</Text>
          </SubmenuItem>
          <SubmenuItem onClick={() => toggleAutoSave(!autoSave)}>
            <Checkbox
              checked={autoSave}
              style={{ position: 'absolute', left: 0 }}
            />
            <Text>{t({ key: 'SIDEBAR_AUTO_SAVE' })}</Text>
          </SubmenuItem>
          {quarantineEnabled && (
            <SubmenuItem
              onClick={() => setQuarantineSettingsModalVisibility(true)}
            >
              <Text selected={location.pathname.includes('/settings')}>
                {t({ key: 'SIDEBAR_QUARANTINE' })}
              </Text>
            </SubmenuItem>
          )}
          <SubmenuItem onClick={showAllowList}>
            <Text
              selected={location.pathname.includes('/settings/allowlist')}
            >
              {t({ key: 'SIDEBAR_ALLOWLIST' })}
            </Text>
          </SubmenuItem>
          <SubmenuItem onClick={showBlockList}>
            <Text
              selected={location.pathname.includes('/settings/blocklist')}
            >
              {t({ key: 'SIDEBAR_BLOCKLIST' })}
            </Text>
          </SubmenuItem>
          {false && (
            <SubmenuItem onClick={showVipList}>
              <Text selected={location.pathname.includes('/settings/viplist')}>
                {t({ key: 'SIDEBAR_VIP_LIST' })}
              </Text>
            </SubmenuItem>
          )}
          {false && (
            <React.Fragment>
              <SubmenuItem onClick={toggleDashboardPermissionsModal}>
                <Text>{t({ key: 'SIDEBAR_DASHBOARD_PERMISSIONS' })}</Text>
              </SubmenuItem>
              <SubmenuItem onClick={toggleMessageSetSettingsModal}>
                <Text>{t({ key: 'SIDEBAR_MESSAGE_SET_SETTINGS' })}</Text>
              </SubmenuItem>
              <SubmenuItem>
                <Text>{t({ key: 'SIDEBAR_COLUMN_SET_EDITOR' })}</Text>
              </SubmenuItem>
              <SubmenuItem onClick={toggleUserPermissionsModal}>
                <Text>{t({ key: 'SIDEBAR_USER_PERMISSIONS' })}</Text>
              </SubmenuItem>
            </React.Fragment>
          )}
        </Column>
      )}
    </I18n>
  )
}
