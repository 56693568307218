import React from 'react'
import { noop } from 'lib/ramda'
import { ToggleSwitch } from 'components/ToggleSwitch'
import { Tooltip } from 'lib/util'
import { I18n } from 'lib/i18n'
import { FilterItem } from './components/FilterItem'
import { FilterT } from 'components/App/Dashboard/types'
import { Container, FilterIcon, ReadOnlyFilterIcon } from './styles'

type FilterBarT = {
  setId?: string
  filters: Array<FilterT>
  isReport: boolean
  filterMode: 'AND' | 'OR'
  belongsToUser: boolean
  isEditor?: boolean
  handleFilterClick: (f: FilterT | null, setId?: string) => void
  handleFilterRemoval: (index: number, setId?: string) => void
  toggleFilterNegation: (f: FilterT, setId?: string) => void
  handleFilterModeChange: (
    filterMode: FilterBarT['filterMode'],
    setId?: string
  ) => void
}
export const FilterBar = ({
  setId,
  filters,
  isReport,
  filterMode,
  belongsToUser,
  isEditor = false,
  handleFilterClick,
  handleFilterRemoval,
  toggleFilterNegation,
  handleFilterModeChange,
}: FilterBarT) => {
  const showRemoveAndNegateFilterButton = belongsToUser && !isReport
  const isEditable = belongsToUser && !isEditor && !isReport
  const isDashboardFilter = setId === undefined

  return (
    <I18n>
      {({ t }) => (
        <Container>
          {!isReport && belongsToUser ? (
            <FilterIcon
              data-tip
              data-for='addFilterButton'
              isDashboardFilter={isDashboardFilter}
              onClick={(event) => {
                event.persist()
                handleFilterClick(null, setId)
              }}
            />
          ) : (
            filters?.length > 0 && (
              <ReadOnlyFilterIcon isDashboardFilter={isDashboardFilter} />
            )
          )}
          <Tooltip bottom id='addFilterButton' delayShow={undefined}>
            <span>{t({ key: 'TOOLTIP_ADD_FILTER' })}</span>
          </Tooltip>
          {filters && filters.length > 1 && (
            <ToggleSwitch
              checked={filterMode === 'OR'}
              onClick={() =>
                filterMode === 'AND'
                  ? handleFilterModeChange('OR', setId)
                  : handleFilterModeChange('AND', setId)
              }
              falseLabel={t({ key: 'AND' })}
              trueLabel={t({ key: 'OR' })}
            />
          )}
          {filters &&
            filters.map((f: FilterT, index: number) => (
              <FilterItem
                key={`${f.name}-${index}`}
                filter={f}
                isEditable={isEditable}
                showRemoveAndNegateFilterButton={
                  showRemoveAndNegateFilterButton
                }
                onClick={() =>
                  isEditable ? handleFilterClick(f, setId) : noop
                }
                onToogleFilter={() => toggleFilterNegation(f, setId)}
                onRemoveFilter={() => handleFilterRemoval(index, setId)}
              />
            ))}
        </Container>
      )}
    </I18n>
  )
}
