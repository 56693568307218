import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { customTheme } from './customTheme'

type StylesConfigProps = {
  children: React.ReactNode
}

export function Theme(props: StylesConfigProps) {
  const { children } = props
  return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>
}
