import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import {
  BACKGROUND_DARK,
  LAYOUT_SEPARRATOR,
  HEADER_BACKGROUND,
} from '../../static/colors'

export const Box = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  padding-bottom: 16px;
  min-width: 360px;
  min-height: 360px;
`

export const BoxLarge = styled.div`
  max-width: 100%;
  flex: 2;
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding-bottom: 16px;
`

export const Horizontal = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const HorizontalBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  padding-bottom: 16px;
  min-width: 360px;
  min-height: 360px;
  padding-top: 10px;
`

export const Vertical = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`

export const Pi = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

export const HorizontalMargin = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 10px;
`

export const HorizontalSeparatorGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 0px 16px;
`

export const VerticalSeparatorGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0px 16px 0px;
`

export const RowSeparator = styled.div`
  width: 100%;
  height: 1px;
  margin: 1px;
  background-color: ${LAYOUT_SEPARRATOR};
`

export const ColumnSeparator = styled.div`
  height: 100%;
  width: 1px;
  margin: 1px;
  background-color: ${LAYOUT_SEPARRATOR};
`

export const Epsilon = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`

export const EpsilonColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 50%;
`

export const BackButton = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${HEADER_BACKGROUND};
  border: 2px solid ${BACKGROUND_DARK};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  margin-top: 20px;
  margin-left: 16px;
  position: absolute;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`

export const BackIcon = styled(Icon).attrs({
  name: 'chevron left',
  color: 'black',
  size: 'large',
  inverted: true,
})``
