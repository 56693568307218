import axios from 'axios'
import omit from 'ramda/src/omit'
import isNil from 'ramda/src/isNil'
import reject from 'ramda/src/reject'
import { KOTLIN_URL } from '../../static/appConfig'
import { fromKotlinDashboard, toKotlinDashboard } from 'requests/dashboard'
import { DashboardPayload, DashboardResponse } from './types'

// XHR //
const getDashboardXhr = (payload: DashboardPayload) => {
  return axios.get<DashboardResponse>(
    `${KOTLIN_URL}/dashboards/${payload.dashboardId}`,
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        'X-Team': payload.realm,
      },
    }
  )
}

const updateDashboardXhr = (body: any) =>
  axios.put(
    `${KOTLIN_URL}/dashboards/${body.id}`,
    omit(['id', 'token', 'realm'], body),
    {
      params: {},
      headers: {
        Authorization: `Bearer ${body.token}`,
        'Content-Type': 'application/json',
        'X-Team': body.realm,
      },
    }
  )

// Actions //
export const getDashboardAction = async (
  _key: string,
  payload: DashboardPayload
) => {
  const res = await getDashboardXhr(payload)
  return fromKotlinDashboard(res.data)
}

export const updateDashboardAction = async (payload: any) => {
  const res = await updateDashboardXhr(
    reject(isNil, toKotlinDashboard(payload))
  )
  return res
}
