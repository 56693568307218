import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import {
  DARK_TEXT,
  PRIMARY_DARK,
  BUTTON_BORDER,
  BACKGROUND_MEDIUM
} from '../../../static/colors'

export const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-y: auto;
`

export const Sidebar = styled.div`
  flex: 1;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  padding: 40px 20px;
  background-color: ${BACKGROUND_MEDIUM};
`

export const SidebarItem = styled.div`
  color: ${props => (props.selected ? PRIMARY_DARK : DARK_TEXT)};
  font-family: Lato;
  font-size: 16px;
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  margin: 10px;
  text-align: left;

  ${props =>
    props.selected
      ? ''
      : `
    &:hover {
      cursor: pointer;
    }
  `}
`

export const ScreenItem = styled.div``

export const Screen = styled.div`
  flex: 3;
  height: 100%;
  max-height: 100%;
  display: flex;
  padding: 20px;
  padding-left: 30px;
  flex-direction: column;
  justify-content: space-between;
`

export const ScreenContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`

export const Title = styled.div`
  color: ${PRIMARY_DARK};
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  min-width: 420px;
  text-align: left;
  max-width: 500px;
  margin-bottom: 16px;
`

export const TextWrapper = styled.div`
  justify-content: flex-start;
`

export const Text = styled.div`
  color: ${DARK_TEXT};
  font-family: Lato;
  font-size: 17px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 10px;
`

export const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  position: relative;
`

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const NextButton = styled(Icon).attrs({
  name: 'chevron right',
  size: 'large'
})`
  height: 36px !important;
  width: 36px !important;
  border-radius: 18px;
  border: 1px solid ${BUTTON_BORDER};
  padding: 7px 0px 0px 2px;
  ${props => (props.active ? 'cursor: pointer;' : '')}
  color: ${props => (props.active ? PRIMARY_DARK : BUTTON_BORDER)};
`

export const BackButton = styled(Icon).attrs({
  name: 'chevron left',
  size: 'large'
})`
  height: 36px !important;
  width: 36px !important;
  border-radius: 18px;
  border: 1px solid ${BUTTON_BORDER};
  padding: 7px 2px 0px 0px;
  ${props => (props.active ? 'cursor: pointer;' : '')}
  color: ${props => (props.active ? PRIMARY_DARK : BUTTON_BORDER)};
`

export const CreateButton = styled.div`
  height: 32px;
  width: 200px;
  border-radius: 5px;
  background-color: rgba(216,221,230,0.71);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CreateButtonIcon = styled.div`
  color: ${PRIMARY_DARK};
  font-family: Lato;
  font-size: 17px;
  font-weight: bold;
  line-height: 17px;
  margin-right: 5px;
`

export const CreateButtonText = styled.div`
  color: ${PRIMARY_DARK};
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.17px;
  line-height: 17px;
`

export const Graphics = styled.img`
  width: 100%;
  margin-top: 10px;
  outline: none;
`

export const Margin = styled.div`
  height: 20px;
  width: 100%;
`
