import React from 'react'
import styled from 'styled-components'
import { RouteComponentProps, Router as ReachRouter } from '@reach/router'
import AllowBlockList from './AllowBlockList'
import VipList from './VipList'

export const Router = styled(ReachRouter)`
  display: flex;
  flex: 1;
`

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent

const Settings = () => {
  return (
    <Router>
      <RouterPage pageComponent={<VipList />} path='viplist' />
      <RouterPage pageComponent={<AllowBlockList />} path='blocklist' />
      <RouterPage pageComponent={<AllowBlockList />} path='allowlist' />
    </Router>
  )
}

export default Settings
