import React, { ReactNode } from 'react'
import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars'
import { colors } from 'static/theme'

interface CustomScrollbarProps extends ScrollbarProps {
  children?: ReactNode
}

export const CustomScrollbar = ({
  children,
  ...rest
}: CustomScrollbarProps) => {
  const renderThumb = ({
    style,
    ...props
  }: {
    style: CustomScrollbarProps['style']
  }) => {
    const thumbStyle = {
      backgroundColor: colors.primaryLight,
      borderRadius: '3px',
    }
    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }
  return (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...rest}
    >
      {children}
    </Scrollbars>
  )
}
