import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { EXPORT_WIDTH, EXPORT_HEIGHT } from './styles'

export const exportToPDF = () => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
  })

  const page1 = document.getElementById('divToPrint1')
  if (page1) {
    html2canvas(page1, { width: EXPORT_WIDTH, height: EXPORT_HEIGHT }).then(
      (canvas) => {
        const imgData = canvas.toDataURL('image/jpeg')
        pdf.addImage(
          imgData,
          'JPEG',
          0,
          0,
          EXPORT_WIDTH * 0.565,
          EXPORT_HEIGHT * 0.565
        )
        pdf.addPage('a4', 'portrait')
        const page2 = document.getElementById('divToPrint2')
        if (page2) {
          html2canvas(page2, {
            width: EXPORT_WIDTH,
            height: EXPORT_HEIGHT,
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg')
            pdf.addImage(
              imgData,
              'JPEG',
              0,
              0,
              EXPORT_WIDTH * 0.565,
              EXPORT_HEIGHT * 0.565
            )
            pdf.save('overview.pdf')
          })
        }
      }
    )
  }
}
