import React, { useState, useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import prop from 'ramda/src/prop'
import eqBy from 'ramda/src/eqBy'
import isNil from 'ramda/src/isNil'
import isEmpty from 'ramda/src/isEmpty'
import unionWith from 'ramda/src/unionWith'
import { Loader, Icon } from 'semantic-ui-react'
import { AuthContext } from '@logicea/react-auth'
import ReactResizeDetector from 'react-resize-detector'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Brush,
  ReferenceArea,
} from 'recharts'
import { createResource, createCacheWithAsyncInvalidation } from '../../../lib/simple-cache-utils'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/message'
import { parseDate } from '../../../lib/util'
import { noop } from '../../../lib/ramda'
import { I18n } from '../../../lib/i18n'
import {
  DARK_GREY,
  CHART_SAFE,
  CHART_CAUTION,
  CHART_DANGER,
  VISUALIZATION_TICK_DARK,
  VISUALIZATION_BACKGROUND,
} from '../../../static/colors'
import {
  TINY_VISUALIZATION_WIDTH_THRESHOLD,
  TINY_VISUALIZATION_HEIGHT_THRESHOLD,
  VISUALIZATION_MARGIN,
} from '../../../static/appConfig'
import {
  Container,
  ZoomButton,
  AbsolutePosition,
  TickText,
} from './styles'
import {
  TootlipContainer,
  TooltipLabel,
  TooltipValue,
} from '../tooltipStyles'
import {
  LegendWrapper,
  ThreatLevelLegend,
  LegendText,
  LegendDot,
} from '../legendStyles'
import THREAT_LEVELS from '../../../static/threatLevels'
import { exportChart } from './export'
import { EmptyResults } from '../EmptyResults'

const { number, array, bool, func, object, string } = PropTypes

let dateAggCache

const dateAggCacheInvalidator = () => {
  dateAggCache = createCacheWithAsyncInvalidation(dateAggCacheInvalidator)
}
dateAggCache = createCacheWithAsyncInvalidation(dateAggCacheInvalidator)

const DateAggResource = createResource(
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, realm, token }) =>
    actions.aggregateByDateAction(
      { dashboardFilters, setFilters, dashboardFilterMode, setFilterMode },
      { realm, token }
    ),
  ({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm }) =>
    JSON.stringify({ dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp, realm })
)

const AsyncDateAreaGraph = ({
  dashboardId,
  dashboardFilters,
  dashboardFilterMode,
  setFilterMode,
  setFilters,
  onCellClick,
  showBrush,
  timestamp,
  downloadAll,
  onFetch,
  belongsToUser,
  shouldExport,
  afterExport,
}) => {
  const [dashboard, setDashboard] = useState(null)

  const context = useContext(DashboardsContext)
  const {
    setViewModeTooltip,
  } = context

  const auth = useContext(AuthContext)
  const { realm, tokens } = auth

  useEffect(() => {
    if (isNil(dashboard)) setDashboard(dashboardId)
    else setDashboard(null)
  }, [dashboardId])

  useEffect(() => {
    if (shouldExport && aggregationData) {
      exportChart(aggregationData)
      afterExport()
    }
  }, [shouldExport, afterExport, aggregationData])

  useEffect(() => {
    if (downloadAll) {
      const fetchedData = DateAggResource
        .read(dateAggCache, {
          dashboardFilters,
          setFilters,
          dashboardFilterMode,
          setFilterMode,
          timestamp,
          realm,
          token: tokens.accessToken,
        })
      const formatedData = fetchedData.data.map(d => ({
        date: new Date(d.date * 1000).toLocaleDateString(),
        total: d.count,
        neutral: d.countSafe,
        caution: d.countCaution,
        danger: d.countDanger,
      }))
      onFetch(formatedData)
    }
  }, [downloadAll, dashboardFilters, setFilters, dashboardFilterMode, setFilterMode, timestamp])


  if (isNil(dashboard)) return null

  const data = DateAggResource
    .read(dateAggCache, {
      dashboardFilters,
      setFilters,
      dashboardFilterMode,
      setFilterMode,
      timestamp,
      realm,
      token: tokens.accessToken,
    })
  const aggregationData = data.data
  const { networkTook, elasticsearchTook } = data

  return (
    <DateAreaGraphLayout
      elasticsearchTook={elasticsearchTook}
      networkTook={networkTook}
      aggregationData={aggregationData}
      onCellClick={belongsToUser ? onCellClick : () => setViewModeTooltip(true)}
      setFilters={setFilters}
      showBrush={showBrush}
      animationsEnabled
    />
  )
}

const DateTick = ({ x, y, payload, onLabelClick }) => (
  <I18n>{({ locale, t }) => (
    <g transform={`translate(${x},${y})`}>
      <TickText
        x={0}
        y={0}
        dx={30}
        dy={16}
        textAnchor="end"
        fill={DARK_GREY}
        isClickable={!!onLabelClick}
        onClick={() => {
          if (onLabelClick) onLabelClick(payload.value)
        }}
      >
        {t({ key: 'CHART_DATE', variables: { locale, date: new Date(parseDate(payload.value)) } })}
      </TickText>
    </g>
  )}
  </I18n>
)

const CountTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <TickText
      x={0}
      y={4}
      textAnchor="end"
      fill={DARK_GREY}
    >
      {payload.value}
    </TickText>
  </g>
)

const LEVEL_NAMES = {
  countSafe: THREAT_LEVELS[0],
  countCaution: THREAT_LEVELS[1],
  countDanger: THREAT_LEVELS[2],
}

const CustomTooltip = ({ payload, label, active }) => (
  active ? (
    <I18n>{({ locale, t }) => (
      <TootlipContainer>
        <TooltipLabel>
          {t({ key: 'CHART_DATE', variables: { locale, date: new Date(parseDate(label)) } })}
        </TooltipLabel>
        {payload.map(level => (
          <TooltipValue key={level.name}>
            {LEVEL_NAMES[level.name]}:
            {t({
              key: 'AGGREGATION_HITS',
              variables: { aggregationHits: level.value },
            })}
          </TooltipValue>
        ))}
      </TootlipContainer>
    )}
    </I18n>
  ) : null
)

const DateAreaGraphLayout = ({
  aggregationData = [],
  animationsEnabled = false,
  showBrush = false,
  onCellClick = noop,
  setFilters = [],
}) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [data, setData] = useState([])
  const [left, setLeft] = useState('dataMin')
  const [right, setRight] = useState('dataMax')
  const [refAreaLeft, setRefAreaLeft] = useState('')
  const [refAreaRight, setRefAreaRight] = useState('')

  useEffect(() => {
    setData(aggregationData)
    setRefAreaLeft('')
    setRefAreaRight('')
    setLeft('dataMin')
    setRight('dataMax')
  }, [aggregationData])

  const onResize = (w, h) => {
    setWidth(w)
    setHeight(h)
  }

  const zoom = () => {
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setRefAreaLeft('')
      setRefAreaRight('')
      return
    }

    setData(data.slice())
    if (refAreaLeft > refAreaRight) {
      setLeft(refAreaRight)
      setRight(refAreaLeft)
    } else {
      setLeft(refAreaLeft)
      setRight(refAreaRight)
    }
    setRefAreaLeft('')
    setRefAreaRight('')
  }

  const zoomOut = () => {
    setData(data.slice())
    setRefAreaLeft('')
    setRefAreaRight('')
    setLeft('dataMin')
    setRight('dataMax')
  }

  const tiny = width < TINY_VISUALIZATION_WIDTH_THRESHOLD || height < TINY_VISUALIZATION_HEIGHT_THRESHOLD

  return (
    <I18n>{({ t }) => (
      isEmpty(aggregationData) ? (
        <EmptyResults />
      ) : (
        <Container>
          <LegendWrapper>
            <ThreatLevelLegend>
              <LegendDot threat={2} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 2 } })}
              </LegendText>
              <LegendDot threat={1} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 1 } })}
              </LegendText>
              <LegendDot threat={0} />
              <LegendText>
                {t({ key: 'THREAT_LEVEL', variables: { level: 0 } })}
              </LegendText>
            </ThreatLevelLegend>
          </LegendWrapper>
          <ZoomButton>
            {(left !== 'dataMin' || right !== 'dataMax') && <Icon name="zoom out" onClick={zoomOut} />}
          </ZoomButton>
          <AbsolutePosition>
            <AreaChart
              width={width - VISUALIZATION_MARGIN}
              height={height}
              data={data}
              style={{
                marginRight: VISUALIZATION_MARGIN,
                backgroundColor: VISUALIZATION_BACKGROUND,
              }}
              onMouseDown={e => e && setRefAreaLeft(parseInt(e.activeLabel, 10))}
              onMouseMove={e => e && refAreaLeft && setRefAreaRight(parseInt(e.activeLabel, 10))}
              onMouseUp={refAreaLeft === refAreaRight || refAreaRight === '' ? (
                d => {
                  if (!isNil(d)) {
                    onCellClick(unionWith(eqBy(prop('name')),
                      [{
                        name: 'processed_date',
                        term: {
                          from: parseInt(d.activeLabel, 10),
                          to: parseInt(d.activeLabel, 10) + 86400,
                        },
                        type: 'date',
                      }], setFilters), null)
                  }
                }
              ) : zoom}
            >
              <XAxis
                allowDataOverflow
                dataKey="date"
                domain={[left, right]}
                type="number"
                tick={
                  (
                    <DateTick
                      onLabelClick={
                        value => onCellClick(unionWith(eqBy(prop('name')),
                          [{
                            name: 'processed_date',
                            term: {
                              from: parseInt(value, 10),
                              to: parseInt(value, 10) + 86400,
                            },
                            type: 'date',
                          }], setFilters), null)
                      }
                    />
                  )
                }
                tickCount={12}
                tickLine={{ stroke: VISUALIZATION_TICK_DARK, strokeWidth: 2.64 }}
                axisLine={false}
                hide={tiny}
              />
              <YAxis
                domain={['dataMin', 'dataMax']}
                axisLine={false}
                tickLine={false}
                tickMargin={16}
                tick={<CountTick />}
                padding={{ top: 20, bottom: 4 }}
                hide={tiny}
              />
              <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
              <Area
                type="monotone"
                dataKey="countSafe"
                strokeWidth={0}
                fill={CHART_SAFE}
                fillOpacity={1}
                dot={false}
                isAnimationActive={animationsEnabled}
              />
              <Area
                type="monotone"
                dataKey="countCaution"
                strokeWidth={0}
                fill={CHART_CAUTION}
                fillOpacity={0.9}
                dot={false}
                isAnimationActive={animationsEnabled}
              />
              <Area
                type="monotone"
                dataKey="countDanger"
                strokeWidth={0}
                fill={CHART_DANGER}
                fillOpacity={0.9}
                dot={false}
                isAnimationActive={animationsEnabled}
              />
              {
                (refAreaLeft && refAreaRight)
                  ? <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                  : null
              }
              {showBrush && (
                <Brush dataKey="date">
                  <AreaChart>
                    <CartesianGrid />
                    <YAxis hide domain={['auto', 'auto']} />
                    <Area
                      dataKey="count"
                      stroke="#ff7300"
                      fill="#0000ff"
                      dot={false}
                      isAnimationActive={animationsEnabled}
                    />
                  </AreaChart>
                </Brush>
              )}
            </AreaChart>
          </AbsolutePosition>
          <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        </Container>
      ))}
    </I18n>
  )
}

const DateAreaGraph = ({ dashboardFilters, ...rest }) => dashboardFilters && (
  <Suspense
    fallback={<Loader active />}
  >
    <AsyncDateAreaGraph dashboardFilters={dashboardFilters} {...rest} />
  </Suspense>
)

DateTick.propTypes = {
  x: number,
  y: number,
  payload: object,
  onLabelClick: func,
}

CountTick.propTypes = {
  x: number,
  y: number,
  payload: object,
}

CustomTooltip.propTypes = {
  active: bool,
  label: string,
  payload: array,
}

AsyncDateAreaGraph.propTypes = {
  dashboardId: string,
  dashboardFilters: array,
  dashboardFilterMode: string,
  setFilterMode: string,
  setFilters: array,
  showBrush: bool,
  onCellClick: func,
  timestamp: number,
  downloadAll: bool,
  onFetch: func,
  belongsToUser: bool,
  shouldExport: bool,
  afterExport: func,
}

DateAreaGraphLayout.propTypes = {
  aggregationData: array,
  animationsEnabled: bool,
  showBrush: bool,
  onCellClick: func,
  setFilters: array,
}

export default DateAreaGraph
