import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import { I18n } from '../../../lib/i18n'
import { noop } from 'lib/ramda'
import modalStyles, {
  SmallButton,
  Label,
  CloseIcon, CloseButtonTop, Content,
} from '../modalStyles'
import {
  OuterContainer,
  ContentContainer,
  InputContainer,
  Checkbox,
  Input,
  Title,
  Column,
  Divider,
  PoliciesTable,
  SubTitle,
  PolicyItemName,
  EditingIcons,
  ModifyIcon,
  TrashIcon,
  DataRow,
  PolicyRow,
  ButtonsWrapper,
  SendersButton,
  CreatePolicyButton,
  AddPolicyIconWrapper,
  AddPolicyIcon,
  PolicyTitleRow
} from './styles'
import { DashboardsContext } from '../../../providers/Dashboards'
import actions from '../../../requests/quarantine'
import {AuthContext} from "../../../@logicea/react-auth";

const QuarantineSettings = () => {

  const [policies, setPolicies] = useState({})
  const [approvers, setApprovers] = useState({})
  const [mailFromAddress, setMailFromAddress] = useState("")
  const [mailFromName, setMailFromName] = useState("")
  const [mailReturnPath, setMailReturnPath] = useState("")
  const [sendMailOnCompletion, setSendMailOnCompletion] = useState(true)
  const [xHeaderKey, setXHeaderKey] = useState("")
  const [stateChangeWebhook, setStateChangeWebhook] = useState("")
  const [reportingWebhook, setReportingWebhook] = useState("")
  const [dirty, setDirty] = useState(false)

  const auth = useContext(AuthContext)
  const context = useContext(DashboardsContext)
  const {
    isQuarantineSettingsModalOpen,
    setQuarantineSettingsModalVisibility,
    setQuarantineApproversModalVisibility,
    setQuarantinePolicyData,
    setQuarantineSettingsData,
    setQuarantinePolicyModalVisibility,
    toggleActionModal,
    setQuarantineApproverList,
    quarantineSettingsData,
  } = context

  const handleCreatePolicyButtonClick = () => {
    setQuarantinePolicyData(null)
    setQuarantinePolicyModalVisibility(true)
  }

  const handleEditPolicyButtonClick = (policy) => {
    setQuarantinePolicyData(policies[policy])
    setQuarantinePolicyModalVisibility(true)
  }

  const handleDeletePolicyButtonClick = (policy) => {
    setQuarantinePolicyData(quarantineSettingsData.policies[policy])
    toggleActionModal(noop)('delete', 'policy')
  }

  const handleShowApproversButtonClick = () => {
    setQuarantineApproverList(approvers)
    setQuarantineApproversModalVisibility(true)
  }

  const handleChangeMailFromName = (evt, data) => {
    if (data.value !== mailFromName)
      setDirty(true)
    setMailFromName(data.value)
  }

  const handleChangeMailFromAddress = (evt, data) => {
    if (data.value !== mailFromAddress)
      setDirty(true)
    setMailFromAddress(data.value)
  }

  const handleChangeMailReturnPath = (evt, data) => {
    if (data.value !== mailReturnPath)
      setDirty(true)
    setMailReturnPath(data.value)
  }

  const handleChangeSendMailOnCompletion = (evt, data) => {
    if (data.value !== sendMailOnCompletion)
      setDirty(true)
    setSendMailOnCompletion(data.value)
  }

  const handleChangeXHeaderKey = (evt, data) => {
    if (data.value !== xHeaderKey)
      setDirty(true)
    setXHeaderKey(data.value)
  }

  const handleChangeStateChangeWebhook = (evt, data) => {
    if (data.value !== stateChangeWebhook)
      setDirty(true)
    setStateChangeWebhook(data.value)
  }

  const handleChangeReportingWebhook = (evt, data) => {
    if (data.value !== reportingWebhook)
      setDirty(true)
    setReportingWebhook(data.value)
  }

  const handleSubmit = () => {
    if (dirty) {
      console.log("Changes need to be saved")
      let settings = {
        mail_from_address: mailFromAddress,
        mail_from_name: mailFromName,
        mail_return_path: mailReturnPath,
        send_mail_on_completion: sendMailOnCompletion,
        header_key: xHeaderKey,
        reporting_webhook: reportingWebhook,
        state_change_webhook: stateChangeWebhook,
        policies: policies,
        approvers: approvers
      }
      actions.putQuarantineSettingsAction('inky', auth, settings)
    }
    setQuarantineSettingsModalVisibility(false)
  }

  useEffect(() => {
    if (quarantineSettingsData) {
      setMailFromAddress(quarantineSettingsData.mail_from_address)
      setMailFromName(quarantineSettingsData.mail_from_name)
      setMailReturnPath(quarantineSettingsData.mail_return_path)
      setSendMailOnCompletion(quarantineSettingsData.send_mail_on_completion)
      setXHeaderKey(quarantineSettingsData.header_key)
      setReportingWebhook(quarantineSettingsData.reporting_webhook)
      setStateChangeWebhook(quarantineSettingsData.state_change_webhook)
      setPolicies(quarantineSettingsData.policies)
      setApprovers(quarantineSettingsData.approvers)
    }
  }, [quarantineSettingsData])

  useEffect(() => {
    if (isQuarantineSettingsModalOpen) {
      actions.getQuarantineSettingsAction('inky', auth).then((settings) => {
        console.log("Got settings", settings)
        setQuarantineSettingsData(settings)
      })
    }
  }, [isQuarantineSettingsModalOpen])

  return (
    <I18n>{({ t }) => (
      <Modal
        isOpen={isQuarantineSettingsModalOpen}
        onRequestClose={() => setQuarantineSettingsModalVisibility(false)}
        contentLabel={t({ key: 'MODAL_QUARANTINE_CONTENT_LABEL' })}
        style={{
          overlay: modalStyles.overlay,
          content: modalStyles.content,
        }}
      >
        <OuterContainer>
          <Title>Quarantine Settings for team Inky</Title>
          <Divider fitted/>
          <ContentContainer>
            <DataRow>
              <Column>
                <InputContainer>
                  <Label>X Header Key</Label>
                  <Input placeholder="key" value={xHeaderKey} onChange={handleChangeXHeaderKey}/>
                </InputContainer>
                <InputContainer>
                  <Label>Reporting Webhook</Label>
                  <Input placeholder="uri" value={reportingWebhook} onChange={handleChangeReportingWebhook}/>
                </InputContainer>
                <InputContainer>
                  <Label>State Change Webhook</Label>
                  <Input placeholder="uri" value={stateChangeWebhook} onChange={handleChangeStateChangeWebhook}/>
                </InputContainer>
                {/*<InputContainer>*/}
                {/*  <Label>Notify Sender</Label>*/}
                {/*  <Checkbox fitted/>*/}
                {/*</InputContainer>*/}
                <InputContainer>
                  <Label>Use Internal MTA</Label>
                  <Checkbox fitted checked={sendMailOnCompletion} onChange={handleChangeSendMailOnCompletion}/>
                </InputContainer>
              </Column>
              <Column>
                <InputContainer>
                  <Label>External Return Path</Label>
                  <Input placeholder="uri" value={mailReturnPath} onChange={handleChangeMailReturnPath}/>
                </InputContainer>
                <InputContainer>
                  <Label>Notification From Name</Label>
                  <Input placeholder="name" value={mailFromName} onChange={handleChangeMailFromName}/>
                </InputContainer>
                <InputContainer>
                  <Label>Notification From Address</Label>
                  <Input placeholder="address" value={mailFromAddress} onChange={handleChangeMailFromAddress}/>
                </InputContainer>
              </Column>
              <Column>
                <SendersButton onClick={handleShowApproversButtonClick}>View Approvers</SendersButton>
                <PolicyTitleRow>
                  <SubTitle>Policies</SubTitle>
                  <AddPolicyIconWrapper onClick={handleCreatePolicyButtonClick}>
                    <AddPolicyIcon />
                  </AddPolicyIconWrapper>
                </PolicyTitleRow>
                <PoliciesTable>
                  <Content>
                    {
                      Object.entries(policies).map( ([key, value]) => (
                        <PolicyRow>
                          <PolicyItemName>{key}</PolicyItemName>
                          <EditingIcons>
                            <ModifyIcon onClick={() => {handleEditPolicyButtonClick(key)}}/>
                            <TrashIcon onClick={() => {handleDeletePolicyButtonClick(key)}}/>
                          </EditingIcons>
                        </PolicyRow>
                      ))
                    }
                  </Content>
                </PoliciesTable>
              </Column>
            </DataRow>
          </ContentContainer>
          <CloseButtonTop>
            <CloseIcon onClick={() => setQuarantineSettingsModalVisibility(false)} />
          </CloseButtonTop>
          <ButtonsWrapper>
            <SmallButton type="button" onClick={() => setQuarantineSettingsModalVisibility(false)}>{t({ key: 'CANCEL' })}</SmallButton>
            <SmallButton type="button" onClick={handleSubmit} dark>{t({ key: 'SAVE' })}</SmallButton>
          </ButtonsWrapper>
        </OuterContainer>
      </Modal>
    )}
    </I18n>
  )
}

export default QuarantineSettings
