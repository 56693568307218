import styled from 'styled-components'
import { colors, BodyPrimaryMainRegular } from 'static/theme'

export const InputGroup = styled.div`
  margin-top: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

  div:first-child {
    margin-right: 10px;
  }
`

export const RangeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px 16px 16px;
`

export const RangeContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const RangeTextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const RangeText = styled(BodyPrimaryMainRegular)``

export const trackStyle = {
  backgroundColor: colors.primaryMain,
}

export const markStyle = {
  fontSize: '14px',
  color: colors.darkGrey,
}

export const handleStyle = {
  backgroundColor: colors.primaryMain,
  border: '1px solid',
  borderColor: colors.primaryLighter,
  boxShadow: 'none',
  height: '16px',
  width: '16px',
}

export const dotStyle = {
  backgroundColor: colors.primaryMain,
  boxShadow: 'none',
  border: 'none',
  height: '8px',
  width: '8px',
}
