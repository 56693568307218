import styled from 'styled-components'
import {
  TABLE_BORDER, ROW_ITEM_HOVER, PRIMARY_DARK,
} from '../../../static/colors'
import {Icon, Input as SemanticInput} from "semantic-ui-react"
import {Divider as SemanticDivider} from "semantic-ui-react"
import {Checkbox as SemanticCheckbox} from "semantic-ui-react";
import {Button} from "../modalStyles"
import plusIcon from "../../../assets/plus/plus.png";

export const Divider = styled(SemanticDivider)`
  width: 90%;
`

export const SendersButton = styled(Button)`
  margin-top: 0;
`

export const CreatePolicyButton = styled(Button)`
  margin-top: 12px;
`

export const AddPolicyIcon = styled.img.attrs({
  src: plusIcon,
  alt: 'add',
})`
  width: 25px;
  height: 25px;
  padding: 5px;
`

export const AddPolicyIconWrapper = styled.div`
  background-color: transparent;
  transition: background-color 0.5s;
  transition: opacity 0.3s;
  border-radius: 13px;
  height: 25px;
  width: 25px;
  right: 8px;

  &:hover {
    cursor: pointer;
    background-color: rgba(100, 100, 100, 0.3);
  }
`

export const PoliciesTable = styled.div`
  max-height: 120px;
  min-height: 85px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid ${TABLE_BORDER};
  overflow-y: auto;
`

export const OuterContainer = styled.div`
  width: 908px;
  height: 460px;
  max-width: 90vw;
  max-height: 90vh;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  font-size: 11px;
`

export const DataRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: flex-start;
`

export const PolicyRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${ROW_ITEM_HOVER};
  }
`

export const EditingIcons = styled.div`
  flex-direction: row;
  display: none;
  flex: 0;

  ${PolicyRow}:hover & {
    display: flex;
    cursor: pointer;
  }
`

export const ModifyIcon = styled(Icon).attrs(
  {name: "pencil"}
)`
  font-size: 1.4em !important;
`

export const TrashIcon = styled(Icon).attrs(
  {name: "trash"}
)`
  font-size: 1.4em !important;
`

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 40px;
`

export const PolicyItemName = styled.div`
  flex: 1;
  display: flex;
  padding: 4px;
  font-size: 14px;
`

export const InputContainer = styled.div`
  padding-bottom: 8px;
`

export const Input = styled(SemanticInput)`
  width: 220px;
`

export const Checkbox = styled(SemanticCheckbox)`
  padding: 5px 0 22px 0;
`

export const Title = styled.div`  
  padding: 20px;
  text-transform: capitalize;
  font-size: 28px;
`

export const PolicyTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SubTitle = styled.div`  
  padding: 8px 0;
  font-size: 16px;
  flex: 1;
`

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ButtonsWrapper = styled.div`
  width: 220px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 20px;
`

