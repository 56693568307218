import React, { useContext } from 'react'
import { I18n } from 'lib/i18n'
import { DashboardsContext } from 'providers/Dashboards'
import { Tooltip } from 'lib/util'
import {
  Container,
  Button,
  Text,
  Seperator,
  RedoIcon,
  SaveIcon,
  UndoIcon,
} from './styles'

export const DashboardStateActions = ({
  onSave,
  canDoRedo,
  onRedo,
  canDoUndo,
  onUndo,
}: {
  canDoUndo: Boolean
  onUndo: () => void
  onSave: () => void
  canDoRedo: Boolean
  onRedo: () => void
}) => {
  const context = useContext(DashboardsContext) as any
  const { autoSave } = context

  return (
    <I18n>
      {({ t }) => (
        <Container>
          <Button data-tip data-for='saveButton' onClick={onSave}>
            <SaveIcon data-for='saveButton' active={!autoSave} />
            <Text active={!autoSave}>
              {t({ key: 'DASHBOARD_STATES_SAVE' })}
            </Text>
          </Button>
          <Tooltip
            top
            id='saveButton'
            delayShow={undefined}
            disable={!autoSave}
          >
            <span>{t({ key: 'TOOLTIP_AUTOSAVE_ENABLED' })}</span>
          </Tooltip>
          <Seperator />
          <Button onClick={onUndo}>
            <UndoIcon active={canDoUndo} />
            <Text active={canDoUndo}>
              {t({ key: 'DASHBOARD_STATES_UNDO' })}
            </Text>
          </Button>
          <Button onClick={onRedo}>
            <RedoIcon active={canDoRedo} />
            <Text active={canDoRedo}>
              {t({ key: 'DASHBOARD_STATES_REDO' })}
            </Text>
          </Button>
        </Container>
      )}
    </I18n>
  )
}
