import styled from 'styled-components'
import {
  colors,
  spacing,
  NumbersLDarkGreyBold as NumbersLDarkGreyBoldAbstract,
  NumbersMDarkGreyBold as NumbersMDarkGreyBoldAbstract,
  BodyXSPrimaryMainRegular,
} from 'static/theme'
import { PercentType } from '.'

type Props = {
  percentType: PercentType
}

export const Container = styled.div`
  display: flex;
`

export const NumbersLDarkGreyBold = styled(NumbersLDarkGreyBoldAbstract)``

export const NumbersMDarkGreyBold = styled(NumbersMDarkGreyBoldAbstract)``

export const Placeholder = styled.div`
  min-width: 43.2px;
`
export const ComparedData = styled(BodyXSPrimaryMainRegular)<Props>`
  display: flex;
  align-items: center;
  color: ${({ percentType }) =>
    percentType === 'positive'
      ? colors.danger
      : percentType === 'negative'
      ? colors.safe
      : colors.primaryLight};
`

export const ArrowIcon = styled.div<Props>`
  margin-left: ${spacing.xs};
  font-size: 0.5rem;
  transform: ${({ percentType }) =>
    percentType === 'positive'
      ? 'rotate(-90deg)'
      : percentType === 'negative'
      ? 'rotate(90deg)'
      : 'rotate(0deg)'};
`

export const Percent = styled.div`
  margin-left: ${spacing.xxs};
`
