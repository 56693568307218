import React, { useContext } from 'react'
import { navigate, useLocation } from '@reach/router'
import prop from 'ramda/src/prop'
import sortBy from 'ramda/src/sortBy'
import propEq from 'ramda/src/propEq'
import filter from 'ramda/src/filter'
import zipObj from 'ramda/src/zipObj'
import { AuthContext } from '@logicea/react-auth'
import {
  Column,
  DashboardName,
  DashboardIcons,
  CloneDashboardIcon,
  SubmenuItem,
} from './styles'
import { DashboardsContext } from '../../../../providers/Dashboards'
import { Tooltip } from '../../../../lib/util'
import { I18n } from '../../../../lib/i18n'
import { noop } from 'lib/ramda'

export const Reports = () => {
  const location = useLocation()
  const context = useContext(DashboardsContext)
  const {
    selectedDashboardIndex,
    isReportSelected,
    handleDashboardChange,
    handleRefreshClick,
    toggleActionModal,
    dashboards,
  } = context as any

  const auth = useContext(AuthContext)
  const { user }: any = auth

  const reportDashboards = user
    ? sortBy(
        prop<string>('name'),
        filter(propEq('user_id', 'report-template@inkysteps.com'), dashboards)
      )
    : []

  let dashboardIds = [] as any
  const dashboardIndices = dashboards.map((dashboard: any, index: number) => {
    dashboardIds.push(dashboard.id)
    return index
  })
  const dashboardIndexById = zipObj(dashboardIds, dashboardIndices)

  const handleDashboardClick = (id: string, isReport: Boolean) => () => {
    navigate(`/reports/${id}`)
    if (
      selectedDashboardIndex > -1 &&
      id === dashboards[selectedDashboardIndex].id &&
      isReport === isReportSelected
    )
      handleRefreshClick()
    else handleDashboardChange(dashboardIndexById[id], isReport)
  }

  const handleCloneDashboardClick = (event: any) => {
    event.stopPropagation()
    toggleActionModal(noop)('clone', 'dashboard')
  }

  return (
    <I18n>
      {({ t }) => (
        <Column>
          {reportDashboards &&
            reportDashboards.map((dashboard: any) => (
              <SubmenuItem
                key={dashboard.id}
                selected={
                  dashboardIndexById[dashboard.id] === selectedDashboardIndex &&
                  isReportSelected &&
                  location.pathname.startsWith('/reports')
                }
                onClick={handleDashboardClick(dashboard.id, true)}
              >
                <DashboardName>{dashboard.name}</DashboardName>
                {dashboardIndexById[dashboard.id] === selectedDashboardIndex &&
                  isReportSelected && (
                    <DashboardIcons>
                      <CloneDashboardIcon
                        data-tip
                        data-for='cloneDashboardButton'
                        onClick={handleCloneDashboardClick}
                      />
                      <Tooltip
                        bottom
                        id='cloneDashboardButton'
                        delayShow={undefined}
                      >
                        <span>{t({ key: 'TOOLTIP_CLONE_DASHBOARD' })}</span>
                      </Tooltip>
                    </DashboardIcons>
                  )}
              </SubmenuItem>
            ))}
        </Column>
      )}
    </I18n>
  )
}
