import React from 'react'
import styled from 'styled-components'
import { FormControl, MenuItem, SelectProps, Select } from '@material-ui/core'
import { Body2DarkGreyRegular, BodyPrimaryMainBold, colors } from 'static/theme'
import { ReactComponent as DropdownSVG } from 'assets/dropdownIcon/chevron-down.svg'

const DropdownIcon = styled(DropdownSVG)`
  fill: ${colors.primaryDark};
`

const StyledSelect = styled(Select)`
  && .MuiSelect-root {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  :before {
    display: none;
  }
  :after {
    display: none;
  }
  svg {
    margin-top: 4px;
  }
`

const LabelRegular = styled(Body2DarkGreyRegular)`
  line-height: 1.2rem;
`

const LabelBold = styled(BodyPrimaryMainBold)`
  line-height: 1.2rem;
`

export type DropdownSelectOption = {
  label: string
  value: number | string
}

export type DropdownSelectProps = {
  variant?: 'filled' | 'outlined' | 'standard' | undefined
  options: Array<DropdownSelectOption>
} & SelectProps

const DropdownSelect = ({ variant, options, ...rest }: DropdownSelectProps) => {
  const Label = variant === 'standard' ? LabelBold : LabelRegular
  return (
    <FormControl variant={variant || 'outlined'} color='secondary' size='small'>
      <StyledSelect IconComponent={DropdownIcon} {...rest}>
        {options.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            <Label>{item.label}</Label>
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  )
}

export { DropdownSelect }
