import React, { useCallback, useContext, useEffect } from 'react'
import { useQuery, useMutation, queryCache } from 'react-query'
import { useParams } from '@reach/router'
import useUndo from 'use-undo'
import { AuthContext } from '@logicea/react-auth'
import last from 'ramda/src/last'
import head from 'ramda/src/head'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import isEmpty from 'ramda/src/isEmpty'
import { zipObj } from 'ramda'
import { Loader } from 'semantic-ui-react'
import { I18n } from '../../../lib/i18n'
import Layout from '../../Layout'
import { Header } from './components/Header'
import DragDropArea from '../../DragDropArea'
import { useHelp } from 'providers/Help'
import { DashboardsContext } from 'providers/Dashboards'
import { getDashboardAction, updateDashboardAction } from 'requests/dashboards'
import { EmptyDashboard } from './EmptyDashboard'
import { DashboardT, FilterT } from './types'
import {
  addWidgets,
  removeColumn,
  addToNextLevel,
  deleteMessageSet,
  handleFilterEdit,
  handleFilterRemoval,
  toggleFilterNegation,
  handleSetLayoutChange,
  handleFilterModeChange,
  handleVisualizationChange,
  toggleColumnViewModeChange,
  handleMessageSetActionSubmit,
} from './utils'
import { Container, Segment, Content, MainContainer } from './styles'

const getDirection = (layout: string) => {
  switch (layout) {
    case 'box':
      return 'vertical'
    case 'horizontal':
      return 'vertical'
    case 'vertical':
      return 'horizontal'
    case 'pi':
      return 'vertical'
    case 'epsilon':
      return 'horizontal'
    default:
      return 'vertical'
  }
}

const Dashboard = () => {
  const { dashboardId: routeDashboardId }: { dashboardId: string } = useParams()
  const [
    dashboardState,
    { set: setDashboard, reset, undo, redo, canUndo, canRedo },
  ] = useUndo<DashboardT | null>(null)
  const { present: dashboard, past, future } = dashboardState
  const {
    layout,
    options,
    autoSave,
    timestamp,
    dashboards,
    showMessage,
    taggedMessage,
    selectedSetId,
    selectedLayoutLevel,
    isReportSelected,
    onMessageTag,
    toggleTagModal,
    toggleActionModal,
    toggleAddWidgetsModal,
    toggleFilterEditorModal,
    toggleConfirmationModal,
    handleMessageSetResize,
    handleMessageClick,
    handleMessageClose,
    handleResultsClose,
    handleLayoutLevelChange,
    messageSetNumberOfColumns,
    handleDashboardChange,
    setSelectedDashboard,
  } = useContext(DashboardsContext)
  const auth = useContext(AuthContext)
  const { realm, tokens }: any = auth
  const { isHelpGuideEnabled } = useHelp()

  const { data, isLoading } = useQuery(
    [
      'dashboard',
      { realm, token: tokens?.accessToken, dashboardId: routeDashboardId },
    ],
    getDashboardAction,
    { enabled: routeDashboardId !== undefined }
  )

  const [update] = useMutation(updateDashboardAction, {
    onSuccess: () => {
      queryCache.setQueryData('dashboard', (old: any) => [...old, dashboard])
    },
  })

  const canDoRedo =
    canRedo &&
    future.length > 0 &&
    head(future) !== null &&
    head(future) !== undefined
  const canDoUndo =
    canUndo &&
    past.length > 0 &&
    last(past) !== null &&
    last(past) !== undefined

  const handleKeyboardEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'KeyZ') {
        if (canDoRedo && event.shiftKey && (event.ctrlKey || event.metaKey)) {
          redo()
        } else if (
          canDoUndo &&
          past.length > 1 &&
          !event.shiftKey &&
          (event.ctrlKey || event.metaKey)
        ) {
          undo()
        }
      }
    },
    [redo, undo, canRedo, canUndo, future, past]
  )

  useEffect(() => {
    document.removeEventListener('keydown', handleKeyboardEvent, false)
    document.addEventListener('keydown', handleKeyboardEvent, false)
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvent, false)
    }
  }, [dashboardState])

  const dashboardIds: Array<number> = []
  const dashboardIndices = dashboards.map((dashboard: any, index: number) => {
    dashboardIds.push(dashboard.id)
    return index
  })
  const dashboardIndexById: Record<string, number> = zipObj(
    dashboardIds,
    dashboardIndices
  )

  useEffect(() => {
    if (data === null) return
    setDashboard(data as DashboardT)
    setSelectedDashboard(data)
    if (routeDashboardId !== undefined) {
      handleDashboardChange(dashboardIndexById[routeDashboardId], false)
    }
  }, [routeDashboardId, data])

  const onSave = useCallback(() => {
    if (!autoSave) {
      update({
        realm,
        token: tokens?.accessToken,
        ...dashboard,
      })
    }
  }, [dashboard, tokens, realm])

  const onUndo = useCallback(() => {
    if (canDoUndo) {
      undo()
      if (autoSave) {
        update({
          realm,
          token: tokens?.accessToken,
          ...last(past),
        })
      }
    }
  }, [undo, canDoUndo, past, tokens, realm])

  const onRedo = useCallback(() => {
    if (canDoRedo) {
      redo()
      if (autoSave) {
        update({
          realm,
          token: tokens?.accessToken,
          ...head(future),
        })
      }
    }
  }, [redo, canDoRedo, past, tokens, realm])

  const handleDashboardUpdate = useCallback(
    (newDashboard: DashboardT | null) => {
      setDashboard(newDashboard)
      if (newDashboard !== null && newDashboard !== undefined && autoSave) {
        update({
          realm,
          token: tokens?.accessToken,
          ...newDashboard,
        })
      }
    },
    [setDashboard, realm, tokens, autoSave]
  )

  const messageSets = dashboard?.message_sets
  const filterMode = dashboard?.filter_mode
  const filtersProp = dashboard?.filters
  const dashboardId = dashboard?.id
  const layoutDirection = getDirection(layout)
  const columns = messageSets?.columnOrder || []
  const dashboardUserId = dashboard?.user_id
  const authUserId = pathOr(null, ['user', 'email'], auth)
  const belongsToUser = authUserId === dashboardUserId || isReportSelected
  const noMessageSets =
    dashboard !== null &&
    dashboard !== undefined &&
    (isEmpty(messageSets) ||
      isNil(messageSets) ||
      isNil(messageSets.sets) ||
      isEmpty(messageSets.sets))

  const handleAddButtonClick = useCallback(() => {
    if (dashboard !== null)
      toggleAddWidgetsModal(addWidgets(dashboard, handleDashboardUpdate))
  }, [dashboard, addWidgets, toggleAddWidgetsModal])

  const handleFilterClick = useCallback(
    (selectedFilter: FilterT | null, setId?: string) => {
      if (dashboard !== null)
        toggleFilterEditorModal(
          selectedFilter,
          setId,
          handleFilterEdit(dashboard, handleDashboardUpdate)
        )
    },
    [dashboard, handleFilterEdit, toggleFilterEditorModal]
  )

  const contentComponent = (
    <I18n>
      {({ t }) => (
        <Segment>
          <Content>
            <DragDropArea
              selectedLayoutLevel={layout === 'box' ? selectedLayoutLevel : 0}
              configureMode
              layout={layout}
              options={options}
              dashboard={dashboard}
              timestamp={timestamp}
              showMessage={showMessage}
              dashboardId={dashboardId}
              direction={layoutDirection}
              belongsToUser={belongsToUser}
              isReport={isReportSelected}
              taggedMessage={taggedMessage}
              messageSetLayout={messageSets}
              dashboardFilters={filtersProp}
              dashboardFilterMode={filterMode}
              onResize={handleMessageSetResize}
              onContentClick={handleMessageClick}
              onLayoutUpdate={handleSetLayoutChange(
                dashboard,
                handleDashboardUpdate
              )}
              deleteMessageSet={deleteMessageSet(
                dashboard,
                handleDashboardUpdate,
                handleLayoutLevelChange,
                selectedSetId
              )}
              onRemoveColumn={removeColumn(
                dashboard,
                handleDashboardUpdate,
                handleLayoutLevelChange
              )}
              onCellClick={addToNextLevel(
                dashboard,
                handleDashboardUpdate,
                handleLayoutLevelChange,
                messageSetNumberOfColumns
              )}
              onMessageTag={onMessageTag}
              toggleTagModal={toggleTagModal}
              toggleConfirmationModal={toggleConfirmationModal}
              toggleMessageSetActionModal={toggleActionModal(
                handleMessageSetActionSubmit(dashboard, handleDashboardUpdate)
              )}
              toggleColumnViewModeChange={toggleColumnViewModeChange(
                dashboard,
                handleDashboardUpdate
              )}
              onVisualizationChange={handleVisualizationChange(
                dashboard,
                handleDashboardUpdate
              )}
              handleFilterClick={handleFilterClick}
              handleLayoutLevelChange={handleLayoutLevelChange}
              handleFilterRemoval={handleFilterRemoval(
                dashboard,
                handleDashboardUpdate
              )}
              toggleFilterNegation={toggleFilterNegation(
                dashboard,
                handleDashboardUpdate
              )}
              handleFilterModeChange={handleFilterModeChange(
                dashboard,
                handleDashboardUpdate
              )}
            />
          </Content>
        </Segment>
      )}
    </I18n>
  )

  return isLoading ? (
    <Loader active />
  ) : (
    <Container>
      <MainContainer>
        {dashboard !== null && (
          <Header
            dashboard={dashboard}
            handleFilterClick={handleFilterClick}
            handleFilterRemoval={handleFilterRemoval(
              dashboard,
              handleDashboardUpdate
            )}
            toggleFilterNegation={toggleFilterNegation(
              dashboard,
              handleDashboardUpdate
            )}
            handleFilterModeChange={handleFilterModeChange(
              dashboard,
              handleDashboardUpdate
            )}
            handleAddButtonClick={handleAddButtonClick}
            onUndo={onUndo}
            canDoUndo={canDoUndo}
            canDoRedo={canDoRedo}
            onRedo={onRedo}
            onSave={onSave}
          />
        )}
        {noMessageSets && belongsToUser ? (
          <EmptyDashboard onAddWidgetsClick={handleAddButtonClick} />
        ) : (
          <Layout
            name={layout}
            first={contentComponent}
            second={
              (layout === 'pi' || layout === 'epsilon') && (
                <DragDropArea
                  selectedLayoutLevel={1}
                  configureMode
                  layout={layout}
                  options={options}
                  dashboard={dashboard}
                  timestamp={timestamp}
                  showMessage={showMessage}
                  dashboardId={dashboardId}
                  direction={layoutDirection}
                  belongsToUser={belongsToUser}
                  taggedMessage={taggedMessage}
                  messageSetLayout={messageSets}
                  dashboardFilters={filtersProp}
                  dashboardFilterMode={filterMode}
                  onResize={handleMessageSetResize}
                  onContentClick={handleMessageClick}
                  onLayoutUpdate={handleSetLayoutChange(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  deleteMessageSet={deleteMessageSet(
                    dashboard,
                    handleDashboardUpdate,
                    handleLayoutLevelChange,
                    selectedSetId
                  )}
                  onRemoveColumn={removeColumn(
                    dashboard,
                    handleDashboardUpdate,
                    handleLayoutLevelChange
                  )}
                  onCellClick={addToNextLevel(
                    dashboard,
                    handleDashboardUpdate,
                    handleLayoutLevelChange,
                    messageSetNumberOfColumns
                  )}
                  onMessageTag={onMessageTag}
                  toggleTagModal={toggleTagModal}
                  toggleConfirmationModal={toggleConfirmationModal}
                  toggleMessageSetActionModal={toggleActionModal(
                    handleMessageSetActionSubmit(
                      dashboard,
                      handleDashboardUpdate
                    )
                  )}
                  toggleColumnViewModeChange={toggleColumnViewModeChange(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  onVisualizationChange={handleVisualizationChange(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  handleFilterClick={handleFilterClick}
                  handleLayoutLevelChange={handleLayoutLevelChange}
                  handleFilterRemoval={handleFilterRemoval(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  toggleFilterNegation={toggleFilterNegation(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  handleFilterModeChange={handleFilterModeChange(
                    dashboard,
                    handleDashboardUpdate
                  )}
                />
              )
            }
            third={
              (layout === 'pi' || layout === 'epsilon') && (
                <DragDropArea
                  selectedLayoutLevel={2}
                  configureMode
                  layout={layout}
                  options={options}
                  dashboard={dashboard}
                  timestamp={timestamp}
                  showMessage={showMessage}
                  dashboardId={dashboardId}
                  direction={layoutDirection}
                  belongsToUser={belongsToUser}
                  taggedMessage={taggedMessage}
                  messageSetLayout={messageSets}
                  dashboardFilters={filtersProp}
                  dashboardFilterMode={filterMode}
                  onResize={handleMessageSetResize}
                  onContentClick={handleMessageClick}
                  onLayoutUpdate={handleSetLayoutChange(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  deleteMessageSet={deleteMessageSet(
                    dashboard,
                    handleDashboardUpdate,
                    handleLayoutLevelChange,
                    selectedSetId
                  )}
                  onRemoveColumn={removeColumn(
                    dashboard,
                    handleDashboardUpdate,
                    handleLayoutLevelChange
                  )}
                  onCellClick={addToNextLevel(
                    dashboard,
                    handleDashboardUpdate,
                    handleLayoutLevelChange,
                    messageSetNumberOfColumns
                  )}
                  onMessageTag={onMessageTag}
                  toggleTagModal={toggleTagModal}
                  toggleConfirmationModal={toggleConfirmationModal}
                  toggleMessageSetActionModal={toggleActionModal(
                    handleMessageSetActionSubmit(
                      dashboard,
                      handleDashboardUpdate
                    )
                  )}
                  toggleColumnViewModeChange={toggleColumnViewModeChange(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  onVisualizationChange={handleVisualizationChange(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  handleFilterClick={handleFilterClick}
                  handleLayoutLevelChange={handleLayoutLevelChange}
                  handleFilterRemoval={handleFilterRemoval(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  toggleFilterNegation={toggleFilterNegation(
                    dashboard,
                    handleDashboardUpdate
                  )}
                  handleFilterModeChange={handleFilterModeChange(
                    dashboard,
                    handleDashboardUpdate
                  )}
                />
              )
            }
            onMessageClose={handleMessageClose}
            onResultsClose={handleResultsClose}
          />
        )}
      </MainContainer>
    </Container>
  )
}

export default Dashboard
